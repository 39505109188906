import React, { Component } from 'react'
import { i18n } from '../../lib'
import { AdminUtil } from '../../lib/utils'
import { RippleButton, InteractiveTable } from '..'
import { RegEx, AdminUsers } from '../../data/model'
import { Button, Loader, Modal, Message } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import exampleZh from '../../img/csvExample_zh.png'
import '../../scss/Modal.scss'

// Errors: [ `NO_DATA` | `INVALID_HEADERS` | `NO_EMPTY ROWS` | ]

const examples = {
    en: exampleZh,
    zh: exampleZh
};
const rowModel = {id: -1, name: "", email: ""};

export default class NewAdvisor extends Component {
    state = {
        data: [],
        existingEmails: {},
        newEmails: {},
        error: false,
        isValid: false,
        loading: false,
        campus: "",
        duplicates: 0
    }
    constructor(){
        super();
        this.onFileDrop = this.onFileDrop.bind(this);
        this.onCampusChange = this.onCampusChange.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.validateField = this.validateField.bind(this);
        this.onRowChange = this.onRowChange.bind(this);
        this.onRowAdd = this.onRowAdd.bind(this);
        this.onRowDelete = this.onRowDelete.bind(this);
        this.headers = {
            NAME: i18n.t('APP.NAME'),
            EMAIL: i18n.t('APP.EMAIL')
        };
    }
    componentDidMount(){
        this.setState({ 
            campus: AdminUtil.getCurrentCampusId(), 
            existingEmails: getEmailMap() 
        });
    }
    /**
     * Get a map of all new emails for duplicate checks
     */
    getNewEmails(){
        const { data } = this.state;
        const newEmails = {};

        for (var i=0; i<data.length; i++){
            newEmails[data[i].email] = true;
        }
        return newEmails;
    }
    /**
     * Validate table cell value
     * 
     * @param {String} key table cell key
     * @param {String} val table cell val
     */
    validateField(key, val){
        if (key === "email"){
            const { existingEmails } = this.state;
            // console.log("VALIDATE EMAIL: ", val, existingEmails.hasOwnProperty(val));
            return (
                    val.length === 0 || 
                    this.validateEmail(val)
                ) && !existingEmails.hasOwnProperty(val);
        }
        return true;
    }
    /**
     * Check that CSV has the correct headers.
     * 
     * The CSV can contain other headers, but it must contain the NAME and
     * EMAIL headers (in the current language) to be considered valid.
     * 
     * @param {Array} csvData array of csv rows. Rows are maps of column keys to cell values.
     */
    validateCSV(csvData){
        if (csvData.length === 0){
            // Check that data exists
            this.setState({ error: 'NO_DATA', loading: false });
            return false;
        }

        const firstRow = csvData[0];
        const headers = this.headers;
        if (!firstRow.hasOwnProperty(headers.NAME) || !firstRow.hasOwnProperty(headers.EMAIL)){
            this.setState({ 
                error: 'INVALID_HEADERS%headers%\"' + 
                    headers.NAME + '\", \"' + headers.EMAIL + "\"", 
                loading: false 
            });
            return false;
        }
        return true;
    }
    /**
     * Validate Email
     * 
     * @param {String} email
     */
    validateEmail(email){
        return email.length > 0 && RegEx.email.test(email);
    }
    /**
     * Validate all emails
     */
    checkAllRows(){
        const { data } = this.state;
        const emails = {};
        if (data.length === 0){
            return { isValid: false, error: false };
        }
        for (var d=0; d<data.length; d++){
            const email = data[d].email;
            if (email.length === 0){
                return {
                    isValid: false,
                    error: false
                };
            } else if (!this.validateEmail(email)){
                return {
                    isValid: false,
                    error: 'INVALID_EMAIL_FORMAT%email%' + email
                };
            } else if (emails[email]){
                return {
                    isValid: false,
                    error: 'HAS_DUPLICATES'
                };
            }
            emails[email] = true;
        }
        return { isValid: true, error: false };
    }
    /**
     * Add all users with valid, non-duplicate emails to the on deck table.
     * 
     * @param {Array} newUsers array of new users, parsed from CSV
     */
    addUsers(newUsers){
        const { existingEmails } = this.state;
        const validatedData = this.state.data;
        const newEmails = this.getNewEmails();
        var count = 0;
        var duplicates = 0;
        
        for (var i=0; i<newUsers.length; i++){
            const row = newUsers[i];
            const name = row[this.headers.NAME] || "--";
            const email = row[this.headers.EMAIL];
            if (email.length === 0){
                continue;
            }

            if (email in existingEmails || email in newEmails){
                duplicates++;
            } else {
                validatedData.push({id: validatedData.length, name, email});
                count++;
            }
        }

        if (count === 0){
            // Check that data exists
            this.setState({ error: 'ALL_DUPLICATES', loading: false });
            return;
        }
        const { isValid, error } = this.checkAllRows();

        this.setState({ 
            data: validatedData, 
            loading: false, 
            isValid,
            error,
            duplicates
        });
    }
    onFileDrop([file]){
        // console.log("DROP: ", file);
        if (file.name.indexOf(".csv") < 0){
            this.setState({ error: 'INVALID_FILE' });
            return;
        }

        this.setState({ loading: true, error: false });
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: ({ data }) => {
                console.log("CSV Parsed: ", data);
                if (this.validateCSV(data)){
                    this.addUsers(data);
                }
            }
        });
    }
    onCampusChange({ target }){
        this.setState({ campus: target.value });
    }
    onRowChange(id, key, value){
        const { data, existingEmails, error } = this.state;
        
        data[id][key] = value;
        if (key === "email"){
            this.setState({ 
                data, 
                isValid: this.validateEmail(value),
                error: existingEmails.hasOwnProperty(value) ? 
                'EMAIL_EXISTS' :
                false 
            });
        } else {
            this.setState({ 
                data, 
                error: false 
            });
        }
    }
    onRowAdd(){
        const { data } = this.state;
        const newRow = Object.assign({}, rowModel);
        newRow.id = data.length;
        data.push(newRow);
        this.setState({ data, isValid: false });
    }
    onRowDelete(id){
        const { data } = this.state;
        data.splice(id, 1);

        for (let d=0; d<data.length; d++){
            data[d].id = d;
        }

        const { isValid, error } = this.checkAllRows();
        this.setState({ data, isValid, error });
    }
    onCreate(){
        const { campus, data } = this.state;
        this.setState({ loading: true });

        const { isValid, error } = this.checkAllRows();

        if (isValid){
            const payload = [];
            for (var d=0; d<data.length; d++){
                payload.push({
                    name: data[d].name,
                    email: data[d].email
                });
            }
            this.props.create(campus, payload, (status, error, invalidAdvisors) => {
                if (status === 401) return true;
                if (invalidAdvisors && invalidAdvisors.length > 0){
                    console.error("FAILED TO CREATE ADVISORS: ", invalidAdvisors);
                }
                this.setState({
                    loading: false,
                    data: error ? this.state.data : invalidAdvisors,
                    error: 'FAILED_TO_CREATE'
                });
            });
        } else {
            this.setState({ loading: false, error });
        }
    }
    render(){
        const { close } = this.props;
        const { data, duplicates, error, loading, campus, isValid } = this.state;
        const campuses = AdminUtil.getCampuses();
        const errorParams = error ? error.split('%') : [];
        const errorCode = error ? errorParams[0] : "";
        const errorPayload = {};

        if (errorParams.length > 1){
            errorPayload[errorParams[1]] = errorParams[2];
        }
        const canAddRow = isValid || data.length === 0;
        const errorMsg = error ? 
            i18n.t('ADMIN.UPLOAD_FAILED') + " - " + 
            i18n.t('ERROR.' + errorCode, errorPayload)
        : '';

        return (
            <Modal
                className='NewAdvisor'
                dimmer='blurring'
                open={true}
                closeOnDimmerClick={false}
                onClose={close}
            >
                <Modal.Header>
                    {i18n.t('ADMIN.NEW_ADVISORS')}
                </Modal.Header>
                <Modal.Content>
                    { AdminUtil.showCampuses() && 
                        <div className="campus">
                            <span>{i18n.t('ADMIN_ADVISORS.CAMPUS') + ": "}</span>
                            <select value={campus} onChange={this.onCampusChange}>
                                {Object.keys(campuses).map(id => (
                                    <option key={id} value={id}>
                                        {campuses[id].name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }
                    <div className="prompt">
                        <h2>{i18n.t('ADMIN.CSV_FORMAT_PROMPT')}</h2>
                        <img src={examples[i18n.language]} alt='csvExample' />
                    </div>
                    <Dropzone onDrop={this.onFileDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className="dropZone">
                                <input {...getInputProps()} />
                                { !loading && <>
                                    <div className="icon file">5</div>
                                    <p>{i18n.t('ADMIN.DROP_FILE')}</p>
                                </>}
                                { loading && <Loader active={true} /> }
                            </div>
                        )}
                    </Dropzone>
                    <InteractiveTable editable
                        tableKey={'ADMIN_ADVISORS'}
                        model={AdminUsers.newAdvisor}
                        data={data}
                        icon='8'
                        itemsPerPage={5}
                        sortable={false}
                        validate={this.validateField}
                        onUpdate={this.onRowChange}
                        onDelete={this.onRowDelete}
                    />
                    { duplicates > 0 && 
                        <div className="duplicates">
                            {i18n.t('ADMIN.DUPLICATES', {count: duplicates})}
                        </div>
                    }
                    <h3>{i18n.t('ADMIN.UPLOAD_COUNT', {count: data.length})}</h3>
                    <div className="addBar">
                        <RippleButton
                            className="addRow"
                            fillFrom="center"
                            icon=")"
                            flat={true}
                            disabled={!canAddRow}
                            text={i18n.t("ADMIN.ADD_ROW")}
                            onClick={this.onRowAdd}
                        /> 
                    </div>
                    { error && 
                        <Message 
                            error
                            content={errorMsg}
                        />
                    }
                </Modal.Content>
                <Modal.Actions>
                    <RippleButton
                        empty flat
                        text={i18n.t('APP.CANCEL')}
                        onClick={close}
                    />
                    <Button
                        positive
                        disabled={!isValid}
                        loading={loading}
                        content={i18n.t('APP.CREATE')}
                        onClick={this.onCreate}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

function getEmailMap(){
    // return {};
    const idMap = AdminUtil.getAdvisors();
    const emailMap = {};
    for (var id in idMap){
        const advisor = idMap[id];
        emailMap[advisor.email] = true;
    }
    return emailMap;
}
