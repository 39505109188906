import React, { Component } from "react";
import { Pagination, message, Popconfirm, Button, Row } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import UUID from "uuid";
import Enroll from "./Enroll";
import More from "./More";
import DataDict from "./DataDict";
import _ from "lodash";
import moment from "moment";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";

class Trackadmission extends Component {
    state = {
        data: [],
        renderdata: {},
        currentPage: 1,
        total: 0,
        error: false,
        errorCode: "",
        loading: true,
        pageSize: 10,
        local: {
            emptyText: " ",
        },
        sorter: {},
        datadict: {
            interviewStatus: DataDict.getMap(
                "moblab:trackadmissionrecord:interviewStatus"
            ),
            result: DataDict.getMap("erp:trackadmission:result"),
            fillResult: DataDict.getMap("moblab:trackadmissionrecord:result"),
            applyType: DataDict.getMap("moblab:academyinfo:applyType"),
        },
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/academyinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    renderRedRow = (row) => {
        return row["pullSchool"] &&
            !row["selectSchoolID"] &&
            row["academyinfoId"]
            ? "redRow"
            : "normal";
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    renderDataSource = (datasource) => {
        let ds = datasource.data;
        let datasourcetmp = [];
        let renderdata = {};
        let deleteData = {};
        for (let i = 0; i < ds.length; i++) {
            let data = ds[i];
            let academyinfo = data["academyinfo"];
            for (let j = 0; j < academyinfo.length; j++) {
                deleteData[data["contractId"]] = deleteData[data["contractId"]]
                    ? true
                    : data["pullSchool"] && !academyinfo[j]["selectSchoolID"];
                if (academyinfo[j]["trackadmission"]) {
                    academyinfo[j]["trackadmission"][
                        "interviewStatus"
                    ] = this.state.datadict.interviewStatus[
                        academyinfo[j]["trackadmission"]["interviewStatus"]
                    ];
                    academyinfo[j]["trackadmission"][
                        "result"
                    ] = this.state.datadict.result[
                        academyinfo[j]["trackadmission"]["result"]
                    ];
                    academyinfo[j]["trackadmission"][
                        "fillResult"
                    ] = this.state.datadict.fillResult[
                        academyinfo[j]["trackadmission"]["fillResult"]
                    ];
                    academyinfo[j]["trackadmission"][
                        "offerEndDate"
                    ] = academyinfo[j]["trackadmission"]["offerEndDate"]
                        ? moment(
                              academyinfo[j]["trackadmission"]["offerEndDate"]
                          )
                        : null;
                }
                let trackadmission = academyinfo[j]["trackadmission"]
                    ? academyinfo[j]["trackadmission"]
                    : {};
                trackadmission["lastQueryDate"] = trackadmission[
                    "lastQueryDate"
                ]
                    ? moment(trackadmission["lastQueryDate"])
                    : null;
                trackadmission["endDate"] = trackadmission["endDate"]
                    ? moment(trackadmission["endDate"])
                    : null;
                trackadmission["offerEndDate"] = trackadmission["offerEndDate"]
                    ? moment(trackadmission["offerEndDate"])
                    : null;
                academyinfo[j]["schoolRank"] = _.isEmpty(
                    academyinfo[j]["schoolId"]
                )
                    ? ""
                    : DataDict.getMap("moblab:school:rank")[
                          academyinfo[j]["schoolId"]
                      ];
                const archiveName = _.isEmpty(academyinfo[j]["archive"])
                    ? DataDict.getMap("moblab:academyinfo:archive")["0"]
                    : DataDict.getMap("moblab:academyinfo:archive")[
                          academyinfo[j]["archive"]
                      ];
                datasourcetmp.push({
                    isLastItemOfGroup: j === academyinfo.length - 1,
                    ...academyinfo[j],
                    ...data,
                    key: academyinfo[j]["_id"],
                    number: i + 1,
                    trackadmission: trackadmission,
                    archive: archiveName,
                    studentId: data["_id"],
                    academyinfoId: academyinfo[j]["_id"],
                    name: data["name"],
                    academyinfoName: academyinfo[j]["name"],
                    schoolRank: academyinfo[j]["schoolRank"],
                    overdate: academyinfo[j]["overdate"]
                        ? moment(academyinfo[j]["overdate"])
                        : null,
                });
            }
            if (academyinfo.length === 0) {
                datasourcetmp.push({
                    isLastItemOfGroup: true,
                    ...data,
                    name: data["name"],
                    key: UUID.v1(),
                    studentId: data["_id"],
                });
            }
            renderdata[data["contractId"]] = {
                count: academyinfo.length === 0 ? 1 : academyinfo.length,
                used: false,
            };
        }

        _.each(datasourcetmp, (v) => {
            let _render = renderdata[v.contractId];
            if (_render && !_render.used) {
                v.rowSpan = _render.count;
                _render.used = true;
            } else {
                v.rowSpan = 0;
            }
        });

        this.setState({
            data: datasourcetmp,
            currentPaged: datasource.currentPage,
            total: datasource.total,
            renderdata: renderdata,
            loading: false,
            deleteData: deleteData,
        });
    };
    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        const columns = [
            {
                className: "column-apply-status cross-row-td",
                title: "申请状态",
                dataIndex: "applyStatus",
                align: "center",
                render: (statusCode, row, index) => {
                    let tag = (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["name"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );

                    const obj = {
                        children: tag,
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                width: "125px",
                sorter: true,
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <StudentLink
                                studentName={value}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "院校名称",
                dataIndex: "academyinfoName",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "300px",
                fixed: "left",
            },
            {
                title: "院校排名",
                dataIndex: "schoolRank",
                width: "80px",
            },
            {
                title: "面试情况",
                dataIndex: ["trackadmission", "interviewStatus"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.interviewStatus, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.interviewStatus":
                                    record["trackadmission"]["interviewStatus"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "120px",
            },
            {
                title: "申请类型",
                dataIndex: "applyType",
                render: (value, row, index) => {
                    const obj = {
                        children: this.state.datadict.applyType[value],
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "110px",
            },
            {
                title: "截止日期",
                dataIndex: "overdate",
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "140px",
            },
            {
                title: "查询网址",
                dataIndex: ["trackadmission", "queryWebsite"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.queryWebsite":
                                    record["trackadmission"]["queryWebsite"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "查询账号",
                dataIndex: ["trackadmission", "queryAccount"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.queryAccount":
                                    record["trackadmission"]["queryAccount"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "查询密码",
                dataIndex: ["trackadmission", "queryPwd"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.queryPwd":
                                    record["trackadmission"]["queryPwd"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "查询ID",
                dataIndex: ["trackadmission", "queryId"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.queryId":
                                    record["trackadmission"]["queryId"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "最后查询日期",
                dataIndex: ["trackadmission", "lastQueryDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                datepicker: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.lastQueryDate":
                                    record["trackadmission"]["lastQueryDate"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "录取结果",
                dataIndex: ["trackadmission", "fillResult"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                dropdown: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.fillResult, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.fillResult":
                                    record["trackadmission"]["fillResult"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "140px",
            },
            {
                title: "结果日期",
                dataIndex: ["trackadmission", "endDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                datepicker: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.endDate":
                                    record["trackadmission"]["endDate"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "接受Offer截止日期",
                dataIndex: ["trackadmission", "offerEndDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : hiddenDIV,
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                datepicker: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    if (isNaN(record["trackadmission"]["offerEndDate"])) {
                        return;
                    }
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.offerEndDate":
                                    record["trackadmission"]["offerEndDate"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "140px",
            },
            {
                title: "奖学金金额",
                dataIndex: ["trackadmission", "scholarship"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    if (isNaN(record["trackadmission"]["scholarship"])) {
                        return;
                    }
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.scholarship":
                                    record["trackadmission"]["scholarship"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "125px",
            },
            {
                title: "备注",
                dataIndex: ["trackadmission", "remark"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = this.renderRedRow(row);
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    Request.POST("academyinfo", {
                        data: {
                            update: {
                                "trackadmission.remark":
                                    record["trackadmission"]["remark"],
                            },
                            id: record["key"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "300px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <OperationButtons
                                studentName={row["name"]}
                                contractName={row["contractName"]}
                                contractStatus={row["contractStatus"]}
                                contractNo={row["contractNo"]}
                            >
                                <Row
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    {this.state.deleteData[
                                        row["contractId"]
                                    ] ? (
                                        <Popconfirm
                                            title="请确认是否删除冗余选校?"
                                            onConfirm={() => {
                                                Request.DELETE(
                                                    "excel/academyinfo/contractinfo/" +
                                                        row["contractId"],
                                                    {
                                                        onSuccess: (data) => {
                                                            this.fetchData();
                                                        },
                                                        onFail: (status) => {
                                                            if (status === 401)
                                                                return true;
                                                            message.error(
                                                                "删除失败"
                                                            );
                                                            this.fetchData();
                                                        },
                                                    }
                                                );
                                            }}
                                            okButtonProps={{
                                                danger: true,
                                            }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button
                                                type="link"
                                                style={{
                                                    padding: "0px",
                                                    margin: "0px",
                                                    height: "20px",
                                                }}
                                            >
                                                删除冗余
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                    <Enroll
                                        studentId={row["studentId"]}
                                        studentName={row["name"]}
                                        applyStatus={row["applyStatus"]}
                                        fetchData={this.fetchData}
                                        contractId={row["contractId"]}
                                        render={true}
                                    />
                                    <More
                                        studentId={row["studentId"]}
                                        studentName={row["name"]}
                                        applyStatus={row["applyStatus"]}
                                        applyAdvisor={row["applyAdvisorName"]}
                                        contractId={row["contractId"]}
                                        fetchData={this.fetchData}
                                    />
                                </Row>
                            </OperationButtons>
                        ),
                        props: {},
                    };
                    obj.props.className = "nopadding";
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                fixed: "right",
                width: "200px",
                align: "center",
            },
        ];

        return (
            <>
                <Editable
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: "3200px", y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </>
        );
    }
}
export default Trackadmission;
