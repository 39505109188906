import React from "react";
import SubSection from "../SubSection";
import TierSummary from "./TierSummary";

const PartTier = ({ reportData, partOrder, part }) => {
    return (
        <React.Fragment>
            <SubSection
                title={part.title}
                partKey={part.key}
                sectionNo={partOrder}
            />
            <TierSummary
                tier={reportData.student.assigned_tier}
                scores={reportData.scores}
                title={part.title}
            />
        </React.Fragment>
    );
};

export default PartTier;
