import React, { Component } from "react";
import { RadialPercent } from "../";
import Card from "../Card";
import { i18n } from "../../lib";
import { rem, formatTitleCase } from "../../lib/utils";
import datastore from "../../data/store";

const sections = ["SUB_MAJORS", "CAREERS"];

class MajorInfo extends Component {
    state = {
        open: false,
        details: null,
    };
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
    }
    getItems(params, key) {
        return params[key.toLowerCase()][i18n.language].split(/\,\s*/);
    }
    toggle() {
        this.props.onToggle(this, this.state.open);
        this.setState({ open: !this.state.open });
    }
    t(val) {
        return i18n.t("MAJOR." + val.toUpperCase());
    }
    render() {
        const { major, fit, className } = this.props;
        const { open } = this.state;
        const details = datastore.get("MAJOR_DETAILS")[major];
        let cardClass = open ? "open" : "";
        cardClass = cardClass + " " + className;

        return (
            <Card type="major_info" className={cardClass} onClick={this.toggle}>
                <div className="overview">
                    <div className="left_section">
                        <h1>{details.name[i18n.language]}</h1>
                        <p>{details.desc[i18n.language]}</p>
                    </div>
                    <div className="right_section">
                        <RadialPercent
                            value={fit}
                            r={rem(2.5)}
                            color={getColor(Math.min(fit + 0.25, 1))}
                        />
                    </div>
                </div>
                {details && (
                    <div className="details">
                        <div className="left_section">
                            {sections.map((sectionKey) => (
                                <div key={sectionKey}>
                                    <h2>{this.t("SECTION_" + sectionKey)}</h2>
                                    <div className="items">
                                        {this.getItems(details, sectionKey).map(
                                            (name) => (
                                                <div
                                                    className="item"
                                                    key={name}
                                                >
                                                    {formatTitleCase(name)}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="divider" />
                        <div className="right_section">
                            <RadialPercent
                                header={this.t("GRADUATE_DEGREE") + ":"}
                                value={details.grad_rate}
                                r={rem(2)}
                                color={getColor(details.grad_rate)}
                            />
                            <h3>{this.t("MEDIAN_INCOME") + ":"}</h3>
                            <p className="income">{details.avg_salary}</p>
                        </div>
                    </div>
                )}
            </Card>
        );
    }
}

function getColor(percent) {
    const colors = ["#EB5BA3", "#FD9D2C", "#00BFD6"];
    return colors[Math.min(Math.floor(percent * 3), 2)];
}

export default MajorInfo;
