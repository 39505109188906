import React from "react";
import { Progress } from "antd";
import propTypes from "prop-types";
import styled from "styled-components";
import utils from "common/utils";

function ProgressPlus({ value, width = 60, color }) {
    const circleColor =
        color ||
        utils.getScoreColor(
            Math.round(value * 100),
            30, //lowerScoreThreshold
            70 //upperScoreThreshold
        );

    return (
        <StyledContainer fontColor={circleColor}>
            <Progress
                // success={{ percent: 100, strokeColor: "#52c41a" }}
                type="circle"
                percent={Math.round(value * 100)}
                width={width}
                format={(percent) => percent + "%"}
                strokeColor={circleColor}
                trailColor="rgba(16,32,55, 0.16)"
            />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "progress-plus",
})`
    .ant-progress-text {
        font-weight: bold;
        color: ${({ fontColor }) => fontColor};
    }
`;

ProgressPlus.propTypes = {
    value: propTypes.number.isRequired,
    width: propTypes.number,
};

export default ProgressPlus;
