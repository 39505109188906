import React from "react";
import Tab from "./Tab";

const tableConfigs = [
    {
        key: "queryStatus",
        firstColumnText: "状态查询进度",
        path: "trackadmission/queryStatus",
    },
    {
        key: "statusIntegrity",
        firstColumnText: "状态是否完整",
        path: "trackadmission/statusIntegrity",
    },
    {
        key: "fillResult",
        firstColumnText: "录取跟踪进度",
        path: "trackadmission/fillResult",
    },
];
function TrackingTab() {
    return <Tab tableConfigs={tableConfigs} />;
}

export default TrackingTab;
