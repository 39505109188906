import _ from "lodash";
import { Request } from "..";
import { i18n } from "../../lib";
import datastore from "../../data/store";
import { SchoolFilters } from "../../data/model";
// import schoolMock from "data/test/school";
const PROPS = getRequiredSchoolPropNames();

/**
 * Note Util
 *
 * Handle all note related actions, including server requests.
 */
const SchoolUtil = {
    /**
     * Check if there are any ids in the student's school recommendations
     * that are not in the local cache, and request these schools' details
     * from the server.
     *
     * @param {Object} fitData      map of ids to fit value
     * @param {Function} callback   onComplete callback
     */
    getMissingSchoolData(fitData, callback) {
        const schoolRegistry = datastore.get("SCHOOLS");
        var missingIds = "";
        for (var id in fitData) {
            if (!schoolRegistry.hasOwnProperty(id)) {
                if (missingIds !== "") {
                    missingIds += "+";
                }
                missingIds += id;
            }
        }
        if (missingIds === "") {
            callback();
            return;
        }
        // console.log("GET MISSING SCHOOLS: ", missingIds, PROPS);
        Request.GET("school/" + missingIds + "/" + PROPS, {
            onSuccess: ({ schools }) => {
                // let { schools } = schoolMock;
                const schoolRegistry = datastore.get("SCHOOLS");
                for (var s = 0; s < schools.length; s++) {
                    const school = schools[s];
                    school.RANK =
                        school.USNEWS_RANK_LIBARTS_2019 || school.MOBLAB_RANK;
                    schoolRegistry[school.UNITID] = school;
                }
                // console.log("SCHOOLS: ", schoolRegistry);
                callback();
            },
        });
    },
    /**
     * Get the detailed school dataset with all fields
     */
    getDataset() {
        return datastore.get("SCHOOLS");
    },
    /**
     * Get the detailed data for a specific school, by its UNITID
     *
     * @param {String} schoolId School UNITID
     */
    getSchoolData(schoolId) {
        const schoolRegistry = datastore.get("SCHOOLS");
        return schoolRegistry[schoolId];
    },
    /**
     * Get all school recommendation filters with translated labels
     */
    getFilters() {
        var filters = datastore.get("SCHOOL_FILTERS");
        if (filters.length > 0) {
            return filters;
        }

        filters = SchoolFilters.map(({ name, filters }) => ({
            name: i18n.t("SCHOOLS.SECTION_" + name),
            filters: filters.map((filter) => {
                const { label, options } = filter;
                const newFilter = {
                    key: filter.label,
                };
                for (var key in filter) {
                    if (key === "label") {
                        newFilter.label = i18n.t("SCHOOLS.LABEL_" + label);
                    } else if (key === "options") {
                        newFilter.options = options.map(({ key, value }) => ({
                            key: key,
                            text:
                                label === "LOCATION"
                                    ? key
                                    : i18n.t("SCHOOLS.OPTION_" + key),
                            value: value,
                        }));
                    } else {
                        newFilter[key] = filter[key];
                    }
                }
                return newFilter;
            }),
        }));

        datastore.set("SCHOOL_FILTERS", filters);
        return filters;
    },

    // NO-1403 Please update the order of college display – College Ranking first, then match percentage
    // [{id: "197708", fit: 0.6100689278781866}]
    sortSchool(data) {
        const copiedData = _.cloneDeep(data);

        copiedData.sort(function (a, b) {
            const dataA = SchoolUtil.getSchoolData(a.id);
            const dataB = SchoolUtil.getSchoolData(b.id);
            if (dataA.MOBLAB_RANK < dataB.MOBLAB_RANK) {
                return -1;
            } else if (dataA.MOBLAB_RANK > dataB.MOBLAB_RANK) {
                return 1;
            } else if (dataA.MOBLAB_RANK === dataB.MOBLAB_RANK) {
                if (a.fit > b.fit) {
                    return -1;
                } else if (a.fit < b.fit) {
                    return 1;
                } else if (a.fit === b.fit) {
                    return 0;
                }
            }

            return 0;
        });

        return copiedData;
    },
};

/**
 * Loop through SchoolFilters and extract all keys into a single props string
 *
 * FORMAT is `"{prop}+{prop}+{prop}"`
 */
function getRequiredSchoolPropNames() {
    return SchoolFilters.reduce(
        (s, { filters }) =>
            s +
            filters.reduce(
                (f, { keys }) => f + keys.reduce((k, key) => k + "+" + key, ""),
                ""
            ),
        "UNITID+CITY+MOBLAB_RANK"
    );
}

export default SchoolUtil;
