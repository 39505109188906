import React from "react";
import Tab from "./Tab";

const tableConfigs = [
    {
        key: "interviewprogress",
        firstColumnText: "面谈进度",
        path: "applyprogress/interviewprogress",
    },
    {
        key: "commonacount",
        firstColumnText: "Common注册",
        path: "applyprogress/commonacount",
    },
    {
        key: "resumeprogress",
        firstColumnText: "简历进度",
        path: "applyprogress/resumeprogress",
    },
    {
        key: "recommendprogress",
        firstColumnText: "推荐信写作进度",
        path: "applyprogress/recommendprogress",
        totalText: "推荐信总量",
    },
    {
        key: "maintextprogress",
        firstColumnText: "主文进度",
        path: "applyprogress/maintextprogress",
    },
    {
        key: "docprogress",
        firstColumnText: "附表文书进度",
        path: "applyprogress/docprogress",
    },

    {
        key: "submitprogress",
        firstColumnText: "院校提交进度",
        path: "applyprogress/submitprogress",
        totalText: "申请数量",
    },
    {
        key: "satactgivescore",
        firstColumnText: "SAT/ACT送分",
        path: "applyprogress/satactgivescore",
    },
    {
        key: "languagegivescore",
        firstColumnText: "语言成绩送分进度",
        path: "applyprogress/languagegivescore",
    },
    {
        key: "collectfileprogress",
        firstColumnText: "收集文件进度",
        path: "applyprogress/collectfileprogress",
    },
];
function ApplyTab() {
    return <Tab tableConfigs={tableConfigs} />;
}

export default ApplyTab;
