import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import visionOverseas from "img/report/logos/vision-overseas.png";
import logoImg from "img/report/logos/ml-logo.svg";

function ReportHeader({ title }) {
    return (
        <StyledContainer>
            <img src={visionOverseas} alt="" className="vision-logo" />
            {/* <div className="logo"></div> */}
            <span className="section-title">{title}</span>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "section-header",
})`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .vision-logo {
        height: 20px;
        margin-right: 10px;
        margin-left: 20px;
    }

    .logo {
        height: 40px;
        width: 116px;
        background-size: cover;
        background-image: url(${logoImg});
    }
    .section-title {
        color: #838c96;
        font-size: 14px;
        font-weight: bold;
    }
`;

ReportHeader.propTypes = {
    title: propTypes.string.isRequired,
};

export default ReportHeader;
