import 中学教师 from "img/report/careers2/中学教师.jpg";
import 临床统计师 from "img/report/careers2/临床统计师.jpg";
import 临床试验项目经理 from "img/report/careers2/临床试验项目经理.jpg";
import 乐团指挥 from "img/report/careers2/乐团指挥.jpg";
import 产业研究员 from "img/report/careers2/产业研究员.jpg";
import 人力资源总监 from "img/report/careers2/人力资源总监.jpg";
import 人工智能训练师 from "img/report/careers2/人工智能训练师.jpg";
import 企业法务经理 from "img/report/careers2/企业法务经理.jpg";
import 保险精算师 from "img/report/careers2/保险精算师.jpg";
import 光学工程师 from "img/report/careers2/光学工程师.jpg";
import 公共关系经理 from "img/report/careers2/公共关系经理.jpg";
import 公共卫生医师 from "img/report/careers2/公共卫生医师.jpg";
import 公证员 from "img/report/careers2/公证员.jpg";
import 内科医师 from "img/report/careers2/内科医师.jpg";
import 分子生物科学家 from "img/report/careers2/分子生物科学家.jpg";
import 办公室主任 from "img/report/careers2/办公室主任.jpg";
import 动力学仿真工程师 from "img/report/careers2/动力学仿真工程师.jpg";
import 化学分析员 from "img/report/careers2/化学分析员.jpg";
import 医学图像算法工程师 from "img/report/careers2/医学图像算法工程师.jpg";
import 博物馆馆长 from "img/report/careers2/博物馆馆长.jpg";
import 历史建筑保护规划设计师 from "img/report/careers2/历史建筑保护规划设计师.jpg";
import 品牌经理 from "img/report/careers2/品牌经理.jpg";
import 商业分析师 from "img/report/careers2/商业分析师.jpg";
import 商务外语翻译 from "img/report/careers2/商务外语翻译.jpg";
import 国际商务经理 from "img/report/careers2/国际商务经理.jpg";
import 地理信息系统工程师 from "img/report/careers2/地理信息系统工程师.jpg";
import 地质工程师 from "img/report/careers2/地质工程师.jpg";
import 城市规划设计师 from "img/report/careers2/城市规划设计师.jpg";
import 基金经理 from "img/report/careers2/基金经理.jpg";
import 外交领事 from "img/report/careers2/外交领事.png";
import 外科医师 from "img/report/careers2/外科医师.jpg";
import 外科护士 from "img/report/careers2/外科护士.jpg";
import 外贸经理 from "img/report/careers2/外贸经理.jpg";
import 大气污染防治工程师 from "img/report/careers2/大气污染防治工程师.jpg";
import 学前教育教研员 from "img/report/careers2/学前教育教研员.jpg";
import 审计经理 from "img/report/careers2/审计经理.jpg";
import 对外投资分析师 from "img/report/careers2/对外投资分析师.jpg";
import 导演 from "img/report/careers2/导演.jpg";
import 小学教师 from "img/report/careers2/小学教师.jpg";
import 市场总监 from "img/report/careers2/市场总监.jpg";
import 市场调查研究员 from "img/report/careers2/市场调查研究员.jpg";
import 平面设计师 from "img/report/careers2/平面设计师.jpg";
import 广告策划师 from "img/report/careers2/广告策划师.jpg";
import 建筑结构工程师 from "img/report/careers2/建筑结构工程师.jpg";
import 建筑设计师 from "img/report/careers2/建筑设计师.jpg";
import 律师 from "img/report/careers2/律师.jpg";
import 微生物研究员 from "img/report/careers2/微生物研究员.jpg";
import 总经理 from "img/report/careers2/总经理.jpg";
import 投融资经理 from "img/report/careers2/投融资经理.jpg";
import 放射科医师 from "img/report/careers2/放射科医师.jpg";
import 教育图书策划 from "img/report/careers2/教育图书策划.jpg";
import 数据库工程师 from "img/report/careers2/数据库工程师.jpg";
import 数据科学家 from "img/report/careers2/数据科学家.jpg";
import 文物修复师 from "img/report/careers2/文物修复师.jpg";
import 新闻记者 from "img/report/careers2/新闻记者.jpg";
import 无人机设计工程师 from "img/report/careers2/无人机设计工程师.jpg";
import 智慧建筑研发工程师 from "img/report/careers2/智慧建筑研发工程师.jpg";
import 机械工程师 from "img/report/careers2/机械工程师.jpg";
import 检察官 from "img/report/careers2/检察官.jpg";
import 水生态工程师 from "img/report/careers2/水生态工程师.jpg";
import 注册会计师 from "img/report/careers2/注册会计师.jpg";
import 注册环评工程师 from "img/report/careers2/注册环评工程师.jpg";
import 注册资产评估师 from "img/report/careers2/注册资产评估师.jpg";
import 海洋生态检测工程师 from "img/report/careers2/海洋生态检测工程师.jpg";
import 特殊教育教师 from "img/report/careers2/特殊教育教师.jpg";
import 生物信息工程师 from "img/report/careers2/生物信息工程师.jpg";
import 生物医学工程师 from "img/report/careers2/生物医学工程师.jpg";
import 生物技术工程师 from "img/report/careers2/生物技术工程师.jpg";
import 生物技术销售工程师 from "img/report/careers2/生物技术销售工程师.jpg";
import 用户研究工程师 from "img/report/careers2/用户研究工程师.jpg";
import 电影电视演员 from "img/report/careers2/电影电视演员.jpg";
import 电气工程师 from "img/report/careers2/电气工程师.jpg";
import 社会工作者 from "img/report/careers2/社会工作者.jpg";
import 社科图书编辑 from "img/report/careers2/社科图书编辑.jpg";
import 私人银行理财顾问 from "img/report/careers2/私人银行理财顾问.jpg";
import 科技辅导教师 from "img/report/careers2/科技辅导教师.jpg";
import 税务经理 from "img/report/careers2/税务经理.jpg";
import 管理咨询顾问 from "img/report/careers2/管理咨询顾问.jpg";
import 绿色建筑咨询工程师 from "img/report/careers2/绿色建筑咨询工程师.jpg";
import 网页设计师 from "img/report/careers2/网页设计师.jpg";
import 美术编辑 from "img/report/careers2/美术编辑.jpg";
import 考古技术人员 from "img/report/careers2/考古技术人员.jpg";
import 舆情分析师 from "img/report/careers2/舆情分析师 .jpg";
import 艺术策展人 from "img/report/careers2/艺术策展人.jpg";
import 药品生产车间主任 from "img/report/careers2/药品生产车间主任.jpg";
import 药师 from "img/report/careers2/药师.jpg";
import 计算天文学研究员 from "img/report/careers2/计算天文学研究员.jpg";
import 课程顾问 from "img/report/careers2/课程顾问.jpg";
import 财务总监 from "img/report/careers2/财务总监.jpg";
import 软件工程师 from "img/report/careers2/软件工程师.jpg";
import 音乐制作人 from "img/report/careers2/音乐制作人.jpg";
import 风景园林设计师 from "img/report/careers2/风景园林设计师.jpg";
import 高分子材料科学家 from "img/report/careers2/高分子材料科学家.jpg";
import 高校教师 from "img/report/careers2/高校教师.jpg";
import 麻醉科医师 from "img/report/careers2/麻醉科医师.jpg";

const data = {
    中学教师,
    临床统计师,
    临床试验项目经理,
    乐团指挥,
    产业研究员,
    人力资源总监,
    人工智能训练师,
    企业法务经理,
    保险精算师,
    光学工程师,
    公共关系经理,
    公共卫生医师,
    公证员,
    内科医师,
    分子生物科学家,
    办公室主任,
    动力学仿真工程师,
    化学分析员,
    医学图像算法工程师,
    博物馆馆长,
    历史建筑保护规划设计师,
    品牌经理,
    商业分析师,
    商务外语翻译,
    国际商务经理,
    地理信息系统工程师,
    地质工程师,
    城市规划设计师,
    基金经理,
    外交领事,
    外科医师,
    外科护士,
    外贸经理,
    大气污染防治工程师,
    学前教育教研员,
    审计经理,
    对外投资分析师,
    导演,
    小学教师,
    市场总监,
    市场调查研究员,
    平面设计师,
    广告策划师,
    建筑结构工程师,
    建筑设计师,
    律师,
    微生物研究员,
    总经理,
    投融资经理,
    放射科医师,
    教育图书策划,
    数据库工程师,
    数据科学家,
    文物修复师,
    新闻记者,
    无人机设计工程师,
    智慧建筑研发工程师,
    机械工程师,
    检察官,
    水生态工程师,
    注册会计师,
    注册环评工程师,
    注册资产评估师,
    海洋生态检测工程师,
    特殊教育教师,
    生物信息工程师,
    生物医学工程师,
    生物技术工程师,
    生物技术销售工程师,
    用户研究工程师,
    电影电视演员,
    电气工程师,
    社会工作者,
    社科图书编辑,
    私人银行理财顾问,
    科技辅导教师,
    税务经理,
    管理咨询顾问,
    绿色建筑咨询工程师,
    网页设计师,
    美术编辑,
    考古技术人员,
    舆情分析师,
    艺术策展人,
    药品生产车间主任,
    药师,
    计算天文学研究员,
    课程顾问,
    财务总监,
    软件工程师,
    音乐制作人,
    风景园林设计师,
    高分子材料科学家,
    高校教师,
    麻醉科医师,
};

export default data;
