import React from "react";
import _ from "lodash";
import Card from "../Card";
import { i18n } from "../../lib";
import { ColorGenerator, rem } from "../../lib/utils";
import { StatTooltip } from "../../components";
import { StudentUtil } from "lib/utils";
import {
    ResponsiveContainer,
    LineChart,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Line,
} from "recharts";

const colors = new ColorGenerator({
    colors: ["TEAL", "BLUE", "PURPLE", "PINK", "ORANGE"],
});

class Statistic extends React.Component {
    state = {};
    constructor(props) {
        super(props);
    }
    getMax() {
        const { series } = this.props;
        var max = 0;
        for (var s = 0; s < series.length; s++) {
            const xSlice = series[s];
            for (var key in xSlice) {
                var val = xSlice[key];
                if (!isNaN(val)) {
                    max = Math.max(max, val);
                }
            }
        }
        return Math.ceil(max / 4) * 4;
    }
    getKeys(data) {
        if (!data || data.length === 0) {
            return [];
        }
        const knownKeys = {};
        const keys = [];
        for (let d = 0; d < data.length; d++) {
            const sample = data[d];
            for (let key in sample) {
                if (knownKeys[key]) {
                    continue;
                }
                knownKeys[key] = true;
                let val = parseFloat(sample[key]);
                if (!isNaN(val) && key !== "label") {
                    keys.push(key);
                }
            }
        }
        return keys;
    }
    render() {
        const { series, dataKeys, value, formatKey } = this.props;
        const useValue = this.props.hasOwnProperty("value");
        const keys = dataKeys || this.getKeys(series);
        const tSection = formatKey || "STATS";

        return (
            <Card {...this.props} type="Statistic">
                {useValue && <div className="StatVal">{value}</div>}
                {!useValue && (
                    <ResponsiveContainer width="90%" height={rem(15)}>
                        <LineChart
                            data={series}
                            margin={{ left: rem(0.5), right: rem(1.5) }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis dataKey="label" />
                            <YAxis
                                domain={[0, this.getMax()]}
                                // allowDecimals={false}
                            />
                            <Tooltip content={<StatTooltip />} />
                            {keys.length > 1 && (
                                <Legend
                                    verticalAlign="top"
                                    align="left"
                                    iconType="circle"
                                    height={rem(3)}
                                    margin={{
                                        left: rem(2),
                                    }}
                                    formatter={(val) => {
                                        if (val === "total") {
                                            return "总数";
                                        }
                                        return StudentUtil.getTierName(
                                            _.last(val)
                                        );
                                    }}
                                />
                            )}
                            {keys.map((key, i) => (
                                <Line
                                    key={key}
                                    dataKey={key}
                                    dot={false}
                                    stackId="a"
                                    stroke={colors.get(i)}
                                    animationDuration={600}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Card>
        );
    }
}

export default Statistic;
