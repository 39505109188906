import React, { Component } from 'react'
import Card from '../Card'
import i18n from "../../lib/i18n/i18n"

const rowStyle = {
    // width: 'calc(100% - 2rem)',
    borderBottom: 'solid 1px rgba(233, 241, 245, 1)',
    padding: '0.5rem 1rem 0.5rem 1rem',
    margin: '0 1rem 0 1rem',
    fontSize: '1.125rem',
}
const labelStyle = {
    display: 'inline-block',
    color: 'rgba(71, 82, 93, 1)',
    marginRight: '1rem'
}
const valStyle = {
    display: 'inline-block',
    float: 'right',
    color: 'rgba(147, 91, 142, 1)'
}
class ScoresOverview extends Component {
    render() {
        return (
            <Card {...this.props} type="scores_overview">
               { this.props.scores.map(({key, value}) => (
                   <div style={rowStyle} key={key}>
                       <div style={labelStyle}>{i18n.t('SCORES.' + key.toUpperCase())}</div>
                       <div style={valStyle}>{value}</div>
                   </div>
               ))}
            </Card>
        )
    }
}

export default ScoresOverview;
