import React, { Component } from "react";

/**
 * props结构说明
 * props{
 *  data{
 *      choosestatus:[选校状态]
 *      applyStatus:[申请状态]
 *      name:[学生姓名]
 *      schools:[][选校]
 *  }
 *  visible:[是否显示],
 *  fetchData:function[更新状态后刷新数据]
 * }
 */

class BindItem extends Component {
    render() {
        return <>
            <div style={{ width: '100%' }}>
                <span style={{ color: '#A1A8B3', fontSize: "16px" }}>{this.props.title}</span>
            </div>
            <br />
            <div style={{ width: '100%' }}>
                <span style={{
                    borderBottom: '0px solid  rgba(34, 36, 38, 0.1)', width: '240px', height: this.props.height ? this.props.height : '36px', fontSize: '14px',
                    WebkitBoxOrient: 'vertical', WebkitLineClamp: '3', display: '-webkit-box', overflow: 'hidden',
                }} title={this.props.value}>
                    {this.props.value ? this.props.value : null}
                </span>
            </div>
        </>
    }
}
export default BindItem;
