import React, { Component } from 'react'
import styled from 'styled-components'
import { Slider, Rail, Handles } from 'react-compound-slider'
import i18n from "../../lib/i18n/i18n"
import thumb from "../../img/slider_thumb.svg"

const sliderStyle = {
  position: 'relative',
  marginTop: '11px'
};

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 20,
  borderRadius: 10,
  backgroundImage: 'linear-gradient(90deg, #A5EEFF, #62C9FF)'
};

const domain = [-3, 103];


function Handle(props) {
    const { handle, getHandleProps } = props;
    return (
        <img
            src={thumb}
            alt=''
            style={{
                width: 16,
                height: 21,
                marginLeft: '-8px',     // half of width
                marginTop: '-3px',
                zIndex: 2,
                left: `${handle.percent}%`,
                position: 'absolute'
                // transition: 'left 1s',
                // transitionTimingFunction: 'ease'
            }}
            {...getHandleProps(handle.id)}
        />
    )
}

const Labels = styled.div`
    color: #A1A8B3;
    width: 100%;
    display: flex;
    font-size: 0.9em;
`

class TraitSlider extends Component {
    state={
        value: 50
    }
    componentDidMount() {

        this.setState({ value: this.props.value });
    }

    render() {
        return (
        <div style={{ width: '100%' }}>
            <Labels>
                <span>{i18n.t('TRAIT_SLIDER.LOW')}</span>
                <span style={{ flex: 1, textAlign: 'center' }}>{i18n.t('TRAIT_SLIDER.MED')}</span>
                <span>{i18n.t('TRAIT_SLIDER.HIGH')}</span>
            </Labels>
            <Slider
                disabled={true}
                mode={1}
                step={1}
                domain={domain}
                rootStyle={sliderStyle}
                values={[ this.state.value ]}
            >
            <Rail>
                {({ getRailProps }) => (
                <div style={railStyle} {...getRailProps()} />
                )}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                    {handles.map(handle => (
                    <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                    />
                    ))}
                </div>
                )}
            </Handles>
            </Slider>
        </div>
        );
    }
}

export default TraitSlider;
