import moment from "moment";

function formatDate(originValue, formatString = "YYYY-MM-DD") {
    if (!originValue) {
        return "";
    }

    return moment(originValue).format(formatString);
}

export default {
    formatDate,
};
