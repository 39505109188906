import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import reportContext from "./reportContext";
import utils from "common/utils";
import GaokaoReport from "./gaokao/GaokaoReport";
import OverseasReport from "./overseas/OverseasReport";
import "./print.scss";

function ReportContainer() {
    const [reportData, setReportData] = useState();

    const [aboutPageNo, setAboutPageNo] = useState(0);
    const [traitPageNo, setTraitPageNo] = useState(0);
    const [majorPageNo, setMajorPageNo] = useState(0);
    const [recommendationPageNo, setRecommendationPageNo] = useState(0);

    let queryParams = new URL(window.document.location).searchParams;

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            // for debug
            import("mock/overseasReportData2").then((module) => {
                const responseData = module.default;
                setReportData(responseData);
            });
            return;
        }
        axios
            .get("/api/1/render/summary", {
                params: {
                    uid: queryParams?.get("uid"),
                    token: queryParams?.get("token"),
                },
            })
            .then(function (response) {
                let responseData = response.data;

                utils.log("responseData", responseData);
                setReportData(responseData);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!reportData) {
        return null;
    }

    const hasTraitData = !_.isEmpty(reportData?.traits);
    const hasMajorData = !_.isEmpty(reportData.major_recommendation);

    const traitPageNoReady = hasTraitData ? !!traitPageNo : true;
    const majorPageNoReady = hasMajorData ? !!majorPageNo : true;

    if (window.location.pathname.indexOf("gaokao") > -1) {
        return (
            <StyledContainer>
                <reportContext.Provider
                    value={{
                        aboutPageNo,
                        setAboutPageNo,
                        traitPageNo,
                        setTraitPageNo,
                        majorPageNo,
                        setMajorPageNo,
                        recommendationPageNo,
                        setRecommendationPageNo,
                        isPageNoReady:
                            // aboutPageNo &&
                            traitPageNoReady && majorPageNoReady,
                    }}
                >
                    <GaokaoReport reportData={reportData} />
                </reportContext.Provider>
            </StyledContainer>
        );
    }

    if (window.location.pathname.indexOf("overseas") > -1) {
        return (
            <StyledContainer>
                <OverseasReport reportData={reportData} />
            </StyledContainer>
        );
    }

    return <div>404 not found</div>;
}

const StyledContainer = styled.div.attrs({
    className: "report-container",
})`
    width: 100%;
    height: 100%;
    overflow: auto;
    @media print {
        overflow: hidden;
        .break-after-line {
            page-break-after: always !important;
        }
        .avoid-break-inside {
            page-break-inside: avoid !important;
        }
    }

    .a4-size {
        overflow: hidden;
        height: 296.5mm !important;
        page-break-inside: avoid !important;
        background-color: #fff;
    }

    .a4-size,
    .ultimate-size {
        page-break-after: always !important;
        width: 210mm;
        position: relative;
        .section-body {
            padding: 25px 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .text-title {
                font-size: 22px;
                font-weight: bold;
                color: #1b2224;
                margin-bottom: 10px;
            }
            .text-desc {
                font-size: 14px;
                color: #47525d;
            }
        }
        .section-pure-body {
            padding: 25px 45px;
        }
        .left-right-padding {
            padding-left: 45px;
            padding-right: 45px;
        }
    }
`;

export default ReportContainer;
