export default {
    PERSONAL: [
        { key: "name", type: "text" },
        { key: "phoneNumber", type: "text" },
        { key: "dateOfBirth", type: "date" },
        { key: "gender", type: "select", options: ["male", "female"] },
        { key: "location", type: "text" },
    ],
    ACADEMIC: [
        // { key: "001_GRADE", type: "question" },
        // { key: "002_ENT_EXAM", type: "question" },
        // { key: "004_CLASS_RANK", type: "question" },
        // { key: "005_SCHOOL_RANK", type: "question" },
        // { key: "006_SCHOOL_TYPE", type: "question" },
        // { key: "007_SAT_SCORE", type: "question" },
        // { key: "009_SATII_SCORE", type: "question" },
        // { key: "010_SATII_SUB", type: "question" },
        // { key: "011_AP_SCORE", type: "question" },
        // { key: "012_AP_SUB", type: "question" },
        // { key: "046_HS_GPA", type: "question" },
        {key: "GRADE", type: "question"},
        {key: "HIGH_SCHOOL_NAME", type: "question"},
        {key: "HIGH_SCHOOL_TYPE", type: "question"},
        {key: "GPA", type: "question"},
        {key: "GPA_TOTAL", type: "question"},
        {key: "SAT_TAKEN", type: "question"},
        {key: "SAT_TARGET", type: "question"},
        {key: "SAT_SCORE", type: "question"},
        {key: "SAT_DATE", type: "question"},
        {key: "TOEFL_TAKEN", type: "question"},
        {key: "TOEFL_TARGET", type: "question"},
        {key: "TOEFL_SCORE", type: "question"},
        {key: "TOEFL_DATE", type: "question"},
        {key: "ENTRANCE_EXAM_TAKEN", type: "question"},
        {key: "ENTRANCE_EXAM_SCORE", type: "question"},
        {key: "ENTRANCE_EXAM_ENGLISH_SCORE", type: "question"},
        {key: "ENTRANCE_EXAM_DATE", type: "question"},
        {key: "ENTRANCE_EXAM_ENGLISH_MAX_SCORE", type: "question"},
        {key: "ENTRANCE_EXAM_MAX_SCORE", type: "question"},
    ],
    APPLICATION: [
        { key: "013_REL_ABROAD", type: "question" },
        { key: "014_SCHOOL_LIST", type: "question" },
        { key: "015_FREE_TIME", type: "question" },
        { key: "016_RES_SCHOOL", type: "question" },
        { key: "017_PAR_EXP", type: "question" },
        { key: "018_BEEN_ABROAD", type: "question" },
        { key: "019_TOUR_SCHOOL", type: "question" },
    ],
    MAJOR_CAREER: [
        { key: "020_MAJOR_SCALE", type: "question" },
        { key: "021_MAJOR", type: "question" },
        { key: "022_FAV_SUB", type: "question" },
        { key: "023_AP_COURSE", type: "question" },
        { key: "024_CAR_IMPACT", type: "question" },
        { key: "025_CAR_CONF", type: "question" },
        { key: "026_EXTRACUR", type: "question" },
    ],
    LANGUAGE: [
        { key: "003_ENT_EXAM_EN", type: "question" },
        { key: "008_TOEFL_SCORE", type: "question" },
    ],
    INTER_ATTAINMENT: [
        { key: "047_GC_GV_OPENNESS1", type: "question" },
        { key: "048_GC_GV_OPENNESS2", type: "question" },
        { key: "049_GC_GV_ICS1", type: "question" },
        { key: "050_GC_GV_ICS2", type: "question" },
        { key: "051_GC_GV_MULTICULTURALISM1", type: "question" },
        { key: "052_GC_GV_MULTICULTURALISM2", type: "question" },
        { key: "053_GC_TAA_AA1", type: "question" },
        { key: "054_GC_TAA_AA2", type: "question" },
        { key: "055_GC_TAA_AA3", type: "question" },
        { key: "056_GC_TAA_CT1", type: "question" },
        { key: "057_GC_TAA_CT2", type: "question" },
        { key: "058_GC_TAA_CURIOSITY1", type: "question" },
        { key: "059_GC_TAA_CURIOSITY2", type: "question" },
        { key: "060_GC_TAA_CURIOSITY3", type: "question" },
        { key: "061_GC_SS_PS1", type: "question" },
        { key: "062_GC_SS_TEAMWORK1", type: "question" },
        { key: "063_GC_SS_LEADERSHIP1", type: "question" },
        { key: "064_GC_VALUES_VOLUNTEER", type: "question" },
    ],
};
