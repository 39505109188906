import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import ProgressPlus from "report/component/ProgressPlus";

function MajorBanner({ major }) {
    return (
        <StyledContainer id={`toc-major-${major.key}`}>
            <div className="major-score">
                <ProgressPlus value={major.score} width={90} />
            </div>
            <div className="major-name">{major.name}</div>
            <div className="major-desc">{major.desc}</div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "major-banner avoid-break-inside",
})`
    margin-bottom: 45px;
    background-color: #f7f9fa;
    width: 100%;
    height: 120px;
    padding-left: 165px;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .major-score {
        position: absolute;
        left: 45px;
        top: 15px;
    }
    .major-name {
        font-size: 18px;
        font-weight: bold;
        color: #1b2224;
    }
    .major-desc {
        color: #60728e;
        font-size: 14px;
        line-height: 21px;
        padding-right: 45px;
    }
`;

MajorBanner.propTypes = {
    major: propTypes.object.isRequired,
};

export default MajorBanner;
