import React, { Component } from "react";
import { Pagination, message } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import _ from "lodash";
import MultiRow from "./MultiRow";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";

class Contact extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        error: false,
        errorCode: "",
        loading: true,
        pageSize: 50,
        local: {
            emptyText: " ",
        },
        sorter: {},
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/studentinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    changeStatus = (row) => {
        this.setState({
            changeStatus: {
                data: row,
                changeStatusvisible: true,
            },
        });
    };
    renderDataSource = (datasource) => {
        let datasourcetmp = [];
        for (let i = 0; i < datasource.data.length; i++) {
            let data = datasource.data[i]["std"];
            let contact = {};
            for (let j = 0; j < data["contacts"].length; j++) {
                contact[data["contacts"][j]["classify"]] = {
                    phone: data["contacts"][j]["phone"],
                    email: data["contacts"][j]["email"],
                    emailpwd: data["contacts"][j]["emailPwd"],
                };
            }
            datasourcetmp.push({
                ...data,
                key: data["_id"],
                number: i + 1,
                studentId: data["_id"],
                contactName: "本人",
                phone: !contact["本人"] ? "" : contact["本人"]["phone"],
                email: !contact["本人"] ? "" : contact["本人"]["email"],
                emailpwd: !contact["本人"] ? "" : contact["本人"]["emailpwd"],
                syncStu: datasource.data[i]["syncStu"],
                contractId: datasource.data[i]["_id"],
                contractName: datasource.data[i]["contractName"],
                contractNo: datasource.data[i]["contractNo"],
                applyStatus: datasource.data[i]["applyStatus"],
                contractStatus: datasource.data[i]["status"],
            });
            datasourcetmp.push({
                ...data,
                key: data["_id"],
                number: i + 1,
                studentId: data["_id"],
                contactName: "父亲",
                phone: !contact["父亲"] ? "" : contact["父亲"]["phone"],
                email: !contact["父亲"] ? "" : contact["父亲"]["email"],
                emailpwd: !contact["父亲"] ? "" : contact["父亲"]["emailpwd"],
                syncStu: datasource.data[i]["syncStu"],
                contractId: datasource.data[i]["_id"],
                contractName: datasource.data[i]["contractName"],
                contractNo: datasource.data[i]["contractNo"],
                applyStatus: datasource.data[i]["applyStatus"],
                contractStatus: datasource.data[i]["status"],
            });
            datasourcetmp.push({
                ...data,
                key: data["_id"],
                number: i + 1,
                studentId: data["_id"],
                contactName: "母亲",
                phone: !contact["母亲"] ? "" : contact["母亲"]["phone"],
                email: !contact["母亲"] ? "" : contact["母亲"]["email"],
                emailpwd: !contact["母亲"] ? "" : contact["母亲"]["emailpwd"],
                syncStu: datasource.data[i]["syncStu"],
                contractId: datasource.data[i]["_id"],
                contractName: datasource.data[i]["contractName"],
                contractNo: datasource.data[i]["contractNo"],
                applyStatus: datasource.data[i]["applyStatus"],
                contractStatus: datasource.data[i]["status"],
            });
            datasourcetmp.push({
                ...data,
                key: data["_id"],
                number: i + 1,
                studentId: data["_id"],
                contactName: "其他",
                phone: !contact["其他"] ? "" : contact["其他"]["phone"],
                email: !contact["其他"] ? "" : contact["其他"]["email"],
                emailpwd: !contact["其他"] ? "" : contact["其他"]["emailpwd"],
                syncStu: datasource.data[i]["syncStu"],
                contractId: datasource.data[i]["_id"],
                contractName: datasource.data[i]["contractName"],
                contractNo: datasource.data[i]["contractNo"],
                applyStatus: datasource.data[i]["applyStatus"],
                contractStatus: datasource.data[i]["status"],
            });
            datasourcetmp.push({
                ...data,
                key: data["_id"],
                number: i + 1,
                studentId: data["_id"],
                contactName: "申请邮箱",
                email: !data["newOrientalEmail"]
                    ? ""
                    : data["newOrientalEmail"],
                emailpwd: !data["newOrientalEmailPwd"]
                    ? ""
                    : data["newOrientalEmailPwd"],
                syncStu: datasource.data[i]["syncStu"],
                contractId: datasource.data[i]["_id"],
                contractName: datasource.data[i]["contractName"],
                contractNo: datasource.data[i]["contractNo"],
                applyStatus: datasource.data[i]["applyStatus"],
                contractStatus: datasource.data[i]["status"],
                isLastItemOfGroup: true,
            });
        }

        this.setState({
            data: datasourcetmp,
            total: datasource.total,
            currentPage: datasource.currentPage,
            loading: false,
        });
    };
    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;

        const columns = [
            {
                className: "column-apply-status cross-row-td",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applyStatus",
                render: (statusCode, row, index) => {
                    let tag = (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["name"]}
                            contractId={row["contractId"]}
                            fetchData={this.fetchData}
                            contractStatus={row["contractStatus"]}
                        />
                    );

                    const obj = {
                        children: tag,
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <StudentLink
                                studentName={value}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "联系人",
                dataIndex: "contactName",
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {
                            rowSpan: 1,
                            className: "tag-cell",
                        },
                    };
                    return obj;
                },
                width: "100px",
            },
            {
                title: "联系电话",
                dataIndex: "phone",
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contactinfo", {
                        data: {
                            type: record["contactName"],
                            attr: "phone",
                            val: record["phone"],
                            id: record["studentId"],
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["contractId"],
                                aggregate: true,
                            },
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : (
                            hiddenDIV
                        ),
                        props: {},
                    };
                    if (row.contactName === "申请邮箱") {
                        obj.props.rowSpan = 1;
                        obj.props.colSpan = 0;
                    } else {
                        obj.props.rowSpan = 1;
                        obj.props.colSpan = 1;
                    }
                    return obj;
                },
            },
            {
                title: "Email地址",
                dataIndex: "email",
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contactinfo", {
                        data: {
                            type: record["contactName"],
                            attr: "email",
                            val: record["email"],
                            id: record["studentId"],
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["contractId"],
                                aggregate: true,
                            },
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : (
                            hiddenDIV
                        ),
                        props: {},
                    };
                    if (row.contactName === "申请邮箱") {
                        obj.props.rowSpan = 1;
                        obj.props.colSpan = 2;
                    } else {
                        obj.props.rowSpan = 1;
                        obj.props.colSpan = 1;
                    }
                    return obj;
                },
            },
            {
                title: "Email密码",
                dataIndex: "emailpwd",
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contactinfo", {
                        data: {
                            type: record["contactName"],
                            attr: "emailPwd",
                            val: record["emailpwd"],
                            id: record["studentId"],
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["contractId"],
                                aggregate: true,
                            },
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : (
                            hiddenDIV
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.colSpan = 1;
                    return obj;
                },
            },
            {
                title: "居住地址",
                dataIndex: "homeAddr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: <MultiRow value={value} row={8} />,
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    obj.props.className = "textarea";
                    return obj;
                },
                editable: true,
                textarea: true,
                row: 7,
                handleSave: (record, value) => {
                    Request.POST("studentinfo", {
                        data: {
                            update: { homeAddr: record["homeAddr"] },
                            id: record["studentId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("查询错误");
                        },
                    });
                },
            },
            {
                title: "邮寄地址",
                dataIndex: "postAddr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: <MultiRow value={value} row={8} />,
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    obj.props.className = "textarea";
                    return obj;
                },
                editable: true,
                textarea: true,
                row: 7,
                handleSave: (record, value) => {
                    Request.POST("studentinfo", {
                        data: {
                            update: { postAddr: record["postAddr"] },
                            id: record["studentId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("查询错误");
                        },
                    });
                },
            },
            {
                title: "备注",
                dataIndex: "contactRemark",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: <MultiRow value={value} row={8} />,
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    obj.props.className = "textarea";
                    return obj;
                },
                editable: true,
                textarea: true,
                row: 7,
                handleSave: (record, value) => {
                    Request.POST("studentinfo", {
                        data: {
                            update: { contactRemark: record["contactRemark"] },
                            id: record["studentId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("查询错误");
                        },
                    });
                },
            },
            {
                title: "操作",
                dataIndex: "opr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <OperationButtons
                                studentId={row["studentId"]}
                                studentName={row["name"]}
                                applyStatus={row["applyStatus"]}
                                applyAdvisor={row["applyAdvisorName"]}
                                fetchData={this.fetchData}
                                isShowUpload={true}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractStatus={row["contractStatus"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    if (index % 5 === 0) {
                        obj.props.rowSpan = 5;
                    } else {
                        obj.props.rowSpan = 0;
                    }
                    return obj;
                },
                fixed: "right",
                width: "140px",
                align: "center",
            },
        ];

        return (
            <>
                <Editable
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 2000, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    rowClassName={(record, index) => {
                        let syncFlag = record["syncStu"] ? "normal" : "syncRow";
                        const classNames = [syncFlag];
                        if (record.isLastItemOfGroup) {
                            classNames.push("last-item-of-group");
                        }

                        return classNames.join(" ");
                    }}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </>
        );
    }
}
export default Contact;
