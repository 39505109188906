export default {
    "TOEFL": {
        id: -1,
        type: "TOEFL",
        test: 'toefl',
        date: "",
        total: 0,
        max: 120,
        breakdown: {
            "reading": 0,
            "listening": 0,
            "writing": 0,
            "speaking": 0
        }
    },
    "ACT": {
        id: -1,
        type: "ACT",
        test: 'act',
        date: "",
        total: 0,
        max: 120,
        breakdown: {
            "reading": 0,
            "listening": 0,
            "writing": 0,
            "speaking": 0
        }
    },
    "SAT": {
        id: -1,
        type: "SAT",
        test: 'sat',
        date: "",
        total: 0,
        max: 1600,
        breakdown: {
            "ebrw": 0,
            "math": 0
        }
    },
    "SAT2": {
        id: -1,
        type: "SAT2",
        subject: "",
        date: "",
        total: 0,
        breakdown: {},
        max: 800
    },
    "AP": {
        id: -1,
        type: "AP",
        subject: "",
        date: "",
        total: 0,
        breakdown: {},
        max: 5
    }
}