import React from 'react'
import { i18n } from '../lib'

// Local styling required to override recharts
const tooltipStyle = {
    background: 'rgba(16, 32, 55, 0.9)',
    color: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '8px'
}
const pStyle = {
    margin: 0,
    padding: 0,
    fontSize: '0.875rem',
    textAlign: 'left'
}
/**
 * Charting tooltip with custom styling
 * 
 * @param {Object} props tooltip props (passed in from recharts) 
 */
const Tooltip = (props) => {
    const packet = props.payload[0];
    const values = [];
    if (packet){
        const payload = packet.payload;
        for (var key in payload){
            if (key !== "label"){
                values.push({
                    key: key,
                    val: payload[key]
                });
            }
        }
        return (
            <div className="tooltip exam" style={tooltipStyle}>
                {values.map(({ key, val }) => (
                    <p key={key} style={pStyle}>{
                        ( key === 'val' ? '' : 
                            (i18n.t('STUDENTS.' + key.toUpperCase()) + ': ')
                        ) + val
                    }</p>
                ))}
            </div>
        )
    }
    return (<></>);
}

export default Tooltip