import React, { Component } from "react";
import i18n from "../../lib/i18n/i18n";
import * as Widget from "../../components/widgets";

class Language extends Component {
    render() {
        const raw_data = this.props.data.raw_data;
        const questions = raw_data.language_questions;
        // console.log("QUESTIONS: ", questions)
        if (!questions || questions.length < 10) {
            return (
                <div className={"Page StudentInfo"}>
                    <div className="no_data">
                        {i18n.t("PROFILE.NO_LANGUAGE_DATA")}
                    </div>
                </div>
            );
        }
        // const { vocab_test_score, vocab_test_median, vocab_test_accuracy } = raw_data;
        return (
            <div className={"Page StudentInfo"}>
                <Widget.RawData page="LANGUAGE" data={raw_data} />
                {/* <Widget.LanguageOverview 
                    // title={i18n.t('LANGUAGE.TITLE')}
                    // subtitle={i18n.t('LANGUAGE.SUBTITLE')}
                    data={questions}
                    score={vocab_test_score}
                    median={vocab_test_median}
                    accuracy={vocab_test_accuracy}
                /> */}
            </div>
        );
    }
}
export default Language;
