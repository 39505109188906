import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import whatisImg from "img/report/about/report-whatis@2x.jpg";
import ReportHeader from "./ReportHeader";
import Pager from "./Pager";

function About({ title }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />\
            <div className="about-report">
                <div className="illustration"></div>

                <div className="title-row">
                    <div className="title-color"></div>
                    <div className="title-text">关于本报告</div>
                </div>
                <div className="desc-row">
                    <div>
                        通过趣味互动⾏为游戏，本测评真实、科学、客观地衡量受测学⽣的⼗五项素质能⼒；
                    </div>
                    <div>
                        结合⼤数据积累和智能匹配推荐算法，向受测学⽣推荐他们更可能获得成功的专业学习领域和相应的职业。
                    </div>
                    <div>
                        本测评报告能够帮助受测学⽣更好地认识⾃我、发展⾃我、规划未来。
                    </div>
                </div>
            </div>
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .about-report {
        height: 100%;
        width: 100%;
        .illustration {
            width: 200px;
            height: 280px;
            background-size: cover;
        }
        .title-row {
            margin-top: 100px;
            margin-bottom: 60px;
            height: 70px;
            align-items: center;
            display: flex;
            .title-color {
                background-color: #30b9f8;
                width: 16px;
                height: 42px;
                margin-right: 24px;
            }
            .title-text {
                font-weight: bold;
                font-size: 48px;
                color: #1b2224;
            }
        }
        .desc-row {
            padding: 0 42px;
            div {
                font-size: 18px;
                color: #47525d;
            }
        }

        .illustration {
            position: absolute;
            right: 0;
            bottom: 60px;
            width: 438px;
            height: 612px;
            background-image: url(${whatisImg});
            border-radius: 16px;
        }
    }
`;

About.propTypes = {
    title: propTypes.string.isRequired,
};

export default About;
