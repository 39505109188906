import React from "react";
import styled from "styled-components";

function ReportEndBlock() {
	return <StyledContainer />;
}

const StyledContainer = styled.div.attrs({
	id: "backcover-bottom",
})`
	visibility: hidden;
	position: absolute;
	left: 0;
	bottom: 10px;
	width: 1px;
	height: 1px;
	z-index: -1;
`;

export default ReportEndBlock;
