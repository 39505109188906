import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';

/**
 * @class UserSearch
 * 
 * User search bar that extends Semantic-UI's search bar.
 * Takes all student data and converts it into the format required by Semantic-UI.
 * 
 * @prop {Array} students       array of student objects
 * @prop {Function} showProfile handler to display a given student's profile, by id
 */
class UserSearch extends Component {
    state={
        isLoading: false,
        results: [],
        value: ''
    }
    constructor(){
        super();
        this.onResultSelect = this.onResultSelect.bind(this);
    }
    componentDidMount(){
        this.setState({
            results: this.props.source
        });
    }
    reset(){
        this.setState({
            isLoading: false,
            results: this.props.source,
            value: ''
        });
    }
    onResultSelect(e, { result }){
        // console.log("ON RESULT SELECT: ", result);
        this.props.onSelect(result.id);
        this.setState({
            value: result.title
        });
    }
    onSearchChange(e, { value }){
        this.setState({ isLoading: true, value: value })

        setTimeout(() => {
            if (this.state.value.length < 1){
                this.props.onSearchChange();
                this.reset();
                return;
            }

            const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
            const isMatch = result => re.test(result.key)
            const results = _.filter(this.props.source, isMatch);

            this.setState({
                isLoading: false,
                results: results
            });
            if (this.props.onSearchChange){
                this.props.onSearchChange(results);
            }
        });
    }
    render() {
        const { isLoading, results, value } = this.state;
        return (
            <Search
                className={isLoading ? "loading" : ""}
                input={{ icon: 'search', iconPosition: 'left' }}
                loading={isLoading}
                results={results}
                value={value}
                onResultSelect={this.onResultSelect.bind(this)}
                onSearchChange={_.debounce(this.onSearchChange.bind(this), 500, { leading: true })}
            />
        )
    }
}

export default UserSearch;
