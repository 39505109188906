import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { TimeUtil } from "lib/utils";
import { Form, Row, Col, Input, Table } from "antd";
import SubmissionStatusPrint from "./SubmissionStatusPrint";

function getParsedStudentInfo(studentData) {
    return studentData.academyInfos || [];
}

function SummarySubmissionStatus({ studentData }) {
    const [academyInfoData, setAcademyInfoData] = useState({});
    const [schoolCount, setSchoolCount] = useState(0);
    const [uncommitedSchoolCount, setUncommitedSchoolCount] = useState(0);
    const [commitedSchoolCount, setCommitedSchoolCount] = useState(0);

    useEffect(() => {
        setAcademyInfoData(getParsedStudentInfo(studentData));
    }, [studentData, setAcademyInfoData]);

    const [tableDataSource, tableColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "院校排名",
                    dataIndex: "schoolRank",
                    key: "schoolRank",
                    width: "80px",
                },
                {
                    title: "选校状态",
                    dataIndex: "archiveValue",
                    key: "archiveValue",
                    width: "80px",
                },
                {
                    title: "院校名称",
                    dataIndex: "schoolNameEN",
                    key: "schoolNameEN",
                    width: "300px",
                    ellipsis: true,
                },
                {
                    title: "申请专业",
                    dataIndex: "majorNameCH",
                    key: "majorNameCH",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "实际申请专业",
                    dataIndex: "realityMajorName",
                    key: "realityMajorName",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "截止日期",
                    dataIndex: "overdate",
                    key: "overdate",
                    width: "140px",
                },
                {
                    title: "申请系统",
                    dataIndex: "applySystemValue",
                    key: "applySystemValue",
                    width: "150px",
                },
                {
                    title: "申请类型",
                    dataIndex: "applyType",
                    key: "applyType",
                    width: "110px",
                },
                {
                    title: "注册网站",
                    dataIndex: "website",
                    key: "website",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "申请用户名",
                    dataIndex: "applyAccount",
                    key: "applyAccount",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "申请密码",
                    dataIndex: "applyPwd",
                    key: "applyPwd",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "院校提交进度",
                    dataIndex: "submitProgressValue",
                    key: "submitProgressValue",
                    width: "125px",
                },
                {
                    title: "附表文书进度",
                    dataIndex: "docProgressValue",
                    key: "docProgressValue",
                    width: "125px",
                },
                {
                    title: "网申提交日期",
                    dataIndex: "webSubmitDate",
                    key: "webSubmitDate",
                    width: "140px",
                },
                {
                    title: "信用卡支付情况",
                    dataIndex: "creditCardPayStatusValue",
                    key: "creditCardPayStatusValue",
                    width: "140px",
                    ellipsis: true,
                },
                {
                    title: "申请费",
                    dataIndex: "cost",
                    key: "cost",
                    width: "125px",
                    ellipsis: true,
                },
                {
                    title: "语言成绩送分",
                    dataIndex: "languageGiveScoreValue",
                    key: "languageGiveScoreValue",
                    width: "125px",
                },
                {
                    title: "SAT/ACT送分",
                    dataIndex: "SATACTGiveScoreValue",
                    key: "SATACTGiveScoreValue",
                    width: "125px",
                },
                {
                    title: "运单号",
                    dataIndex: "tansId",
                    key: "tansId",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "邮寄日期",
                    dataIndex: "postDate",
                    key: "postDate",
                    width: "140px",
                },
                {
                    title: "运单号2",
                    dataIndex: "tansId2",
                    key: "tansId2",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "邮寄日期2",
                    dataIndex: "postDate2",
                    key: "postDate2",
                    width: "140px",
                },
                {
                    title: "是否合作",
                    dataIndex: "isCooperateValue",
                    key: "isCooperateValue",
                    width: "140px",
                    ellipsis: true,
                },
                {
                    title: "合作院校",
                    dataIndex: "cooperateAcademyValue",
                    key: "cooperateAcademyValue",
                    width: "300px",
                    ellipsis: true,
                },
                {
                    title: "合作项目",
                    dataIndex: "cooperateProject",
                    key: "cooperateProject",
                    width: "200px",
                    ellipsis: true,
                },
                /*                 {
                    title: "直升转正",
                    dataIndex: "zszzValue",
                    key: "zszzValue",
                    width: "140px",
                    ellipsis: true,
                }, */
                {
                    title: "备注",
                    dataIndex: "remark",
                    key: "remark",
                    width: "400px",
                    ellipsis: true,
                },
            ];
            let tempSchoolCount = 0;
            let tempUncommitedSchoolCount = 0;
            let tempCommitedSchoolCount = 0;
            let datasource = [];
            _.each(academyInfoData, function (item, index) {
                tempSchoolCount++;

                if (item.submitProgressValue === "未提交") {
                    tempUncommitedSchoolCount++;
                }

                if (item.submitProgressValue === "已提交") {
                    tempCommitedSchoolCount++;
                }

                datasource.push({
                    key: index,
                    schoolRank: item.schoolRank,
                    archiveValue: item.archiveValue,
                    schoolNameEN: item.schoolNameEN,
                    majorNameCH: item.majorNameCH,
                    realityMajorName: item.realityMajorName,
                    overdate: TimeUtil.formatDate(item.overdate),
                    applySystemValue: item.applySystemValue,
                    applyType: item.applyType,
                    website: item.website,
                    applyAccount: item.applyAccount,
                    applyPwd: item.applyPwd,
                    submitProgressValue: item.submitProgressValue,
                    docProgressValue: item.docProgressValue,
                    webSubmitDate: TimeUtil.formatDate(item.webSubmitDate),
                    creditCardPayStatusValue: item.creditCardPayStatusValue,
                    cost: item.cost,
                    languageGiveScoreValue: item.languageGiveScoreValue,
                    SATACTGiveScoreValue: item.SATACTGiveScoreValue,
                    tansId: item.tansId,
                    postDate: TimeUtil.formatDate(item.postDate),
                    tansId2: item.tansId2,
                    postDate2: TimeUtil.formatDate(item.postDate2),
                    isCooperateValue: item.isCooperateValue,
                    cooperateAcademyValue: item.cooperateAcademyValue,
                    cooperateProject: item.cooperateProject,
                    // zszzValue: item.zszzValue,
                    remark: item.remark,
                });
            });

            setSchoolCount(tempSchoolCount);
            setUncommitedSchoolCount(tempUncommitedSchoolCount);
            setCommitedSchoolCount(tempCommitedSchoolCount);
            return [datasource, columns];
        },
        [academyInfoData]
    );

    return (
        <StyledContainer>
            <header className="section-header">院校提交</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label={"选校数量"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={schoolCount} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"未提交"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={uncommitedSchoolCount} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"已提交"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={commitedSchoolCount} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="not-for-print">
                        <Table
                            dataSource={tableDataSource}
                            columns={tableColumns}
                            size="small"
                            pagination={false}
                            scroll={{ x: 5000 }}
                            bordered
                        />
                    </Col>
                </Row>
                <SubmissionStatusPrint dataSource={tableDataSource} />
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})`
    .section-form {
        padding-bottom: 24px !important;
    }
`;

SummarySubmissionStatus.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummarySubmissionStatus;
