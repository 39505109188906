import React, { Component } from "react";
import i18n from "../../lib/i18n/i18n";
import styled from "styled-components";
import { TierCard } from "../../components";
import * as Widget from "../../components/widgets";
import { Grid, Segment } from "semantic-ui-react";
import datastore from "../../data/store";
import cn from "classnames";
import { StudentUtil } from "lib/utils";

const categories = [
    "ACADEMIC_ABILITY",
    "APPLICATION_READINESS",
    "CAREER_FOCUS",
    "LANGUAGE_APTITUDE",
];
class PlanReport extends Component {
    render() {
        const { tier, scores, overall, assigned_tier } = this.props.data;

        return (
            <StyledContainer>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Widget.SuggestedTier
                                tier={tier}
                                overall={overall}
                                assigned_tier={-1}
                                help={i18n.t("HELP.SUGGESTED_TIER")}
                                className="suggested-tier-card"
                            />
                            <Widget.SuggestedTier
                                tier={tier}
                                overall={overall}
                                assigned_tier={assigned_tier}
                                help={i18n.t("HELP.SUGGESTED_TIER")}
                                className="assigned-tier-card"
                            />
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Widget.ScoresOverview
                                title={i18n.t(
                                    "DASHBOARD.INDIVIDUAL_SCORES_TITLE"
                                )}
                                scores={scores}
                                help={i18n.t("HELP.INDIVIDUAL_SCORES")}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {datastore.get("TIERS").map(({ total, individual }, t) => (
                        <Grid.Row
                            key={t}
                            className={cn([
                                t === tier
                                    ? "suggested-tier"
                                    : "unsuggested-tier",
                                {
                                    "inconsistent-tier":
                                        assigned_tier > -1 &&
                                        assigned_tier !== tier,
                                },
                            ])}
                        >
                            <TierCard
                                index={t}
                                total={total}
                                individual={individual}
                                suggested={t === tier}
                                disabled
                            />
                            <Segment className="description">
                                {categories.map((key, c) => (
                                    <div key={key}>
                                        <h2>{i18n.t("SCORES." + key)}</h2>
                                        <p>
                                            {StudentUtil.updateUStoForeignCountry(
                                                i18n.t(
                                                    "PLANS.TIER_" + t + "_" + c
                                                )
                                            )}
                                        </p>
                                    </div>
                                ))}
                            </Segment>
                        </Grid.Row>
                    ))}
                </Grid>
            </StyledContainer>
        );
    }
}

const StyledContainer = styled.div.attrs({
    className: "Page PlanReport",
})`
    .assigned-tier-card {
        display: none;
    }
`;

export default PlanReport;
