import React from 'react'
import Card from '../Card'
import { DateInput, ExamTooltip, RippleButton } from '..'
import { i18n } from '../../lib'
import { ColorGenerator, ExamUtil, formatDate, rem } from '../../lib/utils'
import * as Modal from '../../components/modals'
import { ResponsiveContainer, BarChart, 
    CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from 'recharts'
import { Table, Loader } from 'semantic-ui-react'

const VIEW = {
    GRAPH: 0,
    TABLE: 1
};
const colors = new ColorGenerator({
    colors: [
        "TEAL",
        "BLUE",
        "PURPLE",
        "PINK"
    ]
});

class ExamInfo extends React.Component {
    state={
        view: VIEW.TABLE,
        invalid: false,
        dirty: false,
        loading: false,
        activeRow: -1,
        deleted: null
    }
    constructor(props){
        super(props);

        this.toggleView = this.toggleView.bind(this);
        this.editView = this.editView.bind(this);
        this.addExam = this.addExam.bind(this);
        this.saveVal = this.saveVal.bind(this);
    }
    componentDidMount(){
        if (this.props.data.length > 0){
            this.setState({ view: VIEW.GRAPH });
        }
    }
    componentWillUnmount(){
        if (this.state.invalid){
            ExamUtil.cleanup(this.props.studentId);
        }
    }
    /**
     * Create a formatted array compatible with recharts.
     * 
     * @param {Array} data Array of student's exams
     */
    processData(data){
        return data.filter(
            exam => !exam.deleted && exam.id !== -1
        ).map(({ date, subject, total, breakdown, max }) => {
            const packet = {
                name: subject || formatDate(date),
                total: total
            };
            if (breakdown){
                for (var key in breakdown){
                    packet[key] = breakdown[key];
                }
            }
            packet.breakdown = breakdown;
            packet.max = max;
            return packet;
        });
    }
    /**
     * Save value of a single table cell locally. 
     * 
     * @param {String|Int} id  Exam id
     * @param {String} key     Exam field key for the current cell
     * @param {String|Int} val Exam field value for the current cell
     */
    saveVal(id, key, val){
        // console.log("SAVE: ", id, key, val);
        if (key === "DATE" && val){
            const date = val;
            val = val.getTime();
            // console.log("SAVE THE DATE: ", val, date);
            if (isNaN(val) || date.getFullYear().toString().length > 4){
                return;
            }
        }
        ExamUtil.updateExamDataField(id, key.toLowerCase(), val);
        this.setState({dirty: true, invalid: !val || this.state.invalid});
    }
    /**
     * Save exam to server and update internal state to reflect validation check.
     * 
     * @param {Object} summary Exam object
     */
    saveExam(summary){
        if (this.state.dirty && this.validateExam(summary)){
            console.log("SAVE EXAM: ", summary);
            ExamUtil.saveExam(summary.id);
            this.setState({dirty: false, invalid: false});
        }
    }
    /**
     * Verify that an exam has no empty fields. 
     * 
     * @param {Object} summary Exam object
     */
    validateExam(summary){
        const { columns } = this.props;

        for (var c=0; c<columns.length; c++){
            var key = columns[c].toLowerCase();
            if (key === "score"){
                key = "total";
            }
            if (key === "total" && summary.total > summary.max){
                console.log("SCORE EXCEEDS MAX");
                return false;
            }
            if (!summary[key]){
                if (summary.breakdown){
                    if (!summary.breakdown[key]){
                        console.log("INVALID EXAM BREAKDOWN: ", key, summary);
                        return false;
                    }
                } else {
                    console.log("INVALID EXAM: ", key, summary);
                    return false;
                }
            }
        }
        return true;
    }
    /**
     * Request the creation of a new exam. 
     * 
     * Set the state to loading until the server verifies the creation of a new exam.
     */
    addExam(){
        this.setState({ loading: true, invalid: true });
        ExamUtil.addExam(this.props.type, () => {
            this.setState({ loading: false })
        });
    }
    /**
     * Delete an exam from the server and trigger an undo popup.
     * 
     * @param {String} id exam id
     */
    deleteExam(id){
        if (id === -1){
            // Do not trigger a popup for new, unsaved rows
            ExamUtil.cleanup(this.props.studentId);
            this.setState({ invalid: false });
            return;
        }
        ExamUtil.deleteExam(id);
        const exams = this.props.data;
        var invalid = this.state.invalid;
        if (!invalid){
            this.setState({ deleted: id });
            return;
        }
        for (var i=0; i<exams.length; i++){
            if (exams[i].id === id){
                continue;
            }
            if (!this.validateExam(exams[i])){
                this.setState({ deleted: id });
                return;
            }
        }
        this.setState({ deleted: id, invalid: false });
    }
    /**
     * Restore an exam on the server and dismiss the undo popup.
     * @param {String} id exam id
     */
    restoreExam(id){
        ExamUtil.restoreExam(id);
        this.setState({ deleted: null });
    }
    /**
     * Destroy the exam by removing the option to restore. 
     *      => Dismiss the undo popup. 
     */
    destroyExam(){
        this.setState({ deleted: null });
    }
    /**
     * Toggle the view between GRAPH and TABLE
     */
    toggleView(){
        if (this.state.invalid){
            return;
        }
        this.setState({view: 1 - this.state.view});
    }
    /**
     * Set the view to TABLE mode
     */
    editView(){
        this.setState({view: VIEW.TABLE});
    }
    /**
     * Mark a row as hovered, in order to display the trash icon for that row. 
     * 
     * @param {Int} r row index
     */
    hoverRow(r){
        // console.log("HOVER ROW: ", r);
        this.setState({ activeRow: r });
    }
    /**
     * Mark a row as no longer hovered if it is currently marked as hovered, 
     * in order to hide the trash icon for that row. 
     * 
     * @param {Int} r row index
     */
    unhoverRow(r){
        this.setState(({ activeRow }) => {
            if (activeRow === r){
                // console.log("UNHOVER ROW: ", r);
                return { activeRow: -1 };
            }
            return { activeRow };
        });
    }
    /**
     * Render the current cell, given its data type. 
     * 
     * Total and Score should be rendered as un-editable cells. 
     * Date should be rendered as a Date Input with date picker.
     * Subject should be a text input.
     * Everything else should be a number input.
     * 
     * Also mark fields as invalid if they are empty. 
     * 
     * @param {Object} exam Exam data 
     * @param {String} key  Exam field key
     * @param {Int} r       Cell row number
     * @param {Int} c       Cell column number
     */
    renderCell({id, type, total, date, test, breakdown}, key, r, c){
        if (key === "TOTAL"){
            return (
                <Table.Cell key={r + "_" + c}>
                    {total}
                </Table.Cell>
            )
        }
        var inpType, val;
        if (key === "DATE"){
            return (
                <Table.Cell key={r + "_" + c} className="editable">
                    <DateInput 
                        value={date}
                        onChange={val => this.saveVal(id, key, val)}
                    />
                </Table.Cell>
            )
        } else if (key === "SUBJECT") {
            val = test;
        } else if (key === "SCORE"){
            inpType = "number";
            val = total;
        } else {
            inpType = "number";
            val = breakdown[key.toLowerCase()] || "";
        }
        const inputClass = "editable" + (!val ? " invalid" : "");
        
        if (key === "SUBJECT"){
            return (
                <Table.Cell key={r + "_" + c} className={inputClass}>
                    <select name="subject" 
                        value={val}
                        placeholder={i18n.t('EXAMS.SUBJECT_PLACEHOLDER')}
                        onChange={({ target }) => {
                            this.saveVal(id, key, target.value);
                        }}
                    >
                        <option value="" disabled>
                            {i18n.t('EXAMS.SUBJECT_PLACEHOLDER')}
                        </option>
                        { ExamUtil.getSubjects(type).map(({ code, name }) => (
                            <option 
                                key={code}
                                value={code}
                            >
                                {name}
                            </option>
                        ))}
                    </select>
                </Table.Cell>
            );
        }

        return (
            <Table.Cell key={r + "_" + c} className={inputClass}>
                <input 
                    type={inpType}
                    value={val.toString()}
                    required={true}
                    onChange={({ target }) => {
                        this.saveVal(id, key, target.value);
                    }}
                />
            </Table.Cell>
        )
    }
    render(){
        const { data, columns, bars, max } = this.props;
        const { view, invalid, loading, deleted } = this.state;
        const hasData = data.length > 0;
        
        return (
            <Card {...this.props} type={"exam_info" + (invalid ? " invalid" : "")}>
                <div className="actions">
                    <div className="viewBtn" onClick={this.toggleView}>
                        {i18n.t('EXAMS.CHANGE_VIEW_' + view)}
                    </div>
                    <div className={"icon edit" + (view === VIEW.TABLE ? ' editing' : '')}
                        onClick={this.editView}
                    >%</div>
                </div>
                { view === VIEW.TABLE && <>
                    <Table className="ExamTable">
                        { hasData && 
                            <Table.Header>
                                <Table.Row>
                                    <th className="disabled" />
                                    { columns.map((key) => (
                                        <Table.HeaderCell key={key} disabled>
                                            {i18n.t('EXAMS.' + key.toUpperCase())}
                                        </Table.HeaderCell>
                                    ))}
                                </Table.Row>
                            </Table.Header>
                        }
                        <Table.Body>
                            { data.map((summary, r) => (
                                <Table.Row 
                                    key={r} 
                                    className={summary.deleted ? 'deleted' : ''}
                                    onMouseEnter={() => this.hoverRow(r)}
                                    onMouseLeave={() => this.unhoverRow(r)}
                                    onBlur={() => this.saveExam(summary)}
                                >
                                    <td className="trash" 
                                        onClick={() => this.deleteExam(summary.id)}
                                    >
                                        <div className="icon">b</div>
                                    </td>
                                    { columns.map((key, c) => {
                                        return this.renderCell(summary, key, r, c);
                                    }) }
                                </Table.Row>
                            )) }
                        </Table.Body>
                    </Table>
                    <div className="addBar">
                        { !loading && 
                            <RippleButton
                                className="addExam"
                                fillFrom="center"
                                icon=")"
                                flat={true}
                                disabled={invalid}
                                text={i18n.t("EXAMS.ADD")}
                                onClick={this.addExam}
                            /> 
                        }
                        <Loader active={loading} />
                    </div>
                </>}
                { view === VIEW.GRAPH && 
                    <ResponsiveContainer width="90%" height={rem(15)} >
                        <BarChart data={this.processData(data)}>
                            <CartesianGrid vertical={false}/>
                            <XAxis dataKey="name" />
                            <YAxis domain={[0, max]}/>
                            <Legend 
                                verticalAlign="top" 
                                align="left"
                                iconType="circle"
                                height={rem(3)}
                                margin={{
                                    left: rem(2)
                                }}
                                formatter={(val) => {
                                    return i18n.t('EXAMS.' + val.toUpperCase());
                                }}
                            />
                            { bars.map( (key, i) => (
                                <Bar 
                                    key={key} 
                                    dataKey={key.toLowerCase()} 
                                    stackId="a"
                                    fill={colors.get(i)} 
                                />
                            ))}
                            { hasData && 
                                <Tooltip
                                    content={<ExamTooltip/>}
                                    cursor={{fill: 'rgba(0, 0, 0, 0.1)'}}
                                />
                            }
                        </BarChart>
                    </ResponsiveContainer>
                }
                { deleted && 
                    <Modal.Undo 
                        message={i18n.t('EXAMS.DELETED')}
                        undo={() => {this.restoreExam(deleted)}}
                        close={() => {this.destroyExam(deleted)}}
                    />
                }
            </Card>
        )
    }
}

export default ExamInfo;