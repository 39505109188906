import _ from 'lodash'
import { Request } from "../../lib"

/**
    global.DataDict={moblab:admissioninfo:SEVISFree:0: "无需",
                     moblab:admissioninfo:SEVISFree:1: "是",
                     moblab:admissioninfo:SEVISFree:2: "否"
                    }=>无序
    global.DataDictNew={moblab:writerinfo:reported:[0: {key: "0", value: "是"},1: {key: "1", value: "否"}]}=>有序，按sort排序
    getMap={0: "申请待定", 1: "服务中", 2: "已完成", 3: "已转案", 4: "已退费", 5: "已延期", 6: "其他"}=>无序
    getDictNew=[
         {key: "0", value: "申请待定"},
         {key: "1", value: "服务中"},
         {key: "2", value: "已完成"},
         {key: "3", value: "已转案"},
         {key: "4", value: "已退费"},
         {key: "5", value: "已延期"},
         {key: "6", value: "其他"}
    ]=>有序，按sort排序
    getDict:同上=>无序
 */

const HardCodeData = { "erp:trackadmission:result": { code: "erp000000", value: "尚未达到报完成条件" } }
const InsertHardCode = () => {
    _.map(HardCodeData, (v, k) => {
        global.DataDict[k + ":" + v.code] = v.value;
        global.DataDictNew[k] && global.DataDictNew[k].push({ key: v.code, value: v.value });
    });
}

class DataDict {
    static init = (callback) => {
        global.DataDict = {}
        Request.GET('datadict', {
            onSuccess: (res) => {
                let parent = {};
                let DataDict = {};
                let DataDictNew = {};
                _.each(res.data, (v, k) => {
                    parent[v._id] = { code: v.code, parent: v.parent };
                });
                _.each(res.data, (v, k) => {
                    if (v.parent) {
                        let _key = "";
                        let _parent = v.parent;
                        while (!_.isEmpty(_parent)) {
                            let _p = parent[_parent];
                            _key = _p['code'] + ":" + _key;
                            _parent = _p['parent']
                        }
                        let _code = _key + v['code'];
                        let __key = _key.substring(0, _key.length - 1);
                        DataDict[_code] = v.value;
                        if (_.isEmpty(DataDictNew[__key])) {
                            DataDictNew[__key] = [];
                        }
                        DataDictNew[__key].push({ key: v.code, value: v.value })
                    }
                });
                global.DataDict = {
                    ...DataDict,
                }
                global.DataDictNew = {
                    ...DataDictNew,
                }
                InsertHardCode();
                callback();
            },
            onFail: (status) => {
                if (status === 401) return true;
                this.setState({
                    loading: false,
                    error: true,
                    errorCode: "WRONG"
                });
            }
        });
    }
    /**
    * support fuzzy query
    * eg.
    *  DataDict.getMap("moblab:seniorhighschool:region:510000000000", "成")
    */
    static getMap = (type, match) => {
        let result = {};
        _.map(global.DataDict, (v, k) => {
            if (k.indexOf(type) >= 0) {
                if (!_.isEmpty(match)) {
                    if (v.toUpperCase().indexOf(match.toUpperCase()) >= 0) {
                        result[k.replace(type + ":", "")] = v;
                    }
                } else {
                    result[k.replace(type + ":", "")] = v;
                }
            }
        });
        return result;
    }
    /**
     * support fuzzy query
     * eg.
     *  DataDict.getDictNew("moblab:seniorhighschool:region:510000000000", "成")
     */
    static getDictNew = (type, match) => {
        let result = [];
        if (!_.isEmpty(match)) {
            _.each(global.DataDictNew[type], (v) => {
                if (v.value.toUpperCase().indexOf(match.toUpperCase()) >= 0) {
                    result.push(v);
                }
            })
        } else {
            result = global.DataDictNew[type];
        }
        return result
    }
    /**
    * support fuzzy query
    * eg.
    *  DataDict.getDict("moblab:seniorhighschool:region:510000000000", "成")
    */
    static getDict = (type, match) => {
        let result = [];
        _.map(global.DataDict, (v, k) => {
            if (k.indexOf(type) >= 0) {
                if (!_.isEmpty(match)) {
                    if (v.toUpperCase().indexOf(match.toUpperCase()) >= 0) {
                        result.push({
                            key: k.replace(type + ":", ""),
                            value: v
                        });
                    }
                } else {
                    result.push({
                        key: k.replace(type + ":", ""),
                        value: v
                    });
                }
            }
        });
        return result;
    }

    static get = type => {
        return global.DataDict[type];
    }
}
export default DataDict;