import React, { Component } from "react";
import Card from "../Card";
import i18n from "../../lib/i18n/i18n";
import { StudentUtil } from "lib/utils";
import "../../scss/SuggestedTier.scss";

class SuggestedTier extends Component {
    render() {
        const { assigned_tier, tier, overall } = this.props;
        const selected = assigned_tier > -1;
        const heights = [0, 0, 0, 0];
        const widgetClass = "suggested_tier" + (selected ? " selected" : "");
        const tierKey = selected ? "SELECTED" : "SUGGESTED";
        const displayTier = selected ? assigned_tier : tier;
        return (
            <Card
                type={widgetClass}
                title={i18n.t("DASHBOARD." + tierKey + "_TIER_TITLE")}
                subtitle={i18n.t("DASHBOARD." + tierKey + "_TIER_SUBTITLE")}
                {...this.props}
            >
                <div className="bg">
                    {heights.map((val, i) => (
                        <div
                            key={i}
                            className={"bar h" + (i === assigned_tier ? 1 : 0)}
                        />
                    ))}
                </div>
                <div className="plan">
                    {StudentUtil.getTierName(displayTier).toUpperCase()}
                </div>
                <h2>{i18n.t("SCORES.OVERALL")}</h2>
                <div className="score">{overall}</div>
            </Card>
        );
    }
}

export default SuggestedTier;
