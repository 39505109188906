import React, { Component } from "react";
import { i18n } from "../../lib";
import { AdminUtil } from "../../lib/utils";
import { Statistic } from "../../components/widgets";
import { Dropdown, Loader } from "semantic-ui-react";

var campusOptions, quarterOptions, graphOptions;
const graphTypes = [
    "student_accounts",
    "completed_assessments",
    "plan_assignments",
    "advisor_accounts",
];
class Statistics extends Component {
    state = {
        campuses: [],
        quarters: [],
        selectedGraphs: [],
        data: null,
    };
    constructor() {
        super();
    }
    componentDidMount() {
        const data = AdminUtil.getStatistics();
        console.log("STATS: ", data);
        this.setState({ data });
    }
    getCampusOptions() {
        if (campusOptions) {
            return campusOptions;
        }
        const campuses = [];
        const campusData = AdminUtil.getCampuses();

        for (var id in campusData) {
            campuses.push({
                key: id,
                text: campusData[id].name,
                value: id,
            });
        }
        campusOptions = campuses;
        return campuses;
    }
    getQuarterOptions() {
        if (quarterOptions) {
            return quarterOptions;
        }
        const recorded = AdminUtil.getRecordedQuarters();
        const quarters = [];
        for (var q = 0; q < recorded.length; q++) {
            const { year, quarter } = recorded[q];
            const value = year + "_" + quarter;
            quarters.push({
                key: value,
                text: "Q" + quarter + "/" + year.toString().slice(2),
                value: value,
            });
        }
        quarterOptions = quarters;
        return quarters;
    }
    getStatOptions() {
        if (graphOptions) {
            return graphOptions;
        }
        const graphs = graphTypes.map((key) => ({
            key: key,
            text: i18n.t("ADMIN.STAT_" + key.toUpperCase()),
            value: key,
        }));
        graphOptions = graphs;
        return graphs;
    }
    getGraphSpecs() {
        const { campuses, quarters, selectedGraphs, data } = this.state;
        if (!data) {
            return null;
        }
        const specs = [];
        const ids = campuses.length > 0 ? campuses : ["all"];
        const currGraphs =
            selectedGraphs.length > 0 ? selectedGraphs : graphTypes;
        const dq = 3;
        let currQuarters = quarters;

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const spec = {
                title:
                    id === "all"
                        ? i18n.t("STATS.ALL")
                        : AdminUtil.getCampusName(id),
                graphs: [],
            };
            for (let g = 0; g < currGraphs.length; g++) {
                const graphType = currGraphs[g];
                const graphSpec = {
                    type: graphType,
                    data: [],
                };
                const recordedData = data[id][graphType];
                if (quarters.length == 0) {
                    currQuarters = this.getQuarterOptions().map(
                        ({ value }) => value
                    );
                }
                if (currQuarters.length > 1) {
                    for (let q = 0; q < currQuarters.length; q++) {
                        const [year, quarter] = currQuarters[q].split("_");
                        const yearIndex = AdminUtil.getNormalizedYear(year);
                        const quarterStat = {
                            label: "Q" + quarter + "/" + year.slice(2),
                        };
                        for (
                            let m = (quarter - 1) * dq;
                            m < quarter * dq;
                            m++
                        ) {
                            const record = recordedData[yearIndex][m];
                            for (var dataKey in record) {
                                if (dataKey !== "label") {
                                    if (!quarterStat[dataKey]) {
                                        quarterStat[dataKey] = 0;
                                    }
                                    quarterStat[dataKey] += record[dataKey];
                                }
                            }
                        }
                        graphSpec.data.push(quarterStat);
                    }
                } else {
                    const [year, quarter] = currQuarters[0].split("_");
                    const yearIndex = AdminUtil.getNormalizedYear(year);
                    for (let m = (quarter - 1) * dq; m < quarter * dq; m++) {
                        const record = recordedData[yearIndex][m];
                        graphSpec.data.push(record);
                    }
                }

                spec.graphs.push(graphSpec);
            }
            specs.push(spec);
        }

        return specs;
    }
    render() {
        const { campuses, quarters, selectedGraphs } = this.state;
        const graphSpecs = this.getGraphSpecs();

        return (
            <div className="Statistics">
                <div className="filters">
                    <Dropdown
                        multiple
                        selection
                        placeholder={i18n.t("ADMIN_ADVISORS.CAMPUS")}
                        value={campuses}
                        options={this.getCampusOptions()}
                        onChange={(e, { value }) => {
                            this.setState({ campuses: value });
                        }}
                    />
                    <Dropdown
                        multiple
                        selection
                        placeholder={i18n.t("ADMIN.QUARTER")}
                        value={quarters}
                        options={this.getQuarterOptions()}
                        onChange={(e, { value }) => {
                            this.setState({ quarters: value.sort() });
                        }}
                    />
                    <Dropdown
                        multiple
                        selection
                        placeholder={i18n.t("ADMIN.STAT")}
                        value={selectedGraphs}
                        options={this.getStatOptions()}
                        onChange={(e, { value }) => {
                            this.setState({ selectedGraphs: value });
                        }}
                    />
                </div>
                {graphSpecs && (
                    <div className="charts">
                        {graphSpecs.map(({ title, graphs }) => (
                            <div className="section" key={title}>
                                <h2>{title}</h2>
                                {graphs.map(({ type, data }) => {
                                    return (
                                        <Statistic
                                            key={title + "_" + type}
                                            title={i18n.t(
                                                "ADMIN.STAT_" +
                                                    type.toUpperCase()
                                            )}
                                            formatKey="STUDENTS"
                                            series={data}
                                        />
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
                <Loader active={!graphSpecs} />
            </div>
        );
    }
}

export default Statistics;
