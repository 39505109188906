export default [
    {
        key: "OVERVIEW",
        pages: [
            { key: "DASHBOARD", icon: "\u00B2" },
            {
                key: "PLAN_REPORT",
                icon: "5",
                renderCondition: "requireAssignment",
            },
            { key: "TRAIT_REPORT", icon: "5" },
            { key: "GLOBAL_COMPETENCE_REPORT", icon: "5" },
            { key: "MAJOR_FIT", icon: "5" },
            { key: "SCHOOL_FIT", icon: "5" },
            { key: "NOTES", icon: "5" },
        ],
    },
    {
        key: "STUDENT_INFO",
        pages: [
            { key: "PERSONAL", icon: "x" },
            { key: "ACADEMIC", icon: "x" },
            { key: "APPLICATION", icon: "x" },
            { key: "MAJOR_CAREER", icon: "x" },
            { key: "LANGUAGE", icon: "x" },
            { key: "INTER_ATTAINMENT", icon: "x" },
            // ]},
            // {key: 'RECOMMENDATIONS', pages: [
            //     {page: "MAJOR_REC", icon: '7'},
            //     {page: "SCHOOL_REC", icon: '7'}
            // ]},
            // {key: 'DATA_INTEGRATION', pages: [
            //     {page: "MANAGE", icon: '-'},
            //     {page: "STATISTICS", icon: '±'}
        ],
    },
    { key: "STUDENT_PROGRESS", pages: [{ key: "EXAMS", icon: "D" }] },
];
