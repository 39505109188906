import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Spin } from "antd";
import {
    PlanReport,
    TraitReport,
    MajorFit,
    SchoolsPage,
    GlobalCompetenceReport,
} from "../routes/pages";

function PrintAllView({
    data,
    major_fit,
    school_fit,
    showRecommendations,
    print,
}) {
    const [loading, setLoading] = useState(true);
    useEffect(
        function () {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setLoading(false);
                    print();
                });
            });
            // setTimeout(() => {
            //     setLoading(false);
            //     print();
            // }, 1000);
        },
        [print]
    );

    const competenceTraits = (data || {}).competence_traits;
    let hasData = competenceTraits && !_.isEmpty(competenceTraits);

    return (
        <Spin tip="Loading preview..." spinning={loading}>
            <PlanReport data={data} />
            <TraitReport data={data} printView={true} />
            {hasData ? <GlobalCompetenceReport data={data} printView /> : null}
            {showRecommendations ? (
                <React.Fragment>
                    <MajorFit data={major_fit} printView />
                    <SchoolsPage data={school_fit} />
                </React.Fragment>
            ) : null}
        </Spin>
    );
}

export default PrintAllView;
