const data = {
    majorDetail: [
        {
            key: "ARCHITECTURE",
            avg_salary: "$64,000",
            grad_rate: 0.339,
            career_outcomes: 0.855,
            name: {
                zh: "建筑学",
                en: "Architecture",
            },
            desc: {
                zh:
                    "建筑专业通常学习如何规划，设计和建造建筑物以及其他的结构。",
                en:
                    "Architecture majors are typically involved with the process of planning, designing, and constructing buildings or any other structures.",
            },
            sub_majors: {
                zh: "建筑学, 城市规划, 景观建筑",
                en: "Architecture, Urban Planning, Landscape Architecture",
            },
            careers: {
                zh: "建筑师, 城市规划师, 艺术家",
                en: "Architect, Urban planner, Artist",
            },
        },
        {
            key: "ARTS",
            avg_salary: "$55,000",
            grad_rate: 0.232,
            career_outcomes: 0.825,
            name: {
                zh: "艺术",
                en: "Arts",
            },
            desc: {
                zh:
                    "艺术专业学习人类社会和文化产生的创造力的理论及其物理表达。艺术的主要组成部分包括文学，表演艺术（舞蹈、音乐等）和视觉艺术（绘画、绘画、电影制作等）",
                en:
                    "Arts majors study the theory and physical expression of creativity found in human societies and cultures. Major constituents of the arts include literature, performing arts (e.g., dance, music), and visual arts (e.g., drawing, painting, filmmaking).",
            },
            sub_majors: {
                zh: "美术与设计, 舞蹈, 音乐, 电影, 戏剧",
                en: "Arts and Design, Dance, Music, Film, Drama",
            },
            careers: {
                zh: "艺术家, 网页设计师, 音乐编辑, 导演, 平面设计师",
                en:
                    "Artist, Web Page Designer, Music or sound editor, Movie Director, Graphic designer",
            },
        },
        {
            key: "BIOLOGY",
            avg_salary: "$67,000",
            grad_rate: 0.577,
            career_outcomes: 0.794,
            name: {
                zh: "生物学",
                en: "Biology",
            },
            desc: {
                zh:
                    "生物学专业为学生提供了研究生命和生物的机会，其中具体的议题包括生物的物理结构，化学过程，分子相互作用，生理机制，发育和进化。",
                en:
                    "The biology major provides students with the opportunity to study life and living organisms, including their physical structure, chemical processes, molecular interactions, physiological mechanisms, development and evolution.",
            },
            sub_majors: {
                zh: "生物化学, 细胞生物学, 基因学, 生态学, 神经科学",
                en:
                    "Biochemistry, Cellular Biology, Genetics, Ecology, Neuroscience",
            },
            careers: {
                zh:
                    "教授, 生物技术工程师, 实验室技术员, 卫生护理科学家, 环境咨询师",
                en:
                    "Professor, Biotechnologist, Laboratory Technician, Healthcare scientist, Environmental consultant",
            },
        },
        {
            key: "BUSINESS",
            avg_salary: "$63,000",
            grad_rate: 0.222,
            career_outcomes: 0.864,
            name: {
                zh: "商科",
                en: "Business",
            },
            desc: {
                zh:
                    "商学专业帮助学生从各个层面理解商业的运作基础，从而使他们能够胜任会计、金融、市场营销和管理等各领域的工作。",
                en:
                    "Business majors study the fundamental aspects of business, which will help students prepare for a wide range of positions in accounting, finance, marketing and management.",
            },
            sub_majors: {
                zh: "会计, 金融, 保险, 管理, 物流, 贸易, 市场",
                en: "Accounting, Finance, Insurance, Management, Logistics",
            },
            careers: {
                zh: " 会计, 管理分析师, 证券经纪人, 精算师, 人力资源顾问",
                en:
                    "Accountant, Management analyst, Stock broker, Actuary, Human resources professional",
            },
        },
        {
            key: "COMMUNICATION",
            avg_salary: "$52,000",
            grad_rate: 0.208,
            career_outcomes: 0.853,
            name: {
                zh: "传媒",
                en: "Communication",
            },
            desc: {
                zh:
                    "传媒专业教授学生如何有效地以分析，理解和传输信息。传媒专业涉及的领域包括媒体，法律，政治和商业。",
                en:
                    "The communications major teaches students how to effectively analyze, understand and transmit information through both writing and speaking across fields such as journalism/media, law, politics, and business.",
            },
            sub_majors: {
                zh: "通讯, 广告, 媒体, 新闻学, 公共关系, 多媒体, 传播",
                en:
                    "Communication, Advertising, Media, Journalism, Public Relations",
            },
            careers: {
                zh: "公共关系代表, 营销经理, 记者, 市场顾问",
                en:
                    "Public relations representative, Campaign director, Journalist, Marketing specialist",
            },
        },
        {
            key: "ECONOMICS",
            avg_salary: "$71,000",
            grad_rate: 0.413,
            career_outcomes: 0.858,
            name: {
                zh: "经济学",
                en: "Economics",
            },
            desc: {
                zh:
                    "经济学专业为学生提供经济学理论，数学模型和分析技巧方面的培训，而从使他们能够更好地理解世界经济体系和市场如何运作。经济学专业所习得的技能可以广泛应用于商业、科技、金融和政府等部门。",
                en:
                    "The economics major provides students with the economic theory, mathematical models and analytical skills necessary to undertstand how global economic systems and markets function. Students can apply these skills to a variety of positions in business, technology, finance or the government. ",
            },
            sub_majors: {
                zh: "经济学",
                en: "Economics",
            },
            careers: {
                zh: "咨询师, 投资银行家, 对冲基金管理员, 金融分析师",
                en:
                    "Consultant, Investment banker, Hedge fund administrator, Financial advisor",
            },
        },
        {
            key: "EDUCATION",
            avg_salary: "$51,000",
            grad_rate: 0.446,
            career_outcomes: 0.865,
            name: {
                zh: "教育学",
                en: "Education",
            },
            desc: {
                zh:
                    "教育学专业学习如何向他人传授知识的理论和应用。该专业学生通常需要理解学习的理论和教授的技巧。学生也可以选择特定的教学方向并学习该方向的专业知识。",
                en:
                    "Education majors learn the theory and practice of teaching others. Students typically study how people learn and the most effective ways to teach. Students may choose to specialize in a specific topic and accumulate subject-specific knowledge.",
            },
            sub_majors: {
                zh: "早期教育, 小学教育, 中学教育, 特殊教育, 英语教育",
                en:
                    "Early Childhood, Elementary, Secondary, Special Needs, TESOL",
            },
            careers: {
                zh: "教师, 教育行政人员",
                en: "Teacher, Education administrator",
            },
        },
        {
            key: "ENGINEERING",
            avg_salary: "$83,000",
            grad_rate: 0.382,
            career_outcomes: 0.862,
            name: {
                zh: "工程学",
                en: "Engineering",
            },
            desc: {
                zh:
                    "工程学学习如何运用数学和科学的原理来有效地设计和建造机器和其他的结构。",
                en:
                    "Engineering majors learn how to creatively use scientific and mathematical principles to design and build machines and other structures, across a variety of industries.",
            },
            sub_majors: {
                zh:
                    "生物医学工程, 土木工程, 计算机科学, 电气工程, 机械工程, 农业工程, 航空航天工程",
                en:
                    "Biomedical Engineering, Civil Engineering, Computer Science, Electrical Engineering, Mechanical Engineering",
            },
            careers: {
                zh:
                    "生物医学工程师, 土木工程师, 程序员, 电气工程师, 机械工程师",
                en:
                    "Biomedical engineer, Civil engineer, Computer programmer, Electrical engineer, Mechanical engineer",
            },
        },
        {
            key: "HEALTH",
            avg_salary: "$61,000",
            grad_rate: 0.338,
            career_outcomes: 0.877,
            name: {
                zh: "医疗卫生",
                en: "Health",
            },
            desc: {
                zh:
                    "理疗卫生专业整合了科学，数学，工程和技术领域的知识来指导医疗和保健方面的实践。",
                en:
                    "The health major incorporates a large group of disciplines which focus on applying science, engineering mathematics and tecnology knowledge to health and health care practices. ",
            },
            sub_majors: {
                zh: "公共卫生, 口腔卫生, 健康和保健, 护理学, 药剂学",
                en:
                    "Public Health, Dental Hygiene, Health and Fitness, Nursing, Pharmacy",
            },
            careers: {
                zh: "医生, 牙医, 营养师, 护士, 医药师",
                en:
                    "Doctor, Dentist, Dietitian and nutritionist, Nurse, Pharmacist",
            },
        },
        {
            key: "HUMANITIES",
            avg_salary: "$60,000",
            grad_rate: 0.414,
            career_outcomes: 0.74,
            name: {
                zh: "人文学",
                en: "Humanities",
            },
            desc: {
                zh:
                    "人文学科通过研究人类的文字，哲学和艺术来窥探人类社会和文化的各个方面。因为其多学科的性质，人文学科的学生可以有更多的空间来选择课程，从而使得课程可以跟自己的教育背景和就业导向更加契合。",
                en:
                    "Humanities majors use a multidisciplinary approach to study all aspects of human society and culture, through literature, philosophy and art. Since the degree covers a wide range of disciplines, students have space to choose the course of study that best matches their education and career goals. ",
            },
            sub_majors: {
                zh: "历史, 语言, 文学, 哲学, 宗教",
                en: "History, Language, Literatures, Philosophy, Religion",
            },
            careers: {
                zh: "教授, 技术文件撰稿人, 编辑, 参赞, 宗教领袖",
                en:
                    "Professor, Technical writer, Editor, Counselor, Religious leader",
            },
        },
        {
            key: "MATHEMATICS_AND_STATISTICS",
            avg_salary: "$67,000",
            grad_rate: 0.333,
            career_outcomes: 0.849,
            name: {
                zh: "数学与统计",
                en: "Mathematics And Statistics",
            },
            desc: {
                zh:
                    "数学专业学习数学这门语言，并应用它来描述和理解我们的世界。学生们也会探索数据的采集、分析、理解和表达。",
                en:
                    "As a math major, students will study mathematics as a language and learn how to use it to describe and understand the world. Students will also explore the collection, analysis, interpretation and presentation of data.",
            },
            sub_majors: {
                zh: "纯数学, 应用数学, 统计学",
                en: "Mathematics, Applied Mathematics, Statistics",
            },
            careers: {
                zh: "教授, 精算师, 经济学家, 金融分析师, 数据科学家",
                en:
                    "Professor, Actuary, Economist, Financial analyst, Data scientist",
            },
        },
        {
            key: "PHYSICAL_SCIENCE",
            avg_salary: "$67,000",
            grad_rate: 0.5,
            career_outcomes: 0.81,
            name: {
                zh: "自然科学",
                en: "Physical Science",
            },
            desc: {
                zh:
                    "自然科学专业探索非生物世界的系统和过程，重点关注物理和化学。",
                en:
                    "The physical sciences major explores the systems and processes of the non-living physical world, with a strong focus on physics and chemistry. ",
            },
            sub_majors: {
                zh: "物理, 化学, 地质与地球科学, 天文学",
                en:
                    "Physics, Chemistry, Geological and Earth Science, Astronomy",
            },
            careers: {
                zh: "教授, 实验室技术员, 数据科学家, 地理学家, 天文学家",
                en:
                    "Professor, Laboratory technician, Data Scientist, Geologist, Astronomer",
            },
        },
        {
            key: "SOCIAL_SCIENCE",
            avg_salary: "$54,000",
            grad_rate: 0.414,
            career_outcomes: 0.807,
            name: {
                zh: "社会科学",
                en: "Social Science",
            },
            desc: {
                zh: "社会科学专业主要学习人类社会以及人与人之间的关系。",
                en:
                    "A major in the Social Sciences involves classes from a broad array of subjects all concerned with society and the relationships among individuals within a society.",
            },
            sub_majors: {
                zh: "人类学, 地理学, 心理学, 社会学, 政治学",
                en:
                    "Anthropology, Geography, Psychology, Sociology, Political Science",
            },
            careers: {
                zh: "人类学家, 心理学家, 公共关系专家, 政策分析师, 法律顾问",
                en:
                    "Anthropologist, Psychologist, Public relations specialist, Policy analyst, Legislative assistant",
            },
        },
    ],
};

export default data;
