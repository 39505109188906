import React from 'react'
import { i18n } from "../lib"
import { formatDate } from '../lib/utils'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

//https://www.npmjs.com/package/react-datepicker

class DateInput extends React.Component {
    render(){
        const { value, onChange } = this.props;
        const date = formatDate(value, "input");
        const val = value ? date : "";
        const format = i18n.t('APP.DATE_FORMAT');

        return (
            <DatePicker 
                {...this.props}
                selected={date}
                value={val}
                locale={i18n.locale}
                placeholderText={format}
                dateFormat={format.replace('YYYY', 'yyyy').replace('DD', 'dd')}
                disabledKeyboardNavigation
                scrollableYearDropdown
                onChange={val => onChange(val || "")}
            />
        ) 
    }
}
export default DateInput