import React, { Component } from "react";
import { Button, Dropdown, Menu, Input, DatePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DataDict from "./DataDict";
import _ from "lodash";
import moment from "moment";
import AutoComplete from "./AutoComplete";
import { Request } from "../../lib";
import { produce } from "immer";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Search } = Input;

let currentYear = new Date().getFullYear();
let minYear = 2017;
let maxYear = currentYear + 3;
let yearMap = {};
for(let year = minYear;year<=maxYear;year++){
    yearMap[year.toString()] = year.toString();
}

class FilterInfo extends Component {
    _filterInfo = {
        studentInfo: {
            "studentinfo.name": {
                val: "",
                expression: "like",
            },
            applyAdvisorName: {
                val: "",
                expression: "like",
            },
            docAdvisorName: {
                val: "",
                expression: "like",
            },
            applyStatus: {
                val: "",
                expression: "eq",
                isNc:true,
            },
            contractName: {
                val: "",
                expression: "eq",
            },
            applyYear:{
                val:"",
                expression: "eq",
            }
        },
        academyInfo: {
            schoolId: {
                val: "",
                expression: "eq",
                display: "",
            },
            overdate: {
                val: "",
                expression: "lte",
            },
        },
        showClear: false,
    };
    _ipclass = {
        student: "normal",
        advisor: "normal",
        applystatus: "normal",
        types: "normal",
        academyname: "normal",
        academydate: "normal",
        applyYear:"normal",
        docAdvisor:"normal"
    };
    state = {
        filteredInfo: JSON.parse(JSON.stringify(this._filterInfo)),
        iptclass: JSON.parse(JSON.stringify(this._ipclass)),
    };
    renderStatus = (applyStatusMap)=>{
        if(this.state.filteredInfo.studentInfo.applyStatus.isNc){
            return "未完成"
        }else if(_.isEmpty(this.state.filteredInfo.studentInfo.applyStatus.val)){
            return "全部";
        }else{
            return applyStatusMap[
                this.state.filteredInfo.studentInfo
                    .applyStatus.val
            ]
        }
    }
    search = (params,show) => {
        let showClear = show?show:false;
        _.map(this.state.filteredInfo.studentInfo, (v, k) => {
            if (!_.isEmpty(v.val)) {
                showClear = true;
            }
        });
        _.map(this.state.filteredInfo.academyInfo, (v, k) => {
            if (!_.isEmpty(v.val)) {
                showClear = true;
            }
        });
        let _params = JSON.parse(JSON.stringify(params));
        _params.studentInfo.applyAdvisorName.val = _params.studentInfo.applyAdvisorName.val.trim();
        _params.studentInfo.docAdvisorName.val = _params.studentInfo.docAdvisorName.val.trim();
        _params.studentInfo["studentinfo.name"].val = _params.studentInfo["studentinfo.name"].val.trim();
        this.setState({ showClear: showClear });
        this.props.onSearch(_params);
    };
    handleTypesClick = (value) => {
        let style = "searchipt";
        let showClear = true;
        if (value === "全部" || value === "") {
            style = "normal";
            value = "";
            showClear = false;
        }

        const immutableFilteredInfo = produce(
            this.state.filteredInfo,
            (draftInfo) => {
                draftInfo.studentInfo.contractName.val = value;
            }
        );
        this.setState(
            (state) => {
                state.filteredInfo = { ...immutableFilteredInfo };
                state.iptclass.types = style;
                return state;
            },
            () => {
                this.search(this.state.filteredInfo, showClear);
            }
        );
    };
    handleOverdateChange = (value) => {
        let style = "searchipt";
        let showClear = true;
        if (value === null) {
            style = "normal";
            showClear = false;
        }
        const immutableFilteredInfo = produce(
            this.state.filteredInfo,
            (draftInfo) => {
                draftInfo.academyInfo.overdate.val = value ? moment(value) : "";
            }
        );
        this.setState(
            (state) => {
                state.filteredInfo = { ...immutableFilteredInfo };
                state.iptclass.academydate = style;
                return state;
            },
            () => {
                this.search(this.state.filteredInfo, showClear);
            }
        );
    };
    handleAcademyinfoNameClick = (value, display) => {
        let style = "searchipt";
        let showClear = true;
        if (value === "全部" || value === "") {
            style = "normal";
            value = "";
            showClear = false;
        }
        const immutableFilteredInfo = produce(
            this.state.filteredInfo,
            (draftInfo) => {
                draftInfo.academyInfo.schoolId.val = value;
                draftInfo.academyInfo.schoolId.display = display;
            }
        );

        this.setState(
            (state) => {
                state.filteredInfo = { ...immutableFilteredInfo };
                state.iptclass.academyname = style;
                return state;
            },
            () => {
                this.search(this.state.filteredInfo, showClear);
            }
        );
    };
    handleStatusClick = (value) => {
        let style = "searchipt";
        let showClear = true;
        if (value.key === "全部") {
            value.key = "";
        }
        const immutableFilteredInfo = produce(
            this.state.filteredInfo,
            (draftInfo) => {
                if(value.key==="nc"){
                    draftInfo.studentInfo.applyStatus.val = "";
                    draftInfo.studentInfo.applyStatus.isNc = true;
                }else{
                    draftInfo.studentInfo.applyStatus.val = value.key;
                    draftInfo.studentInfo.applyStatus.isNc = false;
                }
            }
        );

        this.setState(
            (state) => {
                if(value.key==="nc"){
                    style = "normal";
                    showClear = false;
                }
                state.filteredInfo = { ...immutableFilteredInfo };
                state.iptclass.applystatus = style;
                return state;
            },
            () => {
                this.search(this.state.filteredInfo, showClear);
            }
        );
    };


    handleYearClick = (value) => {
        let style = "searchipt";
        let showClear = true;
        if (value.key === "全部") {
            value.key = "";
        }
        const immutableFilteredInfo = produce(
            this.state.filteredInfo,
            (draftInfo) => {
                draftInfo.studentInfo.applyYear.val = value.key;
            }
        );

        this.setState(
            (state) => {
                if(value.key===""){
                    style = "normal";
                    showClear = false;
                }
                state.filteredInfo = { ...immutableFilteredInfo };
                state.iptclass.applyYear = style;
                return state;
            },
            () => {
                this.search(this.state.filteredInfo, showClear);
            }
        );
    };

    render() {
        let applyStatusMap = DataDict.getMap("moblab:contractinfo:applyStatus");
        applyStatusMap["nc"] = "未完成";
        let status = (
            <Menu
                onClick={this.handleStatusClick}
                value={this.state.filteredInfo.studentInfo.applyStatus}
            >
                <Menu.Item key="全部">全部</Menu.Item>
                {_.map(applyStatusMap, (v, k) => {
                    return <Menu.Item key={k}>{v}</Menu.Item>;
                })}
            </Menu>
        );
        return (
            <>
                {this.state.showClear ? (
                    <Button
                        type="link"
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                            this.setState(
                                (state) => {
                                    state.filteredInfo = JSON.parse(
                                        JSON.stringify(this._filterInfo)
                                    );
                                    state.iptclass = JSON.parse(
                                        JSON.stringify(this._ipclass)
                                    );
                                    return state;
                                },
                                () => {
                                    let params = JSON.parse(
                                        JSON.stringify(this.state.filteredInfo)
                                    );
                                    this.search(params, false);
                                }
                            );
                        }}
                    >
                        清空过滤
                    </Button>
                ) : (
                    <span>过滤：</span>
                )}
                <Search
                    className={this.state.iptclass["student"]}
                    placeholder="学生"
                    value={this.state.filteredInfo.studentInfo["studentinfo.name"].val}
                    onChange={(e) => {
                        var value = e.target.value;
                        const immutableFilteredInfo = produce(
                            this.state.filteredInfo,
                            (draftInfo) => {
                                draftInfo.studentInfo["studentinfo.name"].val = value;
                            }
                        );
                        this.setState({
                            filteredInfo: { ...immutableFilteredInfo },
                        });
                    }}
                    onSearch={(value) => {
                        this.setState((state) => {
                            state.iptclass.student = "searchipt";
                            return state;
                        });
                        this.search(this.state.filteredInfo);
                    }}
                    ref={(input) => (this.studentName = input)}
                    style={{ width: 100 }}
                />

                <Search
                    className={this.state.iptclass["advisor"]}
                    placeholder="申请顾问"
                    value={
                        this.state.filteredInfo.studentInfo.applyAdvisorName.val
                    }
                    onChange={(e) => {
                        var value = e.target.value;
                        const immutableFilteredInfo = produce(
                            this.state.filteredInfo,
                            (draftInfo) => {
                                draftInfo.studentInfo.applyAdvisorName.val = value;
                            }
                        );
                        this.setState({
                            filteredInfo: { ...immutableFilteredInfo },
                        });
                    }}
                    onSearch={(value) => {
                        this.setState((state) => {
                            state.iptclass.advisor = "searchipt";
                            return state;
                        });
                        this.search(this.state.filteredInfo);
                    }}
                    style={{ width: 100 }}
                />
                <Search
                    className={this.state.iptclass["docAdvisor"]}
                    placeholder="文书顾问"
                    value={
                        this.state.filteredInfo.studentInfo.docAdvisorName.val
                    }
                    onChange={(e) => {
                        var value = e.target.value;
                        const immutableFilteredInfo = produce(
                            this.state.filteredInfo,
                            (draftInfo) => {
                                draftInfo.studentInfo.docAdvisorName.val = value;
                            }
                        );
                        this.setState({
                            filteredInfo: { ...immutableFilteredInfo },
                        });
                    }}
                    onSearch={(value) => {
                        this.setState((state) => {
                            state.iptclass.docAdvisor = "searchipt";
                            return state;
                        });
                        this.search(this.state.filteredInfo);
                    }}
                    style={{ width: 100 }}
                />
                {/* <AutoComplete
                    width="200px"
                    placeholder="请输入业务类型"
                    onSelect={(option) => {
                        this.handleTypesClick(option.key);
                    }}
                    mergeKV
                    className={this.state.iptclass.types}
                    onChange={(value) => {
                        const immutableFilteredInfo = produce(
                            this.state.filteredInfo,
                            (draftInfo) => {
                                draftInfo.studentInfo.contractName.val = value;
                            }
                        );

                        this.setState({
                            filteredInfo: { ...immutableFilteredInfo },
                        });

                        if (_.isUndefined(value)) {
                            this.handleTypesClick("");
                        }
                    }}
                    allowValue={true}
                    value={this.state.filteredInfo.studentInfo.contractName.val}
                    onSearch={(setOptions, searchText) => {
                        let result = [
                            {
                                key: "",
                                value: "",
                                desc: "全部",
                            },
                        ];
                        let arr = DataDict.getDictNew(
                            "erp:studentinfo:busitype",
                            searchText
                        ).map((v) => ({
                            key: v.value,
                            value: v.value,
                            desc: v.value,
                        }));
                        result = _.concat(result, arr);
                        setOptions(result);
                    }}
                /> */}
                <Dropdown overlay={status}>
                    <Button
                        style={{ float: "right" }}
                        className={this.state.iptclass.applystatus}
                    >
                        {
                           this.renderStatus(applyStatusMap)
                        }
                        <DownOutlined />
                    </Button>
                </Dropdown>
                <Dropdown overlay={ <Menu
                    onClick={this.handleYearClick}
                    value={this.state.filteredInfo.studentInfo.applyYear}
                    >
                        <Menu.Item key="全部">全部</Menu.Item>
                        {_.map(yearMap, (v, k) => {
                            return <Menu.Item key={k}>{v}</Menu.Item>;
                        })}
                    </Menu>}>
                        <Button
                            style={{ float: "right" }}
                            className={this.state.iptclass.applyYear}
                        >
                        {this.state.filteredInfo.studentInfo.applyYear.val===""?"入学年份":this.state.filteredInfo.studentInfo.applyYear.val}
                        <DownOutlined />
                    </Button>
                </Dropdown>
                {/* <Select
                                placeholder="选择状态"
                                style={{ width: 160 }}
                                onChange={this.handleStatusChange}
                            >
                                {_.map(this.applyStatusMap, function (
                                    statusText,
                                    statusCode
                                ) {
                                    return (
                                        <Option
                                            value={statusCode}
                                            key={statusCode}
                                        >
                                            {statusText}
                                        </Option>
                                    );
                                })}
                            </Select> */}
                <DatePicker
                    placeholder="申请截止日期"
                    onChange={this.handleOverdateChange}
                    value={this.state.filteredInfo.academyInfo.overdate.val}
                    className={this.state.iptclass.academydate}
                />

                <AutoComplete
                    width="200px"
                    placeholder="请输入院校名称"
                    onSelect={(option) => {
                        this.handleAcademyinfoNameClick(
                            option.key,
                            option.children
                        );
                    }}
                    mergeKV
                    className={this.state.iptclass.academyname}
                    onChange={(value) => {
                        const immutableFilteredInfo = produce(
                            this.state.filteredInfo,
                            (draftInfo) => {
                                // draftInfo.academyInfo.schoolId.val = value;
                                draftInfo.academyInfo.schoolId.display = value;
                            }
                        );
                        this.setState({
                            filteredInfo: { ...immutableFilteredInfo },
                        });

                        // handle clear
                        if (_.isUndefined(value)) {
                            this.handleAcademyinfoNameClick("", "");
                        }
                    }}
                    allowValue={true}
                    value={(() => {
                        // let _v = DataDict.getMap("erp:school:en")[this.state.filteredInfo.academyInfo.schoolId.val];
                        // _v = _.isEmpty(_v) ? this.state.filteredInfo.academyInfo.schoolId.val : _v;
                        let _v = this.state.filteredInfo.academyInfo.schoolId
                            .display;
                        return _v;
                    })()}
                    onSearch={(setOptions, searchText) => {
                        Request.GET(
                            "datadict/erp:school:en/" +
                                encodeURIComponent(searchText),
                            {
                                onSuccess: (data) => {
                                    let attr = [
                                        {
                                            key: "",
                                            value: "",
                                            desc: "全部",
                                        },
                                    ];
                                    attr.push();
                                    _.each(data.data, (v) => {
                                        attr.push({
                                            key: v.code,
                                            value: v.value,
                                            desc: v.value,
                                        });
                                    });
                                    setOptions(attr);
                                },
                                onFail: (status, err) => {
                                    if (status === 401) return true;
                                    console.error(err);
                                },
                            }
                        );
                    }}
                />
            </>
        );
    }
}
export default FilterInfo;
