import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Card from "../Card";
import { rem, deriveWidth } from "lib/utils";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
} from "recharts";
import styled from "styled-components";
import { i18n } from "lib";

const keyTextMap = {
    gv_openness: "开放性、包容性、尊重差异",
    gv_intercaltural_communication: "跨文化交流沟通的能力",
    gv_multiculturalism: "认识和了解多元文化的能力",
    taa_academic_abilities: "学术能力",
    taa_critical_thinking: "批判性思维",
    taa_curiosity: "好奇心、求知欲",
    taa_creativity: "创造力、创新能力",
    ss_public_speaking: "交流沟通、公开演讲与辩论的能力",
    ss_problem_solving: "解决问题的能力",
    ss_teamwork: "团队合作能力",
    ss_leadership: "领导力",
    values_agreeableness_conscientiousness: "公益心、责任心、服务意识",
};

function convertData(competenceTraits) {
    return _.map(competenceTraits, function (value, key) {
        return { key, value, label: keyTextMap[key] };
    });
}

function GlobalCompetenceOverview({ maxHeight, competenceTraits, ...props }) {
    let [width, setWidth] = useState(0);
    let containerRef = useRef();
    let hasData = competenceTraits && !_.isEmpty(competenceTraits);
    useEffect(
        function () {
            let newWidth = window.matchMedia("print").matches
                ? maxHeight
                    ? 400
                    : 666
                : deriveWidth(containerRef.current, maxHeight, 2);
            setWidth(Math.min(newWidth, 666));
        },
        [setWidth, maxHeight]
    );

    return (
        <StyledContainer>
            <Card {...props}>
                <div ref={containerRef} className="chartContainer">
                    {hasData ? (
                        <RadarChart
                            width={width}
                            height={width / 2}
                            cx={width / 2}
                            cy={width / 4}
                            outerRadius={width / 4 - rem(2)}
                            data={convertData(competenceTraits)}
                        >
                            <PolarGrid />
                            <PolarAngleAxis dataKey="label" />
                            <PolarRadiusAxis
                                axisLine={false}
                                domain={[0, 100]}
                                tickCount={3}
                                tickFormatter={() => ""}
                            />
                            <Radar
                                name="Traits"
                                dataKey="value"
                                // stroke="#8884d8"
                                fill="rgb(37, 193, 56)"
                                fillOpacity={0.6}
                                animationDuration={200}
                            />
                        </RadarChart>
                    ) : (
                        <div className="no_data">{i18n.t("EXAMS.NO_DATA")}</div>
                    )}
                </div>
            </Card>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "global-competence-overview",
})`
    height: 100%;
    .chartContainer {
        text-align: center;
    }
`;
export default GlobalCompetenceOverview;
