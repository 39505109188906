import React from "react";
import propTypes from "prop-types";
// import _ from "lodash";
import styled from "styled-components";
import ReportHeader from "../ReportHeader";
import RadarByTraits from "./RadarByTraits";
import Pager from "../Pager";

function CompetenceOverview({ title, traitsData, traitsThreshold }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            <div className="part-body">
                <div className="part-title">全球胜任力雷达图</div>
                <div className="radar-container">
                    <RadarByTraits
                        isShowTitle={false}
                        printView
                        traitsData={traitsData}
                        traitsThreshold={traitsThreshold}
                    />
                </div>
            </div>
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .part-body {
        margin: 48px 42px;
        .part-title {
            font-weight: bold;
            font-size: 30px;
            line-height: 43px;
            color: #1b2224;
            margin-bottom: 88px;
        }

        .radar-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

CompetenceOverview.propTypes = {
    title: propTypes.string.isRequired,
    traitsData: propTypes.object.isRequired,
};

export default CompetenceOverview;
