import React, { Component } from 'react'
import Card from '../Card'
// import { i18n } from "../../lib"
import { deriveWidth, rem } from '../../lib/utils'
import { RadialPercent } from '../'

class Progress extends Component {
    state={
        width: 0
    }

    componentDidMount() {
        this.setState({
            width: window.matchMedia('print').matches ? 
                200 : 
                deriveWidth(this.container, 12)
        })
    }

    render() {
        const { width } = this.state;
        const { progress } = this.props;
        // const duration = 2 * 60 * 60 * 1000; //TODO: compute
        
        // const timeLabel = i18n.t('DASHBOARD.TIME_SPENT') + getDurString(duration);
        return (
            <Card {...this.props} type="game_progress">
                <div ref={(div) => {this.container = div}}>
                    <RadialPercent 
                        value={progress}
                        r={width/2}
                        color='#41BFF7'
                        labelColor='#102037'
                        fontSize={rem(2)}
                    />
                </div>
            </Card>
        )
    }
}

export default Progress;
