import React, { Component } from "react";
import { i18n } from "../../lib";
import { DateInput, RippleButton } from "..";
import datastore from "../../data/store";
import { Form, Button } from "semantic-ui-react";
import Card from "../Card";
import { StudentUtil } from "lib/utils";
import _ from "lodash";

/**
 * @class RawData
 *
 * Display a dataset as plain fields.
 * Fields can be editable if a callback to save them is provided.
 *
 * @prop {String}   page     page name
 * @prop {Map}      data     dataset to render into fields
 * @prop {Function} [onEdit] save fields callback
 *
 */
const ProfileConfig = {
    GRADE: {
        zh: {
            7: "初一",
            8: "初二",
            9: "初三",
            10: "高一",
            11: "高二",
            12: "高三",
        },
        en: {
            7: "7th",
            8: "8th",
            9: "9th",
            10: "10th",
            11: "11th",
            12: "12th",
        },
    },
    HIGH_SCHOOL_TYPE: {
        zh: {
            regular: "普通高中",
            international: "国际部/学校",
            overseas: "海外高中",
            other: "其他",
        },
        en: {
            regular: "Regular",
            international: "International",
            overseas: "Overseas",
            other: "Other",
        },
    },
    SAT_TAKEN: {
        zh: {
            0: "否",
            1: "是",
        },
        en: {
            0: "No",
            1: "Yes",
        },
    },
    SAT_TARGET: {
        zh: {
            "1550": "1550+",
            "1450": "1450+",
            "1300": "1300+",
            "1200": "1200+",
            "1000": "1000+",
            "0": "<1000",
            "-1": "不知道",
        },
        en: {
            "1550": "1550+",
            "1450": "1450+",
            "1300": "1300+",
            "1200": "1200+",
            "1000": "1000+",
            "0": "<1000",
            "-1": "I don't know",
        },
    },
    TOEFL_TAKEN: {
        zh: {
            0: "否",
            1: "是",
        },
        en: {
            0: "No",
            1: "Yes",
        },
    },
    TOEFL_TARGET: {
        zh: {
            "110": "110+",
            "100": "100+",
            "90": "90+",
            "80": "80+",
            "0": "<80",
            "-1": "不知道",
        },
        en: {
            "110": "110+",
            "100": "100+",
            "90": "90+",
            "80": "80+",
            "0": "<80",
            "-1": "I don't know",
        },
    },
    ENTRANCE_EXAM_TAKEN: {
        zh: {
            0: "否",
            1: "是",
        },
        en: {
            0: "No",
            1: "Yes",
        },
    },
};
class RawData extends Component {
    state = {
        disabled: true,
        focused: false,
        page: false,
        spec: [],
        values: [],
    };
    componentWillMount() {
        this.updateData();
    }
    componentDidUpdate() {
        if (this.state.page !== this.props.page) {
            this.updateData();
        }
    }
    updateData() {
        this.inputs = {};
        const { data, page, profile } = this.props;
        const spec = datastore.get("INFO_SPEC")[page];
        const values = {};
        spec.map(({ key, type }) => {
            if (page === "ACADEMIC") {
                values[key] = getProfileRawResponse(profile, key);
            } else {
                values[key] = getRawResponse(data, key, type);
            }
            return values[key];
        });

        this.setState({ spec, values, page });
    }
    setVal(key, val) {
        const values = Object.assign({}, this.state.values);
        values[key] = val;
        this.setState({ values });
    }
    edit() {
        if (!this.state.disabled) {
            this.save();
        } else {
            this.savedValues = this.state.values;
            this.setState({ disabled: false });
        }
    }
    save() {
        this.props.onEdit(this.state.values);
        this.setState({ disabled: true });
    }
    cancel() {
        this.setState({ values: this.savedValues, disabled: true });
    }
    onFocus(key) {
        this.setState({ focused: key });
    }
    onBlur() {
        this.setState({ focused: false });
    }
    renderInput({ key, type, options }) {
        const { disabled, focused, values } = this.state;
        const questions = datastore.get("QUESTIONS");
        // console.log(key, type, options);
        const commonProps = {
            key: key,
            className: focused === key ? "focused" : "",
            label:
                type === "question"
                    ? ""
                    : i18n.t("INFO_LABELS." + key.toUpperCase()),
            value: values[key],
            disabled: disabled,
            onBlur: this.onBlur.bind(this),
            onFocus: () => {
                this.onFocus(key);
            },
            onChange: (e, { value }) => {
                this.setVal(key, value);
            },
        };

        switch (type) {
            case "select":
                return (
                    <Form.Select
                        {...commonProps}
                        options={options.map((val) => {
                            return {
                                value: val,
                                text: i18n.t("PROFILE.OPTION_" + val),
                            };
                        })}
                    />
                );
            case "date":
                return (
                    <div className="field" key={key}>
                        <label>{commonProps.label}</label>
                        <div className="ui input">
                            <DateInput
                                {...commonProps}
                                onChange={(date) => this.setVal(key, date)}
                            />
                        </div>
                    </div>
                );
            case "question":
                return (
                    <div className="field" disabled key={key}>
                        <label>
                            {StudentUtil.updateUStoForeignCountry(
                                getQuestionTitle(questions, key)
                            )}
                        </label>
                        <div className="ui question">{commonProps.value}</div>
                    </div>
                );
            default:
                return <Form.Input {...commonProps} />;
        }
    }
    render() {
        const { page, onEdit } = this.props;
        return (
            <Card className="FormSegment" title={i18n.t(page + ".TITLE")}>
                {onEdit && (
                    <div className="icon edit" onClick={this.edit.bind(this)}>
                        %
                    </div>
                )}
                <Form>{this.state.spec.map(this.renderInput.bind(this))}</Form>
                {!this.state.disabled && (
                    <div className="actions">
                        <RippleButton
                            empty
                            flat
                            text={i18n.t("APP.CANCEL")}
                            onClick={this.cancel.bind(this)}
                        />
                        <Button
                            positive
                            content={i18n.t("APP.SAVE")}
                            onClick={this.save.bind(this)}
                        />
                    </div>
                )}
            </Card>
        );
    }
}

function getQuestionTitle(questions, key) {
    if (!questions.hasOwnProperty(key)) {
        return i18n.t("INFO_LABELS." + key.toUpperCase());
    }
    const { type, prompt, labels, min, max } = questions[key][i18n.language];
    switch (type) {
        case "NUMERIC_RESPONSE_SCALE":
            return (
                prompt +
                "  (" +
                labels[0] +
                "<" +
                min +
                "> - " +
                labels[labels.length - 1] +
                "<" +
                max +
                ">)"
            );
        default:
            return prompt;
    }
}

function getRawResponse(data, key, dataType) {
    // console.log("GET RAW RESPONSE: ", data);
    if (!data.hasOwnProperty(key)) {
        return "--";
    } else if (dataType === "question") {
        if (data.hasOwnProperty("001_GRADE")) {
            return data[key].actualResponse;
        } else {
            const { choices, type } = datastore.get("QUESTIONS")[key][
                i18n.language
            ];
            const res = data[key].responseIndex;
            switch (type) {
                case "MULTIPLE_CHOICE_CHECKBOX":
                    let arrStr = "";
                    for (var i = 0; i < res.length; i++) {
                        arrStr += (i > 0 ? " // " : "") + choices[res[i]];
                    }
                    return arrStr;
                case "NUMERIC_RESPONSE_SCALE":
                    return res;
                default:
                    return choices[res];
            }
        }
    } else {
        return data[key];
    }
}
function getProfileRawResponse(data, key) {
    let res = ProfileConfig[key]
        ? ProfileConfig[key][i18n.language][data[key]]
        : undefined;
    if (res) {
        return res;
    }
    return data[key] === undefined ? "--" : data[key];
}

export default RawData;
