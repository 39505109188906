import React, { Component } from "react";
import {
    Logo,
    Input,
    PasswordReqs,
    RippleButton,
    TermsOfService,
} from "../components";
import SiteRecord from "components/SiteRecord";
import { i18n, Request } from "../lib";
import { PasswordValidator } from "../lib/utils";
import { Button, Message, Form } from "semantic-ui-react";
import styled from "styled-components";
import cn from "classnames";
import "../scss/Login.scss";

const PAGE = {
    EMAIL: 0,
    LOGIN: 1,
    SIGNUP: 2,
};
const ERROR_CODE = {
    NO_USER: "NO_USER",
    INCORRECT: "INCORRECT",
    MISMATCH: "MISMATCH",
    INVALID: "INVALID",
};
const legacyPwd = "Vision2019";

class Login extends Component {
    constructor() {
        super();
        this.state = {
            page: PAGE.EMAIL,
            email: "",
            password1: "",
            password2: "",
            // rememberMe: false,
            disabled: false,
            exiting: false,
            error: false,
            errorCode: "",
            validation: {},
            ssoUrl: null,
            isPasswordMode: true,
        };
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPwd1Change = this.onPwd1Change.bind(this);
        this.onPwd2Change = this.onPwd2Change.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.completeAuth = this.completeAuth.bind(this);
        this.back = this.back.bind(this);
        this.fresh = true;
    }

    componentWillMount() {
        document.title = i18n.t("LOGIN.ADVISOR_LOGIN");
    }

    componentDidMount() {
        Request.GET("config", {
            onSuccess: (response) => {
                if (response && response.ssoUrl) {
                    this.setState({
                        ssoUrl: response.ssoUrl,
                    });
                } else {
                    this.setState({
                        isPasswordMode: true,
                    });
                }
            },
            onFail: (res, error) => {
                if (error) alert(error);
            },
        });
    }

    onEmailChange({ target }) {
        this.setState({
            email: target.value,
            error: false,
        });
    }
    onPwd1Change({ target }) {
        this.setState({
            password1: target.value,
            error: false,
            validation: {},
        });
    }
    onPwd2Change({ target }) {
        this.setState({
            password2: target.value,
            error: false,
            validation: {},
        });
    }
    onSubmit(e) {
        e.preventDefault();
        switch (this.state.page) {
            case PAGE.EMAIL:
                return this.checkEmail();
            case PAGE.LOGIN:
                return this.login();
            case PAGE.SIGNUP:
                return this.signup();
            default:
                return;
        }
    }
    back() {
        this.setState({
            page: PAGE.EMAIL,
            error: false,
            disabled: false,
            errorCode: "",
            password1: "",
            password2: "",
        });
    }
    checkEmail() {
        const { email } = this.state;
        if (email.length === 0) {
            return;
        }
        this.setState({ disabled: true });

        const goToSignup = () => {
            Request.POST("oauth/signout", {
                data: {},
                onSuccess: () => {
                    this.setState({
                        page: PAGE.SIGNUP,
                        disabled: false,
                        error: false,
                    });
                },
            });
        };
        const checkPassword = (pwd, cb) => {
            Request.POST("session/signin", {
                data: {
                    email: email,
                    password: pwd,
                },
                onSuccess: goToSignup,
                onFail: (res, error) => {
                    if (error === "no such user") {
                        this.setState({
                            disabled: false,
                            error: true,
                            errorCode: ERROR_CODE.NO_USER,
                        });
                    } else {
                        cb();
                    }
                    return false;
                },
            });
        };

        checkPassword(email, () => {
            this.fresh = false;
            checkPassword(legacyPwd, () => {
                this.setState({
                    page: PAGE.LOGIN,
                    disabled: false,
                    error: false,
                });
            });
        });
    }
    login() {
        const { email, password1 } = this.state;
        if (password1.length === 0) {
            return;
        }
        this.setState({
            disabled: true,
        });
        Request.POST("session/signin", {
            data: {
                email: email,
                password: password1,
            },
            onSuccess: this.completeAuth,
            onFail: () => {
                this.setState({
                    disabled: false,
                    error: true,
                    errorCode: ERROR_CODE.INCORRECT,
                });
                return true;
            },
        });
    }
    signup() {
        const { email, password1, password2 } = this.state;
        if (password1 !== password2) {
            this.setState({
                error: true,
                errorCode: ERROR_CODE.MISMATCH,
            });
            return;
        }
        const result = PasswordValidator.validate(password1);
        if (!result.valid) {
            this.setState({
                error: true,
                errorCode: ERROR_CODE.INVALID,
                validation: result,
            });
            return;
        }
        this.setState({ disabled: true });

        Request.POST("session/signin", {
            data: {
                email: email,
                password: this.fresh ? email : legacyPwd,
            },
            onSuccess: ({ user }) => {
                Request.POST("user/password", {
                    data: {
                        id: user._id,
                        password: password1,
                    },
                    onSuccess: this.completeAuth,
                });
            },
        });
    }
    completeAuth() {
        this.setState({
            exiting: true,
            disabled: false,
            error: false,
        });
        setTimeout(() => {
            this.props.init();
        }, 800); // Transition out duration
    }
    render() {
        const {
            page,
            exiting,
            disabled,
            error,
            errorCode,
            validation,
            ssoUrl,
            isPasswordMode,
        } = this.state;
        const submitCode =
            page === PAGE.EMAIL
                ? "NEXT"
                : exiting
                ? "LOADING"
                : page === PAGE.LOGIN
                ? "LOGIN"
                : "SIGNUP";

        return (
            <div className="View Login">
                <div className="splash" />
                <div className={"formWrapper" + (exiting ? " exiting" : "")}>
                    <Form error={error}>
                        <StyledContainer>
                            <div
                                className={cn([
                                    "container",
                                    { show: isPasswordMode },
                                ])}
                            >
                                {page !== PAGE.SIGNUP && (
                                    <>
                                        <h1 className="ui header">
                                            {i18n.t("LOGIN.WELCOME_1")}
                                            <br />
                                            {i18n.t("LOGIN.WELCOME_2")}
                                        </h1>
                                        {/* <h2>{i18n.t('LOGIN.ADVISOR_LOGIN')}</h2> */}
                                    </>
                                )}
                                {page === PAGE.EMAIL && (
                                    <Input
                                        type="text"
                                        error={error}
                                        disabled={disabled}
                                        value={this.state.email}
                                        label={i18n.t("APP.EMAIL")}
                                        onChange={this.onEmailChange}
                                        focused
                                    />
                                )}

                                {page === PAGE.LOGIN && (
                                    <Input
                                        type="password"
                                        error={error}
                                        disabled={disabled}
                                        value={this.state.password1}
                                        label={i18n.t("APP.PASSWORD")}
                                        onChange={this.onPwd1Change}
                                        focused
                                    />
                                )}

                                {page === PAGE.SIGNUP && (
                                    <>
                                        {!this.fresh && (
                                            <>
                                                <h2>
                                                    {i18n.t(
                                                        "LOGIN.ADVISOR_WELCOME_BACK"
                                                    )}
                                                </h2>
                                                <p>
                                                    {i18n.t(
                                                        "LOGIN.ADVISOR_WELCOME_BACK_SUB"
                                                    )}
                                                </p>
                                            </>
                                        )}
                                        {this.fresh && (
                                            <h2>
                                                {i18n.t("LOGIN.ADVISOR_SIGNUP")}
                                            </h2>
                                        )}
                                        <Input
                                            type="password"
                                            error={error}
                                            disabled={disabled}
                                            label={i18n.t("PASSWORD.NEW")}
                                            onChange={this.onPwd1Change}
                                            focused
                                        />
                                        <Input
                                            type="password"
                                            error={error}
                                            disabled={disabled}
                                            label={i18n.t("PASSWORD.CONFIRM")}
                                            onChange={this.onPwd2Change}
                                        />
                                    </>
                                )}

                                {error && errorCode !== ERROR_CODE.INVALID && (
                                    <Message
                                        error
                                        content={i18n.t(
                                            "PASSWORD." + errorCode
                                        )}
                                    />
                                )}
                                {error && errorCode === ERROR_CODE.INVALID && (
                                    <PasswordReqs validation={validation} />
                                )}
                                <div className="actions">
                                    {page !== PAGE.EMAIL && (
                                        <RippleButton
                                            flat
                                            disabled={disabled}
                                            text={i18n.t("APP.BACK")}
                                            onClick={this.back}
                                        />
                                    )}
                                    <Button
                                        inverted
                                        color="blue"
                                        disabled={disabled}
                                        loading={disabled}
                                        onClick={this.onSubmit}
                                    >
                                        {i18n.t("APP." + submitCode)}
                                    </Button>
                                </div>
                                {/* <div className="login-support-container">
                                    <a
                                        className="login-support"
                                        target="_blank"
                                        href="https://moblab-vision.zendesk.com/hc/zh-cn"
                                    >
                                        {i18n.t("APP.SUPPORT")}
                                    </a>
                                </div> */}
                            </div>
                            <div
                                className={cn([
                                    "container",
                                    "sso-container",
                                    { show: Boolean(ssoUrl) },
                                ])}
                            >
                                {/* <h1 className="ui header">
                                    {i18n.t("LOGIN.WELCOME_1")}
                                    <br />
                                    {i18n.t("LOGIN.WELCOME_2")}
                                </h1> */}
                                {/* <p className="or-text">OR</p>
                                <div className="actions">
                                    <Button
                                        inverted
                                        color="blue"
                                        disabled={!ssoUrl}
                                        onClick={() => {
                                            window.location.href = ssoUrl;
                                        }}
                                    >
                                        跳转到前途ERP登录
                                    </Button>
                                </div> */}
                                {/* <div className="login-support-container">
                                    <a
                                        className="login-support"
                                        target="_blank"
                                        href="https://moblab-vision.zendesk.com/hc/zh-cn"
                                    >
                                        {i18n.t("APP.SUPPORT")}
                                    </a>
                                </div> */}
                            </div>
                        </StyledContainer>
                    </Form>
                </div>
                <Logo simple={true} />
                <TermsOfService />
                <SiteRecord />
            </div>
        );
    }
}

const StyledContainer = styled.div`
    .container {
        display: none;
    }
    .container.show {
        display: block;
    }
    .container.sso-container {
        padding-top: 0 !important;
        margin-top: -8px !important;
        .or-text {
            color: #47525d;
        }
    }
`;

export default Login;
