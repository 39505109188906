import React from "react";
import { i18n, Request } from "../lib";
import { AdminUtil, StudentUtil } from "../lib/utils";
import { Sidebar, InteractiveTable } from "../components";
import { Statistics } from "./pages";
import { Loader } from "semantic-ui-react";
import * as Modal from "../components/modals";
import _ from "lodash";

import "../scss/Admin.scss";

const dashboardModel = {
    key: "OVERVIEW",
    pages: [{ key: "DASHBOARD", icon: "\u00B2" }],
};
const usersModel = {
    key: "ALL_USERS",
    pages: [
        { key: "ADVISORS", icon: "8" },
        { key: "STUDENTS", icon: "y" },
        // { key: 'PROSPECTS', icon: 'y' }
    ],
};
export default class Admin extends React.Component {
    state = {
        page: "",
        advisors: [],
        students: [],
        showNewStudentModal: false,
        showNewAdvisorModal: false,
        loaded: false,
    };
    constructor() {
        super();
        this.changePage = this.changePage.bind(this);
        this.addAdvisors = this.addAdvisors.bind(this);
        this.updateAdvisor = this.updateAdvisor.bind(this);
        this.updateStudent = this.updateStudent.bind(this);
        this.saveAdvisor = this.saveAdvisor.bind(this);
        this.saveStudent = this.saveStudent.bind(this);
        this.deleteAdvisor = this.deleteAdvisor.bind(this);
        this.deleteStudent = this.deleteStudent.bind(this);
        this.addStudent = this.addStudent.bind(this);
        this.showAddAdvisor = this.showAddAdvisor.bind(this);
        this.hideAddAdvisor = this.hideAddAdvisor.bind(this);
        this.showAddStudent = this.showAddStudent.bind(this);
        this.hideAddStudent = this.hideAddStudent.bind(this);
    }
    componentDidMount() {
        if (!AdminUtil.isAdmin()) {
            AdminUtil.bounce();
            return;
        }

        const { route } = this.props;

        AdminUtil.getAdvisorsAndStudents(
            ({ advisors, students, advisorAuthority }) => {
                this.setState({
                    page: route.params.page.toUpperCase(),
                    advisors: advisors,
                    students: students,
                    advisorAuthority: advisorAuthority,
                    loaded: true,
                });
            }
        );
    }
    updateAdvisor(id, key, value) {
        AdminUtil.updateAdvisor(id, key, value);
        this.setState({ advisors: AdminUtil.getAdvisors() });
    }
    saveAdvisor(id) {
        AdminUtil.save("Advisor", id);
    }
    deleteAdvisor(id) {}
    addAdvisors(campus, advisors, onFail) {
        // setTimeout(() => {
        //     AdminUtil.refreshAdvisors(( newAdvisors ) => {
        //         this.setState({
        //             advisors: newAdvisors,
        //             showNewAdvisorModal: false
        //         });
        //     });
        // }, 500);
        Request.POST("admin/batch/Advisor", {
            data: {
                campus: campus,
                advisors: advisors,
            },
            onSuccess: ({ invalidAdvisors }) => {
                // get full list of old and new advisors
                AdminUtil.refreshAdvisors((allAdvisors) => {
                    if (invalidAdvisors.length > 0) {
                        this.setState({
                            advisors: allAdvisors,
                        });
                        onFail(200, null, invalidAdvisors);
                    } else {
                        this.setState({
                            advisors: allAdvisors,
                            showNewAdvisorModal: false,
                        });
                    }
                });
            },
            onFail: onFail,
        });
    }
    updateStudent(id, key, value) {
        AdminUtil.updateStudent(id, key, value);
        this.setState({
            advisors: AdminUtil.getAdvisors(),
            students: AdminUtil.getStudents(),
        });
    }
    saveStudent(id) {
        AdminUtil.save("Student", id);
    }
    deleteStudent(id) {}
    addStudent(areaCode, name, phoneNumber, kind) {
        StudentUtil.addStudent(areaCode, name, phoneNumber, kind, (user) => {
            AdminUtil.addStudent(user);
            AdminUtil.save("Student", user._id);
            this.setState({
                advisors: AdminUtil.getAdvisors(),
                students: AdminUtil.getStudents(),
                showNewStudentModal: false,
            });
        });
    }
    showAddAdvisor() {
        this.setState({ showNewAdvisorModal: true });
    }
    hideAddAdvisor() {
        this.setState({ showNewAdvisorModal: false });
    }
    showAddStudent() {
        this.setState({ showNewStudentModal: true });
    }
    hideAddStudent() {
        this.setState({ showNewStudentModal: false });
    }
    isPageEnabled(page) {
        switch (page) {
            case "DASHBOARD":
                return AdminUtil.showDashboard();
            default:
                return true;
        }
    }
    changePage(page) {
        if (page === this.state.page) {
            return;
        }
        this.props.route.goTo("/admin/" + page.toLowerCase());
        this.setState({ page });
    }
    render() {
        const {
            page,
            loaded,
            showNewStudentModal,
            showNewAdvisorModal,
        } = this.state;

        const pageModel = [];
        if (AdminUtil.showDashboard()) {
            pageModel.push(dashboardModel);
        }
        pageModel.push(usersModel);

        return (
            <div className="View Admin">
                {loaded && (
                    <>
                        <Sidebar
                            parent="ADMIN"
                            page={page}
                            model={pageModel}
                            changePage={this.changePage}
                        />
                        <div className="main">{this.renderPage()}</div>
                    </>
                )}
                <Loader active={!loaded} />
                {showNewStudentModal && (
                    <Modal.NewStudent
                        close={this.hideAddStudent}
                        create={this.addStudent}
                    />
                )}
                {showNewAdvisorModal && (
                    <Modal.NewAdvisor
                        close={this.hideAddAdvisor}
                        create={this.addAdvisors}
                    />
                )}
            </div>
        );
    }
    renderPage() {
        const { page, advisors, students, advisorAuthority } = this.state;
        const tableModel = AdminUtil.getTableModel();
        let addAdvisor = false;
        let addStudent = false;
        if (!this.isPageEnabled(page)) {
            this.changePage("ADVISORS");
            return;
        }
        if (advisorAuthority && advisorAuthority.canAddAdvisor) {
            addAdvisor = true;
        }
        if (advisorAuthority && advisorAuthority.canAddStudent) {
            addStudent = true;
        }

        switch (page) {
            case "ADVISORS":
                return (
                    <InteractiveTable
                        editable
                        searchTable
                        key="ADMIN_ADVISORS"
                        tableKey={"ADMIN_ADVISORS"}
                        model={tableModel.advisors}
                        data={advisors}
                        searchSource={AdminUtil.getSearchableAdvisors()}
                        // filters={datastore.get('FILTERS').filters}
                        // cutoff={datastore.get('FILTERS').cutoff}
                        title={i18n.t("ADMIN.ADVISORS")}
                        addMsg={
                            addAdvisor ? i18n.t("ADMIN.ADD_ADVISORS") : null
                        }
                        emptyMsg={i18n.t("ADMIN.NO_ADVISORS")}
                        icon="8"
                        addNew={this.showAddAdvisor}
                        onUpdate={this.updateAdvisor}
                        onSave={this.saveAdvisor}
                        // onDelete={this.deleteAdvisor}
                    />
                );
            case "STUDENTS":
                const activeStudents = _.filter(students, (student) => {
                    return student.hasOwnProperty("advisor");
                });
                return (
                    <InteractiveTable
                        editable
                        searchTable
                        key="ADMIN_STUDENTS"
                        tableKey={"ADMIN_STUDENTS"}
                        model={tableModel.students}
                        data={activeStudents}
                        searchSource={AdminUtil.getSearchableStudents(
                            activeStudents
                        )}
                        // filters={datastore.get('FILTERS').filters}
                        // cutoff={datastore.get('FILTERS').cutoff}
                        title={i18n.t("ADMIN.STUDENTS")}
                        addMsg={
                            addStudent ? i18n.t("STUDENTS.NEW_STUDENT") : null
                        }
                        emptyMsg={i18n.t("STUDENTS.NO_STUDENTS")}
                        icon="y"
                        addNew={this.showAddStudent}
                        onUpdate={this.updateStudent}
                        onSave={this.saveStudent}
                        // onDelete={this.deleteStudent}
                    />
                );
            case "PROSPECTS":
                const prospects = _.filter(students, (student) => {
                    return (
                        !student.hasOwnProperty("advisor") &&
                        student.hasOwnProperty("campus")
                    );
                });
                return (
                    <InteractiveTable
                        editable
                        searchTable
                        key="ADMIN_PROSPECTS"
                        tableKey={"ADMIN_PROSPECTS"}
                        model={tableModel.students}
                        data={prospects}
                        searchSource={AdminUtil.getSearchableStudents(
                            prospects
                        )}
                        title={i18n.t("ADMIN.PROSPECTS")}
                        emptyMsg={i18n.t("ADMIN.NO_PROSPECTS")}
                        icon="y"
                        onUpdate={this.updateStudent}
                        onSave={this.saveStudent}
                    />
                );
            case "DASHBOARD":
                return <Statistics />;
            default:
                return <></>;
        }
    }
}
