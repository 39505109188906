import React, { Component } from "react";
import { Button as AntdButton, Col, Row, Modal, message } from "antd";
import { Request } from "../../lib";
import Enroll from "./Enroll";
import UpdateSubmission from "./UpdateSubmission";
import { RippleButton } from "../../components";
import ChangeStatus from "./ChangeStatus";
import BindStudent from "./BindStudent";
import { ApplyStatusInModalTitle } from "components/ApplyStatus";
import styled, { createGlobalStyle } from "styled-components";

/**
 * props结构说明
 * props{
 *  data{
 *      choosestatus:[选校状态]
 *      applyStatus:[申请状态]
 *      name:[学生姓名]
 *      schools:[][选校]
 *  }
 *  visible:[是否显示],
 *  fetchData:function[更新状态后刷新数据]
 * }
 */

class More extends Component {
    state = {
        statusChange: null,
        visible: false,
        data: [],
        currentPage: 1,
        total: 50,
        pageSize: 5,
        isChangeStatusVisible: false,
        isUpdateSubmissionVisible: false,
        isEnrollvisible: false,
        isBindStudentvisible: false,
        uploadok: true,
        selectok: true,
    };
    componentWillMount = () => { };
    color = "red";

    onOk = () => {
        this.props.fetchData();
    };
    onCancel = () => {
        this.setState({
            visible: false,
            isChangeStatusVisible: false,
            isUpdateSubmissionVisible: false,
            isEnrollvisible: false,
            isBindStudentvisible: false,
        });
    };

    syncStudent = () => {
        this.setState({ uploadok: false });
        Request.GET("sync/student/" + this.props.contractId, {
            onSuccess: (data) => {
                this.setState({ uploadok: true });
                message.info("同步成功");
                this.props.fetchData();
            },
            onFail: (status, error) => {
                if (status === 401) return true;
                this.setState({ uploadok: true });
                message.error("同步错误");
            },
        });
    };

    syncSelectSchool = () => {
        this.setState({ selectok: false });
        Request.GET("sync/selectschool/" + this.props.contractId, {
            onSuccess: (data) => {
                this.setState({ selectok: true });
                this.props.fetchData();
                message.info("同步成功");
            },
            onFail: (status, error) => {
                if (status === 401) return true;
                this.setState({ selectok: true });
                message.error("同步错误");
            },
        });
    };
    closeModal = () => {
        this.setState({visible:false});
    }

    render() {
        return (
            <>
                <AntdButton
                    onClick={() => {
                        this.setState({ visible: true });
                    }}
                    type="link"
                    style={{
                        padding: "0px",
                        margin: "0px",
                        height: "20px",
                        ...this.props.style,
                    }}
                >
                    更多...
                </AntdButton>
                <GlobalStyle />
                <Modal
                    visible={this.state.visible}
                    title={
                        <ApplyStatusInModalTitle
                            studentName={this.props.studentName}
                            statusCode={this.props.applyStatus}
                            titleText="学生操作"
                        />
                    }
                    centered={true}
                    onCancel={this.onCancel}
                    width="450"
                    footer={[
                        <RippleButton
                            key="close"
                            empty
                            flat
                            text="关闭"
                            onClick={this.onCancel}
                        />,
                    ]}
                    wrapClassName="more-actions-modal"
                >
                    <StyledContainer>
                        <p>
                            <AntdButton
                                onClick={() => {
                                    this.setState({
                                        isChangeStatusVisible: true,
                                    });
                                }}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        更改状态
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            更改学生当前申请状态
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                            <ChangeStatus
                                applyStatus={this.props.applyStatus}
                                contractId={this.props.contractId}
                                studentName={this.props.studentName}
                                fetchData={() => {
                                    this.props.fetchData();
                                    this.setState({
                                        isChangeStatusVisible: false,
                                    });
                                }}
                                isShowText={false}
                                visible={this.state.isChangeStatusVisible}
                                closeModal={this.closeModal}
                                onCancel={() => {
                                    this.setState({
                                        isChangeStatusVisible: false,
                                    });
                                }}
                            />
                        </p>
                        <p>
                            <AntdButton
                                onClick={this.syncStudent}
                                disabled={!this.state.uploadok}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        回传信息
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            同步上传学生基本信息到ERP系统
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                        </p>
                        <p>
                            <AntdButton
                                onClick={this.syncSelectSchool}
                                disabled={!this.state.selectok}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        读取协议
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            从ERP系统中拉取选校信息
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                        </p>
                        <p>
                            <AntdButton
                                onClick={() => {
                                    this.setState({
                                        isUpdateSubmissionVisible: true,
                                    });
                                }}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        更新协议
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            更新学生选校协议，同步到ERP系统
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                            <UpdateSubmission
                                studentId={this.props.studentId}
                                contractId={this.props.contractId}
                                studentName={this.props.studentName}
                                applyStatus={this.props.applyStatus}
                                fetchData={this.props.fetchData}
                                applyAdvisor={this.props.applyAdvisor}
                                isShowText={false}
                                visible={this.state.isUpdateSubmissionVisible}
                                onCancel={() => {
                                    this.setState({
                                        isUpdateSubmissionVisible: false,
                                    });
                                }}
                            />
                        </p>
                        <p>
                            <AntdButton
                                style={{ width: "400px" }}
                                onClick={() => {
                                    this.setState({ isEnrollvisible: true });
                                }}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        录取管理
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            管理和上报学生录取信息
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                            <Enroll
                                studentId={this.props.studentId}
                                studentName={this.props.studentName}
                                applyStatus={this.props.applyStatus}
                                fetchData={this.props.fetchData}
                                visible={this.state.isEnrollvisible}
                                contractId={this.props.contractId}
                                onCancel={() => {
                                    this.setState({
                                        isEnrollvisible: false,
                                    });
                                }}
                            />
                        </p>
                        <p>
                            <AntdButton
                                style={{ width: "400px" }}
                                onClick={() => {
                                    this.setState({
                                        isBindStudentvisible: true,
                                    });
                                }}
                            >
                                <Row className="more-dialog-row">
                                    <Col
                                        span={7}
                                        style={{
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}
                                    >
                                        测评帐号
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{ textAlign: "left" }}
                                    >
                                        <span style={{ fontSize: "14px" }}>
                                            绑定前期后期学生
                                        </span>
                                    </Col>
                                </Row>
                            </AntdButton>
                            <BindStudent
                                studentId={this.props.studentId}
                                studentName={this.props.studentName}
                                applyStatus={this.props.applyStatus}
                                fetchData={(visible) => { this.setState({ isBindStudentvisible: visible }); this.props.fetchData() }}
                                visible={this.state.isBindStudentvisible}
                                onCancel={() => {
                                    this.setState({
                                        isBindStudentvisible: false,
                                    });
                                }}
                            />
                        </p>
                    </StyledContainer>
                </Modal>
            </>
        );
    }
}

const StyledContainer = styled.div`
    > p {
        margin-bottom: 8px;
    }
    .ant-btn {
        width: 400px;
        height: 40px;
        border-radius: 5px;
    }
    .ant-row.more-dialog-row {
        align-items: flex-end;
    }
`;

const GlobalStyle = createGlobalStyle`
	.more-actions-modal .ant-modal-body{
        padding: 0 24px;
	}

`;

export default More;
