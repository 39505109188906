import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import cn from "classnames";
import { ReactComponent as ExcelSvg } from "img/excel.svg";
import { ReactComponent as ExcelBlueSvg } from "img/excel-blue.svg";
import { ReactComponent as UserSvg } from "img/user.svg";
import { ReactComponent as UserBlueSvg } from "img/user-blue.svg";
import { ReactComponent as DashSvg } from "img/dashboard.svg";
import { ReactComponent as DashBlueSvg } from "img/dashboard-blue.svg";
import { Tooltip } from "antd";
import Router from "lib/Router";
import { AdminUtil } from "lib/utils";

const PAGE_EXCEL = "trackingsheet";
const PAGE_ASSESSMENT = "assessment";
const PAGE_DASHBOARD = "dashboard";

const LeftNavBar = ({ currentPage }) => {
    return (
        <StyledLeftNavBar>
            <ul style={{ padding: 0 }}>
                {/* dashboard*/}
                <li
                    className={cn([
                        "nav-item",
                        { current: currentPage === PAGE_DASHBOARD },
                    ])}
                >
                    <Tooltip placement="rightTop" title="申请系统统计">
                        <span
                            className="icon-wrapper"
                            onClick={() => {
                                AdminUtil.resetLastRoute();
                                Router.goTo("/dashboard");
                            }}
                        >
                            {currentPage === PAGE_DASHBOARD ? (
                                <DashBlueSvg />
                            ) : (
                                <DashSvg />
                            )}
                        </span>
                    </Tooltip>
                </li>

                {/* applying tracking*/}
                <li
                    className={cn([
                        "nav-item",
                        { current: currentPage === PAGE_EXCEL },
                    ])}
                >
                    <Tooltip placement="rightTop" title="申请追踪系统">
                        <span
                            className="icon-wrapper"
                            onClick={() => {
                                AdminUtil.resetLastRoute();
                                Router.goTo("/trackingsheet");
                            }}
                        >
                            {currentPage === PAGE_EXCEL ? (
                                <ExcelBlueSvg />
                            ) : (
                                <ExcelSvg />
                            )}
                        </span>
                    </Tooltip>
                </li>

                {/* assessment*/}
                <li
                    className={cn([
                        "nav-item",
                        { current: currentPage === PAGE_ASSESSMENT },
                    ])}
                >
                    <Tooltip placement="rightTop" title="测评系统">
                        <span
                            className="icon-wrapper"
                            onClick={() => {
                                Router.goTo("/");
                            }}
                        >
                            {currentPage === PAGE_ASSESSMENT ? (
                                <UserBlueSvg />
                            ) : (
                                <UserSvg />
                            )}
                        </span>
                    </Tooltip>
                </li>
            </ul>
        </StyledLeftNavBar>
    );
};

LeftNavBar.propTypes = {
    currentPage: propTypes.string.isRequired,
};

const StyledLeftNavBar = styled.div.attrs({
    className: "left-nav-bar",
})`
    width: 50px;
    height: 100%;
    padding-top: 10px;
    position: absolute;
    background-color: #3c495c;
    color: #e8eaed;
    z-index: 1;
    .nav-item {
        list-style: none;
        display: flex;
        align-items: center;
        height: 38px;
        cursor: pointer;
        margin: 0 6px;
        padding-left: 8px;
    }

    .nav-item.current {
        background: #45a0ff29 0% 0% no-repeat padding-box;
        border-radius: 6px;
    }

    .icon-wrapper {
        display: block;
        height: 24px;
        width: 24px;
    }
`;

export default LeftNavBar;
