import React, { Component } from 'react'
import * as Widget from "../../components/widgets"
import { ExamProgressModel } from "../../data/model"
import { Loader } from 'semantic-ui-react'
// import { i18n } from '../../lib';

class ExamProgress extends Component {
    processExamData(){
        const { data } = this.props;

        if (!data){
            return [];
        }

        const examData = {};
        for (var i=0; i<ExamProgressModel.length; i++){
            examData[ExamProgressModel[i].type] = [];
        }

        for (var t=0; t<data.length; t++){
            const { type, subject } = data[t];
            const testData = Object.assign({}, data[t]);
            testData.subject = subject;
            examData[type].push(testData);
        }
        return examData;
    }
    render() {
        const { studentId, data } = this.props;
        if (data === null){
            return <div className={"Page"} style={{height: '100%'}}>
                <Loader active/>
            </div>
        }

        const examData = this.processExamData();
        return (
            <div className={"Page Exams"}>
                { ExamProgressModel.map(({ type, columns, bars, max } ) => (
                    <Widget.ExamInfo 
                        title={type}
                        key={type}
                        studentId={studentId}
                        data={examData[type]}
                        type={type}
                        bars={bars}
                        max={max}
                        columns={columns}
                    />
                ))}
            </div>
        )
    }
}
export default ExamProgress;
