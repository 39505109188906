import React from "react";
import Tab from "./Tab";

const tableConfigs = [
    {
        key: "progress",
        firstColumnText: "入读确认进度",
        path: "admission/progress",
    },
    {
        key: "requestI20",
        firstColumnText: "Request I20",
        path: "admission/requestI20",
    },
    {
        key: "arrivedI20",
        firstColumnText: "I20到达情况",
        path: "admission/arrivedI20",
    },
    {
        key: "openedToStu",
        firstColumnText: "是否开放给学生",
        path: "admission/openedToStu",
    },
    {
        key: "deposit",
        firstColumnText: "缴纳入学定金",
        path: "admission/deposit",
    },
    {
        key: "pickup",
        firstColumnText: "预定接机",
        path: "admission/pickup",
    },
    {
        key: "bookroom",
        firstColumnText: "预定住宿",
        path: "admission/bookroom",
    },
    {
        key: "signTime",
        firstColumnText: "预约签证培训",
        path: "admission/signTime",
    },
    {
        key: "sendVisa",
        firstColumnText: "发送签证/行前材料",
        path: "admission/sendVisa",
    },
    {
        key: "visaResult",
        firstColumnText: "签证结果",
        path: "admission/visaResult",
    },
    {
        key: "submitVaccineTable",
        firstColumnText: "提交疫苗表情况",
        path: "admission/submitVaccineTable",
    },
    {
        key: "submitFinish",
        firstColumnText: "是否报完成",
        path: "admission/submitFinish",
    },
];
function AdmissionTab() {
    return <Tab tableConfigs={tableConfigs} />;
}

export default AdmissionTab;
