import React, { Component } from 'react';
import { i18n } from "../lib"

export default class PasswordReqs extends Component {
    render(){
        console.log("VALIDATION: ", this.props.validation);
        const {length, uppercase, lowercase, numeral } = this.props.validation;
        return (
            <ul className="PasswordRequirements">
                <h2>
                    {i18n.t('PASSWORD.REQUIRE_PROMPT')}
                </h2>
                <li className={getClass(length)}>
                    {i18n.t('PASSWORD.REQUIRE_LENGTH')}
                </li>
                <li className={getClass(uppercase)}>
                    {i18n.t('PASSWORD.REQUIRE_UPPERCASE')}
                </li>
                <li className={getClass(lowercase)}>
                    {i18n.t('PASSWORD.REQUIRE_LOWERCASE')}
                </li>
                <li className={getClass(numeral)}>
                    {i18n.t('PASSWORD.REQUIRE_NUMERAL')}
                </li>
            </ul>
        )
    }
}

function getClass(val){
    return val === false ? "invalid" : "";
}