import React, { useState, useCallback } from "react";
import { AutoComplete } from "antd";
import _ from "lodash";

const AutoOption = AutoComplete.Option;

const Complete = (props) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState('');
    const autoChild = options.map((v) => (
        <AutoOption key={v.key} value={props.mergeKV ? v.key + v.value : v.value}>
            {v.desc}
        </AutoOption>
    ));
    const onSearch = useCallback(
        _.debounce(
            async (searchText) => {
                setValue(searchText);
                props.onSearch(setOptions, searchText);
            },
            400
        ),
        [setOptions]
    );
    const onFocus = async (searchText, e) => {
        setValue(searchText.target.value);
        props.onSearch(setOptions, searchText.target.value);
    };

    const onSelect = (e, option) => {
        setValue(option.children);
        props.onSelect(option);
    };

    return (
        <div>
            <AutoComplete
                size={props.size ? props.size : 'default'}
                style={props.style ? props.style : { width: 280 }}
                onSelect={onSelect}
                onSearch={onSearch}
                onFocus={onFocus}
                placeholder={props.placeholder ? props.placeholder : "查询"}
                onChange={props.onChange ? props.onChange : null}
                className={props.className ? props.className : null}
                value={props.allowValue ? props.value : value}
                allowClear={true}
            >
                {autoChild}
            </AutoComplete>
        </div>
    );
};
export default Complete;
