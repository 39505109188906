import Polyglot from "./polyglot"
import language from "./data/en"
import { URLParser } from "../utils"
import { enUS, zhCN } from 'date-fns/locale'
// import React from 'react'

// let polyglot = null;
let polyglot = new Polyglot({phrases: language});
let DEFAULT_LANGUAGE = 'zh';
let locale = {
    en: enUS,
    zh: zhCN
};

export default {
    language: DEFAULT_LANGUAGE,
    /**
     * This method allow you to switch language during runtime, language argument should be the same as your data file name 
     * such as when language is 'zh', it will load your 'zh.js' data source.
     * @method init 
     * @param language - the language specific data file name, such as 'zh' to load 'zh.js'
     */
    init (language) {
        if (!language){
            language = URLParser.run().search.language || DEFAULT_LANGUAGE;
        }
        this.language = language;
        this.locale = locale[language];
        
        let data = require("./data/" + language);
        polyglot.replace(data);
    },
    updateLangMap(){

    },
    /**
     * this method takes a text key as input, and return the localized string
     * Please read https://github.com/airbnb/polyglot.js for details
     * @method t
     * @return {String} localized string
     * @example
     * 
     * var myText = i18n.t('MY_TEXT_KEY');
     * 
     * // if your data source is defined as 
     * // {"hello_name": "Hello, %{name}"}
     * // you can use the following to interpolate the text 
     * var greetingText = i18n.t('hello_name', {name: 'nantas'}); // Hello, nantas
     */    
    t (key, opt) {
        // if (this.language === "test"){
        //     return (
        //         <input className="translation" value={polyglot.t(key, opt)} />
        //     )
        // }
        return polyglot.t(key, opt);
    }
};