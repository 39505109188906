import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ReportHeader from "../ReportHeader";
import Pager from "../Pager";
import ScoreSlide from "report/component/ScoreSlide";
import planningSchemeImg from "img/report/vision2022/planning-scheme.png";
import { level1, level2 } from "lib/i18n/data/globalCompetenceText";

function getLevel2Description(score, level2Copywriting) {
    if (score >= 80) {
        return level2Copywriting.s80;
    } else if (score >= 60) {
        return level2Copywriting.s60;
    }

    return level2Copywriting.s60minus;
}

function getLevel2Distribution(score) {
    if (score >= 80) {
        return "高";
    } else if (score >= 60) {
        return "中";
    }

    return "低";
}

function getLevel1PlanningText(score, level1Copywriting) {
    if (score >= 60) {
        return level1Copywriting.excellent;
    }
    return level1Copywriting.enhanced;
}

function CompetenceDetail({ title, traitsThreshold, pageData }) {
    const level1Copywriting = _.find(level1, { uKey: pageData.level1?.key });

    return (
        <StyledContainer>
            <ReportHeader title={title} />
            {pageData.level1 ? (
                <div className="catagory-summary">
                    <div className="catagory-type">
                        {level1Copywriting.title}
                    </div>
                    <div className="catagory-desc">
                        {level1Copywriting.description}
                    </div>
                    <div className="score-block">
                        <div className="score-text">综合得分</div>
                        <div className="score-value">
                            {Math.floor(pageData.level1.score)}
                        </div>
                    </div>
                    <div className="planning-scheme-title">
                        <img alt="" src={planningSchemeImg} />
                        <span>规划方案</span>
                    </div>
                    <div className="planning-scheme-desc">
                        {getLevel1PlanningText(
                            pageData.level1.score,
                            level1Copywriting
                        )}
                    </div>
                </div>
            ) : null}

            {_.map(pageData.level2, function (score, key) {
                const level2Copywriting = _.find(level2, {
                    uKey: key,
                });
                return (
                    <div className="catagory-list" key={key}>
                        <div className="trait-name">
                            <div className="name-color"></div>
                            <div className="name-title">
                                {level2Copywriting.title}
                            </div>
                            <div className="name-level">
                                {getLevel2Distribution(score)}
                            </div>
                        </div>
                        <div className="trait-definition">
                            <div className="whatis-title">定义</div>
                            <div className="whatis-description">
                                {getLevel2Description(score, level2Copywriting)}
                            </div>
                        </div>
                        <div className="score-slide-wrapper">
                            <ScoreSlide
                                trait={{ score: score }}
                                isRedDot
                                traitsThreshold={traitsThreshold}
                            />
                        </div>
                    </div>
                );
            })}

            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    padding-bottom: 50px;
    .section-header {
        margin-bottom: 50px;
    }

    .catagory-summary {
        margin-left: 42px;
        margin-right: 42px;
        margin-bottom: 40px;
        position: relative;
        .catagory-type {
            font-weight: bold;
            font-size: 30px;
            line-height: 43px;
            color: #1b2224;
            margin-bottom: 8px;
        }
        .catagory-desc {
            width: 569px;
            font-size: 14px;
            line-height: 22px;
            color: #47525d;
            margin-bottom: 32px;
        }
        .score-block {
            background: #f7f9fa;
            padding: 8px 16px 0;
            border-radius: 4px;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .score-text {
                font-size: 14px;
                line-height: 22px;
                color: #60728e;
            }
            .score-value {
                font-weight: bold;
                font-size: 48px;
                line-height: 70px;
                color: #00b6ff;
            }
        }
        .planning-scheme-title {
            margin-bottom: 8px;
            height: 30px;
            img {
                height: 30px;
                width: 30px;
                margin-right: 8px;
            }
            span {
                vertical-align: middle;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: #4386cb;
            }
        }
        .planning-scheme-desc {
            ont-size: 14px;
            line-height: 22px;
            color: #60728e;
        }
    }
    .trait-name {
        background-color: #f7f9fa;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 38px;
        position: relative;
        align-items: center;
        margin-bottom: 16px;
        .name-color {
            width: 20px;
            height: 38px;
            background-color: #00b6ff;
        }
        .name-title {
            color: #1b2224;
            font-weight: bold;
            font-size: 18px;
            margin-left: 22px;
        }
        .name-level {
            position: absolute;
            color: #00b6ff;
            font-weight: bold;
            font-size: 18px;
            top: 6px;
            right: 42px;
        }
    }
    .trait-definition {
        margin-left: 60px;
        margin-right: 42px;
        margin-bottom: 24px;
        .whatis-title {
            font-weight: bold;
            font-size: 14px;
            color: #4386cb;
            line-height: 22px;
            margin-bottom: 4px;
        }
        .whatis-description {
            font-size: 14px;
            line-height: 22px;
            color: #60728e;
        }
    }
    .score-slide-wrapper {
        width: 100%;
        padding-left: 60px;
        padding-right: 42px;
        margin-bottom: 32px;
    }
`;

CompetenceDetail.propTypes = {
    title: propTypes.string.isRequired,
    pageData: propTypes.object.isRequired,
};

export default CompetenceDetail;
