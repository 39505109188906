import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Shade extends React.Component {
    state = {
        shade:false,
    }
    constructor(props){
        super(props);
        Shade.show = this.show.bind(this);
        Shade.hide = this.hide.bind(this);
    };
    show = ()=>{
       this.setState({shade:true});
    };
    hide = ()=>{
        this.setState({shade:false});
     };
    render(){
        return(
            <Spin spinning={this.state.shade} indicator={antIcon} >   
                {this.props.children} 
            </Spin>
        )
    }
}

export default Shade;

