import React, { Component } from 'react'
import * as Modal from "../components/modals"
import { i18n } from "../lib"
import { formatDate, NoteUtil } from "../lib/utils"
import { Loader } from 'semantic-ui-react'

/**
 * @class Note Directory
 * 
 * The note directory is shared between the note page and the note drawer. It 
 * (optionally) filters the notes by page (in the case of the notes drawer) or 
 * displays all notes (notes page). From the Note Directory, the user can delete
 * or open a note (adding and editing occur in different components). 
 * 
 * @prop {Array}  notes     all note objects for the current student
 * @prop {String} selected  the id (or null) of the currently selected note
 * @prop {String} page      the current page (if any) to use to filter the notes
 * @prop {Function} select  handler that is called when a note is selected. Passes
 *                              the selected id to the parent, in order to update
 *                              other components (i.e. NoteEditor) that need to know
 *                              the currently selected note id. 
 */
class NoteDirectory extends Component {
    state={
        loaded: false,
        notes: [],
        purgatory: []
    }
    // componentDidMount(){
    //     this.updateNotes();
    //     NoteUtil.onSave = this.updateNotes.bind(this);
    // }
    // updateNotes(){
    //     this.setState({
    //         notes: NoteUtil.getSavedNotes(this.props.userid),
    //         loaded: true
    //     })
    // }
    /**
     * Delete a note, by id
     * 
     * Remove note from directory tree and dispatch a delete request to the 
     * server, via NoteUtil. Also prevent the click event from trying to open 
     * the deleted note.
     * 
     * @param {String} id   note id 
     * @param {Event} e     click event
     */
    delete(id, e){
        e.stopPropagation();
        const { notes, selected } = this.props;

        if (id !== -1){
            this.showUndoNotice(id);
        } 

        NoteUtil.deleteNote(id);
        if (selected === id){
            for (var n=0; n<notes.length; n++){
                if (notes[n].id !== id && !notes[n].deleted){
                    this.props.select(notes[n].id);
                    return;
                }
            }
        }
    }
    /**
     * Show undo notice. 
     * 
     * Undo notice will render for each item in `purgatory`, so adding a note id
     * to `purgatory` will update the view with an undo notice for the given note.
     * 
     * @param {String} id   note id of recently deleted note
     */
    showUndoNotice(id){
        const purgatory = this.state.purgatory.slice();
        purgatory.push(id);
        this.setState({ purgatory });
    }
    /**
     * Remove undo notice
     * 
     * To close an undo notice, remove the note id from `purgatory`.
     * 
     * @param {String} id note id of recently deleted note
     */
    clearUndoNotice(id){
        const purgatory = this.state.purgatory.slice();
        purgatory.splice(purgatory.indexOf(id), 1);
        this.setState({ purgatory });
    }
    /**
     * Restore a note and clear the undo notice. 
     * 
     * @param {String} id note id of recently deleted note
     */
    restoreNote(id){
        NoteUtil.restoreNote(id);
        this.clearUndoNotice(id);
    }
    /**
     * Permanently destroy a note, with no chance of recovery. 
     * 
     * @param {String} id note id of recently deleted note
     */
    destroyNote(id){
        this.clearUndoNotice(id);
    }
    render() {
        const { selected, page, notes, hasNotes, select } = this.props;
        const loaded = notes !== null;

        if (loaded) {
            // console.log("LOAD NOTE DIRECTORY: ", pageNotes, this.state.purgatory);
            // if (page){
            //     pageNotes = pageNotes.filter(note => note.attachTo === page);
            // }

            notes.sort((a, b) => {
                return (b.editTime || b.updatedAt) - (a.editTime || a.updatedAt);
            })
        }
        return (
            <div className="NoteDirectory">
                {loaded && notes.map(({
                    id, editTime, updatedAt, createdBy, title, body, deleted
                }) => (
                    <div key={id}
                        className={"noteRow" + getSelectors(selected === id, deleted)}
                        onClick={() => { select(id) }}
                    >
                        <h3>{getTitle(title, body)}</h3>
                        <span>{formatDate(editTime || updatedAt)}</span>
                        <span>{createdBy.name}</span>
                        <div className="icon trash" 
                            onClick={(e) => {
                                this.delete(id, e);
                            }}
                        >
                            b
                        </div>
                        <div className="icon go">"</div>
                    </div>
                ))}
                {loaded && !hasNotes &&
                    <div className="no_notes">
                        {i18n.t('PROFILE.NO_NOTES' + (page ? '_ON_THIS_PAGE' : ''))}
                    </div>
                }
                <Loader active={!loaded}/>
                {this.state.purgatory.map((id, i) => (
                    <Modal.Undo key={id}
                        index={i}
                        message={i18n.t('PROFILE.NOTE_DELETED')}
                        undo={() => {this.restoreNote(id)}}
                        close={() => {this.destroyNote(id)}}
                    />
                ))}
            </div>
        )
    }
}

/**
 * Get the display string for a notes title. 
 * 
 * If the note has a title, display the title, otherwise display the first line 
 * of the note body, otherwise display text for NEW_NOTE
 * 
 * @param {String} title note title parameter
 * @param {String} body  note body parameter (includes html tags)
 */
function getTitle(title, body){
    // console.log("GET TITLE: ", title, body);
    if (title && title.length > 0){
        return title;
    } else if (body){
        const rawString = body.split("/n")[0].replace(/<\/*[a-z]+>/g, "");
        if (rawString.length > 0){
            return rawString;
        }
    }
    return i18n.t('PROFILE.NEW_NOTE');
}
/**
 * Add state classes to note class
 * 
 * Add a 'selected' selector if the row is selected, and a 'deleted' selector if
 * it has been deleted (the latter is to animate the row out of view)
 * 
 * @param {boolean} isSelected
 * @param {boolean} deleted 
 */
function getSelectors(isSelected, deleted){
    return (isSelected ? " selected" : "") + (deleted ? " deleted" : "")
}

export default NoteDirectory;
