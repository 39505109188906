export default {
    id: -1,
    title: "",
    body: "<p></p>",
    attachTo: "",
    creationTime: -1,
    updatedAt: -1,
    createdAt: -1,
    updatedBy: "",
    createdBy: ""
}