import _ from "lodash";
// let ROOT = "https://eduworldseoul.moblab.com/";
// let ROOT = "http://127.0.0.1:3000/";
let ROOT = window.location.origin + "/";
if (window.location.origin.indexOf("7546") > -1) {
    ROOT = window.location.protocol + "//localhost:3000/";

    // if use chrome, should disabled sameSite flag in chrome://flags/
    if (process.env.REACT_APP_SERVER_ADDRESS) {
        ROOT = process.env.REACT_APP_SERVER_ADDRESS;
    }
}
const VERSION = 1;
let APP;

const Router = {
    title: "Advisor Console",
    baseUrl: ROOT + "api/" + VERSION + "/",
    app: null,
    params: null,
    userId: null,
    link(app) {
        APP = app;
        window.onpopstate = ({ state }) => {
            // console.log("POP STATE: ", state);
            app.setState({ route: state.route });
        };
    },
    refresh(pathname) {
        window.location.pathname = pathname;
    },
    goTo(pathname, newState = {}) {
        this.setUrl(pathname);
        // console.log("|| ROUTER || go to: ", newState);
        APP.setState({ ...newState, route: pathname });
    },
    setUrl(pathname) {
        Router.savePathtoLocal(pathname);

        let route = pathname + window.location.search;
        window.history.pushState({ route: pathname }, this.title, route);
    },
    setParams(params) {
        this.params = params;
    },

    savePathtoLocal(pathname) {
        if (!Router.userId) {
            return;
        }

        if (!_.values(Router.CachedPaths).includes(pathname)) {
            return;
        }
        localStorage[`${Router.userId}:lastPath`] = pathname;
    },

    CachedPaths: {
        assessment: "/",
        admin: "/admin/dashboard",
        applyingTracking: "/trackingsheet",
        基本信息: "/trackingsheet/baseinfo",
        联系信息: "/trackingsheet/contact",
        考试分数: "/trackingsheet/examinfo",
        高中信息: "/trackingsheet/highschool",
        核心申请: "/trackingsheet/coreapply",
        申请材料: "/trackingsheet/applymaterial",
        院校提交: "/trackingsheet/submissionstatus",
        录取跟踪: "/trackingsheet/trackadmission",
        入读确认: "/trackingsheet/admissioninfo",
        dashboard: "/dashboard",
    },
};

export default Router;
