import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Table } from "antd";
import propTypes from "prop-types";
// data format can refer to dashboardData
// import mockData from "data/test/dashboardData";

function getParsedColumns(advisors, isAllDepartment) {
    const columns = [];

    _.each(advisors, function (item, index) {
        if (item === "$rowTotal") {
            columns.push({
                title: "平均比例",
                dataIndex: "averageRatio",
                key: "averageRatio",
                width: 100,
                fixed: "right",
                align: "center",
                className: "col-ratio",
                render: (value, row) => {
                    let ratioColor = "rgb(37, 193, 56)";

                    let Element = (
                        <>
                            {value !== "0%" ? (
                                <div
                                    className="ratio-bar"
                                    style={{
                                        width: value,
                                        maxWidth: "calc(100% - 6px)",
                                        backgroundColor: ratioColor,
                                    }}
                                ></div>
                            ) : null}
                            <div className="ratio-text">{value}</div>
                        </>
                    );

                    const obj = {
                        children: Element,
                        props: {},
                    };

                    return obj;
                },
            });
        } else {
            columns.push({
                title: item,
                width: isAllDepartment ? 200 : 100,
                children: [
                    {
                        title: "数量",
                        dataIndex: `num${index}`,
                        key: `num${index}`,
                        width: isAllDepartment ? 100 : 50,
                        align: "center",
                    },
                    {
                        title: "比例",
                        dataIndex: `ratio${index}`,
                        key: `ratio${index}`,
                        width: isAllDepartment ? 100 : 50,
                        align: "center",
                        className: "col-ratio",
                        render: (value, row) => {
                            let ratioColor = "rgb(37, 193, 56)";

                            let Element = (
                                <>
                                    {value !== "0%" ? (
                                        <div
                                            className="ratio-bar"
                                            style={{
                                                width: value,
                                                maxWidth: "calc(100% - 6px)",
                                                backgroundColor: ratioColor,
                                            }}
                                        ></div>
                                    ) : null}
                                    <div className="ratio-text">{value}</div>
                                </>
                            );

                            const obj = {
                                children: Element,
                                props: {},
                            };

                            return obj;
                        },
                    },
                ],
            });
        }
    });

    return columns;
}

const groupConfigs = [
    {
        name: "整体情况",
        tables: ["入学季度", "托福分数情况", "SAT分数情况"],
    },
    {
        name: "申请进度",
        tables: [
            "面谈进度",
            "Common注册",
            "简历进度",
            "推荐信写作进度",
            "主文进度",
            "附表文书进度",
            "院校提交进度",
            "SAT/ACT送分",
            "语言成绩送分进度",
            "收集文件进度",
        ],
    },
    {
        name: "跟踪进度",
        tables: ["状态查询进度", "状态是否完整", "录取跟踪进度"],
    },
    {
        name: "入读确认",
        tables: [
            "入读确认进度",
            "Request I20",
            "I20到达情况",
            "是否开放给学生",
            "缴纳入学定金",
            "预定接机",
            "预定住宿",
            "预约签证培训",
            "发送签证/行前材料",
            "签证结果",
            "提交疫苗表情况",
            "是否报完成",
        ],
    },
];

function TabPlus({ advisors, tables, isAllDepartment }) {
    const [tableConfigs, setTableConfigs] = useState(null);

    // console.log("filter", filter);
    useEffect(() => {
        // let tableGroupMap = {};
        // _.each(groupConfigs, function (group) {
        //     const groupName = group.name;
        //     _.each(group.tables, function (table) {
        //         tableGroupMap[table] = groupName;
        //     });
        // });

        // const { advisors, tables } = mockData;

        const parsedColumns = getParsedColumns(advisors, isAllDepartment);

        // expected below format:
        // [{
        //     group: "整体情况",
        //     columns: [],
        //     dataSource: [],
        // }...]
        let parsedTableConfigs = [];
        _.each(tables, function (table) {
            // const group = tableGroupMap[table];
            const columns = [
                {
                    title: table.title,
                    dataIndex: "type",
                    key: "type",
                    width: 120,
                    fixed: "left",
                },
            ].concat(parsedColumns);
            const dataSources = [];
            const $columnTotal = [];
            _.each(table.body, function (body) {
                _.each(body.num, function (num, numIndex) {
                    $columnTotal[numIndex] =
                        ($columnTotal[numIndex] || 0) + num;
                });
            });
            // console.log("$columnTotal", $columnTotal);
            _.each(table.body, function (body) {
                let rowData = {
                    key: body.type,
                    type: body.type,
                };

                _.each(body.num, function (num, numIndex) {
                    let ratio;
                    if ($columnTotal[numIndex] === 0) {
                        ratio = "0%";
                    } else {
                        ratio =
                            Math.round((num / $columnTotal[numIndex]) * 100) +
                            "%";
                    }

                    if (numIndex === body.num.length - 1) {
                        rowData["averageRatio"] = ratio;
                    } else {
                        rowData[`num${numIndex}`] = num;
                        rowData[`ratio${numIndex}`] = ratio;
                    }
                });

                dataSources.push(rowData);
            });
            parsedTableConfigs.push({
                // group,
                title: table.title,
                columns,
                dataSources,
            });
        });
        // console.log(parsedTableConfigs, "parsedTableConfigs");
        setTableConfigs(parsedTableConfigs);
    }, [setTableConfigs, advisors, tables, isAllDepartment]);

    return (
        <StyledContainer>
            {tableConfigs
                ? _.map(groupConfigs, function (group, index) {
                      return (
                          <div key={group.name}>
                              <div className="group-title">{group.name}</div>
                              {_.map(group.tables, function (tableTitle) {
                                  //   console.log(
                                  //       "tableConfigs",
                                  //       tableConfigs,
                                  //       tableTitle
                                  //   );
                                  const config = _.find(tableConfigs, {
                                      title: tableTitle,
                                  });
                                  return config ? (
                                      <Table
                                          columns={config.columns}
                                          dataSource={config.dataSources}
                                          size="small"
                                          pagination={false}
                                          bordered
                                          key={tableTitle}
                                          scroll={{
                                              x: advisors.length * 100 + 120,
                                          }}
                                      />
                                  ) : null;
                              })}
                          </div>
                      );
                  })
                : null}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "dashboard-tab",
})`
    .group-title {
        padding: 20px 20px 10px;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
    }
    .ant-table-wrapper {
        margin-bottom: 25px;
    }
    .ant-table.ant-table-small .ant-table-tbody > tr > th,
    .ant-table.ant-table-small .ant-table-tbody > tr.ant-table-row > td {
        padding: 4px 4px !important;
    }
    .ant-table-cell.ant-table-cell-fix-left,
    .ant-table-cell.ant-table-cell-fix-right {
        z-index: 100;
    }
`;

TabPlus.propTypes = {
    advisors: propTypes.array.isRequired,
    tables: propTypes.array.isRequired,
};

export default TabPlus;
