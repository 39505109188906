import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import propTypes from "prop-types";
import Cover from "./Cover";
import TOC from "./TOC";
import SubSection from "./SubSection";
import About from "./About2";
import TraitReportOverview from "./TraitReportOverview";
import TraitDetailUltimate from "./TraitDetailUltimate";
import MajorOverview from "./MajorOverview";
// import MajorUltimate from "./MajorUltimate";
import MajorUltimatePerMajor from "./MajorUltimatePerMajor";
import BackCover from "./BackCover";
import ReportHeader from "./ReportHeader";
import gaokaoUtils from "./components/gaokaoUtils";

function GaokaoReport({ reportData }) {
    const [majorsBySubName, setMajorsBySubName] = useState([]);

    if (!reportData) {
        return null;
    }

    const hasTraitData = !_.isEmpty(reportData?.traits);
    const hasMajorData = !_.isEmpty(reportData.major_recommendation);

    return (
        <StyledContainer>
            <Cover reportData={reportData} />
            <TOC majorsBySubName={majorsBySubName} />
            <SubSection title={"关于这份报告"} type="about" sectionNo={1} />
            <About title={"关于这份报告"} />
            {hasTraitData && (
                <SubSection title="素质能力" type="trait" sectionNo={2} />
            )}
            {hasTraitData && (
                <TraitReportOverview
                    title={"素质能力"}
                    traitsData={gaokaoUtils.parseTrait(
                        reportData.traits,
                        reportData.traitThreshold?.[0],
                        reportData.traitThreshold?.[1]
                    )}
                    traitsThreshold={{
                        lowerScoreThreshold: reportData.traitThreshold?.[0],
                        upperScoreThreshold: reportData.traitThreshold?.[1],
                    }}
                />
            )}
            {hasTraitData && (
                <table className="wrapper-table">
                    <thead
                        style={{
                            display: "table-header-group",
                        }}
                    >
                        <tr>
                            <td>
                                <div className="header-space">
                                    <ReportHeader title="素质能力" />
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <TraitDetailUltimate
                                    traits={gaokaoUtils.parseTrait(
                                        reportData.traits,
                                        reportData.traitThreshold?.[0],
                                        reportData.traitThreshold?.[1]
                                    )}
                                    title={"素质能力"}
                                    sectionTitle={"素质能力详解"}
                                    sectionDescription="根据您各项素质能力的测评得分，本报告详细列出了您的行为特征及发展建议。"
                                    traitsThreshold={{
                                        lowerScoreThreshold:
                                            reportData.traitThreshold?.[0],
                                        upperScoreThreshold:
                                            reportData.traitThreshold?.[1],
                                    }}
                                    // setTraitsPageNumbers={setTraitsPageNumbers}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot
                        style={{
                            display: "table-footer-group",
                        }}
                    >
                        <tr>
                            <td>
                                <div className="footer-space"></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )}
            {hasMajorData && (
                <>
                    <SubSection
                        title="专业与职业推荐"
                        type="major"
                        sectionNo={3}
                    />
                    <MajorOverview
                        title="专业与职业推荐"
                        majorData={reportData.major_recommendation}
                    />
                </>
            )}
            {hasMajorData &&
                (function () {
                    const majorsBySubNameForToc = [];
                    let pagesNoForUltimatePerMajor = 0;
                    const majors = gaokaoUtils.parseMajors(
                        reportData.major_recommendation
                    );

                    return _.map(majors, function (major, majorIndex) {
                        const pages = gaokaoUtils.getMajorUltimatePages(
                            major,
                            majorIndex === 0
                        );

                        return _.map(pages, function (pageSections, pageIndex) {
                            pagesNoForUltimatePerMajor++;
                            const bannerItem = _.find(pageSections, {
                                type: "banner",
                            });
                            if (bannerItem) {
                                majorsBySubNameForToc.push({
                                    subName: major.name,
                                    majorKey: major.key,
                                    pageNo: pagesNoForUltimatePerMajor,
                                });
                            }

                            // last item
                            if (
                                majors.length === majorIndex + 1 &&
                                pages.length === pageIndex + 1 &&
                                _.isEmpty(majorsBySubName)
                            ) {
                                setMajorsBySubName(majorsBySubNameForToc);
                            }

                            return (
                                <MajorUltimatePerMajor
                                    title={"专业与职业推荐 - " + major.name}
                                    major={major}
                                    pageSections={pageSections}
                                    key={majorIndex + "-" + pageIndex}
                                    setMajorsBySubName={setMajorsBySubName}
                                />
                            );
                        });
                    });
                })()}
            {/* {hasMajorData && (
                <table className="wrapper-table">
                    <thead
                        style={{
                            display: "table-header-group",
                        }}
                    >
                        <tr>
                            <td>
                                <div className="header-space">
                                    <ReportHeader title="专业与职业推荐" />
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <MajorUltimate
                                    traitsPageNumbers={traitsPageNumbers}
                                    majorData={reportData.major_recommendation}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot
                        style={{
                            display: "table-footer-group",
                        }}
                    >
                        <tr>
                            <td>
                                <div className="footer-space"></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )} */}

            <BackCover />
        </StyledContainer>
    );
}
const StyledContainer = styled.div.attrs({
    className: "gaokao-report",
})`
    @media print {
        thead {
            display: table-header-group !important;
        }
        tfoot {
            display: table-footer-group !important;
        }
    }
    .header-space,
    .footer-space {
        height: 40px;
    }
`;

GaokaoReport.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default GaokaoReport;
