import React, { Component } from "react";
import { Table, Modal, message, Button as AntButton } from "antd";
import { Request } from "../../lib";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { RippleButton } from "../../components";
import { i18n } from "../../lib";
import DataDict from "./DataDict";
import _ from "lodash";
import ApplyStatus, { ApplyStatusInModalTitle } from "components/ApplyStatus";

/**
 * props结构说明
 * props{
 *  data{
 *      choosestatus:[选校状态]
 *      applyStatus:[申请状态]
 *      name:[学生姓名]
 *      schools:[][选校]
 *  }
 *  visible:[是否显示],
 *  fetchData:function[更新状态后刷新数据]
 * }
 */

const _applySystem = {
    "0": {
        value: "COMMON系统",
        account: "commonAccount",
        pwd: "commonPwd",
        isUnified: true,
    },
    "1": {
        value: "UC系统",
        account: "UCAccount",
        pwd: "UCPwd",
        isUnified: true,
    },
    "2": {
        value: "CAAS系统",
        account: "CAASAccount",
        pwd: "CAASPwd",
        isUnified: true,
    },
    "3": {
        value: "Apply Texas",
        account: "applyTexasAccount",
        pwd: "independentPwd",
        isUnified: false,
    },
    "4": {
        value: "独立系统",
        account: "independentAccount",
        pwd: "independentPwd",
        isUnified: false,
    },
    "5": {
        value: "合作院校",
        account: "cooperateAccount",
        pwd: "cooperatePwd",
        isUnified: false,
    },
    "6": {
        value: "其他",
        account: "otherAccount",
        pwd: "otherPwd",
        isUnified: false,
    },
};

class UpdateSubmission extends Component {
    state = {
        statusChange: null,
        visible: false,
        data: [],
        // currentPage: 1,
        // total: 0,
        // pageSize: 50,
        ok: true,
        datadict: {
            applyType: DataDict.getMap("moblab:academyinfo:applyType"),
            applySystem: DataDict.getMap("moblab:academyinfo:applySystem"),
            submitProgress: DataDict.getMap(
                "moblab:academyinfo:submitProgress"
            ),
            creditCardPayStatus: DataDict.getMap(
                "moblab:academyinfo:creditCardPayStatus"
            ),
            languageGiveScore: DataDict.getMap(
                "moblab:academyinfo:languageGiveScore"
            ),
            SATACTGiveScore: DataDict.getMap(
                "moblab:academyinfo:SATACTGiveScore"
            ),
            docProgress: DataDict.getMap("moblab:academyinfo:docProgress"),
        },
    };
    componentDidMount = () => {
        this.init();
    };
    color = "red";
    constructor(props) {
        super(props);
        this.renderTag();
    }
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ visible: this.props.visible });
        if (this.props.visible) {
            this.init();
        }
        this.renderTag();
    };
    renderTag = () => {
        this.tag = (
            <ApplyStatus
                statusCode={this.props.applyStatus}
                studentId={this.props.studentId}
                studentName={this.props.studentName}
                fetchData={this.fetchData}
            />
        );
    };
    handleStatusClick = (value) => {
        this.setState({ statusChange: value.key });
    };

    onOk = () => {
        this.setState({ ok: false });
        Request.GET("sync/apply/" + this.props.contractId, {
            onSuccess: (data) => {
                message.info("同步成功");
                this.setState({ ok: true });
                this.init();
            },
            onFail: (status, error) => {
                if (status === 401) return true;
                this.setState({ ok: true });
                message.error("同步错误");
            },
        });
    };
    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.setState({ visible: false });
        }
    };

    init = () => {
        this.fetchData();
    };

    // buildFilter = () => {
    //     return {
    //         currentPage: this.state.currentPage,
    //         pageSize: this.state.pageSize,
    //         studentId: this.props.studentId,
    //     };
    // };
    // pageChange = (page) => {
    //     this.setState({ currentPage: page }, () => {
    //         this.init();
    //     });
    // };
    fetchData = () => {
        Request.GET("academyinfo/" + this.props.contractId, {
            onSuccess: (data) => {
                this.renderDataSource(data);
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("WRONG");
            },
        });
    };

    renderDataSource = (datasource) => {
        let ds = datasource.data;
        for (let i = 0; i < ds.length; i++) {
            ds[i]["key"] = ds[i]["_id"];
            ds[i]["webSubmitDate"] = ds[i]["webSubmitDate"]
                ? moment(ds[i]["webSubmitDate"]).format("YYYY-MM-DD")
                : null;
            ds[i]["overdate"] = ds[i]["overdate"]
                ? moment(ds[i]["overdate"]).format("YYYY-MM-DD")
                : null;
            ds[i]["postDate"] = ds[i]["postDate"]
                ? moment(ds[i]["postDate"]).format("YYYY-MM-DD")
                : null;
            ds[i]["postDate2"] = ds[i]["postDate2"]
                ? moment(ds[i]["postDate2"]).format("YYYY-MM-DD")
                : null;
            ds[i]["schoolId"] = _.isEmpty(ds[i]["schoolId"])
                ? undefined
                // : DataDict.get(`erp:school:en:${ds[i]["schoolId"]}`);
                : ds[i]["schoolNameEn"];
            let account = _applySystem[ds[i].applySystem]
                ? _applySystem[ds[i].applySystem].account
                : null;
            let pwd = _applySystem[ds[i].applySystem]
                ? _applySystem[ds[i].applySystem].pwd
                : null;

            ds[i].applyAccount = account
                ? ds[i].contractInfo[account]
                    ? ds[i].contractInfo[account]
                    : ds[i][account]
                : null;
            ds[i].applyPwd = pwd
                ? ds[i].contractInfo[pwd]
                    ? ds[i].contractInfo[pwd]
                    : ds[i][pwd]
                : null;
        }
        this.setState({
            data: ds,
            currentPage: datasource.currentPage,
            total: ds.length,
        });
    };

    render() {
        /**定义列头 */
        const columns = [
            {
                title: "院校名称",
                dataIndex: "schoolId",
                ellipsis: true,
            },
            {
                title: "申请专业",
                dataIndex: "majorNameCH",
                ellipsis: true,
            },
            {
                title: "实际申请专业",
                dataIndex: "realityMajorName",
                ellipsis: true,
            },
            {
                title: "院校提交进度",
                dataIndex: "submitProgress",
                render: (value) => {
                    return this.state.datadict.submitProgress[
                        value
                    ];
                },
            },
            {
                title: "截止日期",
                dataIndex: "overdate",
                width: '140px'
            },
            {
                title: "申请系统",
                dataIndex: "applySystem",
                render: (value) => {
                    return this.state.datadict.applySystem[value];
                },
            },
            {
                title: "申请类型",
                dataIndex: "applyType",
                render: (value) => {
                    return this.state.datadict.applyType[value];
                },
            },
            {
                title: "注册网站",
                dataIndex: "website",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
            },
            {
                title: "附表文书进度",
                dataIndex: "docProgress",
                render: (value) => {
                    return this.state.datadict.docProgress[value];
                },
            },
            {
                title: "网申提交日期",
                dataIndex: "webSubmitDate",
            },
            {
                title: "信用卡支付情况",
                dataIndex: "creditCardPayStatus",
                render: (value) => {
                    return this.state.datadict.creditCardPayStatus[value];
                },
            },
            {
                title: "申请费",
                dataIndex: "cost",
            },
            {
                title: "语言成绩送分",
                dataIndex: "languageGiveScore",
                render: (value) => {
                    return this.state.datadict.languageGiveScore[value];
                },
            },
            {
                title: "SAT/ACT送分",
                dataIndex: "languageGiveScore",
                render: (value) => {
                    return this.state.datadict.SATACTGiveScore[value];
                },
            },
            {
                title: "运单号",
                dataIndex: "tansId",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
            },
            {
                title: "邮寄日期",
                dataIndex: "postDate",
            },
            {
                title: "运单号2",
                dataIndex: "tansId2",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
            },
            {
                title: "邮寄日期2",
                dataIndex: "postDate2",
            },
        ];
        return (
            <>
                {this.props.isShowText === false ? null : (
                    <AntButton
                        type="link"
                        onClick={() => {
                            this.init();
                            this.setState({ visible: true });
                        }}
                        style={{
                            padding: "0px",
                            margin: "0px",
                            height: "20px",
                        }}
                    >
                        更新协议
                    </AntButton>
                )}
                <Modal
                    wrapClassName="update-submission-model"
                    visible={this.state.visible}
                    title={
                        <ApplyStatusInModalTitle
                            studentName={this.props.studentName}
                            statusCode={this.props.applyStatus}
                            titleText="更新协议"
                        />
                    }
                    centered={true}
                    onCancel={this.onCancel}
                    width="80%"
                    footer={[
                        <RippleButton
                            empty
                            flat
                            text={i18n.t("APP.CANCEL")}
                            onClick={this.onCancel}
                        />,
                        <Button
                            positive
                            disabled={!this.state.ok}
                            content={i18n.t("APP.SUBMIT")}
                            onClick={this.onOk}
                        />,
                    ]}
                >
                    {/* <p>顾问：{this.props.applyAdvisor}</p> */}
                    <p>选校数：{this.state.total}</p>
                    <Table
                        dataSource={this.state.data}
                        columns={columns}
                        bordered
                        pagination={{
                            position: ["bottomCenter"],
                            pageSize: 50,
                            showSizeChanger: false,
                        }}
                        scroll={{ x: 4800, y: 300 }}
                        size="small"
                    />
                </Modal>
            </>
        );
    }
}

export default UpdateSubmission;
