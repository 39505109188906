import { Request } from "../"
import { DataConverter, clamp } from "../utils"
import datastore from "../../data/store"
import * as Model from "../../data/model"

export default {
    /**
     * Profile Component ref, to update global notes state
     */
    profile: null,
    /**
     * Current student's user id, used to ensure that actions are linked to 
     * the correct note, in the case that the user switches users while an 
     * action is being handled. 
     */
    studentId: null,
    /**
     * Link the util to the current user and profile object
     * 
     * @param {String} studentId   currently viewed student's userid
     */
    link(studentId, profile){
        this.profile = profile;
        this.studentId = studentId;
    },
    /**
     * Remove user and profile refs
     */
    unlink(){
        this.profile = null;
        this.studentId = null;
    },
    /**
     * Trigger all components to update their models with the latest version
     * of exams, from the datastore.
     */
    updateExams(){
        if (this.profile){
            this.profile.setState({
                exams: datastore.get("EXAMS")[this.studentId].filter(
                    ({ deleted }) => !deleted
                )
            });
        }
    },
    /**
     * Fetch all of a student's exam data.
     * 
     * Use locally cached versions if they are stored in the datastore, otherwise 
     * dispatch a request for the user's notes and update the store. 
     * 
     * @param {String} userid       currently viewed student's userid
     * @param {Function} callback   callback to execute when exam data is fetched
     */
    getStudentExams(studentId, callback){
        const allExams = datastore.get('EXAMS');
        if (allExams.hasOwnProperty(studentId)){
            callback(allExams[studentId]);
        } else {
            console.warn("Exams requested but not loaded, check render order.");
            Request.GET('advisor/student/' + studentId + '/scores', {
                onSuccess: ({ scores }) => {
                    datastore.get('EXAMS')[studentId] = DataConverter.processExams(scores);
    
                    if (studentId === this.studentId){
                        callback(scores);
                    }
                }
            })
        }
    },
    /**
     * Find exam by id from current student's exams
     * @param {String} examId exam id
     */
    getExamById(examId){
        const exams = datastore.get("EXAMS")[this.studentId];
        return exams.find(res => {
            return res.id.toString() === examId.toString();
        });
    },
    /**
     * Get all subject codes and names for a given exam type
     * 
     * @param {String} type Exam Type
     */
    getSubjects(type){
        return datastore.get('EXAM_TYPES')[type].subjects;
    },
    getSubjectByCode(type, code){
        const testType = datastore.get('EXAM_TYPES')[type];
        if (testType.ref){
            return datastore.get('EXAM_TYPES')[type].ref[code];
        }
        return testType;
    },
    /**
     * Update a single key/value pair in a single exam's data or breakdown
     * 
     * This method is used by the exam page editing table when a single cell is updated.
     * 
     * @param {String} examId    exam id
     * @param {String} key       Field key, can be an exam prop or breakdown field
     * @param {String|Int} value New field value to set
     */
    updateExamDataField(examId, key, value){
        const exam = this.getExamById(examId);
        console.log("UPDATE EXAM FIELD: ", examId, exam, key, value);
        if (key.toUpperCase() === "SCORE") {
            exam.total = clamp(parseInt(value || 0), 0, exam.max);
        } else if (key.toUpperCase() === "SUBJECT"){
            exam.test = value;
            exam.subject = this.getSubjectByCode(exam.type, value).subject.en;
        } else if (exam.hasOwnProperty(key)){
            exam[key] = value;
        } else if (exam.breakdown && exam.breakdown.hasOwnProperty(key)){
            var subjects = 0;
            for (var s in exam.breakdown){
                subjects++;
            }
            exam.breakdown[key] = clamp(parseInt(value || 0), 0, exam.max / subjects);
            exam.total = 0;
            for (var subject in exam.breakdown){
                exam.total += Math.max(exam.breakdown[subject], 0);
            }
        } else {
            console.warn("COULD NOT FIND EXAM FIELD [" + key + "] in EXAM[" + examId + "]", exam);
        }
        this.updateExams();
    },
    /**
     * Create a new exam.
     * 
     * @param {String} type         New exam type (i.e. TOEFL, SAT etc.)
     * @param {Function} callback   Callback once new exam is created on server
     */
    addExam(type, callback){
        const newExam = JSON.parse(JSON.stringify(Model.Exam[type]));
        console.log("ADD EXAM: ", type, newExam);
        const exams = datastore.get("EXAMS")[this.studentId];
        exams.push(newExam);
        this.updateExams();
        callback(newExam);
    },
    /**
     * Save the exam's current state to the server
     * 
     * @param {String} examId exam id
     */
    saveExam(examId){
        const exam = this.getExamById(examId);
        const payload = {
            date: (new Date(exam.date)).toISOString(),
            test: exam.test,
            total: parseInt(exam.total),
            max: parseInt(exam.max),
            breakdown: JSON.stringify(exam.breakdown)
        }
        if (examId !== -1){
            payload.id = exam.id;
            payload.test = this.getSubjectByCode(exam.type, exam.test).id;
        }
        // console.log("SAVE EXAM: ", payload);
        Request.POST('advisor/student/' + this.studentId + '/score', {
            data: payload,
            onSuccess: ({ score }) => {
                if (examId === -1){
                    exam.id = score.id;
                    this.updateExams();
                }
            }
        });
    },
    /**
     * Delete exam from server and mark locally as deleted.
     * 
     * @param {String} examId exam id
     */
    deleteExam(examId){
        const exam = this.getExamById(examId);
        Request.DELETE('advisor/student/' + this.studentId + '/score/' + examId, {});
        exam.deleted = true;
        this.updateExams();
    },
    /**
     * Restore exam from server and mark locally as restored
     * 
     * @param {String} examId exam id
     */
    restoreExam(examId){
        const exam = this.getExamById(examId);
        Request.POST('advisor/student/' + this.studentId + '/score/' + examId + '/restore', {
            data: {}
        });
        exam.deleted = false;
        this.updateExams();
    },
    /**
     * Clean up exams state when the page unmounts.
     */
    cleanup(studentId, skipUpdate){
        const exams = datastore.get("EXAMS")[studentId];
        for (var e=0; e<exams.length; e++){
            if (exams[e].id === -1){
                exams.splice(e, 1);
                if (skipUpdate){
                    return;
                }
                this.updateExams();
                return;
            }
        }
    }
}