import React, { useContext } from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import sectionHeaderImg from "img/report/section/section-topCorner-2202.png";
import sectionFooterImg from "img/report/section/section-illust-bottom@2x.png";
import reportContext from "../reportContext";
import Pager from "./Pager";
import aboutImg from "img/report/section/report-section-header-about.jpg";
import traitImg from "img/report/section/report-section-header-globalTalent.jpg";
import recommendationImg from "img/report/section/report-section-header-growth.jpg";
import majorImg from "img/report/section/report-section-header-majorRec.jpg";

const imageMap = {
    about: aboutImg,
    trait: traitImg,
    major: majorImg,
    recommendation: recommendationImg,
};

function SubSection({ title, type, sectionNo }) {
    const {
        setAboutPageNo,
        setTraitPageNo,
        setMajorPageNo,
        setRecommendationPageNo,
    } = useContext(reportContext);

    const callbackMap = {
        about: setAboutPageNo,
        trait: setTraitPageNo,
        major: setMajorPageNo,
        recommendation: setRecommendationPageNo,
    };

    return (
        <StyledContainer type={String(type).toLowerCase()} id={`toc-${type}`}>
            <div className="section-header">
                <img src={sectionHeaderImg} className="header-img" alt={""} />
            </div>
            <div className="section-body">
                <div className="section-label">PART{sectionNo}</div>
                <div className="section-title">{title}</div>
            </div>
            <img src={sectionFooterImg} className="footer-img" alt={""} />
            <Pager
                callback={callbackMap[String(type).toLowerCase()]}
                isHiddhen
            />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .section-header {
        width: 100%;
        height: 174px;
        position: relative;
        .header-img {
            width: 300px;
            height: 174px;
            position: absolute;
            left: 0;
            top: 0;
        }
        background-image: ${({ type }) => "url(" + imageMap[type] + ")"};
        background-size: cover;
    }
    .section-body {
        display: block !important;
        .section-label {
            margin-top: 80px;
            color: #8a97a5;
            font-size: 100px;
            line-height: 145px;
            font-weight: bold;
        }

        .section-title {
            color: #47525d;
            font-size: 80px;
            line-height: 116px;
            font-weight: bold;
        }
    }
    .footer-img {
        position: absolute;
        right: 0;
        bottom: 2px;
        width: 534px;
        height: 372px;
    }
`;

SubSection.propTypes = {
    title: propTypes.string.isRequired,
    sectionNo: propTypes.number.isRequired,
};

export default SubSection;
