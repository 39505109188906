// var TODO = 'TODO';

module.exports = {
    APP: {
        MOBLAB: "MobLab",
        ADVISOR_CONSOLE: "Advisor Console",
        USERNAME: "Username",
        EMAIL: "Email",
        PASSWORD: "Password",
        FULL_NAME: "Full Name",
        NAME: "Name",
        PHONE: "Phone Number",
        AREA_CODE: "Area Code",
        FILTER: "Filter",
        MANAGE: "Manage",
        SEARCH: "search",
        SAVE: "Save",
        PRINT: "Print",
        CANCEL: "Cancel",
        CREATE: "Create",
        ASSIGN: "Assign",
        SUBMIT: "Submit",
        LOGIN: "Log In",
        LOGOUT: "Log Out",
        SIGNUP: "Sign Up",
        SET_TIER: "Set Tier",
        RESET_TIER: "Reset Tier",
        SIGNUP_LINK: "Signup Link",
        ARCHIVE: "Archive",
        UNARCHIVE: "Unarchive",
        OK: "Ok",
        LOADING: "Loading...",
        ADD: "New",
        UNDO: "Undo",
        SHOW_ALL: "Show All",
        EULA: "EULA",
        PRIVACY: "Privacy Policy",
        TOS: "Terms of Service",
        DATE_FORMAT: "MM/DD/YYYY",
        NO_RESULTS: "No Results",
        I_AGREE: "By using this product, I agree to:",
        SUPPORT: "Support",
        NEXT: "Next",
        BACK: "Back",
        RANGE: "%{range} of %{total}",
        UNBIND: "Unbind",
        BIND: "Bind",
        SHOWPROFILE: "ShowProfile",
    },
    LOGIN: {
        WELCOME_1: "Welcome to MobLab",
        WELCOME_2: "Recommendation Console",
        ADVISOR_LOGIN: "Advisor Login",
        ADVISOR_SIGNUP: "Complete Your Registration",
        ADVISOR_WELCOME_BACK: "Welcome Back!",
        ADVISOR_WELCOME_BACK_SUB:
            "Please secure your account by creating a new password.",
        ENTER_EMAIL: "Please Enter Your Email",
    },
    PASSWORD: {
        CREATE: "Create Your Password",
        CHANGE: "Change Your Password",
        CURRENT: "Current Password",
        NEW: "New Password",
        CONFIRM: "Confirm Password",
        RESET_LINK: "Password Reset Link",
        REQUIRE_PROMPT: "Your password must contain:",
        REQUIRE_LENGTH: "At least 8 characters",
        REQUIRE_UPPERCASE: "At least one English uppercase letter (A-Z)",
        REQUIRE_LOWERCASE: "At least one English lowercase letter (a-z)",
        REQUIRE_NUMERAL: "At least one number (0-9)",
        CHANGE_SUCCESS: "Password changed successfully!",
        CREATE_SUCCESS: "Password created successfully!",
        MISMATCH: "Your passwords do not match.",
        WRONG: "Your current password is incorrect.",
        INCORRECT: "Your password is incorrect.",
        INVALID: "Your new password does not meet the requirements.",
        INVALID_LENGTH: "Password must contain at least 8 characters.",
        INVALID_UPPERCASE:
            "Password must contain at least one uppercase letter (A-Z).",
        INVALID_LOWERCASE:
            "Password must contain at least one lowercase letter (a-z).",
        INVALID_NUMERAL: "Password must contain at least one number (0-9).",
        NO_USER:
            "The email you entered is invalid. If your email address has not been activated yet, please contact your campus admin.",
    },
    ADMIN: {
        VIEW_0: "ADMIN",
        VIEW_1: "ADVISOR",
        SWITCH_VIEW_0: "Switch to Advisor View",
        SWITCH_VIEW_1: "Switch to Admin View",
        ALL_USERS: "ALL USERS",
        ADVISORS: "Advisors",
        STUDENTS: "Students",
        PROSPECTS: "Prospects",
        ADD_ADVISORS: "Add Advisors",
        NEW_ADVISORS: "New Advisors",
        ADD_ROW: "Add a Row",
        CSV_FORMAT_PROMPT: "Please upload your file with the following format:",
        DROP_FILE: "Upload your .csv file here.",
        UPLOAD_FAILED: "UPLOAD FAILED",
        UPLOAD_COUNT: "Total: %{count}",
        DUPLICATES: "Duplicates removed: %{count}",
        OVERVIEW: "",
        DASHBOARD: "Dashboard",
        QUARTER: "Quarter",
        STAT: "Graphs",
        STAT_STUDENT_ACCOUNTS: "Student Accounts",
        STAT_COMPLETED_ASSESSMENTS: "Completed Assessments",
        STAT_PLAN_ASSIGNMENTS: "Plan Assignments",
        STAT_ADVISOR_ACCOUNTS: "Advisor Accounts",
        NO_PROSPECTS: "There are currently no prospects",
    },
    ADMIN_ADVISORS: {
        NAME: "Name",
        EMAIL: "Email",
        STUDENTS: "# of Students",
        CREATION_DATE: "Date Created",
        CAMPUS: "Campus",
        PLACEHOLDER_CAMPUS: "Select a campus",
    },
    ADMIN_STUDENTS: {
        NAME: "Name",
        KIND: "Kind",
        CREATION_DATE: "Date Created",
        ADVISOR: "Advisor",
        CAMPUSNAME: "Campus",
        CAMPUS: "Campus",
        PLACEHOLDER_ADVISOR: "Select an advisor",
    },
    ADMIN_PROSPECTS: {
        NAME: "Name",
        ADVISOR: "Advisor",
        CAMPUSNAME: "Campus",
        PLACEHOLDER_ADVISOR: "Assign an advisor",
    },
    STUDENTS: {
        YOUR_STUDENTS: "Your Students",
        NEW_STUDENT: "New Student",
        NAME: "Name",
        STATUS: "Status",
        KIND: "Kind",
        "STATUS_-1": "Pre-Applicant",
        STATUS_0: "Pre-Applicant",
        STATUS_1: "Plan Ready", // "STATUS_1": "Plan Ready",
        STATUS_2: "Applicant",
        STATUS_3: "Archived",
        ASSIGNED_TIER: "Tier Assigned",
        TIER: "Tier",
        "TIER_-1": "--",
        TIER_0: "Ivy Studio",
        TIER_1: "Elite",
        TIER_2: "Pioneer",
        TIER_3: "Overseas",
        REPORT: "Report",
        CREATE_PROMPT: "Create a New Student",
        NO_STUDENTS: "You do not have any students yet!",
        TOTAL: "Total",
    },
    PROFILE: {
        OVERVIEW: "OVERVIEW",
        STUDENT_INFO: "STUDENT INFO",
        DATA_INTEGRATION: "DATA INTEGRATION (coming soon!)",
        RECOMMENDATIONS: "RECOMMENDATIONS (coming soon!)",
        STUDENT_PROGRESS: "Student Progress",
        NOTES: "Notes",
        DASHBOARD: "Dashboard",
        PLAN_REPORT: "Plan Report",
        TRAIT_REPORT: "Trait Report",
        GLOBAL_COMPETENCE_REPORT: "Global Competence Report",
        INTER_ATTAINMENT: "Inter Attainment",
        MAJOR_FIT: "Major Fit",
        SCHOOL_FIT: "School Fit",
        PERSONAL: "Personal",
        ACADEMIC: "Academic",
        APPLICATION: "Application Resources",
        MAJOR_CAREER: "Major & Career",
        MAJOR_REC: "Major/Specialty",
        SCHOOL_REC: "School",
        STATISTICS: "Statistics",
        LANGUAGE: "Language",
        EXAMS: "Exams",
        OPTION_male: "Male",
        OPTION_female: "Female",
        NOTE_TITLE: "Title",
        NOTE_DELETED: "Your note has been deleted.",
        NEW_NOTE: "New Note",
        NO_NOTES: "You haven't taken any notes.",
        NO_NOTES_ON_THIS_PAGE: "You haven't taken any notes on this page.",
        NO_LANGUAGE_DATA:
            "The student has yet to complete the language assessment.",
    },
    DASHBOARD: {
        SUGGESTED_TIER_TITLE: "Suggested Plan",
        SUGGESTED_TIER_SUBTITLE: "Moblab's assessment app recommends",
        SELECTED_TIER_TITLE: "Selected Plan",
        SELECTED_TIER_SUBTITLE: "Applicant has been placed into",
        ASSIGN_TIER: "READY FOR PLAN ASSIGNMENT",
        INCOMPLETE: "GENERATE ACCOUNT LINK",
        ASSIGNED: "Student was assigned to the %{tier} package",
        RESTORE_STUDENT: "RESTORE STUDENT",
        SUGGESTED: "Suggested",
        INDIVIDUAL: "Individual score",
        INDIVIDUAL_SCORES_TITLE: "Individual Scores",
        TRAITS_OVERVIEW_TITLE: "Trait Report",
        GLOBAL_COMPETENCE_OVERVIEW_TITLE: "Global Competence Report",
        TRAITS_OVERVIEW_SUBTITLE: "Moblab Personality Assessment",
        PROGRESS_TITLE: "Assessment App",
        PROGRESS_SUBTITLE: "Assignment Progress",
        TRAITS_TITLE: "Personality Profile",
        TIME_SPENT: "Time Spent: ",
        ARCHIVED_ON: "Student was Archived on: %{date}",
        TIER_PROMPT: "Select A Plan",
        STUDENT_SCORES: "Student Scores",
        RESET: "undo",
        GENERATE_LINK: "GENERATE",
        CREATE_ACCOUNT:
            "Your student can use this link to register their account.",
        CHANGE_PASSWORD:
            "Your student can use this link to change their password.",
        LINK_TITLE_CREATE: "Student Registration Link",
        LINK_TITLE_CHANGE: "Student Password Change Link",
        LINK_PROMPT:
            "This <c> ONE TIME USE <c> link is meant only for your student. Do not share this link with anyone else, and do not open the link yourself, as the link will expire once activated. This link will be valid for %{exp} days.",
        GET_LINK: "Copy the link to your clipboard",
        LINK_COPIED: "Link copied to clipboard",
        ACCOUNT_LINKED: "Account Linked",
        LINKED_DESC:
            "As your student completes the <c> Moblab Assessment App <c> their progress and Plan Suggestions will be displayed here.",
        RESET_PROMPT:
            "If your student needs to reset their password, you can use the <a> Reset Account Link <a> ",
        EXAM_OVERVIEW: "Exam Overview",
        TOP_EXAM_SCORES: "Tracked Exam Scores",
    },
    FILTERS: {
        DEFAULT_0: "Assignment Incomplete",
        DEFAULT_1: "Ready For Assignment",
        DEFAULT_2: "Application Ready",
        DEFAULT_3: "Archived",
    },
    SCORES: {
        OVERALL: "Overall score",
        ACADEMIC_ABILITY: "Academic Ability",
        APPLICATION_READINESS: "Application Readiness",
        CAREER_FOCUS: "Career Focus",
        LANGUAGE_APTITUDE: "Language Aptitude",
    },
    TRAITS: {
        grit: "Perseverance",
        risk_aversion: "Risk Aversion",
        confidence: "Confidence",
        competitiveness: "Competitiveness",
        fairness: "Fairness",
        strategic_thinking: "Strategic Thinking",
        problem_solving: "Problem Solving",
        self_control: "Self Control",
        procrastination: "Procrastination",
        trust: "Trust",
        extraversion: "Extraversion",
        agreeableness: "Agreeableness",
        conscientiousness: "Conscientiousness",
        calmness: "Calmness",
        openness: "Openness and Creativity",
    },
    TRAITS_DESC: {
        agreeableness: "Tendency to be compassionate and cooperative.",
        calmness: "A measure of level-headedness and emotional stability.",
        competitiveness: "The desire to succeed or compete against others.",
        confidence:
            "A feeling of self-assurance in one’s ability in comparison to others.",
        conscientiousness:
            "Tendency to be organized and dependable, show self-discipline and aim for achievement.",
        extraversion: "A measure of energy, assertiveness and sociability.",
        fairness: "The belief that people should be treated equally.",
        openness:
            "Tendency to seek out novelty and a variety of experiences, and a measure of curiosity.",
        grit: "A measure of persistence of focus and effort.",
        problem_solving: "The ability to logically solve problems. ",
        procrastination: "The tendency to put off or delay tasks. ",
        risk_aversion:
            "The tendency to shy away from risk, and know when to play it safe. ",
        self_control:
            "A measure of control or restraint of one’s actions and feelings and the ability to stay focused.",
        strategic_thinking:
            "The ability to critically reason about other people’s behaviors.",
        trust: "A measure of confidence in someone’s integrity and strength.",
    },
    PLANS: {
        TIER_0_0:
            "This student has excellent academic skills and high levels of strategic thinking, conscientiousness, perseverance and self-control.",
        TIER_0_1:
            "This student has an outstanding knowledge about the application process and strong supporting resources.",
        TIER_0_2:
            "Since this student has a strong opinion about and plan for their major and career, this student might benefit from personalized guidance on extracurricular activities.",
        TIER_0_3: "This student has mastery over the English language.",
        TIER_1_0:
            "This student has a good academic skills and levels of strategic thinking, conscientiousness, perseverance and self-control.",
        TIER_1_1:
            "This student has satisfactory knowledge about the application process, but may require some additional application guidance.",
        TIER_1_2:
            "Since this student has a reasonable opinion about and plan for their major and career, this student might benefit from personalized guidance on major and career selection.",
        TIER_1_3: "This student has a strong grasp of the English language.",
        TIER_2_0:
            "This student has adequate academic skills and levels of strategic thinking, conscientiousness, perseverance and self-control.",
        TIER_2_1:
            "This student has moderate knowledge about the application process, and will require more personalized application guidance.",
        TIER_2_2:
            "Since this student has a limited opinion about and plan for their major and career, this student might benefit from focused guidance on extracurricular activities before selecting a major.",
        TIER_2_3:
            "This student has an acceptable grasp of the English language.",
        TIER_3_0:
            "Since this student has modest academic skills and levels of strategic thinking, conscientiousness, perseverance and self-control, they might benefit from additional preparation to get used to US education environment and courses and support after they go abroad.",
        TIER_3_1:
            "This student has modest knowledge about the application process, and will require more personalized application guidance.",
        TIER_3_2:
            "This student has little to no opinion about and plan for their major and career.",
        TIER_3_3:
            "This student has a rudimentary foundation of the English language.",
    },
    MAJOR: {
        TITLE: "Major Recommendations",
        SUBTITLE:
            "MobLab has built cutting-edge machine learning models to characterize students’ behavioral profiles and their corresponding personalized major recommendations. First, the student’s personality traits are measured with our games and survey questions in the MobLab Vision mobile app. The student’s behavioral profiles are then compared to our extensive database of US university students. MobLab’s model then assign personal major recommendations, as presented below, based on the corresponding majors of the US university students that are most similar to the student.",
        MAJOR: "Major",
        FIT: "Percentage Fit",
        SHOW_ALL: "Show All",
        SHOW_LESS: "Show Less",
        SECTION_SUB_MAJORS: "Sub Majors",
        SECTION_CAREERS: "Careers",
        GRADUATE_DEGREE: "Graduate Degree",
        MEDIAN_INCOME: "Median Income",
    },
    UNITS: {
        DAYS: "%{count} day |||| %{count} days",
        HOURS: "%{count} hr |||| %{count} hrs",
        MINUTES: "%{count} min",
        SECONDS: "%{count} s",
    },
    HELP: {
        SUGGESTED_TIER:
            "Plan Suggestions are based off the four individual scores and the criteria below. Please use this suggestion as a guiding factor when assigning the student to a plan. If you select a plan other than the suggestion, make sure to provide feedback.",
        INDIVIDUAL_SCORES:
            " Individual Scores are based off the game play and survey data collected through the Moblab Assessment app.",
        TRAIT_BREAKDOWN:
            "Trait Breakdown is based off the data collected through the Moblab Assessment app and information reported through the Vision Console.",
    },
    TRAIT_REPORT: {
        TRAIT_BREAKDOWN_TITLE: "Trait Breakdown",
        TRAIT_BREAKDOWN_SUBTITLE: "MobLab Personality Trait assessment",
    },
    TRAIT_SLIDER: {
        LOW: "LOW",
        MED: "MED",
        HIGH: "HIGH",
    },
    PERSONAL: {
        TITLE: "Personal Details",
    },
    ACADEMIC: {
        TITLE: "Academic",
    },
    APPLICATION: {
        TITLE: "Application",
    },
    MAJOR_CAREER: {
        TITLE: "Major & Career",
    },
    INTER_ATTAINMENT: {
        TITLE: "Inter Attainment",
    },
    LANGUAGE: {
        NO_DATA: "The student has not yet completed this task",
        TITLE: "Language Assessment Results",
        SUBTITLE:
            "Difficulty [1-10] increases/decreases after a(n) correct/incorrect answer.",
        QUESTION: "Question",
        DIFFICULTY: "Difficulty",
        LEGEND: "Question Difficulty",
        MEDIAN: "Median",
        ACCURACY: "Accuracy",
        VOCAB_SCORE: "Vocabulary Score",
    },
    EXAMS: {
        ADD: "Add Exam",
        DATE: "Exam Date",
        TOTAL: "Total",
        SCORE: "Score",
        SUBJECT: "Subject",
        READING: "Reading",
        LISTENING: "Listening",
        WRITING: "Writing",
        SPEAKING: "Speaking",
        EBRW: "Reading/Writing",
        MATH: "Math",
        CHANGE_VIEW_0: "Table View",
        CHANGE_VIEW_1: "Graph View",
        SUBJECT_PLACEHOLDER: "Select a Subject",
        NO_DATA: "No Exam Data",
    },
    SCHOOLS: {
        TITLE: "School Recommendations",
        SUBTITLE:
            "MobLab has built cutting-edge machine learning models to help students find the most suitable schools to apply to based on their academic scores and behavioral traits. MobLab's model first anchors the recommendations on a range of qualified schools according to their academic profile (including the student's actual or predicted scores on the SAT and TOEFL). The model then evaluates the behavioral fit of the remaining qualified schools by mapping the student's individual traits to the “personality” of the school, in order to find the most optimal match. The personal school recommendations are presented below.",
        CHANGE_VIEW_0: "Table View",
        CHANGE_VIEW_1: "Grid View",
        STUDENT_FIT: "Student Fit",
        RANKING: "#%{rank} in US Universities",
        RANKING_LIBARTS: "#%{rank} in US Liberal Arts Schools",
        SCHOOL: "School",
        RANK: "Rank",
        FIT: "Fit",
        TEXT_PLACEHOLDER: "Enter a school name",
        SELECT: "Select a filter",
        SELECT_MULTIPLE: "Select filters",
        SECTION_GENERAL: "General Information",
        SECTION_ENROLLMENT: "Enrollment",
        LABEL_SCHOOL_NAME: "School Name",
        LABEL_LOCATION: "State",
        LABEL_REGION: "Region",
        LABEL_SCHOOL_TYPE_I: "School Type",
        LABEL_SCHOOL_TYPE_II: "Liberal Arts",
        LABEL_GENDER: "Gender Preference",
        LABEL_UGDS_NRA: "International Student Population",
        LABEL_RANKING: "School Rank (Assigned by Moblab)",
        LABEL_TUITION: "Total Annual Cost of Attendance (est.)",
        OPTION_LOCALE_11: "City: Large (population > 250,000)",
        OPTION_LOCALE_12: "City: Midsize (100,000 < population < 250,000)",
        OPTION_LOCALE_13: "City: Small (population < 100,000)",
        OPTION_LOCALE_21: "Suburb: Large (population > 250,000)",
        OPTION_LOCALE_22: "Suburb: Midsize (100,000 < population < 250,000)",
        OPTION_LOCALE_23: "Suburb: Small (population < 100,000)",
        OPTION_LOCALE_31: "Town: Fringe (<10mi from urban area)",
        OPTION_LOCALE_32: "Town: Distant (>10mi, <35mi from urban area)",
        OPTION_LOCALE_33: "Town: Remote (>35mi from urban area)",
        OPTION_LOCALE_41: "Rural: Fringe (<2.5mi to nearest town)",
        OPTION_LOCALE_42: "Rural: Distant (>2.5mi, <10mi from nearest town)",
        OPTION_LOCALE_43: "Rural: Remote (>10mi from nearest town)",
        OPTION_CONTROL_1: "Public",
        OPTION_CONTROL_2: "Private (Non-Profit)",
        OPTION_CONTROL_3: "Private (For Profit)",
        OPTION_NON_LIBERAL_ARTS: "University",
        OPTION_LIBERAL_ARTS: "Liberal Arts",
        OPTION_MENONLY: "Men Only",
        OPTION_WOMENONLY: "Female Only",
    },
    FEEDBACK: {
        TITLE: "Feedback",
        SUBTITLE:
            "Let us know why you chose a different plan to help us make better suggestions in the future.",
        Q01: "Parents or student requested another plan",
        Q02:
            "I didn't think the suggested plan was suitable for them based on their ability",
        Q03: "I didn't agree with the plan recommendation's academic score",
        Q04: "I didn't agree with the plan recommendation's application score",
        Q05: "I didn't agree with the plan recommendation's career score",
        Q06: "I didn't agree with the plan recommendation's language score",
        NOTES: "Other Comments",
    },
    INFO_LABELS: {
        NAME: "STUDENT NAME",
        PHONENUMBER: "Phone Number",
        DATEOFBIRTH: "DATE OF BIRTH",
        GENDER: "GENDER",
        LOCATION: "LOCATION",
        "001_GRADE": "GRADE",
        "002_ENT_EXAM": "ENTRANCE EXAM",
        "003_ENT_EXAM_EN": "ENTRANCE EXAM ENGLISH",
        "004_CLASS_RANK": "CLASS RANKING",
        "005_SCHOOL_RANK": "SCHOOL RANKING",
        "006_SCHOOL_TYPE": "SCHOOL TYPE",
        "007_SAT_SCORE": "SAT",
        "008_TOEFL_SCORE": "TOEFL",
        "009_SATII_SCORE": "BEST SATII SCORE",
        "010_SATII_SUB": "SATII SUBJECT",
        "011_AP_SCORE": "BEST AP SCORE",
        "012_AP_SUB": "AP SUBJECT",
        "046_HS_GPA": "High School GPA",
        "013_REL_ABROAD": "RELATIVE STUDY ABROAD",
        "014_SCHOOL_LIST": "SCHOOL LIST?",
        "015_FREE_TIME": "TRAVEL ABROAD",
        "016_RES_SCHOOL": "CAMPUS TOUR",
        "017_PAR_EXP": "PARENTS EXPECTATIONS",
        "018_BEEN_ABROAD": "BEEN ABROAD",
        "019_TOUR_SCHOOL": "TOURED SCHOOLS",
        "020_MAJOR_SCALE": "MAJOR SCALE",
        "021_MAJOR": "MAJOR",
        "022_FAV_SUB": "SUBJECT",
        "023_AP_COURSE": "AP",
        "024_CAR_IMPACT": "CAREER IMPACT",
        "025_CAR_CONF": "CAREER CONFIDENCE",
        "026_EXTRACUR": "EXTRACURRICULARS",
        GRADE: "Grade",
        HIGH_SCHOOL_NAME: "Shool Name",
        HIGH_SCHOOL_TYPE: "School Type",
        GPA: "GPA",
        GPA_TOTAL: "Total GPA",
        SAT_TAKEN: "Already Taken SAT?",
        SAT_TARGET: "SAT target Score",
        SAT_SCORE: "SAT Score",
        SAT_DATE: "SAT Test Date",
        TOEFL_TAKEN: "Already Taken TOEFL?",
        TOEFL_TARGET: "TOEFL target Score",
        TOEFL_SCORE: "TOEFL Score",
        TOEFL_DATE: "TOEFL Test Date",
        ENTRANCE_EXAM_TAKEN: "Already Taken Entrance?",
        ENTRANCE_EXAM_SCORE: "Entrance Score",
        ENTRANCE_EXAM_ENGLISH_SCORE: "Entrance Englist Score",
        ENTRANCE_EXAM_DATE: "Entrance Test Date",
        ENTRANCE_EXAM_ENGLISH_MAX_SCORE: "Entrance Max English Score",
        ENTRANCE_EXAM_MAX_SCORE: "Entrance Max Total Score",
    },
    STATS: {
        MONTH_1: "Jan %{year}",
        MONTH_2: "Feb %{year}",
        MONTH_3: "Mar %{year}",
        MONTH_4: "Apr %{year}",
        MONTH_5: "May %{year}",
        MONTH_6: "Jun %{year}",
        MONTH_7: "Jul %{year}",
        MONTH_8: "Aug %{year}",
        MONTH_9: "Sep %{year}",
        MONTH_10: "Oct %{year}",
        MONTH_11: "Nov %{year}",
        MONTH_12: "Dec %{year}",
        ALL: "Aggregate",
    },
    PORTAL: {
        TITLE: "Your Vision Account is almost ready!",
        YOUR_USERNAME: "Welcome",
        YOUR_NUMBER: "Remember to log in using your phone number",
        BEFORE_START: "Before you start...",
        ERROR_HEADER: "Passwords Do Not Match",
        ERROR_CONTENT:
            "Please make sure you have entered the same password in both fields.",
        CREATING: "Creating your account...",
        DOWNLOAD_TITLE: "Great, you're ready to start playing!",
        DOWNLOAD_PROMPT:
            "Download the Moblab Vision App to start your assessment",
        APP_STORE: "App Store",
        APK: "Download APK",
        EXPIRED_TITLE: "Sorry, this link has expired!",
        EXPIRED: "Please contact your advisor to obtain a valid link.",
        ACTIVATE_PROMPT_CREATE: "Set up your vision account?",
        ACTIVATE_PROMPT_CHANGE: "Reset your password?",
        ACTIVATE_CREATE: "Continue",
        ACTIVATE_CHANGE: "Continue",
        APK_TITLE: "Installing an APK",
        APK_SUBTITLE: "From My Phone",
        APK_INSTRUCTIONS_1:
            "Once the APK download completes, open <c> Downloads <c>",
        APK_INSTRUCTIONS_2:
            "Tap on the APK and then tap <c> Yes <c> when prompted",
    },
    ERROR: {
        ERROR: "An Error Occurred",
        "student already exists":
            "It looks like this phone number is already in use!",
        NAME_EMPTY: "Please enter a name.",
        INVALID_PHONE: "Please enter a valid phone number.",
        INVALID_EMAIL: "Please enter a valid email.",
        INVALID_FILE: "Only .csv files are allowed.",
        NO_DATA: "The csv file you uploaded is empty.",
        INVALID_HEADERS: "Your column headers must match: %{headers}.",
        NO_EMPTY_ROWS: "Empty rows are not allowed.",
        INVALID_EMAIL_FORMAT: "Your file contains an invalid email: %{email}.",
        HAS_DUPLICATES: "The emails you entered contains duplicates.",
        ALL_DUPLICATES: "All the advisors you added already exist.",
        EMAIL_EXISTS: "This email already exists.",
        FAILED_TO_CREATE:
            "Failed to create the above advisors: Their emails already exist.",
        ATTACHMENT_REQUIRED: "Please upload the file first",
        UPDATE_PROTOCOL_REQUIRED:
            "Please update the selectschool protocol first",
    },
};
