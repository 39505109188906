import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ProgressPlus from "report/component/ProgressPlus";
import ReportHeader from "../ReportHeader";
import Pager from "../Pager";
import locationImg from "img/report/vision2022/location.png";
import cupImg from "img/report/vision2022/cup.png";

function SchoolList({ title, shcools, isFirstPage }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            {isFirstPage ? (
                <React.Fragment>
                    <div className="part-header">
                        <div className="color-bar"></div>
                        学校推荐详解
                    </div>
                    <div className="part-desc">
                        基于学生的学术成绩以及行为特质，本测评系统建立了前沿的统计学和机器学习预测匹配模型，来帮助学生定位最适合自己申请的学校。
                        <br />
                        个性化学校推荐模型首先根据学生当前的学术成绩和标准化考试成绩，筛选出一系列满足学术申请条件的学校。
                        <br />
                        之后将学生的行为特质档案与每个筛选出的学校的'个性'属性进行匹配并排序，评估出最适合申请的学校列表如下.，在您做出最终申请决策前，请在参考以上推荐结果的同时，综合考量其他相关信息及因素。
                    </div>
                </React.Fragment>
            ) : null}
            {_.map(shcools, function (schoolData, index) {
                if (!schoolData) {
                    return null;
                }
                return (
                    <div className="school-detail-wrapper" key={index}>
                        <div className="school-detail-title">
                            <div className="color-bar"></div>
                            <span>{schoolData.INSTNM}</span>
                        </div>
                        <div className="school-detail-body">
                            <div className="location-row">
                                <img alt="" src={locationImg} />
                                <span>
                                    {schoolData.CITY + "," + schoolData.STABBR}
                                </span>
                            </div>
                            <div className="cup-row">
                                <img alt="" src={cupImg} />
                                <span>
                                    在美国综合性大学中排名第
                                    {schoolData.MOBLAB_RANK}
                                </span>
                            </div>

                            <div className="float-wrapper">
                                <div className="float-title">
                                    行为特质匹配程度
                                </div>
                                <ProgressPlus
                                    value={schoolData.score}
                                    width={70}
                                    color={"#00BFD6"}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    padding-bottom: 50px;
    .section-header {
        margin-bottom: 50px;
    }

    .part-header {
        width: 100%;
        position: relative;
        padding-left: 42px;
        font-weight: bold;
        font-size: 48px;
        line-height: 70px;
        color: #1b2224;
        margin-bottom: 24px;
        .color-bar {
            width: 16px;
            height: 42px;
            background: #30b9f8;
            position: absolute;
            left: 0;
            top: 14px;
        }
    }
    .part-desc {
        padding-left: 42px;
        padding-right: 42px;
        font-size: 18px;
        line-height: 36px;
        color: #47525d;
        margin-bottom: 48px;
    }

    .school-detail-wrapper {
        margin-bottom: 72px;
        .school-detail-title {
            width: 100%;
            position: relative;
            padding-left: 42px;
            height: 38px;
            background: #f7f9fa;
            .color-bar {
                width: 20px;
                height: 38px;
                background: #00b6ff;
                position: absolute;
                left: 0;
                top: 0;
            }
            span {
                font-weight: bold;
                font-size: 18px;
                line-height: 38px;
                color: #1b2224;
                vertical-align: middle;
            }
        }
    }

    .school-detail-body {
        position: relative;
        width: 100%;
        padding: 32px 60px;

        .location-row,
        .cup-row {
            height: 24px;
            img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
            span {
                font-weight: bold;
                font-size: 14px;
                line-height: 24px;
                color: #60728e;
                vertical-align: middle;
            }
        }
        .location-row {
            margin-bottom: 8px;
        }

        .float-wrapper {
            position: absolute;
            right: 60px;
            top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 18px;
            .float-title {
                font-weight: bold;
                font-size: 12px;
                color: #60728e;
                margin-bottom: 8px;
            }
        }
    }
`;

SchoolList.propTypes = {
    title: propTypes.string.isRequired,
    shcools: propTypes.array.isRequired,
    isFirstPage: propTypes.bool,
};

export default SchoolList;
