import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Form, Row, Col, Input } from "antd";
import { TimeUtil } from "lib/utils";
const { TextArea } = Input;

function getParsedStudentInfo(studentData) {
    return studentData.highSchoolInfo || {};
}

const schoolConfig = [
    {
        label: "中文名称",
        fieldName: "schoolNameCH",
    },
    {
        label: "英文名称",
        fieldName: "schoolNameEN",
    },
    {
        label: "英文地址",
        fieldName: "addrEN",
        span: 16,
    },
    {
        label: "邮政编码",
        fieldName: "postCode",
    },
    {
        label: "高中性质",
        fieldName: "highschoolTypeValue",
    },
    {
        label: "提交路径",
        fieldName: "commitPathValue",
    },
];

const classConfig = [
    {
        label: "入学日期",
        fieldName: "enrollmentDate",
        isDate: true,
    },
    {
        label: "毕业日期",
        fieldName: "graduationDate",
        isDate: true,
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "Unweighted GPA",
        fieldName: "GPA",
    },
    {
        label: "Weighted GPA",
        fieldName: "weightedGPA",
    },
    {
        label: "班级人数",
        fieldName: "stdNum",
    },
    {
        label: "班级排名",
        fieldName: "classRanking",
    },
    {
        label: "国际课程类型",
        fieldName: "internationalCourseValue",
    },
];

function SummaryHighSchool({ studentData }) {
    const [highSchoolData, setHighSchoolData] = useState({});

    useEffect(() => {
        setHighSchoolData(getParsedStudentInfo(studentData));
    }, [studentData, setHighSchoolData]);

    const getSchoolFields = useCallback(
        (config) => {
            const children = [];

            _.each(config, function (
                { label, fieldName, span = 8, isDate },
                index
            ) {
                if (label === "") {
                    children.push(<Col span={span} key={index}></Col>);
                    return;
                }

                let value = highSchoolData[fieldName];
                if (isDate) {
                    value = TimeUtil.formatDate(value);
                }

                children.push(
                    <Col span={span} key={index}>
                        <Form.Item
                            label={label}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={value} />
                        </Form.Item>
                    </Col>
                );
            });

            return children;
        },
        [highSchoolData]
    );

    return (
        <StyledContainer>
            <header className="section-header">高中信息</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                <div className="sub-title">所在高中</div>
                <Row gutter={24} className="border-row">
                    {getSchoolFields(schoolConfig)}
                </Row>
                <div className="sub-title">所在班级</div>
                <Row gutter={24} className="border-row">
                    {getSchoolFields(classConfig)}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="备注" colon={false} labelAlign="left">
                            <TextArea readOnly value={highSchoolData.remark} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})``;

SummaryHighSchool.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryHighSchool;
