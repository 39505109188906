import { Request } from '../'

export default class Deferred {
    count=0
    total=0
    
    constructor(total, onComplete){
        if (total){
            this.total = total;
        }
        if (onComplete){
            this.onComplete = onComplete;
        }
    }
    onComplete(){}
    then(callback){
        this.onComplete = callback;
    }
    resolve(){
        this.count++;
        if (this.count === this.total){
            this.onComplete();
        }
    }
    makeRequest(protocol, path, params){
        this.total++;
        const originalCallback = params.onSuccess;
        params.onSuccess = res => {
            originalCallback(res);
            this.resolve();
        }
        console.log("DEFERRED " + protocol + ": ", path);
        Request.makeRequest(protocol, path, params);
    }
    GET(path, params){
        this.makeRequest('GET', path, params);
    }
    POST(path, params){
        this.makeRequest('POST', path, params);
    }
    DELETE(path, params){
        this.makeRequest('DELETE', path, params);
    }
}