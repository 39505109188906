import React, { useState, useEffect, useContext } from "react";
import { Spin, message } from "antd";
import FilterContext from "./FilterContext";
import TabPlus from "./TabPlus";
import propTypes from "prop-types";
import DashboardUtils from "./DashboardUtils";

function ManagerTab({ isCurrentTab }) {
    let { filter } = useContext(FilterContext);
    const [loading, setLoading] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        if (!isCurrentTab) {
            return;
        }

        setLoading(true);
        DashboardUtils.fetchJSON(`dashboard/advisorSummary`, filter)
            .then(function (resp) {
                setLoading(false);
                const { advisors, tables } = resp;
                setAdvisors(advisors);
                setTables(tables);
            })
            .catch(function (err) {
                setLoading(true);
                message.error(String(err));
            });
    }, [filter, isCurrentTab]);

    return (
        <Spin size="large" tip="Loading..." spinning={loading}>
            <TabPlus advisors={advisors} tables={tables} />
        </Spin>
    );
}

ManagerTab.propTypes = {
    isCurrentTab: propTypes.bool.isRequired,
};

export default ManagerTab;
