// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./lib/utils/GlobalLog";
import "./lib/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import "./index";
import App from "./App";
import StudentPortal from "./student/Portal";
import ReportContainer from "./report/ReportContainer";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");
if (window.location.pathname.indexOf("portal") > -1) {
    ReactDOM.render(<StudentPortal />, root);
} else if (_.startsWith(window.location.pathname, "/report")) {
    ReactDOM.render(<ReportContainer />, root);
} else {
    ReactDOM.render(<App />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
