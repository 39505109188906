import React, { Component } from "react";
import { Request } from "../../lib";
import { message, Button } from "antd";



class SyncSelectSchool extends Component {
    state = { disabled: null }
    syncSelectSchool = (e) => {
        if (this.state.disabled === 'disabled') {
            return false;
        }
        this.setState({ disabled: 'disabled' })
        Request.GET("sync/selectschool/" + this.props.contractId, {
            onSuccess: (data) => {
                this.props.fetchData();
                message.info("同步成功");
                this.setState({ disabled: null })
            },
            onFail: (status, error) => {
                if (status === 401) return true;
                this.setState({ disabled: null })
                message.error("同步错误");
            },
        });
        return false;
    };
    render() {
        return (
            <Button
                type="link"
                onClick={this.syncSelectSchool}
                className={this.state.disabled}
                style={{ padding: '0px', margin: '0px', height: '20px' }}
            >
                读取协议
            </Button>
        );
    }
}
export default SyncSelectSchool;
