import React, { Component } from 'react'
import i18n from "../../lib/i18n/i18n"
import { Button, Modal } from 'semantic-ui-react'
import { TierCard, RippleButton } from '../'
import * as Widget from '../widgets'
import datastore from "../../data/store"
import '../../scss/Modal.scss';

/**
 * @class Assign
 *
 * A modal that displays student's scores and tier options, along with suggested tier.
 * Allows advisor to select one of the tiers and assign the student to that tier.
 *
 * @prop {Array}    scores  individual category scores
 * @prop {Integer}  overall overall score (sum of category scores)
 * @prop {Boolean}  open    show/hide modal
 * @prop {Function} onClose close modal handler, as display boolean is external
 * @prop {Function} setTier tier assignment handler
 */
class Assign extends Component {
    state = {
        name: "",
        scores: [],
        selected: -1
    }
    componentWillMount(){
        var breakdown = this.props.scores;
        var scores = [{key: 'overall', value: this.props.overall}];
        for (var s in breakdown){
            scores.push(breakdown[s]);
        }
        this.setState({scores})
    }
    select(selected){
        this.setState({selected})
    }
    close(){
        this.setState({selected: -1});
        this.props.onClose();
    }
    choose(){
        // console.warn("OFFLINE [CHOOSE]: ", this.state.selected);
        if (this.state.selected > -1){
            this.props.setTier(this.state.selected);
            this.props.onClose();
            this.setState({selected: -1});
        }
    }
    render(){
        const canSubmit = this.state.selected > -1;
        return (
            <Modal
                className='Assign fullscreen'
                basic
                dimmer="inverted"
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.open}
            >
                <div className="icon close" onClick={this.props.onClose}>e</div>
                <Modal.Header>{i18n.t('DASHBOARD.TIER_PROMPT')}</Modal.Header>
                <Modal.Content className="centered" style={{ zIndex: '10001' }}>
                    <Widget.ScoresOverview
                        title={i18n.t('DASHBOARD.STUDENT_SCORES')}
                        scores={this.state.scores}
                    />
                    {datastore.get('TIERS').map(({ total, individual }, i) => (
                        <TierCard
                            key={i}
                            index={i}
                            total={total}
                            individual={individual}
                            suggested={this.props.tier === i}
                            selected={this.state.selected === i}
                            select={this.select.bind(this)}
                        />
                    ))}
                </Modal.Content>
                { canSubmit &&
                    <Modal.Actions className="footer" style={{ zIndex: '10001' }}>
                        <RippleButton
                            empty flat
                            text={i18n.t('APP.CANCEL')}
                            onClick={this.close.bind(this)}
                        />
                        <Button
                            positive
                            content={i18n.t('APP.ASSIGN')}
                            onClick={this.choose.bind(this)}
                        />
                    </Modal.Actions>
                }
            </Modal>
        )
    }
}

export default Assign
