import React from "react";
import propTypes from "prop-types";
// import _ from "lodash";
import styled from "styled-components";
import ReportHeader from "./ReportHeader";
import TraitsOverview from "./TraitsOverview";
import Pager from "./Pager";

function TraitReportOverview({ title, traitsData, traitsThreshold }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            <div className="section-body">
                <div className="report-description">
                    <div className="text-title">素质能力</div>
                    <div className="text-desc">
                        ⽣涯规划与⼼理学理论认为，个体的⽣涯选择在相当⼤的程度上受到能⼒、性格以及⾃我认知的影响。
                        具体⽽⾔，
                        能⼒倾向会影响专业学习和⼯作过程中的⾃我效能感（即⾃⼰认为能不能做好），
                        个性特质决定了⼈与专业/岗位
                        的匹配度（即个⼈⾏为⻛格是否适合某个领域）、
                        ⾃我认知则影响个体在新环境中的适应性（信⼼及⾃我调节能⼒
                        如何）。
                        <br />
                        本测评在⼤量⽂献研究的基础上，参考经典模型，精⼼选择了⼗五项能⼒素质，
                        包含了能⼒、性格及⾃我认知三个层⾯的关键维度。通过游戏化测评⼯具，
                        衡量您在各个维度上的发展现状，配合海量学⽣⾏为特征数
                        据，以期在求学和求职道路上助您⼀臂之⼒。
                    </div>
                </div>
                <div className="overview-container">
                    <TraitsOverview
                        isShowTitle={false}
                        printView
                        traitsData={traitsData}
                        traitsThreshold={traitsThreshold}
                    />
                </div>
            </div>
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .section-body {
        .overview-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .trait-overview-section {
            box-shadow: none;
        }
    }
`;

TraitReportOverview.propTypes = {
    title: propTypes.string.isRequired,
    traitsData: propTypes.array.isRequired,
};

export default TraitReportOverview;
