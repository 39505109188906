import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as SliderThumb } from "img/report/graphs/slider_thumb.svg";
import { ReactComponent as SliderThumbRed } from "img/report/graphs/graphDot.svg";

function ScoreSlide({ trait, traitsThreshold = {}, isRedDot }) {
    const [slideHandlerOffset, setSlideHandlerOffset] = useState("50%");

    const LowRankingBoundary = traitsThreshold?.lowerScoreThreshold;
    const HighRankingBoundary = traitsThreshold?.upperScoreThreshold;

    const LowScoreScale = Math.ceil((LowRankingBoundary + 0) / 2);
    const AverageScoreScale = Math.ceil(
        (LowRankingBoundary + HighRankingBoundary) / 2
    );
    const HighScoreScale = Math.ceil((HighRankingBoundary + 100) / 2);

    useEffect(
        function () {
            setSlideHandlerOffset((trait.score || 0) + "%");
        },
        [setSlideHandlerOffset, trait]
    );
    return (
        <StyledContainer slideHandlerOffset={slideHandlerOffset}>
            <div className="slide-title">
                <span
                    className="low-scale"
                    style={{ left: `calc( ${LowScoreScale}% - 14px)` }}
                >
                    较低
                </span>
                <span
                    className="average-scale"
                    style={{ left: `calc( ${AverageScoreScale}% - 14px)` }}
                >
                    平均
                </span>
                <span
                    className="high-scale"
                    style={{ left: `calc( ${HighScoreScale}% - 14px)` }}
                >
                    出色
                </span>
            </div>
            <div className="slide-bar">
                {isRedDot ? (
                    <SliderThumbRed className="slide-handle-red" />
                ) : (
                    <SliderThumb className="slide-handle" />
                )}
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "score-slide avoid-break-inside",
})`
    .slide-title {
        width: 100%;
        height: 22px;
        margin-bottom: 10px;
        color: #a1a8b3;
        font-size: 14px;
        position: relative;
        .low-scale,
        .average-scale,
        .high-scale {
            position: absolute;
        }
    }

    .slide-bar {
        width: 100%;
        height: 20px;
        border-radius: 10px;
        z-index: 100;
        background-image: linear-gradient(90deg, #ab9fff 0%, #30b9f8 100%);
        position: relative;
        .slide-handle {
            width: 16px;
            height: 21px;
            margin-top: -3px;
        }
        .slide-handle-red {
            width: 20px;
            height: 25px;
            margin-top: -12px;
        }
        .slide-handle,
        .slide-handle-red {
            z-index: 2;
            left: ${({ slideHandlerOffset }) => slideHandlerOffset};
            position: absolute;
            margin-left: -8px;
        }
    }
`;

ScoreSlide.propTypes = {
    trait: propTypes.object.isRequired,
    traitsThreshold: propTypes.object.isRequired,
};

export default ScoreSlide;
