const data = {
    biology: {
        name: "生物科学",
        desc:
            "生物科学类专业为学生提供了研究生命和生物的机会，其中具体的议题包括生物的物理结构，化学过程，分子相互作用，生理机制，发育和进化。",
        subs: [
            {
                name: "生物科学",
                target:
                    "本专业旨在培养具备生物科学基本理论、基本知识和较强的实验技能，能够在科研机构、高等学校及企事业单位等从事科学研究、教学工作及管理工作的生物科学高级专门人才。",
                course:
                    "动物生物学、植物生物学、微生物学、生物化学、细胞生物学、遗传学、发育生物学、神经生物学、分子生物学、生态学等。",
                abroadVista: 5,
                major_code: "071001",
            },
            {
                name: "生物技术",
                target:
                    "本专业培养具备生命科学的基本理论和较系统的生物技术的基本理论、基本知识、基本技能，能在科研机构或高等学校从事科学研究或教学工作，能在工业、医药、食品、农、林、牧、渔、环保、园林等行业的企业、事业和行政管理部门从事与生物技术有关的应用研究、技术开发、生产管理和行政管理等工作的高级专门人才。",
                course:
                    "微生物学、细胞生物学、遗传学、生物化学、分子生物学、基因工程、细胞工程、微生物工程、生化工程、生物工程下游技术、酶工程、发酵工程设备等。",
                abroadVista: 5,
                major_code: "071002",
            },
            {
                name: "生物信息学",
                target:
                    "本专业培养具备分子生物学、计算机科学与技术、数学和统计学素养，掌握生物信息学基本理论和方法，具备生物信息收集、分析、挖掘、利用等方面的基本能力，能在科研机构、高等学校、医疗医药、环境保护等相关部门与行业从事教学、科研、管理、疾病分子诊断、药物设计、生物软件开发、环境微生物监测等工作的高级科学技术人才。",
                course:
                    "生物学、数学、计算机科学、普通生物学、生物化学、分子生物学、遗传学、生物信息学、计算生物学、基因组学、生物芯片原理与技术、蛋白质组学、模式识别与预测、数据库系统原理、Linux基础与应用、生物软件及及数据库、Perl编程基础等。",
                abroadVista: 5,
                major_code: "071003",
            },
            {
                name: "生态学",
                target:
                    "本专业培养具备生态学的基本理论、基本知识和基本技能，能在科研机构、高等学校、企事业单位及行政部门等从事科研、教学和管理等工作的高级专门人才。",
                course:
                    "普通生物学、生物化学、生态学、环境微生物学、环境学、地学基础、环境生态工程、环境人文社会科学等。",
                abroadVista: 4,
                major_code: "071004",
            },
        ],
        jobs: [
            {
                name: "分子生物科学家",
                content:
                    "设计、优化及实施分子克隆相关的实验技术和方案。\r\n设计、优化及实施基因编辑实验技术和方案，检测基因编辑实验效果。\r\n研发新的基因编辑技术。\r\n培训并指导基因编辑研究助理。\r\n完成论文写作及专利申请相关工作。",
                salaryMedian: 30000,
                educational: "博士",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "微生物研究员",
                content:
                    "协助制订微生物实验室相关工作流程。\r\n开展菌株筛选、分离、功能鉴定与菌种应用技术的开发。\r\n微生物菌种的培养与发酵的工艺执行、优化及放大工作。\r\n参与发酵产物的分离纯化工作。\r\n书写中英文项目报告，定期与国内外客户汇报项目进展。",
                salaryMedian: 11000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "药品生产车间主任",
                content:
                    "负责车间各生产小组按照批准的工艺规程进行有序生产，保证中间产品质量，按时完成生产任务。\r\n负责审核车间的GMP（药品生产质量管理规范）标准文件。\r\n确保车间的卫生状况和生产秩序，保证员工的劳动安全。\r\n负责车间设备日常运行维护，确保设备正常运行。\r\n负责车间生产人员的培训和考核，定期向直属上级汇报工作。",
                salaryMedian: 8600,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "生物技术工程师",
                content:
                    "根据新产品开发计划，负责生物相关部分实验的完成，包括方案设计、实验操作、数据处理与分析等。\r\n配合研发经理进行产品的调试和功能验证，完成工艺路线设计及优化。\r\n负责生物技术领域的信息收集和评估，了解技术发展趋势。\r\n协助制定公司研发计划。\r\n为产品市场推广提供技术支持。",
                salaryMedian: 9000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "生物技术销售工程师",
                content:
                    "负责生物产品、实验服务项目的销售工作，执行并完成销售计划。\r\n根据公司产品、服务、价格及市场策略，向客户提供专业咨询和报价，完成合同签订等事宜。\r\n及时跟踪并处理客户反馈，定期进行重点客户的拜访，维护新老客户关系。\r\n收集市场一线信息和客户意见，对公司营销策略、售后服务、等提出参考意见。\r\n负责确保货款及时回笼及核对。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "生物信息工程师",
                content:
                    "开发和维护生物信息分析流程。\r\n及时准确地进行生物信息分析，出具结果报告。\r\n参与数据分析算法的开发、维护和优化。\r\n生物信息数据库建立与维护。",
                salaryMedian: 14100,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "水生态工程师",
                content:
                    "负责河道治理、水生态修复治理工程等项目的工程现场踏勘、项目前期调研、方案设计及施工图的审核，编制相关投标文件。\r\n负责生态、环境等方面的问题诊断与策略分析。\r\n负责黑臭水体治理等相关河湖流域生态工程的新技术、新产品引进、比选与推广。\r\n为项目前期调研、项目准备、项目施工及项目运营维护等提供技术支持。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "注册环评工程师",
                content:
                    "负责环境影响评价项目现场勘查及情况反馈，编制相应类别的报告书、应急预案及相关环境咨询文件。\r\n负责制定环评项目实施工作计划、预算并实施。\r\n负责审查环评文件稿件汇编文字、表格、图件等。\r\n负责项目报审、报批文件准备，负责项目评审的相关组织工作。",
                salaryMedian: 15000,
                educational: "本科",
                qualification:
                    "《中华人民共和国环境影响评价工程师职业资格证书》",
                jobVista: 5,
            },
        ],
    },
    economics: {
        name: "经济学",
        desc:
            "经济学类专业为学生提供经济学理论，数学模型和分析技巧方面的培训，而从使他们能够更好地理解世界经济体系和市场如何运作。经济学专业所习得的技能可以广泛应用于商业、科技、金融和政府等部门。",
        subs: [
            {
                name: "经济学",
                target:
                    "本专业培养具备比较扎实的马克思主义经济学理论基础，熟悉现代西方经济学理论，比较熟练地掌握现代经济分析方法，知识面较宽，具有向经济学相关领域扩展渗透的能力，能在综合经济管理部门、政策研究部门，金融机构和企业从事经济分析、预测、规划和经济管理工作的高级专门人才。",
                course:
                    "政治经济学、西方经济学、会计学、统计学、计量经济学、国际经济学、货币银行学、财政学、经济学说史、发展经济学、企业管理、市场营销、国际金融、国际贸易等。",
                abroadVista: 4,
                major_code: "020101",
            },
            {
                name: "经济统计学",
                target:
                    "培养具有良好的经济学素养，掌握统计学的基本原理和方法，能熟练的运用计算机分析数据，能在企业、事业单位和经济、管理部门从事统计调查、统计信息管理、数量分析等开发、应用和管理工作，或在科研、教育部门从事研究和教学工作的高级专门人才。",
                course:
                    "数理统计、描述统计、国民经济核算、宏观经济统计分析、抽样调查原理、多源统计分析、计算机应用、政治经济学、会计学、货币银行学等。",
                abroadVista: 4,
                major_code: "020102",
            },
            {
                name: "财政学",
                target:
                    "本专业培养具备财政、税务等方面的理论知识和业务技能，能在财政、税务及其他经济管理部门和企业从事相关工作的高级专门人才。",
                course:
                    "政治经济学、西方经济学、货币银行学、国际经济学、财政学、国家预算、税收管理、国际税收、国有资产管理等。",
                abroadVista: 1,
                major_code: "020201K",
            },
            {
                name: "税收学",
                target:
                    "本专业按照宽口径、厚基础、重实践、强能力的要求，注重培养学生的综合素质和强化学生的税收科研能力，培养适应税收学科发展需要，能在政府财税机关、税务中介机构、企事业单位，尤其是税务研究机构从事税收工作的复合型税务人才，为在经济学科、管理学科继续深造打下基础。",
                course:
                    "税收理论、中国税制、外国税制、税收筹划、税务代理实务、国际税收、税收管理、财税信息化、财务会计、公共财政学、中国财政史、国家预算、公共支出管理、宏观经济学、微观经济学、计量经济学、经济法、国际经济学。",
                abroadVista: 1,
                major_code: "020202",
            },
            {
                name: "金融学",
                target:
                    "本专业培养具备金融学方面的理论知识和业务技能，能在银行、证券、投资、保险及其他经济管理部门和企业从事相关工作的专门人才。",
                course:
                    "政治经济学、西方经济学、财政学、国际经济学、货币银行学、国际金融管理、证券投资学、保险学、商业银行业务管理、中央银行业务、投资银行理论与实务等。",
                abroadVista: 3,
                major_code: "020301K",
            },
            {
                name: "保险学",
                target:
                    "本专业培养适应我国保险业现代化、国际化发展要求，具有保险学、保险业务与管理、金融投资等方面的理论知识与业务技能，能够从事商业性保险业务的营销、经营管理、社会保险基金运作与管理、保险监管等实际工作以及科学研究工作的高级保险人才。",
                course:
                    "微观经济学、宏观经济学、国际经济学、货币银行学、金融市场学、计量经济学、会计学、统计学、财政学、管理学、保险学、经济法、保险公司经营管理、保险学原理、保险精算、财产保险原理与实务、人寿保险原理与实务社会保险、人寿与健康保险、财产和责任保险、保险公司财务管理、利息理论、寿险精算、非寿险精算、公司金融等。",
                abroadVista: 3,
                major_code: "020303",
            },
            {
                name: "投资学",
                target:
                    "旨在培养具备当代世界政治、经济视野，了解中国投资政策，能够在银行、证券公司、资产管理公司、基金及信托公司等金融机构和投资咨询公司等企业从事投资管理、投资咨询工作的高素质的投资专门人才。",
                course:
                    "政治经济学、西方经济学、计量经济学、货币银行学、财政学、会计学、投资学、国际投资、企业投资学、公共投资学、风险投资、投资项目评估、证券投资学、投资基金管理、投资银行概论、人力资本投资、项目融资、投资估算、投资项目管理、房地产投资、投资管理信息系统等。",
                abroadVista: 4,
                major_code: "020304",
            },
            {
                name: "国际经济与贸易",
                target:
                    "本专业培养的学生应较系统地掌握马克思主义经济学基本原理和国际经济、国际贸易的基本理论，掌握国际贸易的基本知识与基本技能，了解当代国际经济贸易的发展现状，熟悉通行的国际贸易规则和惯例，以及中国对外贸易的政策法规，了解主要国家与地区的社会经济情况，能在涉外经济贸易部门、外资企业及政府机构从事实际业务、管理、调研和宣传策划工作的高级专门人才。",
                course:
                    "政治经济学、西方经济学、国际经济学、计量经济学、世界经济概论、国际贸易理论与实务、国际金融、国际结算、货币银行学、财政学、会计学、统计学。",
                abroadVista: 2,
                major_code: "020401",
            },
        ],
        jobs: [
            {
                name: "管理咨询顾问",
                content:
                    "向客户提供管理咨询服务，参与企业内部访谈及企业现状的调研分析。\r\n参与咨询方案的设计、研讨、讲解、培训，并撰写项目报告。\r\n参与项目小组的讨论，提出创造性的观点和建议。\r\n根据项目需求进行行业研究、案例分析和相关政策专题研究等。\r\n进行相关领域的理论研究与项目总结，参与学术交流。",
                salaryMedian: 10000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "产业研究员",
                content:
                    "负责围绕公司重点产业领域开展研究，分析相关政策法规，判断产业发展趋势与前景，发掘行业的前瞻性投资机会，形成产业研究报告。 \r\n负责推进公司整体产业研究工作，包括日常联络沟通、活动组织和监督落实等。\r\n负责撰写研究报告及项目申报工作。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "基金经理",
                content:
                    "撰写宏观经济、证券市场、行业和投资品种研究分析报告，制定投资策略。\r\n负责对股票或期货进行调研。\r\n负责投资组合的构建、评估和动态调整。\r\n负责交易数据的研究分析和风险监控。\r\n撰写投资备忘和研究报告；\r\n配合基金融资需求，分析、评估市场条件，制定融资方案。",
                salaryMedian: 30000,
                educational: "硕士",
                qualification: "《基金从业资格证》",
                jobVista: 5,
            },
            {
                name: "投融资经理",
                content:
                    "负责公司与金融机构、外资投资机构、风险投资机构联系。\r\n负责寻找融资资本，全面规划融资项目。\r\n负责融资渠道的发掘、维护，投资项目的前期规划。\r\n负责引入外部资金，设计合作模式，对企业发展进行投融资支持。\r\n负责撰写项目建议书、可行性研究报告、商业计划书等。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "保险精算师",
                content:
                    "收集、整理、分析、统计资料，运用数理统计方法设计有关被保险人的疾病 、伤害、失业、退休及财产损失等事故出现的概率表。\r\n依据被保险人的年龄、性别、 职业等因素，计算投保人应缴纳的保险费率。\r\n估算被保险人在保险期内所得到的金额。\r\n评价保险基金投资的利润。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "税务经理",
                content:
                    "根据公司管理需求，完善税务相关管理制度及流程。\r\n参与项目经营税负测算，制定税务规划方案并跟踪执行。\r\n提供税务建议及决策支持，提高税法遵从性，优化项目税负成本支出，降低税务风险。\r\n负责日常税款申报等工作。\r\n负责税收相关政策研究及分析，组织公司内部税务知识培训。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "私人银行理财顾问",
                content:
                    "以专业的金融理财知识和经验，为客户配置资产，为高净值客户提供资产保值、增值、投融资咨询服务。\r\n负责客户的开拓与维护、客户需求分析、客户资产净值的动态跟踪。\r\n通过与高净值客户沟通，了解其投资需求，提供一系列私人银行服务，包括全球资产配置、私人家族信托、税务规划等。\r\n定期向客户告知产品收益情况，介绍新的金融服务、理财产品及金融市场动向。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "外贸经理",
                content:
                    "负责公司产品的海外销售活动的策划和执行，完成销售任务。\r\n负责开拓海外新市场，开发新客户，增加产品销售范围。\r\n负责海外目标市场信息的收集及竞争对手的分析。\r\n负责管理、维护客户关系以及客户间的长期战略合作计划。",
                salaryMedian: 12000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
        ],
    },
    engineering: {
        name: "工程学",
        desc:
            "工程学学习如何运用数学和科学的原理来有效地设计解决方案以满足实际生产生活的需要。",
        subs: [
            {
                name: "工程力学",
                target:
                    "本专业培养具备力学基础理论知识、计算和试验能力，能在各种工程（如机械、土建、材料、能源、交通、航空、船舶、水利、化工等）中从事与力学有关的科研、技术开发、工程设计和力学教学工作的高级工程科学技术人才。",
                course:
                    "理论力学、材料力学、弹性力学、流体力学、振动力学、计算力学、实验力学、结构力学、电工与电子技术、计算机基础知识及程序设计。",
                abroadVista: 4,
                major_code: "080102",
            },
            {
                name: "机械工程",
                target:
                    "本专业培养具备机械设计、制造及自动化的基础知识与应用能力，能在工业生产第一线从事机械工程及自动化领域内的设计制造、科技开发、应用研究、进行管理和经营销售等方面工作的高级工程技术人才。",
                course:
                    "理论力学、材料力学、机械制图、热工学、机械原理、工程流体力学、工程材料及成形工艺、机械设计、自动控制理论、互换性与测量技术、测试技术、机械制造工艺基础、液压与气压传动、机械制造装备设计、机电传动控制、机械创新设计、机械振动、现代设计方法、CAD/CAM技术及应用、微机电系统等。",
                abroadVista: 4,
                major_code: "080201",
            },
            {
                name: "材料科学与工程",
                target:
                    "本专业培养具备包括金属材料、无机非金属材料、高分子材料等材料领域的科学与工程方面较宽的基础知识，能在各种材料的制备、加工成型、材料结构与性能等领域从事科学研究与教学、技术开发、工艺和设备设计、技术改造及经营管理等方面工作，适应社会主义市场经济发展的高层次、高素质全面发展的科学研究与工程技术人才。",
                course:
                    "物理化学、量子与统计力学、固体物理、材料学概论、材料科学基础、材料物理、材料化学、材料力学、材料科学研究方法、材料工艺与设备、计算机在材料科学中的应用等",
                abroadVista: 5,
                major_code: "080401",
            },
            {
                name: "电气工程及其自动化",
                target:
                    "本专业培养能够从事与电气工程有关的系统运行、自动控制、电力电子技术、信息处理、试验分析、研制开发、经济管理以及电子与计算机技术应用等领域工作的宽口径“复合型”高级工程技术人才。",
                course:
                    "电路原理、电子技术基础、电机学、电力电子技术、电力拖动与控制、计算机技术（语言、软件基础、硬件基础、单片机等）、信号与系统、控制理论、高年级根据社会需要学习柔性的、适应性强、覆盖面宽的专业课及专业选修课。",
                abroadVista: 4,
                major_code: "080601",
            },
            {
                name: "计算机科学与技术",
                target:
                    "本专业培养具有良好的科学素养，系统地、较好地掌握计算机科学与技术包括计算机硬件、软件与应用的基本理论、基本知识和基本技能与方法，能在科研部门、教育单位、企业、事业、技术和行政管理部门等单位从事计算机教学、科学研究和应用的计算机科学与技术学科的高级专门科学技术人才。",
                course:
                    "电路原理、模拟电子技术、数字逻辑、数字分析、计算机原理、微型计算机技术、计算机系统结构、计算机网络、高级语言、汇编语言、数据结构、操作系统、编译原理、系统分析与控制、信号处理原理、通信原理概论。",
                abroadVista: 5,
                major_code: "080901",
            },
            {
                name: "土木工程",
                target:
                    "本专业培养掌握工程力学、流体力学、岩土力学和市政工程学科的基本理论和基本知识，具备从事土木工程的项目规划、设计、研究开发、施工及管理的能力，能在房屋建筑、地下建筑、隧道、道路、桥梁、矿井等的设计、研究、施工、教育、管理、投资、开发部门从事技术或管理工作的高级工程技术人才。",
                course:
                    "材料力学、结构力学、流体力学、土力学、建筑材料、混凝土结构与钢结构、房屋结构、桥梁结构、地下结构、道路勘测设计与路基路面结构、施工技术与管理。",
                abroadVista: 3,
                major_code: "081001",
            },
            {
                name: "航空航天工程",
                target:
                    "本专业培养具备航空航天领域的多学科知识，能运用理论分析、数值模拟和实验研究等手段研究和解决航空航天领域的实际问题，能从事导弹、航天器、飞行器等航空航天器总体、结构和系统设计相关工作的高级工程技术人才；毕业生应具有扎实的数学、物理、力学、实验及计算机基础，可直接进入航空航天部门的科研院所和工程单位，从事与航空航天工程有关的科研、技术开发、工程设计、测试、制造、使用、维修和教学工作，也可在航空航天科学与技术、力学等相关专业继续深造。",
                course:
                    "空气动力学I、飞行器结构力学、航空航天概论、机械设计基础、电路与电子学、自动控制原理、工程热力学、飞行器总体设计、飞行器结构设计、飞机维修等。",
                abroadVista: 4,
                major_code: "082001",
            },
            {
                name: "生物医学工程",
                target:
                    "本专业培养具备生命科学、电子技术、计算机技术及信息科学有关的基础理论知识以及医学与工程技术相结合的科学研究能力，能在生物医学工程领域、医学仪器以及其它电子技术、计算机技术、信息产业等部门从事研究、开发、教学及管理的高级工程技术人才。",
                course:
                    "基础医学课程、定量生理学、模拟与数字电子技术、生物医学传感器与测量，微型计算机原理及其在医学中的应用、数字信号处理、医学信号处理、医学图像处理、医学仪器、医学成像技术。",
                abroadVista: 5,
                major_code: "082601",
            },
        ],
        jobs: [
            {
                name: "动力学仿真工程师",
                content:
                    "负责机电相关产品的动力学仿真分析，给出设计优化建议。\r\n负责对系统总体设计进行仿真分析验证。\r\n负责仿真试验的设计、实施、数据处理与分析、对标等工作。\r\n负责仿真数据库建立。\r\n仿真技术规划及仿真流程编制。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 4.5,
            },
            {
                name: "机械工程师",
                content:
                    "参与新产品的机械方案设计。\r\n负责产品图纸、2D/3D模型以及BOM（物料清单）表的制作、元器件选型。\r\n负责现有设备的优化升级，指导、协助设备安装、调试和运行。\r\n协助处理产品加工过程中存在的问题。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "高分子材料科学家",
                content:
                    "负责高分子材料合成及分析，改进材料的性能。\r\n管理研发团队，负责新产品研发可行性分析、立项、产品设计等工作的安排和协调，执行并确保项目按计划完成推进。\r\n负责在研产品技术资料的收集、整理和分析，专利及科技项目申报事宜。\r\n对接国内外科研机构，拓展行业领域内专家的合作与交流，对内、对外演示沟通科研成果。",
                salaryMedian: 20000,
                educational: "博士",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "电气工程师",
                content:
                    "负责工程项目电气设计工作，根据技术要求进行电气元器件选型，绘制原理图。\r\n根据工艺流程图编写设备程序（包括单片机、机器人等设备），并严格按照电气标准化软件标准执行。\r\n负责工程项目设备调试工作，按照技术要求完成设定工艺流程，记录并归档调试报告。\r\n负责工程项目设备装配、调试指导，售后服务技术支持等。\r\n负责工程项目相关输出资料的编写、更新及归档，协助完成项目认证验收工作。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "软件工程师",
                content:
                    "参与软件开发项目需求分析和方案设计。\r\n参与软件代码、代码注释以及开发文档的编写。\r\n根据设计文档或需求说明完成代码调试。\r\n协助测试工程师制定测试计划，定位发现的问题。\r\n分析并解决软件开发过程中的问题。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "建筑结构工程师",
                content:
                    "按照技术规范提交项目工程的结构部分设计工作成果，出具设计图纸。\r\n提出项目结构工程设计条件，提出施工注意事项，优化设计，使其达到施工要求，并合理控制造价。\r\n参与图纸会审。\r\n负责解决施工现场出现的有关结构方面的技术问题。",
                salaryMedian: 10000,
                educational: "本科",
                qualification:
                    "《中华人民共和国一级/二级注册结构工程师执业资格证书》",
                jobVista: 3.5,
            },
            {
                name: "无人机设计工程师",
                content:
                    "负责无人机产品的概念设计。\r\n负责无人机产品具体方案的总体设计（总体参数、总体布置等）。\r\n负责无人机产品研发过程中地面试验、飞行试验的技术策划并牵头实施。\r\n负责编写相关技术文件。\r\n参与研发中心相关方面的科研工作。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "生物医学工程师",
                content:
                    "负责二代DNA测序工作以及相关技术研发。\r\n制订并维护实验操作规程，设计实验优化方案，整理分析实验数据。\r\n开展技术市场调研和科研项目运行。\r\n参与技术创新，撰写专利或文章。\r\n与生物信息团队合作，分析解读生物信息数据。",
                salaryMedian: 10000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4.5,
            },
        ],
    },
    mathematics_and_statistics: {
        name: "数学与统计",
        desc:
            "数学专业学习数学这门语言，并应用它来描述和理解我们的世界。学生们也会探索数据的采集、分析、理解和表达。",
        subs: [
            {
                name: "数学与应用数学",
                target:
                    "本专业培养掌握数学科学的基本理论与基本方法，具备运用数学知识、使用计算机解决实际问题的能力，受到科学研究的初步训练，能在科技、教育和经济部门从事研究、教学工作或在生产经营及管理部门从事实际应用、开发研究和管理工作的高级专门人才。",
                course:
                    "分析学、代数学、几何学、概率论、物理学、数学模型、数学实验、计算机基础、数值方法、数学史等，以及根据应用方向选择的基本课程。",
                abroadVista: 4,
                major_code: "070101",
            },
            {
                name: "信息与计算科学",
                target:
                    "本专业培养具有良好的数学素养，掌握信息科学和计算科学的基本理论和方法，受到科学研究的初步训练，能运用所学知识和熟练的计算机技能解决实际问题，能在科技、教育和经济部门从事研究、教学和应用开发和管理工作的高级专门人才。",
                course:
                    "数学基础课（分析、代数、几何）、概率统计、数学模型、物理学、计算机基础（计算概论、算法与数据结构、软件系统基础）、信息科学基础、理论计算机科学基础、数值计算方法、计算机图形学、运筹与优化等。",
                abroadVista: 5,
                major_code: "070102",
            },
            {
                name: "数理基础科学",
                target:
                    "培养能从事数学、物理等基础科学教学和科研的有发展潜力的优秀人才，同时也为对数理基础要求高的其它学科培养有良好的数理基础的新型人才。",
                course:
                    "分析学、代数学、几何学、概率论、数学模型、数学实验、计算机基础、普通物理学、数学物理方法、理论力学、量子力学、固体物理学、计算物理学入门等。",
                abroadVista: 4,
                major_code: "070103T",
            },
            {
                name: "统计学",
                target:
                    "本专业培养具有良好的数学或数学素养，掌握统计学的基本理论和方法，能熟练地运用计算机分析数据，能在企业、事业单位和经济、管理部门从事统计调查、统计信息管理、数量分析等开发、应用和管理工作，或在科研、教育部门从事研究和教学工作的高级专门人才。",
                course:
                    "数学基础课（分析、代数、几何）、概率论、数理统计、运筹学、计算机基础、应用随机过程、实用回归分析、时间序列分析、多元统计分析、抽样调查、 参数统计、统计预测与决策、风险管理等，以及根据应用方向选择的基本课程（如经济统计方向可选择社会调查方法、经济与社会统计、国民经济核算、会计学等）。",
                abroadVista: 4,
                major_code: "071201",
            },
            {
                name: "应用统计学",
                target:
                    "本专业主要包括一般统计和经济统计两类专业方向，培养具有良好的数学或数学与经济学素养，掌握统计学的基本理论和方法，能熟练地运用计算机分析数据，能在企业、事业单位和经济、管理部门从事统计调查、统计信息管理、数量分析等开发、应用和管理工作，或在科研、教育部门从事研究和教学工作的高级专门人才。",
                course:
                    "数学基础课、概率论、数理统计、运筹学、描述统计、抽样调查原理、多源统计分析、计算机基础、应用随机过程等。",
                abroadVista: 4,
                major_code: "071202",
            },
        ],
        jobs: [
            {
                name: "医学图像算法工程师",
                content:
                    "负责手术导航软件系统医学图像模块的开发和维护。\r\n根据产品需求研究、设计图像处理的算法，包括CT医学图像的特征提取、分割、重建、配准、融合和识别等方面。\r\n负责优化图像处理的结果和代码运行效率，负责产品图像的质量测试，问题排查与解决。\r\n对医疗影像领域相关前沿技术进行技术预研和技术验证。",
                salaryMedian: 25000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "数据库工程师",
                content:
                    "负责数据库的安装、配置和升级，满足工作的需要。\r\n负责日常运维工作，包括数据库定期巡检、数据库报警处理、备份、迁移等。\r\n采集操作系统和数据库相关的运行数据和日志文件并上传归档。\r\n针对客户的需求或技术问题编写技术方案和技术文档。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "数据科学家",
                content:
                    "利用定量分析、数据挖掘的专业知识，分析数据背后的用户行为，与产品开发人员合作解决业务问题、预测产品趋势和商业机会。 \r\n负责数据运营，监控产品关键指标，分析指标变化的原因，生成报表和分析报告。\r\n负责用户研究，探索有效的数据分析和实验方法研究用户体验，为优化产品提供建议。",
                salaryMedian: 20000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "临床统计师",
                content:
                    "为公司新药开发计划提供统计学支持。\r\n确保临床试验中的统计工作符合相应的法规和标准。\r\n完善临床试验方案中统计环节的设计。\r\n负责统计分析计划和统计分析报告的起草，审阅临床研究报告。\r\n参与临床研究论文的撰写和发表工作。",
                salaryMedian: 10000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "商业分析师",
                content:
                    "通过定量分析，识别推动公司业务发展的重要因素。\r\n搭建经营分析体系，建立业务监控、分析和预测模型。\r\n对公司运营中遇到的各类业务问题展开专题研究，产出对改善运营、改进产品有价值的方案和建议。\r\n与销售团队、产品团队保持沟通协作，推动业务目标达成。",
                salaryMedian: 12000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
        ],
    },
    communication: {
        name: "传媒",
        desc:
            "传媒专业教授学生如何有效地以分析，理解和传播信息。传媒专业涉及的领域包括媒体，法律，政治和商业。",
        subs: [
            {
                name: "新闻学",
                target:
                    "本专业培养具备系统的新闻理论知识与技能、宽广的文化与科学知识，熟悉我国新闻、宣传政策法规，能在新闻、出版与宣传部门从事编辑、记者与管理等工作的新闻学高级专门人才。",
                course:
                    "新闻学概论、中国新闻事业史、外国新闻事业、新闻采访与写作、新闻编辑与评、马列新闻论著选读、中国历代文学作品选读、大众传播学、新闻法规与新闻职业道德、新闻摄影、广播电视学、新闻事业管理、广告学与公共关系学等。",
                abroadVista: 4,
                major_code: "050301",
            },
            {
                name: "传播学",
                target:
                    "培养具备现代媒体传播的基础理论、基础知识和基本技能，适应信息化社会与知识经济时代的要求，掌握现代电子媒体特别是电视媒体与网络多媒体传播的基本技能，胜任从事影视传播、新闻传播、网络传播、广告及媒介经营管理的高级专门人才。",
                course:
                    "新闻学概论、传播学概论、传播研究方法、市场营销学、网络传播、网络新闻传播原理与应用、新闻媒体与信息技术、视觉传播、公共信息写作、媒体技术应用与发展、移动通讯技术与发展、宽带技术应用与发展、社会信息化建设等。",
                abroadVista: 3,
                major_code: "050304",
            },
            {
                name: "广告学",
                target:
                    "本专业培养具备广告学理论与技能、宽广的文化与科学知识，能在新闻媒介广告部门、广告公司、市场调查及信息咨询行业以及企事业单位从事广告经营管理、广告策划创意和设计制作、市场营销策划及市场调查分析工作的广告学高级专门人才。",
                course:
                    "传播学概论、广告学概论、广告策划与创意、广告史、广告文案写作、广告经营与管理学、广告媒体研究、广告摄像与摄影、实用美术与广告设计、电脑图文设计、广告效果研究方法。市场调查与分析、公共关系学、中外广告法规与广告职业道德等。",
                abroadVista: 3,
                major_code: "050303",
            },
            {
                name: "网络与新媒体",
                target:
                    "本专业培养适应传统媒体机构、政府机关、事业单位、公司等团体组织急需的宽口径、复合型信息传播人才。",
                course:
                    "广播电视新闻学、新闻学概论、新媒体概论、新媒体实务、数字媒体技术与应用、网络营销策划与创意、网站策划与设计、网页设计与制作、数据库、摄影与摄像、数字图像创意与设计、动画创意与设计等。",
                abroadVista: 4,
                major_code: "050306T",
            },
            {
                name: "公共关系学",
                target:
                    "本专业培养适合市场经济需求，德智体美全面发展，具备扎实的公共关系专业知识和良好的职业道德修养，专门从事组织机构公众信息传播、关系协调与形象管理事务的调查、咨询、策划和实施的复合型人才。",
                course:
                    "传播学、管理学、市场营销学、政治学、社会学、社会心理学、公共关系学原理、公共关系实务与案例、广告学原理、广告策划与策略、广告设计、CI战略、企业文化、领导科学与艺术、组织行为学、人际沟通与礼仪、公共关系写作、口才与演讲、谈判技巧、社会调查方法、计算机应用、大众媒体研究、影视制作、摄影、传播法规教程、传播伦理、美学、国际关系等",
                abroadVista: 2,
                major_code: "120409T",
            },
        ],
        jobs: [
            {
                name: "广告策划师",
                content:
                    "与客户深入沟通，收集产品信息，分析相关资料，提炼产品卖点，推导广告策略。\r\n围绕产品核心主题设计广告方案，搜寻可供参考的成功方案。\r\n负责广告内容制作（如分镜脚本、旁白、画面等）。\r\n与客户、导演对接沟通，根据他们的建议调整方案。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "新闻记者",
                content:
                    "提出选题策划方案，制定新闻采访报道的工作计划。\r\n及时捕捉新闻线索，独立采写完成新闻报道稿件，参与视频制作。\r\n与相关部门协作完成重大选题及采访工作。\r\n保质保量完成所在部门安排的采访任务。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《新闻记者证》",
                jobVista: 3.5,
            },
            {
                name: "公共关系经理",
                content:
                    "负责开发及维护公司重点业务所涉及的关系网络。\r\n组织参与公司与政府有关的日常沟通，代表公司参与社会活动，提升公司的社会知名度和对外形象。\r\n参与政府支持项目的申报和资质认定工作。\r\n协助业务部门进行大客户的商务应酬、出席商务谈判等。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "舆情分析师",
                content:
                    "利用舆情系统和搜索引擎向客户提供舆情监测服务，甄别负面、敏感信息。\r\n把握舆情热点，预测舆情走势，分析潜在危机，提出合理有效的舆论引导建议。\r\n撰写社交媒体舆情分析报告。\r\n根据舆情数据，为客户提供应对策略建议。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "品牌经理",
                content:
                    "根据公司发展战略，制定品牌战略规划。\r\n制定年度品牌推广计划，跟进方案实施。\r\n组织市场调研，掌握消费者需求和竞品动态。\r\n负责品牌传播相关文案的内容策划。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
        ],
    },
    physical_science: {
        name: "自然科学",
        desc: "自然科学专业探索自然现象背后的本质和规律。",
        subs: [
            {
                name: "物理学",
                target:
                    "本专业培养掌握物理学的基本理论与方法，具有良好的数学基础和实验技能，能在物理学或相关的科学技术领域中从事科研、教学、技术和相关的管理工作的高级专门人才。",
                course:
                    "高等数学、普通物理学、数学物理方法、理论力学、热力学与统计物理、电动力学、量子力学、固体物理学、结构和物性、计算物理学入门等。",
                abroadVista: 5,
                major_code: "070201",
            },
            {
                name: "化学",
                target:
                    "本专业培养具备化学的基础知识、基本理论和基本技能，能在化学及与化学相关的科学技术和其它领域从事科研、教学、技术及相关管理工作的高级专门人才。",
                course:
                    "无机化学、分析化学（含仪器分析）、有机化学、物理化学（含结构化学）、化学工程基础等。",
                abroadVista: 4,
                major_code: "070301",
            },
            {
                name: "天文学",
                target:
                    "本专业培养具备良好的数学、物理和天文等方面的基本知识和基本能力，能在天文学及相关学科从事科研、教学和技术工作的高级专门人才。",
                course:
                    "大学数学、大学物理、理论力学、数学物理方法、电动力学、普通天文学、实测大体物理、恒星物理基础、计算天文学人门等。",
                abroadVista: 4,
                major_code: "070401",
            },
            {
                name: "地理信息科学",
                target:
                    "本专业培养基础理论扎实、系统掌握现代信息科学与技术的理论和方法，能从事地球空间信息工程、3S集成（GPS、GIS、RS）、空间数据无线网络传输、数据信息可视化等领域科学研究、应用研究、教学和运营管理等方面工作，有较强的独立工作能力和创新精神的高级科技人才。",
                course:
                    "地理信息系统概论、GIS设计与开发、地理信息工程、计量地理学、遥感导论、遥感数字图象处理、卫星导航定位原理与应用、自然地理学、数据结构、数据库原理与应用等。",
                abroadVista: 4,
                major_code: "070504",
            },
            {
                name: "大气科学",
                target:
                    "本专业培养具有扎实的大气科学基本理论、基本知识和基本技能，能够在大气物理、大气环境、大气探测、气象学、气候学、应用气象及相关学科从事科研、教学、科技开发及相关管理工作的高级专门人才。",
                course:
                    "大气科学概论（地球科学概论）、大气物理学、大气探测学、天气学、大气动力学基础、近代气候学基础等。",
                abroadVista: 4,
                major_code: "070601",
            },
            {
                name: "海洋科学",
                target:
                    "本专业培养具备海洋科学的基本理论、基础知识和基本技能，能在海洋科学及相关领域从事科研、教学、管理及技术工作的高级专门人才。",
                course:
                    "高等数学、大学物理及实验、大学化学及实验、海洋科学导论、生物海洋学、海洋地质学、海洋调查与观测技术（含出海实习）等。",
                abroadVista: 4,
                major_code: "070701",
            },
            {
                name: "地质学",
                target:
                    "本专业培养具备地质学基本理论、基本知识、基本技能和相关学科基础知识，具有较好的科学素养及初步的研究、教学和管理能力，能在科研机构、学校从事地质科学研究或教学工作，在地矿、冶金、建材、石油、煤炭、材料、环境、基础工程、旅游开发从事技术开发与技术管理工作以及在行政部门从事管理工作的高级专门人才。",
                course:
                    "地质学、结晶矿物学、古生物学、地史学、岩石学、构造地质学、矿床学、地球物理及勘探方法、地球化学、遥感技术等。",
                abroadVista: 3,
                major_code: "070901",
            },
            {
                name: "心理学",
                target:
                    "本专业培养具备心理学的基本理论、基本知识、基本技能，能在科研部门、高等和中等学校、企事业单位等从事心理学科学研究、教学工作和管理工作的高级专门人才。",
                course:
                    "普通心理学、实验心理学、心理统计、生理心理学、认知心理学、发展心理学、认知科学等。",
                abroadVista: 4,
                major_code: "071101",
            },
        ],
        jobs: [
            {
                name: "光学工程师",
                content:
                    "负责产品光学系统的设计和评估。\r\n完成新产品的打样、测试和问题分析。\r\n协助生产部门实施批量生产。\r\n参与制定产品光学规格、标准以及生产工艺。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "化学分析员",
                content:
                    "负责样品的日常分析检测工作，按照作业指导书的规范和要求进行样品分析。\r\n负责各类分析检测仪器设备的使用、维护保养工作。\r\n负责检测过程中原始记录的填写、审核，以及检测报告的开具。\r\n负责完成新检测方法的确认。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 2.5,
            },
            {
                name: "计算天文学研究员",
                content:
                    "负责计算天文学算法研发工作，包括天文数据分析算法设计、射电信号处理等。\r\n应用人工智能和高性能计算技术，完成天文大数据处理，实现高效精确的宇宙探索。\r\n负责天文数据库的建立与维护。\r\n跟踪学术界和业界前沿技术进展，并且快速应用到课题任务中。",
                salaryMedian: 20000,
                educational: "博士",
                qualification: "none",
                jobVista: 4.5,
            },
            {
                name: "地理信息系统工程师",
                content:
                    "设计基于三维GIS（地理信息系统）的应用软件。\r\n协助完成代码编写与程序测试工作，编写技术文档。\r\n负责地理数据采集与处理、数据库建设及维护。\r\n对项目实施过程进行规划，整理项目资料。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "大气污染防治工程师",
                content:
                    "对大气进行监测，收集数据并加以分析。参与现场排查工作，判断污染来源，确定项目重点工作方向。\r\n针对本地污染源特点，制定适合本地区的污染源管控措施和专项整治方案。\r\n诊断地区行业特征和企业问题，提出行业整治和企业治理的规划建议。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "海洋生态检测工程师",
                content:
                    "负责调查海域浮游植物的种类组成和数量分布，调查浮游动物的生物量、种类组成和数量分布。\r\n负责鉴定大型底栖生物的种类，测评栖息密度和生物量，分析其相对丰度和群落多样性。\r\n负责参与检测项目报告编辑工作。\r\n协助操作和维护检测设备。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 2.5,
            },
            {
                name: "地质工程师",
                content:
                    "负责矿山查明资源量和保有地质资源量的统计工作，为矿山长期开发规划和短期生产提供资源依据。\r\n负责矿山地质编录、取样的工作，及时收集井下地质、水文资料，为巷道掘进、采区生产提供地质依据。\r\n负责矿山开采储量的动态管理。\r\n配合采矿工程师编制生产探矿年度计划。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 2,
            },
            {
                name: "用户研究工程师",
                content:
                    "通过定性和定量的方式建立用户体验评价体系。\r\n在产品设计阶段，通过可用性测试、用户访谈、焦点小组、问卷调研、后台数据分析和眼动跟踪等方法研究用户行为，为确定产品设计方案提供依据。\r\n产品上线后，对用户进行追踪研究，为持续改进产品体验提供参考。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
        ],
    },
    education: {
        name: "教育学",
        desc:
            "教育学专业学习如何向他人传授知识的理论和应用。该专业学生通常需要理解学习的理论和教授的技巧。学生也可以选择特定的教学方向并学习该方向的专业知识。",
        subs: [
            {
                name: "教育学",
                target:
                    "本专业培养具有良好思想道德品质、较高教育理论素养和较强教育实际工作能力的中、高等师范院校师资、中小学校教育科研人员、教育科学研究单位研究人员、各级教育行政管理人员和其他教育工作者。",
                course:
                    "普通心理学、教育心理学、中国教育史、外国教育史、教育通论、教学论、德育原理、教育社会学、教育统计测量评价、教育哲学、中小学语文或数学教学法。",
                abroadVista: 3,
                major_code: "040101",
            },
            {
                name: "教育技术学",
                target:
                    "本专业培养能够在新技术教育领域从事教学媒体和教学系统的设计、开发。运用、管理和评价等的教育技术学科高级专门人才，包括各级师范院校和中等学校教育技术学课程教师以及各级电教馆、高校和普教的教育技术人员。",
                course:
                    "教育技术学、教学系统设计、计算机教育基础、网络教育应用、远距离教育、电视教材设计与制作、教育技术研究方法、教育传播学。",
                abroadVista: 3,
                major_code: "040104",
            },
            {
                name: "学前教育",
                target:
                    "本专业培养具备学前教育专业知识，能在托幼机构从事保教和研究工作的教师学前教育行政人员以及其他有关机构的教学、研究人才。",
                course:
                    "普通心理学、人体解剖生理学、教育社会学、声乐、舞蹈、美术、学前教育学、幼儿心理学、幼儿教育心理学、幼儿保健学、幼儿教育研究方法等。",
                abroadVista: 1,
                major_code: "040106",
            },
            {
                name: "小学教育",
                target:
                    "本专业培养德、智、体、美、劳全面发展，掌握小学教育专业基本理论、基本知识和基本技能扎实，具有现代教育理念和深厚教育理论素养，具有较强的教育、教学和教研及管理能力，具有较强实践能力和创新精神，能在各类小学和教育机构中从事相关小学教育教学、教学研究和管理工作，能适应地方社会经济发展以及基础教育课程改革需要的高素质应用型小学教育工作者。",
                course:
                    "近现代史、马克思主义基本原理、英语（二）、心理卫生与心理辅导、课程与教学论、发展与教育心理学、中外教育简史、中小学教育管理、比较教育、小学艺术教育、现代教育测量与评价、中小学教师信息技术、学校管理心理学、教学设计、德育原理、教育社会学、学习心理学、教育哲学",
                abroadVista: 1,
                major_code: "040107",
            },
            {
                name: "特殊教育",
                target:
                    "本专业培养具备普通教育和特殊教育的知识和能力，主要能在特殊教育机构及与特殊教育相关的机构从事特殊教育实践、理论研究、管理工作等方面的高级专门人才。",
                course:
                    "特殊教育导论、盲童心理与教育、聋童心理与教育、弱智儿童心理与教育、残疾儿童生理与病理、残疾儿童康复、特殊教育技术。",
                abroadVista: 2,
                major_code: "040108",
            },
            {
                name: "科学教育",
                target:
                    "培养适应社会与经济发展需要，德、智、体全面发展，掌握科技创新教育、综合实践活动课程与教学论、以技术设计为核心的技术教育等基本理论、知识和技能，具备专业化科技教育、传播与普及能力，能够担当中小学综合实践活动必修课的科技教师；高中技术课程师资；从事科协、科技教育场、馆、所、科技教育基地、社区科普站、新闻媒体等的科技教育、传播与普及的专业化人才，以及能继续攻读该领域硕士研究生学历的高级专门人才。",
                course:
                    "高等数学、大学物理、大学化学、生命科学及实验、现代科技概论、工程制图、手工工艺技术、应用力学、机械技术、电子电工技术、技术设计与制作、信息技术、自动化技术基础、综合实践活动教学论、科技创新教育概论。",
                abroadVista: 3,
                major_code: "040102",
            },
            {
                name: "人文教育",
                target:
                    "适应新世纪我国社会发展需要，培养基础扎实、知识结构合理，具有现代教育思想和技能，具有一定理论素养、创新精神和实践能力，既能胜任中学综合文科“人文与社会”课程教学需要，又能适应历史、中文、政治分科教学需要，德、智、体、美全面发展的高级应用人才。",
                course:
                    "自然地理、中国地理、世界地理、人文地理、地理教材分析、地理微格教学、中国历史、中国文学史（含中国现当代文学史）、马克思主义哲学原理、世界历史、古代汉语、政治经济学、文学基本原理、史学理论与方法、法学理论与实践、中国古代文献教程、中学人文学科教学论、人文科学概论、中外历史比较研究、中国思想文化史、社会学、现代汉语、地理学概论、心理学、教育学、专业论文写作等。",
                abroadVista: 1,
                major_code: "040103",
            },
            {
                name: "艺术教育",
                target:
                    "本专业培养德、智、体全面发展，并具有先进教育理念、综合艺术特长的人才，为学前教育、基础教育、特殊教育学校与机构输送从事儿童综合艺术教育的教师和社会工作者。",
                course:
                    "艺术概论、学前教育概论、特殊教育概论、艺术教育、现代教育、技术培训、中外音乐史、中外美术史、艺术欣赏、中外文学名篇欣赏等。",
                abroadVista: 2,
                major_code: "040102",
            },
        ],
        jobs: [
            {
                name: "高校教师",
                content:
                    "掌握本学科最新学术动态，领导或参与学科建设，参与制定并组织实施学科发展规划，团结带领本学科学术骨干进行学科建设。\r\n负责本学科的学术梯队建设，培养和指导年轻教师，形成较稳定的学术群体，积极创建教学、科研团队。\r\n讲授本学科核心课程，每年完成学校规定的教学工作量。\r\n开设学科前沿领域专题讲座，每年面向教师和学生做一场以上学术报告，开展教学改革和学术交流活动。",
                salaryMedian: 8000,
                educational: "博士",
                qualification: "《高等学校教师资格证》",
                jobVista: 5,
            },
            {
                name: "小学教师",
                content:
                    "教授学校开设的各种教程，学习教学大纲，认真备课，按时保质完成学校的教学任务。批改和讲解学生作业，解答学生疑问。\r\n组织考试，选择或编写考卷，批改试卷并登记学生成绩。\r\n对学生进行个别辅导，必要的时候进行家访。\r\n对学生开展思想道德教育，树立儿童正确积极的世界观、人生观。\r\n如实对学生的成绩、表现、思想情况做出客观公正的评价。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "《小学教师资格证》",
                jobVista: 2.5,
            },
            {
                name: "中学教师",
                content:
                    "安排教学进度，按照大纲要求备课并作好备课笔记。\r\n课堂授课，批改和讲解学生作业。\r\n组织考试，选择或编写考卷，批改试卷并登记学生成绩。\r\n对学生进行个别辅导，必要的时候进行家访，指导学生形成健康的身体和心理素质。\r\n积极参与课程建设和课程改革，参加教学研究，不断总结和改进教学方法。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《初级/高级中学教师资格证》",
                jobVista: 3.5,
            },
            {
                name: "教育图书策划",
                content:
                    "负责中小学教育图书独立策划，选题方向的整体策划和全程策划。\r\n负责图书生产流程，能从选题申报、书稿审读、策划方案到印刷全程管理，确保项目的进度及质量。\r\n参与并协调制定图书营销推广方案并监督实施，制定宣传和活动策划。\r\n对来稿进行深度的文字加工，有优秀的作者和译者资源，不断扩宽作者和选题资源。\r\n经常进行市场调研，了解图书市场信息，开拓选题思路。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "特殊教育教师",
                content:
                    "负责对阿兹伯格综合征、自闭症、发育迟缓儿童的各项能力进行评估，并制定个别化教育计划。\r\n撰写目标儿童的教学方案，对特殊儿童进行一对一教学和培训。\r\n及时与家长沟通儿童的教学计划与教学进度。\r\n每天为儿童布置家庭作业，并积极为家长提供家庭训练的方法。\r\n负责研发集体课教案，材料制作及授课。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "《教师资格证》\r\n《特殊教育指导师》",
                jobVista: 3,
            },
            {
                name: "科技辅导教师",
                content:
                    "负责科普展区运行管理、演示性项目及展区内教育活动的日常实施和管理。负责全程讲解、各类教育活动的日常实施和优化完善等。\r\n负责科普活动室、实验室活动策划、组织与推广，以及相关教育项目、课题的研究、开发与统筹。\r\n负责科普培训、创客教育类活动项目、课程相关资源开发与实施，包括教案、教材、教具内容策划、开发。\r\n负责展厅科普教育项目、课题的研究与统筹。\r\n协助即时展览策划、统筹与实施。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "学前教育教研员",
                content:
                    "幼儿师资、教学内容及过程质量评估与监控，确保课程高质量交付。\r\n实施市场调研，挖掘家长和幼儿园的需求，设计专业化的解决方案。\r\n幼儿课程内容的策划、研发、评价与迭代。\r\n研发幼儿师资培训课程并担任讲师，提升教师的教学能力。",
                salaryMedian: 6000,
                educational: "硕士",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "课程顾问",
                content:
                    "通过各种市场活动收集及公司提供的名单，电话邀约家长上门咨询、了解课程、参加体验或活动。\r\n接待来访客户，对客户现场咨询给予专业的解答。\r\n配合完成公司线下活动。\r\n促成家长报名课程，完成个人业绩目标。\r\n配合老师保持与家长的良好的沟通与互动，及时处理反馈意见，提高满意度，完成续费和转介绍目标。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
        ],
    },
    humanities: {
        name: "人文学",
        desc:
            "人文学科通过研究人类的文字，哲学和艺术来窥探人类社会和文化的各个方面。因为其多学科的性质，人文学科的学生可以有更多的空间来选择课程，从而使得课程可以跟自己的教育背景和就业导向更加契合。",
        subs: [
            {
                name: "哲学",
                target:
                    "本专业培养具有一定马克思主义哲学理论素养和系统的专业基础知识，有进一步培养潜质的哲学专门人才，以及能在国家机关、文教事业、新闻出版、企业等部门从事实际工作的应用型、复合型高级专门人才。",
                course:
                    "哲学概论、马克思主义哲学原理、中国哲学史、西方哲学史、科学技术哲学、伦理学、宗教学、美学、逻辑学、心理学、中外哲学原著导读等。",
                abroadVista: 2,
                major_code: "010101",
            },
            {
                name: "历史学",
                target:
                    "本专业培养具有一定的马克思主义基本理论素养和系统的专业基本知识，有进一步培养潜能的史学专门人才，以及能在国家机关、文教事业、新闻出版、文博档案及各类企事业单位从事实际工作的应用型、复合型高级专门人才。",
                course:
                    "中国通史、世界通史、史学导论、中国史学史、西方史学史、考古学通论、历史地理学、古代汉语、中外历史文化原典导读与选读、中国断代史（从先秦到当代）、专题史（经济史、社会史、政治制度史、思想文化史等）、世界各主要国家和地区史（美、英、法、日、德、俄等国、拉美、非洲、中亚、南亚、东南亚等地区）、中外关系史。",
                abroadVista: 3,
                major_code: "060101",
            },
            {
                name: "考古学",
                target:
                    "本专业培养具备考古学基础知识与基本技能，有进一步培养潜能的高层次专门人才和能在考古、文物、博物馆等事业单位及国家机关从事研究、教学、管理等实际工作的考古学高级专门人才。",
                course:
                    "中国通史、世界上古史、中国考古学、考古学导论、旧石器时代考古、新石器时代考古、夏商周考古、战国秦汉考古、三国两晋南北朝考古、隋唐考古、宋元考古、博物馆学概论、文物学理论、考古绘图、田野考古学概论等。",
                abroadVista: 2,
                major_code: "060103",
            },
            {
                name: "文物保护技术",
                target:
                    "本专业培养具备数理化和历史、文物考古的一般知识，以及文物保护材料的合成、分析、文物修复等实际技能，能在考古、博物馆、文物管理机构从事文物保护与研究工作的高级专门人才。",
                course:
                    "文物学概论、中外建筑史、工程力学、古建筑保护、文化人类学、考古学通论、田野考古技术、中国书画、文物鉴定技术、有机质与无机质保护、低温技术与应用、GPS与无线电探测、材料学与古代冶金、金属焊接与表面处理、壁画保护与修复等。",
                abroadVista: 2,
                major_code: "060105T",
            },
            {
                name: "汉语言文学",
                target:
                    "本专业培养具备一定的文艺理论素养和系统的汉语言文学知识，能在新闻文艺出版部门、高校、科研机构和机关企事业单位从事文学评论、汉语言文学教学与研究工作，以及文化、宣传方面的实际工作的汉语言文学高级专门人才。",
                course:
                    "语言学概论、古代汉语、现代汉语、文学概论、中国古代文学史、中国现当代文学史、马克思主义文论、中国古典文献学、汉语史、史学等。",
                abroadVista: 1,
                major_code: "050101",
            },
            {
                name: "应用语言学",
                target:
                    "本专业以理论与应用相结合为特点，既重视语言和语言教学理论的学习和探讨，又强调教学实践和应用。毕业生多在高等院校、研究、出版等机构从事相关专业的教学及研究，或进入国家机关和公司企业从事语言应用方面的工作。",
                course:
                    "西方文论专题、现代语言学专题、应用语言学理论与方法、对外汉语教材编写理论与实践、语言规划研究、社会语言学、言语交际学、修辞学理论与方法、语言规划研究等。",
                abroadVista: 3,
                major_code: "050106T",
            },
            {
                name: "英语",
                target:
                    "本专业培养具有扎实的英语语言基础和比较广泛的科学文化知识，能在外事、经贸、文化、新闻出版、教育、科研、旅游等部门从事翻译、研究、教学、管理工作的英语高级专门人才。",
                course:
                    "基础英语、高级英语、报刊选读、视听、口语、英语写作、翻译理论与实践、语言理论、语言学概论、主要英语国家文学史及文学作品选读、主要英语国家国情等。",
                abroadVista: 3,
                major_code: "050201",
            },
            {
                name: "法语",
                target:
                    "本专业培养具有扎实的相应语言基础，比较广泛的科学文化知识，能在外事、经贸、文化、新闻出版、教育、科研、旅游等部门从事翻译、研究、教学、管理工作的相应语言高级专门人才。",
                course:
                    "基础法语、高级法语、报刊选读、视听、口语、法语写作、翻译理论与实践、语言理论、语言学概论、法语国家文学史及文学作品选读、法语国家国情等。",
                abroadVista: 4,
                major_code: "050204",
            },
        ],
        jobs: [
            {
                name: "社科图书编辑",
                content:
                    "从事社科图书的市场调查，独立策划有特色的图书选题。\r\n扮演项目负责人角色，跟进作者稿件写作、协调稿件加工进度、撰写营销文案、处理成书后期事务。\r\n积极开拓作者资源，与相关专家建立联系与合作。\r\n独立完成书稿编辑加工，对编校质量负责。完成岗位年度出书品种、利润、编辑加工字数指标。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "《中华人民共和国出版专业技术人员职业资格证书》",
                jobVista: 3,
            },
            {
                name: "博物馆馆长",
                content:
                    "统筹博物馆的规划设计、建设和人员管理工作。\r\n负责博物馆全年活动规划，完成策展、布展方案。\r\n收集藏品，组织人员进行鉴定，并指导陈列和管理。\r\n定期与国内外同行开展文化交流活动，举办交流展览会。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "考古技术人员",
                content:
                    "搜集整理考古项目历史文献。\r\n按照《考古勘探工作规程》开展田野考古勘探工作，确保勘探过程合法合规、勘探结果科学精确。\r\n整理考古原始资料，制作各类图表，汇编考古工作报告。\r\n绘制遗迹平剖面图及地层剖面图。",
                salaryMedian: 4000,
                educational: "本科",
                qualification: "none",
                jobVista: 2,
            },
            {
                name: "文物修复师",
                content:
                    "独立完成文物修复工作，制定修复方案，记录修复过程，编写修复工作报告。\r\n熟悉文物材质，对文物修复的传统技艺进行研究和总结。\r\n运用新材料、新技术、新设备，对文物修复中出现的问题提出课题进行研究，独立或协助完成科研项目。",
                salaryMedian: 4000,
                educational: "本科",
                qualification: "none",
                jobVista: 2,
            },
            {
                name: "办公室主任",
                content:
                    "统筹公司办公室日常事务，负责公司重大文书、各类公文、管理制度和工作流程文件的审核把关。\r\n组织公司常规会议，参与公司重大决策的研讨和制定。\r\n负责公司网站、自媒体、内部刊物等企业文化平台的管理与维护。\r\n整合公司内外资源，获取政策优势，组织策划公司重大活动。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "人工智能训练师",
                content:
                    "负责智能客服机器人知识库和对话库的设计，保证客服机器人问答的准确率。\r\n根据指定的规则，对数据进行标注。\r\n负责设计和优化智能客服机器人的话术。\r\n通过测试工具，收集人机对话过程中的错误，协助优化产品功能。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "商务外语翻译",
                content:
                    "负责公司网站、产品资料、往来邮件、商务文件的翻译工作。\r\n负责公司对外商务谈判以及联络的现场翻译工作。\r\n协助公司负责人出席对外商务活动，负责重要客户的接待工作。\r\n与国外客户进行日常沟通交流，维系客户关系。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
        ],
    },
    business: {
        name: "商科",
        desc:
            "商学专业帮助学生从各个层面理解商业的运作基础，从而使他们能够胜任会计、金融、市场营销和管理等各领域的工作。",
        subs: [
            {
                name: "会计学",
                target:
                    "本专业培养具备管理、经济、法律和会计学等方面的知识和能力，能在企、事业单位及政府部门从事会计实务以及教学、科研方面工作的工商管理学科高级专门人才。",
                course:
                    "管理学、微观经济学、宏观经济学、管理信息系统、统计学、会计学、财务管理、市场营销、经济法、财务会计、成本会计、管理会计、审计学。",
                abroadVista: 2,
                major_code: "120203K",
            },
            {
                name: "财务管理",
                target:
                    "本专业培养具备管理、经济、法律和理财、金融等方面的知识和能力，能在工商、金融企业、事业单位及政府部门从事财务、金融管理以及教学、科研方面工作的工商管理学科高级专门人才。",
                course:
                    "管理学、微观经济学、宏观经济学、管理信息系统、统计学、会计学、财务管理、市场营销、经济法、中级财务管理、高级财务管理、商业银行经营管理（或金融机构管理）、投资学、跨国公司财务、项目评估。",
                abroadVista: 3,
                major_code: 120204,
            },
            {
                name: "工商管理",
                target:
                    "本专业培养具备管理、经济、法律及企业管理方面的知识和能力，能在企、事业单位及政府部门从事管理以及教学、科研方面工作的工商管理学科高级专门人才。",
                course:
                    "管理学、微观经济学、宏观经济学、管理信息系统、统计学、会计学、财务管理、市场营销、经济法、运营管理、人力资源管理、企业战略管理。",
                abroadVista: 4,
                major_code: "120201K",
            },
            {
                name: "市场营销",
                target:
                    "本专业培养具备管理、经济、法律、市场营销等方面的知识和能力，能在企、事业单位及政府部门从事市场营销与管理以及教学、科研方面工作的工商管理学科高级专门人才。",
                course:
                    "管理学、微观经济学、宏观经济学、管理信息系统、统计学、会计学、财务管理、市场营销、经济法、消费者行为学、国际市场营销、市场调查。",
                abroadVista: 4,
                major_code: 120202,
            },
            {
                name: "人力资源管理",
                target:
                    "本专业培养具备管理、经济、法律及人力资源管理等方面的知识和能力，能在企、事业单位及政府部门从事人力资源管理以及教学、科研方面工作的工商管理学科高级专门人才。",
                course:
                    "管理学、微观经济学、宏观经济学、管理信息系统，统计学、会计学、财务管理、市场营销、经济法、人力资源管理、组织行为学、劳动经济学。",
                abroadVista: 2,
                major_code: 120206,
            },
            {
                name: "审计学",
                target:
                    "本专业培养面向基层、面向管理一线、牢固掌握相应的专业知识和专业技能，具有扎实的财会、审计专业知识，熟练掌握企业会计手工和微机操作基本技能、审计技能，既有一定财会、审计理论水平，又有较强的动手能力的高级岗位技术人才。",
                course:
                    "审计学原理、财务会计、财务管理、管理会计、审计学、财务收支审计、经济效益审计、统计学、经济法。",
                abroadVista: 2,
                major_code: 120207,
            },
            {
                name: "国际商务",
                target:
                    "本专业培养具有国际贸易基础知识与基本技能，能在涉外经济贸易部门、中外合资企业从事国际贸易业务和管理工作的高等技术应用性专门人才。",
                course:
                    "西方经济学、国际贸易理论与政策、国际贸易实务、国际商法、国际结算、海关业务、外贸函电、国际市场营销、电子商务概论、外贸制单、外贸谈判技巧等。",
                abroadVista: 3,
                major_code: 120205,
            },
            {
                name: "资产评估",
                target:
                    "本专业旨在培养具备扎实的经济学、管理学基础理论知识，以及资产评估理论知识，具有能够出色完成各类资产价值分析和评估的专业能力，并具有国际化视野的创新型、应用型、复合型专门人才。",
                course:
                    "经济法、财务会计、财务管理、统计学、金融学、保险学、投资学、财政学、税法、资产评估原理、建筑工程概论、机电设备评估、建筑工程评估、企业资产评估、审计学、国有资产管理、企业价值评估、房地产评估、无形资产评估、国际评估准则等。",
                abroadVista: 3,
                major_code: 120208,
            },
        ],
        jobs: [
            {
                name: "注册会计师",
                content:
                    "负责承办审计业务：审查企业财务报告，出具审计报告。办理企业合并、分立、清算事宜中的审计业务，出具有关报告。\r\n负责验资业务：对被审验单位的实收资本及相关的资产、负债的真实性、合法性进行验证。\r\n承担管理咨询工作：为客户提供政策咨询、业务咨询、信息咨询等服务，如设计会计制度、税务代理和税务咨询、资产评估、企业改制等。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "《注册会计师证》",
                jobVista: 4.5,
            },
            {
                name: "财务总监",
                content:
                    "负责制定、完善公司内部的会计核算、财务管理、计划预算、内部控制等制度，贯彻执行国家的各项法律法规。\r\n协助公司领导层对公司的经营问题作出决策。\r\n对公司会计核算的准确性、及时性进行审核。\r\n审核公司的大额费用支出、资本性支出及财务合同。\r\n负责公司资金计划的审核、融资安排及资金运用。",
                salaryMedian: 25000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "总经理",
                content:
                    "根据公司的战略发展目标，制订公司年度工作经营指标，提出公司的业务规划并组织检查落实。\r\n完成各项经营、管理指标，提高公司的经济效益。\r\n及时分析企业经营活动的运行情况，提出改进和提高的意见。\r\n主持制订公司年度预决算、审批公司重大经费的开支和资金使用、分配方案。\r\n主持制定和完善公司各项制度，建立健全内部的组织系统，协调各部门关系，建立内部合理而有效的运行机制。",
                salaryMedian: 25000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "市场总监",
                content:
                    "根据公司发展目标及战略，策划公司产品和品牌定位，制订有效的市场推广策略。\r\n规划和拓展市场渠道，探索创新更适合公司的营销模式，实现公司整体市场目标。\r\n负责品牌推广和宣传，制定各种营销活动，对危机事件作出有效反应。\r\n制定年度市场活动预算与计划，监督实施并评估活动效果与预算执行情况。",
                salaryMedian: 20000,
                educational: "本科",
                qualification: "none",
                jobVista: 4.5,
            },
            {
                name: "人力资源总监",
                content:
                    "根据公司发展战略及规划，制定企业人力资源规划。\r\n完善人力资源管理体系，包括招聘、培训、绩效管理、薪酬管理以及员工发展等方面。\r\n负责人才队伍的建设，控制人力资源成本。\r\n推进公司企业文化建设。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "审计经理",
                content:
                    "负责搭建公司审计监察系统，建立健全各项管理制度，监督各项规章制度的执行。\r\n与财务部共同制定和维护公司内部审计流程，制定财务工作的标准流程，确保财务工作符合审计规范。\r\n定期对内部各项目进行审计，对公司潜在风险进行预警，提出有效的优化建议，将风险控制在可控范围内。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "国际商务经理",
                content:
                    "对国外商业机会进行综合评估筛选，促成合作。\r\n负责国外客户资源的发掘、合作关系建立和维护。\r\n负责制定谈判方案，与客户完成合作协议的签定并跟踪落实。\r\n建立国外项目数据库及客户信息收集表，定期维护。",
                salaryMedian: 30000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "注册资产评估师",
                content:
                    "负责承接资产评估项目，完成资产评估任务。\r\n负责制定评估方案与计划，安排组织评估师队伍。\r\n负责资产评估项目的现场查勘和调查。\r\n运用成本法、收益现值法、市场法等评估方法，测算评估结果，撰写评估报告。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "《中华人民共和国资产评估师职业资格证书》",
                jobVista: 4,
            },
        ],
    },
    health: {
        name: "医疗卫生",
        desc:
            "医疗卫生专业整合了科学，数学，工程和技术领域的知识来指导医疗和保健方面的实践。",
        subs: [
            {
                name: "基础医学",
                target:
                    "本专业培养具备自然科学、生命科学和医学科学基本理论知识和实验技能，能够在高等医学院校和医学科研机构等部门从事基础医学各学科的教学、科学研究及基础与临床相结合的医学实验研究工作的医学高级专门人才。",
                course:
                    "人体解剖学、组织胚胎学、细胞生物学、生理学、神经生理学、生物化学与分子生物学、医学遗传学、微生物学与免疫学、病理学、药理学、临床医学。",
                abroadVista: 5,
                major_code: "100101K",
            },
            {
                name: "临床医学",
                target:
                    "本专业培养具备基础医学、临床医学的基本理论和医疗预防的基本技能，能在医疗卫生单位、医学科研等部门从事医疗及预防、医学科研等方面工作的医学高级专门人才。",
                course:
                    "人体解剖学、组织肝胎学、生理学、生物化学、药理学、病理学、预防医学、免疫学、诊断学、内科学、外科学、妇产科学、儿科学、中医学。",
                abroadVista: 5,
                major_code: "100201K",
            },
            {
                name: "麻醉学",
                target:
                    "本专业培养具有基础医学、临床医学和麻醉学等方面的基本理论知识和基本技能，能在医疗卫生单位的麻醉科、急诊科、急救中心、重症监测治疗病房（ICU）、药物依赖戒断及疼痛诊疗等领域从事临床麻醉、急救和复苏、术后监测、生理机能调控等方面工作的医学高级专门人才。",
                course:
                    "人体解剖学、生理学、药理学、内科学、外科学、临床麻醉学、急救医学、疼痛诊疗学、麻醉药理学、麻醉设备学、麻醉生理学、麻醉解剖学。",
                abroadVista: 4,
                major_code: "100202TK",
            },
            {
                name: "放射医学",
                target:
                    "本专业培养从事放射医学及防护、放射病诊治、核医学及医学影像等工作的德智体全面发展的、具有创新精神和系统的放射医学理论与实践能力的临床医师。",
                course:
                    "体解剖学、组织与胚胎学、病原学、免疫学、生物化学、生理学、病理学、药理学、临床医学导论、内科学、外科学、预防医学、放射医学及防护、影像诊断学、肿瘤放射治疗学、核医学",
                abroadVista: 4,
                major_code: "100206TK",
            },
            {
                name: "口腔医学",
                target:
                    "本专业培养具备医学基础理论和临床医学知识，掌握口腔医学的基本理论和临床操作技能，能在医疗卫生机构从事口腔常见病、多发病的诊治、修复和预防工作的医学高级专门人才。",
                course:
                    "物理学、生物化学、口腔解剖生理学、口腔组织病理学、口腔材料学、口腔内科学、口腔颌面外科学、口腔修复学、口腔正畸学。",
                abroadVista: 5,
                major_code: "100301K",
            },
            {
                name: "预防医学",
                target:
                    "本专业学生主要学习基础医学、预防医学的基本理论知识，受到卫生检测技术、疾病控制的基本训练，具有卫生防疫、控制传染病与职业病、改进人群环境卫生、实施食品卫生监督等工作的基本能力。",
                course:
                    "生物化学、医学微生物学、免疫学、生理学、病理学、诊断学、内科学、卫生统计学、 流行病学、环境卫生学、营养与食品卫生学、劳动卫生学、少儿卫生学、卫生事业管理。",
                abroadVista: 4,
                major_code: "100401K",
            },
            {
                name: "药学",
                target:
                    "本专业培养具备药学学科基本理论、基本知识和实验技能，能在药品生产、检验、流通、使用和研究与开发领域从事鉴定、药物设计、一般药物制剂及临床合理用药等方面工作的高级科学技术人才。",
                course:
                    "有机化学、物理化学、生物化学、微生物学、药物化学、药剂学、药理学、药物分析学、药事管理学、临床医学概论。",
                abroadVista: 5,
                major_code: 100701,
            },
            {
                name: "护理学",
                target:
                    "本专业培养具备人文社会科学、医学、预防保健的基本知识及护理学的基本理论知识和技能，能在护理领域内从事临床护理、预防保健、护理管理、护理教学和护理科研的高级专门人才。",
                course:
                    "人体解剖学、生理学、医学伦理学、心理学、病因学、药物治疗学、诊断学基础、护理学基础、急重症护理、内外科护理学、妇儿科护理学、精神护理学、预防医学、护理管理学。",
                abroadVista: 3,
                major_code: 101101,
            },
        ],
        jobs: [
            {
                name: "临床试验项目经理",
                content:
                    "提出有价值的新药研究方向，组织申报临床试验。\r\n参与临床试验外包公司和技术供应商的评估、筛选和管理。\r\n管理项目成本，控制项目费用。 \r\n制定临床研究项目进度计划表，跟进、协调公司内部及外包公司的工作进程，确保按时结项。",
                salaryMedian: 12000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "外科医师",
                content:
                    "询问和检查患者，书写病历，记录病案。\r\n给予医嘱或实施化验、影像学、介入方法、手术、穿刺技术以及其他诊断程序。\r\n分析化验和检查报告，做出诊断，确定是否施行外科手术治疗。\r\n制定外科手术方案、实施手术。\r\n开具处方，进行药物治疗、化学治疗或其他疗法。\r\n观察患者术后病情变化并采取相应的治疗措施。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《医师资格证书》",
                jobVista: 4.5,
            },
            {
                name: "内科医师",
                content:
                    "询问和检查患者，书写病历，记录病案。\r\n实施临床检验、影像学、介入方法、穿刺技术以及其他辅助诊断程序。\r\n开具饮食、辅助治疗、处方药物及具体实施药物治疗等医嘱，提出康复、预防建议。\r\n选择适应证，应用介入、腔镜等诊疗手段治疗内科疾病。\r\n提出会诊请求或介绍患者转科治疗意见。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《医师资格证书》",
                jobVista: 4.5,
            },
            {
                name: "麻醉科医师",
                content:
                    "运用药物，对手术患者实施麻醉。\r\n使用仪器监测患者术中的麻醉状态，调整患者呼吸与循环功能。\r\n监测、治疗麻醉恢复期患者，诊断、治疗麻醉后并发症。\r\n治疗、观察急性疼痛，参与慢性疼痛患者诊断和治疗。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《医师资格证书》",
                jobVista: 4.5,
            },
            {
                name: "放射科医师",
                content:
                    "获取患者病史及相关资料。\r\n进行X射线透视、电子计算机体层摄影(CT)与磁共振成像(MRI)等影像学检查。\r\n分析对比影像资料，作出诊断意见。\r\n进行介入性放射学检查和治疗。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "《医师资格证书》",
                jobVista: 4,
            },
            {
                name: "公共卫生医师",
                content:
                    "监测、描述疾病在辖区内的发生、分布和变化情况。\r\n分析疾病的分布原因，提出预防控制对策，参与预防控制效果评价。\r\n进行疾病控制中的公共卫生监督管理。\r\n调查、处理新发传染病、再发传染病或其他特殊疾病事件。\r\n研究、分析病因学与危险因素，制定干预措施。\r\n进行预防接种与药品治疗性预防。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "《医师资格证书》",
                jobVista: 3.5,
            },
            {
                name: "药师",
                content:
                    "负责药品采购、处方或用药医嘱审核、医院制剂配制。\r\n保存配方档案。\r\n负责特殊药品的登记与管理。\r\n进行药品质量监测，收集、整理、报告药品不良反应和药品损害情况。\r\n参与临床药物治疗并提出建议，参加查房、会诊、病例讨论和疑难危重患者的救治。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "《中华人民共和国执业药师职业资格证书》",
                jobVista: 3.5,
            },
            {
                name: "外科护士",
                content:
                    "遵循外科护理程序，制订术前、术中、术后护理康复计划，进行术前准备及术后护理。\r\n执行基础护理及外科护理常规。\r\n执行医嘱，配合各项诊疗工作，实施护理措施。\r\n参与危重患者抢救。\r\n进行健康教育和康复指导。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "《护士资格证》\r\n《护士执业证书》",
                jobVista: 4,
            },
        ],
    },
    social_science: {
        name: "社会科学",
        desc: "社会科学专业主要学习人类社会以及人与人之间的关系。",
        subs: [
            {
                name: "社会学",
                target:
                    "本专业培养具备较全面的社会学理论知识、较熟练的社会调查技能，能在教育、科研机构、党政机关、企事业单位、社会团体从事社会研究与调查、政策研究与评估、社会规划与管理、发展研究与预测等工作的社会学高级专门人才。",
                course:
                    "社会学概论、社会研究方法、社会心理学、中外社会息想史、古典及现代社会学理论、社会统计与计算机应用、中国社会等。",
                abroadVista: 3,
                major_code: "030301",
            },
            {
                name: "社会工作",
                target:
                    "本专业培养具有基本的社会工作理论和知识，较熟练的社会调查研究技能和社会工作能力，能在民政、劳动、社会保障和卫生部门，及工会、青年、妇女等社会组织及其他社会福利、服务和公益团体等机构从事社会保障、社会政策研究、社会行政管理、社区发展与管理、社会服务、评估与操作等工作的高级专门人才。",
                course:
                    "社会学概论、社会工作概论、个案与团体工作、社区工作、社会行政、社会保险与社会福利、社会环境保护、人口社会学、组织社会学、社会心理学、青少年社会工作、老年社会工作、社会调查方法、社会统计学等。",
                abroadVista: 1,
                major_code: "030302",
            },
            {
                name: "人类学",
                target:
                    "本专业培养具备系统的人类学训练、掌握民族学基本知识，有进一步培养潜能的民族学、人类学专门人才，以及能在国家机关、文教事业、新闻出版、社会团体和各类企事业部门从事实际工作的应用型、复合型高级专门人才",
                course:
                    "人类学概论、文化人类学理论方法、考古学、体质人类学、语言学、世界民族志、中国民族学、中国民族志、宗教学概论、社会学概论、田野调查方法等。",
                abroadVista: 2,
                major_code: "030303T",
            },
            {
                name: "政治学与行政学",
                target:
                    "本专业培养具有一定马克思主义理论素养和政治学、行政学方面的基本理论和专门知识，能在党政机关、新闻出版机构、企事业和社会团体等单位从事教学科研、行政管理等方面工作的政治学和行政学高级专门人才。",
                course:
                    "政治学原理、行政学概论、中国政治制度史、当代中国政治制度、中国政治思想史、当代西方政治思潮、中国社会政治分析、比较政治制度、市政学、公共政策概论、政法学、人事行政学、社会调查与社会统计等。",
                abroadVista: 2,
                major_code: "030201",
            },
            {
                name: "法学",
                target:
                    "本专业培养具备金融学方面的理论知识和业务技能，能在银行、证券、投资、保险及其他经济管理部门和企业从事相关工作的专门人才。本专业培养系统掌握法学知识，熟悉我国法律和党的相关政策，能在国家机关、企事业单位和社会团体、特别是能在立法机关、行政机关、检察机关、审判机关、仲裁机构和法律服务机构从事法律工作的高级专门人才。",
                course:
                    "法理学、中国法制史、宪法、行政法与行政诉讼法、民法、商法、知识产权法、经济法、刑法、民事诉讼法、刑事诉讼法、国际法、国际私法、国际经济法。",
                abroadVista: 2,
                major_code: "030101K",
            },
            {
                name: "国际政治",
                target:
                    "本专业培养具有一定马克思主义理论素养和国际政治、国际法、政治学等方面的基本理论和专门知识，能在党政机关、企事业单位、高校和科研等部门从事外交、外事、对外宣传、教学和研究等方面的国际政治学高级专门人才。",
                course:
                    "政治学原理、国际政治概论、国际法与国际组织、比较政治制度、当代国际关系、近现代国际关系、近现代中国外交、西方国际关系理论、国际政治经济学、美国外交政策、亚太地区政治与经济、欧洲地区政治与经济、各国政治与经济等。",
                abroadVista: 4,
                major_code: "030202",
            },
            {
                name: "外交学",
                target:
                    "本专业培养具有一定的马克思主义理论素养和强烈的爱国心，对国际事务和中国外交有系统全面的了解，有交际和谈判的能力，在政治、业务、作风、纪律和语言方面具有较高的综合素质，能在外交和其他外事部门从事实际工作、国际问题研究和教学工作的外交学高级专门人才。",
                course:
                    "外交学、国际关系理论、中国外交史、当代中国外交、国际政治导论、国际关系史。当代国际政治与多边外交、国际政治经济学、世界经济概论、国际法与国际组织、外国政治制度、宗教与国际政治、谈判学、专业外语等。",
                abroadVista: 3,
                major_code: "030203",
            },
            {
                name: "国际事务与国际关系",
                target:
                    "本专业培养具有一定马克思主义理论素养和政治学、行政学方面的基本理论和专门知识，能在外企公司、大型企业等从事国际事务等方面工作的国际事务与国际关系学的高级专门人才。",
                course:
                    "现代国际关系理论、世界各国概况、国际法、国际商务与管理、国际交流学、国际组织学、中国涉外法规、WTO和国际贸易规则、外事实务、涉外文书演习、外交礼仪演习、媒体与国际关系、国际文化理论、世界宗教概况、专业外语等。",
                abroadVista: 3,
                major_code: "030204T",
            },
        ],
        jobs: [
            {
                name: "市场调查研究员",
                content:
                    "负责市场调查项目的管理和执行工作，确保项目按时顺利完成。\r\n参与客户前期需求沟通，协助完成计划书的撰写。\r\n完成访谈大纲的设计。\r\n撰写深度访谈/座谈会小结，完成定性研究报告。",
                salaryMedian: 10000,
                educational: "硕士",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "社会工作者",
                content:
                    "完成社区服务常规工作。\r\n收集服务对象的相关资料，跟踪记录服务过程，提出改进建议。\r\n参与社区公益活动的策划、组织、宣传工作，参与社区党建相关工作。\r\n负责协调、培训和管理志愿者和实习生。\r\n参与制定公益机构本身的发展规划和工作计划。",
                salaryMedian: 5000,
                educational: "本科",
                qualification: "none",
                jobVista: 2.5,
            },
            {
                name: "企业法务经理",
                content:
                    "为公司及特定产品提供综合性法律支持。\r\n负责起草、修改、审核各类合同、协议等法律文件。\r\n参与重大项目的商务谈判，进行法律风险分析。\r\n负责各类法律纠纷及案件处理。\r\n负责法律培训和宣传。",
                salaryMedian: 15000,
                educational: "本科",
                qualification: "《中华人民共和国法律职业资格证书》",
                jobVista: 4,
            },
            {
                name: "律师",
                content:
                    "接受民事、行政案件当事人的委托，担任代理人，参加诉讼。\r\n接受刑事案件犯罪嫌疑人的委托，为其提供法律咨询，代理申诉。\r\n接受犯罪嫌疑人、被告人的委托或者人民法院的指定，担任辩护人。\r\n接受委托，参加调解、仲裁活动。",
                salaryMedian: 10000,
                educational: "本科",
                qualification:
                    "《中华人民共和国法律职业资格证书》\r\n《中华人民共和国律师执业证》",
                jobVista: 5,
            },
            {
                name: "检察官",
                content:
                    "依法进行法律监督工作。\r\n代表国家进行公诉。\r\n对法律规定由人民检察院直接受理的犯罪案件进行侦查。\r\n法律规定的其他职责。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "《中华人民共和国法律职业资格证书》",
                jobVista: 5,
            },
            {
                name: "公证员",
                content:
                    "向来访人员解答法律服务咨询，提供个性化公证解决方案。\r\n代写法律文书，审查合同、协议。\r\n尽职调查、取证、现场核实证明材料等。\r\n完成公证卷宗自查、整理、归档，确保质量无差错。",
                salaryMedian: 7000,
                educational: "本科",
                qualification:
                    "《中华人民共和国法律职业资格证书》\r\n《公证员执业证书》",
                jobVista: 4,
            },
            {
                name: "对外投资分析师",
                content:
                    "分析国际政治经济的发展形势，为公司的投资战略提供信息支持。\r\n关注国际热点事件，分析其发展方向和对公司可能产生的影响。\r\n协助投资经理完善投资策略。\r\n协作开展与外部专家学者的交流工作。",
                salaryMedian: 12000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "外交领事",
                content:
                    "促进派遣国与接受国关系的发展。\r\n保护本国公民及法人的正当权益。\r\n颁发护照和签证。\r\n担任民事登记员和公证人。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
        ],
    },
    arts: {
        name: "艺术",
        desc:
            "艺术专业学习人类社会和文化产生的创造力的理论及其物理表达。艺术的主要组成部分包括文学，表演艺术（舞蹈、音乐等）和视觉艺术（绘画、绘画、电影制作等）。",
        subs: [
            {
                name: "艺术史论",
                target:
                    "本专业培养具备中外艺术学理论、中外艺术史等方面的基本知识，能够在各级文化部门、美术馆、博物馆，以及报纸杂志、广播电视、出版机构、文化公司等单位承担策划、管理、编辑、评论与文创等工作的高级人才。",
                course:
                    "艺术学原理、艺术文化学、艺术心理学、艺术教育学、艺术传播学、中国艺术学、西方艺术学、中国艺术史、亚洲艺术史、西方艺术史等。",
                abroadVista: 3,
                major_code: 130101,
            },
            {
                name: "音乐表演",
                target:
                    "本专业培养具备音乐表演方面的能力，能在专业文艺团体、艺术院校等相关的部门、机构从事表演、教学及研究工作的高级专门人才。",
                course:
                    "表演、音乐技术理论、音乐史、民族民间音乐、重奏(唱)合奏(唱)等",
                abroadVista: 4,
                major_code: 130201,
            },
            {
                name: "音乐学",
                target:
                    "本专业培养具备一定音乐实践技能和教学能力，能在高、中等专业或普通院校、社会文艺团体、艺术研究单位和文化机关、出版及广播、影视部门从事教学、研究、编辑、评论、管理等方面工作的高级专门人才。",
                course:
                    "音乐史、音乐学理论、中外民族民间音乐、教育学、美学、作曲技术理论、钢琴或其他乐器演奏等",
                abroadVista: 4,
                major_code: 130202,
            },
            {
                name: "舞蹈学",
                target:
                    "本专业培养具备能从事中外舞蹈史、舞蹈理论的研究、舞蹈教学以及编辑等工作的高等专门人才。",
                course:
                    "舞蹈编导、舞蹈基本功训练、现代舞技术、舞蹈素材组合训练、舞蹈剧目分析、舞蹈创作实习、音乐(钢琴、曲式分析)、舞蹈编导理论、现代舞专业基础课程、现代舞表导教系统课程等",
                abroadVista: 3,
                major_code: 130205,
            },
            {
                name: "表演",
                target:
                    "本专业培养具备和掌握表演艺术的基本理论和基本技巧，能够在戏剧、戏曲、电影、电视和舞蹈等表演中独立完成不同人物形象创作的高级专门人才。",
                course:
                    "表演基础理论、表演基本技能、表演剧目、艺术概论、文学修养课程等",
                abroadVista: 3,
                major_code: 130301,
            },
            {
                name: "戏剧学",
                target:
                    "本专业培养具备戏剧和影视的理论、评论、编辑和艺术管理等方面的知识，能在剧院(团)或电视台、电影厂、编辑部以及文化管理机关等部门从事理论研究、编审与文化管理等方面工作，以及能在国家机关、文教事业单位从事实际工作的高级专门人才。",
                course:
                    "导演学、表演艺术、舞台美术设计基础、中外戏剧史、艺术概论等",
                abroadVista: 3,
                major_code: 130302,
            },
            {
                name: "美术学",
                target:
                    "本专业培养具备绘画艺术创作、教学、研究等方面的能力，能在文化艺术领域、教育、设计、研究、出版、管理单位从事教学、创作、研究、出版、管理等方面工作的高级专门人才。",
                course:
                    "中外美术史、美术概论、中外画论概要、古文字学与古代汉语、美术考古学基础、书画鉴定概论、美术与摄影基础等",
                abroadVista: 3,
                major_code: 130401,
            },
            {
                name: "数字媒体艺术",
                target:
                    "本专业培养能满足信息时代社会发展需要，具有良好的技术技能和美学修养，熟练地掌握数字媒体艺术设计和制作的相关软件技术，熟悉数字媒体艺术设计和创作的一般规律，具有较强的艺术鉴赏能力，将信息技术与文化艺术相结合的复合型人才",
                course:
                    "数字信号处理、微机原理与接口技术、计算机网络、数字图像处理、网页设计、多媒体信息处理与传输、流媒体技术、动画原理与网络游戏设计、影视技术基础、摄影与摄像、视频特技与非线性编辑、虚拟现实、影视艺术导论、艺术设计概论、设计美学、画面构图、数字媒体新技术与艺术欣赏等",
                abroadVista: 4,
                major_code: 130508,
            },
        ],
        jobs: [
            {
                name: "美术编辑",
                content:
                    "规划、设计出版物（书籍、报纸、杂志等）的封面、版面、页面。\r\n选用、设计、编排图片。\r\n核对封面、版面和插图并进行装帧。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "平面设计师",
                content:
                    "按照公司品牌管理标准，更新公司整体VI（视觉识别系统）形象及相关物资的平面设计工作，确保公司VI和品牌VI的一致性。\r\n主导完成各类画册手册的设计制作，确保画册手册及时准确交付。\r\n配合其他部门完成市场促销活动、订货会、展会等各类重要活动中的平面设计工作。",
                salaryMedian: 6000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "网页设计师",
                content:
                    "负责独立完成电脑和手机网站前端页面的整体设计。进行需求沟通，确定网页设计的视觉标准和风格标准。\r\n与网站开发人员协同工作，对产品展示界面及交互方式进行美化调整。\r\n负责促销专场活动页面设计。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "乐团指挥",
                content:
                    "阅读、分析总谱，以内心听觉把握作品内涵，形成自己的独特理解。\r\n经过排练，指导并带领乐团全体演奏人员逐步接近对作品的创造性诠释，并达到默契。\r\n统率全体演奏人员将对作品的诠释转化为直观音响。\r\n参加或主办艺术交流活动，扩大乐团的影响力。",
                salaryMedian: 7000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "音乐制作人",
                content:
                    "负责定制歌曲的创作、编曲。\r\n独立完成录音和后期音效编辑工作。\r\n对歌手进行演唱指导。\r\n发掘优秀的录音师及作曲家并与之保持良好的合作关系。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3,
            },
            {
                name: "导演",
                content:
                    "组织创作人员研究和分析剧本，进行艺术表达形式总体构思。\r\n以统一的思想主题指导各部门进行创作。\r\n与剧组负责人或制片人、摄制组确定演员角色人选及艺术生产场所。\r\n按计划组织、指导演员进行排演、拍摄。\r\n配合制片部门对作品进行宣传。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "电影电视演员",
                content:
                    "在导演的指导下，研究、分析所扮演的剧本人物形象。\r\n在摄影棚或实景中，实现表演艺术与影视技术结合。\r\n运用表演技巧，塑造人物。",
                salaryMedian: 7400,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "艺术策展人",
                content:
                    "制定美术馆的年度展陈计划，评估论证展览项目。\r\n与国外博物馆、艺术机构对接，统筹合同签订、布展、撤展等相关问题。\r\n负责巡展市场开发工作，带领团队进行学术调研工作。\r\n统筹展览业务，安排展览档期，调控展览场地。",
                salaryMedian: 10000,
                educational: "硕士",
                qualification: "none",
                jobVista: 4,
            },
        ],
    },
    architecture: {
        name: "建筑学",
        desc: "建筑专业通常学习如何规划，设计和建造建筑物以及其他的结构。",
        subs: [
            {
                name: "建筑学",
                target:
                    "本专业培养具备建筑设计、城市设计、室内设计等方面的知识，能在设计部门从事设计工作，并具有多种职业适应能力的通才型、复合型高级工程技术人才。",
                course:
                    "建筑设计基础、建筑设计及原理、中外建筑历史、建筑结构与建筑力学、建筑构造。",
                abroadVista: 4,
                major_code: "082801",
            },
            {
                name: "城乡规划",
                target:
                    "本专业培养具备城市规划、城市设计等方面的知识，能在城市规划设计、城市规划管理、决策咨询、房地产开发等部门从事城市规划设计与管理、城市道路交通规划、城市市政工程规划、城市生态规划等方面工作的城市规划学科高级工程技术人才。",
                course:
                    "城市规划原理、城市规划设计、城市设计、城市规划理论与城市发展史、城市道路与交通、城市生态与环境保护、城市地理学、城市经济学、区域规划等。",
                abroadVista: 3,
                major_code: "082802",
            },
            {
                name: "风景园林",
                target:
                    "本专业培养基础扎实，知识面宽，专业素质高，实践能力强，具备风景园林规划设计、城市规划与设计、风景名胜区和各类城市绿地的规划设计等方面的知识，能在城市建设、园林等部门从事规划与设计、施工和管理的应用型人才。",
                course:
                    "园林建筑结构与构造学、建筑工程、中国园林史、园林园艺、设计思维学、外国园林史、地质地貌学、资源与环境、艺术与传媒、园林树木栽植养护、风景区规划、插花艺术、专业英语、旅游学等。",
                abroadVista: 3,
                major_code: "082803",
            },
            {
                name: "历史建筑保护工程",
                target:
                    "本专业着重培养以建筑学的基本理论及技能为基础，系统掌握历史建筑和历史环境保护与再生的理论、方法与技术，具有较高建筑学素养和特殊保护技能的专家和未来的专业领导者。",
                course:
                    "建筑初步、美术、历史建筑形制与工艺、建筑设计、建设历史、建筑技术、保护技术、艺术史、文博专题等。",
                abroadVista: 2,
                major_code: "082804T",
            },
            {
                name: "人居环境科学与技术",
                target:
                    "以人居环境科学为基本理念、以服务未来智慧城市发展为目标，培养具备系统思维并接受了大数据与智慧城市、土木工程、建筑环境与能源应用工程、地球环境科学等学科教育，能够在城市基础设施建设岗位上发挥骨干作用的高级人才。",
                course:
                    "人居环境科学导论、城市地理学、城市规划概论、现代城市与住宅设计、城市防灾与减灾、城市经济学等。",
                abroadVista: 4,
                major_code: "082805T",
            },
            {
                name: "城市设计",
                target:
                    "本专业培养适应国家建设需要、适应未来社会发展需求、掌握城市设计学科的基本理论和基本的设计方法，了解各相关学科领域知识，获得初步的实践能力和研究训练的高级人才。",
                course:
                    "城市设计及其理论、建筑及城市历史、城市设计方法、建筑技术科学、建筑设计基础、景观设计原理 、城市发展史、城市社会学原理、城市经济学原理等。",
                abroadVista: 4,
                major_code: "082806T",
            },
            {
                name: "智慧建筑与建造",
                target:
                    "培养具备智慧建筑与建造相关自然科学知识与人文精神素养，掌握智慧建筑与建造基础和前沿理论方法、技术工具，具备开放兼容的知识结构、扎实求精的工程能力的高级人才。",
                course:
                    "建筑原理、建筑历史、建筑技术、计算性设计、人工智能、智慧建造、项目管理等。",
                abroadVista: 4,
                major_code: "082807T",
            },
        ],
        jobs: [
            {
                name: "建筑设计师",
                content:
                    "研究、设计建筑的布局、风格、规模和功能，准备材料、造价和工期资料。\r\n收集工程项目环境、地质、水文等技术资料。\r\n编制建筑工程方案和施工图设计文件。\r\n提供建设过程的设计技术服务。",
                salaryMedian: 11000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "城市规划设计师",
                content:
                    "收集分析城乡规划基础资料。\r\n综合土地利用、空间布局等方面的因素，编制城乡规划阶段性方案及技术文件。\r\n编制城镇体系规划、城市总体规划、乡镇总体规划、村庄规划。\r\n进行城乡规划理论研究和应用技术开发。",
                salaryMedian: 8500,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "风景园林设计师",
                content:
                    "编制风景名胜区、城市景观等规划方案。\r\n编制城市园林绿地系统、公园体系、生物多样性保护规划。\r\n设计公园、绿地、景区景观。\r\n负责风景园林工程施工管理和养护管理。",
                salaryMedian: 8200,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
            {
                name: "历史建筑保护规划设计师",
                content:
                    "从事历史街区设计、沿街建筑改造、文物保护规划，古建筑维修保护等工作。\r\n负责古建筑测绘及修缮方案编制。\r\n负责编制文物保护规划文本、编制设计方案、绘制施工图等工作。",
                salaryMedian: 8000,
                educational: "本科",
                qualification: "none",
                jobVista: 3.5,
            },
            {
                name: "智慧建筑研发工程师",
                content:
                    "参与智慧建筑解决方案及标准的研究。\r\n根据项目需求，负责智慧建筑软件开发，包括智慧办公/会议、智慧出入（人脸识别）、安防工程等模块。\r\n协调代码开发、UI、测试、运营团队，紧密合作达成产品目标。\r\n分析产品运营数据，及时提出改进方案。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 5,
            },
            {
                name: "绿色建筑咨询工程师",
                content:
                    "依据中国绿色星级标准或国外标准，提供绿色建筑设计咨询和评估认证服务。\r\n对建筑进行风、光、热、声等方面的物理环境模拟分析，提供设计建议。\r\n参与绿色建筑相关课题研究，撰写研究报告。",
                salaryMedian: 10000,
                educational: "本科",
                qualification: "none",
                jobVista: 4,
            },
        ],
    },
};
export default data;
