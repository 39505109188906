import React, { Component } from "react";
import i18n from "../../lib/i18n/i18n";
import { Button, Form, Modal, Message } from "semantic-ui-react";
import { Radio } from "antd";
import { RippleButton, Input } from "../";
import { RegEx } from "../../data/model";
import "../../scss/Modal.scss";
const areaCodes = ["+86"];

class NewStudent extends Component {
    state = {
        name: "",
        areaCode: "",
        phone: "",
        kind: window.localStorage.getItem("studentKind") || "VS",
        nameError: "",
        phoneError: "",
    };
    componentDidMount() {
        this.setState({ areaCode: areaCodes[0] });
    }
    setName({ target }) {
        this.setState({ name: target.value });
    }
    setAreaCode({ target }) {
        this.setState({ areaCode: target.value });
    }
    setPhone({ target }) {
        const phone = target.value.replace(/\D/gi, "");
        this.setState({ phone });
    }
    validateForm({ name, areaCode, phone }, cb) {
        const validatePhoneNumber = (area, number) => {
            const numberRegExps = RegEx.phone[area];
            let isValid = false;

            if (numberRegExps) {
                for (let i = 0; i < numberRegExps.length; i += 1) {
                    if (numberRegExps[i].test(number)) {
                        isValid = true;
                    }
                }
            }

            if (number === "" || !isValid) {
                return i18n.t("ERROR.INVALID_PHONE");
            }
            return "";
        };

        const nameError = name === "" ? i18n.t("ERROR.NAME_EMPTY") : "";
        const phoneError = validatePhoneNumber(areaCode, phone);
        this.setState({ nameError, phoneError }, cb);
    }
    create() {
        this.validateForm(this.state, () => {
            const { nameError, phoneError } = this.state;
            if (nameError === "" && phoneError === "") {
                this.props.create(
                    this.state.name,
                    this.state.areaCode,
                    this.state.phone,
                    this.state.kind
                );
            }
        });
    }
    render() {
        const canSubmit = this.state.name && this.state.phone;
        return (
            <Modal
                className="NewStudent"
                dimmer="blurring"
                open={true}
                onClose={this.props.close}
            >
                <Modal.Header>{i18n.t("STUDENTS.CREATE_PROMPT")}</Modal.Header>
                <Modal.Content>
                    {(this.state.nameError || this.state.phoneError) && (
                        <Message
                            error
                            list={[
                                this.state.nameError,
                                this.state.phoneError,
                            ].filter((val) => val !== "")}
                        />
                    )}
                    <Form onSubmit={this.create.bind(this)}>
                        <Input
                            label={i18n.t("APP.FULL_NAME")}
                            value={this.state.name}
                            onChange={this.setName.bind(this)}
                            error={!!this.state.nameError}
                            focused
                        />
                        {this.state.name && (
                            <Form.Group>
                                <Form.Field
                                    control="select"
                                    label={i18n.t("APP.AREA_CODE")}
                                    value={this.state.areaCode}
                                    onChange={this.setAreaCode.bind(this)}
                                >
                                    {areaCodes.map((code) => (
                                        <option value={code} key={code}>
                                            {code}
                                        </option>
                                    ))}
                                </Form.Field>
                                <Input
                                    label={i18n.t("APP.PHONE")}
                                    value={this.state.phone}
                                    onChange={this.setPhone.bind(this)}
                                    error={!!this.state.phoneError}
                                />
                            </Form.Group>
                        )}

                        {this.state.name && (
                            <Form.Group>
                                <Radio.Group
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        this.setState({ kind: value });
                                        window.localStorage.setItem(
                                            "studentKind",
                                            value
                                        );
                                    }}
                                    value={this.state.kind}
                                    style={{ marginLeft: 8 }}
                                >
                                    <Radio value="VS">留学</Radio>
                                    <Radio value="GK">高考</Radio>
                                </Radio.Group>
                            </Form.Group>
                        )}

                        <button type="submit" style={{ display: "none" }} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <RippleButton
                        empty
                        flat
                        text={i18n.t("APP.CANCEL")}
                        onClick={this.props.close}
                    />
                    <Button
                        positive
                        disabled={!canSubmit}
                        content={i18n.t("APP.CREATE")}
                        onClick={this.create.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default NewStudent;
