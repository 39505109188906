import React from "react";
import _ from "lodash";
import SubSection from "../SubSection";
import CompetenceOverview from "./CompetenceOverview";
import CompetenceDetail from "./CompetenceDetail";

const PartCompetence = ({ reportData, partOrder, part }) => {
    const competenceData = reportData.competence;
    const competenceTraitsData = reportData.competence_traits;

    const detailsPages = [];
    _.each(competenceData, function (score, key) {
        if (key === "global_vision") {
            detailsPages.push({
                level1: {
                    key,
                    score,
                },
                level2: {
                    gv_openness: competenceTraitsData["gv_openness"],
                    gv_intercaltural_communication:
                        competenceTraitsData["gv_intercaltural_communication"],
                    gv_multiculturalism:
                        competenceTraitsData["gv_multiculturalism"],
                },
            });
        }

        if (key === "thinking_and_academic_ability") {
            detailsPages.push({
                level1: {
                    key,
                    score,
                },
                level2: {
                    taa_academic_abilities:
                        competenceTraitsData["taa_academic_abilities"],
                    taa_critical_thinking:
                        competenceTraitsData["taa_critical_thinking"],
                    taa_curiosity: competenceTraitsData["taa_curiosity"],
                },
            });
            detailsPages.push({
                level2: {
                    taa_creativity: competenceTraitsData["taa_creativity"],
                },
            });
        }

        if (key === "social_skills") {
            detailsPages.push({
                level1: {
                    key,
                    score,
                },
                level2: {
                    ss_public_speaking:
                        competenceTraitsData["ss_public_speaking"],
                    ss_problem_solving:
                        competenceTraitsData["ss_problem_solving"],
                    ss_teamwork: competenceTraitsData["ss_teamwork"],
                },
            });
            detailsPages.push({
                level2: {
                    ss_leadership: competenceTraitsData["ss_leadership"],
                },
            });
        }

        if (key === "values") {
            detailsPages.push({
                level1: {
                    key,
                    score,
                },
                level2: {
                    values_agreeableness_conscientiousness:
                        competenceTraitsData[
                            "values_agreeableness_conscientiousness"
                        ],
                },
            });
        }
    });

    return (
        <React.Fragment>
            <SubSection
                title={part.title}
                partKey={part.key}
                sectionNo={partOrder}
            />
            <CompetenceOverview
                traitsData={reportData.competence_traits}
                traitsThreshold={{
                    lowerScoreThreshold: reportData.traitThreshold?.[0],
                    upperScoreThreshold: reportData.traitThreshold?.[1],
                }}
                title={part.title}
            />
            {_.map(detailsPages, function (pageData, index) {
                return (
                    <CompetenceDetail
                        key={index}
                        title={part.title}
                        traitsThreshold={{
                            lowerScoreThreshold: reportData.traitThreshold?.[0],
                            upperScoreThreshold: reportData.traitThreshold?.[1],
                        }}
                        pageData={pageData}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default PartCompetence;
