import React, { Component } from 'react'
import { Segment, Popup } from 'semantic-ui-react'

/**
 * @class Card
 * 
 * Widget wrapper for all standard widgets. 
 * Defined styling and base actions for all widgets. 
 * 
 * @prop {String}   type     widget type, added as a className
 * @prop {String}   title    widget title
 * @prop {String}   subtitle widget subtitle
 * @prop {String}   help     text to display when clicking the 'help' icon
 * @prop {Map}      style    custom widget wrapper styling
 * @prop {Function} more     helper to take the user to more detailed content
 */
class Card extends Component {
    render() {
        const { 
            className, type, more, style, 
            title, subtitle, help, children, onClick
        } = this.props;
        var cardClass = "Widget Card";
        if (className) {
            cardClass += (" " + className)
        }
        if(type) {
            cardClass += (" " + type);
        }
        if (more){
            cardClass += " clickable";
        }
        return (
            <Segment className={cardClass}
                style={style || {}}
                onClick={more || onClick || function(){}}
            >
                { title &&
                 <h1>{title}</h1>
                }
                { subtitle &&
                 <h2>{subtitle}</h2>
                }
                { help &&
                    <Popup
                        trigger={
                            <div className='icon help'>K</div>
                        }
                        content={help}
                        on='click'
                        basic
                        position='bottom right'
                    />
                }
                { more &&
                    <div className='icon more'>"</div>
                }
                {children}
            </Segment>
        )
    }
}

export default Card;
