import React from "react";
import Tab from "./Tab";

const tableConfigs = [
    {
        key: "startquarter",
        firstColumnText: "入学季度",
        path: "baseinfo/startquarter",
        totalText: "学生合同总数",
    },
    {
        key: "toeflscore",
        firstColumnText: "托福分数情况",
        path: "baseinfo/toeflscore",
    },
    {
        key: "satscore",
        firstColumnText: "SAT分数情况",
        path: "baseinfo/satscore",
    },
];
function SummaryTab() {
    return <Tab tableConfigs={tableConfigs} />;
}

export default SummaryTab;
