import React, { Component } from 'react'
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts'

const hStyle = {
    textAlign: 'center',
    marginBottom: '0.5rem'
}
/**
 * @class RadialPercent
 * 
 * A standardized radial bar chart used to express statistics as a percentage.
 * 
 * @prop {String} [header]  optional chart title
 * @prop {float} value      chart value [0,1]
 * @prop {float} r          chart radius
 * @prop {String} color     chart color [default='#00BFD6']
 */
class RadialPercent extends Component {
    defaultColor='#00BFD6'
    render() {
        const { header, value, r, color, labelColor, fontSize } = this.props;
        const chartColor = color || this.defaultColor;
        const fontPx = fontSize || Math.round(r/2);
        const dy = Math.round(fontPx * 0.5) + r + 4;
        const outerStyle = {
            textAlign: 'center',
            paddingBottom: dy - fontPx + 'px'
        };
        const pStyle = {
            fontWeight: 900,
            fontSize: fontPx + 'px',
            lineHeight: '1em',
            width: '100%',
            color: labelColor || chartColor,
            marginTop: -dy + 'px'
        };
        return (
            <div className="RadialPercent" style={outerStyle}>
                <h3 style={hStyle}>{header}</h3>
                <RadialBarChart 
                    width={r * 2} 
                    height={r * 2} 
                    cx={r} 
                    cy={r} 
                    innerRadius={r * 0.8} 
                    outerRadius={r} 
                    startAngle={450}
                    endAngle={90}
                    data={[{ 
                        name: 'progress', 
                        value: value, 
                        fill: chartColor 
                    }]}
                >
                    <PolarAngleAxis 
                        type="number"
                        domain={[0, 1]} 
                        angleAxisId={0}
                        tick={false}
                    />
                    <RadialBar 
                        angleAxisId={0}
                        animationDuration={200}
                        background 
                        dataKey="value" 
                    />
                </RadialBarChart>
                <div style={pStyle}>{Math.round(value * 100) + "%"}</div>
            </div>
        )
    }
}

export default RadialPercent;
