export default {
    /**
     * Convert a color hex code string into an rgb array
     * 
     * @param {String} hex
     * @returns [r,g,b] array
     */
    hex2rgb(hex){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    },
    /**
     * Convert an rgb array into a hex string
     * 
     * @param {Array} rgb [r,g,b] array
     * @returns hex string
     */
    rgb2hex(rgb) {
        return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
    },
    /**
     * Convert rgb to hsl (hue, saturation, lightness)
     * 
     * @param {Array} color [r,g,b] array
     * @returns [h,s,l] array
     */
    rgb2hsl(color) {
        var r = color[0]/255;
        var g = color[1]/255;
        var b = color[2]/255;
      
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;
      
        if (max === min) {
          h = s = 0; // achromatic
        } else {
          var d = max - min;
          s = (l > 0.5 ? d / (2 - max - min) : d / (max + min));
          switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: return;
          }
          h /= 6;
        }
      
        return [h, s, l];
    },
    /**
     * Convert hsl to rgb
     * 
     * @param {Array} color [h,s,l] array
     * @returns [r,g,b] array
     */
    hsl2rgb(color) {
        var l = color[2];
      
        if (color[1] === 0) {
          l = Math.round(l*255);
          return [l, l, l];
        } else {
          function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1/6) return p + (q - p) * 6 * t;
            if (t < 1/2) return q;
            if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
          }
      
          var s = color[1];
          var q = (l < 0.5 ? l * (1 + s) : l + s - l * s);
          var p = 2 * l - q;
          var r = hue2rgb(p, q, color[0] + 1/3);
          var g = hue2rgb(p, q, color[0]);
          var b = hue2rgb(p, q, color[0] - 1/3);
          return [Math.round(r*255), Math.round(g*255), Math.round(b*255)];
        }
    },
    /**
     * Mixes two hex colors and returns a hex string of the result. 
     * 
     * The mixture is equivalent to setting color1's opacity to factor and 
     * color2's opacity to (1-factor).
     * Taken from the awesome ROT.js roguelike dev library at 
     * https://github.com/ondras/rot.js
     * 
     * @param {String} color1 hex string for starting color
     * @param {String} color2 hex string for ending color
     * @param {int} factor mixing factor
     * @returns hex string that is {factor} between color1 and color2
     */
    mix(color1, color2, factor) {
        color1 = this.hex2rgb(color1);
        color2 = this.hex2rgb(color2);

        if (arguments.length < 3) { 
            factor = 0.5; 
        }
        var result = color1.slice();
        for (var i=0;i<3;i++) {
          result[i] = Math.round(result[i] + factor*(color2[i]-color1[i]));
        }
        return this.rgb2hex(result);
    },
    /**
     * Interpolates two hex colors and returns a hex string of the result. 
     * 
     * The mixture is equivalent to moving {factor} between color1 and color2 
     * on the color wheel.
     * Taken from the awesome ROT.js roguelike dev library at 
     * https://github.com/ondras/rot.js
     * 
     * @param {String} color1 hex string for starting color
     * @param {String} color2 hex string for ending color
     * @param {int} factor interpolation factor
     * @returns hex string that is {factor} between color1 and color2
     */
    interpolate(color1, color2, factor) {
        if (arguments.length < 3) { 
            factor = 0.5; 
        }
        var hsl1 = this.rgb2hsl(this.hex2rgb(color1));
        var hsl2 = this.rgb2hsl(this.hex2rgb(color2));

        for (var i=0;i<3;i++) {
          hsl1[i] += factor*(hsl2[i]-hsl1[i]);
        }
        return this.rgb2hex(this.hsl2rgb(hsl1));
      }
}