import React, { Component } from "react";
import {
    Button as AntdButton,
    Col,
    Row,
    Menu,
    Card,
    Select,
    Upload,
    message,
    Modal,
    Tag,
    Popconfirm,
} from "antd";
import { Request } from "lib";
import { UploadOutlined } from "@ant-design/icons";
import DataDict from "./DataDict";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { RippleButton } from "components";
import { i18n } from "lib";
import Router from "lib/Router";
import _ from "lodash";
import styled from "styled-components";
import cn from "classnames";

/**
 * props结构说明
 * props{
 *  data{
 *      choosestatus:[选校状态]
 *      applyStatus:[申请状态]
 *      name:[学生姓名]
 *      schools:[][选校]
 *  }
 *  visible:[是否显示],
 *  fetchData:function[更新状态后刷新数据]
 * }
 */
const { Option } = Select;

const allowSubmit = ["已录取", "拒绝(Denied)"];

class Enroll extends Component {
    state = {
        statusChange: null,
        visible: false,
        data: [],
        fileList: [],
        update: true,
        ok: true,
        isSuperAdmin: false,
    };

    uploadProps = (data, attr) => {
        let fileList = [];
        if (data["trackadmission"] && data["trackadmission"][attr]) {
            fileList.push({
                uid: data["trackadmission"][attr]["_id"],
                name: data["trackadmission"][attr]["filename"],
                status: "done",
            });
        }
        return {
            name: "file",
            withCredentials: true,
            action: Router.baseUrl + "upload",
            headers: {
                authorization: "authorization-text",
            },
            data: {
                id: data["_id"],
                model: "AcademyInfo",
                attr: "trackadmission." + attr,
            },
            fileList: this.state.fileListMap[data["_id"]][attr],
            beforeUpload: (file, fileList) => {
                if (file.size > 10 * 1024 * 1024) {
                    message.error("文件大小不能大于10MB");
                    return false;
                }
                if (file.name.indexOf(".") < 0) {
                    message.error("文件必须要后缀名");
                    return false;
                }
                var filed = file;
                this.setState((state) => {
                    state.fileListMap[data["_id"]][attr] = [
                        {
                            name: filed.name,
                            uid: data["_id"],
                            status: "uploading",
                        },
                    ];
                    return state;
                });
                return true;
            },
            onSuccess: (file) => {
                this.init();
                this.setState((state) => {
                    state.fileListMap[data["_id"]][attr] = [
                        { name: file.name, uid: data["_id"], status: "done" },
                    ];
                    return state;
                });
            },
            onError: (err, res) => {
                if (err.status === 401) {
                    Router.goTo("/login", {
                        loaded: true,
                        authenticated: false,
                    });
                }
            },
        };
    };
    getFiled = (data, keys) => {
        try {
            _.each(keys, (v) => {
                data = data[v];
            });
            return data;
        } catch (e) {
            return null;
        }
    };

    UNSAFE_componentWillMount = () => {
        this.init();
    };
    // constructor(props) {
    //     super(props);
    // }

    UNSAFE_componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ visible: this.props.visible });
        this.fetchData();
    };
    handleStatusClick = (value) => {
        console.log("handleStatusClick", value);
        this.setState({ statusChange: value.key });
    };
    status = (
        <Menu onClick={this.handleStatusClick}>
            <Menu.Item key="信息收集">信息收集</Menu.Item>
            <Menu.Item key="选校">选校</Menu.Item>
            <Menu.Item key="申请准备">申请准备</Menu.Item>
            <Menu.Item key="申请跟踪">申请跟踪</Menu.Item>
            <Menu.Item key="入读规划">入读规划</Menu.Item>
        </Menu>
    );

    deleteFile = (data, attr) => {
        if (this.getFiled(data, ["trackadmission", attr, "_id"])) {
            Request.POST("delete", {
                data: {
                    id: data["_id"],
                    model: "AcademyInfo",
                    attr: "trackadmission." + attr,
                    fileId:
                        data["trackadmission"] && data["trackadmission"][attr]
                            ? data["trackadmission"][attr]["_id"]
                            : null,
                    key:
                        data["trackadmission"] && data["trackadmission"][attr]
                            ? data["trackadmission"][attr]["path"]
                            : null,
                },
                onSuccess: (data) => {
                    if (data.msg) {
                        message.warn(data.msg);
                    }
                    this.init();
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    this.setState({
                        loading: false,
                        error: true,
                        errorCode: "WRONG",
                    });
                },
            });
        }
    };

    checkFile = (data, attr, success, fail) => {
        if (this.getFiled(data, ["trackadmission", attr, "_id"])) {
            Request.POST("checkFile", {
                data: {
                    fileId:
                        data["trackadmission"] && data["trackadmission"][attr]
                            ? data["trackadmission"][attr]["_id"]
                            : null,
                },
                onSuccess: (data) => {
                    success();
                },
                onFail: (status) => {
                    fail();
                },
            });
        }
    };

    onOk = (academyinfoId) => {
        this.setState({ ok: false });
        Request.GET("sync/admission/" + academyinfoId, {
            onSuccess: (data) => {
                message.info("同步成功");
                this.init();
                this.setState({ ok: true });
            },
            onFail: (status, err) => {
                if (status === 401) return true;
                this.setState({ ok: true });
                if (status === 400) {
                    message.error(i18n.t("ERROR." + err));
                } else {
                    message.error("同步错误");
                }
            },
        });
    };
    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.setState({ visible: false });
        }
    };

    init = () => {
        this.fetchData();
    };

    buildFilter = () => {
        console.log("buildFilter......");
        return {
            studentId: this.props.studentId,
        };
    };
    fetchData = () => {
        Request.GET("academyinfo/" + this.props.contractId, {
            onSuccess: (data) => {
                this.renderDataSource(data);
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("数据错误");
            },
        });

        Request.GET("advisor/isadmin", {
            onSuccess: (response) => {
                this.setState({
                    isSuperAdmin: response.isAdmin === 3,
                });
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("数据错误");
            },
        });
    };

    showTip = (i)=>{
        if(
            !_.isEqual(
                "1",
                this.getFiled(this.state.data[i], [
                    "submitProgress",
                ])
            )
        ){
            return <><span color="red">请在“院校提交”表里将“院校提交进度”更新为“已提交”后才能提交录取结果</span> <Button
            positive
            disabled={true}
            content={i18n.t("APP.SUBMIT")}
            style={{
                width: "55px",
                height: "25px",
                fontSize: "13px",
                lineHeight: "9px",
            }}
            onClick={() => {
                this.onOk(
                    this.state.data[i]["_id"]
                );
            }}
        /></>;
        }
        if(!this.getFiled(this.state.data[i], [
            "trackadmission",
            "result",
        ]) ||
        allowSubmit.indexOf(
            DataDict.getMap(
                "erp:trackadmission:result"
            )[
                this.getFiled(this.state.data[i], [
                    "trackadmission",
                    "result",
                ])
            ]
        ) < 0 ){
            return <><span color="red">只有“已录取”和“拒绝”结果可以提交到ERP</span> <Button
            positive
            disabled={true}
            content={i18n.t("APP.SUBMIT")}
            style={{
                width: "55px",
                height: "25px",
                fontSize: "13px",
                lineHeight: "9px",
            }}
            onClick={() => {
                this.onOk(
                    this.state.data[i]["_id"]
                );
            }}
        /></>;
        }
    };

    renderDataSource = (datasource) => {
        let ds = datasource.data;
        let fileListMap = {};
        for (let i = 0; i < ds.length; i++) {
            ds[i]["key"] = ds[i]["_id"];
            ds[i]["webSubmitDate"] = moment(ds[i]["webSubmitDate"]).format(
                "YYYY-MM-DD"
            );
            ds[i]["overdate"] = moment(ds[i]["overdate"]).format("YYYY-MM-DD");
            ds[i]["postDate"] = moment(ds[i]["postDate"]).format("YYYY-MM-DD");
            ds[i]["postDate2"] = moment(ds[i]["postDate2"]).format(
                "YYYY-MM-DD"
            );
            ds[i]["schoolId"] = _.isEmpty(ds[i]["schoolId"])
                ? undefined
                : // : DataDict.get(`erp:school:en:${ds[i]["schoolId"]}`);
                  ds[i]["schoolNameEn"];
            let fileList1 = [];
            let fileList2 = [];
            let fileList3 = [];
            if (
                ds[i]["trackadmission"] &&
                ds[i]["trackadmission"]["offerLetter"]
            ) {
                fileList1.push({
                    uid: ds[i]["trackadmission"]["offerLetter"]["_id"],
                    name: ds[i]["trackadmission"]["offerLetter"]["filename"],
                    status: "done",
                });
            }
            if (
                ds[i]["trackadmission"] &&
                ds[i]["trackadmission"]["applyForm"]
            ) {
                fileList2.push({
                    uid: ds[i]["trackadmission"]["applyForm"]["_id"],
                    name: ds[i]["trackadmission"]["applyForm"]["filename"],
                    status: "done",
                });
            }
            if (
                ds[i]["trackadmission"] &&
                ds[i]["trackadmission"]["misc"]
            ) {
                fileList3.push({
                    uid: ds[i]["trackadmission"]["misc"]["_id"],
                    name: ds[i]["trackadmission"]["misc"]["filename"],
                    status: "done",
                });
            }
            fileListMap[ds[i]["_id"]] = {
                offerLetter: fileList1,
                applyForm: fileList2,
                misc:fileList3,
            };
        }
        this.setState({ data: ds, fileListMap: fileListMap });
    };

    render() {
        let modal = [];
        for (let i = 0; i < this.state.data.length; i++) {
            const rowData = this.state.data[i];

            let enrollResult = this.getFiled(this.state.data[i], [
                "trackadmission",
                "result",
            ]);

            modal.push(
                <Row
                    style={{ marginTop: "10px" }}
                    key={i}
                    className="upload-modal"
                >
                    <Card style={{ width: 900 }}>
                        <Row>
                            <Col
                                style={{
                                    lineHeight: "30px",
                                    fontSize: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {this.state.data[i]["schoolId"]} -{" "}
                                {this.state.data[i]["majorNameCH"]}
                                {this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "isSync",
                                ]) ? (
                                    <Tag
                                        style={{
                                            marginLeft: "10px",
                                            width: "53px",
                                            height: "23px",
                                        }}
                                        color="green"
                                    >
                                        已上报
                                    </Tag>
                                ) : (
                                    <Tag
                                        style={{
                                            marginLeft: "10px",
                                            width: "53px",
                                            height: "23px",
                                        }}
                                        color="blue"
                                    >
                                        待上报
                                    </Tag>
                                )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={4} style={{ lineHeight: "35px" }}>
                                <span>录取结果：</span>
                            </Col>
                            <Col span={13}>
                                <Select
                                    defaultValue={enrollResult}
                                    value={enrollResult}
                                    style={{ width: 190 }}
                                    onChange={(e) => {
                                        Request.POST("academyinfo", {
                                            data: {
                                                update: {
                                                    "trackadmission.result": e,
                                                },
                                                id: this.state.data[i]["key"],
                                            },
                                            onSuccess: (data) => {
                                                this.init();
                                            },
                                            onFail: (status) => {
                                                if (status === 401) return true;
                                                message.error("修改错误");
                                            },
                                        });
                                    }}
                                    disabled={this.getFiled(
                                        this.state.data[i],
                                        ["trackadmission", "isSync"]
                                    )}
                                >
                                    {(() => {
                                        let arr = [];
                                        _.map(
                                            DataDict.getMap(
                                                "erp:trackadmission:result"
                                            ),
                                            (v, k) => {
                                                arr.push(
                                                    <Option key={k}>{v}</Option>
                                                );
                                            }
                                        );
                                        return arr;
                                    })()}
                                </Select>
                            </Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row
                            style={{ marginTop: "10px" }}
                            className={cn({
                                "after-enroll-result": _.isUndefined(
                                    enrollResult
                                ),
                            })}
                        >
                            <Col span={4}>
                                <span>录取信:</span>
                            </Col>
                            <Col
                                span={15}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    padding: "2px 5px",
                                }}
                            >
                                <Upload
                                    {...this.uploadProps(
                                        this.state.data[i],
                                        "offerLetter"
                                    )}
                                >
                                    {!this.getFiled(this.state.data[i], [
                                        "trackadmission",
                                        "isSync",
                                    ]) ? (
                                        <Button
                                            style={{
                                                fontSize: "13px",
                                                padding: "3px",
                                            }}
                                        >
                                            <UploadOutlined />
                                            {this.getFiled(this.state.data[i], [
                                                "trackadmission",
                                                "offerLetter",
                                                "_id",
                                            ])
                                                ? "重新上传"
                                                : "上传"}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Upload>
                            </Col>
                            <Col
                                span={5}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "offerLetter",
                                    "_id",
                                ]) ? (
                                    <>
                                        <AntdButton
                                            type="link"
                                            onClick={() => {
                                                this.checkFile(
                                                    this.state.data[i],
                                                    "offerLetter",
                                                    () => {
                                                        window.location.href =
                                                            Router.baseUrl +
                                                            "excel/download/" +
                                                            this.getFiled(
                                                                this.state.data[
                                                                    i
                                                                ],
                                                                [
                                                                    "trackadmission",
                                                                    "offerLetter",
                                                                    "_id",
                                                                ]
                                                            );
                                                    },
                                                    () => {
                                                        message.warn(
                                                            "文件已删除"
                                                        );
                                                        this.init();
                                                    }
                                                );
                                            }}
                                        >
                                            下载
                                        </AntdButton>
                                        {"  "}
                                        {!this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "isSync",
                                        ]) ? (
                                            <AntdButton
                                                type="link"
                                                onClick={() => {
                                                    this.deleteFile(
                                                        this.state.data[i],
                                                        "offerLetter"
                                                    );
                                                }}
                                            >
                                                删除
                                            </AntdButton>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                        <Row
                            style={{ marginTop: "10px" }}
                            className={cn({
                                "after-enroll-result": _.isUndefined(
                                    enrollResult
                                ),
                            })}
                        >
                            <Col span={4}>
                                <span>申请表格:</span>
                            </Col>
                            <Col
                                span={15}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    padding: "2px 5px",
                                }}
                            >
                                <Upload
                                    {...this.uploadProps(
                                        this.state.data[i],
                                        "applyForm"
                                    )}
                                >
                                    {!this.getFiled(this.state.data[i], [
                                        "trackadmission",
                                        "isSync",
                                    ]) ? (
                                        <Button
                                            style={{
                                                fontSize: "13px",
                                                padding: "3px",
                                            }}
                                        >
                                            <UploadOutlined />
                                            {this.getFiled(this.state.data[i], [
                                                "trackadmission",
                                                "applyForm",
                                                "_id",
                                            ])
                                                ? "重新上传"
                                                : "上传"}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Upload>
                            </Col>
                            <Col
                                span={5}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "applyForm",
                                    "_id",
                                ]) ? (
                                    <>
                                        <AntdButton
                                            type="link"
                                            onClick={() => {
                                                this.checkFile(
                                                    this.state.data[i],
                                                    "applyForm",
                                                    () => {
                                                        window.location.href =
                                                            Router.baseUrl +
                                                            "excel/download/" +
                                                            this.getFiled(
                                                                this.state.data[
                                                                    i
                                                                ],
                                                                [
                                                                    "trackadmission",
                                                                    "applyForm",
                                                                    "_id",
                                                                ]
                                                            );
                                                    },
                                                    () => {
                                                        message.warn(
                                                            "文件已删除"
                                                        );
                                                        this.init();
                                                    }
                                                );
                                            }}
                                        >
                                            下载
                                        </AntdButton>
                                        {"  "}
                                        {!this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "isSync",
                                        ]) ? (
                                            <AntdButton
                                                type="link"
                                                onClick={() => {
                                                    this.deleteFile(
                                                        this.state.data[i],
                                                        "applyForm"
                                                    );
                                                }}
                                            >
                                                删除
                                            </AntdButton>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                        <Row
                            style={{ marginTop: "10px" }}
                            className={cn({
                                "after-enroll-result": _.isUndefined(
                                    enrollResult
                                ),
                            })}
                        >
                            <Col span={4}>
                                <span>其他:</span>
                            </Col>
                            <Col
                                span={15}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    padding: "2px 5px",
                                }}
                            >
                                <Upload
                                    {...this.uploadProps(
                                        this.state.data[i],
                                        "misc"
                                    )}
                                >
                                {!this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "isSync",
                                ]) ? (
                                    <Button
                                        style={{
                                            fontSize: "13px",
                                            padding: "3px",
                                        }}
                                    >
                                        <UploadOutlined />
                                        {this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "misc",
                                            "_id",
                                        ])
                                            ? "重新上传"
                                            : "上传"}
                                    </Button>
                                ): (
                                    <></>
                                )}
                                </Upload>
                            </Col>
                            <Col
                                span={5}
                                style={{
                                    border: "solid 1px #D9D9DE",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "misc",
                                    "_id",
                                ]) ? (
                                    <>
                                        <AntdButton
                                            type="link"
                                            onClick={() => {
                                                this.checkFile(
                                                    this.state.data[i],
                                                    "misc",
                                                    () => {
                                                        window.location.href =
                                                            Router.baseUrl +
                                                            "excel/download/" +
                                                            this.getFiled(
                                                                this.state.data[
                                                                    i
                                                                ],
                                                                [
                                                                    "trackadmission",
                                                                    "misc",
                                                                    "_id",
                                                                ]
                                                            );
                                                    },
                                                    () => {
                                                        message.warn(
                                                            "文件已删除"
                                                        );
                                                        this.init();
                                                    }
                                                );
                                            }}
                                        >
                                            下载
                                        </AntdButton>
                                        {"  "}
                                        {!this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "isSync",
                                        ]) ? (
                                            <AntdButton
                                                type="link"
                                                onClick={() => {
                                                    this.deleteFile(
                                                        this.state.data[i],
                                                        "misc"
                                                    );
                                                }}
                                            >
                                                删除
                                            </AntdButton>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                        <Row
                            style={{ marginTop: "10px" }}
                            className={cn({
                                "after-enroll-result": _.isUndefined(
                                    enrollResult
                                ),
                            })}
                        >
                            <Col span={2}></Col>
                            <Col
                                span={22}
                                style={{
                                    lineHeight: "40px",
                                    marginRight: "0px",
                                    textAlign:"right"
                                }}
                            >
                                {this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "result",
                                ]) &&
                                allowSubmit.indexOf(
                                    DataDict.getMap(
                                        "erp:trackadmission:result"
                                    )[
                                        this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "result",
                                        ])
                                    ]
                                ) >= 0 &&
                                !this.getFiled(this.state.data[i], [
                                    "trackadmission",
                                    "isSync",
                                ]) &&
                                _.isEqual(
                                    "1",
                                    this.getFiled(this.state.data[i], [
                                        "submitProgress",
                                    ])
                                ) ? (
                                    <Button
                                        positive
                                        disabled={!this.state.ok}
                                        content={i18n.t("APP.SUBMIT")}
                                        style={{
                                            width: "55px",
                                            height: "25px",
                                            fontSize: "13px",
                                            lineHeight: "9px",
                                        }}
                                        onClick={() => {
                                            this.onOk(
                                                this.state.data[i]["_id"]
                                            );
                                        }}
                                    />
                                    ) : <>{this.showTip(i)}</>}
                            </Col>
                        </Row>
                        <Row
                            style={{ marginTop: "10px" }}
                            className={cn([
                                "reset-action-row",
                                {
                                    "is-sync-result":
                                        this.getFiled(this.state.data[i], [
                                            "trackadmission",
                                            "isSync",
                                        ]) && this.state.isSuperAdmin,
                                },
                            ])}
                        >
                            <Col
                                span={4}
                                style={{
                                    lineHeight: "40px",
                                    marginRight: "0px",
                                }}
                            >
                                <Popconfirm
                                    title="注意: 请确认该所学校录取信息需要重新上传，不当操作可能导致数据与ERP无法同步。"
                                    cancelText="取消"
                                    okText="确认重置"
                                    onConfirm={() => {
                                        Request.POST("cancelAdmission", {
                                            data: {
                                                academyId: rowData?._id,
                                            },
                                            onSuccess: (data) => {
                                                message.info("重置成功");
                                                this.init();
                                            },
                                            onFail: (status, err) => {
                                                if (status === 401) return true;
                                                if (status === 400) {
                                                    message.error(
                                                        i18n.t("ERROR." + err)
                                                    );
                                                } else {
                                                    message.error("重置失败");
                                                }
                                            },
                                        });
                                    }}
                                >
                                    <Button
                                        positive
                                        content="重置上报状态"
                                        style={{
                                            height: "25px",
                                            fontSize: "13px",
                                            lineHeight: "9px",
                                            backgroundColor: "#ff4d4f",
                                        }}
                                        onClick={() => {
                                            // do nothing
                                        }}
                                    />
                                </Popconfirm>
                            </Col>
                            <Col span={20}></Col>
                        </Row>
                    </Card>
                </Row>
            );
        }

        return (
            <>
                {this.props.render ? (
                    <AntdButton
                        type="link"
                        style={{
                            padding: "0px",
                            margin: "0px",
                            height: "20px",
                            ...this.props.style,
                        }}
                        onClick={() => {
                            this.setState({ visible: true });
                        }}
                    >
                        录取管理
                    </AntdButton>
                ) : null}

                <Modal
                    visible={this.state.visible}
                    title={"录取管理: " + this.props.studentName}
                    centered={true}
                    onCancel={this.onCancel}
                    width="750px"
                    footer={[
                        <RippleButton
                            key="close"
                            empty
                            flat
                            text="关闭"
                            onClick={this.onCancel}
                        />,
                    ]}
                >
                    <StyledEnroll>{modal}</StyledEnroll>
                </Modal>
            </>
        );
    }
}

const StyledEnroll = styled.div.attrs({
    id: "styled-enroll-container",
})`
    position: relative;
    .upload-modal .ant-upload-list-item-card-actions {
        display: none;
    }
    .upload-modal .ant-upload-list {
        display: inline-flex;
        align-items: center;
    }
    .upload-modal .ant-upload-list-item {
        margin-top: -10px;
    }

    .upload-modal .ant-upload-list-item-name {
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .ant-card-body {
        padding: 10px 24px;
    }

    .after-enroll-result {
        display: none;
    }

    .reset-action-row {
        display: none;
        &.is-sync-result {
            display: flex;
        }
    }
`;

export default Enroll;
