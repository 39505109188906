import _ from "lodash";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Form, Row, Col, Input } from "antd";
const { TextArea } = Input;

function getParsedStudentInfo(studentData) {
    return studentData.studentInfo || {};
}

function SummaryContact({ studentData }) {
    const [studentInfoData, setStudentInfoData] = useState({});

    useEffect(() => {
        setStudentInfoData(getParsedStudentInfo(studentData));
    }, [studentData, setStudentInfoData]);
    const getContactsFields = () => {
        const children = [];

        _.each(studentInfoData.contacts, function (
            { classify, phone, email, emailPwd },
            index
        ) {
            children.push(
                <React.Fragment key={index}>
                    <div className="sub-title">{classify}</div>
                    <Row gutter={24} className="border-row">
                        <Col span={8}>
                            <Form.Item
                                label={"电话"}
                                colon={false}
                                labelAlign="left"
                            >
                                <Input readOnly value={phone} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={"Email地址"}
                                colon={false}
                                labelAlign="left"
                            >
                                <Input readOnly value={email} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={"Email密码"}
                                colon={false}
                                labelAlign="left"
                            >
                                <Input readOnly value={emailPwd} />
                            </Form.Item>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        });

        return children;
    };

    return (
        <StyledContainer>
            <header className="section-header">联系信息</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                {getContactsFields()}
                <div className="sub-title">申请邮箱</div>
                <Row gutter={24} className="border-row">
                    <Col span={8}>
                        <Form.Item
                            label="Email地址"
                            colon={false}
                            labelAlign="left"
                        >
                            <Input
                                readOnly
                                value={studentInfoData.newOrientalEmail}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Email密码"
                            colon={false}
                            labelAlign="left"
                        >
                            <Input
                                readOnly
                                value={studentInfoData.newOrientalEmailPwd}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="居住地址"
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={studentInfoData.homeAddr} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="邮寄地址"
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={studentInfoData.postAddr} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="备注" colon={false} labelAlign="left">
                            <TextArea
                                readOnly
                                value={studentInfoData.contactRemark}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})``;

SummaryContact.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryContact;
