import React, { Component } from "react";
import { message, Pagination } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import moment from "moment";
import DataDict from "./DataDict";
import _ from "lodash";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";
import Shade from "components/Shade";

class AdmissionInfo extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        pageSize: 50,
        loading: true,
        local: {
            emptyText: " ",
        },
        schoolMap: {},
        sorter: {},
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: { ...this.props.filteredInfo, isAdmission: true },
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/admissioninfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    Shade.hide();
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };
    renderDataSource = (datasource) => {
        let ds = datasource.data;
        let schoolIdListEn = datasource.schoolIdListEn;
        let schoolMap = {};
        for (let i = 0; i < ds.length; i++) {
            ds[i]["key"] = i;
            ds[i]["number"] = i + 1;
            ds[i]["roomOvertime"] = _.isEmpty(ds[i]["roomOvertime"])
                ? undefined
                : moment(ds[i]["roomOvertime"]);
            ds[i]["signTime"] = _.isEmpty(ds[i]["signTime"])
                ? undefined
                : moment(ds[i]["signTime"]);
            ds[i]["visaTime"] = _.isEmpty(ds[i]["visaTime"])
                ? undefined
                : moment(ds[i]["visaTime"]);
            ds[i]["offerArrived"] = _.isEmpty(ds[i]["offerArrived"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:offerArrived")[
                      ds[i]["offerArrived"]
                  ];
            ds[i]["request120"] = _.isEmpty(ds[i]["request120"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:request120")[
                      ds[i]["request120"]
                  ];
            ds[i]["arrived120"] = _.isEmpty(ds[i]["arrived120"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:arrived120")[
                      ds[i]["arrived120"]
                  ];
            ds[i]["openedToStu"] = _.isEmpty(ds[i]["openedToStu"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:openedToStu")[
                      ds[i]["openedToStu"]
                  ];
            ds[i]["confirm"] = _.isEmpty(ds[i]["confirm"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:confirm")[
                      ds[i]["confirm"]
                  ];
            ds[i]["deposit"] = _.isEmpty(ds[i]["deposit"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:deposit")[
                      ds[i]["deposit"]
                  ];
            ds[i]["pickup"] = _.isEmpty(ds[i]["pickup"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:pickup")[
                      ds[i]["pickup"]
                  ];
            ds[i]["bookroom"] = _.isEmpty(ds[i]["bookroom"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:bookroom")[
                      ds[i]["bookroom"]
                  ];
            ds[i]["endVisa"] = _.isEmpty(ds[i]["endVisa"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:endVisa")[
                      ds[i]["endVisa"]
                  ];
            ds[i]["visaResult"] = _.isEmpty(ds[i]["visaResult"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:visaResult")[
                      ds[i]["visaResult"]
                  ];
            ds[i]["healthForm"] = _.isEmpty(ds[i]["healthForm"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:healthForm")[
                      ds[i]["healthForm"]
                  ];
            if (_.isEqual("moblab_addition_school1", ds[i]["schoolId"])) {
                ds[i]["schoolId"] = "放弃入读";
            } else if (
                _.isEqual("moblab_addition_school2", ds[i]["schoolId"])
            ) {
                ds[i]["schoolId"] = "选择其他国家";
            } else {
                ds[i]["schoolId"] = _.isEmpty(ds[i]["schoolId"])
                    ? undefined
                    : // : DataDict.get(`erp:school:en:${ds[i]["schoolId"]}`);
                      ds[i]["schoolNameEn"];
            }
            ds[i]["secondSchoolId"] = _.isEmpty(ds[i]["secondSchoolId"])
                ? undefined
                : // : DataDict.get(`erp:school:en:${ds[i]["secondSchoolId"]}`);
                  ds[i]["secondSchoolNameEn"];
            ds[i]["sendVisa"] = _.isEmpty(ds[i]["sendVisa"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:sendVisa")[
                      ds[i]["sendVisa"]
                  ];
            ds[i]["passportEndDate"] = _.isEmpty(ds[i]["passportEndDate"])
                ? undefined
                : moment(ds[i]["passportEndDate"]);
            ds[i]["placementTestEndDate"] = _.isEmpty(
                ds[i]["placementTestEndDate"]
            )
                ? undefined
                : moment(ds[i]["placementTestEndDate"]);
            ds[i]["vaccineTableEndDate"] = _.isEmpty(
                ds[i]["vaccineTableEndDate"]
            )
                ? undefined
                : moment(ds[i]["vaccineTableEndDate"]);
            ds[i]["SEVISFee"] = _.isEmpty(ds[i]["SEVISFee"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:SEVISFee")[
                      ds[i]["SEVISFee"]
                  ];
            ds[i]["placementTest"] = _.isEmpty(ds[i]["placementTest"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:placementTest")[
                      ds[i]["placementTest"]
                  ];
            ds[i]["submitFinish"] = _.isEmpty(ds[i]["submitFinish"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:submitFinish")[
                      ds[i]["submitFinish"]
                  ];
            ds[i]["registerOrientation"] = _.isEmpty(
                ds[i]["registerOrientation"]
            )
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:registerOrientation")[
                      ds[i]["registerOrientation"]
                  ];
            ds[i]["submitFinalReportCard"] = _.isEmpty(
                ds[i]["submitFinalReportCard"]
            )
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:submitFinalReportCard")[
                      ds[i]["submitFinalReportCard"]
                  ];
            ds[i]["submitVaccineTable"] = _.isEmpty(ds[i]["submitVaccineTable"])
                ? undefined
                : DataDict.getMap("moblab:admissioninfo:submitVaccineTable")[
                      ds[i]["submitVaccineTable"]
                  ];

            schoolMap[ds[i]["contractId"]] = ds[i]["schoolIdList"];
            ds[i]["name"] = ds[i]["studentName"];
        }
        for (let k in schoolMap) {
            schoolMap[k] = _.map(schoolMap[k], (e) => {
                // return { key: e, value: DataDict.get(`erp:school:en:${e}`) };
                return { key: e, value: schoolIdListEn[e] };
            });
        }

        this.setState({
            data: ds,
            currentPage: datasource.currentPage,
            total: datasource.total,
            loading: false,
            schoolMap: schoolMap,
        });
        Shade.hide();
    };

    updateRow = (field, record, value) => {
        console.log(`${field} 编辑:`, record);
        if (!record["id"]) {
            Shade.show();
        }
        Request.POST("excel/admissioninfo", {
            data: {
                attr: field,
                val: record[field],
                id: record["id"],
                contractId: record["contractId"],
            },
            onSuccess: (data) => {
                if (data.error === "duplicate key for insert db") {
                    message.error("已有记录存在");
                }
                this.init();
            },
            onFail: (status) => {
                Shade.hide();
                if (status === 401) return true;
                message.error("更新失败");
            },
        });
    };

    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        const columns = [
            {
                className: "column-apply-status",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applystatus",
                render: (statusCode, row) => {
                    return (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                key: "name",
                render: (value, row, index) => {
                    return (
                        <StudentLink
                            studentName={value}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "学生生日",
                dataIndex: "birthday",
                width: "110px",
                render: (value, row, index) => {
                    return value
                        ? moment(value).format("YYYY-MM-DD")
                        : hiddenDIV;
                },
            },
            {
                title: "护照到期日",
                dataIndex: ["passportEndDate"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("passportEndDate", record, value);
                },
                width: "140px",
            },
            {
                title: "确认入读学校名称",
                dataIndex: ["schoolId"],
                editable: true,
                dynamicDropdown: true,
                ellipsis: true,
                getDropDownData: (e) => {
                    const schoolList = this.state.schoolMap[e.contractId];
                    const ops = _.isEmpty(schoolList)
                        ? []
                        : _.clone(schoolList);
                    ops.push({
                        key: "moblab_addition_school1",
                        value: "放弃入读",
                    });
                    ops.push({
                        key: "moblab_addition_school2",
                        value: "选择其他国家",
                    });
                    return ops;
                },
                handleSave: (record, value) => {
                    this.updateRow("schoolId", record, value);
                },
                width: "300px",
            },
            {
                title: "确认入读网址",
                dataIndex: ["website"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("website", record, value);
                },
                ellipsis: true,
                width: "300px",
            },
            {
                title: "确认入读账号",
                dataIndex: ["account"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("account", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "确认入读密码",
                dataIndex: ["password"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("password", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "缴纳入读定金",
                dataIndex: ["deposit"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:deposit"),
                handleSave: (record, value) => {
                    this.updateRow("deposit", record, value);
                },
                width: "125px",
            },
            {
                title: "学生ID",
                dataIndex: ["stuId"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("stuId", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "学生邮箱",
                dataIndex: ["stuMail"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("stuMail", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "密码",
                dataIndex: ["passwd"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("passwd", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "Request I20",
                dataIndex: ["request120"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:request120"),
                handleSave: (record, value) => {
                    this.updateRow("request120", record, value);
                },
                width: "125px",
            },
            {
                title: "I20已到达",
                dataIndex: ["arrived120"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:arrived120"),
                handleSave: (record, value) => {
                    this.updateRow("arrived120", record, value);
                },
                width: "125px",
            },
            {
                title: "SEVIS ID",
                dataIndex: ["SEVISId"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("SEVISId", record, value);
                },
                ellipsis: true,
                width: "125px",
            },
            {
                title: "SEVIS Fee",
                dataIndex: ["SEVISFee"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:SEVISFee"),
                handleSave: (record, value) => {
                    this.updateRow("SEVISFee", record, value);
                },
                width: "125px",
            },
            {
                title: "住宿截止",
                dataIndex: ["roomOvertime"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("roomOvertime", record, value);
                },
                width: "140px",
            },
            {
                title: "提交住宿申请",
                dataIndex: ["bookroom"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:bookroom"),
                handleSave: (record, value) => {
                    this.updateRow("bookroom", record, value);
                },
                width: "125px",
            },
            {
                title: "Placement Test",
                dataIndex: ["placementTest"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:placementTest"),
                handleSave: (record, value) => {
                    this.updateRow("placementTest", record, value);
                },
                width: "125px",
            },
            {
                title: "Placement Test 截止日期",
                dataIndex: ["placementTestEndDate"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("placementTestEndDate", record, value);
                },
                width: "240px",
            },
            {
                title: "注册 Orientation",
                dataIndex: ["registerOrientation"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:admissioninfo:registerOrientation"
                ),
                handleSave: (record, value) => {
                    this.updateRow("registerOrientation", record, value);
                },
                width: "125px",
            },
            {
                title: "签培时间",
                dataIndex: ["signTime"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("signTime", record, value);
                },
                width: "140px",
            },
            {
                title: "发送签证、行前材料",
                dataIndex: ["sendVisa"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:sendVisa"),
                handleSave: (record, value) => {
                    this.updateRow("sendVisa", record, value);
                },
                width: "180px",
            },
            {
                title: "签证时间",
                dataIndex: ["visaTime"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("visaTime", record, value);
                },
                width: "140px",
            },
            {
                title: "签证结果",
                dataIndex: ["visaResult"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:visaResult"),
                handleSave: (record, value) => {
                    this.updateRow("visaResult", record, value);
                },
                width: "110px",
            },
            {
                title: "疫苗截止日期",
                dataIndex: ["vaccineTableEndDate"],
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.updateRow("vaccineTableEndDate", record, value);
                },
                width: "140px",
            },
            {
                title: "提交疫苗表",
                dataIndex: ["submitVaccineTable"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:admissioninfo:submitVaccineTable"
                ),
                handleSave: (record, value) => {
                    this.updateRow("submitVaccineTable", record, value);
                },
                width: "110px",
            },
            {
                title: "已开放给学生",
                dataIndex: ["openedToStu"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:openedToStu"),
                handleSave: (record, value) => {
                    this.updateRow("openedToStu", record, value);
                },
                width: "125px",
            },
            {
                title: "Final 成绩单提交",
                dataIndex: ["submitFinalReportCard"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:admissioninfo:submitFinalReportCard"
                ),
                handleSave: (record, value) => {
                    this.updateRow("submitFinalReportCard", record, value);
                },
                width: "210px",
            },
            {
                title: "预定接机",
                dataIndex: ["pickup"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:pickup"),
                handleSave: (record, value) => {
                    this.updateRow("pickup", record, value);
                },
                width: "125px",
            },
            {
                title: "备选学校",
                dataIndex: ["secondSchoolId"],
                editable: true,
                dynamicDropdown: true,
                ellipsis: true,
                getDropDownData: (e) => {
                    let ops = this.state.schoolMap[e.contractId];
                    return _.isEmpty(ops) ? [] : ops;
                },
                handleSave: (record, value) => {
                    this.updateRow("secondSchoolId", record, value);
                },
                width: "300px",
            },
            {
                title: "是否报完成",
                dataIndex: ["submitFinish"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:admissioninfo:submitFinish"),
                handleSave: (record, value) => {
                    this.updateRow("submitFinish", record, value);
                },
                width: "110px",
            },
            {
                title: "未完成原因",
                dataIndex: ["unFinishReason"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("unFinishReason", record, value);
                },
                ellipsis: true,
                width: "200px",
            },
            {
                title: "备注",
                dataIndex: ["remark"],
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("remark", record, value);
                },
                ellipsis: true,
                width: "300px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                render: (value, row, index) => {
                    return (
                        <OperationButtons
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            applyStatus={row["applyStatus"]}
                            applyAdvisor={row["applyAdvisorName"]}
                            fetchData={this.fetchData}
                            isShowUpload={false}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractStatus={row["contractStatus"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                fixed: "right",
                width: "100px",
                align: "center",
            },
        ];

        return (
            <Shade>
                <Editable
                    size="small"
                    dataSource={this.state.data}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: 4300, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </Shade>
        );
    }
}
export default AdmissionInfo;
