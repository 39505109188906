import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";

function Section({ title, subTitle, description, children, className }) {
	return (
		<StyledContainer className={className}>
			{title ? <div className="section-title">{title}</div> : null}
			{subTitle ? (
				<div className="section-subTitle">{subTitle}</div>
			) : null}
			{description ? (
				<div className="section-desc">{description}</div>
			) : null}
			{children}
		</StyledContainer>
	);
}

const StyledContainer = styled.div.attrs({
	className: "section-container",
})`
	width: 100%;
	box-shadow: 0 1px 4px rgba(16, 32, 55, 0.16);
	border: none;
	border-radius: 8px;
	margin-bottom: 20px;
	background-color: white;
	padding: 20px;
	position: relative;
	.section-title {
		font-size: 24px;
		color: #202324;
	}
	.section-subTitle {
		font-size: 14px;
		color: #202324;
		position: absolute;
		top: 25px;
		right: 30px;
	}

	@media print {
		.section-subTitle {
			display: none;
		}
	}

	.section-desc {
		font-size: 16px;
		font-weight: 400;
		color: #a1a8b3;
		margin-bottom: 20px;
	}
`;

Section.propTypes = {
	title: propTypes.string,
	description: propTypes.string,
};

export default Section;
