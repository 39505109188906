import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import propTypes from "prop-types";
import SubSection from "../SubSection";
import SchoolList from "./SchoolList";

function PartSchool({ reportData, partOrder, part }) {
    let queryParams = new URL(window.document.location).searchParams;
    const [schoolData, setSchoolData] = useState();
    useEffect(() => {
        const schoolIds = _.keys(reportData.school_recommendation).join("+");
        const schoolFilters =
            "UNITID+CITY+MOBLAB_RANK+INSTNM+STABBR+LOCALE+CONTROL+SCHOOL_TYPE+MENONLY+WOMENONLY+UGDS_NRA+RANK+TUITIONFEE_OUT";
        axios
            .get(`/api/1/school/${schoolIds}/${schoolFilters}`, {
                params: {
                    uid: queryParams?.get("uid"),
                    token: queryParams?.get("token"),
                },
            })
            .then(function (response) {
                let schoolMetaData = response.data.schools;
                const parsedSchoolData = _.map(
                    reportData.school_recommendation,
                    function (score, shcoolId) {
                        const metaData = _.find(schoolMetaData, {
                            UNITID: Number(shcoolId),
                        });
                        metaData.score = score;
                        return metaData;
                    }
                );
                const orderedSchoolData = _.orderBy(
                    parsedSchoolData,
                    ["score"],
                    ["desc"]
                );

                // get top 10 shcools, orderedSchoolData.slice(0, 10)
                setSchoolData(orderedSchoolData);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!schoolData) {
        return null;
    }
    return (
        <React.Fragment>
            <SubSection
                title={part.title}
                partKey={part.key}
                sectionNo={partOrder}
            />
            <SchoolList
                title={part.title}
                shcools={[schoolData[0], schoolData[1]]}
                isFirstPage
            />
            {_.map(schoolData, function (major, index) {
                if (index <= 1) {
                    return null;
                }

                if ((index - 2) % 4 === 0) {
                    return (
                        <SchoolList
                            key={index}
                            title={part.title}
                            shcools={[
                                major,
                                schoolData[index + 1],
                                schoolData[index + 2],
                                schoolData[index + 3],
                            ]}
                        />
                    );
                }
            })}
        </React.Fragment>
    );
}

PartSchool.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default PartSchool;
