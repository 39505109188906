import React, { useState, useEffect } from "react";
import _ from "lodash";
import cn from "classnames";
import Card from "../Card";
import styled from "styled-components";
// import { useForceUpdate } from "lib/customHooks";

function getLevel2Description(item, score) {
    if (score >= 80) {
        return item.s80;
    } else if (score >= 60) {
        return item.s60;
    }

    return item.s60minus;
}

function getLevel2Distribution(score) {
    if (score >= 80) {
        return "高";
    } else if (score >= 60) {
        return "中";
    }

    return "低";
}

function GlobalCompetenceSection({
    title,
    subtitle,
    level2items,
    compositeScore,
    level2Scores,
    planSolution,
    printView,
}) {
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        setShowDetails(!!printView);
    }, [printView]);

    return (
        <StyledContainer>
            <Card title={title} subtitle={subtitle}>
                <div className="level2-container">
                    <div className="level1-plan">规划方案</div>
                    <div className="level1-plan-text">{planSolution}</div>
                    <div
                        className="toggle-level2"
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                    >
                        {showDetails ? "收起<<" : "详细维度解读>>"}
                    </div>
                    {_.map(level2items, function (item, index) {
                        return (
                            <div
                                className={cn([
                                    "level2-sub-container",
                                    { collapsed: !showDetails },
                                ])}
                                key={index}
                            >
                                <div className="level2-title">
                                    {item.title}
                                    <div className="level2-score">
                                        {getLevel2Distribution(
                                            level2Scores[index]
                                        )}
                                    </div>
                                </div>
                                <div className="level2-description">
                                    {getLevel2Description(
                                        item,
                                        level2Scores[index]
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card>
            <div className="composite-score">
                综合得分 {Math.round(compositeScore)}
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "global-competence-section",
})`
    position: relative;
    .composite-score {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 1rem;
        font-weight: 400;
        color: #202324;
    }
    .level2-container {
        padding: 20px;
        .level2-sub-container {
            &.collapsed {
                display: none;
            }
            margin: 40px 0;
        }

        .toggle-level2 {
            margin: 20px 0;
            cursor: pointer;
            text-decoration: underline;
            color: #1890ff;
        }

        .level1-plan,
        .level1-plan-text,
        .toggle-level2,
        .level2-title {
            font-size: 1.2rem;
            font-weight: 400;
        }

        .level1-plan {
            color: #202324;
        }

        .level1-plan-text {
            color: #a1a8b3;
        }

        .level2-title {
            color: #202324;
            position: relative;
            .level2-score {
                position: absolute;
                top: 0;
                right: 40px;
                font-size: 1rem;
            }
        }

        .level2-description {
            font-size: 1rem;
            font-weight: 400;
            color: #a1a8b3;
        }
    }
`;

export default GlobalCompetenceSection;
