export default {
    validate(password) {
        const hasNumeral = /[0-9]/;
        const hasLowercase = /[a-z]/;
        const hasUppercase = /[A-Z]/;

        const validation = {
            length: password.length >= 8,
            numeral: hasNumeral.test(password),
            uppercase: hasUppercase.test(password),
            lowercase: hasLowercase.test(password)
        };
        validation.valid = validation.length && 
            validation.numeral && 
            validation.uppercase && 
            validation.lowercase;
            
        return validation;
    }
}