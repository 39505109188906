export const level1 = [
    {
        uKey: "global_vision",
        title: "全球视野",
        description: `强调学生知识的多元化，对本地、全球性和跨文化问题的了解程度。是否视自己为全球公民，了解世界现状，关注全球健康，移民，国际冲突，老年人问题等内容。`,
        excellent: `未来可以通过参与Level3级别的国际夏校、全球性议题的学术研究、模拟联合国等活动继续突出学生在全球视野维度的优势。`,
        enhanced: `未来可以通过参与Level1/Level2级别的国际夏校、全球性议题的学术研究、跨文化领域的实践活动、拓展阅读等方式来补充提升在全球视野维度的深入参与，具象化认识和了解多元文化、跨文化交流沟通等能力。`,
    },
    {
        uKey: "thinking_and_academic_ability",
        title: "思维学术",
        description: `强调对世界的理解力和行动力，不断思考并寻求解决方案。拥有好奇心，批判性思维，能够独立思考本地、全球性和跨文化问题，并且愿意探索这些问题的形成原因和未来发展趋势。运用所学知识，不断研究解决全球性和跨文化问题的方向。`,
        excellent: `未来可以通过参与Level3级别的国际学术竞赛、学术科研活动、发明创造等学术实践活动，拓展学术思维能力的同时取得较为卓越的学术研究成果，不断地凸显在学术思维维度的各种特质以及优势。`,
        enhanced: `未来可以通过参与Level1/Level2级别的学术竞赛、各学科领域的实验活动、学术科研活动等学术实践活动来补充学术能力维度的实践经历，提升学术思维能力。`,
    },
    {
        uKey: "social_skills",
        title: "社会技能",
        description: `强调为达到某一特定目标而进行的复杂有组织的行为的能力。更容易适应新环境并能够将技能和知识应用到新环境中；能够在不同的场合运用正确的方式，
        准确的表达个人的观点；能够参与到团队合作当中去，发挥团队精神以达到团队最佳效能；能够协调团队成员，领导组织安排各项活动，形成团队合力，有效的解决处理各项问题。`,
        excellent: `未来可以通过作为活动组织者、创始人或关键角色参与课外活动、社会实践活动、NSDA全美演讲与辩论联赛、各学校为单位组织的学生会、社团等活动，不断提升综合能力，展示在社会技能维度的实践优势。`,
        enhanced: `未来可以通过深入参与课外活动、社会实践活动、学术竞赛、学术研究实践、演讲与辩论竞赛、各学校为单位组织的学生会、商社、辩论社等活动，补充在社会技能维度的相关经历，具象化在该维度上的能力。`,
    },
    {
        uKey: "values",
        title: "价值态度",
        description: `强调人类的尊严和文化多样性，要求形成重视人的尊严和重视文化多样性的价值观。拥有这样价值观的青年会对自己及周围环境了解更加深刻，更具有责任心和服务意识，能够不断地反思自我，愿意投入到全球性和跨文化问题的服务中。`,
        excellent: `未来可以通过深入落实服务意识，参与解决更多的本地、全球和跨文化问题方面的公益活动、社会活动等实践活动，突出展现在公益心、责任心、服务意识的特质。`,
        enhanced: `未来可以通过深入落实服务意识，参与解决更多的社会问题方面的公益活动、社会活动等实践活动，例如：关注特殊人群、环境保护、公益扶贫等，突出展现在公益心、责任心、服务意识的特质。`,
    },
];

export const level2 = [
    {
        uKey: "gv_openness",
        type: "全球视野",
        title: "开放性、包容性、尊重差异",
        s80: `能充分理解差异是如何影响自我及他人的观点、判断与诠释; 能够以开放、包容地的态度，尊重差异，并能够与不同文化背景的人沟通的能力。`,
        s60: `理解差异是如何影响自我及他人的观点、判断与诠释; 在生活中能够尊重差异，主动、开放、包容地接受多元文化。`,
        s60minus: `能充分理解差异是如何影响自我及他人的观点、判断与诠释; 但并未参与到与不同文化背景得人的接触中。`,
    },
    {
        uKey: "gv_intercaltural_communication",
        type: "全球视野",
        title: "跨文化交流沟通的能力",
        s80: `与不同国家、不同文化背景的人交往沟通的能力。能够熟练运用至少一种外语进行跨文化交流，有同理心，可以站在对方的角度去寻求有效的沟通方式。`,
        s60: `与不同国家、不同文化背景的人交往沟通的能力。能够有效运用至少一种外语进行跨文化交流，理解并尊重双方的观点。`,
        s60minus: `与不同国家、不同文化背景的人交往沟通的能力。能够有效运用至少一种外语进行跨文化交流，有同理心，可以站在对方的角度去寻求有效的沟通方式。`,
    },
    {
        uKey: "gv_multiculturalism",
        type: "全球视野",
        title: "认识和了解多元文化的能力",
        s80: `强调学生知识的多元化，对本地、全球性和跨文化问题的了解程度。视自己为全球公民，了解世界现状，参与全球性问题的研究和实践。`,
        s60: `能够有意识的拓展知识面，了解本地、全球性和跨文化问题。关注世界现状，了解全球性问题，如健康，移民，国际冲突，老年人问题等。`,
        s60minus: `能够有意识的拓展知识面，主动了解本地、全球性和跨文化问题。但是对这些问题的了解并不深入，还未参与到全球性问题中。`,
    },
    {
        uKey: "taa_academic_abilities",
        type: "思维学术",
        title: "学术能力",
        s80: `具备卓越的探索能力，善于思考，发现问题和提出问题；能够不断拓展思维模式与研究的方法，深入探索、研究，解决问题的学术实践能力，并且取得了卓越的研究成果。`,
        s60: `具备卓越的探索能力，发现问题和提出问题；对于这些问题，能够不断拓展思维模式与研究的方法，同时参与实践，并有所研究。`,
        s60minus: `能够广泛的阅读，善于思考，发现问题和提出问题；并愿意利用所学知识进行学术实践，寻求解决方法。`,
    },
    {
        uKey: "taa_critical_thinking",
        type: "思维学术",
        title: "批判性思维",
        s80: `批判性地分析全球与跨文化议题的能力，能够认可，表达，定位和解释多个利益相关者观点的能力，在社会冲突中，考虑并提供整合各自不同立场的解决方案。`,
        s60: `认可，表达，定位和解释多个利益相关者观点的能力，能够更为准确和全面的认识事物的本质，批判性地分析全球与跨文化议题的能力。`,
        s60minus: `评价、质疑、矫正，并通过分析、比较、综合，进而达到对事物本质更为准确和全面认识的能力，在社会冲突中，可以不断思考解决方案。`,
    },
    {
        uKey: "taa_curiosity",
        type: "思维学术",
        title: "好奇心、求知欲",
        s80: `善于发现问题，喜欢不断探索本地、全球性和跨文化问题的形成原因和未来发展趋势，深入地研究过至少某一个学术问题，致力于寻求更好的解决方案。`,
        s60: `善于发现问题，喜欢不断探索本地、全球性和跨文化问题的形成原因和未来发展趋势，不断参与实践研究，寻求更好的解决方案。`,
        s60minus: `善于发现问题并不断探索本地、全球性和跨文化问题的形成原因和未来发展趋势，但并未参与到相关领域的研究中。`,
    },
    {
        uKey: "taa_creativity",
        type: "思维学术",
        title: "创造力、创新能力",
        s80: `具备产生新思想，发现和创造新事物的能力。并取得一定的创新实践成果。`,
        s60: `具备一定的产生新思想，发现和创造新事物的能力，并且开展了创新实践的研究。`,
        s60minus: `具备一定的产生新思想，发现和创造新事物的能力。但并未参与实践。`,
    },
    {
        uKey: "ss_public_speaking",
        type: "社会技能",
        title: "交流沟通、公开演讲与辩论的能力",
        s80: `能够正确理解他人的观点，能够在不同的场合运用正确的方式，流畅、准确的表达个人的观点，并达到沟通的目的。`,
        s60: `善于倾听，并且能够准确的表达个人的观点。`,
        s60minus: `能够理解他人，并且能够顺畅的表达个人的观点。`,
    },
    {
        uKey: "ss_problem_solving",
        type: "社会技能",
        title: "解决问题的能力",
        s80: `具备运用知识、策略和方法独立解决问题的能力。且在学习、生活中多次主导处理解决实际问题。`,
        s60: `具有一定的运用知识、策略和方法独立解决问题的能力，且在学习、生活中能够协助处理解决实际问题。`,
        s60minus: `具有一定的运用知识、策略和方法独立解决问题的能力，但并未参与到解决问题的实践中。`,
    },
    {
        uKey: "ss_teamwork",
        type: "社会技能",
        title: "团队合作能力",
        s80: `能够快速融入并参与到团队合作当中去，激发团队成员的团队精神以达到团队最佳效能，实现最终目标。具有组建团队或者作为团队关键角色参与实践的经历。`,
        s60: `能够融入并参与到团队合作当中去，为共同目标努力。具有作为团队关键角色参与实践的经历。`,
        s60minus: `愿意融入并参与到团队合作当中去，为共同目标努力。`,
    },
    {
        uKey: "ss_leadership",
        type: "社会技能",
        title: "领导力",
        s80: `能够作为领导者，有效的协调团队成员，领导组织安排各项活动，形成团队合力，最终高效解决处理各项问题。`,
        s60: `能够作为关键角色，协调团队成员，领导组织安排各项活动，顺利解决处理各项问题。`,
        s60minus: `愿意主动作为领导者参与团队合作，协调团队成员，组织安排各项活动，但并未参与过领导实践。`,
    },
    {
        uKey: "values_agreeableness_conscientiousness",
        type: "价值态度",
        title: "公益心、责任心、服务意识",
        s80: `有责任心和服务意识，能够不断地反思自我，同时尽可能的参与社会实践，投入到全球性和跨文化问题的服务中，并取得一定成就。`,
        s60: `有责任心和服务意识，能够不断地反思自我，同时尽可能的参与全球性和跨文化问题的社会实践，为了取得成果不断努力。`,
        s60minus: `有责任心和服务意识，能够不断地反思自我，愿意投入到全球性和跨文化问题的服务中。`,
    },
];
