import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DashFilter from "./DashFilter";
import FilterContext from "./FilterContext";
import SummaryTab from "./SummaryTab";
import ApplyTab from "./ApplyTab";
import TrackingTab from "./TrackingTab";
import AdmissionTab from "./AdmissionTab";
import ManagerTab from "./ManagerTab";
import AdminTab from "./AdminTab";
import { Request } from "lib";
import { Tabs, Spin } from "antd";
import { Button } from "semantic-ui-react";
const { TabPane } = Tabs;

function Dashboard() {
    // adminLevel = [0, 1, 2, 3], 1 & 2  is manager, 3 is admin
    const [adminLevel, setAdminLevel] = useState(0);

    const [tabKey, setTabKey] = useState("1");
    const [loading, setLoading] = useState(false);

    let [filter, setFilter] = useState({
        year: "",
        advisor: "",
        academy: "",
        departmentId: "",
    });

    useEffect(() => {
        setLoading(true);
        Request.GET("advisor/isadmin", {
            onSuccess: (response) => {
                setLoading(false);
                setAdminLevel(response.isAdmin);
            },
            onFail: (error) => {
                setLoading(false);
            },
        });
    }, []);

    function callback(key) {
        // NO-1450 clear department autocomplete
        if (key === "6" || key === "7") {
            let newFilter = {
                ...filter,
                departmentId: "",
            };
            setFilter(newFilter);
        }

        setTabKey(key);
    }
    return (
        <Spin tip="Loading..." spinning={loading}>
            <StyledContainer>
                <FilterContext.Provider value={{ filter, setFilter }}>
                    <header>
                        <DashFilter adminLevel={adminLevel} tabKey={tabKey} />
                    </header>
                    <div className="widgets-container">
                        <Tabs
                            defaultActiveKey="1"
                            onChange={callback}
                            animated={false}
                        >
                            <TabPane tab="整体情况" key="1">
                                <SummaryTab />
                            </TabPane>
                            <TabPane tab="申请进度" key="2">
                                <ApplyTab />
                            </TabPane>
                            <TabPane tab="跟踪进度" key="3">
                                <TrackingTab />
                            </TabPane>
                            <TabPane tab="入读确认" key="4">
                                <AdmissionTab />
                            </TabPane>
                            {[1, 2].includes(adminLevel) ? (
                                <TabPane tab="顾问汇总" key="5">
                                    <ManagerTab isCurrentTab={tabKey === "5"} />
                                </TabPane>
                            ) : null}
                            {adminLevel === 3 ? (
                                <TabPane tab="部门汇总" key="6">
                                    <AdminTab
                                        isCurrentTab={tabKey === "6"}
                                        tabKey={tabKey}
                                    />
                                </TabPane>
                            ) : null}
                            {adminLevel === 3 ? (
                                <TabPane tab="分公司汇总" key="7">
                                    <AdminTab
                                        isCurrentTab={tabKey === "7"}
                                        tabKey={tabKey}
                                    />
                                </TabPane>
                            ) : null}
                        </Tabs>
                        {adminLevel === 3 ? (
                            <a
                                href={"/api/1/dashboard/exportexcel"}
                                download="美本后期申请数据汇总.xlsx"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <Button
                                    positive
                                    content="导出数据"
                                    className="export-button"
                                />
                            </a>
                        ) : null}
                    </div>
                </FilterContext.Provider>
            </StyledContainer>
        </Spin>
    );
}

const StyledContainer = styled.div.attrs({
    className: "dashboard-container",
})`
    height: calc(100vh - 5rem);
    padding-left: 50px;
    overflow: auto;
    .widgets-container {
        padding: 0 40px 20px;
        position: relative;
    }

    .ant-tabs-nav-scroll {
        display: flex;
    }

    .ant-tabs-tab {
        font-size: 18px;
    }

    .ant-table.ant-table-small .ant-table-tbody {
        > tr {
            line-height: unset;
            > th,
            > td {
                padding: 4px 8px;
            }
        }
    }

    .ant-table-cell.col-ratio {
        padding: 3px !important;
        position: relative;
        .ratio-bar {
            position: absolute;
            background-color: rgb(37, 193, 56);
            height: calc(100% - 6px);
            top: 3px;
            left: 3px;
            z-index: 1;
        }
        .ratio-text {
            position: relative;
            z-index: 2;
        }
    }

    .font-orange {
        color: orange;
    }

    .font-red {
        color: red;
    }

    .export-button {
        min-height: unset;
        font-size: 14px;
        line-height: 20px;
        padding: 5px 15px;
        position: absolute;
        top: 12px;
        right: 40px;
    }
`;

Dashboard.propTypes = {};

export default Dashboard;
