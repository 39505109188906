import React from "react";
import _ from "lodash";
import crypto from "crypto-browserify";
import { Buffer } from "buffer";
import { Select } from "antd";
const { Option } = Select;

const utils = {
    sleep: function (ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },

    // async function asyncPrint() {
    // 	await sleep(10000);
    // 	window.print();
    // }

    log: function (...msgs) {
        if (process.env.NODE_ENV === "development") {
            console.log(...msgs);
        }
    },

    dir: function (...msgs) {
        if (process.env.NODE_ENV === "development") {
            console.dir(...msgs);
        }
    },

    validateEmail: function (emailString) {
        var emailRegex = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,}$/i;
        return emailRegex.test(emailString);
    },

    fixNumber: function (value, digits) {
        return Number((Number(value) || 0).toFixed(digits));
    },

    getSelectOptions: function (collections) {
        return _.map(collections, function ({ key, value }) {
            return (
                <Option value={key} key={key}>
                    {value}
                </Option>
            );
        });
    },

    buildUrl: function (base = "", params) {
        let serializedParams = utils.paramsSerializer(params);
        if (serializedParams === "") {
            return base;
        }

        const separator = base.includes("?") ? "&" : "?";

        return base + separator + utils.paramsSerializer(params);
    },

    paramsSerializer: function (params) {
        const parts = [];

        _.each(params, function serialize(val, key) {
            if (_.isNil(val) || val === "") {
                return;
            }

            parts.push(`${key}=${encodeURIComponent(val)}`);
        });

        return parts.join("&");
    },

    listMonthesBetweenDate: function (momentStart, momentEnd) {
        let timeValues = [];

        while (
            momentEnd > momentStart ||
            momentStart.format("M") === momentEnd.format("M")
        ) {
            timeValues.push(momentStart.format("YY/MM"));
            momentStart.add(1, "month");
        }
        return timeValues;
    },

    triggerWindowResize: function () {
        // For a full list of event types: https://developer.mozilla.org/en-US/docs/Web/API/document.createEvent
        let el = document; // This can be your element on which to trigger the event
        let event = document.createEvent("HTMLEvents");
        event.initEvent("resize", true, false);
        el.dispatchEvent(event);
    },

    getScoreColor: function (score, LowRankingBoundary, HighRankingBoundary) {
        if (score < LowRankingBoundary) {
            return "#FF6383";
        } else if (score >= LowRankingBoundary && score < HighRankingBoundary) {
            return "#FFA74E";
        } else if (score >= HighRankingBoundary && score < 100) {
            return "#30B9F8";
        } else {
            return "#4FCC9C";
        }
    },

    getScoreColor2108: function (
        score,
        LowRankingBoundary,
        HighRankingBoundary
    ) {
        if (score === -1) {
            return "#E1E3E6";
        }

        if (score < LowRankingBoundary) {
            return "#FF4E4E";
        } else if (score >= LowRankingBoundary && score < HighRankingBoundary) {
            return "#FEBD55";
        } else {
            return "#3B9CFF";
        }
    },

    getElementTop: function (element) {
        var actualTop = element.offsetTop;
        var current = element.offsetParent;
        while (current !== null) {
            actualTop += current.offsetTop;
            current = current.offsetParent;
        }
        return actualTop;
    },

    isLocalMock: function () {
        return localStorage.getItem("config.localMock") === "1";
    },

    getTop5Majors: function (majors) {
        let sortedMajors = _.sortBy(majors, ["score"]).reverse();
        sortedMajors = sortedMajors.slice(0, 5);
        return sortedMajors;
    },

    getLastestAssessmentInstance: function (assessmentInstances) {
        if (!assessmentInstances?.length) {
            return undefined;
        }

        const sortedAssessmentInstances = _.sortBy(
            assessmentInstances,
            function (instance) {
                return +new Date(instance.lastUsedTime);
            }
        );

        return _.last(sortedAssessmentInstances);
    },

    reportFinishStatus: {
        invalid: Symbol(),
        partial: Symbol(),
        finished: Symbol(),
    },

    getReportFinishStatusByTraits: function (traits) {
        let unFinishedNum = 0;

        _.each(traits, function (trait) {
            if (trait.score === -1) {
                unFinishedNum++;
            }
        });

        if (unFinishedNum === 0) {
            return utils.reportFinishStatus.finished;
        }

        if (unFinishedNum === traits.length) {
            return utils.reportFinishStatus.invalid;
        }

        return utils.reportFinishStatus.partial;
    },

    getAuthorization: function ({
        accessId = "eduguide",
        accessKey = "eduguide",
        method = "GET",
        endpoint = "/report",
        body = "{}",
    }) {
        const timestamp = new Date().valueOf();
        const requestVars =
            method +
            timestamp +
            (_.isEqual(method, "GET") ? "" : body) +
            endpoint;

        const hex = crypto
            .createHmac("sha256", accessKey)
            .update(requestVars)
            .digest("hex");

        const signature = new Buffer(hex).toString("base64");

        return window.btoa(`${accessId}:${signature}:${timestamp}:${endpoint}`);
    },
};

export default utils;
