import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'

/**
 * @class Input
 * 
 * Custom input component with Moblab styling.
 * 
 * This input field only has a border-bottom, and the label will appear as the
 * placeholder until the field is focused or not empty, at which point it will
 * animate to a position above the input field. This field also supports a 
 * `show_password` toggle, when the type is set to `password`.
 * 
 * @prop {String} type        input type [text|password|numeric]
 * @prop {String} label       label text
 * @prop {String} placeholder placeholder text (only use if open=true)
 * @prop {String|Int} value   field value
 * @prop {Boolean} disabled   if true, will disable the input
 * @prop {Boolean} open       if true, label will always appear above input
 * @prop {Boolean} focused    if true, will set the initial state to `focused`
 * @prop {Boolean} error      if true, will set the field state to `errored`
 * @prop {Function} onBlur    onBlur synthetic event callback
 * @prop {Function} onCange   onChange synthetic event callback
 */
class Input extends Component {
    state={
        value: "",
        empty: true,
        showPassword: false,
        focused: false
    }
    constructor(){
        super();
        this.togglePassword = this.togglePassword.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount(){
        if (this.props.focused){
            setTimeout(() => {
                this.input.focus();
            })
        }
    }
    togglePassword(){
        this.setState({showPassword: !this.state.showPassword});
    }
    onBlur(){
        this.setState({focused: false});
    }
    onFocus(e, a){
        if (this.props.onFocus){
            this.props.onFocus(e, a);
        }
        this.setState({focused: true});
    }
    onChange(e){
        if (!this.props.hasOwnProperty('value')){
            this.setState({ value: e.target.value });
        }
        if (this.props.hasOwnProperty('onChange')){
            this.props.onChange(e);
        }
    }
    render() {
        const { showPassword, focused } = this.state;
        const { disabled, error, label, type, value, open, placeholder } = this.props;

        const inpVal = value || this.state.value;
        const pwdType = showPassword ? "text" : "password";
        const displayType = type === "password" ? pwdType : type;
        var iconClass = (showPassword ? "hide" : "show") + "Password icon";
        var fieldClass = (inpVal ? "" : "empty") + 
            (focused ? " focused" : "") + 
            (open ? " open" : "");
        return (
            <Form.Field
                className={fieldClass}
                error={error}
                disabled={disabled}
                onBlur={this.onBlur}
            >
                {label && 
                    <label>{label}</label>
                }
                <div className="ui input">
                    <input
                        type={displayType}
                        ref={(input) => { this.input = input; }}
                        value={value}
                        placeholder={placeholder}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                    />
                </div>
                { type === "password" &&
                    <i className={iconClass} onClick={this.togglePassword}>
                        {this.state.showPassword ? "G" : "H"}
                    </i>
                }
            </Form.Field>
        )
    }
}

export default Input;
