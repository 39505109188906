import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ReportHeader from "./ReportHeader";
import Pager from "./Pager";
import majorIcon from "img/report/major/major-icon.png";
import jobIcon from "img/report/major/job-icon.png";
import MajorBanner from "./components/MajorBanner";
import MajorSubTitle from "./components/MajorSubTitle";
import MajorSubItem from "./components/MajorSubItem";
import MajorJobItem from "./components/MajorJobItem";

function MajorUltimatePerMajor({
    title,
    major,
    pageSections,
    setMajorsBySubName,
}) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            {_.map(pageSections, function (section, index) {
                const type = section.type;

                switch (type) {
                    case "allTitleSection":
                        return (
                            <div
                                className="left-right-padding all-major-title"
                                style={{ alignItems: "flex-start" }}
                                key={index}
                            >
                                <div className="text-title">
                                    专业与职业推荐详解
                                </div>
                                <div className="text-desc">
                                    我们列出了您最匹配的专业类别的详细介绍以及对应的未来职业发展规划，
                                    让您在进行专业选择时能够有更全面的信息作为参考。
                                </div>
                            </div>
                        );
                    case "banner":
                        return <MajorBanner major={major} key={index} />;
                    case "subMajorTitle":
                        return (
                            <MajorSubTitle
                                title="专业细分"
                                icon={majorIcon}
                                key={index}
                            />
                        );
                    case "subMajor":
                        return (
                            <MajorSubItem subMajor={section.data} key={index} />
                        );
                    case "subJobTitle":
                        return (
                            <MajorSubTitle
                                title="职业方向"
                                icon={jobIcon}
                                key={index}
                            />
                        );
                    case "subJob":
                        return <MajorJobItem job={section.data} key={index} />;
                    default:
                        return null;
                }
            })}
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    padding-bottom: 50px;
    .section-header {
        margin-bottom: 50px;
    }
    .all-major-title {
        margin-bottom: 15px;
        .text-title {
            font-size: 30px;
            line-height: 43px;
            color: #1b2224;
            margin-bottom: 8px;
            font-weight: bold;
        }
        .text-desc {
            font-size: 14px;
            line-height: 22px;
            color: #47525d;
        }
    }
`;

MajorUltimatePerMajor.propTypes = {
    title: propTypes.string.isRequired,
    major: propTypes.object.isRequired,
    pageSections: propTypes.array.isRequired,
    setMajorsBySubName: propTypes.func.isRequired,
};

export default MajorUltimatePerMajor;
