import React, { Component } from 'react'
import * as Widget from "../../components/widgets"

export default class StudentInfo extends Component {
    render() {
        return (
            <div className="Page StudentInfo">
                <Widget.RawData {...this.props}/>
            </div>
        )
    }
}