import "antd/dist/antd.css";
import "./antdExtend.scss";
import { message } from "antd";

message.config({
    top: 24,
    duration: 3,
    maxCount: 10,
    rtl: false,
    getContainer: () => document.getElementById("root"),
});
