import React from 'react'
import styled from 'styled-components'
import i18n from "../../lib/i18n/i18n"
import Card from '../Card'
import TraitSlider from './TraitSlider'

const TraitItem = styled.div`
    border-bottom: solid 1px #E9F1F5;
    padding: 1em !important;        // fight against a style cascading down from Profile.css
`
const TextColumn = styled.div`
    min-height: 2em;
    padding-right: 2em !important;
`
const TraitName = styled.h3`
    margin-bottom: 0.5em;
    color: #47525D;
`
const TraitDesc = styled.p`
    color: #758CA3;
    line-height: 1.3;
`
const SliderColumn = styled.div`
    &.simple {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }
`

const TraitBar = styled.div`
    background-color: #DDD;
    height: 2em;
    border: 3px solid #DDD;
`

const TraitBarLabels = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    h4 {
        margin: 0;
        padding: 0;
        color: #47525D;
    }
`;

function TraitDescriptions(props) {
    return (
        <Card
            type="traits_detail"
            {...props}
        >
            <div style={{ marginTop:'1em', marginBottom:'2em' }}>
                { props.traits.map(({key, value}) => (
                    <TraitItem className="ui two column grid" key={key}>
                        <TextColumn className="column">
                            <TraitName>{ i18n.t('TRAITS.' + key) }</TraitName>
                            <TraitDesc>{ i18n.t('TRAITS_DESC.' + key) }</TraitDesc>
                        </TextColumn>
                        <SliderColumn className={`column ${props.simple ? 'simple' : ''}`}>
                            {props.simple
                                ? (
                                    <React.Fragment>
                                        <TraitBar
                                            className="trait-bar"
                                            style={{ width: `${Math.floor(value)}%` }}
                                        />
                                        <TraitBarLabels>
                                            <h4>Low</h4>
                                            <h4>Med</h4>
                                            <h4>High</h4>
                                        </TraitBarLabels>
                                    </React.Fragment>
                                )
                                : <TraitSlider value={ value }/>
                            }
                        </SliderColumn>
                    </TraitItem>
                ))}
            </div>
        </Card>
    )
}

export default TraitDescriptions;
