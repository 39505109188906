import React, { Component } from "react";
import {
    Col,
    Row,
    Pagination,
    Table,
    message,
    Input as AntInput,
    Radio,
    Modal,
} from "antd";
import { Request, Router } from "lib";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { RippleButton } from "components";
import { i18n } from "lib";
import _ from "lodash";
import styled from "styled-components";
import AutoComplete from "./AutoComplete";
import BindItem from "./BindItem";
import { ApplyStatusInModalTitle } from "components/ApplyStatus";
import datastore from "../../data/store";

const GENDER = {
    female: "女",
    male: "男",
};
class BindStudent extends Component {
    defaultState = {
        visible: false,
        currentPage: 1,
        total: 0,
        pageSize: 10,
        filteredInfo: {},
        data: [],
        step: 1,
        checked: {},
        advisorName:"",
    };
    state = this.defaultState;
    componentDidMount = () => {
        this.init();
    };
    UNSAFE_componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ visible: this.props.visible });
        this.init();
    };
    init = () => {
        Request.GET("advisor/studentinfo/" + this.props.studentId, {
            onSuccess: (res) => {
                let data = res.data;
                if (data.bindUid && data.bindUid._id) {
                    this.setState({
                        studentInfo: data,
                        checked: data.bindUid,
                        step: 4,
                    });
                } else {
                    this.setState({
                        ...this.defaultState,
                        studentInfo: data,
                        visible: this.props.visible,
                    });
                }
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("获取学生数据错误");
            },
        });
    };
    cleaerState = (attr) => {
        if (attr) {
            let tmp = {};
            tmp[attr] = this.defaultState[attr];
            this.setState(tmp);
        } else {
            this.setState(this.defaultState);
        }
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.onSearch();
        });
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.state.filteredInfo,
        };
    };
    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.cleaerState("visible");
        }
    };
    onBack = () => {
        this.setState((state) => {
            state.step = state.step - 1;
            return state;
        });
    };
    onSearch = () => {
        Request.GET(
            "bindstudent/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    _.each(data.data, (v) => {
                        v.key = v._id;
                    });
                    this.setState({
                        data: data.data,
                        currentPage: data.currentPage,
                        total: data.total,
                        step: 2,
                        checked: {},
                    });
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    message.error("系统错误");
                },
            }
        );
    };
    onNext = () => {
        if (this.state.checked._id) {
            this.setState({ step: 3 });
        } else {
            message.error("请选择一个学生");
        }
    };
    onBind = () => {
        Request.POST("unbind", {
            data: {
                bindUid: this.state.checked._id,
            },
            onSuccess: (data) => {
                Request.POST("studentinfo", {
                    data: {
                        update: { bindUid: this.state.checked._id },
                        id: this.state.studentInfo._id,
                    },
                    onSuccess: (data) => {
                        Request.GET("advisor/students", {
                            onSuccess: (studentData) => {
                                datastore.process("STUDENTS", studentData);
                                message.info("绑定成功");
                                // this.cleaerState("visible");
                                this.setState({ step: 4 });
                                this.props.fetchData(true);
                            },
                        });
                    },
                    onFail: (status) => {
                        if (status === 401) return true;
                        message.error("绑定失败");
                    },
                });
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("绑定失败");
            },
        });
    };
    onUnbind = () => {
        Request.POST("studentinfo", {
            data: {
                update: { bindUid: null },
                id: this.state.studentInfo._id,
            },
            onSuccess: (data) => {
                Request.GET("advisor/students", {
                    onSuccess: (studentData) => {
                        datastore.process("STUDENTS", studentData);
                        message.info("解除绑定成功");
                        this.props.fetchData(false);
                    },
                });
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("解除绑定失败");
            },
        });
    };

    onCheck = (e) => {
        if (e.target.checked) {
            this.setState({ checked: { ...e.target.value } });
        }
    };
    getAttr = (obj, attrs) => {
        try {
            _.each(attrs, (v) => {
                obj = obj[v];
            });
            return obj;
        } catch (e) {
            return null;
        }
    };
    showProfile(id) {
        Request.GET("advisor/students", {
            onSuccess: (studentData) => {
                datastore.process("STUDENTS", studentData);
                Router.goTo("/profile/" + id + "/dashboard");
            },
        });
    }
    render() {
        let columns = [
            {
                title: "选择",
                dataIndex: "_id",
                render: (value, record, index) => {
                    return (
                        <Radio
                            checked={
                                this.state.checked._id &&
                                this.state.checked._id === value
                                    ? true
                                    : false
                            }
                            value={record}
                            onChange={this.onCheck}
                        />
                    );
                },
                width: 10,
                align: "center",
            },
            {
                title: "姓名",
                dataIndex: "name",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
                width: 30,
            },
            {
                title: "电话",
                dataIndex: "phoneNumber",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
                width: 30,
            },
            {
                title: "性别",
                dataIndex: "gender",
                render: (value) => {
                    if (value === "male") {
                        return "男";
                    } else if (value === "female") {
                        return "女";
                    } else {
                        return value;
                    }
                },
                width: 20,
            },
            {
                title: "生日",
                dataIndex: "dateOfBirth",
                render: (value) => {
                    return value ? moment(value).format("YYYY-MM-DD") : null;
                },
                width: 20,
            },
        ];
        let SEARCH = (
            <Row align="middle">
                <div style={{ justifyContent: "space-between" }}>
                    测评姓名：
                </div>
                <div
                    style={{ justifyContent: "space-between", width: "100px" }}
                >
                    <AntInput
                        size="small"
                        onChange={(e, v) => {
                            let value = e.target.value;
                            this.setState((state) => {
                                state.filteredInfo.name = {
                                    val: value.trim(),
                                    expression: "like",
                                };
                                return state;
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        justifyContent: "space-between",
                        marginLeft: "15px",
                    }}
                >
                    测评电话：
                </div>
                <div style={{ justifyContent: "space-between" }}>
                    <AntInput
                        size="small"
                        onChange={(e, v) => {
                            let value = e.target.value;
                            this.setState((state) => {
                                state.filteredInfo.phoneNumber = {
                                    val: value.trim(),
                                    expression: "like",
                                };
                                return state;
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        justifyContent: "space-between",
                        marginLeft: "15px",
                    }}
                >
                    顾问:
                </div>
                <div
                    style={{
                        justifyContent: "space-between",
                        marginLeft: "5px",
                    }}
                >
                    <AutoComplete
                        size={"small"}
                        placeholder="请输入顾问名称"
                        style={{ width: "120px" }}
                        onSelect={(option) => {
                            this.setState((state) => {
                                state.filteredInfo.advisor = {
                                    val: option.key,
                                    expression: "eq",
                                };
                                return state;
                            });
                        }}
                        onChange={(value) => {
                            this.setState({ advisorName: value });
                        }}
                        value={this.state.advisorName}
                        allowValue={true}
                        onSearch={(setOptions, searchText) => {
                            Request.GET(
                                "bindadvisor/" + encodeURIComponent(searchText),
                                {
                                    onSuccess: (data) => {
                                        let attr = [
                                            {
                                                key: "",
                                                value: "",
                                                desc: "全部",
                                            },
                                        ];
                                        attr.push();
                                        _.each(data.data, (v) => {
                                            attr.push({
                                                key: v._id,
                                                value: v.name,
                                                desc: v.name,
                                            });
                                        });
                                        setOptions(attr);
                                    },
                                    onFail: (status, err) => {
                                        if (status === 401) return true;
                                        console.error(err);
                                    },
                                }
                            );
                        }}
                    />
                </div>
                <div
                    style={{
                        justifyContent: "space-between",
                        marginLeft: "15px",
                    }}
                >
                    <Button
                        size={"small"}
                        content={i18n.t("APP.SEARCH")}
                        onClick={this.onSearch}
                    />
                </div>
            </Row>
        );
        let COMPARE = (
            <Row
                style={{
                    border: "1px solid #BDBDBD",
                    borderRadius: "10px",
                    padding: "10px 0px 10px 75px",
                }}
            >
                <Col span={12}>
                    <Row style={{ marginTop: "5px" }}>
                        <span style={{ fontSize: "18px" }}>测评信息</span>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>姓名</Tag>
                        {this.state.checked.name} */}
                        <BindItem
                            value={this.state.checked.name}
                            title="姓名"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>电话号码</Tag>
                        {this.state.checked.phoneNumber} */}
                        <BindItem
                            value={this.state.checked.phoneNumber}
                            title="电话号码"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>生日</Tag>
                        {this.state.checked.dateOfBirth
                            ? moment(this.state.checked.dateOfBirth).format(
                                "YYYY-MM-DD"
                            )
                            : null} */}
                        <BindItem
                            value={
                                this.state.checked.dateOfBirth
                                    ? moment(
                                          this.state.checked.dateOfBirth
                                      ).format("YYYY-MM-DD")
                                    : null
                            }
                            title="生日"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>性别</Tag>
                        {this.state.checked.gender} */}
                        <BindItem
                            value={
                                GENDER[this.state.checked.gender]
                                    ? GENDER[this.state.checked.gender]
                                    : this.state.checked.gender
                            }
                            title="性别"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>地址</Tag>
                        {this.state.checked.location} */}
                        <BindItem
                            value={this.state.checked.location}
                            height="66px"
                            title="地址"
                        />
                    </Row>
                </Col>
                <Col span={12}>
                    <Row style={{ marginTop: "5px" }}>
                        <span style={{ fontSize: "18px" }}>申请信息</span>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>姓名</Tag>
                        {this.getAttr(this.state.studentInfo, ["name"])} */}
                        <BindItem
                            value={this.getAttr(this.state.studentInfo, [
                                "name",
                            ])}
                            title="姓名"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>电话号码</Tag>
                        {this.getAttr(this.state.studentInfo, [
                            "contacts",
                            0,
                            "phone",
                        ])} */}
                        <BindItem
                            value={this.getAttr(this.state.studentInfo, [
                                "contacts",
                                0,
                                "phone",
                            ])}
                            title="电话号码"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <div style={{ width: '100%' }}>
                            <span style={{ color: "#A1A8B3!important" }}>生日</span>
                        </div>
                        <br />
                        <div style={{ width: '100%' }}>
                            <span style={{ borderBottom: '1px solid  rgba(34, 36, 38, 0.1)', width: '200px', display: 'block', fontSize: '1.43rem' }}>
                                {this.getAttr(this.state.studentInfo, ["birthday"])
                                    ? moment(
                                        this.getAttr(this.state.studentInfo, [
                                            "birthday",
                                        ])
                                    ).format("YYYY-MM-DD")
                                    : null}
                            </span>
                        </div> */}
                        <BindItem
                            value={
                                this.getAttr(this.state.studentInfo, [
                                    "birthday",
                                ])
                                    ? moment(
                                          this.getAttr(this.state.studentInfo, [
                                              "birthday",
                                          ])
                                      ).format("YYYY-MM-DD")
                                    : null
                            }
                            title="生日"
                        />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>性别</Tag> */}
                        <BindItem value={null} title="性别" />
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        {/* <Tag>地址</Tag>
                        {this.getAttr(this.state.studentInfo, ["homeAddr"])} */}
                        <BindItem
                            value={this.getAttr(this.state.studentInfo, [
                                "homeAddr",
                            ])}
                            title="地址"
                            height="66px"
                        />
                    </Row>
                </Col>
            </Row>
        );
        return (
            <Modal
                visible={this.state.visible}
                title={
                    <ApplyStatusInModalTitle
                        studentName={this.props.studentName}
                        statusCode={this.props.applyStatus}
                        titleText={
                            this.state.step === 4 ? "已绑定" : "确认绑定"
                        }
                    />
                }
                centered={true}
                onCancel={this.onCancel}
                width="750px"
                footer={(() => {
                    switch (this.state.step) {
                        case 1:
                            return [
                                <RippleButton
                                    empty
                                    flat
                                    text={i18n.t("APP.CANCEL")}
                                    onClick={this.onCancel}
                                />,
                            ];

                        case 2:
                            return [
                                <RippleButton
                                    empty
                                    flat
                                    text={i18n.t("APP.CANCEL")}
                                    onClick={this.onCancel}
                                />,
                                <Button
                                    content={i18n.t("APP.NEXT")}
                                    onClick={this.onNext}
                                />,
                            ];

                        case 3:
                            return [
                                <RippleButton
                                    empty
                                    flat
                                    text={i18n.t("APP.BACK")}
                                    onClick={this.onBack}
                                />,
                                <Button
                                    content={i18n.t("APP.BIND")}
                                    onClick={this.onBind}
                                    color={"teal"}
                                />,
                            ];
                        case 4:
                            return [
                                <RippleButton
                                    empty
                                    flat
                                    text={i18n.t("APP.SHOWPROFILE")}
                                    onClick={() => {
                                        this.showProfile(
                                            this.state.checked._id
                                        );
                                    }}
                                />,
                                <Button
                                    content={i18n.t("APP.UNBIND")}
                                    onClick={this.onUnbind}
                                />,
                            ];
                        default:
                            return null;
                    }
                })()}
            >
                <StyledEnroll>
                    {this.state.step === 1 || this.state.step === 2
                        ? SEARCH
                        : null}
                    {(() => {
                        switch (this.state.step) {
                            case 1:
                                return null;
                            case 2:
                                return (
                                    <>
                                        <Row style={{ marginTop: "1vh" }}>
                                            <Table
                                                style={{ width: "670px" }}
                                                size="small"
                                                dataSource={this.state.data}
                                                columns={columns}
                                                bordered={true}
                                                scroll={{
                                                    x: "100px",
                                                    y: "75vh",
                                                }}
                                                pagination={false}
                                                showSizeChanger={false}
                                            />
                                            <div style={{ margin: "auto" }}>
                                                <Pagination
                                                    showQuickJumper
                                                    defaultCurrent={1}
                                                    current={
                                                        this.state.currentPage
                                                    }
                                                    onChange={this.pageChange}
                                                    total={this.state.total}
                                                    pageSize={
                                                        this.state.pageSize
                                                    }
                                                    showSizeChanger={false}
                                                    style={{
                                                        marginTop: "10px",
                                                        width: "100%",
                                                    }}
                                                />
                                            </div>
                                        </Row>
                                    </>
                                );
                            case 3:
                                return COMPARE;
                            case 4:
                                return COMPARE;
                            default:
                                return null;
                        }
                    })()}
                </StyledEnroll>
            </Modal>
        );
    }
}

const StyledEnroll = styled.div`
    position: relative;
    .upload-modal .ant-upload-list-item-card-actions {
        display: none;
    }
    .upload-modal .ant-upload-list {
        display: inline-flex;
        align-items: center;
    }

    .upload-modal .ant-upload-list-item-name {
        text-overflow: ellipsis;
        max-width: 240px;
    }
`;

export default BindStudent;
