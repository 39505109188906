import _ from "lodash";
import traitsMeta from "./trait.json";

const utils = {
    parseTrait: function (traits, lowRankingBoundary, highRankingBoundary) {
        const parsedTraits = [];
        _.each(traits, function (score, key) {
            const mataTrait = traitsMeta[key];
            let level = "";
            if (score >= highRankingBoundary) {
                level = "A";
            } else if (
                score >= lowRankingBoundary &&
                score < highRankingBoundary
            ) {
                level = "B";
            } else {
                level = "C";
            }

            parsedTraits.push({
                type: mataTrait.name,
                desc: mataTrait.definition,
                score,
                suggestion: (mataTrait.level || {})[level],
                // category: "能力基础",
            });
        });
        return parsedTraits;
    },

    // major_recommendation is responsed by API
    getTop5MajorsNew: function (major_recommendation) {
        const majorArray = _.map(major_recommendation, function (value, key) {
            return {
                key: key,
                score: value,
            };
        });
        let sortedMajors = _.sortBy(majorArray, ["score"]).reverse();
        sortedMajors = sortedMajors.slice(
            0,
            process.env.REACT_APP_MAJOR_DISPLAY_COUNT || 5
        );
        return sortedMajors;
    },

    getMajorUltimatePages: function (major, isShowAllTitle) {
        // 1122(A4 px in 96dpi) - 40(header) - 50(top padding)
        const ALLOW_HEIGHT = 1122 - 40 - 50;
        const a4Pages = [];

        let page = [
            {
                type: "banner",
                height: 165, // 120 + 45,
            },
            {
                type: "subMajorTitle",
                height: 50, // 20 + 30
            },
        ];

        if (isShowAllTitle) {
            page.unshift({
                type: "allTitleSection",
                height: 110, // 95 + 15,
            });
        }

        let restHeight = ALLOW_HEIGHT - 165 - 50;
        if (isShowAllTitle) {
            restHeight = restHeight - 110;
        }
        _.each(major.subs, function (item) {
            const itemHeight =
                30 + // title
                40 + // rate
                50 + // margin-bottom
                // 56 char one row
                Math.ceil(item.target?.length / 56) * 20 +
                Math.ceil(item.course?.length / 56) * 20;

            // 30 is the buffer, since the margin-bottom is 50
            if (restHeight - itemHeight < 30) {
                // need a new page
                a4Pages.push([...page]);
                page = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - itemHeight;
            page.push({
                type: "subMajor",
                height: itemHeight,
                data: item,
            });
        });

        // sub title2 begin
        // subTitle height is 50, padding bottom is 50
        if (restHeight < 100) {
            // need a new page
            a4Pages.push([...page]);
            page = [];
            restHeight = ALLOW_HEIGHT;
        }

        restHeight = restHeight - 50;
        page.push({
            type: "subJobTitle",
            height: 50,
        });
        // sub title2 end

        _.each(major.jobs, function (item) {
            let contentHeight = 0;
            _.each((item.content || "").split("\r\n"), function (oneContent) {
                contentHeight =
                    contentHeight + Math.ceil(oneContent.length / 56) * 20;
            });

            const qualificationHeight =
                major.qualification && major.qualification !== "none" ? 55 : 0;

            const itemHeight =
                35 + // title
                84 + // picture
                50 + // margin-bottom
                contentHeight +
                qualificationHeight;

            // 30 is the buffer, since the margin-bottom is 50
            if (restHeight - itemHeight < 30) {
                // need a new page
                a4Pages.push([...page]);
                page = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - itemHeight;
            page.push({
                type: "subJob",
                height: itemHeight,
                data: item,
            });
        });

        a4Pages.push([...page]);

        return a4Pages;
    },

    // type: mataTrait.name,
    // desc: mataTrait.definition,
    // score,
    // suggestion: (mataTrait.level || {})[level],
    getPagesByTraitData: function (traits) {
        // 1122(A4 px in 96dpi) - 40(header) - 50(top padding) - 40(footer)
        const ALLOW_HEIGHT = 1122 - 40 - 50 - 40;
        const a4Pages = [];

        let pageSections = [];
        let restHeight = ALLOW_HEIGHT;

        // partTitle begin
        pageSections.push({
            type: "partTitle",
            height: 75, // 43 + 32,
        });
        restHeight = restHeight - 75;
        // partTitle end

        _.each(traits, function (trait) {
            // sub part 1
            const nameHeight = 54; // 38 + 16

            if (restHeight - nameHeight < 0) {
                // need a new page
                a4Pages.push([...pageSections]);
                pageSections = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - nameHeight;
            pageSections.push({
                type: "name",
                height: nameHeight,
                trait,
            });

            // sub part 2
            // 26 is "定义", 22+4; 49 is one row chars number, 22 is line-height
            const descHeight = 26 + Math.ceil(trait.desc.length / 49) * 22 + 24; // 24 is margin-bottom

            if (restHeight - descHeight < 0) {
                // need a new page
                a4Pages.push([...pageSections]);
                pageSections = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - descHeight;
            pageSections.push({
                type: "desc",
                height: descHeight,
                trait,
            });

            // sub part 3
            // 14(top label)+8(margin)+20(bar)+24(margin)
            const barHeight = 66;

            if (restHeight - barHeight < 0) {
                // need a new page
                a4Pages.push([...pageSections]);
                pageSections = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - barHeight;
            pageSections.push({
                type: "bar",
                height: barHeight,
                trait,
            });

            // sub part 4
            // 30 is "发展建议", 22+8; 49 is one row chars number, 22 is line-height
            if (trait.suggestion?.length) {
                const suggestHeight =
                    30 + Math.ceil(trait.suggestion.length / 49) * 22 + 50; // 50 is margin-bottom

                // 20 is the buffer, since the margin-bottom is 50
                if (restHeight - suggestHeight < -20) {
                    // need a new page
                    a4Pages.push([...pageSections]);
                    pageSections = [];
                    restHeight = ALLOW_HEIGHT;
                }

                restHeight = restHeight - suggestHeight;
                pageSections.push({
                    type: "suggest",
                    height: suggestHeight,
                    trait,
                });
            }
        });

        a4Pages.push([...pageSections]);

        return a4Pages;
    },
};

export default utils;
