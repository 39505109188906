import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import propTypes from "prop-types";
import Cover from "./Cover";
import TOC from "./TOC";
import BackCover from "./BackCover";
import PartTier from "./tier/PartTier";
import PartTrait from "./trait/PartTrait";
import PartCompetence from "./competence/PartCompetence";
import PartMajor from "./major/PartMajor";
import PartSchool from "./school/PartSchool";
import reportContext from "../reportContext";

function OverseasReport({ reportData }) {
    const [pageNoByParts, setPageNoByParts] = useState({});
    if (!reportData) {
        return null;
    }

    const reportParts = [];
    if (
        !_.isUndefined(reportData.student.assigned_tier) &&
        !_.isEmpty(reportData.scores)
    ) {
        reportParts.push({
            key: "tier",
            title: "推荐套餐",
            Component: PartTier,
        });
    }

    if (!_.isEmpty(reportData.traits)) {
        reportParts.push({
            key: "trait",
            title: "行为特征报告",
            Component: PartTrait,
        });
    }

    if (
        !_.isEmpty(reportData.competence) &&
        !_.isEmpty(reportData.competence_traits)
    ) {
        reportParts.push({
            key: "competence",
            title: "全球胜任力报告",
            Component: PartCompetence,
        });
    }

    if (!_.isEmpty(reportData.major_recommendation)) {
        reportParts.push({
            key: "major",
            title: "专业推荐",
            Component: PartMajor,
        });
    }

    if (!_.isEmpty(reportData.school_recommendation)) {
        reportParts.push({
            key: "school",
            title: "美国学校推荐",
            Component: PartSchool,
        });
    }

    return (
        <reportContext.Provider
            value={{
                pageNoByParts,
                setPageNoByParts,
                isPageNoReady:
                    reportParts.length === _.keys(pageNoByParts).length,
            }}
        >
            <StyledContainer>
                <Cover reportData={reportData} />
                <TOC reportParts={reportParts} />
                {_.map(reportParts, function (item, index) {
                    return (
                        <item.Component
                            key={index}
                            reportData={reportData}
                            partOrder={index + 1}
                            part={item}
                        />
                    );
                })}

                <BackCover />
            </StyledContainer>
        </reportContext.Provider>
    );
}
const StyledContainer = styled.div.attrs({
    className: "overseas-report",
})`
    @media print {
        thead {
            display: table-header-group !important;
        }
        tfoot {
            display: table-footer-group !important;
        }
    }
    .header-space,
    .footer-space {
        height: 40px;
    }
`;

OverseasReport.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default OverseasReport;
