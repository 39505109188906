import React, { Component } from 'react'
import { i18n } from "../../lib"
import { Portal, Segment } from 'semantic-ui-react'

class Undo extends Component {
    componentWillMount(){
        const timeout = this.props.timeout || 5;
        this.expiration = setTimeout(this.props.close, timeout * 1000);
    }
    componentWillUnmount(){
        clearTimeout(this.expiration);
    }
    render(){
        const { message, close, undo } = this.props;
        return (
            <Portal 
                open
                onClose={close}
            >
                <Segment className='Undo'>
                    <span className="message">{message}</span>
                    <span className="undo_action" onClick={undo}>
                        {i18n.t('APP.UNDO')}
                    </span>
                    <div className="icon close" onClick={close}>e</div>
                </Segment>
            </Portal>
        )
    }
}

export default Undo