import React, { useState, useEffect, useRef } from "react";
import utils from "common/utils";
import styled from "styled-components";

function Pager({ callback, isHiddhen }) {
    const [pageNo, setPageNo] = useState(0);
    const locationRef = useRef();
    useEffect(
        function () {
            const pagerHeight = utils.getElementTop(locationRef.current);
            const pageNo = Math.ceil(pagerHeight / 1122.5);
            setPageNo(pageNo);
            if (callback) {
                callback(pageNo);
            }
            // console.log(pagerHeight, "pagerHeight");
        },
        [callback]
    );

    return (
        <StyledContainer ref={locationRef} isHiddhen={isHiddhen}>
            <span className="cpoyright">
                ©{new Date().getFullYear()} Powered by MobLab Inc.
            </span>
            <span className="pager-no">{pageNo}</span>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "report-pager",
})`
    visibility: ${({ isHiddhen }) => (isHiddhen ? "hidden" : "visible")};
    page-break-after: avoid;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
    height: 15px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    .cpoyright {
        color: #b0b5ba;
        font-size: 11px;
        margin-left: 20px;
    }
    .pager-no {
        color: #47525d;
        font-size: 11px;
        margin-right: 20px;
    }
`;

export default Pager;
