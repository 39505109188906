import React, { useState, useEffect, useContext } from "react";
import { Spin, message } from "antd";
import _ from "lodash";
import propTypes from "prop-types";
import FilterContext from "./FilterContext";
import TabPlus from "./TabPlus";
import DashboardUtils from "./DashboardUtils";

function AdminTab({ isCurrentTab, tabKey }) {
    let { filter } = useContext(FilterContext);
    const [loading, setLoading] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [tables, setTables] = useState([]);
    const [isAllDepartment, setIsAllDepartment] = useState(true);

    useEffect(() => {
        if (!isCurrentTab) {
            return;
        }

        setLoading(true);
        if (filter && filter.departmentId) {
            DashboardUtils.fetchJSON(
                `dashboard/advisorSummary?status=[4]`,
                _.omit(filter, ["advisor", "academy"])
            )
                .then(function (resp) {
                    setLoading(false);
                    const { advisors, tables } = resp;
                    setAdvisors(advisors);
                    setTables(tables);
                    setIsAllDepartment(false);
                })
                .catch(function (err) {
                    setLoading(true);
                    message.error(String(err));
                });
        } else {
            DashboardUtils.fetchJSON(
                `dashboard/departmentSummary?status=[4]&deps=${
                    tabKey === "6" ? "[%22department%22]" : "[%22company%22]"
                }`,
                _.omit(filter, ["advisor", "academy"])
            )
                .then(function (resp) {
                    setLoading(false);
                    const { departments, tables } = resp;
                    setAdvisors(departments);
                    setTables(tables);
                    setIsAllDepartment(true);
                })
                .catch(function (err) {
                    setLoading(true);
                    message.error(String(err));
                });
        }
    }, [filter, isCurrentTab, tabKey]);

    return (
        <Spin size="large" tip="Loading..." spinning={loading}>
            <TabPlus
                advisors={advisors}
                tables={tables}
                isAllDepartment={isAllDepartment}
            />
        </Spin>
    );
}

AdminTab.propTypes = {
    isCurrentTab: propTypes.bool.isRequired,
    tabKey: propTypes.string.isRequired,
};

export default AdminTab;
