import React, { Component } from "react";
import datastore from "../data/store";
import { SortDirection } from "../data/enum";
import { i18n } from "../lib";
import { filterByQuery, paginate } from "../lib/utils";
import { FilterSelect, RippleButton, UserSearch } from "../components";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import { StudentUtil } from "lib/utils";

import "../scss/InteractiveTable.scss";

const UP = SortDirection.UP;
const DOWN = SortDirection.DOWN;
const ITEMS_PER_PAGE = 10;

export default class InteractiveTable extends Component {
    state = {
        search: null,
        sortKey: "",
        sortDirection: "",
        filters: [],
        dataMap: null,
        page: 1,
    };
    constructor() {
        super();
        this.enableFilter = this.enableFilter.bind(this);
        this.disableFilter = this.disableFilter.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    componentDidMount() {
        const { tableKey, filters } = this.props;
        const dataMap = {};

        for (var i in this.props.data) {
            const entry = this.props.data[i];
            dataMap[entry.id || entry._id] = entry;
        }

        if (this.props.sortable === false) {
            this.setState({ sortable: false, filters, dataMap });
        } else {
            this.setState({
                sortable: true,
                filters,
                dataMap,
                sortKey: datastore.get(tableKey + "_SORT").key,
                sortDirection: datastore.get(tableKey + "_SORT").direction,
            });
        }
    }
    enableFilter(value) {
        if (this.state.filters[value].active) {
            return;
        }
        var filters = Array.from(this.state.filters);
        filters[value].active = true;
        this.setState({ filters });
    }
    disableFilter(value) {
        if (!this.state.filters[value].active) {
            return;
        }
        var filters = Array.from(this.state.filters);
        filters[value].active = false;
        this.setState({ filters });
    }
    sort(key) {
        const { tableKey } = this.props;
        const { sortKey, sortDirection, sortable } = this.state;

        if (!sortable) {
            return;
        }

        if (sortKey === key) {
            const newDirection = sortDirection === UP ? DOWN : UP;
            datastore.get(tableKey + "_SORT").direction = newDirection;
            this.setState({ sortDirection: newDirection });
        } else {
            datastore.set(tableKey + "_SORT", { key: key, direction: UP });
            this.setState({
                sortKey: key,
                sortDirection: UP,
            });
        }
    }
    back() {
        this.setState({ page: this.state.page - 1 });
    }
    next() {
        this.setState({ page: this.state.page + 1 });
    }
    getData() {
        const {
            dataMap,
            filters,
            sortKey,
            sortDirection,
            search,
            sortable,
        } = this.state;

        var data = this.props.data;
        if (!dataMap) {
            return [];
        }
        if (search) {
            data = search.map(({ id }) => dataMap[id]);
        }
        data = _.sortBy(data, [sortKey]);

        if (sortable) {
            if (sortDirection === DOWN) {
                data.reverse();
            }
        }

        if (filters) {
            const { filters } = this.props;
            for (var f in filters) {
                if (!filters[f].active) {
                    continue;
                }
                data = filterByQuery(data, filters[f]);
            }
        }
        return data;
    }
    onSelect(id) {
        if (this.props.hasOwnProperty("onSelect")) {
            this.props.onSelect(id);
        }
    }
    onSave(id) {
        if (this.props.hasOwnProperty("onSave")) {
            this.props.onSave(id);
        }
    }
    onSearchChange(results) {
        if (this.props.searchTable) {
            this.setState({ search: results, page: 1 });
        }
    }
    validateCell(key, val) {
        if (this.props.validate) {
            return this.props.validate(key, val);
        }
        return true;
    }
    renderCell(data, schema) {
        const { tableKey, editable, onUpdate } = this.props;

        //special handler for kind
        if (schema?.key === "kind") {
            return (
                <select
                    className={"kind-select"}
                    value={data.kind || ""}
                    onChange={({ target }) => {
                        onUpdate(data.id, "kind", target.value);
                    }}
                    style={{ width: 80 }}
                >
                    <option key={"VS"} value={"VS"}>
                        留学
                    </option>
                    <option key={"GK"} value={"GK"}>
                        高考
                    </option>
                </select>
            );
        }

        if (editable) {
            const { key, type, options, hide } = schema;
            const val = data[key];
            const validState = this.validateCell(key, val) ? "" : " invalid";
            if (hide) {
                return <></>;
            }
            switch (type) {
                case "static":
                    return val;
                case "select":
                    const placeholderText = i18n.t(
                        tableKey + ".PLACEHOLDER_" + key.toUpperCase()
                    );
                    return (
                        <select
                            className={
                                "subject" + (val ? "" : " empty") + validState
                            }
                            value={val || ""}
                            onChange={({ target }) => {
                                onUpdate(data.id, key, target.value);
                            }}
                        >
                            <option value="" disabled>
                                {placeholderText}
                            </option>
                            {options.map(({ label, value }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    );
                default:
                    const dataKey = key;
                    return (
                        <input
                            className={validState}
                            type={type}
                            value={val}
                            required={true}
                            onChange={({ target }) => {
                                onUpdate(data.id, dataKey, target.value);
                            }}
                            onKeyDown={({ key, target }) => {
                                if (key === "Enter") {
                                    onUpdate(data.id, dataKey, target.value);
                                }
                            }}
                        />
                    );
            }
        } else {
            const staticKey = schema.key;
            switch (staticKey) {
                case "name":
                    return data.name;
                case "report":
                    return getBoolIcon(data.report);
                case "assigned_tier":
                    return data.kind === "GK"
                        ? "N/A"
                        : StudentUtil.getTierName(data[staticKey]);
                case "kind":
                    return data.kind === "GK" ? "高考" : "留学";
                default:
                    return i18n.t(
                        tableKey +
                            "." +
                            geti18nKey(staticKey) +
                            "_" +
                            data[staticKey]
                    );
            }
        }
    }
    render() {
        // console.log("RENDER STUDENTS: ", this.state);
        const { filters, sortKey, sortDirection, sortable, page } = this.state;
        const {
            tableKey,
            model,
            title,
            icon,
            emptyMsg,
            addMsg,
            cutoff,
            addNew,
            onDelete,
            searchSource,
            editable,
        } = this.props;
        const itemsPerPage = this.props.itemsPerPage || ITEMS_PER_PAGE;
        const data = this.getData();
        const noData = this.props.data.length === 0;
        const { start, end, results, canGoNext } = paginate(
            data,
            page,
            itemsPerPage
        );
        // console.log("RESULTS: ", data, results, start, end, page, itemsPerPage);

        return (
            <div className="InteractiveTable">
                {title && (
                    <h1 className="ui header">
                        <p className="icon">{icon}</p>
                        {title}
                    </h1>
                )}
                {
                    <div className="right-ui">
                        {addMsg && (
                            <RippleButton
                                className="add_new"
                                fillFrom="left"
                                icon=")"
                                text={addMsg}
                                onClick={addNew}
                            />
                        )}
                        {searchSource && (
                            <UserSearch
                                source={searchSource}
                                onSelect={this.onSelect}
                                onSearchChange={this.onSearchChange}
                            />
                        )}
                    </div>
                }
                {filters && (
                    <FilterSelect
                        filters={filters}
                        cutoff={cutoff}
                        add={this.enableFilter}
                        remove={this.disableFilter}
                    />
                )}
                {noData && <h2>{emptyMsg}</h2>}
                {!noData && (
                    <Table className={editable ? "editable" : ""}>
                        <Table.Header>
                            <Table.Row>
                                {onDelete && <th />}
                                {model.map(({ key, hide }) => {
                                    return hide ? (
                                        <></>
                                    ) : (
                                        <Table.HeaderCell
                                            key={key}
                                            className={
                                                sortable
                                                    ? sortKey === key
                                                        ? sortDirection
                                                        : ""
                                                    : "disabled"
                                            }
                                            onClick={() => {
                                                this.sort(key);
                                            }}
                                        >
                                            {i18n.t(
                                                tableKey +
                                                    "." +
                                                    key.toUpperCase()
                                            )}
                                            <div className="icon arrow" />
                                        </Table.HeaderCell>
                                    );
                                })}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {results.map((data) => (
                                <Table.Row
                                    key={data.id}
                                    onBlur={() => {
                                        this.onSave(data.id);
                                    }}
                                    onClick={(e) => {
                                        if (
                                            e?.target?.className ===
                                            "kind-select"
                                        ) {
                                            return;
                                        }
                                        this.onSelect(data.id);
                                    }}
                                >
                                    {onDelete && (
                                        <Table.Cell
                                            className="trash"
                                            onClick={() => onDelete(data.id)}
                                        >
                                            <div className="icon">b</div>
                                        </Table.Cell>
                                    )}
                                    {model.map((schema, i) => (
                                        <Table.Cell key={data.id + i}>
                                            {this.renderCell(data, schema)}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
                {data.length > itemsPerPage && (
                    <div className={"pagination"}>
                        <div className="range">
                            {i18n.t("APP.RANGE", {
                                range: start + 1 + "-" + end,
                                total: data.length,
                            })}
                        </div>
                        <RippleButton
                            className="back"
                            disabled={page === 1}
                            icon="!"
                            flat
                            onClick={this.back}
                        />
                        <RippleButton
                            className="next"
                            disabled={!canGoNext}
                            icon='"'
                            flat
                            onClick={this.next}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function getBoolIcon(bool) {
    return (
        <i className={"icon " + (bool ? "yes" : "no")}>{bool ? "B" : "2"}</i>
    );
}
function geti18nKey(key) {
    return key.replace("assigned_", "").toUpperCase();
}
