import React, { useContext } from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as BottomSvg } from "img/report/toc/toc-lowerDots.svg";
import reportContext from "../reportContext";

function TOC({ majorsBySubName }) {
    const { aboutPageNo, traitPageNo, majorPageNo } = useContext(reportContext);

    let totalTocItem = 3;

    const itemRows = Math.ceil(totalTocItem / 3);
    const initItems = [
        {
            title: "关于这份报告",
            desc: "报告简介",
            pageNo: aboutPageNo,
            anchor: "#toc-about",
        },
        {
            title: "素质能力",
            desc: "素质能力详解",
            pageNo: traitPageNo,
            anchor: "#toc-trait",
        },
        {
            title: "专业推荐",
            desc: "概览",
            pageNo: majorPageNo,
            anchor: "#toc-major",
        },
    ];

    return (
        <StyledContainer>
            <div className="toc-sidebar">
                <div className="title">内容</div>
                <div className="desc">本报告内容及所在位置</div>
            </div>
            <div className="toc-body">
                {_.map(initItems, function (item, index) {
                    return (
                        <div className="toc-item" key={index}>
                            <div className="page-no">
                                <a href={item.anchor}>
                                    {String(item.pageNo).padStart(2, "0")}
                                </a>
                            </div>
                            <div className="section-title">{item.title}</div>
                            <div className="section-desc">{item.desc}</div>
                            {itemRows > 1 ? (
                                <div className="section-dot"></div>
                            ) : null}
                        </div>
                    );
                })}
                {_.map(majorsBySubName, function (item, index) {
                    return (
                        <div className="toc-item" key={index}>
                            <div className="page-no">
                                <a href={`#toc-major-${item.majorKey}`}>
                                    {String(
                                        item.pageNo + majorPageNo + 1
                                    ).padStart(2, "0")}
                                </a>
                            </div>
                            <div className="section-title">专业与职业推荐</div>
                            <div className="section-desc">{item.subName}</div>
                            {itemRows > 1 ? (
                                <div className="section-dot"></div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
            <BottomSvg className="toc-footer" />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    display: flex;
    .toc-sidebar {
        width: 160px;
        height: 100%;
        border-radius: 0px 32px 0px 0px;
        background-color: #ee2a49;
        position: relative;
        z-index: 2;
        padding: 30px 10px 0 20px;
        .title {
            color: #fff;
            font-size: 28px;
            font-weight: bold;
        }
        .desc {
            color: #f0f3f5;
            font-size: 12px;
            line-height: 16px;
        }
    }
    .toc-body {
        width: calc(100% - 160px);
        height: 100%;
        padding: 30px 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 130px 130px 130px;
        column-gap: 25px;
        grid-column-gap: 25px;
        row-gap: 25px;
        grid-row-gap: 25px;
        .toc-item {
            height: 140px;
            width: 140px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            .page-no {
                a {
                    color: #47525d;
                    font-weight: bold;
                    font-size: 36px;
                }
            }
            .section-title {
                color: #1b2224;
                font-size: 14px;
            }
            .section-desc {
                color: #838c96;
                font-size: 12px;
                text-align: center;
            }
            .section-dot {
                width: 8px;
                height: 8px;
                background-color: #e1e3e6;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1000;
                border-radius: 8px;
            }
        }
    }

    .toc-footer {
        position: absolute;
        left: 65px;
        bottom: -15px;
        height: 233px;
        width: 208px;
        transform: rotate(-90deg);
        z-index: 1;
    }
`;

TOC.propTypes = {
    // hasTraitData: propTypes.bool.isRequired,
    // hasMajorData: propTypes.bool.isRequired,
    majorsBySubName: propTypes.array.isRequired,
};

export default TOC;
