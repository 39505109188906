export default [
    {
        id: "138492014812",
        name: "Jerry Ringo",
        tier: -1,
        assigned_tier: -1,
        archived: false,
        scores: {},
        traits: {},
        raw_data: {},
        sessions: ["FALL_2018, WINTER_2018"],
        gender: "Male",
        location: "Los Angeles, CA, USA",
        dob: "09/19/1990"
    },{
        id: "138492011739",
        name: "Timothy McTommison",
        tier: 0,
        assigned_tier: -1,
        archived: false,
        scores: {
            "academic_ability": 95,
            "application_readiness": 78,
            "career_focus": 92,
            "language_aptitude": 84
        },
        traits: {
            "grit": 80,
            "risk_aversion": 35,
            "confidence": 28,
            "competitiveness": 94,
            "fairness": 56,
            "strategic_thinking": 83,
            "problem_solving": 80,
            "self_control": 78,
            "procrastination": 30,
            "trust": 62,
            "extraversion": 91,
            "agreeableness": 43,
            "conscientiousness": 38,
            "calmness": 92,
            "openness": 68
        },
        raw_data: {
            "Grade": { responseIndex: 1, actualResponse: '初二', score: 0.8 },
            "Entrance Exam": { responseIndex: '12,45', actualResponse: '12,45', score: 0 },
            "Class Ranking": { responseIndex: 1, actualResponse: '前10%', score: 0.9 },
            "School Ranking": { responseIndex: 3, actualResponse: '前50%', score: 0.5 },
            "School Type": { responseIndex: 1, actualResponse: '市重点', score: 0.67 },
            "SAT": { responseIndex: 1, actualResponse: '1450+', score: 0.9 },
            "SAT confidence": { responseIndex: 1, actualResponse: 1, score: 0.2 },
            "TOEFL": { responseIndex: 1, actualResponse: '100+', score: 0.8 },
            "TOEFL confidence": { responseIndex: 7, actualResponse: 7, score: 0.8 },
            "Relative Study Abroad": { responseIndex: [ 1 ], actualResponse: '有的，兄弟姐妹', score: 1 },
            "School List?": { responseIndex: 1, actualResponse: '没有', score: 0 },
            "Travel Abroad": { responseIndex: 2, actualResponse: '2-5小时', score: 0.6 },
            "Campus Tour": { responseIndex: 2, actualResponse: '5-10小时', score: 0.5 },
            "Parents Expectations": { responseIndex: 0, actualResponse: '有', score: 1 },
            "Been Abroad": { responseIndex: 2, actualResponse: '4-6次', score: 0.7 },
            "Toured Schools": { responseIndex: 2, actualResponse: '有，4-6次', score: 0.7 },
            "Expectations": { responseIndex: 7, actualResponse: 7, score: 0.8 },
            "Major Scale": { responseIndex: 6, actualResponse: 6, score: 0 },
            "Major": { responseIndex: 3, actualResponse: '艺术/音乐', score: 1 },
            "Subject": { responseIndex: 3, actualResponse: '物理', score: 1 },
            "AP": { responseIndex: [ 2 ], actualResponse: 'AP艺术', score: 1 }
        },
        sessions: ["FALL_2018, WINTER_2018"],
        gender: "Male",
        location: "Los Angeles, CA, USA",
        dob: "09/19/1990"
    }, {
        id: "134920153874",
        name: "Samantha Mordoralicus",
        tier: 1,
        assigned_tier: -1,
        archived: false,
        scores: {
            "academic_ability": 82,
            "application_readiness": 78,
            "career_focus": 77,
            "language_aptitude": 68
        },
        traits: {
            "grit": 80,
            "risk_aversion": 35,
            "confidence": 28,
            "competitiveness": 94,
            "fairness": 56,
            "strategic_thinking": 83,
            "problem_solving": 80,
            "self_control": 78,
            "procrastination": 30,
            "trust": 62,
            "extraversion": 91,
            "agreeableness": 43,
            "conscientiousness": 38,
            "calmness": 92,
            "openness": 68
        },
        raw_data: {
            "Grade": { responseIndex: 1, actualResponse: '初二', score: 0.8 },
            "Entrance Exam": { responseIndex: '12,45', actualResponse: '12,45', score: 0 },
            "Class Ranking": { responseIndex: 1, actualResponse: '前10%', score: 0.9 },
            "School Ranking": { responseIndex: 3, actualResponse: '前50%', score: 0.5 },
            "School Type": { responseIndex: 1, actualResponse: '市重点', score: 0.67 },
            "SAT": { responseIndex: 1, actualResponse: '1450+', score: 0.9 },
            "SAT confidence": { responseIndex: 1, actualResponse: 1, score: 0.2 }
        },
        sessions: ["FALL_2018"],
        gender: "Male",
        location: "Los Angeles, CA, USA",
        dob: "09/19/1990"
    }, {
        id: "138492015552",
        name: "Tronald Dump",
        tier: 0,
        assigned_tier: 0,
        archived: false,
        scores: {
            "academic_ability": 95,
            "application_readiness": 78,
            "career_focus": 92,
            "language_aptitude": 84
        },
        traits: {
            "grit": 80,
            "risk_aversion": 35,
            "confidence": 28,
            "competitiveness": 94,
            "fairness": 56,
            "strategic_thinking": 83,
            "problem_solving": 80,
            "self_control": 78,
            "procrastination": 30,
            "trust": 62,
            "extraversion": 91,
            "agreeableness": 43,
            "conscientiousness": 38,
            "calmness": 92,
            "openness": 68
        },
        raw_data: {
            "Grade": { responseIndex: 1, actualResponse: '初二', score: 0.8 },
            "Entrance Exam": { responseIndex: '12,45', actualResponse: '12,45', score: 0 },
            "Class Ranking": { responseIndex: 1, actualResponse: '前10%', score: 0.9 },
            "School Ranking": { responseIndex: 3, actualResponse: '前50%', score: 0.5 },
            "School Type": { responseIndex: 1, actualResponse: '市重点', score: 0.67 },
            "SAT": { responseIndex: 1, actualResponse: '1450+', score: 0.9 },
            "SAT confidence": { responseIndex: 1, actualResponse: 1, score: 0.2 },
            "TOEFL": { responseIndex: 1, actualResponse: '100+', score: 0.8 },
            "TOEFL confidence": { responseIndex: 7, actualResponse: 7, score: 0.8 },
            "Relative Study Abroad": { responseIndex: [ 1 ], actualResponse: '有的，兄弟姐妹', score: 1 },
            "School List?": { responseIndex: 1, actualResponse: '没有', score: 0 },
            "Travel Abroad": { responseIndex: 2, actualResponse: '2-5小时', score: 0.6 },
            "Campus Tour": { responseIndex: 2, actualResponse: '5-10小时', score: 0.5 },
            "Parents Expectations": { responseIndex: 0, actualResponse: '有', score: 1 },
            "Been Abroad": { responseIndex: 2, actualResponse: '4-6次', score: 0.7 },
            "Toured Schools": { responseIndex: 2, actualResponse: '有，4-6次', score: 0.7 },
            "Expectations": { responseIndex: 7, actualResponse: 7, score: 0.8 },
            "Major Scale": { responseIndex: 6, actualResponse: 6, score: 0 },
            "Major": { responseIndex: 3, actualResponse: '艺术/音乐', score: 1 },
            "Subject": { responseIndex: 3, actualResponse: '物理', score: 1 },
            "AP": { responseIndex: [ 2 ], actualResponse: 'AP艺术', score: 1 }
        },
        sessions: ["SUMMER_2018"],
        gender: "Male",
        location: "Los Angeles, CA, USA",
        dob: "09/19/1990"
    }, {
        id: "138492009375",
        name: "Mary Contrary",
        tier: 1,
        assigned_tier: 0,
        archived: 1541958153565,
        scores: {
            "academic_ability": 95,
            "application_readiness": 78,
            "career_focus": 92,
            "language_aptitude": 84
        },
        traits: {
            "grit": 80,
            "risk_aversion": 35,
            "confidence": 28,
            "competitiveness": 94,
            "fairness": 56,
            "strategic_thinking": 83,
            "problem_solving": 80,
            "self_control": 78,
            "procrastination": 30,
            "trust": 62,
            "extraversion": 91,
            "agreeableness": 43,
            "conscientiousness": 38,
            "calmness": 92,
            "openness": 68
        },
        raw_data: {
            "Grade": { responseIndex: 1, actualResponse: '初二', score: 0.8 },
            "Entrance Exam": { responseIndex: '12,45', actualResponse: '12,45', score: 0 },
            "Class Ranking": { responseIndex: 1, actualResponse: '前10%', score: 0.9 },
            "School Ranking": { responseIndex: 3, actualResponse: '前50%', score: 0.5 },
            "School Type": { responseIndex: 1, actualResponse: '市重点', score: 0.67 },
            "SAT": { responseIndex: 1, actualResponse: '1450+', score: 0.9 },
            "SAT confidence": { responseIndex: 1, actualResponse: 1, score: 0.2 },
            "TOEFL": { responseIndex: 1, actualResponse: '100+', score: 0.8 },
            "TOEFL confidence": { responseIndex: 7, actualResponse: 7, score: 0.8 },
            "Relative Study Abroad": { responseIndex: [ 1 ], actualResponse: '有的，兄弟姐妹', score: 1 },
            "School List?": { responseIndex: 1, actualResponse: '没有', score: 0 },
            "Travel Abroad": { responseIndex: 2, actualResponse: '2-5小时', score: 0.6 },
            "Campus Tour": { responseIndex: 2, actualResponse: '5-10小时', score: 0.5 },
            "Parents Expectations": { responseIndex: 0, actualResponse: '有', score: 1 },
            "Been Abroad": { responseIndex: 2, actualResponse: '4-6次', score: 0.7 }
        },
        sessions: ["SUMMER_2018", "FALL_2018, WINTER_2018"],
        gender: "Male",
        location: "Los Angeles, CA, USA",
        dob: "09/19/1990"
    }
]
