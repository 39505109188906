import React, { Component } from "react";
import { i18n } from "../lib";
import { Status } from "../data/enum";
import { Dropdown } from "semantic-ui-react";
import { AdminUtil } from "lib/utils";

/**
 * A contextual dropdown menu for the profile page.
 *
 * Take into account status, page, and progress to determine context
 * Click handlers are all passed in as props.
 */
export default class DotMenu extends Component {
    render() {
        const {
            status,
            page,
            progress,
            showNotes,
            generateLink,
            beginAssignment,
            resetTier,
            archive,
            print,
            isBind,
            isGK,
        } = this.props;
        return (
            <Dropdown text="•••" icon="" direction="right">
                <Dropdown.Menu position="right">
                    {page !== "NOTES" &&
                        !isBind &&
                        !isGK &&
                        AdminUtil.isVisionAccount() && (
                            <Dropdown.Item onClick={showNotes}>
                                {i18n.t("PROFILE.NOTES")}
                            </Dropdown.Item>
                        )}
                    {status === Status.UNLINKED && !isBind && (
                        <Dropdown.Item onClick={generateLink}>
                            {i18n.t("APP.SIGNUP_LINK")}
                        </Dropdown.Item>
                    )}
                    {status === Status.READY_FOR_ASSIGNMENT &&
                        !isBind &&
                        !isGK && (
                            <Dropdown.Item onClick={beginAssignment}>
                                {i18n.t("APP.SET_TIER")}
                            </Dropdown.Item>
                        )}
                    {status === Status.APPLICANT && !isBind && !isGK && (
                        <Dropdown.Item onClick={resetTier}>
                            {i18n.t("APP.RESET_TIER")}
                        </Dropdown.Item>
                    )}
                    {progress > 0 && !isBind && (
                        <Dropdown.Item onClick={generateLink}>
                            {i18n.t("PASSWORD.RESET_LINK")}
                        </Dropdown.Item>
                    )}
                    {!isBind && (
                        <Dropdown.Item onClick={archive}>
                            {i18n.t("APP.ARCHIVE")}
                        </Dropdown.Item>
                    )}
                    {!isGK && (
                        <Dropdown.Item onClick={print}>
                            {i18n.t("APP.PRINT")}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
