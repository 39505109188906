import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ReportHeader from "../ReportHeader";
import Pager from "../Pager";
import ScoreSlide from "report/component/ScoreSlide";

function TraitDynamicPagination({ title, pageSections, traitsThreshold }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            {_.map(pageSections, function (section, index) {
                const type = section.type;

                switch (type) {
                    case "partTitle":
                        return (
                            <div className="part-title" key={index}>
                                {title}
                            </div>
                        );
                    case "name":
                        return (
                            <div className="trait-name" key={index}>
                                <div className="name-color"></div>
                                <div className="name-title">
                                    {section.trait.type}
                                </div>
                            </div>
                        );
                    case "desc":
                        return (
                            <div className="trait-definition" key={index}>
                                <div className="whatis-title">定义</div>
                                <div className="whatis-description">
                                    {section.trait.desc}
                                </div>
                            </div>
                        );
                    case "bar":
                        return (
                            <div className="score-slide-wrapper" key={index}>
                                <ScoreSlide
                                    trait={section.trait}
                                    isRedDot
                                    traitsThreshold={traitsThreshold}
                                    key={index}
                                />
                            </div>
                        );
                    case "suggest":
                        return (
                            <div className="suggestion-row" key={index}>
                                <div className="suggestion-title">发展建议</div>
                                <div className="suggestion-text">
                                    {section.trait.suggestion}
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            })}
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    padding-bottom: 50px;
    .section-header {
        margin-bottom: 50px;
    }

    .part-title {
        margin-left: 42px;
        margin-right: 42px;
        font-weight: bold;
        font-size: 30px;
        line-height: 43px;
        color: #1b2224;
        margin-bottom: 32px;
    }
    .trait-name {
        background-color: #f7f9fa;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 38px;
        position: relative;
        align-items: center;
        margin-bottom: 16px;
        .name-color {
            width: 20px;
            height: 38px;
            background-color: #00b6ff;
        }
        .name-title {
            color: #1b2224;
            font-weight: bold;
            font-size: 18px;
            margin-left: 22px;
        }
    }
    .trait-definition {
        margin-left: 60px;
        margin-right: 42px;
        margin-bottom: 24px;
        .whatis-title {
            font-weight: bold;
            font-size: 14px;
            color: #4386cb;
            line-height: 22px;
            margin-bottom: 4px;
        }
        .whatis-description {
            font-size: 14px;
            line-height: 22px;
            color: #60728e;
        }
    }
    .score-slide-wrapper {
        width: 100%;
        padding-left: 60px;
        padding-right: 42px;
        margin-bottom: 24px;
    }
    .suggestion-row {
        margin-left: 60px;
        margin-right: 42px;
        margin-bottom: 32px;
        .suggestion-title {
            color: #1b2224;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 8px;
            font-weight: bold;
        }

        .suggestion-text {
            font-size: 14px;
            line-height: 22px;
            color: #60728e;
        }
    }
`;

TraitDynamicPagination.propTypes = {
    title: propTypes.string.isRequired,
    pageSections: propTypes.array.isRequired,
};

export default TraitDynamicPagination;
