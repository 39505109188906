import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { produce } from "immer";
import { Form, Row, Col, Input } from "antd";
import { TimeUtil } from "lib/utils";
const { TextArea } = Input;

const config = [
    {
        label: "性别",
        fieldName: "genderValue",
    },
    {
        label: "接案时间",
        fieldName: "caseDate",
    },
    {
        label: "国籍",
        fieldName: "countryCodeValue",
    },
    {
        label: "入学年",
        fieldName: "applyYear",
    },
    {
        label: "入学季度",
        fieldName: "quarterTypeValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "业务类型",
        fieldName: "busiType",
        span: 8,
    },
    {
        label: "合同号",
        fieldName: "contractNo",
        span: 8,
    },
    {
        label: "写作形式",
        fieldName: "writingTypeValue",
    },
    {
        label: "销售顾问",
        fieldName: "saleAdvisorName",
    },
    {
        label: "中期顾问",
        fieldName: "midAdvisorName",
    },
    {
        label: "申请顾问",
        fieldName: "applyAdvisorName",
    },
    {
        label: "文书顾问",
        fieldName: "docAdvisorName",
        span: 8,
    },
    {
        label: "",
        span: 16,
    },
    {
        label: "高中所在国家",
        fieldName: "countryIdValue",
    },
    {
        label: "高中所在省份",
        fieldName: "provinceValue",
    },
    {
        label: "高中所在城市",
        fieldName: "cityValue",
    },
    {
        label: "关系户",
        fieldName: "specialTypeValue",
    },
    {
        label: "暑期课程院校",
        fieldName: "summerCourseSchool",
    },
    {
        label: "备注",
        fieldName: "remark",
        span: 24,
        DisplayComponent: TextArea,
    },
];

function getParsedStudentInfo(studentData) {
    if (studentData.studentInfo && studentData.contractInfo) {
        const contractInfo = studentData.contractInfo;
        return produce(studentData.studentInfo, (draftInfo) => {
            draftInfo.caseDate = TimeUtil.formatDate(contractInfo.caseDate);
            draftInfo.quarterTypeValue = contractInfo.quarterTypeValue;
            draftInfo.writingTypeValue = contractInfo.writingTypeValue;
            draftInfo.countryCodeValue = contractInfo.countryCodeValue;
            draftInfo.countryIdValue = contractInfo.countryIdValue;
            draftInfo.provinceValue = contractInfo.provinceValue;
            draftInfo.cityValue = contractInfo.cityValue;
            draftInfo.specialTypeValue = contractInfo.specialTypeValue;
            draftInfo.applyYear = _.isEqual(contractInfo.applyYear, "5000")
                ? "待定"
                : contractInfo.applyYear;
            draftInfo.saleAdvisorName = contractInfo.saleAdvisorName;
            draftInfo.midAdvisorName = contractInfo.midAdvisorName;
            draftInfo.applyAdvisorName = contractInfo.applyAdvisorName;
            draftInfo.docAdvisorName = contractInfo.docAdvisorName;
            draftInfo.summerCourseSchool = contractInfo.summerCourseSchool;
            draftInfo.remark = contractInfo.remark;
            draftInfo.busiType = contractInfo.contractName;
            draftInfo.contractNo = contractInfo.contractNo;
        });
    } else {
        return {};
    }
}

function SummaryBaseInfo({ studentData }) {
    const [studentInfoData, setStudentInfoData] = useState({});

    useEffect(() => {
        setStudentInfoData(getParsedStudentInfo(studentData));
    }, [studentData, setStudentInfoData]);
    const getFields = useCallback(
        (config) => {
            const children = [];

            _.each(config, function (
                { label, fieldName, span = 8, DisplayComponent },
                index
            ) {
                if (label === "") {
                    children.push(<Col span={span} key={index}></Col>);
                    return;
                }

                children.push(
                    <Col span={span} key={index}>
                        <Form.Item
                            label={label}
                            colon={false}
                            labelAlign="left"
                        >
                            {DisplayComponent ? (
                                <DisplayComponent
                                    defaultValue={studentInfoData[fieldName]}
                                    value={studentInfoData[fieldName]}
                                    readOnly
                                />
                            ) : (
                                <Input
                                    readOnly
                                    defaultValue={studentInfoData[fieldName]}
                                    value={studentInfoData[fieldName]}
                                />
                            )}
                        </Form.Item>
                    </Col>
                );
            });

            return children;
        },
        [studentInfoData]
    );

    return (
        <StyledContainer>
            <header className="section-header">基本信息</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                <Row gutter={24}>{getFields(config)}</Row>
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})``;

SummaryBaseInfo.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryBaseInfo;
