import React, { Component } from 'react'
import { i18n } from "../../lib"
import { rem } from "../../lib/utils"
import { Button, Modal, Form } from 'semantic-ui-react'
import '../../scss/Modal.scss';

class Feedback extends Component {
    state = {
        canSubmit: false
    }
    componentWillMount(){
        this.response = [];
        this.note = "";
    }
    submit(){
        this.props.submit(this.response, this.note);
    }
    setResponse(e, { label, checked }){
        if (checked){
            this.response.push(label);
        } else {
            this.response.splice(this.response.indexOf(label), 1);
        }
        console.log("UPDATE RESPONSE: ", this.response);

        if (this.response.length > 0 && !this.state.canSubmit){
            this.setState({canSubmit: true});
        } else if (this.response.length === 0 && this.state.canSubmit){
            this.setState({canSubmit: false});
        }
    }
    setNote({ target }){
        this.note = target.value;
    }
    setNoteHeight(){
        const minHeight = this.notesDiv.clientHeight - rem(5);
        if (this.textarea.clientHeight < minHeight){
            const rowHeight = this.textarea.clientHeight / this.textarea.rows;
            this.textarea.rows = Math.ceil(minHeight / rowHeight);
            // console.log(this.textarea.clientHeight, minHeight);
        }
    }
    render(){
        const { canSubmit } = this.state;
        return (
            <Modal 
                className='Feedback'
                dimmer="blurring" 
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.open}
                // open={true}
            >
                <Modal.Header>
                    <h1>{this.props.title}</h1>
                    <h2>{this.props.subtitle}</h2>
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <div className="questions">
                            {this.props.questions.map(q => (
                                <Form.Checkbox key={q}
                                    label={i18n.t('FEEDBACK.Q' + q)} 
                                    onChange={this.setResponse.bind(this)}
                                />
                            ))}
                        </div>
                        <div className="notes" ref={(div) => this.notesDiv=div}>
                            <h3>{i18n.t('PROFILE.NOTES')}</h3>
                            <Form.Field>
                                <textarea 
                                    ref={(textarea) => this.textarea = textarea}
                                    rows={this.props.questions.length}
                                    placeholder={i18n.t('FEEDBACK.NOTES')}
                                    onChange={this.setNote.bind(this)}
                                    onFocus={this.setNoteHeight.bind(this)}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {/* <Button 
                        className='cancel'
                        onClick={this.close.bind(this)}>
                        {i18n.t('APP.CANCEL')}
                    </Button> */}
                    <Button
                        className={canSubmit ? '' : 'disabled'}
                        positive
                        disabled={!canSubmit}
                        content={i18n.t('APP.SUBMIT')}
                        onClick={this.submit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default Feedback