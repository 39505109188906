import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ReportHeader from "./ReportHeader";
import Pager from "./Pager";
import gaokaoUtils from "./components/gaokaoUtils";
import majorMeta from "./components/majorMeta";

function MajorOverview({ title, majorData }) {
    let sortedMajors = gaokaoUtils.getTop5MajorsNew(majorData);

    return (
        <StyledContainer>
            <ReportHeader title={title} />
            <div className="section-body">
                <div>
                    <div className="text-title">专业推荐</div>
                    <div className="text-desc">
                        基于海量学⽣游戏和⾏为数据，本测评系统建⽴了前沿的统计学和机器学习预测匹配模型，实现了个性化的专业推
                        荐。
                        <br />
                        个性化专业推荐原理：⾸先通过测评游戏和问卷测量学⽣的素质能⼒，
                        再将学⽣的测评数据与数据库中的已有学⽣档案进⾏匹配，根据其相似程度推荐对应的专业。
                        （注意：请不要将本报告作为学⽣⽣涯决策的唯⼀依据，请全⾯综合考量其它相关信息及因素。）
                        <br />
                        根据本次测评结果，个性化专业推荐模型的推荐结果如下：
                    </div>
                </div>
                <div className="overview-container">
                    {_.map(sortedMajors, function (item, index) {
                        return (
                            <div className="major-row" key={item.key}>
                                <div className="text-header">
                                    <div className="major-name">
                                        {majorMeta[item.key]?.name}
                                    </div>
                                    <div className="major-score">
                                        {index === 0 ? "匹配度" : ""}{" "}
                                        {Math.round(item.score * 100) + "%"}
                                    </div>
                                </div>
                                <div className="major-bar">
                                    <div
                                        className="score-bar"
                                        style={{
                                            width: 100 - item.score * 100 + "%",
                                        }}
                                    ></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .section-body {
        .overview-container {
            margin-top: 60px;
            width: 100%;
        }
        .major-row {
            margin: 20px 0;
            .text-header {
                height: 20px;
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                color: #47525d;
            }
            .major-bar {
                height: 16px;
                width: 100%;
                border-radius: 6px;
                background-image: linear-gradient(
                    to right,
                    rgb(235, 110, 133),
                    rgb(99, 174, 229)
                );
                position: relative;
                .score-bar {
                    background-color: #ebedf0;
                    height: 16px;
                    border-radius: 0 6px 6px 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
`;

MajorOverview.propTypes = {
    title: propTypes.string.isRequired,
    majorData: propTypes.object.isRequired,
};

export default MajorOverview;
