import React, { useState, useContext, useCallback, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import propTypes from "prop-types";
import { Request } from "lib";
import { Input, Select, AutoComplete, Button } from "antd";
import FilterContext from "./FilterContext";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Option } = Select;

function getYears() {
    const startYear = 2019;
    const now = new Date();
    const currYear = now.getFullYear();
    let yearOptions = [];
    for (let i = startYear; i <= currYear + 2; i++) {
        yearOptions.push(
            <Option value={i} key={i}>
                {i}
            </Option>
        );
    }
    return yearOptions;
}

function DashFilter({ adminLevel, tabKey }) {
    let { setFilter } = useContext(FilterContext);

    const [year, setYear] = useState("");

    const [advisor, setAdvisor] = useState("");
    const [tempAdvisor, setTempAdvisor] = useState("");

    const [academyCode, setAcademyCode] = useState("");
    const [academyText, setAcademyText] = useState("");
    const [academyOptions, setAcademyOptions] = useState([]);

    const [departmentCode, setDepartmentCode] = useState("");
    const [departmentText, setDepartmentText] = useState("");
    const [departmentOptions, setDepartmentOptions] = useState([]);

    const setFilterHelper = useCallback(
        function ({
            newYear = year,
            newAdvisor = advisor,
            newAcademy = academyCode,
            newDepartment = departmentCode,
        }) {
            setFilter({
                year: newYear,
                advisor: newAdvisor,
                academy: newAcademy,
                departmentId: newDepartment,
            });
        },
        [setFilter, year, advisor, academyCode, departmentCode]
    );

    useEffect(
        function () {
            // NO-1450 clear department autocomplete
            if (tabKey === "6" || tabKey === "7") {
                setDepartmentCode("");
                setDepartmentText("");
                setDepartmentOptions([]);
            }
        },
        [tabKey]
    );

    const showFilterClear = useCallback(
        function () {
            const isEmptyFilter =
                year === "" &&
                advisor === "" &&
                academyCode === "" &&
                departmentCode === "";
            return !isEmptyFilter;
        },
        [year, advisor, academyCode, departmentCode]
    );

    const fetchAcademies = useCallback(
        _.debounce(function (searchText) {
            Request.GET(
                "datadict/erp:school:en/" + encodeURIComponent(searchText),
                {
                    onSuccess: (data) => {
                        let attr = [
                            {
                                key: "全部",
                                value: "",
                                desc: "全部",
                            },
                        ];
                        _.each(data.data, (v) => {
                            attr.push({
                                key: v.code,
                                value: v.code,
                                desc: v.value,
                            });
                        });
                        setAcademyOptions(attr);
                    },
                    onFail: (status, err) => {
                        if (status === 401) return true;
                        console.error(err);
                    },
                }
            );
        }, 200),
        []
    );

    // when key is 5 or 6, it's plusTab
    const isPlusTab = tabKey === "5" || tabKey === "6" || tabKey === "7";
    let organPlaceholder = "";
    let fetchOrganUrl = "";

    if (tabKey === "6") {
        organPlaceholder = "部门";
        fetchOrganUrl = "dashboard/organ?deps=[%22department%22]&search=";
    } else if (tabKey === "7") {
        organPlaceholder = "分公司";
        fetchOrganUrl = "dashboard/organ?deps=[%22company%22]&search=";
    } else {
        // tabKey <= 5
        organPlaceholder = "部门或分公司";
        fetchOrganUrl =
            "dashboard/organ?deps=[%22company%22,%22department%22]&search=";
    }

    const fetchDepartments = useCallback(
        _.debounce(function (searchText) {
            if (fetchOrganUrl) {
                // old url: "datadict/moblab:contractinfo:organ/"
                Request.GET(fetchOrganUrl + encodeURIComponent(searchText), {
                    onSuccess: (data) => {
                        let attr = [
                            {
                                key: "全部",
                                value: "",
                                desc: "全部",
                            },
                        ];
                        _.each(data.data, (v) => {
                            attr.push({
                                key: v.code,
                                value: v.code,
                                desc: v.value,
                            });
                        });
                        setDepartmentOptions(attr);
                    },
                    onFail: (status, err) => {
                        if (status === 401) return true;
                        console.error(err);
                    },
                });
            }
        }, 200),
        [tabKey]
    );

    const onAdvisorChange = useCallback(
        function (e) {
            setTempAdvisor(e.target.value);
        },
        [setTempAdvisor]
    );

    return (
        <StyledContainer className={showFilterClear() ? "filter-active" : ""}>
            {showFilterClear() ? (
                <Button
                    type="link"
                    icon={<CloseCircleOutlined />}
                    className="clear-button"
                    onClick={() => {
                        setFilter({
                            year: "",
                            advisor: "",
                            academy: "",
                            departmentId: "",
                        });
                        setYear("");
                        setAdvisor("");
                        setTempAdvisor("");

                        setAcademyCode("");
                        setAcademyText("");
                        setAcademyOptions([]);

                        setDepartmentCode("");
                        setDepartmentText("");
                        setDepartmentOptions([]);
                    }}
                >
                    清空过滤
                </Button>
            ) : (
                <span className="clear-label">过滤：</span>
            )}
            <Select
                style={{ width: 120 }}
                onChange={useCallback(
                    function (value) {
                        setYear(value || "");
                        setFilterHelper({
                            newYear: value || "",
                        });
                    },
                    [setYear, setFilterHelper]
                )}
                allowClear
                placeholder="入学年份"
                value={year === "" ? undefined : year}
            >
                {getYears()}
            </Select>

            {isPlusTab && adminLevel === 3 ? null : (
                <Search
                    className="filter-advisor"
                    placeholder="申请顾问"
                    value={tempAdvisor}
                    onChange={onAdvisorChange}
                    onSearch={(value) => {
                        setAdvisor(value);
                        setFilterHelper({
                            newAdvisor: value,
                        });
                    }}
                    style={{ width: 200 }}
                />
            )}

            {isPlusTab ? null : (
                <AutoComplete
                    placeholder="申请院校"
                    style={{ width: 280 }}
                    className="filter-academyname"
                    value={academyText}
                    onFocus={(e) => {
                        const searchText = e.target.value;
                        setAcademyText(searchText);
                        fetchAcademies(searchText);
                    }}
                    onSelect={(e, option) => {
                        setAcademyText(option.children);
                        setAcademyCode(option.value);
                        setFilterHelper({
                            newAcademy: option.value,
                        });
                    }}
                    onChange={(value) => {
                        setAcademyCode(value || "");
                        // handle clear
                        if (_.isUndefined(value)) {
                            setAcademyText("");
                            setFilterHelper({
                                newAcademy: "",
                            });
                        }
                    }}
                    onSearch={(searchText) => {
                        setAcademyText(searchText);
                        fetchAcademies(searchText);
                    }}
                    allowClear
                >
                    {academyOptions.map(({ key, value, desc }) => (
                        <AutoComplete.Option key={key} value={value}>
                            {desc}
                        </AutoComplete.Option>
                    ))}
                </AutoComplete>
            )}

            {adminLevel > 0 ? (
                <AutoComplete
                    placeholder={organPlaceholder}
                    style={{ width: 360 }}
                    className="filter-departmentname"
                    value={departmentText}
                    onFocus={(e) => {
                        const searchText = e.target.value;
                        setDepartmentText(searchText);
                        fetchDepartments(searchText);
                    }}
                    onSelect={(e, option) => {
                        setDepartmentText(option.children);
                        setDepartmentCode(option.value);
                        setFilterHelper({
                            newDepartment: option.value,
                        });
                    }}
                    onChange={(value) => {
                        setDepartmentCode(value || "");
                        // handle clear
                        if (_.isUndefined(value)) {
                            setDepartmentText("");
                            setFilterHelper({
                                newDepartment: "",
                            });
                        }
                    }}
                    onSearch={(searchText) => {
                        setDepartmentText(searchText);
                        fetchDepartments(searchText);
                    }}
                    allowClear
                >
                    {departmentOptions.map(({ key, value, desc }) => (
                        <AutoComplete.Option key={key} value={value}>
                            {desc}
                        </AutoComplete.Option>
                    ))}
                </AutoComplete>
            ) : null}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "filter-con",
})`
    display: flex;
    padding: 20px 40px;
    justify-content: flex-start;
    align-items: center;
    > * {
        margin-right: 20px;
    }
    .clear-button {
        margin-right: 0;
        padding: 4px 20px;
        padding-left: 0;
    }
    .clear-label {
        margin-right: 0;
        text-align: left;
        width: 100px;
    }

    &.filter-active {
        .ant-input,
        .ant-select-selection-search-input,
        .ant-select-selection-item {
            color: blue;
        }
    }
`;

DashFilter.propTypes = {
    adminLevel: propTypes.number.isRequired,
    tabKey: propTypes.string.isRequired,
};

export default DashFilter;
