import React, { Component } from 'react';

/**
 * @class RichText
 * 
 * Rich text component to handle inline styling and embedding without custom scss.
 * This is necessary due to translations not always being done in the same order,
 * thus requiring a dynamic construction paradigm. 
 * 
 * @prop {Map}    styles     A map of delimiters (i.e. "b" for <b>) to style objects
 * @prop {String} content    A text string with delimiters to indicate inline styling
 * @prop {Array}  [handlers] An array of function handlers, that will be attached to 
 *                              "<a>" delimiters
 */
export default class RichText extends Component {
    render(){
        const { className, styles, content } = this.props;
        const delimiter = /<[a-z]+>/g;
        const keys = content.match(delimiter);
        const parts = content.split(delimiter);
        const handlers = (this.props.handlers || []).slice();
        return (
            <p style={{margin: 0, padding: 0}} className={className}>
                {parts.map((str, i) => {
                    if (i % 2){
                        const key = keys[i].match(/[a-z]+/);
                        if (key[0] === "a"){
                            return (
                                <span key={i} 
                                    className="inline-link"
                                    onClick={handlers.shift()}
                                >
                                    {str}
                                </span>
                            )
                        } else if (key[0] === "b"){
                            return (
                                <span key={i} style={{fontWeight: 'bold'}}>{str}</span>
                            )
                        }
                        return (
                            <span key={i} style={styles[key]}>{str}</span>
                        )
                    } else if (str.length > 0){
                        return (
                            <span key={i}>{str}</span>
                        )  
                    }
                    return (<></>)
                })}
            </p>
        )
    }
}