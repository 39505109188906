export default {
	"id":"5c796d3b620eba01d5f420cf",
	"name":"TODO",
	"fields":{
		"name":"TODO",
		"email":"TODO",
		"password":true,
		"dateOfBirth":null,
		"parents":[

		],
		"role":"student",
		"isAdmin":false,
		"canGiveConsent":false,
		"canPlayGame":false,
		"canViewReport":false,
        "numericId":1551461691988775,
		"coins":0,
		"createdAt":"2019-03-01T17:34:51.359Z",
		"createdBy":"5c75a9a9443a7c0014a9d1b5",
		"updatedAt":"2019-03-01T17:34:51.359Z",
        "updatedBy":"5c75a9a9443a7c0014a9d1b5",
        
        //CURRENTLY DOES NOT OUTPUT THIS, BUT SHOULD
		"tier": -1,
        "assigned_tier": -1,
        "archived": false,
        "scores": {},
        "traits": {}
	}
}