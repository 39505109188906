import React, { Component } from "react";
import { Button, Menu, Pagination, message, Popconfirm, Modal } from "antd";
import Editable from "./Editable";
import { Request } from "../../lib";
import UpdateSubmission from "./UpdateSubmission";
import SyncSelectSchool from "./SyncSelectSchool";
import More from "./More";
import moment from "moment";
import DataDict from "./DataDict";
import _ from "lodash";
import UUID from "uuid";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
var confirm = Modal.confirm;

const _applySystem = {
    "0": {
        value: "Common系统",
        account: "commonAccount",
        pwd: "commonPwd",
        isUnified: true,
    },
    "1": {
        value: "UC系统",
        account: "UCAccount",
        pwd: "UCPwd",
        isUnified: true,
    },
    "2": {
        value: "CAAS系统",
        account: "CAASAccount",
        pwd: "CAASPwd",
        isUnified: true,
    },
    "3": {
        value: "Apply Texas",
        account: "applyTexasAccount",
        pwd: "applyTexasPwd",
        isUnified: false,
    },
    "4": {
        value: "独立系统",
        account: "independentAccount",
        pwd: "independentPwd",
        isUnified: false,
    },
    "5": {
        value: "合作院校",
        account: "cooperateAccount",
        pwd: "cooperatePwd",
        isUnified: false,
    },
    "6": {
        value: "其他",
        account: "otherAccount",
        pwd: "otherPwd",
        isUnified: false,
    },
};
const _submitProgress = {
    选校数量: { count: 0, rowSpan: 3, used: false },
    未提交: { count: 0, rowSpan: 3, used: false },
    已提交: { count: 0, rowSpan: 3, used: false },
    放弃申请: { count: 0, rowSpan: 3, used: false },
};
const defaultCouunt = 12;
const perDefaultCount = 4;
const isRedundancy = (row) => {
    return row["pullSchool"] && !row["selectSchoolID"] && row["_id"];
};
class SubmissionStatus extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        pageSize: 10,
        renderdata: {},
        loading: true,
        changeStatus: {
            data: {},
            changeStatusvisible: false,
        },
        local: {
            emptyText: " ",
        },
        sorter: {},
        datadict: {
            applyStatus: DataDict.getMap("moblab:contractinfo:applyStatus"),
        },
    };
    rawData = [];
    setRawData = (contractId, academyInfoId, changed) => {
        for (let j = 0; j < this.rawData.data.length; j++) {
            let contract = this.rawData.data[j];
            if (contract.contractId === contractId) {
                if (!contract.academyinfo) {
                    this.rawData.data[j].academyinfo = [];
                    this.rawData.data[j].academyinfo.push({
                        _id: academyInfoId,
                        ...changed,
                    });
                    break;
                }
                let flag = true;
                for (let k = 0; k < contract.academyinfo.length; k++) {
                    let academy = contract.academyinfo[k];
                    if (academy._id === academyInfoId) {
                        this.rawData.data[j].academyinfo[k] = {
                            ...academy,
                            ...changed,
                        };
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    this.rawData.data[j].academyinfo.push({
                        _id: academyInfoId,
                        ...changed,
                    });
                }
                break;
            }
        }
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.fetchData(true);
    };
    init = () => {
        let ApplySystem = DataDict.getMap("moblab:academyinfo:applySystem");
        let ApplySystemArr = DataDict.getDictNew(
            "moblab:academyinfo:applySystem"
        );
        let ApplyType = DataDict.getMap("moblab:academyinfo:applyType");
        let SubmitProgress = DataDict.getMap(
            "moblab:academyinfo:submitProgress"
        );
        let DocProgress = DataDict.getMap("moblab:academyinfo:docProgress");
        let CreditCardPayStatus = DataDict.getMap(
            "moblab:academyinfo:creditCardPayStatus"
        );
        let IsCooperate = DataDict.getMap("moblab:academyinfo:isCooperate");
        let zszz = DataDict.getMap("moblab:academyinfo:zszz");
        let CooperateAcademy = DataDict.getMap(
            "erp:academyinfo:cooperateAcademy"
        );
        let CooperMode = DataDict.getMap("erp:academyinfo:cooperMode");
        let LanguageGiveScore = DataDict.getMap(
            "moblab:academyinfo:languageGiveScore"
        );
        let SATACTGiveScore = DataDict.getMap(
            "moblab:academyinfo:SATACTGiveScore"
        );
        this.setState({
            applySystem: ApplySystem,
            applyType: ApplyType,
            submitProgress: SubmitProgress,
            docProgress: DocProgress,
            creditCardPayStatus: CreditCardPayStatus,
            languageGiveScore: LanguageGiveScore,
            SATACTGiveScore: SATACTGiveScore,
            applySystemArr: ApplySystemArr,
            isCooperate: IsCooperate,
            zszz: zszz,
            cooperateAcademy: CooperateAcademy,
            cooperMode: CooperMode,
        });
        this.fetchData();
    };
    fetchData = (init) => {
        if (init) {
            this.setState({ currentPage: 1, loading: true }, () => {
                Request.GET(
                    "excel/academyinfo/" +
                        encodeURIComponent(
                            JSON.stringify({ ...this.buildFilter() })
                        ),
                    {
                        onSuccess: (data) => {
                            this.rawData = data;
                            this.renderDataSource(this.rawData);
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("查询失败");
                        },
                    }
                );
            });
        } else {
            this.setState({ loading: true }, () => {
                Request.GET(
                    "excel/academyinfo/" +
                        encodeURIComponent(
                            JSON.stringify({ ...this.buildFilter() })
                        ),
                    {
                        onSuccess: (data) => {
                            this.rawData = data;
                            this.renderDataSource(this.rawData);
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("查询失败");
                        },
                    }
                );
            });
        }
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    renderDataSource = (datasource) => {
        let renderdata = {};
        let deleteData = {};
        let data = [];
        for (let i = 0; i < datasource["data"].length; i++) {
            let ds = datasource["data"][i];
            delete ds.remark;
            let academyinfo = ds["academyinfo"];
            // let studentId = ds["_id"];
            let contractId = ds["contractId"];
            let render = (renderdata[contractId] = JSON.parse(
                JSON.stringify(_submitProgress)
            ));
            for (let j = 0; j < academyinfo.length; j++) {
                deleteData[contractId] = deleteData[contractId]
                    ? true
                    : ds["pullSchool"] &&
                      !academyinfo[j]["selectSchoolID"] &&
                      academyinfo[j]["_id"];
                const rKey = this.state.submitProgress[
                    academyinfo[j]["submitProgress"]
                ];
                academyinfo[j]["isCooperateValue"] = _.isEmpty(
                    academyinfo[j]["isCooperate"]
                )
                    ? academyinfo[j]["isCooperate"]
                    : this.state.isCooperate[academyinfo[j]["isCooperate"]];
                academyinfo[j]["zszzValue"] = _.isEmpty(academyinfo[j]["zszz"])
                    ? academyinfo[j]["zszz"]
                    : this.state.zszz[academyinfo[j]["zszz"]];
                academyinfo[j]["cooperateAcademyValue"] = _.isEmpty(
                    academyinfo[j]["cooperateAcademy"]
                )
                    ? academyinfo[j]["cooperateAcademy"]
                    : this.state.cooperateAcademy[
                          academyinfo[j]["cooperateAcademy"]
                      ];
                academyinfo[j]["cooperModeEntryIValue"] = _.isEmpty(
                    academyinfo[j]["cooperModeEntryI"]
                )
                    ? academyinfo[j]["cooperModeEntryI"]
                    : this.state.cooperMode[academyinfo[j]["cooperModeEntryI"]];
                academyinfo[j]["schoolRank"] = _.isEmpty(
                    academyinfo[j]["schoolId"]
                )
                    ? ""
                    : DataDict.getMap("moblab:school:rank")[
                          academyinfo[j]["schoolId"]
                      ];
                const archiveName = _.isEmpty(academyinfo[j]["archive"])
                    ? DataDict.getMap("moblab:academyinfo:archive")["0"]
                    : DataDict.getMap("moblab:academyinfo:archive")[
                          academyinfo[j]["archive"]
                      ];
                render[rKey] && render[rKey]["count"]++;
                render["选校数量"]["count"]++;
                data.push({
                    ...ds,
                    ...academyinfo[j],
                    key: UUID.v1(),
                    number: i + 1,
                    name: ds["name"],
                    bindUid: ds["bindUid"],
                    academyinfoName: academyinfo[j]["name"],
                    archive: archiveName,
                    studentId: ds["_id"],
                    academyinfoId: academyinfo[j]["_id"],
                    overdate: academyinfo[j]["overdate"]
                        ? moment(academyinfo[j]["overdate"])
                        : null,
                    webSubmitDate: academyinfo[j]["webSubmitDate"]
                        ? moment(academyinfo[j]["webSubmitDate"])
                        : null,
                    postDate: academyinfo[j]["postDate"]
                        ? moment(academyinfo[j]["postDate"])
                        : null,
                    postDate2: academyinfo[j]["postDate2"]
                        ? moment(academyinfo[j]["postDate2"])
                        : null,
                });
            }
            if (render["选校数量"]["count"] >= defaultCouunt) {
                render["选校数量"]["rowSpan"] =
                    perDefaultCount +
                    render["选校数量"]["count"] -
                    defaultCouunt +
                    1;
                data.push({
                    isLastItemOfGroup: true,
                    ...ds,
                    _id: null,
                    studentId: ds["_id"],
                    contractStatus: ds["contractStatus"],
                    bindUid: ds["bindUid"],
                    name: ds["name"],
                    number: i + 1,
                    key: UUID.v1(),
                });
            }
            let count = defaultCouunt - render["选校数量"]["count"];
            for (let k = 0; k < count; k++) {
                data.push({
                    isLastItemOfGroup: k === count - 1,
                    ...ds,
                    _id: null,
                    studentId: ds["_id"],
                    contractStatus: ds["contractStatus"],
                    bindUid: ds["bindUid"],
                    name: ds["name"],
                    number: i + 1,
                    key: UUID.v1(),
                });
            }
            render["count"] =
                render["选校数量"]["count"] < defaultCouunt
                    ? defaultCouunt
                    : render["选校数量"]["count"] + 1;
        }
        _.each(data, (v, index) => {
            let renderMap = renderdata[v.contractId];
            let submitProgress = "";
            let count = 0;
            let rowSpan = 0;
            let studentCount = renderdata[v.contractId].count;
            renderdata[v.contractId].count = 0;
            for (let key in renderMap) {
                if (renderMap[key]["used"] === false) {
                    submitProgress = key;
                    count = renderMap[key]["count"];
                    rowSpan = renderMap[key]["rowSpan"];
                    renderMap[key]["used"] = true;
                    renderMap[key]["rowSpan"]--;
                    break;
                } else if (renderMap[key]["rowSpan"] !== 0) {
                    renderMap[key]["rowSpan"]--;
                    break;
                }
            }
            v.rowSpan = rowSpan;
            v.submitProgressName = submitProgress;
            v.count = count;
            v.studentCount = studentCount;
            v._index = index;
            v.applyAccount = _applySystem[v.applySystem]
                ? v[_applySystem[v.applySystem].account]
                : null;
            v.applyPwd = _applySystem[v.applySystem]
                ? v[_applySystem[v.applySystem].pwd]
                : null;

            // NO-1670 Adjust College name columns and tables
            v.shcoolCount = renderMap["选校数量"]["count"];
            v.unsubmitCount = renderMap["未提交"]["count"];
            v.submitCount = renderMap["已提交"]["count"];
            v.giveupCount = renderMap["放弃申请"]["count"];
        });
        this.setState({
            data: data,
            renderdata: renderdata,
            currentPage: datasource["currentPage"],
            total: datasource["total"],
            loading: false,
            deleteData: deleteData,
        });
    };
    handleStatusClick = (value) => {
        this.setState({ statusChange: value.key });
    };

    saveWithInit = (record, attr, value) => {
        if (!record["_id"]) {
            message.warn("请先录入院校名称或申请专业名称");
            return false;
        }
        let _value = value ? value : record[attr];
        let update = { [attr]: _value };
        // this.setRawData(record.studentId, record._id, update);
        // this.renderDataSource(this.rawData);
        if (!record["_id"]) {
            update.contractInfo = record.contractId;
        }
        Request.POST("academyinfo", {
            data: {
                update: update,
                id: record["_id"],
            },
            onSuccess: (data) => {
                this.init();
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("更新失败");
            },
        });
    };

    save = (record, attr, value) => {
        if (!record["_id"]) {
            message.warn("请先录入院校名称或申请专业名称");
            return false;
        }
        let _value = value ? value : record[attr];
        let update = { [attr]: _value };
        let self = this;
        if (!isRedundancy(record)) {
            if (update.submitProgress === "1") {
                confirm({
                    title: `确认将${record.academyinfoName}的院校提交状态修改为“已提交“`,
                    content:
                        "请注意：修改以后该院校信息将同步到ERP系统并自动发送申请成功邮件给学生。",
                    okText: "确认",
                    cancelText: "取消",
                    onOk: function () {
                        self.setRawData(record.contractId, record._id, update);
                        self.renderDataSource(self.rawData);
                        if (!record["_id"]) {
                            update.contractInfo = record.contractId;
                        }
                        Request.POST("academyinfo", {
                            data: {
                                update: update,
                                id: record["_id"],
                            },
                            onSuccess: (data) => {
                                Request.GET("sync/apply/" + record.contractId, {
                                    onSuccess: (data) => {
                                        message.info("同步成功");
                                    },
                                    onFail: (status, error) => {
                                        message.error("同步错误");
                                    },
                                });
                            },
                            onFail: (status) => {
                                if (status === 401) return true;
                                message.error("更新失败");
                            },
                        });
                    },
                });
                return false;
            }
        }

        this.setRawData(record.contractId, record._id, update);
        this.renderDataSource(this.rawData);
        if (!record["_id"]) {
            update.contractInfo = record.contractId;
        }
        Request.POST("academyinfo", {
            data: {
                update: update,
                id: record["_id"],
            },
            onSuccess: (data) => {},
            onFail: (status) => {
                if (status === 401) return true;
                message.error("更新失败");
            },
        });
    };

    saves = (record, params) => {
        let update = {};
        if (!record["_id"]) {
            update.contractInfo = record.contractId;
        }
        _.each(params, (p) => {
            let _value = p.value ? p.value : record[p.attr];
            update[p.attr] = _value;
        });
        Request.POST("academyinfo", {
            data: {
                update: update,
                id: record["_id"],
            },
            onSuccess: (data) => {
                this.setRawData(
                    record.contractId,
                    data.data.academyInfo._id,
                    update
                );
                this.renderDataSource(this.rawData);
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("更新失败");
            },
        });
    };

    status = (
        <Menu onClick={this.handleStatusClick}>
            <Menu.Item key="申请状态">全部</Menu.Item>
            <Menu.Item key="信息收集">信息收集</Menu.Item>
            <Menu.Item key="选校">选校</Menu.Item>
            <Menu.Item key="申请准备">申请准备</Menu.Item>
            <Menu.Item key="申请提交">申请提交</Menu.Item>
            <Menu.Item key="申请跟踪">申请跟踪</Menu.Item>
            <Menu.Item key="入读规划">入读规划</Menu.Item>
        </Menu>
    );
    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        let columns = [
            {
                className: "column-apply-status cross-row-td",
                title: "申请状态",
                dataIndex: "applyStatus",
                align: "center",
                render: (statusCode, row, index) => {
                    let tag = (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["name"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );

                    const obj = {
                        children: tag,
                        props: {},
                    };
                    obj.props.rowSpan = row["studentCount"];
                    return obj;
                },
                width: "125px",
                sorter: true,
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div className="col-student-name">
                                <StudentLink
                                    studentName={value}
                                    contractId={row["contractId"]}
                                    contractName={row["contractName"]}
                                    contractNo={row["contractNo"]}
                                />

                                <div>选校数量: {row.shcoolCount}</div>
                                <div>未提交: {row.unsubmitCount}</div>
                                <div>已提交: {row.submitCount}</div>
                                <div>放弃申请: {row.giveupCount}</div>
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row["studentCount"];
                    return obj;
                },
                width: "100px",
                sorter: true,
                fixed: "left",
            },
            {
                title: "院校名称",
                dataIndex: "academyinfoName",
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["selectSchoolID"] ? false : true,
                    editRow: record["contractStatus"] !== "7",
                    autoComplete: true,
                }),
                handleSearch: (value, callback, autoCompleteAddition) => {
                    // if (!_.isEmpty(value)) {
                    Request.GET(
                        `datadict/erp:school:en/${encodeURIComponent(value)}`,
                        {
                            onSuccess: (data) => {
                                let ds = [];
                                _.each(data.data, (e) => {
                                    ds.push({
                                        key: e.code,
                                        value: e.value,
                                        desc: e.value,
                                        data: e,
                                    });
                                });
                                callback(ds);
                            },
                            onFail: (status) => {
                                if (status === 401) return true;
                                // message.error("查询失败")
                            },
                        }
                    );
                    // }
                },
                handleSave: (record, value, data) => {
                    let params = [];
                    params.push({ attr: "name", value: data.value });
                    params.push({ attr: "schoolId", value: data.code });
                    this.saves(record, params);
                },
                width: "300px",
                fixed: "left",
            },
            // {
            //     title: "申请进度",
            //     dataIndex: "progress",
            //     colSpan: 2,
            //     render: (value, row, index) => {
            //         const obj = {
            //             children: row.submitProgressName,
            //             props: {},
            //         };
            //         obj.props.rowSpan = row.rowSpan;
            //         return obj;
            //     },
            //     width: "110px",
            // },
            // {
            //     title: "count",
            //     colSpan: 0,
            //     render: (value, row, index) => {
            //         const obj = {
            //             children: row.count,
            //             props: {},
            //         };
            //         obj.props.rowSpan = row.rowSpan;
            //         return obj;
            //     },
            //     width: "80px",
            // },
            {
                title: "院校排名",
                dataIndex: "schoolRank",
                width: "80px",
            },
            {
                title: "选校状态",
                dataIndex: "archive",
                width: "100px",
                render: (value, row, index) => {
                    return isRedundancy(row) &&
                        row["contractStatus"] !== "7" ? (
                        <Popconfirm
                            placement="topLeft"
                            title={"是否要删除该选校"}
                            onConfirm={() => {
                                Request.DELETE(
                                    "excel/academyinfo/academyinfo/" +
                                        row["_id"],
                                    {
                                        onSuccess: (data) => {
                                            this.fetchData();
                                        },
                                        onFail: (status) => {
                                            if (status === 401) return true;
                                            if (status === 404) {
                                                message.error("选校不存在");
                                            } else {
                                                message.error("删除失败");
                                            }
                                            this.fetchData();
                                        },
                                    }
                                );
                            }}
                            okText="是"
                            cancelText="否"
                        >
                            <Button size="small" icon={<CloseOutlined />}>
                                {value}
                            </Button>
                        </Popconfirm>
                    ) : (
                        value
                    );
                },
            },

            {
                title: "申请专业",
                dataIndex: "majorNameCH",
                onCell: (record, index) => ({
                    editable: record["selectSchoolID"] ? false : true,
                    editRow: record["contractStatus"] !== "7",
                    autoComplete: true,
                }),
                handleSearch: (value, callback, autoCompleteAddition) => {
                    Request.GET(
                        `datadict/erp:major:name/${encodeURIComponent(value)}`,
                        {
                            onSuccess: (data) => {
                                let ds = [];
                                _.each(data.data, (e) => {
                                    ds.push({
                                        key: e.code,
                                        value: e.value,
                                        desc: e.value,
                                        data: e,
                                    });
                                });
                                callback(ds);
                            },
                            onFail: (status) => {
                                if (status === 401) return true;
                                message.error("更新失败");
                            },
                        }
                    );
                },
                handleSave: (record, value, data) => {
                    let params = [];
                    params.push({ attr: "majorNameCH", value: data.value });
                    this.saves(record, params);
                },
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                width: "200px",
            },
            {
                title: "实际申请专业",
                dataIndex: "realityMajorName",
                handleSave: (record, value) => {
                    this.save(record, "realityMajorName");
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "200px",
            },
            {
                title: "截止日期",
                dataIndex: "overdate",
                datepicker: true,
                handleSave: (record, value) => {
                    this.save(record, "overdate");
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "140px",
            },
            {
                title: "申请系统",
                dataIndex: "applySystem",
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.each(this.state.applySystemArr, ({ key, value }) => {
                        arr.push({ key: key, value: value });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    this.saveWithInit(record, "applySystem");
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.applySystem[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "150px",
            },
            {
                title: "申请类型",
                dataIndex: "applyType",
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.applyType, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    this.save(record, "applyType");
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.applyType[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
            },
            {
                title: "注册网站",
                dataIndex: "website",
                handleSave: (record, value) => {
                    this.save(record, "website");
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "200px",
            },
            {
                title: "申请用户名",
                dataIndex: "applyAccount",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record) => {
                    if (record.academyinfoId) {
                        let system = _applySystem[record.applySystem];
                        if (system && system.isUnified) {
                            Request.POST("contractInfo", {
                                data: {
                                    attr: system.account,
                                    val: record.applyAccount,
                                    id: record["contractId"],
                                },
                                onSuccess: (data) => {
                                    this.init();
                                },
                                onFail: (status) => {
                                    if (status === 401) return true;
                                    message.error("更新失败");
                                },
                            });
                        } else if (system && !system.isUnified) {
                            this.save(
                                record,
                                system.account,
                                record.applyAccount
                            );
                        } else {
                            message.warn("请先选择申请系统");
                        }
                    } else {
                        message.warn("请先录入院校名称或申请专业名称");
                    }
                },
                width: "200px",
            },
            {
                title: "申请密码",
                dataIndex: "applyPwd",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record) => {
                    if (record.academyinfoId) {
                        let system = _applySystem[record.applySystem];
                        if (system && system.isUnified) {
                            Request.POST("contractInfo", {
                                data: {
                                    attr: system.pwd,
                                    val: record.applyPwd,
                                    id: record["contractId"],
                                },
                                onSuccess: (data) => {
                                    this.init();
                                },
                                onFail: (status) => {
                                    if (status === 401) return true;
                                    message.error("更新失败");
                                },
                            });
                        } else if (system && !system.isUnified) {
                            this.save(record, system.pwd, record.applyPwd);
                        } else {
                            message.warn("请先选择申请系统");
                        }
                    } else {
                        message.warn("请先录入院校名称或申请专业名称");
                    }
                },
                width: "200px",
            },
            {
                title: "院校提交进度",
                dataIndex: "submitProgress",
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.submitProgress, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    this.save(record, "submitProgress");
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.submitProgress[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "125px",
            },
            {
                title: "附表文书进度",
                dataIndex: "docProgress",
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.docProgress, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                handleSave: (record, value) => {
                    this.save(record, "docProgress");
                },
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.docProgress[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                width: "125px",
            },
            {
                title: "网申提交日期",
                dataIndex: "webSubmitDate",
                datepicker: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "webSubmitDate");
                },
                width: "140px",
            },
            {
                title: "信用卡支付情况",
                dataIndex: "creditCardPayStatus",
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.creditCardPayStatus, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.creditCardPayStatus[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "creditCardPayStatus");
                },
                width: "140px",
            },
            {
                title: "申请费",
                dataIndex: "cost",
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    if (isNaN(Number(record["cost"]))) {
                        message.error("请输入数字");
                        return false;
                    }
                    this.save(record, "cost", Number(record["cost"]));
                },
                width: "125px",
            },
            {
                title: "语言成绩送分",
                dataIndex: "languageGiveScore",
                dropdown: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                menus: (() => {
                    let arr = [];
                    _.map(this.state.languageGiveScore, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.languageGiveScore[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "languageGiveScore");
                },
                width: "125px",
            },
            {
                title: "SAT/ACT送分",
                dataIndex: "SATACTGiveScore",
                dropdown: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                menus: (() => {
                    let arr = [];
                    _.map(this.state.SATACTGiveScore, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? this.state.SATACTGiveScore[value]
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "SATACTGiveScore");
                },
                width: "125px",
            },
            {
                title: "运单号",
                dataIndex: "tansId",
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "tansId");
                },
                width: "200px",
            },
            {
                title: "邮寄日期",
                dataIndex: "postDate",
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                datepicker: true,
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "postDate");
                },
                width: "140px",
            },
            {
                title: "运单号2",
                dataIndex: "tansId2",
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "tansId2");
                },
                width: "200px",
            },
            {
                title: "邮寄日期2",
                dataIndex: "postDate2",
                datepicker: true,
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : row._id
                            ? hiddenDIV
                            : "",
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "postDate2");
                },
                width: "140px",
            },
            {
                title: "是否合作",
                dataIndex: "isCooperateValue",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
            },
            {
                title: "合作院校",
                dataIndex: "cooperateAcademyValue",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                width: "300px",
            },
            {
                title: "合作项目",
                dataIndex: "cooperateProject",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    return obj;
                },
                width: "200px",
            },
            {
                title: "备注",
                dataIndex: "remark",
                onCell: (record, index) => ({
                    editable: record["archive"] === "已取消" ? false : true,
                    editRow: record["contractStatus"] !== "7",
                }),
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : row._id ? (
                            hiddenDIV
                        ) : (
                            ""
                        ),
                        props: {},
                    };
                    obj.props.className = isRedundancy(row)
                        ? "redRow"
                        : "normal";
                    obj.props.rowSpan = 1;
                    return obj;
                },
                handleSave: (record, value) => {
                    this.save(record, "remark");
                },
                width: "300px",
            },
            {
                title: "操作",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <OperationButtons
                                studentName={row["name"]}
                                contractName={row["contractName"]}
                                contractStatus={row["contractStatus"]}
                                contractNo={row["contractNo"]}
                            >
                                <div className="operation-wrapper">
                                    {this.state.deleteData[
                                        row["contractId"]
                                    ] ? (
                                        <Popconfirm
                                            title="请确认是否删除冗余选校?"
                                            onConfirm={() => {
                                                Request.DELETE(
                                                    "excel/academyinfo/contractinfo/" +
                                                        row["contractId"],
                                                    {
                                                        onSuccess: (data) => {
                                                            this.fetchData();
                                                        },
                                                        onFail: (status) => {
                                                            if (status === 401)
                                                                return true;
                                                            message.error(
                                                                "删除失败"
                                                            );
                                                            this.fetchData();
                                                        },
                                                    }
                                                );
                                            }}
                                            okButtonProps={{ danger: true }}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button
                                                type="link"
                                                style={{
                                                    padding: "0px",
                                                    margin: "0px",
                                                    height: "20px",
                                                }}
                                            >
                                                删除冗余
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                    <UpdateSubmission
                                        studentId={row["studentId"]}
                                        studentName={row["name"]}
                                        applyStatus={row["applyStatus"]}
                                        applyAdvisor={row["applyAdvisorName"]}
                                        contractId={row["contractId"]}
                                        fetchData={this.fetchData}
                                    />

                                    <SyncSelectSchool
                                        studentId={row["studentId"]}
                                        fetchData={this.fetchData}
                                        contractId={row["contractId"]}
                                    />

                                    <More
                                        studentId={row["studentId"]}
                                        studentName={row["name"]}
                                        applyStatus={row["applyStatus"]}
                                        applyAdvisor={row["applyAdvisorName"]}
                                        contractId={row["contractId"]}
                                        fetchData={this.fetchData}
                                    />
                                </div>
                            </OperationButtons>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.studentCount;
                    return obj;
                },
                fixed: "right",
                width: "150px",
            },
        ];
        return (
            <StyledContainer>
                <Editable
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 5200, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                    rowClassName={(record, index) => {
                        let syncFlag = "normal";
                        const classNames = [syncFlag];

                        if (record.isLastItemOfGroup) {
                            classNames.push("last-item-of-group");
                        }

                        return classNames.join(" ");
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </StyledContainer>
        );
    }
}

const StyledContainer = styled.div`
    .operation-wrapper {
        margin-left: 5px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        > button {
            margin-top: 20px !important;
            margin-right: 5px !important;
        }
    }
    .col-student-name {
        .student-link {
            margin-bottom: 20px;
        }
        > div {
            height: 20px;
        }
    }
`;

export default SubmissionStatus;
