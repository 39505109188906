import React, { Component } from 'react'
import { NoteDirectory, NoteEditor, RippleButton } from "."
import { i18n } from "../lib"
import { NoteUtil } from "../lib/utils"

/**
 * @class Note Drawer
 * 
 * The Note Drawer lives on all profile pages as a sidebar that can be hidden or 
 * shown. The note drawer contains the Note Directory and Note Editor, which act as
 * separate views (as opposed to concurrent, as in the Note Page). The Note Drawer 
 * only displays notes created on the current page. From the Note Drawer, the user 
 * can create a new note, or open all notes. 
 * 
 * @prop {Array} notes          all note objects for the current student
 * @prop {String} page          the current page
 * @prop {String} userid        the current student's user id
 * @prop {Function} changePage  handler to change the current page, used to route the
 *                                  user to the NOTES page
 */
class NoteDrawer extends Component {
    state={
        noteid: null
    }
    constructor(){
        super();
        this.back = this.back.bind(this);
        this.selectNote = this.selectNote.bind(this);
        this.newNote = this.newNote.bind(this);
    }
    /**
     * Update the selected note id if it is -1. 
     * 
     * When a new note is created, it is set with id=-1 locally, and a request is 
     * dispatched to the server (by the Note Editor) to create a new note. When the 
     * server confirms that the new note has been created, it will send the new note's
     * id back. This new id will be stored in NoteUtil so that all components can 
     * update their pointers accordingly. 
     */
    componentDidUpdate(){
        const { notes, page, userid } = this.props;
        const { noteid } = this.state;
        if (notes !== null && notes.length > 0){
            const new_id = NoteUtil.noteid;
            if (noteid === -1 && new_id !== -1){
                this.setState({noteid: new_id})
            } else if (noteid !== null){
                const currentNote = NoteUtil.getNote(userid, noteid);
                if (currentNote && currentNote.attachTo !== page){
                    this.back();
                }
            }
        }
    }
    /**
     * Create a new note by setting id to -1. 
     * 
     * This id will be passed as a prop to the Note Editor, which will dispatch 
     * the new note creation request. 
     */
    newNote(){
        this.setState({noteid: -1})
    }
    /**
     * Select a note. 
     * 
     * This method is used only by Note Directory, and when called, will trigger the
     * Note Drawer view to update and display the Note Editor (instead of the Note 
     * Directory), while passing the selected note id to the Note Editor.
     * @param {String | int} noteid 
     */
    selectNote(noteid){
        this.setState({noteid})
    }
    /**
     * Return from Note Editor to Note Directory.
     * 
     * Reset the selected id to null to update the view. 
     */
    back(){
        if (this.state.noteid){
            this.setState({noteid: null});
        }
    }
    render() {
        const { notes, page } = this.props;
        const { noteid } = this.state;
        const hasSelection = noteid !== null;
        var hasNotes = false;
        var pageNotes = notes;

        if (notes !== null && notes.length > 0){
            pageNotes = notes.filter(note => note.attachTo === page);
            
            for (var n=0; n<pageNotes.length; n++){
                if (!pageNotes[n].deleted){
                    hasNotes = true;
                    break;
                }
            }
        }

        return (
            <div className={"NoteDrawer" + (hasSelection ? " edit" : "")}>
                <h1 onClick={this.back}>
                    <span className="icon">!</span>
                    {i18n.t('PROFILE.NOTES')}
                </h1>
                { page && 
                    <h2>{i18n.t('PROFILE.' + page)}</h2>
                }
                <div 
                    className="showAll" 
                    onClick={() => this.props.changePage('NOTES')}
                >
                    {i18n.t('APP.SHOW_ALL').toUpperCase()}
                </div>
                { !hasSelection && <>
                    <div className="scrollArea">
                        <NoteDirectory {...this.props}
                            userid={this.props.userid}
                            noteid={noteid}
                            notes={pageNotes}
                            page={page}
                            hasNotes={hasNotes}
                            select={this.selectNote}
                        /> 
                    </div>
                     <RippleButton 
                        className="addNote"
                        fillFrom="center"
                        icon=")"
                        text={i18n.t("APP.ADD")}
                        flat
                        onClick={this.newNote}
                    />
                </>}
                <div className={"editorBox" + (hasSelection ? " visible" : "")}>
                    { hasSelection && 
                        <NoteEditor {...this.props} 
                            userid={this.props.userid}
                            noteid={noteid}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default NoteDrawer;
