import React, { Component } from "react";
import { message, Pagination } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import DataDict from "./DataDict";
import _ from "lodash";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import moment from "moment";
import StudentLink from "./summary/StudentLink";
import Shade from "components/Shade";

class CoreApply extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        pageSize: 50,
        loading: true,
        local: {
            emptyText: " ",
        },
        sorter: {},
        datadict: {
            recommendProgress: DataDict.getMap("moblab:recommendinfo:progress"),
        },
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    fetchData = () => {
        Request.GET(
            "excel/coreapplyinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    Shade.hide();
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };
    renderDataSource = (datasource) => {
        let ds = datasource.data;
        for (let i = 0; i < ds.length; i++) {
            ds[i]["key"] = i;
            ds[i]["number"] = i + 1;
            ds[i]["interviewProgress"] = _.isEmpty(ds[i]["interviewProgress"])
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:interviewProgress")[
                      ds[i]["interviewProgress"]
                  ];
            ds[i]["resumeProgress"] = _.isEmpty(ds[i]["resumeProgress"])
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:resumeProgress")[
                      ds[i]["resumeProgress"]
                  ];
            ds[i]["mainTextProgress"] = _.isEmpty(ds[i]["mainTextProgress"])
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:mainTextProgress")[
                      ds[i]["mainTextProgress"]
                  ];
            ds[i]["professionDocProgress"] = _.isEmpty(
                ds[i]["professionDocProgress"]
            )
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:professionDocProgress")[
                      ds[i]["professionDocProgress"]
                  ];
            ds[i]["recommendProgress"] = _.isEmpty(ds[i]["recommendProgress"])
                ? undefined
                : DataDict.getMap("moblab:recommendinfo:progress")[
                      ds[i]["recommendProgress"]
                  ];
            ds[i]["caDocProgress"] = _.isEmpty(ds[i]["caDocProgress"])
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:califoniaDocProgress")[
                      ds[i]["caDocProgress"]
                  ];
            ds[i]["collectFileProgress"] = _.isEmpty(
                ds[i]["collectFileProgress"]
            )
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:collectFileProgress")[
                      ds[i]["collectFileProgress"]
                  ];
            ds[i]["earlierApply"] = _.isEmpty(ds[i]["earlierApply"])
                ? undefined
                : DataDict.getMap("moblab:coreapplyinfo:earlierApply")[
                      ds[i]["earlierApply"]
                  ];
            ds[i]["name"] = ds[i]["studentName"];
            ds[i]["birthday"] = _.isEmpty(ds[i]["birthday"])
                ? null
                : moment(ds[i]["birthday"]);
        }
        this.setState({
            data: ds,
            currentPage: datasource.currentPage,
            total: datasource.total,
            loading: false,
        });
        Shade.hide();
    };

    updateRow = (field, record, value) => {
        console.log(`${field} 编辑:`, record);
        if (!record["id"]) {
            Shade.show();
        }
        Request.POST("excel/coreapplyinfo", {
            data: {
                attr: field,
                val: record[field],
                id: record["id"],
                contractId: record["contractId"],
            },
            onSuccess: (data) => {
                if (data.error === "duplicate key for insert db") {
                    message.error("已有记录存在");
                }
                this.init();
            },
            onFail: (status) => {
                Shade.hide();
                if (status === 401) return true;
                message.error("更新失败");
            },
        });
    };

    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;

        const columns = [
            {
                className: "column-apply-status",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applystatus",
                render: (statusCode, row) => {
                    return (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                render: (value, row, index) => {
                    return (
                        <StudentLink
                            studentName={value}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "学生生日",
                dataIndex: "birthday",
                editable: true,
                datepicker: true,
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                handleSave: (record) => {
                    Request.POST("studentinfo", {
                        data: {
                            update: { birthday: record["birthday"] },
                            id: record["studentId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "150px",
            },
            {
                title: "Common账号",
                dataIndex: ["commonAccount"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "commonAccount",
                            val: record["commonAccount"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "Common密码",
                dataIndex: ["commonPwd"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "commonPwd",
                            val: record["commonPwd"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "CommonID",
                dataIndex: ["commonId"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "commonId",
                            val: record["commonId"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "UC账号",
                dataIndex: ["UCAccount"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "UCAccount",
                            val: record["UCAccount"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "UC密码",
                dataIndex: ["UCPwd"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "UCPwd",
                            val: record["UCPwd"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "UCID",
                dataIndex: ["UCId"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "UCId",
                            val: record["UCId"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },

            {
                title: "CAAS账号",
                dataIndex: ["CAASAccount"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "CAASAccount",
                            val: record["CAASAccount"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "CAAS密码",
                dataIndex: ["CAASPwd"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "CAASPwd",
                            val: record["CAASPwd"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "CAASID",
                dataIndex: ["CAASId"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractInfo", {
                        data: {
                            attr: "CAASId",
                            val: record["CAASId"],
                            id: record["contractId"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                ellipsis: true,
            },
            {
                title: "面谈进度",
                dataIndex: ["interviewProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:coreapplyinfo:interviewProgress"
                ),
                handleSave: (record, value) => {
                    this.updateRow("interviewProgress", record, value);
                },
                width: "150px",
            },
            {
                title: "简历进度",
                dataIndex: ["resumeProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:coreapplyinfo:resumeProgress"),
                handleSave: (record, value) => {
                    this.updateRow("resumeProgress", record, value);
                },
            },
            {
                title: "主文进度",
                dataIndex: ["mainTextProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:coreapplyinfo:mainTextProgress"
                ),
                handleSave: (record, value) => {
                    this.updateRow("mainTextProgress", record, value);
                },
            },
            // {
            //     title: "专业文书进度",
            //     dataIndex: ["professionDocProgress"],
            //     editable: true,
            //     dropdown: true,
            //     menus: DataDict.getDict(
            //         "moblab:coreapplyinfo:professionDocProgress"
            //     ),
            //     handleSave: (record, value) => {
            //         this.updateRow("professionDocProgress", record, value);
            //     },
            // },
            {
                title: "推荐信写作进度",
                dataIndex: ["recommendProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:recommendinfo:progress"),
                handleSave: (record, value) => {
                    this.updateRow("recommendProgress", record, value);
                },
            },
            {
                title: "加州系文书进度",
                dataIndex: ["caDocProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:coreapplyinfo:califoniaDocProgress"
                ),
                handleSave: (record, value) => {
                    this.updateRow("caDocProgress", record, value);
                },
            },
            {
                title: "收集文件进度",
                dataIndex: ["collectFileProgress"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict(
                    "moblab:coreapplyinfo:collectFileProgress"
                ),
                handleSave: (record, value) => {
                    this.updateRow("collectFileProgress", record, value);
                },
                width: "150px",
            },
            {
                title: "是否早申请",
                dataIndex: ["earlierApply"],
                editable: true,
                dropdown: true,
                menus: DataDict.getDict("moblab:coreapplyinfo:earlierApply"),
                handleSave: (record, value) => {
                    this.updateRow("earlierApply", record, value);
                },
                width: "110px",
            },
            {
                title: "备注",
                dataIndex: ["remark"],
                render: (value, row, index) => {
                    return value ? value : hiddenDIV;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.updateRow("remark", record, value);
                },
                ellipsis: true,
                width: "300px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                render: (value, row, index) => {
                    return (
                        <OperationButtons
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            applyStatus={row["applyStatus"]}
                            applyAdvisor={row["applyAdvisorName"]}
                            fetchData={this.fetchData}
                            isShowUpload={false}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractStatus={row["contractStatus"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                fixed: "right",
                width: "100px", //150
                align: "center",
            },
        ];

        return (
            <Shade>
                <Editable
                    size="small"
                    dataSource={this.state.data}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: 2900, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </Shade>
        );
    }
}
export default CoreApply;
