import React, { Component } from 'react'
import { i18n } from "../../lib"
import { RichText } from "../../components"
import { Modal, Segment } from 'semantic-ui-react'
import '../../scss/Modal.scss';

/**
 * @class APK
 * 
 * A modal that displays Android APK download instructions for the student portal.
 * 
 * @prop {Boolean} open   show/hide modal
 * @prop {Function} close handler to hide modal, as display boolean is external
 */
class APK extends Component {
    render(){
        return (
            <Modal 
                className='APK fullscreen'
                basic
                dimmer="inverted" 
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.open}
            >
                <div className="icon close" onClick={this.props.close}>e</div>
                <Modal.Header>{i18n.t('PORTAL.APK_TITLE')}</Modal.Header>
                <Modal.Content>
                    <h2>{i18n.t('PORTAL.APK_SUBTITLE')}</h2>
                    <Segment>
                        <div className="row">
                            <p>1</p>
                            <RichText 
                                styles={{
                                    c: {color: '#00A0BC'}
                                }}
                                content={i18n.t('PORTAL.APK_INSTRUCTIONS_1')}
                            />
                        </div>
                        <div className="row">
                            <p>2</p>
                            <RichText 
                                styles={{
                                    c: {color: '#00A0BC'}
                                }}
                                content={i18n.t('PORTAL.APK_INSTRUCTIONS_2')}
                            />
                        </div>
                    </Segment>
                </Modal.Content>
            </Modal>
        )
    }
}

export default APK