import React, { Component } from 'react'
import { i18n } from "../../lib"
import { NoteUtil } from '../../lib/utils'
import { NoteDirectory, NoteEditor, RippleButton } from '../../components'
import { Segment } from 'semantic-ui-react'

export default class Notes extends Component {
    state={
        noteid: null
    }
    componentWillUpdate(){
        const { notes } = this.props;
        if (notes !== null && notes.length > 0){
            const new_id = NoteUtil.noteid;
            if (this.state.noteid === -1 && new_id !== -1){
                this.setState({noteid: new_id})
            }
        }
    }
    newNote(){
        this.setState({noteid: -1})
        NoteUtil.newNote(this.props.userid, -1);
    }
    selectNote(noteid){
        this.setState({noteid: noteid})
    }
    render() {
        const { userid, notes } = this.props;
        var noteid = this.state.noteid;
        var hasNotes = false;
        var emptyNote = null;
        if (notes !== null && notes.length > 0){
            for (var n=0; n<notes.length; n++){
                var note = notes[n];
                if (!note.deleted){
                    hasNotes = true;
                }
                if (note.title === "" && note.body === "<p></p>"){
                    emptyNote = note;
                }
            }
            if (noteid === null){
                if (emptyNote){
                    noteid = emptyNote.id;
                } else if (hasNotes){
                    noteid = notes[0].id;
                }
            }
        }

        return (
            <div className="Page Notes">
                <div className="menu">
                    <div className="actions">
                        <RippleButton 
                            className="addNote"
                            fillFrom="center"
                            icon=")"
                            text={i18n.t("APP.ADD")}
                            disabled={hasNewNote(noteid, notes)}
                            flat
                            onClick={this.newNote.bind(this)}
                        />
                    </div>
                    <div className="scrollArea">
                        <NoteDirectory 
                            userid={userid}
                            noteid={noteid}
                            selected={noteid}
                            notes={notes}
                            hasNotes={hasNotes}
                            select={this.selectNote.bind(this)}
                        />
                    </div>
                </div>
                <div className="pit">
                    { notes !== null && hasNotes && 
                        <Segment>
                            <NoteEditor 
                                userid={userid}
                                noteid={noteid}
                            />
                        </Segment>
                    }
                </div>
            </div>
        )
    }
}

function hasNewNote(noteid, notes){
    return notes !== null && 
        notes.length > 0 && 
        (
            noteid === -1 || 
            !NoteUtil.validateNote(notes[0])
        );
}