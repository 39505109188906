export default [
    // {
    //     name: "Sort by Report and Name"
    // },
    // {
    //     name: "Show only students with report complete"
    // },
    // {
    //     name: "Show all students with partical game completion"
    // },
    // {
    //     name: "Finally a short filter name"
    // },
    // {
    //     name: "Sort by Cohort and Registration Status"
    // },
    // {
    //     name: "Super duper duper duper duper unreasonably ridiculously wayyyyyyyyy too long, like really unreasonably and incredibly long, (like this is basically an essay and there's no way anyone would ever name a filter this long but we have to try) filter name"
    // }
]