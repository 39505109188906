import React from "react";
import styled from "styled-components";
import LeftNavBar from "./LeftNavBar";
import Students from "./Students";
import { AdminUtil } from "lib/utils";
import cn from "classnames";

const ENABLED_PHASE2 =
    String(process.env.REACT_APP_ENABLED_PHASE2).toLocaleLowerCase() === "true";
const StudentsContainer = (props) => {
    const hasLeftNav = AdminUtil.isVisionAccount() && ENABLED_PHASE2;
    return (
        <StyledStudentsContainer
            className={cn({
                "has-left-nav": hasLeftNav,
            })}
        >
            {hasLeftNav ? <LeftNavBar currentPage="assessment" /> : null}
            <Students {...props} />
        </StyledStudentsContainer>
    );
};

const StyledStudentsContainer = styled.div`
    width: 100%;
    height: calc(100vh - 5rem);
    .View.Students {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: auto;
    }
    &.has-left-nav {
        .View.Students {
            left: 50px;
            width: calc (100% - 50px);
        }
    }
`;

export default StudentsContainer;
