import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { TimeUtil } from "lib/utils";
import { Form, Row, Col, Input, Table } from "antd";
const { TextArea } = Input;

function getParsedStudentInfo(studentData) {
    return studentData.examInfo || {};
}

function SummaryExamInfo({ studentData }) {
    const [examInfoData, setExamInfoData] = useState({});
    const [maxTOEFLScore, setMaxTOEFLScore] = useState(0);
    const [pickedMaxTOEFLScore, setPickedMaxTOEFLScore] = useState(0);
    const [maxSATScore, setMaxSATScore] = useState(0);
    const [pickedMaxSATScore, setPickedMaxSATScore] = useState(0);

    useEffect(() => {
        setExamInfoData(getParsedStudentInfo(studentData));
    }, [studentData, setExamInfoData]);

    const [TOEFLDataSource, TOEFLColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "TOEFL考试时间",
                    dataIndex: "examDate",
                    key: "examDate",
                    width: "140px",
                },
                {
                    title: "TOEFL注册号",
                    dataIndex: "registerAccount",
                    key: "registerAccount",
                },
                {
                    title: "TOEFL阅读成绩",
                    dataIndex: "readingScore",
                    key: "readingScore",
                },
                {
                    title: "TOEFL听力成绩",
                    dataIndex: "listeningScore",
                    key: "listeningScore",
                },
                {
                    title: "TOEFL口语成绩",
                    dataIndex: "speakingScore",
                    key: "speakingScore",
                },
                {
                    title: "TOEFL写作成绩",
                    dataIndex: "writingScore",
                    key: "writingScore",
                },
                {
                    title: "TOEFL总成绩",
                    dataIndex: "totalScore",
                    key: "totalScore",
                },
            ];
            let datasource = [];
            let readingScores = [];
            let listeningScores = [];
            let speakingScores = [];
            let writingScores = [];
            let totalScores = [];

            if (examInfoData.TOEFLScores && examInfoData.TOEFLScores.length) {
                _.each(examInfoData.TOEFLScores, function (item, index) {
                    let readingScore = item.readingScore || 0;
                    let listeningScore = item.listeningScore || 0;
                    let speakingScore = item.speakingScore || 0;
                    let writingScore = item.writingScore || 0;
                    let totalScore =
                        readingScore +
                        listeningScore +
                        speakingScore +
                        writingScore;
                    readingScores.push(readingScore);
                    listeningScores.push(listeningScore);
                    speakingScores.push(speakingScore);
                    writingScores.push(writingScore);
                    totalScores.push(totalScore);

                    datasource.push({
                        key: index,
                        examDate: TimeUtil.formatDate(item.examDate),
                        registerAccount: item.registerAccount,
                        readingScore,
                        listeningScore,
                        speakingScore,
                        writingScore,
                        totalScore,
                    });
                });

                setMaxTOEFLScore(Math.max(...totalScores));
                setPickedMaxTOEFLScore(
                    Math.max(...readingScores) +
                        Math.max(...listeningScores) +
                        Math.max(...speakingScores) +
                        Math.max(...writingScores)
                );
            } else {
                setMaxTOEFLScore(0);
                setPickedMaxTOEFLScore(0);
            }

            return [datasource, columns];
        },
        [examInfoData]
    );

    const [SATDataSource, SATColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "SAT1考试时间",
                    dataIndex: "examDate",
                    key: "examDate",
                    width: "140px",
                },
                {
                    title: "SAT1阅读成绩",
                    dataIndex: "readingScore",
                    key: "readingScore",
                },
                {
                    title: "SAT1数学成绩",
                    dataIndex: "mathScore",
                    key: "mathScore",
                },
                {
                    title: "SAT1总成绩",
                    dataIndex: "totalScore",
                    key: "totalScore",
                },
                {
                    title: "SAT*Reading",
                    dataIndex: "writingReadingScore",
                    key: "writingReadingScore",
                },
                {
                    title: "SAT*Analysis",
                    dataIndex: "writingAnalysisScore",
                    key: "writingAnalysisScore",
                },
                {
                    title: "SAT*Writing",
                    dataIndex: "writingScore",
                    key: "writingScore",
                },
            ];
            let datasource = [];
            let readingScores = [];
            let mathScores = [];
            let totalScores = [];

            if (examInfoData.SAT1Scores && examInfoData.SAT1Scores.length) {
                _.each(examInfoData.SAT1Scores, function (item, index) {
                    let readingScore = item.readingScore || 0;
                    let mathScore = item.mathScore || 0;
                    let writingScore = item.writingScore || 0;
                    let writingReadingScore = item.writingReadingScore || 0;
                    let writingAnalysisScore = item.writingAnalysisScore || 0;

                    let totalScore = readingScore + mathScore;
                    readingScores.push(readingScore);
                    mathScores.push(mathScore);
                    totalScores.push(totalScore);

                    datasource.push({
                        key: index,
                        examDate: TimeUtil.formatDate(item.examDate),
                        readingScore,
                        mathScore,
                        totalScore,
                        writingScore,
                        writingReadingScore,
                        writingAnalysisScore,
                    });
                });

                setMaxSATScore(Math.max(...totalScores));
                setPickedMaxSATScore(
                    Math.max(...readingScores) + Math.max(...mathScores)
                );
            } else {
                setMaxSATScore(0);
                setPickedMaxSATScore(0);
            }

            return [datasource, columns];
        },
        [examInfoData]
    );

    const [SAT2DataSource, SAT2Columns] = useMemo(
        function () {
            let columns = [
                {
                    title: "SAT2考试时间",
                    dataIndex: "examDate",
                    key: "examDate",
                    width: "140px",
                },
                {
                    title: "SAT2科目名称",
                    dataIndex: "subjectValue",
                    key: "subjectValue",
                },
                {
                    title: "SAT2科目分数",
                    dataIndex: "score",
                    key: "score",
                },
            ];
            let datasource = [];
            _.each(examInfoData.SAT2Scores, function (item, index) {
                datasource.push({
                    key: index,
                    examDate: TimeUtil.formatDate(item.examDate),
                    subjectValue: item.subjectValue,
                    score: item.score || 0,
                });
            });

            return [datasource, columns];
        },
        [examInfoData]
    );

    const [APDataSource, APColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "AP科目名称",
                    dataIndex: "subjectValue",
                    key: "subjectValue",
                },
                {
                    title: "AP科目分数",
                    dataIndex: "score",
                    key: "score",
                },
            ];
            let datasource = [];
            _.each(examInfoData.APScores, function (item, index) {
                datasource.push({
                    key: index,
                    examDate: TimeUtil.formatDate(item.examDate),
                    subjectValue: item.subjectValue,
                    score: item.score || 0,
                });
            });

            return [datasource, columns];
        },
        [examInfoData]
    );

    const [otherDataSource, otherColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "其他*考试时间",
                    dataIndex: "examDate",
                    key: "examDate",
                    width: "140px",
                },
                {
                    title: "其他*科目名称",
                    dataIndex: "subjectValue",
                    key: "subjectValue",
                },
                {
                    title: "其他*科目分数",
                    dataIndex: "score",
                    key: "score",
                },
            ];
            let datasource = [];
            _.each(examInfoData.OtherScores, function (item, index) {
                datasource.push({
                    key: index,
                    examDate: TimeUtil.formatDate(item.examDate),
                    subjectValue: item.subjectValue,
                    score: item.score || 0,
                });
            });

            return [datasource, columns];
        },
        [examInfoData]
    );

    return (
        <StyledContainer>
            <header className="section-header">考试分数</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                <div className="sub-title">TOEFL</div>
                <Row gutter={24} className="border-row mixed-table-row">
                    <Col span={6}>
                        <Form.Item
                            label={"TOEFL账号"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={examInfoData.TOEFLAccount} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TOEFL密码"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={examInfoData.TOEFLPwd} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"最高分"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={maxTOEFLScore} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"拼分"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={pickedMaxTOEFLScore} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Table
                            dataSource={TOEFLDataSource}
                            columns={TOEFLColumns}
                            size="small"
                            pagination={false}
                            bordered
                        />
                    </Col>
                </Row>
                <div className="sub-title">SAT</div>
                <Row gutter={24} className="border-row mixed-table-row">
                    <Col span={6}>
                        <Form.Item
                            label={"SAT账号"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={examInfoData.SATAccount} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"SAT密码"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={examInfoData.SATPwd} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"最高分"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={maxSATScore} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"拼分"}
                            colon={false}
                            labelAlign="left"
                        >
                            <Input readOnly value={pickedMaxSATScore} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Table
                            dataSource={SATDataSource}
                            columns={SATColumns}
                            size="small"
                            pagination={false}
                            bordered
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8} className="not-for-print">
                        <div className="sub-title">SAT2</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={SAT2DataSource}
                                columns={SAT2Columns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                    <Col span={8} className="not-for-print">
                        <div className="sub-title">AP</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={APDataSource}
                                columns={APColumns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                    <Col span={8} className="not-for-print">
                        <div className="sub-title">其它考试</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={otherDataSource}
                                columns={otherColumns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                    <Col span={24} className="only-for-print">
                        <div className="sub-title">SAT2</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={SAT2DataSource}
                                columns={SAT2Columns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                    <Col span={24} className="only-for-print">
                        <div className="sub-title">AP</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={APDataSource}
                                columns={APColumns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                    <Col span={24} className="only-for-print">
                        <div className="sub-title">其它考试</div>
                        <Row className="border-row pure-table-row">
                            <Table
                                dataSource={otherDataSource}
                                columns={otherColumns}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        </Row>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="备注" colon={false} labelAlign="left">
                            <TextArea readOnly value={examInfoData.remark} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})`
    .border-row.mixed-table-row {
        padding-bottom: 10px;
    }
    .border-row.pure-table-row {
        padding: 10px;
    }
`;

SummaryExamInfo.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryExamInfo;
