import _ from "lodash";
import traitsMeta from "./trait.json";
import majorMeta from "./majorMeta";

const utils = {
    parseTrait: function (traits, lowRankingBoundary, highRankingBoundary) {
        const parsedTraits = [];
        _.each(traits, function (score, key) {
            const mataTrait = traitsMeta[key];
            let level = "";
            if (score >= highRankingBoundary) {
                level = "A";
            } else if (
                score >= lowRankingBoundary &&
                score < highRankingBoundary
            ) {
                level = "B";
            } else {
                level = "C";
            }

            parsedTraits.push({
                type: mataTrait.name,
                desc: mataTrait.definition,
                score,
                suggestion: (mataTrait.level || {})[level],
                // category: "能力基础",
            });
        });
        return parsedTraits;
    },

    // major_recommendation is responsed by API
    getTop5MajorsNew: function (major_recommendation) {
        const majorArray = _.map(major_recommendation, function (value, key) {
            return {
                key: key,
                score: value,
            };
        });
        let sortedMajors = _.sortBy(majorArray, ["score"]).reverse();
        sortedMajors = sortedMajors.slice(
            0,
            process.env.REACT_APP_MAJOR_DISPLAY_COUNT || 5
        );
        return sortedMajors;
    },

    // major_recommendation is responsed by API
    parseMajors: function (major_recommendation) {
        // [{key: "economics",score: 0.25974985110184634}, ...]
        const majorKeyValueArray = utils.getTop5MajorsNew(major_recommendation);

        return _.map(majorKeyValueArray, function (item) {
            const matchedMajor = majorMeta[item.key];
            return {
                ...matchedMajor,
                ...item,
            };
        });
    },

    getMajorUltimatePages: function (major, isShowAllTitle) {
        // 1122(A4 px in 96dpi) - 40(header) - 50(top padding)
        const ALLOW_HEIGHT = 1122 - 40 - 50;
        const a4Pages = [];

        let page = [
            {
                type: "banner",
                height: 165, // 120 + 45,
            },
            {
                type: "subMajorTitle",
                height: 50, // 20 + 30
            },
        ];

        if (isShowAllTitle) {
            page.unshift({
                type: "allTitleSection",
                height: 110, // 95 + 15,
            });
        }

        let restHeight = ALLOW_HEIGHT - 165 - 50;
        if (isShowAllTitle) {
            restHeight = restHeight - 110;
        }
        _.each(major.subs, function (item) {
            const itemHeight =
                30 + // title
                40 + // rate
                50 + // margin-bottom
                // 56 char one row
                Math.ceil(item.target?.length / 56) * 20 +
                Math.ceil(item.course?.length / 56) * 20;

            // 30 is the buffer, since the margin-bottom is 50
            if (restHeight - itemHeight < 30) {
                // need a new page
                a4Pages.push([...page]);
                page = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - itemHeight;
            page.push({
                type: "subMajor",
                height: itemHeight,
                data: item,
            });
        });

        // sub title2 begin
        // subTitle height is 50, padding bottom is 50
        if (restHeight < 100) {
            // need a new page
            a4Pages.push([...page]);
            page = [];
            restHeight = ALLOW_HEIGHT;
        }

        restHeight = restHeight - 50;
        page.push({
            type: "subJobTitle",
            height: 50,
        });
        // sub title2 end

        _.each(major.jobs, function (item) {
            let contentHeight = 0;
            _.each((item.content || "").split("\r\n"), function (oneContent) {
                contentHeight =
                    contentHeight + Math.ceil(oneContent.length / 56) * 20;
            });

            const qualificationHeight =
                major.qualification && major.qualification !== "none" ? 55 : 0;

            const itemHeight =
                35 + // title
                84 + // picture
                50 + // margin-bottom
                contentHeight +
                qualificationHeight;

            // 30 is the buffer, since the margin-bottom is 50
            if (restHeight - itemHeight < 30) {
                // need a new page
                a4Pages.push([...page]);
                page = [];
                restHeight = ALLOW_HEIGHT;
            }

            restHeight = restHeight - itemHeight;
            page.push({
                type: "subJob",
                height: itemHeight,
                data: item,
            });
        });

        a4Pages.push([...page]);

        return a4Pages;
    },
};

export default utils;
