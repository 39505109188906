import React from 'react'
import { i18n } from '../lib'

// Local styling required to override recharts
const tooltipStyle = {
    background: 'rgba(16, 32, 55, 0.9)',
    minWidth: '10rem',
    color: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '8px'
}
const hStyle = {
    margin: '0.5rem 0',
    fontSize: '1rem'
}
const pStyle = {
    margin: 0,
    padding: 0,
    fontSize: '0.875rem',
    textAlign: 'left'
}
/**
 * Charting tooltip with custom styling
 * 
 * @param {Object} props tooltip props (passed in from recharts) 
 */
const Tooltip = (props) => {
    const packet = props.payload[0];
    const subjects = [];
    if (packet && !packet.payload.hide){
        const { name, total, breakdown, max } = packet.payload;
        for (var key in breakdown){
            subjects.push(t(key.toUpperCase()) + ": " + breakdown[key]);
        }
        const title = t(subjects.length > 0 ? 'TOTAL' : 'SCORE');
        // console.log("PROPS: ", packet.payload);
        return (
            <div className="tooltip exam" style={tooltipStyle}>
                {name && 
                    <h2>{name}</h2>
                }
                <h3 style={hStyle}>{title + ": " + total + "/" + max}</h3>
                {subjects.map(text => (
                    <p key={text} style={pStyle}>{text}</p>
                ))}
            </div>
        )
    }
    return (<></>);
}

function t(key){
    return i18n.t('EXAMS.' + key);
}

export default Tooltip