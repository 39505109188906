import { DataConverter } from '../lib/utils'
import tiers from "./tiers"
import infoSpec from "./infoSpec"

const data = {
    'USER': {},
    'FILTERS': {},
    'STUDENTS': [],
    'STUDENTS_SORT': {},
    'ADMIN_ADVISORS': {},
    'ADMIN_STUDENTS': {},
    'ADMIN_ADVISORS_SORT': {},
    'ADMIN_STUDENTS_SORT': {},
    'ADMIN_PROSPECTS_SORT': {},
    'CAMPUSES': {},
    'MAJOR_DETAILS': [],
    'TIERS': [],
    'NOTES': {},
    'EXAMS': {},
    'EXAM_TYPES': {},
    'SCHOOLS': {},
    'SCHOOL_FILTERS': [],
    'RENDER_CONDITIONS': {}
};

/**
 * Data Store {Singleton}
 *
 * The data store is a simple data cache that is used to store data shared by
 * many components on many levels. Only static, non-state-dependent data should
 * be stored here. Most of the data should be treated as immutable, and refreshed
 * only after receiving new data from the server.
 *
 * The only exception to this is data used to hold state from page to page, i.e.
 * sorting settings. In the future, this can be handled with cookies.
 */
export default {
    /**
     * Initialize static data
     */
    init(){
        this.set('TIERS', tiers);
        this.set('INFO_SPEC', infoSpec);
        this.set('MAJOR_DETAILS', []);
        this.set('QUESTIONS', {});
        this.set('NOTES', {});
        this.set('EXAMS', {});
        this.set('SCHOOLS', {});
        this.set('SCHOOL_FILTERS', []);
        this.set('STUDENTS_SORT', {});
        this.set('ADMIN_ADVISORS_SORT', {});
        this.set('ADMIN_STUDENTS_SORT', {});
        this.set('ADMIN_PROSPECTS_SORT', {});
        this.set('CAMPUSES', null);
        this.set('ADMIN_ADVISORS', null);
        this.set('ADMIN_STUDENTS', null);
    },
    /**
     * Retrieve a data store object by key
     *
     * @param {String} key  data key
     */
    get(key){
        return data[key];
    },
    /**
     * Directly set the value of a data store object
     *
     * @param {String} key  data key
     * @param {Object} val  data value
     */
    set(key, val){
        data[key] = val;
    },
    /**
     * Process and store data. This is designed to handle raw API responses and
     * convert them into an App-friendly format.
     *
     * @param {String} key  data key, matching the keys defined in 'data' above
     * @param {Object} data raw json from server
     */
    process(key, data){
        this.set(key, DataConverter.process(key, data));
    },
    /**
     * Wipe all data from store
     */
    clear(){
        for (var key in data){
            delete data[key]
        }
    },
    updateStudent(id, params){
        const student = this.get("STUDENTS").find(res => {
            return res.id.toString() === id;
        });
        // console.log("FETCH STUDENT:", student);
        return DataConverter.updateStudent(student, params);
    },
    newStudent(user, phoneNumber){
        const students = this.get("STUDENTS");
        students.push(DataConverter.newStudent(user, phoneNumber));
        this.set('STUDENTS', students);
    },
    getNote(student_id, noteid){
        var notes = this.get("NOTES")[student_id] || [];
        var note = notes.find(res => {
            return res.id.toString() === noteid.toString();
        })
        if (note){
            note.readOnly = note.createdBy.id !== this.get('USER').id;
        }
        return note;
    },
    newNote(student_id){
        const data = DataConverter.newNote(this.get('USER'));
        this.get("NOTES")[student_id].splice(0, 0, data);
        return data;
    },
    saveNote(student_id, noteid, noteData){
        var storeVersion = this.getNote(student_id, noteid);
        // console.log("SAVE NOTE: ", noteid, storeVersion, noteData);
        if (!storeVersion){
            return;
        }
        for (var key in noteData){
            storeVersion[key] = noteData[key];
        }
    },
    destroyNote(student_id, noteid){
        var notes = this.get("NOTES")[student_id];
        for (var i=0; i<notes.length; i++){
            if (notes[i].id === noteid){
                notes.splice(i, 1);
                return true;
            }
        }
        console.warn("DESTROY NOTE FAILED: could not find note with id [" + noteid + "]");
    }
};
