import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ProgressPlus from "report/component/ProgressPlus";
import ReportHeader from "../ReportHeader";
import Pager from "../Pager";
import MajorBanner from "./MajorBanner";
import majorMeta from "./majorMeta";
import majorImg from "img/report/vision2022/major-detail.png";
import jobImg from "img/report/vision2022/job-target.png";

function getColor(percent) {
    const colors = ["#EB5BA3", "#FD9D2C", "#00BFD6"];
    return colors[Math.min(Math.floor(percent * 3), 2)];
}

function MajorList({ title, majors, isFirstPage }) {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            {isFirstPage ? (
                <React.Fragment>
                    <div className="part-header">
                        <div className="color-bar"></div>
                        专业推荐详解
                    </div>
                    <div className="part-desc">
                        基于海量学生的游戏和行为数据，本测评系统建立了前沿的统计学和机器学习的预测匹配模型，来刻画学生的行为特质、实现个性化的专业推荐。
                        <br />
                        个性化专业推荐模型首先通过 VisionGuide
                        APP端的游戏和问卷数据测量学生的个性特质，之后将学生的个性特质档案与数据库中的海量的国内外大学生档案进行匹配，根据其相似程度推荐对应的专业。
                        <br />
                        基于该学生的游玩数据，个性化专业推荐模型的专业推荐结果如下，在您做出最终申请决策前，请在参考以上推荐结果的同时，综合考量其他相关信息及因素。
                    </div>
                </React.Fragment>
            ) : null}
            {_.map(majors, function (majorData, index) {
                if (!majorData) {
                    return null;
                }

                const parsedMajorData = _.find(majorMeta.majorDetail, {
                    key: String(majorData.key).toUpperCase(),
                });
                parsedMajorData.score = majorData.value;

                return (
                    <div className="major-wrapper" key={index}>
                        <MajorBanner
                            major={{
                                key: parsedMajorData.key,
                                score: parsedMajorData.score,
                                name: parsedMajorData.name.zh,
                                desc: parsedMajorData.desc.zh,
                            }}
                            color={getColor(
                                Math.min(parsedMajorData.score + 0.25, 1)
                            )}
                        />
                        <div className="major-detail">
                            <div className="major-detail-title">
                                <img alt="" src={majorImg} />
                                <span>专业细分</span>
                            </div>
                            <div className="major-subitems-wrapper">
                                {_.map(
                                    parsedMajorData.sub_majors.zh.split(","),
                                    function (subItem, subItemIndex) {
                                        return (
                                            <div
                                                className="major-subitem"
                                                key={subItemIndex}
                                            >
                                                {subItem}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div className="career-title">
                                <img alt="" src={jobImg} />
                                <span>职业方向</span>
                            </div>
                            <div className="careers-wrapper">
                                {_.map(
                                    parsedMajorData.careers.zh.split(","),
                                    function (career, careerIndex) {
                                        return (
                                            <div
                                                className="career-subitem"
                                                key={careerIndex}
                                            >
                                                {career}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div className="float-wrapper">
                                <div className="float-title">
                                    获得研究生学位比例
                                </div>
                                <ProgressPlus
                                    value={parsedMajorData.grad_rate}
                                    width={80}
                                    color={getColor(parsedMajorData.grad_rate)}
                                />
                                <div className="float-title2">平均年收入</div>
                                <div className="avg-salary">
                                    {parsedMajorData.avg_salary}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Pager />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    padding-bottom: 50px;
    .section-header {
        margin-bottom: 50px;
    }

    .part-header {
        width: 100%;
        position: relative;
        padding-left: 42px;
        font-weight: bold;
        font-size: 48px;
        line-height: 70px;
        color: #1b2224;
        margin-bottom: 24px;
        .color-bar {
            width: 16px;
            height: 42px;
            background: #30b9f8;
            position: absolute;
            left: 0;
            top: 14px;
        }
    }
    .part-desc {
        padding-left: 42px;
        padding-right: 42px;
        font-size: 18px;
        line-height: 36px;
        color: #47525d;
        margin-bottom: 48px;
    }
    .major-banner {
        margin-bottom: 8px;
    }
    .major-detail {
        position: relative;
        width: 100%;
        padding: 24px 60px;
        background: #f7f9fa;
        margin-bottom: 32px;
        .major-detail-title,
        .career-title {
            height: 32px;
            margin-bottom: 16px;
            img {
                width: 32px;
                height: 30px;
                margin-right: 8px;
            }
            span {
                font-weight: bold;
                font-size: 18px;
                color: #4386cb;
                vertical-align: middle;
            }
        }

        .career-title {
            margin-top: 32px;
        }

        .major-subitems-wrapper,
        .careers-wrapper {
            width: 520px;
            display: flex;
            flex-wrap: wrap;
            .major-subitem,
            .career-subitem {
                margin-right: 16px;
                margin-bottom: 8px;
                padding: 8px;
                height: 40px;
                background: #ffffff;
                border-radius: 4px;
                text-align: center;
                color: #60728e;
                font-weight: bold;
                font-size: 16px;
            }
        }

        .float-wrapper {
            position: absolute;
            right: 60px;
            top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 18px;
            .float-title {
                font-weight: bold;
                font-size: 12px;
                color: #60728e;
                margin-bottom: 16px;
            }
            .float-title2 {
                margin-top: 16px;
                margin-bottom: 8px;
            }
            .avg-salary {
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                color: #00b6ff;
            }
        }
    }
`;

MajorList.propTypes = {
    title: propTypes.string.isRequired,
    majors: propTypes.array.isRequired,
    isFirstPage: propTypes.bool,
};

export default MajorList;
