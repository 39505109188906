import React, { useState, useEffect, useContext, useCallback } from "react";
// import _ from "lodash";
import styled from "styled-components";
// import Router from "lib/Router";
// import LeftNavBar from "routes/LeftNavBar";
import { LeftOutlined } from "@ant-design/icons";
import SummaryBaseInfo from "./SummaryBaseInfo";
import SummaryContact from "./SummaryContact";
import SummaryExamInfo from "./SummaryExamInfo";
import SummaryHighSchool from "./SummaryHighSchool";
import SummaryCoreApply from "./SummaryCoreApply";
import SummaryApplyMaterial from "./SummaryApplyMaterial";
import SummarySubmissionStatus from "./SummarySubmissionStatus";
import SummaryTrackadMission from "./SummaryTrackadMission";
import SummaryAdmissionInfo from "./SummaryAdmissionInfo";
import ApplyStatus from "components/ApplyStatus";
import printSVG from "img/download2.svg";
import { Request } from "lib";
import { message, Spin } from "antd";
import SummaryContext from "./SummaryContext";
import propTypes from "prop-types";

function StudentInfo({ contractId }) {
    const [studentData, setStudentData] = useState({
        studentInfo: {},
        contractInfo: {},
    });
    const [loading, setLoading] = useState(false);
    let { hideSummary } = useContext(SummaryContext);
    useEffect(() => {
        setLoading(true);
        Request.GET("excel/personal/" + encodeURIComponent(contractId), {
            onSuccess: (response) => {
                setLoading(false);
                setStudentData(response.data);
                // console.log(response.data);
            },
            onFail: (status) => {
                setLoading(false);
                if (status === 401) return true;
                message.error("查询失败");
            },
        });
    }, [contractId, setStudentData]);

    const handlePrint = useCallback(function () {
        window.print();
    }, []);

    return (
        <StyledContainer>
            {/* <LeftNavBar currentPage="trackingsheet" /> */}
            <Spin size="large" tip="Loading..." spinning={loading}>
                <div className="student-info">
                    <header>
                        <div
                            className="back-con"
                            onClick={() => {
                                hideSummary();
                            }}
                        >
                            <LeftOutlined />
                            <span>回到总表</span>
                        </div>
                        <div className="title-con">
                            <span>{studentData.studentInfo.name}</span>
                            <ApplyStatus
                                statusCode={
                                    studentData.contractInfo.applyStatus || ""
                                }
                            />
                        </div>
                        <button
                            className="print-btn"
                            onClick={handlePrint}
                        ></button>
                    </header>
                    <SummaryBaseInfo studentData={studentData} />
                    <SummaryContact studentData={studentData} />
                    <SummaryExamInfo studentData={studentData} />
                    <SummaryHighSchool studentData={studentData} />
                    <SummaryCoreApply studentData={studentData} />
                    <SummaryApplyMaterial studentData={studentData} />
                    <SummarySubmissionStatus studentData={studentData} />
                    <SummaryTrackadMission studentData={studentData} />
                    <SummaryAdmissionInfo studentData={studentData} />
                </div>
            </Spin>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "student-info-container",
})`
    /* height: 100%; */
    height: calc(100vh - 5rem);
    overflow: auto;
    /* padding-bottom: 100px; */

    .student-info {
        padding: 20px;
        padding-left: 70px;
        /* width: calc(100% - 50px); */
        min-height: 100vh;
        color: rgba(0, 0, 0, 0.85);
        textarea {
            resize: none;
        }
        > header {
            position: relative;
            .back-con {
                cursor: pointer;
                position: absolute;
                left: 0;
                font-size: 14px;
            }
            .title-con {
                font-size: 18px;
                font-weight: bold;
                width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                .apply-status {
                    margin: auto 15px;
                }
            }

            .print-btn {
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 0;
                background: url(${printSVG});
                background-size: contain;
                background-repeat: no-repeat;
                border: 0;
                padding: 1rem;
                height: 1rem;
                width: 1rem;
                z-index: 1;
            }
        }
        .info-section {
            margin-top: 40px;
            .section-header {
                font-size: 16px;
                font-weight: bold;
                padding: 5px;
                text-align: left;
            }

            .section-form {
                padding: 24px;
                padding-bottom: 0;
                background: #fbfbfb;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                .ant-form-item {
                    display: flex;
                    /* height: 40px; */
                    line-height: 16px;
                }
                .ant-form-item-control-wrapper {
                    flex: 1;
                }
                .ant-form-item-label > label {
                    width: 108px;
                    white-space: normal;
                    word-break: break-word;
                }

                .sub-title {
                    text-align: left;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 5px;
                }

                .ant-row.border-row {
                    border: 1px solid #d9d9d9;
                    border-radius: 5px;
                    margin-bottom: 25px;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    .ant-form-item {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                .ant-table-thead > tr > th {
                    border-right: 1px solid #f0f0f0;
                    background-color: #fafafa;
                    /* background: rgb(189, 189, 189); */
                    border-bottom: 1px solid #f0f0f0;
                    padding: 4px;
                }

                .ant-input {
                    border: none;
                }
                .ant-input:focus,
                .ant-input-focused {
                    border: none;
                    box-shadow: none;
                }
                textarea {
                    cursor: default;
                }
            }
        }
    }

    .only-for-print {
        display: none;
    }
    @media print {
        @page {
            /* portrait */
            /* size: A4 landscape; */
            /* 边距 上右下左 */
            margin: 1cm 1cm 1cm 1cm;
        }
        height: auto;
        .back-con {
            display: none;
        }
        .print-btn {
            display: none;
        }
        /* .ant-col, */
        /* .ant-row, */
        .ant-table {
            page-break-inside: avoid;
        }

        .ant-row.page-break-auto {
            page-break-inside: auto;
        }

        .student-info {
            padding-left: 5px;
            padding-right: 5px;
        }

        .not-for-print {
            display: none;
        }
        .only-for-print {
            display: block;
            .sub-title.break-before {
                page-break-before: always;
            }
        }
        /* .section-header {
            page-break-after: avoid !important;
        } */
    }
`;

StudentInfo.propTypes = {
    contractId: propTypes.string.isRequired,
};

export default StudentInfo;
