import React, { Component } from "react";
import Card from "../Card";
import { ExamTooltip } from "../";
// import { i18n } from "../../lib"
import { ColorGenerator, ExamUtil, clamp, rem } from "../../lib/utils";
import { RadialBarChart, RadialBar, PolarAngleAxis, Tooltip } from "recharts";
import { Loader } from "semantic-ui-react";
import { i18n } from "../../lib";

class ExamSummary extends Component {
    state = {
        r: 0,
        barSize: 0,
        data: [],
        loaded: false,
    };
    componentDidMount() {
        const { studentId } = this.props;
        ExamUtil.getStudentExams(studentId, (examData) => {
            const data = [{ name: "-", value: 0, hide: true }];
            const summary = {};
            const colors = new ColorGenerator({ protocol: "MIX" });

            // Filter exam data and only use the highest score for each test type
            for (var d = 0; d < examData.length; d++) {
                const exam = Object.assign({}, examData[d]);
                if (exam.deleted || exam.id === -1) {
                    continue;
                }
                const currentData = summary[exam.test];
                if (!summary.hasOwnProperty(exam.test)) {
                    updateExamFields(exam);
                    exam.fill = colors.next();
                    summary[exam.test] = exam;
                } else if (exam.total > currentData.total) {
                    updateExamFields(currentData, exam);
                }
            }
            for (var type in summary) {
                data.push(summary[type]);
            }
            // console.log("EXAM DATA: ", data);

            this.setState({
                data: data,
                loaded: true,
            });
        });

        function updateExamFields(target, newData) {
            const { type, subject, total, max, breakdown } = newData || target;
            target.name = type + (subject ? " " + subject : "");
            target.value = (total / max) * 100;
            if (breakdown) {
                target.breakdown = breakdown;
            }
        }
    }
    /**
     * Determine graph radius and bar size from constraints.
     *
     * Graph has several constraints:
     *  1. must be smaller than the dashboard viewport (~VH - 12rem)
     *  2. bars must be at least 4px thick
     *  3. bars should be 1rem thick with 0.125rem buffer, if constraints 1 and 2 are
     *      valid.
     */
    getGraphSize() {
        const maxRadius = (window.innerHeight - rem(12)) / 2;
        const idealBarSize = rem(1);
        const minBarSize = 4;
        const buffer = 0.8;
        const numBars = this.state.data.length;
        const r0 = rem(1);

        const minRadius = r0 + (minBarSize / buffer) * numBars;
        const idealRadius = r0 + (idealBarSize / buffer) * numBars;
        const r = clamp(idealRadius, minRadius, maxRadius);
        const barSize = Math.round(((r - r0) / numBars) * buffer);

        return { r0, r, barSize };
    }
    render() {
        const { data, loaded } = this.state;
        const { r0, r, barSize } = this.getGraphSize();
        const isEmpty = data.length === 1;
        // const minWidthStyle = {
        //     minWidth: (r + 300) * 2 + 'px'
        // };

        return (
            <Card {...this.props} type="exam_summary">
                {isEmpty && (
                    <div className="no_data">{i18n.t("EXAMS.NO_DATA")}</div>
                )}
                {!isEmpty && (
                    <div className="chartContainer">
                        <div className="legend">
                            {data.map(
                                ({ name, fill, hide }) =>
                                    !hide && (
                                        <div key={name} className="exam_label">
                                            <div
                                                className="dot"
                                                style={{ background: fill }}
                                            />
                                            <span>{name}</span>
                                        </div>
                                    )
                            )}
                        </div>
                        <RadialBarChart
                            width={r * 2}
                            height={r * 2}
                            barSize={barSize}
                            cx={r}
                            cy={r}
                            innerRadius={r0}
                            outerRadius={r}
                            startAngle={450}
                            endAngle={90}
                            data={data}
                        >
                            <PolarAngleAxis
                                type="number"
                                domain={[0, 100]}
                                angleAxisId={0}
                                tick={false}
                            />
                            <RadialBar
                                angleAxisId={0}
                                animationDuration={200}
                                background
                                dataKey="value"
                            />
                            <Tooltip
                                content={<ExamTooltip />}
                                cursor={{ fill: "rgba(0, 0, 0, 0.1)" }}
                            />
                        </RadialBarChart>
                        <Loader active={!loaded} />
                    </div>
                )}
            </Card>
        );
    }
}

export default ExamSummary;
