export default [
    {
        type: "TOEFL", 
        columns: [
            "DATE", "TOTAL", "READING", "LISTENING", "WRITING", "SPEAKING"
        ], 
        bars: ["READING", "LISTENING", "WRITING", "SPEAKING"],
        max: 120
    },
    {
        type: "SAT",
        columns: [
            "DATE", "TOTAL", "EBRW", "MATH"
        ], 
        bars: [
            "EBRW", "MATH"
        ],
        max: 1600
    }, 
    {
        type: "SAT2", 
        columns: [
            "DATE", "SUBJECT", "SCORE"
        ], 
        bars: ["TOTAL"],
        max: 800
    }, 
    {
        type: "AP", 
        columns: [
            "DATE", "SUBJECT", "SCORE"
        ], 
        bars: ["TOTAL"],
        max: 5
    }
]