import React, { Component } from "react";
import { Col, Row, Modal, message, Button as AntButton, Select } from "antd";
import { Request } from "../../lib";
import { Button } from "semantic-ui-react";
import { RippleButton } from "../../components";
import { i18n } from "../../lib";
import _ from "lodash";
import ApplyStatus, { codeTextMap } from "components/ApplyStatus";

const { Option } = Select;

class ChangeStatus extends Component {
    state = {
        statusChange: null,
        visible: false,
    };
    color = "red";
    bordercolor = "red";

    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ visible: this.props.visible });
    };

    handleStatusChange = (value) => {
        this.setState({ statusChange: value });
    };

    onOk = () => {
        if(_.isNil(this.state.statusChange)){
            message.error("请选择状态");
            return false;
        }
        if (this.props.applyStatus === this.state.statusChange) {
            this.onCancel();
        } else {
            Request.POST("contractinfo", {
                data: {
                    attr:"applyStatus",
                    val:this.state.statusChange ,
                    id: this.props.contractId,
                },
                onSuccess: (data) => {
                    this.setState({statusChange:null});
                    if (this.props.fetchData) {
                        this.props.fetchData();
                    }
                    if(this.props.closeModal){
                        this.props.closeModal();
                    }
                },
                onFail: (status, data) => {
                    if (status === 401) return true;
                    message.error(data);
                },
            });
        }
    };
    onCancel = () => {
        this.setState({statusChange:null});
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.setState({ visible: false });
        }
    };

    render() {
        return (
            <>
                {this.props.isShowText === false ? null : (
                    <AntButton
                        type="link"
                        onClick={() => {
                            this.setState({ visible: true });
                        }}
                        style={{
                            padding: "0px",
                            margin: "0px",
                            height: "20px",
                            ...this.props.style,
                        }}
                    >
                        更改状态
                    </AntButton>
                )}
                <Modal
                    visible={this.state.visible}
                    title={"更改状态: " + this.props.studentName}
                    centered={true}
                    onCancel={this.onCancel}
                    width="400px"
                    footer={[
                        <RippleButton
                            empty
                            flat
                            text={i18n.t("APP.CANCEL")}
                            onClick={this.onCancel}
                        />,
                        <Button
                            positive
                            disabled={false}
                            content={i18n.t("APP.SUBMIT")}
                            onClick={this.onOk}
                        />,
                    ]}
                >
                    <Row>
                        <Col span={5}>
                            <ApplyStatus statusCode={this.props.applyStatus} />
                        </Col>
                        <Col
                            span={2}
                            style={{
                                lineHeight: "30px",
                                marginLeft: "35px",
                            }}
                        >
                            →
                        </Col>
                        <Col span={14}>
                            <Select
                                placeholder="选择状态"
                                style={{ width: 160 }}
                                onChange={this.handleStatusChange}
                                value={this.state.statusChange}
                            >
                                {_.map(codeTextMap, function (
                                    statusText,
                                    statusCode
                                ) {
                                    return (
                                        <Option
                                            value={statusCode}
                                            key={statusCode}
                                        >
                                            {statusText}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}
export default ChangeStatus;
