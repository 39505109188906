import { Strategy, Type } from '../enum/Filter'

export default [
    { name: 'GENERAL', filters: [
        {
            type: Type.TEXT,
            label: 'SCHOOL_NAME',
            keys: ['INSTNM'],
            strategy: Strategy.SUBSTRING
        },{
            type: Type.SELECT,
            label: 'LOCATION',
            keys: ['STABBR'],
            options: [
                {key: 'Alabama', value: 'AL'},
                {key: 'Alaska', value: 'AK'},
                {key: 'Arizona', value: 'AZ'},
                {key: 'Arkansas', value: 'AR'},
                {key: 'California', value: 'CA'},
                {key: 'Colorado', value: 'CO'},
                {key: 'Connecticut', value: 'CT'},
                {key: 'Delaware', value: 'DE'},
                {key: 'Florida', value: 'FL'},
                {key: 'Georgia', value: 'GA'},
                {key: 'Hawaii', value: 'HI'},
                {key: 'Idaho', value: 'ID'},
                {key: 'Illinois', value: 'IL'},
                {key: 'Indiana', value: 'IN'},
                {key: 'Iowa', value: 'IA'},
                {key: 'Kansas', value: 'KS'},
                {key: 'Kentucky', value: 'KY'},
                {key: 'Louisiana', value: 'LA'},
                {key: 'Maine', value: 'ME'},
                {key: 'Maryland', value: 'MD'},
                {key: 'Massachusetts', value: 'MA'},
                {key: 'Michigan', value: 'MI'},
                {key: 'Minnesota', value: 'MN'},
                {key: 'Mississippi', value: 'MS'},
                {key: 'Missouri', value: 'MO'},
                {key: 'Montana', value: 'MT'},
                {key: 'Nebraska', value: 'NE'},
                {key: 'Nevada', value: 'NV'},
                {key: 'New Hampshire', value: 'NH'},
                {key: 'New Jersey', value: 'NJ'},
                {key: 'New Mexico', value: 'NM'},
                {key: 'New York', value: 'NY'},
                {key: 'North Carolina', value: 'NC'},
                {key: 'North Dakota', value: 'ND'},
                {key: 'Ohio', value: 'OH'},
                {key: 'Oklahoma', value: 'OK'},
                {key: 'Oregon', value: 'OR'},
                {key: 'Pennsylvania', value: 'PA'},
                {key: 'Rhode Island', value: 'RI'},
                {key: 'South Carolina', value: 'SC'},
                {key: 'South Dakota', value: 'SD'},
                {key: 'Tennessee', value: 'TN'},
                {key: 'Texas', value: 'TX'},
                {key: 'Utah', value: 'UT'},
                {key: 'Vermont', value: 'VT'},
                {key: 'Virginia', value: 'VA'},
                {key: 'Washington', value: 'WA'},
                {key: 'West Virginia', value: 'WV'},
                {key: 'Wisconsin', value: 'WI'},
                {key: 'Wyoming', value: 'WY'}
            ],
            strategy: Strategy.MULTIPLE
        },{
            type: Type.SELECT,
            label: 'REGION',
            keys: ['LOCALE'],
            options: [
                {key: 'LOCALE_11', value: 11},
                {key: 'LOCALE_12', value: 12},
                {key: 'LOCALE_13', value: 13},
                {key: 'LOCALE_21', value: 21},
                {key: 'LOCALE_22', value: 22},
                {key: 'LOCALE_23', value: 23},
                {key: 'LOCALE_31', value: 31},
                {key: 'LOCALE_32', value: 32},
                {key: 'LOCALE_33', value: 33},
                {key: 'LOCALE_41', value: 41},
                {key: 'LOCALE_42', value: 42},
                {key: 'LOCALE_43', value: 43}
            ],
            strategy: Strategy.MATCH
        },{
            type: Type.SELECT,
            label: 'SCHOOL_TYPE_I',
            keys: ['CONTROL'],
            options: [
                {key: 'CONTROL_1', value: 1},
                {key: 'CONTROL_2', value: 2},
                {key: 'CONTROL_3', value: 3}
            ],
            strategy: Strategy.MATCH
        },{
            type: Type.SELECT,
            label: 'SCHOOL_TYPE_II',
            keys: ['SCHOOL_TYPE'],
            options: [
                {key: 'NON_LIBERAL_ARTS', value: 1},
                {key: 'LIBERAL_ARTS', value: 2}
            ],
            strategy: Strategy.MATCH
        }
    ]},
    { name: 'ENROLLMENT', filters: [
        {
            type: Type.SELECT,
            label: 'GENDER',
            keys: ['MENONLY', 'WOMENONLY'],
            options: [
                {key: 'MENONLY', value: '10'},
                {key: 'WOMENONLY', value: '01'}
            ],
            strategy: Strategy.BITWISE_AND
        },
        {
            type: Type.RANGE,
            label: 'UGDS_NRA',
            keys: ['UGDS_NRA'],
            min: 0,
            max: 0.5,
            units: ['', '%'],
            strategy: Strategy.RANGE
        },
        {
            type: Type.RANGE,
            label: 'RANKING',
            keys: ['RANK'],
            min: 1,
            max: 200,
            units: ['#', ''],
            strategy: Strategy.RANGE
        },
        {
            type: Type.RANGE,
            label: 'TUITION',
            keys: ['TUITIONFEE_OUT'],
            min: 0,
            max: 100000,
            units: ['$', ''],
            strategy: Strategy.RANGE
        }
    ]}
]
