import React from "react";
import styled from "styled-components";

function SiteRecord() {
    return (
        <StyledContainer>
            <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
            >
                京ICP备18058029号-1
            </a>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "site-record",
})`
    color: rgb(131, 140, 150);
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    a {
        color: #4183c4;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
    }
`;

export default SiteRecord;
