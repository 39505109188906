module.exports = {
    Strategy: {
        /**
         * Check field for exact string match
         * Field Type: `String | Number`
         * Value Type: `String | Number`
         */
        MATCH: 'MATCH',
        /**
         * Non-Case-Sensitive substring check
         * Field Type: `String`
         * Value Type: `String`
         */
        SUBSTRING: 'SUBSTRING',
        /**
         * Check for value match in multiple fields
         * Field Type: `String | Number`
         * Value Type: `String | Number`
         */
        MULTIPLE: 'MULTIPLE',
        /**
         * Boolean check if value matches field (true-true, false-false)
         * Field Type: `String | Number`
         * Value Type: `Number`
         */
        EXISTS: 'EXISTS',
        /**
         * Check if field falls within a value range
         * Field Type: `Number`
         */
        RANGE: 'RANGE',
        /**
         * Check any field for an exact match against character bits in value
         * Field Type: `String | Number`
         * Value Type: `String`
         */
        BITWISE_OR: 'BITWISE_OR',
        /**
         * Check all fields for exact matches against character bits in value
         * Field Type: `String | Number`
         * Value Type: `String`
         */
        BITWISE_AND: 'BITWISE_AND'
    },
    Type: {
        TEXT: 'text',
        SELECT: 'select',
        RANGE: 'range'
    }
}