import React from "react";
import _ from "lodash";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import propTypes from "prop-types";
import styled from "styled-components";

HighchartsMore(Highcharts);

const keyTextMap = {
    gv_openness: "开放性、包容性、尊重差异",
    gv_intercaltural_communication: "跨文化交流沟通的能力",
    gv_multiculturalism: "认识和了解多元文化的能力",
    taa_academic_abilities: "学术能力",
    taa_critical_thinking: "批判性思维",
    taa_curiosity: "好奇心、求知欲",
    taa_creativity: "创造力、创新能力",
    ss_public_speaking: "交流沟通、公开演讲与辩论的能力",
    ss_problem_solving: "解决问题的能力",
    ss_teamwork: "团队合作能力",
    ss_leadership: "领导力",
    values_agreeableness_conscientiousness: "公益心、责任心、服务意识",
};

function getSeries(traits) {
    const data = [];

    _.each(traits, function (score, key) {
        data.push(Math.max(score, 5));
    });

    let series = {
        type: "area",
        name: "分数",
        data: data,
        pointPlacement: "on",
        color: "#88B5E7",
        // color: "#8b43e0",
        zIndex: 2,
        states: {
            inactive: {
                opacity: 1,
            },
        },
    };

    return [series];
}

function getOptions({ traitsData, traitsThreshold }) {
    const categories = [];
    _.each(traitsData, function (score, key) {
        categories.push(keyTextMap[key]);
    });

    const options = {
        chart: {
            polar: true,
            height: 500,
            width: 600,
            events: {
                load: function () {
                    // var chart = this;
                    // chart.addSeries();
                },
            },
        },
        title: {
            text: null,
            // x: -80,
        },
        xAxis: {
            categories: categories,
            tickmarkPlacement: "on",
            lineWidth: 0,
            labels: {
                useHTML: true,
                style: {
                    width: "150px",
                    color: "#47525D",
                    fontSize: "14px",
                    maxWidth: "150px",
                    wordBreak: "break-all",
                    // whiteSpace: "normal",
                },
            },
        },

        yAxis: {
            gridLineColor: "#ACC7EA",
            gridLineInterpolation: "polygon",
            lineWidth: 0,
            maxPadding: 0,
            min: 1,
            max: 100,
            tickPositions: [
                0,
                traitsThreshold?.lowerScoreThreshold,
                traitsThreshold?.upperScoreThreshold,
                100,
            ],
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: getSeries(traitsData),
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {},
        },
    };

    return options;
}

function RadarByTraits({ traitsData, traitsThreshold }) {
    return (
        <StyledContainer>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions({
                    traitsData,
                    traitsThreshold,
                })}
            />
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "chart-container",
})`
    /* height: 550px; */
    width: 100%;
    display: flex;
    justify-content: center;
`;

RadarByTraits.propTypes = {
    traitsData: propTypes.object.isRequired,
    traitsThreshold: propTypes.object.isRequired,
};

export default RadarByTraits;
