import React, { Component } from "react";
import { Pagination, message } from "antd";
import { Request } from "../../lib";
import _ from "lodash";
import Editable from "./Editable";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import DataDict from "./DataDict";
import StudentLink from "./summary/StudentLink";
import Shade from "components/Shade";

class ApplyMaterial extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        pageSize: 50,
        loading: true,
        local: {
            emptyText: " ",
        },
        sorter: {},
        datadict: {
            resumeInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:resumeInfo"
            ),
            referrerInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:referrerInfo"
            ),
            creditCardInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:creditCardInfo"
            ),
            passportInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:passportInfo"
            ),
            examInfo: DataDict.getMap("moblab:applymaterialsinfos:examInfo"),
            worksInfo: DataDict.getMap("moblab:applymaterialsinfos:worksInfo"),
            courseDesInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:courseDesInfo"
            ),
            scoreInfo: DataDict.getMap("moblab:applymaterialsinfos:scoreInfo"),
            juniorScore: DataDict.getMap(
                "moblab:applymaterialsinfos:juniorScore"
            ),
            seniorScore: DataDict.getMap(
                "moblab:applymaterialsinfos:seniorScore"
            ),
            seniorProve: DataDict.getMap(
                "moblab:applymaterialsinfos:seniorProve"
            ),
            seniorDiploma: DataDict.getMap(
                "moblab:applymaterialsinfos:seniorDiploma"
            ),
            collegeScore: DataDict.getMap(
                "moblab:applymaterialsinfos:collegeScore"
            ),
            visaPage: DataDict.getMap("moblab:applymaterialsinfos:visaPage"),
            pastI20: DataDict.getMap("moblab:applymaterialsinfos:pastI20"),
            depositInfo: DataDict.getMap(
                "moblab:applymaterialsinfos:depositInfo"
            ),
        },
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    fetchData = () => {
        Request.GET(
            "excel/applymaterialsinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    Shade.hide();
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };

    renderDataSource = (datasource) => {
        let ds = datasource.data;
        let collects = [
            "resumeInfo",
            "referrerInfo",
            "creditCardInfo",
            "passportInfo",
            "examInfo",
            "worksInfo",
            "courseDesInfo",
            "scoreInfo",
            "juniorScore",
            "seniorScore",
            "seniorProve",
            "seniorDiploma",
            "collegeScore",
            "visaPage",
            "pastI20",
            "depositInfo",
        ];
        for (let i = 0; i < ds.length; i++) {
            ds[i]["key"] = i;
            ds[i]["number"] = i + 1;
            _.each(collects, (v) => {
                if (this.state.datadict[v][ds[i][v]] === "已收集") {
                    ds[i][v] = (
                        <span style={{ color: "green" }}>
                            {this.state.datadict[v][ds[i][v]]}{" "}
                        </span>
                    );
                } else if (this.state.datadict[v][ds[i][v]] === "未收集") {
                    ds[i][v] = (
                        <span style={{ color: "red" }}>
                            {this.state.datadict[v][ds[i][v]]}{" "}
                        </span>
                    );
                } else {
                    ds[i][v] = (
                        <span style={{ color: "gray" }}>
                            {this.state.datadict[v][ds[i][v]]}{" "}
                        </span>
                    );
                }
            });
            ds[i]["name"] = ds[i]["studentName"];
            Shade.hide();
        }

        this.setState({
            data: ds,
            currentPage: datasource.currentPage,
            total: datasource.total,
            loading: false,
        });
    };

    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        const columns = [
            {
                className: "column-apply-status",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applystatus",
                render: (statusCode, row) => {
                    return (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                key: "name",
                render: (value, row, index) => {
                    return (
                        <StudentLink
                            studentName={value}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "简历信息表",
                dataIndex: ["resumeInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.resumeInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("resumeInfo 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "resumeInfo",
                            contractId: record["contractId"],
                            val: record["resumeInfo"],
                            id: record["id"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "推荐人信息表",
                dataIndex: ["referrerInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.referrerInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "referrerInfo",
                            val: record["referrerInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "信用卡信息",
                dataIndex: ["creditCardInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.creditCardInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "creditCardInfo",
                            val: record["creditCardInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "银行存款证明",
                dataIndex: ["depositInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.depositInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "depositInfo",
                            val: record["depositInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "护照首页",
                dataIndex: ["passportInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.passportInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "passportInfo",
                            val: record["passportInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "考试账户信息",
                dataIndex: ["examInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.examInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "examInfo",
                            val: record["examInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "作品/补充材料",
                dataIndex: ["worksInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.worksInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "worksInfo",
                            val: record["worksInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "课程描述（转学）",
                dataIndex: ["courseDesInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.courseDesInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "courseDesInfo",
                            val: record["courseDesInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "成绩认证",
                dataIndex: ["scoreInfo"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.scoreInfo, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "scoreInfo",
                            val: record["scoreInfo"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "初中成绩单",
                dataIndex: ["juniorScore"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.juniorScore, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "juniorScore",
                            val: record["juniorScore"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "高中成绩单",
                dataIndex: ["seniorScore"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.seniorScore, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "seniorScore",
                            val: record["seniorScore"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "高中在读证明",
                dataIndex: ["seniorProve"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.seniorProve, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "seniorProve",
                            val: record["seniorProve"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "高中毕业证",
                dataIndex: ["seniorDiploma"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.seniorDiploma, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "seniorDiploma",
                            val: record["seniorDiploma"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "大学成绩单（转学）",
                dataIndex: ["collegeScore"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.collegeScore, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "collegeScore",
                            val: record["collegeScore"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "过往美国签证页（如有）",
                dataIndex: ["visaPage"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.visaPage, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "visaPage",
                            val: record["visaPage"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "200px",
            },
            {
                title: "过往I-20（如有）",
                dataIndex: ["pastI20"],
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.pastI20, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    console.log("school 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "pastI20",
                            val: record["pastI20"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "备注",
                dataIndex: ["remark"],
                render: (value, row, index) => {
                    return value ? (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    ) : (
                        hiddenDIV
                    );
                },
                editable: true,
                handleSave: (record, value) => {
                    console.log("申请材料 编辑:", record);
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("excel/updateapplymaterialsInfo", {
                        data: {
                            attr: "remark",
                            val: record["remark"],
                            id: record["id"],
                            contractId: record["contractId"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "操作",
                dataIndex: "opr",
                render: (value, row, index) => {
                    return (
                        <OperationButtons
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            applyStatus={row["applyStatus"]}
                            applyAdvisor={row["applyAdvisorName"]}
                            fetchData={this.fetchData}
                            isShowUpload={false}
                            contractId={row["contractId"]}
                            contractName={row["contractName"]}
                            contractStatus={row["contractStatus"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                fixed: "right",
                width: "100px",
                align: "center",
            },
        ];

        return (
            <Shade>
                <Editable
                    size="small"
                    dataSource={this.state.data}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: 2900, y: "73vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </Shade>
        );
    }
}
export default ApplyMaterial;
