import React, { Component } from "react";
import _ from "lodash";
import * as Widget from "../../components/widgets";
import { RippleButton, RichText } from "../../components";
import { i18n } from "../../lib";
import { formatDate } from "../../lib/utils";
import { Grid, Segment } from "semantic-ui-react";
import { Status } from "../../data/enum";
import datastore from "../../data/store";
import { StudentUtil } from "lib/utils";

class Dashboard extends Component {
    doTierAction() {
        switch (this.props.data.status) {
            case Status.UNLINKED:
                this.props.generateLink();
                break;
            case Status.READY_FOR_ASSIGNMENT:
                this.props.beginAssignment();
                break;
            case Status.ARCHIVED:
                this.props.unarchive();
                break;
            default:
            //do nothing
        }
    }
    render() {
        const {
            id,
            tier,
            scores,
            status,
            overall,
            archived,
            traits,
            competence_traits,
            progress,
            assigned_tier,
            kind,
        } = this.props.data;
        const isGK = kind === "GK";

        const showPlan =
            !datastore.get("RENDER_CONDITIONS").hidePlan ||
            status === Status.UNLINKED;
        const notAchievedGaokao = isGK && status !== Status.ARCHIVED;

        var tierAction, disabled, showOverview;
        // sepical handler for gao kao
        if (progress === 1 && isGK) {
            showOverview = true;
        }

        switch (status) {
            case Status.UNLINKED:
                tierAction = i18n.t("DASHBOARD.INCOMPLETE");
                break;
            case Status.PRE_APPLICANT:
                tierAction = i18n.t("DASHBOARD.ACCOUNT_LINKED");
                disabled = true;
                break;
            case Status.READY_FOR_ASSIGNMENT:
                tierAction = i18n.t("DASHBOARD.ASSIGN_TIER");
                showOverview = true;
                break;
            case Status.APPLICANT:
                tierAction = i18n.t("DASHBOARD.ASSIGNED", {
                    tier: StudentUtil.getTierName(assigned_tier),
                });
                disabled = true;
                showOverview = true;
                break;
            case Status.ARCHIVED:
                tierAction = i18n.t("DASHBOARD.RESTORE_STUDENT");
                break;
            default:
                disabled = true;
        }
        const actionClass =
            "student_action status_" + status + (disabled ? " disabled" : "");
        return (
            <div className={"Page Dashboard"}>
                <Grid>
                    {!notAchievedGaokao && showPlan && !this.props.data.isBind && (
                        <Grid.Row>
                            <Grid.Column>
                                <Segment
                                    className={actionClass}
                                    onClick={this.doTierAction.bind(this)}
                                >
                                    {/* <div className='fill left' /> */}
                                    <div className="status">
                                        {i18n.t("STUDENTS.STATUS_" + status)}
                                    </div>
                                    <div className={"action action_" + status}>
                                        <div>
                                            {tierAction}
                                            {!disabled && (
                                                <div className="icon">"</div>
                                            )}
                                        </div>
                                        {status === Status.UNLINKED && (
                                            <p>
                                                {i18n.t(
                                                    "DASHBOARD.CREATE_ACCOUNT"
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    {/* { status === Status.PRE_APPLICANT && 
                                        <RippleButton 
                                            text={i18n.t("DASHBOARD.GENERATE_LINK")}
                                            onClick={this.props.generateLink}
                                        />
                                    } */}
                                    {status === Status.APPLICANT && (
                                        <RippleButton
                                            text={i18n.t("DASHBOARD.RESET")}
                                            onClick={this.props.resetTier}
                                        />
                                    )}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {!showOverview && status !== Status.ARCHIVED && (
                        <>
                            <Grid.Row>
                                <Grid.Column>
                                    <Widget.Progress
                                        title={i18n.t(
                                            "DASHBOARD.PROGRESS_TITLE"
                                        )}
                                        subtitle={i18n.t(
                                            "DASHBOARD.PROGRESS_SUBTITLE"
                                        )}
                                        progress={progress}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {!isGK && status === Status.PRE_APPLICANT && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="footer">
                                            <RichText
                                                className="linkedDesc"
                                                styles={{
                                                    c: {
                                                        color: "#935B8E",
                                                    },
                                                }}
                                                content={i18n.t(
                                                    "DASHBOARD.LINKED_DESC"
                                                )}
                                            />
                                            <RichText
                                                content={i18n.t(
                                                    "DASHBOARD.RESET_PROMPT"
                                                )}
                                                handlers={[
                                                    this.props.generateLink,
                                                ]}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </>
                    )}
                    {showOverview && (
                        <>
                            {!isGK && showPlan && (
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        <Widget.SuggestedTier
                                            tier={tier}
                                            overall={overall}
                                            assigned_tier={assigned_tier}
                                            more={() => {
                                                this.props.changePage(
                                                    "PLAN_REPORT"
                                                );
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        <Widget.ScoresOverview
                                            title={i18n.t(
                                                "DASHBOARD.INDIVIDUAL_SCORES_TITLE"
                                            )}
                                            scores={scores}
                                            more={() => {
                                                this.props.changePage(
                                                    "PLAN_REPORT"
                                                );
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Widget.Progress
                                        title={i18n.t(
                                            "DASHBOARD.PROGRESS_TITLE"
                                        )}
                                        subtitle={i18n.t(
                                            "DASHBOARD.PROGRESS_SUBTITLE"
                                        )}
                                        progress={progress}
                                    />
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Widget.TraitsOverview
                                        title={i18n.t(
                                            "DASHBOARD.TRAITS_OVERVIEW_TITLE"
                                        )}
                                        subtitle={i18n.t(
                                            "DASHBOARD.TRAITS_OVERVIEW_SUBTITLE"
                                        )}
                                        traits={traits}
                                        maxHeight={15}
                                        more={() => {
                                            this.props.changePage(
                                                "TRAIT_REPORT"
                                            );
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {!isGK && (
                                <Grid.Row>
                                    <Grid.Column width={9}>
                                        <Widget.GlobalCompetenceOverview
                                            title={i18n.t(
                                                "DASHBOARD.GLOBAL_COMPETENCE_OVERVIEW_TITLE"
                                            )}
                                            subtitle=""
                                            competenceTraits={competence_traits}
                                            more={() => {
                                                if (
                                                    competence_traits &&
                                                    !_.isEmpty(
                                                        competence_traits
                                                    )
                                                ) {
                                                    this.props.changePage(
                                                        "GLOBAL_COMPETENCE_REPORT"
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <Widget.ExamSummary
                                            title={i18n.t(
                                                "DASHBOARD.EXAM_OVERVIEW"
                                            )}
                                            subtitle={i18n.t(
                                                "DASHBOARD.TOP_EXAM_SCORES"
                                            )}
                                            studentId={id}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {/* <Grid.Row>
                            <Grid.Column>
                                <Widget.Note 
                                    title={"NOTES"}
                                />
                            </Grid.Column>
                        </Grid.Row> */}
                        </>
                    )}
                    {status === Status.ARCHIVED && (
                        <div className="archive_date">
                            {getArchiveDate(archived)}
                        </div>
                    )}
                </Grid>
            </div>
        );
    }
}

function getArchiveDate(ms) {
    return i18n.t("DASHBOARD.ARCHIVED_ON", { date: formatDate(ms) });
}

export default Dashboard;
