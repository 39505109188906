import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { TimeUtil } from "lib/utils";
import { Form, Row, Col, Input } from "antd";
const { TextArea } = Input;

const config = [
    {
        label: "学生生日",
        fieldName: "birthday",
    },
    {
        label: "是否早申请",
        fieldName: "earlierApplyValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "Common账号",
        fieldName: "commonAccount",
    },
    {
        label: "Common密码",
        fieldName: "commonPwd",
    },
    {
        label: "CommonId",
        fieldName: "commonId",
    },
    {
        label: "UC账号",
        fieldName: "UCAccount",
    },
    {
        label: "UC密码",
        fieldName: "UCPwd",
    },
    {
        label: "UCID",
        fieldName: "UCId",
    },
    {
        label: "CAAS账号",
        fieldName: "CAASAccount",
    },
    {
        label: "CAAS密码",
        fieldName: "CAASPwd",
    },
    {
        label: "CAASID",
        fieldName: "CAASId",
    },
    {
        label: "面谈进度",
        fieldName: "interviewProgressValue",
    },
    {
        label: "简历进度",
        fieldName: "resumeProgressValue",
    },
    {
        label: "主文进度",
        fieldName: "mainTextProgressValue",
    },
    {
        label: "推荐信写作进度",
        fieldName: "recommendProgressValue",
    },
    {
        label: "加州系文书进度",
        fieldName: "caDocProgressValue",
    },
    {
        label: "收集文件进度",
        fieldName: "collectFileProgressValue",
    },
    {
        label: "备注",
        fieldName: "remark",
        span: 24,
        DisplayComponent: TextArea,
    },
];

function getParsedStudentInfo(studentData) {
    const baseInfo = studentData.studentInfo || {};
    const coreApplyInfos = studentData.coreApplyInfos || [];
    const birthday = TimeUtil.formatDate(baseInfo.birthday);
    let parsedCoreApplyInfos = [];
    _.each(coreApplyInfos, function (item) {
        let obj = {
            birthday,
            earlierApplyValue: item.earlierApplyValue,
            commonAccount: item.contractInfo.commonAccount,
            commonPwd: item.contractInfo.commonPwd,
            commonId: item.contractInfo.commonId,
            UCAccount: item.contractInfo.UCAccount,
            UCPwd: item.contractInfo.UCPwd,
            UCId: item.contractInfo.UCId,
            CAASAccount: item.contractInfo.CAASAccount,
            CAASPwd: item.contractInfo.CAASPwd,
            CAASId: item.contractInfo.CAASId,
            interviewProgressValue: item.interviewProgressValue,
            resumeProgressValue: item.resumeProgressValue,
            mainTextProgressValue: item.mainTextProgressValue,
            recommendProgressValue: item.recommendProgressValue,
            caDocProgressValue: item.caDocProgressValue,
            collectFileProgressValue: item.collectFileProgressValue,
            remark: item.remark,
            contractId: item.contractInfo.contractId,
        };
        parsedCoreApplyInfos.push(obj);
    });

    return parsedCoreApplyInfos;
}

function SummaryCoreApply({ studentData }) {
    const [coreApplyInfos, setCoreApplyInfos] = useState({});

    useEffect(() => {
        setCoreApplyInfos(getParsedStudentInfo(studentData));
    }, [studentData, setCoreApplyInfos]);
    const getFields = useCallback((config, coreApplyInfoData) => {
        const children = [];

        _.each(config, function (
            { label, fieldName, span = 8, DisplayComponent },
            index
        ) {
            if (label === "") {
                children.push(<Col span={span} key={index}></Col>);
                return;
            }

            children.push(
                <Col span={span} key={index}>
                    <Form.Item label={label} colon={false} labelAlign="left">
                        {DisplayComponent ? (
                            <DisplayComponent
                                readOnly
                                value={coreApplyInfoData[fieldName]}
                            />
                        ) : (
                            <Input
                                readOnly
                                value={coreApplyInfoData[fieldName]}
                            />
                        )}
                    </Form.Item>
                </Col>
            );
        });

        return children;
    }, []);

    return _.map(coreApplyInfos, function (item, index) {
        return (
            <StyledContainer key={index}>
                <header className="section-header">
                    核心申请{" "}
                    {coreApplyInfos.length > 1
                        ? `(合同号:${item.contractId})`
                        : ""}
                </header>
                <Form
                    className="section-form"
                    initialValues={{
                        size: "small",
                    }}
                >
                    <Row gutter={24} className="page-break-auto">
                        {getFields(config, item)}
                    </Row>
                </Form>
            </StyledContainer>
        );
    });
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})``;

SummaryCoreApply.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryCoreApply;
