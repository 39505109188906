import React, { Component } from 'react'
import RichTextEditor from 'react-rte' //https://github.com/sstur/react-rte/
import { NoteUtil, formatDate } from '../lib/utils'
import { i18n } from '../lib'
import { Input } from '.'
import { Form, Loader } from 'semantic-ui-react'

/**
 * @class Note Editor
 * 
 * The Note Editor allows the user to change a notes title or body. The body can
 * have styling using an editor plugin, the state of which is stringified as html 
 * when saved to the server. 
 * 
 * The Editor dispatches all save requests to NoteUtil, which handles server
 * connection. The editor should show the current page (if required), update time,
 * and creator of the current note. The editor should also lock if the note's 
 * creator does not match the current user. 
 * 
 * @prop {String} userid        student's id
 * @prop {String | int} noteid  note id (-1 if note is new and unsaved)
 */
class NoteEditor extends Component {
    state = {
        id: null,
        title: "",
        body: RichTextEditor.createEmptyValue()
    }
    /**
     * Initialize editor
     */
    componentWillMount() {
        this.init();
    }
    /**
     * Update editor if a new note has been selected. 
     * 
     * The editor must not re-initialize when the note id changes from -1 to 
     * the new note's real id. This is done by checking NoteUtil for the new 
     * note's id. 
     * 
     * If changing to a new note, the old note should trigger a forced save. 
     */
    componentDidUpdate() {
        if (this.state.id !== this.props.noteid) {
            if (this.state.id === -1 && NoteUtil.new_id === this.props.noteid) {
                this.setState({ id: this.props.noteid });
                NoteUtil.new_id = null;
                return;
            }
            NoteUtil.link(this.props.userid)
            NoteUtil.saveOnClose(this.state);
            this.init();
        }
    }
    /**
     * Trigger a forced save when the component unmounts
     */
    componentWillUnmount() {
        NoteUtil.link(this.props.userid)
        NoteUtil.saveOnClose(this.state);
        NoteUtil.unlink();
    }
    /**
     * Fetch note data and initialize state. 
     * 
     * Use props.noteid to fetch the note data from the datastore and initialize the 
     * local state. Raw note data cannot be used due to certain local metadata such
     * as `editTime`, as well as the body needing to be converted back from html
     * string to editor object.
     */
    init() {
        const { userid, noteid } = this.props;
        if (noteid !== null) {
            const noteData = NoteUtil.getNote(userid, noteid) ||
                NoteUtil.newNote(userid, noteid);

            // console.log("NOTE EDITOR: ", noteData);
            this.setState({
                id: noteData.id,
                page: noteData.attachTo,
                updatedAt: noteData.editTime || noteData.updatedAt,
                createdBy: noteData.createdBy.name,
                readOnly: noteData.readOnly,
                title: noteData.title || "",
                body: RichTextEditor.createValueFromString(noteData.body, 'html')
            });
        }
    }
    /**
     * Request a note save (handled by NoteUtil) and update local state.
     * 
     * @param {Object} noteData current note's title and body 
     */
    save({ body, title }) {
        const { userid, noteid } = this.props;

        var params = NoteUtil.saveNote(userid, noteid, {
            id: noteid,
            body: body,
            title: title
        });

        this.setState({
            body: body,
            title: title,
            updatedAt: params.updatedAt || this.state.updatedAt
        });
    }
    /**
     * Editor handler to update note body state. 
     * 
     * @param {Object} value editor state object
     */
    onEditorUpdate(value) {
        this.save({ body: value, title: this.state.title })
    }
    /**
     * Input handler to update note title string.
     * 
     * @param {Object} event    input update event 
     */
    onTitleUpdate({ target }) {
        this.save({ title: target.value, body: this.state.body });
    }
    render() {
        const { title, body, page, updatedAt, createdBy, readOnly } = this.state;
        const loaded = this.props.noteid !== null;

        return (
            <div className={"NoteEditor" + (readOnly ? " readOnly" : "")}>
                {loaded && <>
                    <Form>
                        {readOnly &&
                            <div className="title">
                                <label>{i18n.t('PROFILE.NOTE_TITLE')}</label>
                                <div>{title}</div>
                            </div>
                        }
                        {!readOnly &&
                            <Input
                                type="text"
                                label={i18n.t('PROFILE.NOTE_TITLE')}
                                value={title}
                                onChange={this.onTitleUpdate.bind(this)}
                            />
                        }
                    </Form>
                    <RichTextEditor
                        className="editor"
                        value={body}
                        readOnly={readOnly}
                        onChange={this.onEditorUpdate.bind(this)}
                        toolbarConfig={{
                            display: [
                                'INLINE_STYLE_BUTTONS',
                                'BLOCK_TYPE_BUTTONS',
                                // 'LINK_BUTTONS'
                                // 'HISTORY_BUTTONS'
                            ],
                            INLINE_STYLE_BUTTONS: [
                                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                                { label: 'Italic', style: 'ITALIC' },
                                { label: 'Underline', style: 'UNDERLINE' }
                            ],
                            BLOCK_TYPE_BUTTONS: [
                                { label: 'UL', style: 'unordered-list-item' },
                                { label: 'OL', style: 'ordered-list-item' }
                            ]
                        }}
                    />
                </>}
                <div className="stats">
                    <div>{i18n.t('PROFILE.' + page)}</div>
                    <div>{formatDate(updatedAt)}</div>
                    <div>{createdBy}</div>
                </div>
                <Loader active={!loaded} />
            </div>
        )
    }
}

export default NoteEditor;
