// var TODO = 'TODO';

module.exports = {
    APP: {
        MOBLAB: "MobLab",
        ADVISOR_CONSOLE: "咨询顾问后台",
        USERNAME: "用户名",
        EMAIL: "EMAIL",
        PASSWORD: "密码",
        FULL_NAME: "姓名",
        NAME: "姓名",
        PHONE: "电话号码",
        AREA_CODE: "区号",
        FILTER: "筛选",
        MANAGE: "管理",
        SEARCH: "搜索",
        SAVE: "保存",
        PRINT: "打印",
        CANCEL: "取消",
        CREATE: "创建",
        ASSIGN: "分配",
        SUBMIT: "提交",
        LOGIN: "登录",
        LOGOUT: "登出",
        SIGNUP: "注册",
        LOADING: "读取中...",
        OK: "OK",
        SET_TIER: "分配套餐",
        RESET_TIER: "重置套餐",
        SIGNUP_LINK: "学生注册链接",
        ARCHIVE: "归档",
        UNARCHIVE: "还原",
        ADD: "新建",
        UNDO: "撤销",
        SHOW_ALL: "显示所有",
        EULA: "终端用户许可协议",
        PRIVACY: "隐私政策",
        TOS: "使用条款",
        DATE_FORMAT: "YYYY-MM-DD",
        NO_RESULTS: "没有结果",
        I_AGREE: "通过使用该产品，我同意:",
        SUPPORT: "客服",
        NEXT: "下一步",
        BACK: "上一步",
        RANGE: "第%{range}行, 共%{total}行",
        UNBIND: "解除绑定",
        BIND: "绑定",
        SHOWPROFILE: "查看测评",
    },
    LOGIN: {
        WELCOME_1: "欢迎来到MobLab",
        WELCOME_2: "推荐系统平台",
        ADVISOR_LOGIN: "顾问登录",
        ADVISOR_SIGNUP: "完成注册",
        ADVISOR_WELCOME_BACK: "欢迎回来",
        ADVISOR_WELCOME_BACK_SUB: "为了你的账号安全，请设置一个新的密码",
    },
    PASSWORD: {
        CREATE: "创建密码",
        CHANGE: "更改密码",
        CURRENT: "当前密码",
        NEW: "新密码",
        CONFIRM: "确认密码",
        RESET_LINK: "修改密码",
        REQUIRE_PROMPT: "密码至少包含:",
        REQUIRE_LENGTH: "八个字符",
        REQUIRE_UPPERCASE: "一个英文大写字母 (A-Z)",
        REQUIRE_LOWERCASE: "一个英文小写字母(a-z)",
        REQUIRE_NUMERAL: "一个数字 (0-9)",
        CHANGE_SUCCESS: "更改密码成功!",
        CREATE_SUCCESS: "创建密码成功!",
        MISMATCH: "密码不匹配.",
        WRONG: "您当前的密码不对.",
        INCORRECT: "您的密码不对.",
        INVALID: "密码不符合要求.",
        INVALID_LENGTH: "密码至少要有八个字符.",
        INVALID_UPPERCASE: "密码至少包含一个英文大写字母.",
        INVALID_LOWERCASE: "密码至少包含一个小写字母.",
        INVALID_NUMERAL: "密码至少包含0-9数字中的一个.",
        NO_USER: "您输入的邮箱是无效的, 请输入正确的邮箱.",
    },
    ADMIN: {
        VIEW_0: "管理员",
        VIEW_1: "顾问",
        SWITCH_VIEW_0: "切换到顾问界面",
        SWITCH_VIEW_1: "切换到管理员界面",
        ALL_USERS: "所有用户",
        ADVISORS: "顾问",
        STUDENTS: "学生",
        PROSPECTS: "潜在客户",
        ADD_ADVISORS: "添加顾问",
        NEW_ADVISORS: "新顾问",
        ADD_ROW: "添加一行",
        CSV_FORMAT_PROMPT: "请参考以下格式上传您的文件:",
        DROP_FILE: "请上传.csv文件",
        UPLOAD_FAILED: "上传失败",
        UPLOAD_COUNT: "总共: %{count}",
        DUPLICATES: "删除重复: %{count}",
        OVERVIEW: "概述",
        DASHBOARD: "概览",
        QUARTER: "季度",
        STAT: "图表统计",
        STAT_STUDENT_ACCOUNTS: "学生账号统计",
        STAT_COMPLETED_ASSESSMENTS: "已完成测评数统计",
        STAT_PLAN_ASSIGNMENTS: "套餐分配数统计",
        STAT_ADVISOR_ACCOUNTS: "咨询老师账号统计",
        NO_PROSPECTS: "没有潜在客户",
    },
    ADMIN_ADVISORS: {
        NAME: "名字",
        EMAIL: "EMAIL",
        STUDENTS: "学生人数",
        CREATION_DATE: "创建日期",
        CAMPUS: "校区",
        PLACEHOLDER_CAMPUS: "选择一个校区",
    },
    ADMIN_STUDENTS: {
        NAME: "名字",
        ADVISOR: "顾问",
        KIND: "类型",
        CREATION_DATE: "创建日期",
        CAMPUSNAME: "校区",
        CAMPUS: "校区",
        PLACEHOLDER_ADVISOR: "选择一个顾问",
    },
    ADMIN_PROSPECTS: {
        NAME: "名字",
        ADVISOR: "顾问",
        CREATION_DATE: "创建日期",
        CAMPUSNAME: "校区",
        PLACEHOLDER_ADVISOR: "选择一位顾问",
    },
    STUDENTS: {
        YOUR_STUDENTS: "您的学生",
        NEW_STUDENT: "新建学生档案",
        NAME: "姓名",
        STATUS: "状态",
        KIND: "类型",
        "STATUS_-1": "申请前",
        STATUS_0: "申请前",
        STATUS_1: "等待分配套餐",
        STATUS_2: "申请中",
        STATUS_3: "已归档",
        ASSIGNED_TIER: "套餐已分配",
        TIER: "套餐",
        "TIER_-1": "--",
        TIER_0: "常春藤工作室",
        TIER_1: "精英",
        TIER_2: "志领",
        TIER_3: "留美",
        REPORT: "报告",
        CREATE_PROMPT: "输入学生的姓名",
        NO_STUDENTS: "你还没有添加任何学生!",
        TOTAL: "总数",
    },
    PROFILE: {
        OVERVIEW: "概述",
        STUDENT_INFO: "学生信息",
        DATA_INTEGRATION: "DATA INTEGRATION (coming soon!)",
        RECOMMENDATIONS: "RECOMMENDATIONS (coming soon!)",
        STUDENT_PROGRESS: "学生进度",
        NOTES: "笔记",
        DASHBOARD: "概览",
        PLAN_REPORT: "套餐报告",
        TRAIT_REPORT: "行为特征报告",
        GLOBAL_COMPETENCE_REPORT: "全球胜任力报告",
        INTER_ATTAINMENT: "国际素养",
        MAJOR_FIT: "专业推荐",
        SCHOOL_FIT: "学校推荐",
        PERSONAL: "个人",
        ACADEMIC: "学术",
        APPLICATION: "申请资源",
        MAJOR_CAREER: "专业&职业",
        MAJOR_REC: "Major/Specialty",
        SCHOOL_REC: "School",
        MANAGE: "管理",
        STATISTICS: "Statistics",
        LANGUAGE: "语言",
        EXAMS: "标准化考试",
        OPTION_male: "男",
        OPTION_female: "女",
        NOTE_TITLE: "标题",
        NOTE_DELETED: "笔记删除了",
        NEW_NOTE: "新笔记",
        NO_NOTES: "你没有写过笔记.",
        NO_NOTES_ON_THIS_PAGE: "你没有在这页写过笔记.",
        NO_LANGUAGE_DATA: "学生还没有完成语言测试.",
    },
    DASHBOARD: {
        SUGGESTED_TIER_TITLE: "建议套餐",
        SUGGESTED_TIER_SUBTITLE: "摹方评估应用的推荐",
        SELECTED_TIER_TITLE: "推荐套餐",
        SELECTED_TIER_SUBTITLE: "学生已经被分配到",
        ASSIGN_TIER: "套餐分配已就绪",
        INCOMPLETE: "学生还在进行测评中",
        ASSIGNED: "学生已经被分配到 %{tier} 套餐中",
        RESTORE_STUDENT: "恢复学生",
        SUGGESTED: "推荐",
        INDIVIDUAL: "分项得分",
        INDIVIDUAL_SCORES_TITLE: "分项得分",
        TRAITS_OVERVIEW_TITLE: "特征报告",
        GLOBAL_COMPETENCE_OVERVIEW_TITLE: "全球胜任力报告",
        TRAITS_OVERVIEW_SUBTITLE: "摹方的个性特征评估",
        PROGRESS_TITLE: "测评系统",
        PROGRESS_SUBTITLE: "进度",
        TRAITS_TITLE: "行为特征",
        TIME_SPENT: "用时: ",
        ARCHIVED_ON: "学生归档于: %{date}",
        TIER_PROMPT: "选择一个套餐",
        STUDENT_SCORES: "学生分数",
        RESET: "修改",
        GENERATE_LINK: "生成",
        CHANGE_PASSWORD: "您的学生可以用以下链接修改密码",
        LINK_TITLE_CHANGE: "学生密码修改链接",
        LINK_TITLE_CREATE: "学生注册链接",
        LINK_PROMPT:
            "此注册链接为学生使用 <c> 一次性有效（%{exp}天内有效）<c>，请顾问老师将以下链接直接发给指定学生。",
        GET_LINK: "复制链接到剪贴板",
        LINK_COPIED: "链接已复制到剪贴板",
        CREATE_ACCOUNT: "您的学生可使用此链接注册账户",
        ACCOUNT_LINKED: "账户已绑定",
        LINKED_DESC:
            "当您的学生完成<c>MobLab测评<c>, 他们的测评进度和申请套餐计划推荐将会在这里展示.",
        RESET_PROMPT: "如果您的学生需要重置密码, 您可以使用<a>密码修改链接<a>",
        EXAM_OVERVIEW: "标准化考试概述",
        TOP_EXAM_SCORES: "考试分数追踪",
    },
    FILTERS: {
        DEFAULT_0: "测评未完成",
        DEFAULT_1: "测评已完成等待分配套餐",
        DEFAULT_2: "申请准备就绪",
        DEFAULT_3: "已归档",
    },
    SCORES: {
        OVERALL: "整体得分",
        ACADEMIC_ABILITY: "学术能力",
        APPLICATION_READINESS: "申请准备",
        CAREER_FOCUS: "职业规划",
        LANGUAGE_APTITUDE: "语言能力",
    },
    TRAITS: {
        grit: "坚持力",
        risk_aversion: "风险偏好",
        confidence: "信心",
        competitiveness: "竞争性",
        fairness: "公平性",
        strategic_thinking: "策略思考",
        problem_solving: "解决问题能力",
        self_control: "自控力",
        procrastination: "拖延症",
        trust: "信任",
        extraversion: "外向性",
        agreeableness: "亲和力",
        conscientiousness: "尽责性",
        calmness: "冷静性",
        openness: "思维开放性和创造力",
    },
    TRAITS_DESC: {
        agreeableness: "对他人富有同情心且乐于合作的倾向",
        calmness: "头脑冷静度或情绪稳定度",
        competitiveness: "和其他人进行竞争的倾向",
        confidence: "对于自己相对于其他人能力的评估和认知",
        conscientiousness: "擅长自我管理且值得依赖的属性",
        extraversion: "充满能量且乐于社交的属性",
        fairness: "认为人应该被同等对待的观念",
        openness: "寻求新的想法和经历，保持好奇的倾向",
        grit: "持续保持专注并努力的能力",
        problem_solving: "运用逻辑解决问题的能力",
        procrastination: "将计划执行的任务拖延到之后的时间",
        risk_aversion: "规避风险的倾向",
        self_control: "控制自己的情感和行动的能力",
        strategic_thinking: "辩证地思考并预测其他人的行为的能力",
        trust: "对于其他人是否正直以及是否有能力的信心",
    },
    PLANS: {
        TIER_0_0:
            "这位学生有杰出的学术力，高水平的策略思考能力，做事细致有条理，有良好的毅力和自控力。 过往经验建议：可通过高含金量及专业性的课外活动指导从而突显自己的软实力。",
        TIER_0_1:
            "这位学生通过自主调研对申请过程较为了解。过往经验建议：如额外接受专业性高强度的指导可以锦上添花。",
        TIER_0_2:
            "这位学生对于将来的专业有着明确方向，对职业有着较明确的理解。过往经验建议：通过高含金量个性化的职业背景提升，可比其他同龄人获得更高的职业起点。",
        TIER_0_3:
            "这位学生已经熟练地掌握了基本英语水平。过往经验建议：可通过实际英语交谈锻炼口语及听力。",
        TIER_1_0:
            "这位学生有出色的学术力，良好的策略思考能力，毅力和自控力。过往经验建议：可以通过专业性课外活动指导来启发专业兴趣，丰富课外活动背景，突出自身优势，从而获得有竞争力的软实力背景。",
        TIER_1_1:
            "这位学生对申请过程有一定的了解，但是还需要一些额外的指导。过往经验建议：更深一步的指导从而全面了解各个美国大学的特征，找到适合自己的学校。",
        TIER_1_2:
            "这位学生对于将来的专业和职业有着一定的规划，但尚无具体清晰规划和认识。过往经验建议：可通过提前进行职业背景提升，获得比其他同龄人更高的职业起点。",
        TIER_1_3:
            "这位学生的英语语言能力处于中上等。过往经验建议：需进一步通过实操进行英语水平提升。",
        TIER_2_0:
            "这位学生有合格的学术力，策略思考能力，条理性，毅力和自控力。过往经验建议：可以通过公益类，通识教育类课外活动，提升美国大学所需的软实力，并且可提前开始适应美国大学教学模式及课程难度的训练。",
        TIER_2_1:
            "这位学生对申请过程部分了解，他/她需要更多的基础性申请指导扩充知识面，从而进行美国大学申请。过往经验建议：可能需要贯穿始终的全程申请指导，同时他/她需要提升软实力背景和提高标化成绩，做好时间规划。",
        TIER_2_2:
            "这位学生对于将来的专业和职业只有有限的规划。过往经验建议：可能需要更多标准化的课外活动指导，了解专业内容及未来规划。",
        TIER_2_3:
            "这位学生的英语语言能力合格,仍有巨大潜力可供挖掘。过往经验建议：建议增加词汇量以及阅读和写作能力。",
        TIER_3_0:
            "这位学生学术力一般，策略思考能力，条理性，毅力和自控力也不突出。额外的指导能帮助他/她更快的适应美国的学习环境和课程安排。过往经验建议：可能属于申请规划较晚或尚不充分的潜能生，需要开始准备提前适应美国大学教学模式及课程难度的训练以此来适应美国的学习环境和课程安排。",
        TIER_3_1:
            "这位学生对申请过程了解较少，他/她需要专业细致性的申请指导来完成申请步骤。",
        TIER_3_2:
            "这位学生此前几乎未考虑未来的专业或职业方向，建议先从顺利渡过美国第一年的学习及生活开始，也可以在上大学之前提前渡过语言关以及了解美国大学课程设置，以此保证高GPA来确保大三可以顺利确定专业。",
        TIER_3_3:
            "这位学生的英语语言处于基础水平。过往经验建议：需要增加词汇量，了解托福考试常见词汇的定义。",
    },
    MAJOR: {
        TITLE: "专业推荐",
        SUBTITLE:
            "基于海量学生的游戏和行为数据，MobLab建立了前沿的统计学和机器学习的预测匹配模型，来刻画学生的行为特质、实现个性化的专业推荐. MobLab个性化专业推荐模型首先通过MobLab-前途出国移动APP端的游戏和问卷数据测量学生的个性特质，之后将学生的个性特质档案与MobLab数据库中的海量的美国大学生档案进行匹配，根据其相似程度推荐对应的专业. 基于该学生的游玩数据，MobLab个性化专业推荐模型的专业推荐结果如下. 在您做出最终申请决策前，请在参考以上推荐结果的同时，综合考量其他相关信息及因素.",
        MAJOR: "专业",
        FIT: "匹配程度",
        SHOW_ALL: "显示全部",
        SHOW_LESS: "显示部分",
        SECTION_SUB_MAJORS: "专业细分",
        SECTION_CAREERS: "职业",
        GRADUATE_DEGREE: "获得研究生学位比例",
        MEDIAN_INCOME: "平均年收入",
    },
    UNITS: {
        DAYS: "%{count} 天 |||| %{count} 天",
        HOURS: "%{count} 小时 |||| %{count} 小时",
        MINUTES: "%{count} 分钟",
        SECONDS: "%{count} 秒",
    },
    HELP: {
        SUGGESTED_TIER:
            "套餐建议系统是根据学生的四个维度（学术力、语言力、申请力、就业力）的分项分数和下附的测评标准得出。套餐建议仅供参考。如果您选择与建议不符的套餐，请提供反馈和原因备注。",
        INDIVIDUAL_SCORES:
            "测评各项分数是根据MobLab测评系统的互动游戏数据和问卷数据得出。",
        TRAIT_BREAKDOWN:
            "行为特征测评是根据MobLab测评系统的互动游戏数据和问卷数据得出。",
    },
    TRAIT_REPORT: {
        TRAIT_BREAKDOWN_TITLE: "行为特征",
        TRAIT_BREAKDOWN_SUBTITLE: "MobLab行为特征评估",
    },
    TRAIT_SLIDER: {
        LOW: "较低",
        MED: "平均",
        HIGH: "出色",
    },
    PERSONAL: {
        TITLE: "个人信息",
    },
    ACADEMIC: {
        TITLE: "学术",
    },
    APPLICATION: {
        TITLE: "申请资源",
    },
    MAJOR_CAREER: {
        TITLE: "专业&职业",
    },
    INTER_ATTAINMENT: {
        TITLE: "国际素养",
    },
    LANGUAGE: {
        NO_DATA: "学生还没有完成这个测试",
        TITLE: "语言测试成绩",
        SUBTITLE: "难度【1-10】会（提升/下降）当回答（对/错）",
        QUESTION: "问题",
        DIFFICULTY: "难度",
        LEGEND: "问题难度",
        MEDIAN: "中位数",
        ACCURACY: "正确率",
        VOCAB_SCORE: "语言测试分数",
    },
    EXAMS: {
        ADD: "添加考试",
        DATE: "考试日期",
        TOTAL: "总分",
        SCORE: "分数",
        SUBJECT: "科目",
        READING: " 阅读",
        LISTENING: "听力",
        WRITING: "写作",
        SPEAKING: "口语",
        EBRW: "阅读和写作",
        MATH: "数学",
        CHANGE_VIEW_0: "表格视图",
        CHANGE_VIEW_1: "图表视图",
        SUBJECT_PLACEHOLDER: "选择一个科目",
        NO_DATA: "无数据",
        DELETED: "考试删除了",
    },
    SCHOOLS: {
        TITLE: "学校推荐",
        SUBTITLE:
            "基于学生的学术成绩以及行为特质，MobLab建立了前沿的统计学和机器学习预测匹配模型，来帮助学生定位最适合自己申请的学校. MobLab个性化学校推荐模型首先根据学生当前的学术成绩和标准化考试成绩，筛选出一系列满足学术申请条件的学校. 之后将学生的行为特质档案与每个筛选出的学校的'个性'属性进行匹配并排序，评估出最适合申请的学校列表如下. 在您做出最终申请决策前，请在参考以上推荐结果的同时，综合考量其他相关信息及因素.",
        CHANGE_VIEW_0: "表格视图",
        CHANGE_VIEW_1: "卡片视图",
        STUDENT_FIT: "行为特质匹配程度",
        RANKING: "在美国综合性大学中排名第%{rank}",
        RANKING_LIBARTS: "在美国文理学院中排名第%{rank}",
        SCHOOL: "学校",
        RANK: "排名",
        FIT: "匹配程度",
        TEXT_PLACEHOLDER: "输入学校名称",
        SELECT: "选择一个筛选条件",
        SELECT_MULTIPLE: "选择一个或多个筛选条件",
        SECTION_GENERAL: "一般信息",
        SECTION_ENROLLMENT: "招生信息",
        LABEL_SCHOOL_NAME: "学校名称",
        LABEL_LOCATION: "州",
        LABEL_REGION: "地区",
        LABEL_SCHOOL_TYPE_I: "学校类型",
        LABEL_SCHOOL_TYPE_II: "综合性大学或者文理学院",
        LABEL_GENDER: "性别偏好",
        LABEL_UGDS_NRA: "国际学生比例",
        LABEL_RANKING: "学校排名",
        LABEL_TUITION: "估计年均学费花费 (美元)",
        OPTION_LOCALE_11: "城市: 大型（人口>250,000)",
        OPTION_LOCALE_12: "城市: 中型 (100,000<人口<250,000)",
        OPTION_LOCALE_13: "城市: 小型 (人口<100,000)",
        OPTION_LOCALE_21: "郊区: 大型 (人口>250,000)",
        OPTION_LOCALE_22: "郊区: 中型 (100,000<人口<250,000)",
        OPTION_LOCALE_23: "郊区: 小型 (人口<100,000)",
        OPTION_LOCALE_31: "乡镇: 距离城市化地区<10英里",
        OPTION_LOCALE_32: "乡镇: 距离城市化地区>10英里且<35英里",
        OPTION_LOCALE_33: "乡镇: 距离城市化地区>35英里",
        OPTION_LOCALE_41: "偏远地区: 距离最近的乡镇<2.5英里",
        OPTION_LOCALE_42: "偏远地区: 距离最近的乡镇>2.5英里且<10英里",
        OPTION_LOCALE_43: "偏远地区: 距离最近的乡镇>10英里",
        OPTION_CONTROL_1: "公立",
        OPTION_CONTROL_2: "私立 (非盈利)",
        OPTION_CONTROL_3: "私立 (盈利)",
        OPTION_NON_LIBERAL_ARTS: "综合性大学",
        OPTION_LIBERAL_ARTS: "文理学院",
        OPTION_MENONLY: "男校",
        OPTION_WOMENONLY: "女校",
    },
    FEEDBACK: {
        TITLE: "反馈",
        SUBTITLE:
            "请告诉我们您选择另一个套餐的原因，以便于我们在将来进一步改进推荐系统",
        Q01: "家长或学生要求另一个套餐",
        Q02: "我觉得推荐的套餐不适合他们的能力",
        Q03: "我觉得学术能力分数不够准确",
        Q04: "我觉得申请准备分数不够准确",
        Q05: "我觉得职业规划分数不够准确",
        Q06: "我觉得语言能力分数不够准确",
        NOTES: "另外反馈",
    },
    INFO_LABELS: {
        NAME: "姓名",
        PHONENUMBER: "电话号码",
        DATEOFBIRTH: "生日",
        GENDER: "性别",
        LOCATION: "居住地址",
        "001_GRADE": "当前年级",
        "002_ENT_EXAM": "中考成绩总分",
        "003_ENT_EXAM_EN": "中考英语成绩",
        "004_CLASS_RANK": "最近学期的班级排名",
        "005_SCHOOL_RANK": "最近学期的学校排名",
        "006_SCHOOL_TYPE": "学校类型",
        "007_SAT_SCORE": "真实/目标SAT分数",
        "008_TOEFL_SCORE": "真实/目标TOEFL分数",
        "009_SATII_SCORE": "SATII最高分数",
        "010_SATII_SUB": "SATII科目",
        "011_AP_SCORE": "AP最高分数",
        "012_AP_SUB": "AP科目",
        "046_HS_GPA": "高中GPA",
        "013_REL_ABROAD": "请问你有家里人在美国留学吗?",
        "014_SCHOOL_LIST": "请问你是否已有想申请的学校列表和计划?",
        "015_FREE_TIME": "请问你预计每周会有多少空闲时间用在留学申请准备?",
        "016_RES_SCHOOL": "请问你已经花了多少时间在研究美国学校?",
        "017_PAR_EXP": "请问你家长有推荐什么学校给你申请吗?",
        "018_BEEN_ABROAD": "请问你有多少次出国的经历?",
        "019_TOUR_SCHOOL": "请问你去实地考察过您的意向学校吗?",
        "020_MAJOR_SCALE": "专业选择的明确性",
        "021_MAJOR": "目标专业",
        "022_FAV_SUB": "最喜欢的学科",
        "023_AP_COURSE": "计划参加的AP考试",
        "024_CAR_IMPACT": "职业规划的清晰度",
        "025_CAR_CONF": "职业选择的明确性",
        "026_EXTRACUR": "目前参与的课外活动",
        GRADE: "年级",
        HIGH_SCHOOL_NAME: "学校名称",
        HIGH_SCHOOL_TYPE: "学校类型",
        GPA: "GPA",
        GPA_TOTAL: "总GPA",
        SAT_TAKEN: "是否参加过SAT?",
        SAT_TARGET: "SAT目标分数",
        SAT_SCORE: "你的SAT分数",
        SAT_DATE: "SAT考试日期",
        TOEFL_TAKEN: "是否参加过TOEFL?",
        TOEFL_TARGET: "TOEFL目标分数",
        TOEFL_SCORE: "你的TOEFL分数",
        TOEFL_DATE: "TOEFL考试日期",
        ENTRANCE_EXAM_TAKEN: "是否参加过中考?",
        ENTRANCE_EXAM_SCORE: "你的中考分数",
        ENTRANCE_EXAM_ENGLISH_SCORE: "你的英语成绩",
        ENTRANCE_EXAM_DATE: "中考考试日期",
        ENTRANCE_EXAM_ENGLISH_MAX_SCORE: "英语成绩满分",
        ENTRANCE_EXAM_MAX_SCORE: "总成绩满分",
    },
    STATS: {
        MONTH_1: "一月%{year}年",
        MONTH_2: "二月%{year}年",
        MONTH_3: "三月%{year}年",
        MONTH_4: "四月%{year}年",
        MONTH_5: "五月%{year}年",
        MONTH_6: "六月%{year}年",
        MONTH_7: "七月%{year}年",
        MONTH_8: "八月%{year}年",
        MONTH_9: "九月%{year}年",
        MONTH_10: "十月%{year}年",
        MONTH_11: "十一月%{year}年",
        MONTH_12: "十二月%{year}年",
        ALL: "概述",
    },
    PORTAL: {
        ACTIVATE_PROMPT_CREATE: "准备注册前途账号？",
        ACTIVATE_CREATE: "继续",
        TITLE: "你的MobLab账号马上就好",
        YOUR_USERNAME: "你的用户名是",
        YOUR_NUMBER: "记得使用你的手机号登录",
        BEFORE_START: "在开始之前",
        ERROR_HEADER: "密码输入不匹配",
        ERROR_CONTENT: "请保证密码输入一致",
        CREATING: "创建你的账户",
        DOWNLOAD_TITLE: "棒！你可以开始玩了！",
        DOWNLOAD_PROMPT: "下载VisionGuide App开始你的申请测评",
        APP_STORE: "App商城",
        APK: "下载APK",
        EXPIRED_TITLE: "请联系你的顾问老师获取有效链接",
        EXPIRED: "抱歉，该链接已失效!",
        ACTIVATE_PROMPT_CHANGE: "重置你的密码?",
        ACTIVATE_CHANGE: "继续",
        APK_TITLE: "安装安卓应用程序包",
        APK_SUBTITLE: "从我的手机",
        APK_INSTRUCTIONS_1: "当安卓应用程序包下载完成之后, 打开<c>下载<c>",
        APK_INSTRUCTIONS_2:
            "打开已下载的'MobLab Vision'安装包文件, 当提示框跳出, 请点击<c>确认<c>",
    },
    ERROR: {
        ERROR: "发生故障",
        "student already exists": "看起来这个电话号码已经用过!",
        NAME_EMPTY: "请输入个名字.",
        INVALID_PHONE: "电话号码无效.",
        INVALID_EMAIL: "邮箱无效.",
        INVALID_FILE: "仅支持上传.csv文件.",
        NO_DATA: "您上传的.csv文件为空.",
        INVALID_HEADERS: "您的列标题必须匹配: %{headers}.",
        NO_EMPTY_ROWS: "不支持空白行.",
        INVALID_EMAIL_FORMAT: "您的文件中含有无效EMAIL: %{email}.",
        HAS_DUPLICATES: "以上EMAIL有重复.",
        ALL_DUPLICATES: "所添加用户已存在.",
        EMAIL_EXISTS: "以上EMAIL已存在.",
        FAILED_TO_CREATE: "添加以上用户失败: EMAIL已被注册.",
        ATTACHMENT_REQUIRED: "附件不能为空",
        UPDATE_PROTOCOL_REQUIRED: "请先更新协议",
    },
};
