import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { TimeUtil } from "lib/utils";
import { Form, Row, Col, Input } from "antd";
const { TextArea } = Input;

const config = [
    {
        label: "确认入读院校",
        fieldName: "schoolNameEN",
    },
    {
        label: "学生生日",
        fieldName: "birthday",
    },

    {
        label: "护照到期日",
        fieldName: "passportEndDate",
        isDate: true,
    },
    {
        label: "确认入读网址",
        fieldName: "website",
    },
    {
        label: "确认入读账号",
        fieldName: "account",
    },
    {
        label: "确认入读密码",
        fieldName: "password",
    },
    {
        label: "学生ID",
        fieldName: "stuId",
    },
    {
        label: "学生邮箱",
        fieldName: "stuMail",
    },
    {
        label: "密码",
        fieldName: "passwd",
    },
    {
        label: "Request I20",
        fieldName: "requestI20Value",
    },
    {
        label: "I20已到达",
        fieldName: "arrivedI20Value",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "SEVIS ID",
        fieldName: "SEVISId",
    },
    {
        label: "SEVIS Fee",
        fieldName: "SEVISFeeValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "住宿截止",
        fieldName: "roomOvertime",
        isDate: true,
    },
    {
        label: "提交住宿申请",
        fieldName: "bookroomValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "Placement Test",
        fieldName: "placementTestValue",
    },
    {
        label: "Placement Test 截止日期",
        fieldName: "placementTestEndDate",
        isDate: true,
    },
    {
        label: "注册 Orientation",
        fieldName: "registerOrientationValue",
    },
    {
        label: "签培时间",
        fieldName: "signTime",
        isDate: true,
    },
    {
        label: "发送签证、行前材料",
        fieldName: "sendVisaValue",
    },
    {
        label: "签证时间",
        fieldName: "visaTime",
        isDate: true,
    },
    {
        label: "签证结果",
        fieldName: "visaResultValue",
    },
    {
        label: "",
        span: 16,
    },
    {
        label: "疫苗截止日期",
        fieldName: "vaccineTableEndDate",
        isDate: true,
    },
    {
        label: "提交疫苗表",
        fieldName: "submitVaccineTableValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "已开放给学生",
        fieldName: "openedToStuValue",
    },
    {
        label: "Final 成绩单提交",
        fieldName: "submitFinalReportCardValue",
    },
    {
        label: "预定接机",
        fieldName: "pickupValue",
    },
    {
        label: "备选学校",
        fieldName: "secondSchoolNameEN",
    },
    {
        label: "是否报完成",
        fieldName: "submitFinishValue",
    },
    {
        label: "未完成原因",
        fieldName: "unFinishReason",
    },
    {
        label: "备注",
        fieldName: "remark",
        span: 24,
        DisplayComponent: TextArea,
    },
];

function getParsedStudentInfo(studentData) {
    const baseInfo = studentData.studentInfo || {};
    const admissionInfos = studentData.admissionInfos || [];
    const birthday = TimeUtil.formatDate(baseInfo.birthday);
    let parsedAdmissionInfos = [];
    _.each(admissionInfos, function (item) {
        let obj = {
            ...item,
            birthday,
            contractId: item.contractInfo.contractId,
        };
        parsedAdmissionInfos.push(obj);
    });

    return parsedAdmissionInfos;
}

function SummaryAdmissionInfo({ studentData }) {
    const [admissionInfos, setAdmissionInfos] = useState({});

    useEffect(() => {
        setAdmissionInfos(getParsedStudentInfo(studentData));
    }, [studentData, setAdmissionInfos]);
    const getFields = useCallback((config, coreApplyInfoData) => {
        const children = [];

        _.each(config, function (
            { label, fieldName, span = 8, DisplayComponent, isDate },
            index
        ) {
            if (label === "") {
                children.push(<Col span={span} key={index}></Col>);
                return;
            }

            let value = coreApplyInfoData[fieldName];
            if (isDate) {
                value = TimeUtil.formatDate(value);
            }
            children.push(
                <Col span={span} key={index}>
                    <Form.Item label={label} colon={false} labelAlign="left">
                        {DisplayComponent ? (
                            <DisplayComponent value={value} readOnly />
                        ) : (
                            <Input readOnly value={value} />
                        )}
                    </Form.Item>
                </Col>
            );
        });

        return children;
    }, []);

    return _.map(admissionInfos, function (item, index) {
        return (
            <StyledContainer key={index}>
                <header className="section-header">
                    入读确认{" "}
                    {admissionInfos.length > 1
                        ? `(合同号:${item.contractId})`
                        : ""}
                </header>
                <Form
                    className="section-form"
                    initialValues={{
                        size: "small",
                    }}
                >
                    <Row gutter={24} className="page-break-auto">
                        {getFields(config, item)}
                    </Row>
                </Form>
            </StyledContainer>
        );
    });
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})``;

SummaryAdmissionInfo.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryAdmissionInfo;
