import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ReportHeader from "../ReportHeader";
import Pager from "../Pager";
import tieImg from "img/report/tier/tier-mini-tip.png";
import zh from "lib/i18n/data/zh";
import tiersScoreConditions from "data/tiers";

const tieNameMap = {
    "-1": "--",
    "0": "常春藤工作室",
    "1": "精英",
    "2": "志领",
    "3": "留美",
};
const TierSummary = ({ tier, scores = {}, title }) => {
    return (
        <StyledContainer>
            <ReportHeader title={title} />
            <div className="page-title">推荐套餐</div>
            <div className="suggest-label">学生已经被分配到</div>
            <div className="suggest-bar">
                <div className="left-part">
                    <div className="color-bar"></div>
                    <div className="big-name">{tieNameMap[tier]}</div>
                </div>
                <div className="right-part">
                    <div className="overview-column">
                        <div className="column-label">整体得分:</div>
                        <div className="overview-column-score">
                            {Math.floor(
                                Math.floor(
                                    (scores.academic_ability +
                                        scores.application_readiness +
                                        scores.career_focus +
                                        scores.language_aptitude) /
                                        4
                                )
                            )}
                        </div>
                    </div>
                    <div className="subitem-column">
                        <div className="column-label">分项得分:</div>
                        <div className="subitem-row">
                            <div className="subitem-label">学术能力</div>
                            <div className="subitem-score">
                                {Math.floor(scores.academic_ability)}
                            </div>
                        </div>
                        <div className="subitem-row">
                            <div className="subitem-label">申请准备</div>
                            <div className="subitem-score">
                                {Math.floor(scores.application_readiness)}
                            </div>
                        </div>
                        <div className="subitem-row">
                            <div className="subitem-label">职业规划</div>
                            <div className="subitem-score">
                                {Math.floor(scores.career_focus)}
                            </div>
                        </div>
                        <div className="subitem-row">
                            <div className="subitem-label">语言能力</div>
                            <div className="subitem-score">
                                {Math.floor(scores.language_aptitude)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tier-detail">
                <div className="tier-header">
                    <div className="header-icon">
                        <img alt="" src={tieImg} />
                    </div>
                    <div className="header-text">套餐详解</div>
                </div>
                <div className="tier-name">{tieNameMap[tier]}</div>
                <div className="tier-overview-score">
                    <div className="score-block">
                        <div className="score-type">整体得分</div>
                        <div className="score-range">
                            {tiersScoreConditions[tier].total}
                        </div>
                    </div>
                    <div className="score-block">
                        <div className="score-type">分项得分</div>
                        <div className="score-range">
                            {tiersScoreConditions[tier].individual}
                        </div>
                    </div>
                </div>
                {_.map(
                    ["学术能力", "申请准备", "职业规划", "语言能力"],
                    function (item, index) {
                        return (
                            <React.Fragment key={index}>
                                <div className="subitem-name">{item}</div>
                                <div className="subitem-desc">
                                    {zh.PLANS["TIER_" + tier + "_" + index]}
                                </div>
                            </React.Fragment>
                        );
                    }
                )}
            </div>
            <Pager />
        </StyledContainer>
    );
};

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .page-title {
        margin: 48px 42px 8px;
        color: #1b2224;
        font-size: 30px;
        font-weight: bold;
    }
    .suggest-label {
        color: #47525d;
        font-size: 14px;
        margin-left: 42px;
        margin-right: 42px;
        margin-bottom: 16px;
    }
    .suggest-bar {
        height: 186px;
        display: flex;
        background: #f7f9fa;
        .left-part {
            height: 100%;
            width: 50%;
            position: relative;
            .color-bar {
                width: 16px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #30b9f8;
            }
            .big-name {
                color: #30b9f8;
                font-size: 48px;
                font-weight: bold;
                line-height: 87px;
                margin-top: 16px;
                margin-left: 42px;
            }
        }
        .right-part {
            height: 100%;
            width: 50%;
            display: flex;
            .column-label {
                font-size: 14px;
                color: #60728e;
                margin-top: 16px;
                margin-bottom: 4px;
            }
            .overview-column {
                height: 100%;
                margin-right: 42px;
                .overview-column-score {
                    color: #47525d;
                    font-size: 48px;
                    line-height: 70px;
                    font-weight: bold;
                }
            }
            .subitem-column {
                .subitem-row {
                    margin-bottom: 12px;
                    display: flex;
                    line-height: 22px;
                    .subitem-label {
                        font-weight: 500;
                        color: #60728e;
                        font-size: 18px;
                        margin-right: 80px;
                    }
                    .subitem-score {
                        width: 36px;
                        text-align: right;
                        color: #47525d;
                    }
                }
            }
        }
    }
    .tier-detail {
        margin: 24px 42px 0;
        .tier-header {
            display: flex;
            margin-bottom: 24px;
            .header-icon {
                height: 30px;
                width: 30px;
                border-radius: 30px;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #4386cb;
                img {
                    width: 18px;
                    height: 18px;
                }
            }
            .header-text {
                height: 30px;
                line-height: 30px;
                font-weight: 500;
                font-size: 18px;
                color: #4386cb;
            }
        }
        .tier-name {
            color: #47525d;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 16px;
        }
        .tier-overview-score {
            display: flex;
            margin-bottom: 24px;
            .score-block {
                background: #f7f9fa;
                border-radius: 4px;
                width: 80px;
                height: 88px;
                margin-right: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .score-type {
                    margin-bottom: 16px;
                    font-size: 12px;
                    color: #60728e;
                }
                .score-range {
                    font-weight: 500;
                    font-size: 16px;
                    olor: #60728e;
                }
            }
        }
        .subitem-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 34px;
            color: #1b2224;
        }
        .subitem-desc {
            font-size: 12px;
            line-height: 20px;
            color: #47525d;
            margin-bottom: 16px;
        }
    }
`;

TierSummary.propTypes = {
    tier: propTypes.number.isRequired,
    scores: propTypes.shape({
        academic_ability: propTypes.number.isRequired,
        language_aptitude: propTypes.number.isRequired,
        application_readiness: propTypes.number.isRequired,
        career_focus: propTypes.number.isRequired,
    }),
};

export default TierSummary;
