import React, { Component } from 'react'
import { RippleButton, Input, PasswordReqs } from '../'
import { i18n, Request } from "../../lib"
import { PasswordValidator } from "../../lib/utils"
import datastore from "../../data/store"
import { Button, Message, Modal, Form } from 'semantic-ui-react'
import '../../scss/Modal.scss';

/**
 * @class ChangePassword
 * 
 * A modal that allows a user to change their password. 
 * User data is fetched directly from the datastore, rather than passed in through props.
 * 
 * @prop {Boolean}  open    show/hide modal
 * @prop {Function} onClose close modal handler, as display boolean is external
 */
class ChangePassword extends Component {
    state = {
        current_password: "",
        new_password: "",
        confirm_password: "",
        loading: false,
        error: false,
        errorCode: "",
        validation: {},
        success: false
    }
    onCurrentChange({ target }){
        this.setState({
            current_password: target.value, 
            error: false, 
            errorCode: "", 
            validation: {}
        });
    }
    onNewChange({ target }){
        this.setState({
            new_password: target.value, 
            error: false, 
            errorCode: "", 
            validation: {}
        });
    }
    onConfirmChange({ target }){
        this.setState({
            confirm_password: 
            target.value, 
            error: false, 
            errorCode: "", 
            validation: {}
        });
    }
    submit(){
        const { current_password, new_password, confirm_password } = this.state;
        if (new_password !== confirm_password){
            this.setState({
                error: true,
                errorCode: "MISMATCH"
            })
            return;
        }
        const result = PasswordValidator.validate(new_password);
        if (!result.valid){
            this.setState({
                error: true,
                errorCode: "INVALID",
                validation: result
            })
            return;
        }
        this.setState({loading: true});
        const user = datastore.get("USER");

        Request.POST('session/signin', {
            data: {
                email: user.email, 
                password: current_password
            },
            onSuccess: () => {
                Request.POST('user/password', {
                    data: {
                        id: user._id,
                        password: new_password
                    },
                    onSuccess: () => {
                        this.setState({loading: false, success: true});
                    },
                    onFail: () => {
                        this.setState({loading: false});
                        return true;
                    }
                })
            },
            onFail: () => {
                this.setState({
                    loading: false,
                    error: true,
                    errorCode: "WRONG"
                });
            }
        });
    }
    render(){
        const { 
            current_password, new_password, confirm_password, 
            error, errorCode, validation
        } = this.state;
        const canSubmit = current_password.length > 0 && 
            new_password.length > 0 && 
            confirm_password.length > 0 && 
            validation.valid !== false;
        return (
            <Modal 
                className='ChangePassword fullscreen'
                basic
                dimmer="inverted" 
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.open}
                // open={true}
            >
                <div className="icon close" onClick={this.props.close}>e</div>
                <Modal.Header>{i18n.t('PASSWORD.CHANGE')}</Modal.Header>
                <Modal.Content>
                    <Form error={error}>
                        <Input 
                            type="password"
                            label={i18n.t('PASSWORD.CURRENT')}
                            onChange={this.onCurrentChange.bind(this)}
                            focused
                        />
                        <Input 
                            type="password"
                            label={i18n.t('PASSWORD.NEW')}
                            onChange={this.onNewChange.bind(this)}
                        />
                        <Input 
                            type="password"
                            label={i18n.t('PASSWORD.CONFIRM')}
                            onChange={this.onConfirmChange.bind(this)}
                        />
                        <Message 
                            error
                            content={i18n.t("PASSWORD." + errorCode)}
                        />
                        { this.state.success && 
                            <Message 
                                positive
                                content={i18n.t("PASSWORD.CHANGE_SUCCESS")}
                            />
                        }
                    </Form>
                    <PasswordReqs validation={validation}/>
                </Modal.Content>
                <Modal.Actions>
                    <RippleButton 
                        empty flat
                        text={i18n.t('APP.CANCEL')}
                        onClick={this.props.close}
                    />
                    <Button
                        className={canSubmit ? '' : 'disabled'}
                        positive
                        loading={this.state.loading}
                        disabled={!canSubmit}
                        content={i18n.t('APP.SUBMIT')}
                        onClick={this.submit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default ChangePassword