import _ from "lodash";
import React, { useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Form, Row, Col, Input } from "antd";
import cn from "classnames";
const { TextArea } = Input;

const fieldConfig = [
    {
        label: "院校排名",
        fieldName: "schoolRank",
    },
    {
        label: "选校状态",
        fieldName: "archiveValue",
    },
    {
        label: "院校提交进度",
        fieldName: "submitProgressValue",
    },
    {
        label: "申请专业",
        fieldName: "majorNameCH",
    },
    {
        label: "实际申请专业",
        fieldName: "realityMajorName",
    },
    {
        label: "截止日期",
        fieldName: "overdate",
    },
    {
        label: "申请系统",
        fieldName: "applySystemValue",
    },
    {
        label: "申请类型",
        fieldName: "applyType",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "注册网站",
        fieldName: "website",
    },
    {
        label: "申请用户名",
        fieldName: "applyAccount",
    },
    {
        label: "申请密码",
        fieldName: "applyPwd",
    },
    {
        label: "附表文书进度",
        fieldName: "docProgressValue",
    },
    {
        label: "网申提交日期",
        fieldName: "webSubmitDate",
    },
    {
        label: "信用卡支付情况",
        fieldName: "creditCardPayStatusValue",
    },
    {
        label: "申请费",
        fieldName: "cost",
    },
    {
        label: "语言成绩送分",
        fieldName: "languageGiveScoreValue",
    },
    {
        label: "SAT/ACT送分",
        fieldName: "SATACTGiveScoreValue",
    },
    {
        label: "运单号",
        fieldName: "tansId",
    },
    {
        label: "邮寄日期",
        fieldName: "postDate",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "运单号2",
        fieldName: "tansId2",
    },
    {
        label: "邮寄日期2",
        fieldName: "postDate2",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "是否合作",
        fieldName: "isCooperateValue",
    },

    {
        label: "合作院校",
        fieldName: "cooperateAcademyValue",
    },
    {
        label: "合作项目",
        fieldName: "cooperateProject",
    },
    {
        label: "备注",
        fieldName: "remark",
        isRemark: true,
        span: 24,
    },
];

function SubmissionStatusPrint({ dataSource }) {
    const getFields = useCallback((config, schoolData) => {
        const children = [];

        _.each(config, function (
            { label, fieldName, span = 8, isRemark },
            index
        ) {
            if (label === "") {
                children.push(<Col span={span} key={index}></Col>);
                return;
            }

            children.push(
                <Col span={span} key={index}>
                    <Form.Item label={label} colon={false} labelAlign="left">
                        {isRemark ? (
                            <TextArea readOnly value={schoolData[fieldName]} />
                        ) : (
                            <Input readOnly value={schoolData[fieldName]} />
                        )}
                    </Form.Item>
                </Col>
            );
        });

        return children;
    }, []);

    return (
        <StyledContainer>
            {_.map(dataSource, function (schoolData, index) {
                return (
                    <React.Fragment key={index}>
                        <div
                            className={cn([
                                "sub-title",
                                // { "break-before": index > 0 },
                            ])}
                        >
                            {schoolData.schoolNameEN}
                        </div>
                        <Row gutter={24} className="border-row page-break-auto">
                            {getFields(fieldConfig, schoolData)}
                        </Row>
                    </React.Fragment>
                );
            })}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "only-for-print",
})``;

SubmissionStatusPrint.propTypes = {
    dataSource: propTypes.array.isRequired,
};

export default SubmissionStatusPrint;
