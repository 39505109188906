import { Status } from "../../data/enum";
import { Note, Student } from "../../data/model";
import { i18n } from "../../lib";
// import { formatDate } from "../../lib/utils"

const scoreIndices = {
    academic_ability: 0,
    application_readiness: 1,
    career_focus: 2,
    language_aptitude: 3,
};

/**
 * @static Data Converter
 *
 * Used by the datastore to convert data returned from API calls into the
 * correct format required for use by the App.
 */
export default {
    /**
     * Process API results into the required format by the App.
     * Formats differ due to different optimization requirements.
     *
     * @param {String} api
     * @param {Map} data
     *
     * @return {Map} formatted and processed data.
     */
    process(api, data) {
        switch (api.toLowerCase()) {
            case "students":
                return this.processStudents(data);
            case "filters":
                return this.processFilters(data);
            case "exam_types":
                return this.processTestTypes(data);
            case "major_details":
                return this.processMajorDetails(data);
            case "questions":
                return this.processQuestions(data);
            default:
                return data;
        }
    },
    /**
     * Derive or convert student properties to be compatible with client-side
     * hooks.
     *
     * @param {Array} studentData An Array of Student Attributes
     *
     * @return {Array} a processed array of student attributes
     */
    processStudents(studentData) {
        for (var s in studentData) {
            this.processStudent(studentData[s]);
        }
        return studentData;
    },
    /**
     * There are several secondary attributes that are computed from the
     * student's primary attributes:
     *
     * STATUS: this is an enum matching certain attribute conditions
     * REPORT: 1 if student has a suggested tier, 0 otherwise
     * SCORES: sorted by key and reformatted to be easier to sort and graph
     * TOTAL: total score, the sum of SCORES
     *
     * @param {Object} student
     *
     * @return {Object} processed student object
     */
    processStudent(student) {
        // Set default values
        student.gender = "";
        student.location = "";
        student.dateOfBirth = "";

        student.status = getStudentStatus(student);

        if (student.kind === "GK") {
            student.report = student.gk_complete ? 1 : 0;
        } else {
            student.report = student.tier > -1 ? 1 : 0;
        }

        return student;
    },
    /**
     * Process student notes by converting dates into ms values
     *
     * @param {Array} notes
     */
    processNotes(notes) {
        for (var n = 0; n < notes.length; n++) {
            notes[n].updatedAt = new Date(notes[n].updatedAt).getTime();
        }
        return notes;
    },
    /**
     * Process student's standardized test data.
     *
     * Dates should be converted to MS. Flatten test descriptor into the
     * score/exam object. Parse breakdown from JSON string to JSON.
     *
     * @param {Array} exams
     */
    processExams(exams) {
        for (var n = 0; n < exams.length; n++) {
            const exam = exams[n];
            exam.date = new Date(exam.date).getTime();
            exam.breakdown = JSON.parse(exam.breakdown);
            exam.subject = exam.test.subject.en;
            exam.type = exam.test.type;
            exam.test = exam.test.code;
            if (exam.type === "SAT Subject") {
                exam.type = "SAT2";
            }
        }
        return exams;
    },
    /**
     * Convert major fit map into an array of sorted major recommendations.
     *
     * @param {Object} major_fit server-generated major recommendations, mapping
     *                              major (string) to fit (float)
     */
    processMajors(major_fit) {
        const majors = [];
        for (var major in major_fit) {
            majors.push({ key: major.toUpperCase(), value: major_fit[major] });
        }
        return majors.sort((a, b) => b.value - a.value);
    },
    processSchoolFit(school_fit) {
        const sorted = [];
        for (var id in school_fit) {
            sorted.push({ id: id, fit: school_fit[id] });
        }
        return sorted.sort((a, b) => b.fit - a.fit);
    },
    /**
     * Filters must be reformatted for sorting, and output as a single array
     *
     * @param {Object} filterData an object containing the user's default and custom filters
     *
     * @return {Object} an object containing all filters and the number of default filters
     */
    processFilters(filterData) {
        const defaultFilters = [];
        for (var s in Status) {
            var val = Status[s];
            if (val > -1) {
                defaultFilters.push({
                    label: i18n.t("FILTERS.DEFAULT_" + val),
                    query: { status: val },
                    value: val,
                    active: false,
                });
            }
        }
        const customFilters = filterData.map(({ name, query }, c) => ({
            label: name,
            query: query,
            value: defaultFilters.length + c,
            active: false,
        }));
        return {
            filters: defaultFilters.concat(customFilters),
            cutoff: defaultFilters.length,
        };
    },
    processTestTypes(testTypes) {
        const testData = {};
        for (var i = 0; i < testTypes.length; i++) {
            const { code, subject } = testTypes[i];
            var type = testTypes[i].type.replace(" Subject", "2");

            if (!testData.hasOwnProperty(type)) {
                if (subject.en) {
                    testData[type] = {
                        subjects: [],
                        ref: {},
                    };
                } else {
                    testData[type] = testTypes[i];
                }
            }
            if (subject.en) {
                testData[type].subjects.push({
                    code: code,
                    name: subject.en,
                });
                testData[type].ref[code] = testTypes[i];
            }
        }
        return testData;
    },
    processMajorDetails(majorDetails) {
        const majorMap = {};
        for (var m = 0; m < majorDetails.length; m++) {
            const details = majorDetails[m];
            majorMap[details.key] = details;
        }
        return majorMap;
    },
    processQuestions(questionArr) {
        const questionMap = {};
        for (let i = 0; i < questionArr.length; i++) {
            const { key, language } = questionArr[i];
            if (!questionMap.hasOwnProperty(key)) {
                questionMap[key] = {};
            }
            questionMap[key][language] = questionArr[i];
            delete questionMap[key][language].key;
        }
        return questionMap;
    },
    /**
     * Update a subset of parameters of a given student, then update its status
     *
     * @param {Map} student
     * @param {Map} params
     *
     * @return {Map} updated student object
     */
    updateStudent(student, params) {
        for (var param in params) {
            switch (param) {
                case "traits":
                    student.traits = processTraits(params.traits);
                    break;
                case "scores":
                    const { total, sortedScores } = processScores(
                        params.scores
                    );
                    student.total = Math.floor(total);
                    student.overall = Math.floor(total / 4);
                    student.scores = sortedScores;
                    break;
                case "major_recommendation":
                    student.major_fit = this.processMajors(
                        params.major_recommendation
                    );
                    break;
                case "school_recommendation":
                    student.school_fit = this.processSchoolFit(
                        params.school_recommendation
                    );
                    break;
                case "notes":
                case "exams":
                    break;
                default:
                    student[param] = params[param];
            }
        }

        student.status = getStudentStatus(student);
        student.updateTime = Date.now();
        return student;
    },
    /**
     * Create a new student, using the static student model
     *
     * @param {Map}     user
     * @param {Integer} phoneNumber
     *
     * @return {Map}    student object with updated fields
     */
    newStudent(user, phoneNumber) {
        const student = Object.assign({}, Student);
        student.id = user._id;
        student.name = user.name;
        student.phoneNumber = phoneNumber;
        return student;
    },
    /**
     * Create a new note, using the static note model
     */
    newNote(creator) {
        const date = new Date();
        const note = Object.assign({}, Note);
        note.createdAt = date.getTime();
        note.updatedAt = date.getTime();
        note.createdBy = creator;
        note.updatedBy = creator;
        note.attachTo = window.location.pathname.split("/")[3].toUpperCase();
        return note;
    },
};

/******************** Private Methods ********************/

/**
 * Sort scores, cap them, and calculate total score.
 *
 * @param {Array} scores
 */
function processScores(scores) {
    const sortedScores = [];
    var total = 0;
    for (var key in scores) {
        var cappedScore = Math.min(Math.floor(scores[key]), 100);
        sortedScores[scoreIndices[key]] = {
            key: key,
            value: cappedScore,
        };
        total += cappedScore;
    }
    return { total, sortedScores };
}

/**
 * Add labels, cap values, and sort traits.
 *
 * @param {Object} traits map of trait keys to values
 */
function processTraits(traits) {
    var sortedTraits = [];
    for (var trait in traits) {
        sortedTraits.push({
            key: trait,
            value: Math.min(traits[trait], 100),
            label: i18n.t("TRAITS." + trait),
        });
    }
    sortedTraits = sortedTraits.sort(sortByKey);
    return sortedTraits;
}
/**
 * Determine a student's status
 *
 * @param {Map} student student object
 *
 * @return {Int} Student's status enum
 */
function getStudentStatus({ archived, assigned_tier, tier, progress }) {
    if (archived > -1) {
        return Status.ARCHIVED;
    } else if (assigned_tier > -1) {
        return Status.APPLICANT;
    } else if (tier > -1) {
        return Status.READY_FOR_ASSIGNMENT;
    } else if (progress > 0 && progress < 1) {
        return Status.PRE_APPLICANT;
    }
    return Status.UNLINKED;
}

function sortByKey(a, b) {
    return a.key - b.key;
}
