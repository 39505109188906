import React, { Component } from 'react'
import Card from '../Card'
import { i18n } from '../../lib'
import { formatTitleCase, rem } from '../../lib/utils'
import { RadialPercent } from "../"

class SchoolInfo extends Component {
    render() {
        const { fit, data } = this.props;
        const { INSTNM, MOBLAB_RANK, USNEWS_RANK_LIBARTS_2019, CITY, STABBR } = data;
        const ranking = USNEWS_RANK_LIBARTS_2019 ? 
            i18n.t('SCHOOLS.RANKING_LIBARTS', {rank: USNEWS_RANK_LIBARTS_2019}) : 
            i18n.t('SCHOOLS.RANKING', {rank: MOBLAB_RANK});

            return (
            <Card type="school_info">
                <h1>{formatTitleCase(INSTNM)}</h1>
                <h2>
                    <div className="icon location">q</div>
                    {formatTitleCase(CITY) + ", " + STABBR}
                </h2>
                <h3>
                    <div className="icon trophy">c</div>
                    {ranking}
                </h3>
                <RadialPercent 
                    header={i18n.t('SCHOOLS.STUDENT_FIT')}
                    value={fit}
                    r={rem(2.5)}
                />
                <div className="compareBar">
                </div>
            </Card>
        )
    }
}

export default SchoolInfo;
