import { Router } from "."
import * as TestData from "../data/test"

// window.spoofRequests = window.location.origin === "http://localhost:7546";
// window.spoofRequests = true;
const alwaysSpoof = [
    'advisor/filters',
    'advisor/newStudent',
    // 'advisor/students',
    // 'user'
];

export default {
    /**
     * Create a GET request
     *
     * @param {String} path     request URL path
     * @param {Object} params   request parameters
     *          {Object} [.data]        request payload
     *          {Function} [.onSuccess] successful request callback
     *          {Function} [.onFail]    failed request callback
     */
    GET(path, params){
        this.makeRequest('GET', path, params);
    },
    /**
     * Create a POST request
     *
     * @param {String} path     request URL path
     * @param {Object} params   request parameters
     *          {Object} [.data]        request payload
     *          {Function} [.onSuccess] successful request callback
     *          {Function} [.onFail]    failed request callback
     */
    POST(path, params){
        console.log("POST: ", params);
        this.makeRequest('POST', path, params);
    },
    /**
     * Create a DELETE request
     *
     * @param {String} path     request URL path
     * @param {Object} params   request parameters
     *          {Object} [.data]        request payload
     *          {Function} [.onSuccess] successful request callback
     *          {Function} [.onFail]    failed request callback
     */
    DELETE(path, params){
        console.log("DELETE: ", params);
        this.makeRequest('DELETE', path, params);
    },
    bounce(){
        console.warn("REQUEST BOUNCE IS UNLINKED");
    },
    makeRequest(method, path, params){
        if (window.spoofRequests || alwaysSpoof.indexOf(path) > -1){
            this.spoofRequest(method, path, params);
            return;
        }
        const options = {
            method: method,
            // mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        };
        if (params.cacheBust){
            options.headers.Pragma = 'no-cache';
        }
        if (method === 'POST'){
            options.body = JSON.stringify(params.data || "");
        }
        fetch(Router.baseUrl + path, options).then( response => {
            // console.log("RESPONSE: ", response, response.headers);
            if (response.ok && params.hasOwnProperty('onSuccess')){
                response.json().then(data => params.onSuccess(data))
            } else {
                response.json().then(data => onFail(response, data.error))
            }
        }).catch(onFail)

        var self = this;
        function onFail(response, error){
            // console.log("RESPONSE ON FAIL: ", response, params)
            var showError = !params.hasOwnProperty('onFail');
            if (params.hasOwnProperty('onFail')){
                showError = params.onFail(response.status, error);
            } 
            if (showError){
                self.bounce(response.status, error);
            }
        }
    },
    /**
     * Spoof requests by returning hard coded data in order to test specific
     * behaviors or simply bypass cors issues on localhost. Spoofing can be
     * toggled on/off globally by setting the flag 'window.spoofRequests'. This
     * can be done at the top of this file or in the inspector terminal.
     *
     * Spoof data is stored in './data/test'
     *
     * @param {String} method
     * @param {String} path
     * @param {Object} params
     *
     * @return hard coded data from test data store
     */
    spoofRequest(method, path, params){
        setTimeout(() => {
            var data;
            if (method === 'GET'){

                switch (path){
                    case 'advisor/students':
                        data=TestData.students;
                        break;
                    case 'advisor/filters':
                        data=TestData.filters;
                        break;
                    default:
                        data=TestData[path];
                }
                console.log( "|| REQUEST || Spoofing Request [GET /" + path + "]:", data);
            } else {
                switch (path){
                    case 'advisor/newStudent':
                        data=TestData.newStudent;
                        var id = Math.floor(Math.random() * 5000000000000) + 150000000000000;
                        data.name = data.fields.name = params.data.name;
                        data.fields.numericId = id;
                        break;
                    default:
                        data={}
                }
                console.log(
                    "|| REQUEST || Spoofing Request [POST /" + path + "]:\n",
                    params.data,
                    data
                );
            }
            params.onSuccess(JSON.parse(JSON.stringify(data)))
        })
    }
};
