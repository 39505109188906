import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import SubSection from "../SubSection";
import MajorList from "./MajorList";

function PartMajor({ reportData, partOrder, part }) {
    const parsedMajorData = _.map(reportData.major_recommendation, function (
        value,
        key
    ) {
        return {
            key,
            value,
        };
    });
    const orderedMajorData = _.orderBy(parsedMajorData, ["value"], ["desc"]);
    return (
        <React.Fragment>
            <SubSection
                title={part.title}
                partKey={part.key}
                sectionNo={partOrder}
            />
            <MajorList
                title={part.title}
                majors={[orderedMajorData[0]]}
                isFirstPage
            />
            {_.map(orderedMajorData, function (major, index) {
                if (index === 0) {
                    return null;
                }

                if ((index - 1) % 2 === 0) {
                    if (orderedMajorData[index + 1]) {
                        return (
                            <MajorList
                                key={index}
                                title={part.title}
                                majors={[major, orderedMajorData[index + 1]]}
                            />
                        );
                    } else {
                        return (
                            <MajorList
                                title={part.title}
                                key={index}
                                majors={[major]}
                            />
                        );
                    }
                }
            })}
        </React.Fragment>
    );
}

PartMajor.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default PartMajor;
