import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import i18n from "../lib/i18n/i18n";

class StudentTable extends Component {
    getBoolIcon(bool){
        return (
            <i className={"icon " + (bool ? "yes" : "no")}>{bool ? "B" : "2"}</i>
        )
    }
    geti18nKey(key){
        return key.replace('assigned_', '').toUpperCase();
    }
    renderCell(student, column){
        switch(column){
            case "name":
                return student.name
            case "report":
                return this.getBoolIcon(student.report)
            default:
                const key = this.geti18nKey(column);
                return i18n.t("STUDENTS." + key + "_" + student[column]);
        }
    }
    render() {
        const { data, columns, sortKey, sortDirection } = this.props;
        return (
            <Table className="StudentTable">
                <Table.Header>
                    <Table.Row>
                        { columns.map((key) => (
                            <Table.HeaderCell
                                key={key}
                                className={sortKey === key ? sortDirection: ""}
                                onClick={() => {this.props.sort(key)}}
                            >
                                {i18n.t('STUDENTS.' + key.toUpperCase())}
                                <div className="icon arrow" />
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { data.map((data) => (
                        <Table.Row key={data.id}
                            onClick={() => { this.props.goTo(data.id) }}
                        >
                            { columns.map((key, i) => (
                                <Table.Cell key={data.id + i}>
                                    {this.renderCell(data, key)}
                                </Table.Cell>
                            )) }
                        </Table.Row>
                    )) }
                </Table.Body>
            </Table>
        )
    }
}

export default StudentTable;
