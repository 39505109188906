import React from "react";
// import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import { Rate } from "antd";

function MajorSubItem({ subMajor }) {
    return (
        <StyledContainer>
            <div className="sub-item-title">
                <span className="item-name">{subMajor.name}</span>
                <span className="item-code">
                    专业代码: {subMajor.major_code}
                </span>
            </div>
            <ul className="item-text">
                <li>培养目标: {subMajor.target}</li>
                <li>主要课程: {subMajor.course}</li>
            </ul>
            <div className="item-vision">
                <span className="item-vision-text">留学深造前景</span>
                <Rate
                    disabled
                    allowHalf
                    defaultValue={subMajor.abroadVista}
                    style={{
                        fontSize: "24px",
                        color: "#FEBD55",
                    }}
                />
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "left-right-padding avoid-break-inside sub-major-item",
})`
    margin-bottom: 50px;
    .sub-item-title {
        margin-bottom: 10px;
        line-height: 20px;
        height: 20px;
        .item-name {
            color: #1b2224;
            font-size: 16px;
            margin-right: 16px;
            vertical-align: middle;
            font-weight: bold;
        }
        .item-code {
            vertical-align: middle;
            color: #60728e;
            font-size: 12px;
            background: #f7f9fa;
            border-radius: 4px;
            padding: 2px 8px;
        }
    }
    .item-text {
        margin-bottom: 0;
        padding-inline-start: 20px;
        li {
            font-size: 12px;
            line-height: 20px;
            color: #47525d;
        }
    }
    .item-vision {
        height: 40px;
        padding-left: 20px;
        .item-vision-text {
            color: #8a97a5;
            margin-right: 12px;
            font-size: 12px;
        }
    }
`;

MajorSubItem.propTypes = {
    subMajor: propTypes.object.isRequired,
};

export default MajorSubItem;
