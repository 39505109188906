import React, { useState, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import propTypes from "prop-types";
import ChangeStatus from "routes/excel/ChangeStatus";

const bgColorMap = {
    信息收集: "#FFD64B29",
    选校: "#45A0FF29",
    申请准备: "#05D3BC29",
    申请提交: "#D8DCE129",
    申请跟踪: "#FF698029",
    入读规划: "#FFAB4A29",
    暂停服务: "#605D5D89",
    服务完成: "#6BF06429",
};
const colorMap = {
    信息收集: "#000000",
    选校: "#000000",
    申请准备: "#000000",
    申请提交: "#000000",
    申请跟踪: "#000000",
    入读规划: "#000000",
    暂停服务: "#ffffff",
    服务完成: "#000000",
};

const borderColorMap = {
    信息收集: "#FFD64B",
    选校: "#45A0FF",
    申请准备: "#05D3BC",
    申请提交: "#D8DCE1",
    申请跟踪: "#FF6980",
    入读规划: "#FFAB4A",
    暂停服务: "#000000",
    服务完成: "#60AE41",
};

export const codeTextMap = {
    "0": "信息收集",
    "1": "选校",
    "2": "申请准备",
    "3": "申请提交",
    "4": "申请跟踪",
    "5": "入读规划",
    "6": "暂停服务",
    "7": "服务完成",
};

function ApplyStatus({ statusCode, studentId, studentName, fetchData,contractId,contractStatus }) {
    const statusText = codeTextMap[statusCode];
    const bgColor = bgColorMap[statusText];
    const borderColor = borderColorMap[statusText];
    const color = colorMap[statusText];
    const [isShowModal, setIsShowModal] = useState(false);

    const onClickLabel = useCallback(() => {
        setIsShowModal(true);
    }, [setIsShowModal]);
    const canEdit = contractStatus!=='7';
    return statusText ? (
        <>
            <StyledContainer
                clickable={!!contractId}
                bgColor={bgColor}
                borderColor={borderColor}
                color={color}
                onClick={canEdit?onClickLabel:null}
            >
                <GlobalStyle />
                {statusText}
            </StyledContainer>
            {contractId ? (
                <ChangeStatus
                    applyStatus={statusCode}
                    studentId={studentId}
                    contractId={contractId}
                    studentName={studentName}
                    fetchData={() => {
                        fetchData();
                        setIsShowModal(false);
                    }}
                    isShowText={false}
                    visible={isShowModal}
                    onCancel={() => {
                        setIsShowModal(false);
                    }}
                />
            ) : null}
        </>
    ) : (
        <div></div>
    );
}

const GlobalStyle = createGlobalStyle`
	.ant-table.ant-table-small .ant-table-tbody > tr > td.column-apply-status {
        padding: 3px 8px;
	}
`;

const StyledContainer = styled.div.attrs({
    className: "apply-status",
})`
    font-size: 13px;
    width: 100px;
    height: 30px;
    background: ${(props) => props.bgColor} 0% 0% no-repeat padding-box;
    border: 3px solid ${(props) => props.borderColor};
    color: ${(props) => props.color};
    border-radius: 15px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

ApplyStatus.propTypes = {
    statusCode: propTypes.string.isRequired,
    studentId: propTypes.string,
    studentName: propTypes.string,
    fetchData: propTypes.func,
};

export default ApplyStatus;

export function ApplyStatusInModalTitle({
    statusCode,
    studentName,
    titleText,
}) {
    return (
        <StyledContainerForTitle>
            <span className="title-text">
                {titleText}: {studentName}
            </span>
            <ApplyStatus statusCode={statusCode} />
        </StyledContainerForTitle>
    );
}

const StyledContainerForTitle = styled.div.attrs({
    className: "apply-status-title",
})`
    display: flex;
    align-items: center;
    .apply-status {
        margin-left: 20px;
    }
    .title-text {
        font-size: 20px;
    }
`;
