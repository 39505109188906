import React from "react";
import { i18n } from "../lib";
import { RippleButton } from "./";
import datastore from "../data/store";
import { StudentUtil, AdminUtil } from "lib/utils";
export default class Sidebar extends React.Component {
    isPageEnabled(key, i) {
        if (this.props.hasOwnProperty("isPageEnabled")) {
            return this.props.isPageEnabled(key, i);
        }
        return true;
    }
    isPageHidden(key, i) {
        if (this.props.hasOwnProperty("isPageHidden")) {
            return this.props.isPageHidden(key, i);
        }
        return true;
    }
    render() {
        const { parent, page, model, changePage } = this.props;
        const renderConditions = datastore.get("RENDER_CONDITIONS");
        return (
            <div className="sidebar">
                {model.map(({ key, pages }, i) => (
                    <div className="navGroup" key={i}>
                        <h3>{i18n.t(parent + "." + key)}</h3>
                        {pages.map(({ key, icon, renderCondition }, p) => {
                            const isVisionAccount = AdminUtil.isVisionAccount();
                            if (!isVisionAccount && key === "NOTES") {
                                return null;
                            }

                            return (
                                (!renderCondition ||
                                    renderConditions[renderCondition]) && (
                                    <RippleButton
                                        key={p + "_" + i}
                                        flat
                                        fillFrom="center"
                                        icon={icon}
                                        text={StudentUtil.updateUStoForeignCountry(
                                            i18n.t(parent + "." + key),
                                            "PROFILE.SCHOOL_FIT"
                                        )}
                                        disabled={!this.isPageEnabled(key, i)}
                                        hidden={!this.isPageHidden(key, i)}
                                        selected={page === key}
                                        onClick={() => changePage(key)}
                                    />
                                )
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }
}
