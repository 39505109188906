import React, { Component } from 'react';
import "../scss/RippleButton.scss";

/**
 * @class RippleButton
 * 
 * A custom button class that uses Google's Material Design paradigm. 
 * This button has a fill that expands to fill the container on hover. 
 * 
 * @prop {String}  [icon]     use an icon as the button contents
 * @prop {String}  [text]     use text as the button contents
 * @prop {String}  [fillFrom] fill direction ['center', 'left']
 * @prop {Map}     [style]    button style
 * @prop {Boolean} [empty]    button will have no default fill
 * @prop {Boolean} [flat]     button will have no box-shadow
 * @prop {Boolean} [white]    button will have a white fill (rather than darken)
 * @prop {Boolean} [disabled] button will be disabled and appear in a disabled state
 * @prop {Boolean} [selected] button will be disabled but appear in the default state
 */
class RippleButton extends Component {
    onClick() {
        if (this.props.disabled || !this.props.onClick) {
            return;
        }
        this.props.onClick();
    }
    render() {
        var propClass = this.props.className || "";
        var classKeys = ["empty", "flat", "white", "disabled", "selected"];
        for (var k = 0; k < classKeys.length; k++) {
            const key = classKeys[k];
            if (this.props.hasOwnProperty(key) && this.props[key]) {
                if (propClass.length > 0) {
                    propClass += " ";
                }
                propClass += classKeys[k];
            }
        }

        const fillFrom = this.props.fillFrom || "center";
        return (
            <div className={"Ripple " + propClass}
                style={this.props.style}
                onClick={this.onClick.bind(this)}
                hidden={this.props.hidden}
            >
                <div className={"fill " + fillFrom}></div>
                {this.props.icon &&
                    <div className="icon">{this.props.icon}</div>
                }
                {this.props.text &&
                    <div className="text">{this.props.text}</div>
                }
                {this.props.children}
            </div>
        )
    }
}

export default RippleButton;
