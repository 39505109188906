import React, { Component } from "react";
import { Pagination, message } from "antd";
import { Request } from "../../lib";
import moment from "moment";
import Editable from "./Editable";
import DataDict from "./DataDict";
import _ from "lodash";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";

class BaseInfo extends Component {
    state = {
        data: [],
        currentPage: 1,
        total: 0,
        pageSize: 50,
        loading: true,
        local: {
            emptyText: " ",
        },
        sorter: {},
        datadict: {
            quarterType: DataDict.getMap("moblab:contractinfo:quarterType"),
            writingType: DataDict.getMap("moblab:contractinfo:writingType"),
            docOpen: DataDict.getMap("moblab:contractinfo:docOpen"),
            accountOpen: DataDict.getMap("moblab:contractinfo:accountOpen"),
            country: DataDict.getMap("erp:country"),
            specialType: DataDict.getMap("moblab:contractinfo:specialType"),
            status: DataDict.getMap("moblab:studentinfo:status"),
            gender: DataDict.getMap("moblab:studentinfo:gender"),
            countryCode: DataDict.getMap("moblab:en:countryCode"),
            region: DataDict.getMap("moblab:seniorhighschool:region"),
        },
    };
    UNSAFE_componentWillMount = () => {
        this.init();
    };
    UNSAFE_componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/studentinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    message.error("查询失败");
                },
            }
        );
    };

    renderDataSource = (datasource) => {
        let ds = datasource.data;
        for (let i = 0; i < ds.length; i++) {
            // ds[i] = { ...ds[i]["stdinfo"] };
            ds[i]["contractStatus"] = ds[i]["status"];
            ds[i]["key"] = i;
            ds[i]["number"] = i + 1;
            ds[i]["studentId"] = ds[i]["std"]["_id"];
            ds[i]["name"] = ds[i]["std"]["name"];
            ds[i]["quarterType"] = this.state.datadict.quarterType[
                ds[i]["quarterType"]
            ];
            ds[i]["writingType"] = this.state.datadict.writingType[
                ds[i]["writingType"]
            ];
            ds[i]["docOpen"] = this.state.datadict.docOpen[ds[i]["docOpen"]];
            ds[i]["accountOpen"] = this.state.datadict.accountOpen[
                ds[i]["accountOpen"]
            ];
            ds[i]["countryId"] = this.state.datadict.country[
                ds[i]["countryId"]
            ];
            ds[i]["specialType"] = this.state.datadict.specialType[
                ds[i]["specialType"]
            ];
            ds[i]["status"] = this.state.datadict.status[ds[i]["status"]];
            ds[i]["caseDate"] = ds[i]["caseDate"]
                ? moment(ds[i]["caseDate"])
                : null;
            ds[i]["gender"] = this.state.datadict.gender[
                ds[i]["std"]["gender"]
            ];
            ds[i]["countryCode"] = this.state.datadict.countryCode[
                ds[i]["countryCode"]
            ];
            if (!_.isEmpty(ds[i]["province"])) {
                ds[i]["autoComplete"] = {
                    province: ds[i]["province"],
                };
            }
            ds[i]["city"] = DataDict.getMap(
                "moblab:seniorhighschool:region:" + ds[i]["province"]
            )[ds[i]["city"]];
            ds[i]["province"] = this.state.datadict.region[ds[i]["province"]];
        }
        this.setState({
            data: ds,
            currentPage: datasource.currentPage,
            total: datasource.total,
            loading: false,
        });
    };

    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        const columns = [
            {
                className: "column-apply-status",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applystatus",
                render: (statusCode, row) => {
                    return (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["std"]["_id"]}
                            studentName={row["std"]["name"]}
                            contractId={row["_id"]}
                            fetchData={this.fetchData}
                            contractStatus={row["contractStatus"]}
                        />
                    );
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                key: "name",
                render: (value, row) => {
                    return (
                        <StudentLink
                            studentName={row["std"]["name"]}
                            contractId={row["_id"]}
                            contractName={row["contractName"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "性别",
                dataIndex: "gender",
                key: "gender",
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.gender, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    Request.POST("studentinfo", {
                        data: {
                            update: { gender: record["gender"] },
                            id: record["std"]["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "110px",
            },
            {
                title: "接案时间",
                dataIndex: "caseDate",
                render: (value, row, index) => {
                    return value ? value.format("YYYY-MM-DD") : hiddenDIV;
                },
                width: "140px",
            },
            {
                title: "入学年",
                dataIndex: "applyYear",
                render: (value, row, index) => {
                    return _.isEqual(value, "5000") ? "待定" : value;
                },
                width: "110px",
            },
            {
                title: "入学季度",
                dataIndex: "quarterType",
                key: "quarterType",
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.quarterType, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                className: "nopadding", //TODO padding-right: 0xp;
                handleSave: (record, value) => {
                    Request.POST("contractinfo/", {
                        data: {
                            attr: "quarterType",
                            val: record["quarterType"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "110px", //TODO
            },
            {
                title: "业务类型",
                dataIndex: "contractName",
                key: "contractName",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
                width: "200px",
            },
            {
                title: "合同号",
                dataIndex: "contractNo",
                key: "contractNo",
                render: (value) => {
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                height: "15px",
                                lineHeight: "15px",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    );
                },
                width: "200px",
            },
            {
                title: "销售顾问",
                dataIndex: "saleAdvisorName",
                width: "200px",
                ellipsis: true,
            },
            {
                title: "中期顾问",
                dataIndex: "midAdvisorName",
                width: "200px",
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "midAdvisorName",
                            val: record["midAdvisorName"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                render: (value, row, index) => {
                    return value ? (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    ) : (
                        hiddenDIV
                    );
                },
            },
            {
                title: "申请顾问",
                dataIndex: "applyAdvisorName",
                width: "200px",
                ellipsis: true,
            },
            {
                title: "文书顾问",
                dataIndex: "docAdvisorName",
                width: "200px",
                ellipsis: true,
            },
            {
                title: "国籍",
                dataIndex: "countryCode",
                editable: true,
                autoComplete: true,
                key: "countryCode",
                width: "250px",
                ellipsis: true,
                handleSearch: (value, callback) => {
                    let ds = [];
                    let sList = DataDict.getDictNew(
                        "moblab:en:countryCode",
                        value
                    );
                    _.each(sList, (e) => {
                        ds.push({
                            key: e.key,
                            value: e.key,
                            desc: e.value,
                            data: e,
                        });
                    });
                    callback(ds);
                },
                handleSave: (record, value, data) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "countryCode",
                            val: value["countryCode"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "写作形式",
                dataIndex: "writingType",
                key: "writingType",
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.writingType, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "writingType",
                            val: record["writingType"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "160px",
            },
            {
                title: "高中所在国家",
                dataIndex: "countryId",
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(this.state.datadict.country, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "countryId",
                            val: record["countryId"],
                            id: record["_id"],
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["_id"],
                            },
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "160px",
                className: "nopadding",
            },
            {
                title: "高中所在省份",
                dataIndex: ["province"],
                key: "province",
                editable: true,
                width: "160px",
                autoComplete: true,
                handleSearch: (value, callback) => {
                    let ds = [];
                    let sList = DataDict.getDictNew(
                        "moblab:seniorhighschool:region",
                        value
                    );
                    _.each(sList, (e) => {
                        ds.push({
                            key: e.key,
                            value: e.key,
                            desc: e.value,
                            data: e,
                        });
                    });
                    callback(ds);
                },
                handleSave: (record, value, data) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "province",
                            val: value["province"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "高中所在城市",
                dataIndex: ["city"],
                key: "city",
                editable: true,
                width: "160px",
                autoComplete: true,
                handleSearch: (value, callback, addition) => {
                    let ds = [];
                    if (!_.isEmpty(addition.province)) {
                        let sList = DataDict.getDictNew(
                            `moblab:seniorhighschool:region:${addition.province}`,
                            value
                        );
                        _.each(sList, (e) => {
                            ds.push({
                                key: e.key,
                                value: e.key,
                                desc: e.value,
                                data: e,
                            });
                        });
                    }
                    callback(ds);
                },
                handleSave: (record, value, data) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "city",
                            val: value["city"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
            },
            {
                title: "关系户",
                dataIndex: "specialType",
                key: "specialType",
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    _.map(
                        DataDict.getMap("moblab:contractinfo:specialType"),
                        (v, k) => {
                            arr.push({ key: k, value: v });
                        }
                    );
                    return arr;
                })(),
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "specialType",
                            val: record["specialType"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "110px",
                className: "nopadding",
            },
            {
                title: "暑期课程院校",
                dataIndex: "summerCourseSchool",
                key: "summerCourseSchool",
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "summerCourseSchool",
                            val: record["summerCourseSchool"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                render: (value, row, index) => {
                    return value ? (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    ) : (
                        hiddenDIV
                    );
                },
                width: "300px",
            },
            {
                title: "备注",
                dataIndex: "remark",
                render: (value, row, index) => {
                    return value ? (
                        <div
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            title={value}
                        >
                            {value}
                        </div>
                    ) : (
                        hiddenDIV
                    );
                },
                editable: true,
                handleSave: (record, value) => {
                    Request.POST("contractinfo", {
                        data: {
                            attr: "remark",
                            val: record["remark"],
                            id: record["_id"],
                        },
                        onSuccess: (data) => {
                            this.init();
                        },
                        onFail: (status) => {
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "300px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                render: (value, row, index) => {
                    return (
                        <OperationButtons
                            studentId={row["studentId"]}
                            studentName={row["name"]}
                            applyStatus={row["applyStatus"]}
                            applyAdvisor={row["applyAdvisorName"]}
                            fetchData={this.fetchData}
                            isShowUpload={true}
                            contractId={row["_id"]}
                            contractName={row["contractName"]}
                            contractStatus={row["contractStatus"]}
                            contractNo={row["contractNo"]}
                        />
                    );
                },
                fixed: "right",
                width: "140px",
                align: "center",
            },
        ];

        return (
            <>
                <Editable
                    size="small"
                    dataSource={this.state.data}
                    columns={columns}
                    bordered={true}
                    scroll={{ x: 3200, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    rowClassName={(record, index) => {
                        return record["syncStu"] ? "normal" : "syncRow";
                    }}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </>
        );
    }
}
export default BaseInfo;
