import React, { useContext } from "react";
// import propTypes from "prop-types";
import styled from "styled-components";
import reportContext from "../reportContext";
import headerImg from "img/report/backCover/backCover-illust-top@2x.png";
import footerImg from "img/report/backCover/backCover-illust-bottom@2x.png";
import visionOverseas from "img/report/logos/vision-overseas-white.png";
import ReportEndBlock from "report/component/ReportEndBlock";

function BackCover() {
    const { isPageNoReady } = useContext(reportContext);

    return (
        <StyledContainer>
            <div className="bkcover-header"></div>
            <div className="bkcover-body">
                <img className="vision-logo" src={visionOverseas} alt="" />
                <div className="title">- Vision Guide行为能力测评 -</div>
                <div className="title2">智能的专业选择推荐系统</div>
            </div>
            <div className="bkcover-footer"></div>

            {isPageNoReady ? <ReportEndBlock /> : null}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    z-index: 9999;
    page-break-after: avoid !important;
    background-color: #ee2a49 !important;
    margin-bottom: 0;
    .bkcover-header {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: url(${headerImg});
        background-size: cover;
        width: 282px;
        height: 376px;
    }

    .bkcover-footer {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-image: url(${footerImg});
        background-size: cover;
        width: 200px;
        height: 260px;
    }

    .bkcover-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .vision-logo {
            margin-top: 300px;
            height: 78px;
            width: 480px;
        }
        .title {
            margin-top: 80px;
            font-size: 32px;
            font-weight: bold;
            color: #fff;
        }
        .title2 {
            margin-top: 24px;
            color: #fff;
            font-size: 24px;
        }
    }
`;

BackCover.propTypes = {};

export default BackCover;
