/**
 * @enum Status
 * 
 * Student account statuses
 */
const Status = {
    UNLINKED: -1,
    PRE_APPLICANT: 0,
    READY_FOR_ASSIGNMENT: 1,
    APPLICANT: 2,
    ARCHIVED: 3
}
module.exports = Status;