import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import accountantImg from "img/report/careers/accountant.jpg";
import careerPicMap from "../careerPicMap2";
import { Rate } from "antd";

function MajorCareer({ job }) {
    return (
        <StyledContainer>
            <div className="item-row avoid-break-inside">
                <div className="item-name">{job.name}</div>
                <div className="item-summary">
                    <div className="item-summary-grid">
                        <img
                            src={careerPicMap[job.name] || accountantImg}
                            alt={""}
                            className="career-img"
                        ></img>
                    </div>
                    <div className="item-summary-grid">
                        <div className="grid-title">学历要求</div>
                        <div className="gird-line"></div>
                        <div className="gird-text">{job.educational}</div>
                    </div>
                    <div className="item-summary-grid">
                        <div className="grid-title">月薪中位数</div>
                        <div className="gird-line"></div>
                        <div className="gird-text">{job.salaryMedian}</div>
                    </div>
                    <div className="item-summary-grid">
                        <div className="grid-title">发展前景</div>
                        <div className="gird-line"></div>
                        <div className="gird-text gird-star">
                            <Rate
                                disabled
                                allowHalf
                                defaultValue={job.jobVista}
                                style={{
                                    fontSize: "24px",
                                    color: "#FEBD55",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ul className="item-content">
                    {_.map((job.content || "").split("\r\n"), function (
                        oneContent,
                        index
                    ) {
                        if (!oneContent) {
                            return null;
                        }
                        return (
                            <li className="content-item" key={index}>
                                {oneContent}
                            </li>
                        );
                    })}
                </ul>
                {job.qualification && job.qualification !== "none" && (
                    <div className="qualification-section">
                        <div className="qualification-title">相关资格证书</div>
                        <div className="qualification-content">
                            {job.qualification.replaceAll("\r\n", "")}
                        </div>
                    </div>
                )}
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "left-right-padding avoid-break-inside job-item",
})`
    margin-bottom: 50px;
    .item-name {
        margin-bottom: 15px;
        color: #1b2224;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
    }

    .item-summary {
        margin-bottom: 20px;
        .career-img {
            height: 64px;
            width: 64px;
            display: inline-block;
            margin-right: 15px;
            border-radius: 16px;
        }
        .item-summary-grid {
            display: inline-block;
            margin-right: 30px;
            height: 64px;
            vertical-align: middle;
            .grid-title {
                margin-top: 5px;
                margin-bottom: 7px;
                font-size: 12px;
                color: #60728e;
            }
            .gird-line {
                width: 80px;
                height: 0px;
                border-bottom: 1px solid #60728e;
                margin-bottom: 9px;
            }
            .gird-text {
                font-size: 16px;
                color: #47525d;
            }
            .gird-star {
                ul {
                    position: relative;
                    top: -8px;
                }
            }
        }
    }

    .item-content {
        font-size: 12px;
        line-height: 20px;
        color: #47525d;
        padding-inline-start: 20px;
    }

    .qualification-section {
        margin-top: 15px;
        padding-left: 20 px;
        .qualification-title {
            font-size: 12px;
            line-height: 20px;
            color: #8a97a5;
        }
        .qualification-content {
            font-size: 12px;
            line-height: 20px;
            color: #47525d;
        }
    }
`;

MajorCareer.propTypes = {
    job: propTypes.object.isRequired,
};

export default MajorCareer;
