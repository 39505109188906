
export default {
    run(){
        var params = {
            search: {},
            route: window.location.pathname
        };
        var search = window.location.search.replace("?","");
        var searchParams = search.split('&');
        for (var s in searchParams){
            var searchParam = searchParams[s].split('=');
            params.search[searchParam[0]] = searchParam.length > 1 ? searchParam[1] : true;
        }
        return params;
    }
}