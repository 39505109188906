import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import * as Route from "./routes";
import { i18n, Router, Request } from "./lib";
import { Deferred, URLParser } from "./lib/utils";
import { AppBar, Logo } from "./components";
import { ChangePassword } from "./components/modals";
import { Dimmer, Loader, Modal, Button } from "semantic-ui-react";
import { message } from "antd";
import datastore from "./data/store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "./scss/App.scss";
import "lib/antdExtend";

var urlParams = URLParser.run();
const ENABLED_PHASE2 =
    String(process.env.REACT_APP_ENABLED_PHASE2).toLocaleLowerCase() === "true";
window.moblab.log("ENABLED_PHASE2", ENABLED_PHASE2);

let ROOT = process.env.REACT_APP_SERVER_ADDRESS || window.location.origin;
axios.defaults.withCredentials = true;

if (
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "localhost"
) {
    // ROOT = "http://localhost:3001";
}
axios.defaults.baseURL = ROOT;
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let errorText = "";
        window.moblab.log("error entry", error);
        if (_.isString(error)) {
            window.moblab.log("Request Error1:", error);
            errorText = error;
        } else if (error.response) {
            window.moblab.log("Request Error2:", error.response);
            if (error.response.status === 401) {
                let request = error.response.request || {};

                // change password
                if (request.responseURL.endsWith("/changepassword")) {
                    errorText = "原密码错误";
                } else if (window.location.pathname === "/login") {
                    errorText = "邮箱或密码错误";
                } else {
                    errorText = "";
                    // window.location.href = "/login";
                }
            } else if (error.response.data instanceof Blob) {
                // a special handler for download report pdf
                errorText = "";
            } else {
                if (_.isString(error.response)) {
                    errorText = error.response;
                } else if (_.isString(error.response.data.error)) {
                    errorText = error.response.data.error;
                } else {
                    errorText = "Network Error:" + error.response.status;
                }
            }
        }

        if (errorText) {
            message.error(errorText);
        }
        const ret = { ...error, text: errorText };
        return Promise.reject(ret);
    }
);

class App extends Component {
    state = {
        totalGames: 9,
        loaded: false,
        authenticated: false,
        showPwdChange: false,
        route: urlParams.route || "login",
        error: false,
        userId: null, // login user id
    };
    componentWillMount() {
        window.moblab.log("BASE URL: ", Router.baseUrl);

        Request.bounce = this.bounce.bind(this);
        Router.link(this);
        i18n.init();
        this.init();
    }
    init() {
        if (this.state.loaded) {
            this.setState({ loaded: false });
        }
        Request.GET("user", {
            onSuccess: (userData) => {
                datastore.init();
                datastore.process("USER", userData.user);

                var deferred = new Deferred(4, () => {
                    window.moblab.log("this.state.route", this.state.route);
                    const userId = userData.user.id;
                    Router.userId = userId;

                    if (ENABLED_PHASE2) {
                        const lastPath = localStorage[`${userId}:lastPath`];

                        if (_.values(Router.CachedPaths).includes(lastPath)) {
                            Router.goTo(lastPath);
                        } else {
                            Router.goTo("/");
                        }
                    } else {
                        Router.goTo("/");
                    }

                    this.setState({
                        loaded: true,
                        authenticated: true,
                        userId: userId,
                    });
                });

                Request.GET("advisor/students", {
                    onSuccess: (studentData) => {
                        datastore.process("STUDENTS", studentData);
                        window.moblab.log(
                            "|| APP || students processed: ",
                            datastore.get("STUDENTS")
                        );
                        deferred.resolve();
                    },
                });
                Request.GET("advisor/filters", {
                    onSuccess: (filterData) => {
                        datastore.process("FILTERS", filterData);
                        deferred.resolve();
                    },
                });
                Request.GET("advisor/globals", {
                    onSuccess: ({ testTypes, majorDetails, questions }) => {
                        datastore.process("EXAM_TYPES", testTypes);
                        datastore.process("MAJOR_DETAILS", majorDetails);
                        datastore.process("QUESTIONS", questions);
                        window.moblab.log("|| APP || globals processed: ", {
                            examTypes: datastore.get("EXAM_TYPES"),
                            majorDetails: datastore.get("MAJOR_DETAILS"),
                            questions: datastore.get("QUESTIONS"),
                        });
                        deferred.resolve();
                    },
                });
                Request.GET("advisor/renderConditions", {
                    onSuccess: (data) => {
                        datastore.set("RENDER_CONDITIONS", data);
                        deferred.resolve();
                    },
                });
            },
        });
    }
    logout() {
        Request.POST("oauth/signout", {
            data: {},
            onSuccess: () => {
                datastore.clear();
                Router.goTo("/login", { loaded: true, authenticated: false });
            },
        });
    }
    bounce(status, error) {
        if (status === 401) {
            Router.goTo("/login", { loaded: true, authenticated: false });
        } else {
            this.setState({ loaded: true, error: error });
        }
    }
    showPwdChange() {
        this.setState({ showPwdChange: true });
    }
    hidePwdChange() {
        this.setState({ showPwdChange: false });
    }

    /** RENDERING */
    dismissError() {
        this.setState({ error: false });
    }
    render() {
        const {
            filters,
            totalGames,
            portal,
            route,
            authenticated,
            loaded,
            showPwdChange,
            error,
        } = this.state;
        return (
            <div className="App">
                {authenticated && (
                    <AppBar
                        showPwdChange={this.showPwdChange.bind(this)}
                        route={route}
                        logout={this.logout.bind(this)}
                    />
                )}
                {loaded && (
                    <Route.Switch path={route}>
                        <Route.Login
                            path="/login"
                            route={Router}
                            init={this.init.bind(this)}
                        />
                        <Route.Students
                            path="/"
                            route={Router}
                            filters={filters}
                            totalGames={totalGames}
                        />
                        {ENABLED_PHASE2 && (
                            <Route.Excel path="/trackingsheet" />
                        )}
                        {ENABLED_PHASE2 && (
                            <Route.Excel path="/trackingsheet/:page" />
                        )}
                        {ENABLED_PHASE2 && (
                            <Route.Dashboard path="/dashboard" />
                        )}
                        {/* <Route.StudentInfo path="/studentinfo/:studentId" /> */}
                        <Route.Profile
                            path="/profile/:userid/:page"
                            key={route}
                            route={Router}
                        />
                        <Route.Admin
                            path="/admin/:page"
                            key={route}
                            route={Router}
                        />
                    </Route.Switch>
                )}
                {!loaded && !portal && (
                    <div className="loading_app">
                        <Dimmer active inverted>
                            <Loader content="Loading" />
                        </Dimmer>
                    </div>
                )}
                {error && (
                    <Modal
                        open={true}
                        onClose={this.dismissError.bind(this)}
                        dimmer="blurring"
                        size="small"
                    >
                        <Modal.Content>
                            <h3>{i18n.t("ERROR.ERROR")}</h3>
                            <p>{i18n.t("ERROR." + error)}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                color="blue"
                                inverted
                                onClick={this.dismissError.bind(this)}
                            >
                                {i18n.t("APP.OK")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}
                {showPwdChange && (
                    <ChangePassword
                        open={true}
                        close={this.hidePwdChange.bind(this)}
                    />
                )}
                <Logo className="printLogo" />
            </div>
        );
    }
}

export default App;
