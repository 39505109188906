import datastore from "../../data/store";
import { Request } from "../";
import AdminUtil from "./AdminUtil";
import i18n from "lib/i18n/i18n";

const commonTierNameMap = {
    "-1": "--",
    0: "顶尖",
    1: "精英",
    2: "领航",
    3: "启航",
};

export default {
    addStudent(name, areaCode, phoneNumber, kind, callback) {
        Request.POST("advisor/student", {
            data: {
                name: name,
                countryCode: areaCode,
                phoneNumber: phoneNumber,
                kind,
            },
            onSuccess: ({ user }) => {
                datastore.newStudent(user, phoneNumber);
                if (callback) {
                    callback(user);
                }
            },
        });
    },

    getTierName(tierIndex, kind) {
        if (kind === "GK") {
            return "N/A";
        }

        const isVisionAccount = AdminUtil.isVisionAccount();
        return isVisionAccount
            ? i18n.t("STUDENTS.TIER_" + tierIndex)
            : commonTierNameMap[tierIndex];
    },

    // key = MAJOR.SUBTITLE | PROFILE.SCHOOL_FIT
    updateUStoForeignCountry(text, key) {
        const isVisionAccount = AdminUtil.isVisionAccount();

        if (isVisionAccount) {
            return text;
        }

        if (key === "MAJOR.SUBTITLE") {
            return String(text)
                .replace(/MobLab-前途出国移动APP端/g, "EduGuide APP端")
                .replace(/美国/g, "国内外");
        }

        if (key === "PROFILE.SCHOOL_FIT") {
            return String(text).replace("学校推荐", "美国学校推荐");
        }

        return String(text).replace(/美国/g, "国外");
    },
};
