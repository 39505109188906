import React, { useEffect, useRef } from "react";
import _ from "lodash";
import propTypes from "prop-types";
import styled from "styled-components";
import ScoreSlide from "report/component/ScoreSlide";

function TraitDetailUltimate({
    traits,
    sectionTitle,
    sectionDescription,
    traitsThreshold,
    // setTraitsPageNumbers,
}) {
    const elementRef = useRef();

    // refer to:
    // https://stackoverflow.com/questions/20050939/print-page-numbers-on-pages-when-printing-html
    useEffect(function () {
        //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi, ;
        // 1043 = 1123 - header(40) - footer(40)
        const a4PagesNumber = Math.ceil(elementRef.current.scrollHeight / 1043);
        // setTraitsPageNumbers(a4PagesNumber);
        const howManyPageBeforeTraits = 6;
        const prevDistance = howManyPageBeforeTraits * 1122.5 + "px";
        for (let i = 1; i <= a4PagesNumber; i++) {
            let companyNameDiv = document.createElement("div");
            companyNameDiv.style.color = "#B0B5BA";
            companyNameDiv.style.fontSize = "11px";
            companyNameDiv.appendChild(
                document.createTextNode("©2021 Powered by MobLab Inc.")
            );

            let pagerNoDiv = document.createElement("div");
            pagerNoDiv.style.fontSize = "11px";
            pagerNoDiv.style.color = " #47525D";
            let pageNumberText = document.createTextNode(
                i + howManyPageBeforeTraits
            );
            pagerNoDiv.appendChild(pageNumberText);

            let pageNumberContainer = document.createElement("div");
            pageNumberContainer.style.position = "absolute";
            pageNumberContainer.style.top = `calc(${i} * 1122.5px - 40px + ${prevDistance})`;
            pageNumberContainer.style.height = "16px";
            pageNumberContainer.style.width = "210mm";
            pageNumberContainer.style.zIndex = 3000;
            pageNumberContainer.style.left = 0;
            pageNumberContainer.style.backgroundColor = "#fff";
            pageNumberContainer.style.display = "flex";
            pageNumberContainer.style.alignItems = "center";
            pageNumberContainer.style.justifyContent = "space-between";
            pageNumberContainer.style.paddingLeft = "20px";
            pageNumberContainer.style.paddingRight = "20px";
            pageNumberContainer.appendChild(companyNameDiv);
            pageNumberContainer.appendChild(pagerNoDiv);
            document.body.appendChild(pageNumberContainer);
        }
    }, []);

    return (
        <StyledContainer ref={elementRef}>
            <div className="section-body">
                <div className="report-description">
                    <div className="text-title">{sectionTitle}</div>
                    <div className="text-desc">{sectionDescription}</div>
                </div>
            </div>

            <div className="traits-container">
                {_.map(traits, function (trait, index) {
                    if (!trait) {
                        return null;
                    }

                    return (
                        <div key={index} className="trait-item">
                            {index !== 0 && (
                                <div
                                    className="space-placeholder"
                                    style={{ height: "50px" }}
                                >
                                    placeholder
                                </div>
                            )}
                            <div className="trait-name avoid-break-inside">
                                <div className="name-color"></div>
                                <div className="name-title">{trait.type}</div>
                                {/* <div className="name-catogary">
                                    {trait.category}
                                </div> */}
                            </div>
                            <div className="section-pure-body">
                                <div className="avoid-break-inside">
                                    <div className="whatis-title">定义</div>
                                    <div className="whatis-description">
                                        {trait.desc}
                                    </div>
                                </div>
                                <ScoreSlide
                                    trait={trait}
                                    isRedDot
                                    traitsThreshold={traitsThreshold}
                                />
                                <div className="avoid-break-inside suggestion-row">
                                    <div className="suggestion-title">
                                        发展建议
                                    </div>
                                    <div className="suggestion-text">
                                        {trait.suggestion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "ultimate-size",
})`
    /* .report-description {
        margin-bottom: 32px;
    } */

    .section-body {
        align-items: flex-start !important;
    }

    .space-placeholder {
        width: 100%;
        color: #fff;
        font-size: 0;
    }
    .trait-name {
        background-color: #f7f9fa;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 38px;
        position: relative;
        align-items: center;
        .name-color {
            width: 20px;
            height: 38px;
            background-color: #00b6ff;
        }
        .name-title {
            color: #1b2224;
            font-weight: bold;
            font-size: 18px;
            margin-left: 15px;
        }
        .name-catogary {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            right: 0;
            color: #47525d;
            height: 100%;
            line-height: 38px;
            padding: 0 15px;
        }
    }
    .whatis-title {
        font-weight: bold;
        font-size: 14px;
        color: #4386cb;
        white-space: nowrap;
    }
    .whatis-description {
        font-size: 14px;
        color: #47525d;
    }
    .score-slide {
        width: 100%;
        margin: 20px 0;
    }
    .suggestion-row {
        .suggestion-title {
            color: #1b2224;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        .suggestion-text {
            color: #47525d;
            font-size: 12px;
        }
    }
`;

TraitDetailUltimate.propTypes = {
    traits: propTypes.array.isRequired,
    sectionTitle: propTypes.string.isRequired,
    sectionDescription: propTypes.string.isRequired,
    traitsThreshold: propTypes.object.isRequired,
};

export default TraitDetailUltimate;
