import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";

function MajorDetail({ title, icon }) {
    return (
        <StyledContainer>
            <span className="tip-icon">
                <img alt="" src={icon} />
            </span>
            <span className="tip-text">{title}</span>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "major-sub-title left-right-padding",
})`
    color: #4386cb;
    height: 20px;
    margin-bottom: 30px;
    .tip-icon {
        width: 32px;
        height: 30px;
        margin-right: 10px;
        vertical-align: middle;
        img {
            width: 32px;
            height: 30px;
        }
    }
    .tip-text {
        font-size: 14px;
        font-weight: bold;
        vertical-align: middle;
    }
`;

MajorDetail.propTypes = {
    title: propTypes.string.isRequired,
    icon: propTypes.string.isRequired,
};

export default MajorDetail;
