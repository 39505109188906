export default {
    advisors: [
        { key: "name", type: "text", save: true },
        { key: "email", type: "static" },
        { key: "students", type: "static" },
        { key: "creation_date", type: "static" },
        { key: "campus", type: "select", save: true },
    ],
    students: [
        { key: "name", type: "text", save: true },
        { key: "advisor", type: "select", save: true },
        { key: "kind", type: "select", save: true },
        { key: "creation_date", type: "static" },
        { key: "campus", type: "static", save: true },
        { key: "campusName", type: "static" },
    ],
    newAdvisor: [
        { key: "name", type: "text", save: true },
        { key: "email", type: "text", save: true },
    ],
};
