export default {
    archived: -1,
    assigned_tier: -1,
    dateOfBirth: "",
    gender: "",
    id: -1,
    location: "",
    name: "",
    notes: [],
    overall: 0,
    phoneNumber: -1,
    progress: 0,
    raw_data: {},
    report: 0,
    role: "student",
    scores: [],
    sessions: [],
    status: -1,
    tier: -1,
    total: 0,
    traits: []
}