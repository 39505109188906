import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
// import { RippleButton } from '.';
import i18n from '../lib/i18n/i18n';
import "../scss/FilterSelect.scss";

class FilterSelect extends Component {
    render() {
        var options = [];
        var buttons = [];
        this.props.filters.map(({ label, value, active }) => {
            var activeClass = active ? " active" : "";

            if (value === this.props.cutoff){
                options.push((
                    <div key="divider" className="divider"></div>
                ));
            }
            options.push((
                <div 
                    key={value + "o"}
                    className={"option" + activeClass} 
                    onClick={() => { this.props.add(value) }}
                >
                    {label}
                </div>
            ));
            buttons.push((
                <div 
                    key={value + "b"} 
                    className={"filter" + activeClass}
                    onClick={() => { this.props.remove(value) }
                }>
                    <div className="label">{label}</div>
                    <div className="icon close"></div>
                </div>
            ))
            return true;
        })
        return (
            <div className="FilterSelect">
                <Form.Select
                    options={options} 
                    text={i18n.t('APP.FILTER')}
                />
                {/* <RippleButton
                    flat={true}
                    icon="%"
                    text={i18n.t('APP.MANAGE')}
                /> */}
                {buttons}
            </div>
        )
    }
}

export default FilterSelect;
