import React from 'react'
import { Input } from '.'
import { i18n } from '../lib'
import { Type, Strategy } from '../data/enum/Filter'
import { Dropdown } from 'semantic-ui-react'
import { Range } from 'rc-slider'
import { debounce } from 'lodash'
import 'rc-slider/assets/index.css'

const throttleDur = 200;
export default class FilterField extends React.Component {
    constructor(){
        super();
        this.scheduleFilterUpdate = debounce(this.setFilter, throttleDur, {
            leading: true,
            trailing: true,
            maxWait: throttleDur
        });
    }
    /**
     * Format the range label based on filter parameters
     * 
     * @param {Object} filter        Filter parameters
     * @param {Object} activeFilters Active filter with 
     */
    formatRangeLabel(){
        const { activeFilters, params } = this.props;
        const range = params.max - params.min;
        const [ min, max ] = activeFilters.hasOwnProperty(params.key) ? 
            activeFilters[params.key].value : [0, 100];
        const factor = Math.max(params.min, params.max) > 1 ? 100 : 1;

        return scale(min) + " - " + scale(max);
        
        function scale(val){
            const scaledVal = Math.round(params.min + val / factor * range);
            return params.units[0] + scaledVal + params.units[1];
        }
    }
    /**
     * Check a filter's value to see if it is active
     * 
     * @param {Object} value    current filter value
     */
    isFilterInactive(value){
        const { strategy } = this.props.params;

        return value === "" || 
            (strategy === Strategy.MULTIPLE && value.length === 0) ||
            (strategy === Strategy.RANGE && value[0] === 0 && value[1] === 100);
    }
    /**
     * Set the active filter state of the current filter. 
     * 
     * @param {String | Number} value current field value 
     */
    setFilter(value){
        const { activeFilters, update, params } = this.props;
        const filters = Object.assign({}, activeFilters);
        const { key } = params;
        
        if (this.isFilterInactive(value)){
            if (filters.hasOwnProperty(key)){
                delete filters[key];
                update(filters);
            }
        } else {
            filters[key] = {
                filter: params,
                value: value
            };
            update(filters);
        }
    }
    render(){
        const { type, label, options, strategy } = this.props.params;

        switch (type){
            case Type.SELECT: 
                return (
                    <div className="filter" key={label}>
                        <label>{label}</label>
                        <Dropdown
                            placeholder={i18n.t(
                                'SCHOOLS.SELECT' + (
                                    strategy === Strategy.MULTIPLE ? '_MULTIPLE' : ''
                                )
                            )}
                            multiple={strategy === Strategy.MULTIPLE}
                            options={options}
                            selection clearable
                            onChange={ (e, { value }) => 
                                this.setFilter(value)
                            }
                        />
                    </div>
                )
            case Type.RANGE:
                return (
                    <div key={label} className="filter range" >
                        <label>{label}</label>
                        <Range 
                            defaultValue={[0,100]}
                            allowCross={false}
                            onChange={val => 
                                this.scheduleFilterUpdate(val)
                            }
                        />
                        <div className="currentRange">
                            {this.formatRangeLabel()}
                        </div>
                    </div>
                )
            default:
                return (
                    <Input key={label}
                        type={type}
                        label={label}
                        placeholder={i18n.t('SCHOOLS.TEXT_PLACEHOLDER')}
                        open
                        onChange={e => {
                            e.persist();
                            this.scheduleFilterUpdate(e.target.value);
                        }}
                    />
                )
        }
    }
}