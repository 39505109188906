import React, { Component } from "react";
import { Pagination, message } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import moment from "moment";
import MultiRow from "./MultiRow";
import DataDict from "./DataDict";
import _ from "lodash";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";
import Shade from "components/Shade";

const DefaultCount = 7;
class HighSchool extends Component {
    state = {
        data: [],
        renderdata: {},
        currentPage: 1,
        total: 0,
        error: false,
        errorCode: "",
        loading: true,
        pageSize: 50,
        changeStatus: {
            data: {},
            changeStatusvisible: false,
        },
        local: {
            emptyText: " ",
        },
        sorter: {},
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/highschoolinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    Shade.hide();
                    if (status === 401) return true;
                    message.error("更新失败");
                },
            }
        );
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    renderDataSource = (datasource) => {
        let ds = datasource.data;
        let datasourcetmp = [];
        let renderdata = {};
        for (let i = 0; i < ds.length; i++) {
            let data = ds[i];
            let recommendInfos = ds[i]["recommendInfos"];
            renderdata[data["contractId"]] = {
                count: DefaultCount,
                used: false,
            };
            for (let j = 0; j < DefaultCount; j++) {
                let recommendinfo = recommendInfos[j] ? recommendInfos[j] : {};
                let highSchool = "";
                let clazz = "";
                let value = "";
                let classValue = "";
                let attr = "";
                let classAttr = "";
                let isDate = {};
                let autoComplete = {};
                let isDropdown = {};
                let classValidation = null;
                if (j === 0) {
                    highSchool = "中文名称";
                    clazz = "入学日期";
                    value = data["schoolNameCh"];
                    attr = "schoolId";
                    classValue = data["enrollmentDate"]
                        ? moment(data["enrollmentDate"])
                        : null;
                    classAttr = "enrollmentDate";
                    isDate = { classValue: true };
                    autoComplete = {
                        schoolValue: true,
                        key: "erp:school:ch",
                        escape: true,
                    };
                } else if (j === 1) {
                    highSchool = "英文名称";
                    clazz = "毕业日期";
                    // value = DataDict.getMap("erp:school:en")[data["schoolId"]];
                    value = data["schoolNameEn"];
                    attr = "schoolId";
                    classValue = data["graduationDate"]
                        ? moment(data["graduationDate"])
                        : null;
                    classAttr = "graduationDate";
                    isDate = { classValue: true };
                    autoComplete = {
                        schoolValue: true,
                        key: "erp:school:en",
                        escape: true,
                    };
                    isDropdown = {
                        schoolValue: false,
                    };
                } else if (j === 2) {
                    highSchool = "英文地址";
                    value = data["addrEN"];
                    attr = "addrEN";

                    clazz = "Unweighted GPA";
                    classValue = data["GPA"];
                    classAttr = "GPA";
                    classValidation = (record) => {
                        if (_.isNaN(Number(record["classValue"]))) {
                            message.error("请输入数字");
                            return false;
                        }

                        if (Number(record["classValue"]) > 5) {
                            message.error("数字不能大于5");
                            return false;
                        }

                        if (Number(record["classValue"]) < 0) {
                            message.error("数字不能小于0");
                            return false;
                        }
                        return true;
                    };

                    isDate = { classValue: false };
                    autoComplete = {
                        schoolValue: false,
                    };
                    isDropdown = {
                        schoolValue: false,
                    };
                } else if (j === 3) {
                    highSchool = "邮政编码";
                    value = data["postCode"];
                    attr = "postCode";

                    clazz = "Weighted GPA";
                    classValue = data["weightedGPA"];
                    classAttr = "weightedGPA";
                    classValidation = (record) => {
                        if (_.isNaN(Number(record["classValue"]))) {
                            message.error("请输入数字");
                            return false;
                        }
                        if (Number(record["classValue"]) > 5) {
                            message.error("数字不能大于5");
                            return false;
                        }
                        if (Number(record["classValue"]) < 0) {
                            message.error("数字不能小于0");
                            return false;
                        }
                        return true;
                    };

                    isDate = { classValue: false };
                    autoComplete = {
                        schoolValue: false,
                    };
                    isDropdown = {
                        schoolValue: false,
                    };
                } else if (j === 4) {
                    highSchool = "提交路径";
                    value = DataDict.getMap(
                        "moblab:seniorhighschoolinfo:commitPath"
                    )[data["commitPath"]];
                    attr = "commitPath";

                    clazz = "班级人数";
                    classValue = data["stdNum"];
                    classAttr = "stdNum";
                    classValidation = (record) => {
                        if (record["classValue"] !== undefined) {
                            if (
                                !_.isInteger(Number(record["classValue"])) ||
                                Number(record["classValue"]) < 0
                            ) {
                                message.error("请输入大于等于班级排名的正整数");
                                return false;
                            }
                            if (
                                data["classRanking"] &&
                                data["classRanking"] >
                                    Number(record["classValue"])
                            ) {
                                message.error("请输入大于等于班级排名的正整数");
                                return false;
                            }
                        }
                        return true;
                    };

                    isDate = { classValue: false };
                    autoComplete = {
                        schoolValue: false,
                    };
                    isDropdown = {
                        schoolValue: {
                            enable: true,
                            menus: (() => {
                                let arr = [];
                                _.map(
                                    DataDict.getMap(
                                        "moblab:seniorhighschoolinfo:commitPath"
                                    ),
                                    (v, k) => {
                                        arr.push({ key: k, value: v });
                                    }
                                );
                                return arr;
                            })(),
                        },
                    };
                } else if (j === 5) {
                    highSchool = "高中性质";
                    value = DataDict.getMap("moblab:examinfo:highschoolType")[
                        data["highschoolType"]
                    ];
                    attr = "highschoolType";

                    clazz = "班级排名";
                    classValue = data["classRanking"];
                    classAttr = "classRanking";
                    classValidation = (record) => {
                        if (record["classValue"] !== undefined) {
                            if (
                                !_.isInteger(Number(record["classValue"])) ||
                                Number(record["classValue"]) < 0
                            ) {
                                message.error("请输入小于等于班级人数的正整数");
                                return false;
                            }
                            if (
                                data["stdNum"] &&
                                Number(record["classValue"]) > data["stdNum"]
                            ) {
                                message.error("请输入小于等于班级人数的正整数");
                                return false;
                            }
                        }
                        return true;
                    };

                    isDate = { classValue: false };
                    autoComplete = {
                        schoolValue: false,
                    };
                    isDropdown = {
                        schoolValue: {
                            enable: true,
                            menus: (() => {
                                let arr = [];
                                _.map(
                                    DataDict.getMap(
                                        "moblab:examinfo:highschoolType"
                                    ),
                                    (v, k) => {
                                        arr.push({ key: k, value: v });
                                    }
                                );
                                return arr;
                            })(),
                        },
                    };
                } else if (j === 6) {
                    highSchool = "";
                    value = "";

                    clazz = "国际课程类型";
                    classValue = DataDict.getMap(
                        "moblab:seniorhighschoolinfo:internationalCourse"
                    )[data["internationalCourse"]];
                    classAttr = "internationalCourse";

                    isDate = { classValue: false };
                    autoComplete = {
                        schoolValue: false,
                    };
                    isDropdown = {
                        schoolValue: false,
                        classValue: {
                            enable: true,
                            menus: (() => {
                                let arr = [];
                                _.map(
                                    DataDict.getMap(
                                        "moblab:seniorhighschoolinfo:internationalCourse"
                                    ),
                                    (v, k) => {
                                        arr.push({ key: k, value: v });
                                    }
                                );
                                return arr;
                            })(),
                        },
                    };
                } else {
                    highSchool = "";
                    clazz = "";
                    value = "";
                    classValue = "";
                    autoComplete = {
                        schoolValue: false,
                    };
                }
                datasourcetmp.push({
                    isLastItemOfGroup: j === DefaultCount - 1,
                    ...data,
                    key: i + 1,
                    number: i + 1,
                    id: data["_id"],
                    studentId: data["studentId"],
                    name: data["studentName"],
                    highSchool: highSchool,
                    schoolValue: value,
                    class: clazz,
                    classValue: classValue,
                    isDate: isDate,
                    autoComplete: autoComplete,
                    isDropdown: isDropdown,
                    attr: attr,
                    classAttr: classAttr,
                    classValidation: classValidation,
                    recommendationProgress: recommendinfo["progress"]
                        ? DataDict.getMap("moblab:recommendinfo:progress")[
                              recommendinfo["progress"]
                          ]
                        : null,
                    recommendationStatus: recommendinfo["status"]
                        ? DataDict.getMap("moblab:recommendinfo:status")[
                              recommendinfo["status"]
                          ]
                        : null,
                    teacherName: recommendinfo["teachername"],
                    sex: recommendinfo["gender"],
                    title: recommendinfo["title"],
                    subject: recommendinfo["subject"],
                    grade: recommendinfo["grade"],
                    phone: recommendinfo["phone"],
                    email: recommendinfo["email"],
                    password: recommendinfo["pwd"],
                    keyworld: recommendinfo["threeKeyword"],
                    common: recommendinfo["commonAccount"],
                    commonPassword: recommendinfo["commonPwd"],
                    // remark: recommendinfo["remark"],
                    recommendinfoId: recommendinfo["id"],
                    gender: recommendinfo["gender"]
                        ? DataDict.getMap("moblab:recommendinfo:gender")[
                              recommendinfo["gender"]
                          ]
                        : null,
                    subRowIndex: j,
                });
            }
        }

        _.each(datasourcetmp, (v) => {
            let _render = renderdata[v.contractId];
            if (_render && !_render.used) {
                v.rowSpan = _render.count;
                _render.used = true;
            } else {
                v.rowSpan = 0;
            }
        });

        this.setState({
            data: datasourcetmp,
            currentPaged: datasource.currentPage,
            total: datasource.total,
            renderdata: renderdata,
            loading: false,
        });
        Shade.hide();
    };
    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;

        const columns = [
            {
                className: "column-apply-status cross-row-td",
                title: "申请状态",
                dataIndex: "applyStatus",
                align: "center",
                render: (statusCode, row, index) => {
                    let tag = (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["name"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );

                    const obj = {
                        children: tag,
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                width: "125px",
                sorter: true,
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <StudentLink
                                studentName={value}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "所在高中",
                dataIndex: "highSchool",
                colSpan: 2,
                render: (value, row, index) => {
                    const obj = {
                        children: <span>{value}</span>,
                        props: {
                            rowSpan: 1,
                            className: "tag-cell",
                        },
                    };
                    return obj;
                },
                width: 100,
            },
            {
                title: "所在高中值",
                dataIndex: "schoolValue",
                colSpan: 0,
                render: (value, row, index) => {
                    const obj = {
                        children: value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : (
                            hiddenDIV
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: function (record, dataIndex) {
                    if (
                        record.subRowIndex === 6 &&
                        dataIndex === "schoolValue"
                    ) {
                        return false;
                    }
                    return true;
                },
                handleSearch: (value, callback, autoCompleteAddition) => {
                    if (!_.isEmpty(value)) {
                        Request.GET(
                            `datadict/${encodeURIComponent(
                                autoCompleteAddition.key
                            )}/${encodeURIComponent(value)}`,
                            {
                                onSuccess: (data) => {
                                    let ds = [];
                                    _.each(data.data, (e) => {
                                        ds.push({
                                            key: e.code,
                                            value: e.value,
                                            desc: e.value,
                                            data: e,
                                        });
                                    });
                                    callback(ds);
                                },
                                onFail: (status) => {
                                    if (status === 401) return true;
                                    message.error("查询错误");
                                },
                            }
                        );
                    }
                },
                handleSave: (record, value, data) => {
                    let upparam = {};
                    upparam[record["attr"]] =
                        data && data["code"]
                            ? data["code"]
                            : record["schoolValue"];
                    let syncStuParam = null;
                    if (["schoolId", "addrEN"].indexOf(record["attr"]) > -1) {
                        syncStuParam = {
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["contractId"],
                            },
                        };
                    }
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("highschoolinfo", {
                        data: {
                            update: upparam,
                            id: record["id"],
                            contractId: record["contractId"],
                            studentName: record["name"],
                            ...syncStuParam,
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "260px",
            },
            {
                title: "所在班级",
                dataIndex: "class",
                colSpan: 2,
                render: (value, row, index) => {
                    const obj = {
                        children: <span>{value}</span>,
                        props: {
                            rowSpan: 1,
                            className: "tag-cell",
                        },
                    };
                    return obj;
                },
                width: "130px",
            },
            {
                title: "所在班级值",
                dataIndex: "classValue",
                colSpan: 0,
                render: (value, row, index) => {
                    if (row["isDate"]["classValue"]) {
                        value = value ? value.format("YYYY-MM-DD") : hiddenDIV;
                    } else {
                        value = value ? (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ) : (
                            hiddenDIV
                        );
                    }
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (record.classValidation) {
                        if (!record.classValidation(record)) {
                            return false;
                        }
                    }
                    let upparam = {};
                    let syncStuParam = null;
                    if (
                        [
                            "GPA",
                            "weightedGPA",
                            "enrollmentDate",
                            "graduationDate",
                            "classRanking",
                        ].indexOf(record["classAttr"]) > -1
                    ) {
                        syncStuParam = {
                            syncStuParam: {
                                syncStu: false,
                                contractId: record["contractId"],
                            },
                        };
                    }
                    if (
                        record["classAttr"] === "stdNum" &&
                        !(record["classValue"] % 1 === 0)
                    ) {
                        return;
                    } else if (
                        record["classAttr"] === "classRanking" &&
                        !(record["classValue"] % 1 === 0)
                    ) {
                        return;
                    }

                    if (
                        record["classAttr"] === "GPA" ||
                        record["classAttr"] === "weightedGPA"
                    ) {
                        record["classValue"] = Number(
                            record["classValue"]
                        ).toFixed(2);
                    }
                    upparam[record["classAttr"]] = record["classValue"];
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("highschoolinfo", {
                        data: {
                            update: upparam,
                            id: record["id"],
                            contractId: record["contractId"],
                            studentName: record["name"],
                            ...syncStuParam,
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "130px",
                className: "nopadding",
            },
            {
                title: "备注",
                dataIndex: "remark",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: <MultiRow value={value} row={10} />,
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    obj.props.className = "textarea";
                    return obj;
                },
                editable: true,
                textarea: true,
                row: 8,
                handleSave: (record, value) => {
                    if (!record["id"]) {
                        Shade.show();
                    }
                    Request.POST("highschoolinfo", {
                        data: {
                            update: { remark: record["remark"] },
                            id: record["id"],
                            contractId: record["contractId"],
                            studentName: record["name"],
                        },
                        onSuccess: (data) => {
                            if (data.error === "duplicate key for insert db") {
                                message.error("已有记录存在");
                            }
                            this.init();
                        },
                        onFail: (status) => {
                            Shade.hide();
                            if (status === 401) return true;
                            message.error("更新失败");
                        },
                    });
                },
                width: "300px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <OperationButtons
                                studentId={row["studentId"]}
                                studentName={row["name"]}
                                applyStatus={row["applyStatus"]}
                                applyAdvisor={row["applyAdvisorName"]}
                                fetchData={this.fetchData}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                isShowUpload={true}
                                contractStatus={row["contractStatus"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                fixed: "right",
                width: "120px",
                align: "center",
            },
        ];

        return (
            <Shade>
                <Editable
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: "1500px", y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    rowClassName={(record, index) => {
                        let syncFlag = record["syncStu"] ? "normal" : "syncRow";
                        const classNames = [syncFlag];
                        if (record.isLastItemOfGroup) {
                            classNames.push("last-item-of-group");
                        }

                        return classNames.join(" ");
                    }}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </Shade>
        );
    }
}
export default HighSchool;
