import React, { Component } from 'react'
// import i18n from "../lib/i18n/i18n"
import logo from "../img/logo.svg"
import logo_white from "../img/logo_white.svg"
// import "../scss/Logo.scss"

class Logo extends Component {
    render() {
        const { className } = this.props;
        const logoClass = "Logo" + (className ? " " + className : "");
        if (this.props.simple){
            return (<img className={logoClass} src={logo} alt="logo" />)
        } else {
            return (<img className={logoClass} src={logo_white} alt="logo_white"/>)
        }
        // // var logoClass = "Component Logo" + (this.props.simple ? " simple" : "");
        // return (
        //     <div className={logoClass}>
        //         {/* <div className="icon">P</div> */}
        //         { this.props.simple && 
        //             <img src={logo} />
        //             // <div className="name big">{i18n.t("APP.MOBLAB")}</div>
        //         }
        //         { !this.props.simple &&
        //             // <div className="labels">
        //             //     <div className="name small">{i18n.t("APP.MOBLAB")}</div>
        //             //     <div className="subtitle">{i18n.t("APP.ADVISOR_CONSOLE")}</div>
        //             // </div>
        //             <img src={logo_white} />
        //         }
        //     </div>
        // )
    }
}

export default Logo;
