import React from "react";
import Card from "../Card";
import { MajorInfo } from ".";
import { RippleButton } from "..";
import { i18n } from "../../lib";
import styled from "styled-components";
import { StudentUtil } from "lib/utils";
class MajorTable extends React.Component {
    state = {
        show_all: false,
    };
    constructor(props) {
        super(props);
        this.toggleView = this.toggleView.bind(this);
        this.toggleMajor = this.toggleMajor.bind(this);
        this.selectedMajor = null;
    }
    toggleView() {
        this.setState({ show_all: !this.state.show_all });
    }
    toggleMajor(majorComponent, open) {
        // console.log("TOGGLE: ", majorComponent, open);
        if (open) {
            this.selectedMajor = null;
        } else {
            if (this.selectedMajor) {
                this.selectedMajor.toggle();
            }
            this.selectedMajor = majorComponent;
        }
    }
    render() {
        const { data } = this.props;
        const { show_all } = this.state;
        // const tableData = show_all ? data : data.slice(0, 5);
        const tableData = data;

        return (
            <Card
                {...this.props}
                title={i18n.t("MAJOR.TITLE")}
                subtitle={StudentUtil.updateUStoForeignCountry(
                    i18n.t("MAJOR.SUBTITLE"),
                    "MAJOR.SUBTITLE"
                )}
                type={"major"}
            >
                <StyledContainer
                    isPrintView={this.props.printView}
                    isShowAll={show_all}
                >
                    {tableData.map(({ key, value }, index) => (
                        <MajorInfo
                            key={key}
                            major={key}
                            fit={value}
                            className={index >= 5 ? "major-more" : "major-top5"}
                            onToggle={this.toggleMajor}
                        />
                    ))}
                    {this.props.printView ? null : (
                        <div className="actions">
                            <RippleButton
                                className="show_all"
                                flat
                                text={i18n.t(
                                    "MAJOR.SHOW_" + (show_all ? "LESS" : "ALL")
                                )}
                                onClick={this.toggleView}
                            />
                        </div>
                    )}
                </StyledContainer>
            </Card>
        );
    }
}

const StyledContainer = styled.div.attrs({
    className: "majors",
})`
    .major-more {
        display: ${({ isPrintView, isShowAll }) => {
            if (isPrintView) {
                return "block";
            }

            return isShowAll ? "block" : "none";
        }};
    }

    @media print {
        .show_all {
            display: none !important;
        }
        .major-more {
            display: block !important;
        }
    }
`;

export default MajorTable;
