import _ from "lodash";
import React from "react";
import Router from "lib/Router";

const options = {
    method: "get",
    headers: {
        "Content-Type": "application/json",
    },
    credentials: "include",
};

function fetchJSON(path, filter) {
    let url = buildUrl(Router.baseUrl + path, filter);
    return fetch(url, options).then(function (response) {
        if (response.status === 401) {
            Router.goTo("/login", { loaded: true, authenticated: false });
            throw new Error("access not allowed");
        }

        return response.json();
    });
}

function parseData(originData, totalText) {
    let total = _.reduce(
        originData,
        function (result, value) {
            return result + value.count;
        },
        0
    );

    let parsedData = _.map(originData, function (item) {
        let ratio = "";

        if (total !== 0) {
            ratio = (Number(item.count / total) * 100).toFixed(1) + "%";
        }

        return { ...item, key: item.type, ratio };
    });

    if (totalText) {
        parsedData.unshift({
            type: totalText,
            key: totalText,
            count: total,
            isTotal: true,
        });
    }

    return parsedData;
}

function getColumns(firstColumnName) {
    return [
        {
            title: firstColumnName,
            dataIndex: "type",
            key: "type",
        },
        {
            title: "数量",
            dataIndex: "count",
            key: "count",
            align: "center",
            render: (value, row, index) => {
                let fontColor = row.fontColor || "default";
                const obj = {
                    children: (
                        <div className={`count-text font-${fontColor}`}>
                            {value}
                        </div>
                    ),
                    props: {},
                };

                if (row.isTotal) {
                    obj.props.colSpan = 2;
                }

                return obj;
            },
        },
        {
            title: "比例",
            dataIndex: "ratio",
            key: "ratio",
            align: "center",
            className: "col-ratio",
            render: (value, row, index) => {
                let ratioColor = "rgb(37, 193, 56)";

                let Element =
                    row.count > 0 ? (
                        <>
                            <div
                                className="ratio-bar"
                                style={{
                                    width: row.ratio,
                                    maxWidth: "calc(100% - 6px)",
                                    backgroundColor: ratioColor,
                                }}
                            ></div>
                            <div className="ratio-text">{value}</div>
                        </>
                    ) : (
                        <div className="ratio-text">{value}</div>
                    );

                const obj = {
                    children: Element,
                    props: {},
                };

                if (row.isTotal) {
                    obj.props.colSpan = 0;
                }

                return obj;
            },
        },
    ];
}

function buildUrl(base = "", params) {
    let serializedParams = paramsSerializer(params);
    if (serializedParams === "") {
        return base;
    }

    const separator = base.includes("?") ? "&" : "?";

    return base + separator + paramsSerializer(params);
}

export function paramsSerializer(params) {
    const parts = [];

    _.each(params, function serialize(val, key) {
        if (_.isNil(val) || val === "") {
            return;
        }

        parts.push(`${key}=${encodeURIComponent(val)}`);
    });

    return parts.join("&");
}

export default {
    parseData,
    getColumns,
    fetchJSON,
    buildUrl,
};
