import React, { Component } from "react";
import axios from "axios";
import { i18n, Request } from "../lib";
import datastore from "../data/store";
import { DataConverter, ExamUtil, NoteUtil, SchoolUtil } from "../lib/utils";
import {
    DotMenu,
    NoteDrawer,
    RippleButton,
    Sidebar,
    PrintAllView,
} from "../components";
import { Tier, Status } from "../data/enum";
import { PageModel } from "../data/model";
import * as Page from "./pages";
import * as Modal from "../components/modals";
import { Loader } from "semantic-ui-react";
import "../scss/Profile.scss";
import _ from "lodash";
import { AdminUtil } from "lib/utils";
import { message, Spin } from "antd";
import utils from "common/utils";
// import studentMock from "data/test/student";

let prevPage = "";
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            id: "",
            page: "",
            link: "",
            data: {},
            pageMap: {},
            notes: null,
            exams: null,
            school_fit: null,
            status: -1,
            assigning: false,
            changePassword: false,
            feedback: false,
            incomplete: true,
            showNotes: false,
            loaded: false,
            isPrintMode: false,
            downloadingReport: false,
        };
        this.changePage = this.changePage.bind(this);
        this.isPageEnabled = this.isPageEnabled.bind(this);
        this.isPageHidden = this.isPageHidden.bind(this);
        this.setTier = this.setTier.bind(this);
        this.resetTier = this.resetTier.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.closeLink = this.closeLink.bind(this);
        this.beginAssignment = this.beginAssignment.bind(this);
        this.endAssignment = this.endAssignment.bind(this);
        this.archive = this.archive.bind(this);
        this.unarchive = this.unarchive.bind(this);
        this.showNotes = this.showNotes.bind(this);
        this.toggleNotes = this.toggleNotes.bind(this);
        this.hideNotes = this.hideNotes.bind(this);
        this.back = this.back.bind(this);
        this.print = this.print.bind(this);
        this.printAll = this.printAll.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.printAndRevert = this.printAndRevert.bind(this);
    }
    componentDidMount() {
        document.title = i18n.t("APP.ADVISOR_CONSOLE");

        const studentId = this.props.route.params.userid;
        const currentPage = this.props.route.params.page.toUpperCase();
        const cachedStudent = datastore.get("STUDENTS").find((res) => {
            return res.id.toString() === studentId;
        });

        if (!cachedStudent) {
            return this.props.route.goTo("/");
        }

        const pageMap = {};

        for (var i = 0; i < PageModel.length; i++) {
            const { pages, key } = PageModel[i];
            const section = key;
            for (var j = 0; j < pages.length; j++) {
                const page = pages[j].key;
                pageMap[page] = section;
            }
        }

        ExamUtil.link(studentId, this);
        NoteUtil.link(studentId, this);

        const { updateTime } = cachedStudent;
        const freshDuration = 15 * 60 * 1000; // 15 minutes;
        if (updateTime && Date.now() - updateTime < freshDuration) {
            this.setState({
                id: studentId,
                page: currentPage,
                pageMap: pageMap,
                data: cachedStudent,
                status: cachedStudent.status,
                notes: datastore.get("NOTES")[studentId],
                exams: datastore.get("EXAMS")[studentId],
                major_fit: cachedStudent.major_fit,
                school_fit: cachedStudent.school_fit,
                incomplete: cachedStudent.tier === Tier.UNASSIGNED,
                loaded: true,
            });
            return;
        }
        Request.GET("advisor/student/" + studentId, {
            onSuccess: (studentData) => {
                // studentData = studentMock;
                const { school_recommendation } = studentData;
                const fullStudent = datastore.updateStudent(
                    studentId,
                    studentData
                );
                const notes = DataConverter.processNotes(studentData.notes);
                const exams = DataConverter.processExams(studentData.exams);
                datastore.get("NOTES")[studentId] = notes;
                datastore.get("EXAMS")[studentId] = exams;

                SchoolUtil.getMissingSchoolData(school_recommendation, () => {
                    console.log(
                        "|| Profile || ...loaded",
                        Object.assign(
                            {
                                notes,
                                exams,
                            },
                            fullStudent
                        )
                    );

                    this.setState({
                        id: studentId,
                        page: currentPage,
                        pageMap: pageMap,
                        data: fullStudent,
                        status: fullStudent.status,
                        notes,
                        exams,
                        major_fit: fullStudent.major_fit,
                        school_fit: fullStudent.school_fit,
                        incomplete: fullStudent.tier === Tier.UNASSIGNED,
                        loaded: true,
                    });
                });
            },
            onFail: (status) => {
                if (status === 401) return true;
                this.back();
            },
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page && this.state.page === "PRINT") {
            prevPage = prevState.page;
        }
    }
    componentWillUnmount() {
        ExamUtil.unlink();
    }
    back() {
        this.props.route.goTo("/");
    }
    saveStudent(values) {
        values.id = this.state.id;
        // values.dateofBirth = (new Date(values.dateofBirth)).toISOString();
        // console.log("DOB: ", values.dateOfBirth, new Date(values.dateOfBirth));

        Request.POST("advisor/updateStudentAttribute", {
            data: values,
            onSuccess: () => {
                var student = datastore.updateStudent(this.state.id, values);
                // console.log("ARCHIVE STUDENT: ", student);
                this.setState({ data: student, status: student.status });
            },
        });
    }
    generateLink() {
        Request.POST("advisor/studentToken", {
            data: {
                id: this.state.id,
            },
            onSuccess: (res) => {
                const { token } = res;
                const baseUrl = window.location.origin + "/portal?";
                const lang = "language=" + i18n.language;
                const link = baseUrl + lang + "&token=" + token;
                this.setState({ link: link });
            },
        });
    }
    closeLink() {
        this.setState({ link: "" });
    }
    beginAssignment() {
        this.setState({ assigning: true });
    }
    endAssignment() {
        this.setState({ assigning: false });
    }
    setTier(tier) {
        Request.POST("advisor/updateStudentAttribute", {
            data: {
                id: this.state.id,
                assigned_tier: tier,
            },
            onSuccess: () => {
                this.assigned_tier = tier;

                if (tier < 0 || tier === this.state.data.tier) {
                    this.updateTier();
                } else {
                    this.setState({ feedback: true });
                }
            },
        });
    }
    updateTier() {
        const student = datastore.updateStudent(this.state.id, {
            assigned_tier: this.assigned_tier,
        });
        // console.log("ASSIGN TIER: ", student);
        this.setState({ data: student, status: student.status });
    }
    resetTier() {
        this.setTier(-1);
    }
    submitFeedback(response, note) {
        var respString =
            '{"response": "' +
            response.toString() +
            '", "note": "' +
            note +
            '"}';

        this.setState({ feedback: false });
        Request.POST("advisor/updateStudentAttribute", {
            data: {
                id: this.state.id,
                feedback: respString,
            },
            onSuccess: this.updateTier.bind(this),
        });
    }
    archive() {
        const datetime = new Date().getTime();
        Request.POST("advisor/updateStudentAttribute", {
            data: {
                id: this.state.id,
                archived: datetime,
            },
            onSuccess: () => {
                var student = datastore.updateStudent(this.state.id, {
                    archived: datetime,
                });
                // console.log("ARCHIVE STUDENT: ", student);
                this.setState({ data: student, status: student.status });
            },
        });
        // this.changePage("DASHBOARD");
    }
    unarchive() {
        Request.POST("advisor/updateStudentAttribute", {
            data: {
                id: this.state.id,
                archived: -1,
            },
            onSuccess: () => {
                var student = datastore.updateStudent(this.state.id, {
                    archived: -1,
                });
                // console.log("ARCHIVE TIER: ", student);
                this.setState({ data: student, status: student.status });
            },
        });
    }
    showNotes() {
        this.setState({ showNotes: true });
    }
    toggleNotes() {
        this.setState((state) => ({ showNotes: !state.showNotes }));
    }
    hideNotes() {
        if (this.state.showNotes) {
            this.setState({ showNotes: false });
        }
    }
    print() {
        if (this.state.page === "GLOBAL_COMPETENCE_REPORT") {
            this.setState({ isPrintMode: true });
            setTimeout(() => {
                window.print();
                this.setState({ isPrintMode: false });
            }, 1000);
        } else {
            window.print();
        }
    }
    printAll() {
        this.setState({ page: "PRINT" });
    }

    async downloadReport(reportType) {
        utils.log("download");
        const path =
            process.env.REACT_APP_SERVER_ADDRESS ||
            window.location.origin + `/report/${reportType}`;

        this.setState({ downloadingReport: true });

        const me = this;
        const authorization = await axios
            .get("/api/1/render/getAuthorization", {
                params: {
                    path: "/api/1/render/pdf",
                    method: "GET",
                    // accessId: globalConfig.accessId,
                },
            })
            .then(function (response) {
                return response.data.authorization;
            });

        if (process.env.NODE_ENV !== "production") {
            const renderServerAuthorization = utils.getAuthorization({
                accessId: "eduguide",
                accessKey: "eduguide",
            });
            utils.log("renderServerAuthorization", renderServerAuthorization);
            // debug with summary
            axios
                .get("/api/1/render/summary", {
                    params: {
                        uid: this.state.id,
                        token: renderServerAuthorization,
                    },
                })
                .then(function (response) {
                    let responseData = response.data;

                    utils.log("responseData", responseData);
                });
            me.setState({ downloadingReport: false });
            window.open(
                `${window.location.origin}/report/${reportType}?uid=${this.state.id}&token=${renderServerAuthorization}`
            );
            return;
        }

        const renderServer = _.trimEnd(
            process.env.REACT_APP_RENDER_SERVER_ADDRESS,
            "/"
        );
        if (!renderServer) {
            message.error("尚未配置render server地址");
            me.setState({ downloadingReport: false });
            return;
        }

        const studentName = this.state.data?.name;

        axios
            .get(renderServer + "/api/1/render/pdf", {
                params: {
                    uid: this.state.id,
                    src: path,
                },
                headers: {
                    authorization,
                    // "Content-Type": "application/octet-stream",
                },
                responseType: "blob",
            })
            .then(function ({ data }) {
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([data])
                );
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute(
                    "download",
                    studentName + "_素质能力与专业方向测评报告.pdf"
                ); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                me.setState({ downloadingReport: false });
            })
            .catch(async function (error) {
                me.setState({ downloadingReport: false });

                if (!error.response) {
                    message.error("错误，请联系管理员");
                    return;
                }

                const text = await error.response.data.text();
                const errObj = JSON.parse(text);

                // {code: 50301, msg: "The server is busy, please try again later"}
                if (errObj.code === 50301) {
                    message.info("当前服务忙，请稍后再试");
                } else {
                    message.error("错误，请联系管理员");
                }
            });
    }

    printAndRevert() {
        window.print();
        this.setState({ page: prevPage });
    }

    isPageEnabled(page) {
        const { status, tier, assigned_tier, kind, progress } = this.state.data;
        const { requireAssignment } = datastore.get("RENDER_CONDITIONS");
        const section = this.state.pageMap[page];

        if (page === "DASHBOARD") {
            return true;
        } else if (page === "GLOBAL_COMPETENCE_REPORT") {
            const competenceTraits = (this.state.data || {}).competence_traits;
            if (!competenceTraits || _.isEmpty(competenceTraits)) {
                return false;
            }
        } else if (status === Status.ARCHIVED) {
            return false;
        } else if (page === "NOTES") {
            return true;
        } else if (page === "SCHOOL_FIT" || page === "MAJOR_FIT") {
            if (kind === "GK") {
                if (progress === 1) {
                    return true;
                } else {
                    return false;
                }
            }

            const useTier = requireAssignment ? assigned_tier : tier;
            return useTier !== Tier.UNASSIGNED;
        } else if (section === "OVERVIEW") {
            if (kind === "GK") {
                if (progress === 1) {
                    return true;
                } else {
                    return false;
                }
            }

            return tier !== Tier.UNASSIGNED;
        }
        return true;
    }
    isPageHidden(page) {
        const { isBind, kind } = this.state.data;
        const isGK = kind === "GK";
        const gkNowAllowMenus = [
            "PLAN_REPORT",
            "GLOBAL_COMPETENCE_REPORT",
            "SCHOOL_FIT",
            "NOTES",
            "APPLICATION",
            "LANGUAGE",
            "INTER_ATTAINMENT",
        ];
        if (isGK && gkNowAllowMenus.includes(page)) {
            return false;
        }

        if (page === "NOTES" || page === "EXAMS") {
            if (isBind) {
                return false;
            }
            return true;
        }
        return true;
    }
    changePage(page) {
        if (page === this.state.page || !this.isPageEnabled(page)) {
            return;
        }
        this.hideNotes();
        this.props.route.goTo(
            "/profile/" + this.state.id + "/" + page.toLowerCase()
        );
        this.setState({ page: page });
    }
    render() {
        const {
            data,
            page,
            notes,
            showNotes,
            loaded,
            downloadingReport,
        } = this.state;
        const viewClass = "View Profile" + (showNotes ? " showNotes" : "");

        if (!loaded) {
            return (
                <div className={viewClass}>
                    <div className="nav">
                        <h1>{data.name}</h1>
                    </div>
                    <Loader active />
                </div>
            );
        }
        let _pagemodel = [];
        if (data.isBind || data.kind === "GK") {
            _.each(PageModel, (model) => {
                if (model.key !== "STUDENT_PROGRESS") {
                    _pagemodel.push(model);
                }
            });
        } else {
            _pagemodel = PageModel;
        }

        return (
            <div className={viewClass}>
                <Spin tip="报告正在生成中..." spinning={downloadingReport}>
                    <div className="nav" onClick={this.hideNotes}>
                        <h2 onClick={this.back.bind(this)}>
                            <div className="icon">!</div>
                            {i18n.t("STUDENTS.YOUR_STUDENTS")}
                        </h2>
                        <h1>{data.name}</h1>
                        {data.kind !== "GK" && data.tier > -1 && (
                            // <button
                            //     className="print-all-button"
                            //     onClick={this.printAll}
                            // />
                            <button
                                className="download-report"
                                onClick={() => {
                                    if (data.assigned_tier === -1) {
                                        message.info("分配套餐后才能下载报告");
                                        return;
                                    }
                                    this.downloadReport("overseas");
                                }}
                            />
                        )}
                        {data.kind === "GK" && data.progress === 1 && (
                            <button
                                className="download-report"
                                onClick={() => {
                                    this.downloadReport("gaokao");
                                }}
                            />
                        )}
                        {data.status !== Status.ARCHIVED && (
                            <DotMenu
                                status={data.status}
                                page={page}
                                progress={data.progress}
                                showNotes={this.showNotes}
                                generateLink={this.generateLink}
                                beginAssignment={this.beginAssignment}
                                resetTier={this.resetTier}
                                archive={this.archive}
                                print={this.print}
                                isBind={data.isBind}
                                isGK={data.kind === "GK"}
                            />
                        )}
                    </div>
                </Spin>
                <Sidebar
                    parent="PROFILE"
                    page={this.state.page}
                    model={_pagemodel}
                    isPageEnabled={this.isPageEnabled}
                    isPageHidden={this.isPageHidden}
                    changePage={this.changePage}
                />
                <div className="main" onClick={this.hideNotes}>
                    {this.renderPage()}
                </div>
                <NoteDrawer
                    page={page}
                    userid={data.id}
                    notes={notes}
                    changePage={this.changePage}
                />

                {data.kind !== "GK" &&
                    page !== "NOTES" &&
                    !data.isBind &&
                    AdminUtil.isVisionAccount() && (
                        <div className="notesBtn">
                            <RippleButton
                                fillFrom="center"
                                icon="£"
                                onClick={this.toggleNotes}
                            />
                        </div>
                    )}
                <Modal.Assign
                    studentId={data.id}
                    tier={data.tier}
                    scores={data.scores}
                    overall={data.overall}
                    open={this.state.assigning}
                    setTier={this.setTier}
                    onClose={this.endAssignment}
                />
                <Modal.StudentLink
                    close={this.closeLink}
                    link={this.state.link}
                    activated={data.progress > 0}
                />
                <Modal.Feedback
                    title={i18n.t("FEEDBACK.TITLE")}
                    subtitle={i18n.t("FEEDBACK.SUBTITLE")}
                    open={this.state.feedback}
                    questions={["01", "02", "03", "04", "05", "06"]}
                    submit={this.submitFeedback.bind(this)}
                />
            </div>
        );
    }
    renderPage() {
        const { data, exams, notes, page, major_fit, school_fit } = this.state;

        if (!page) {
            return;
        }
        if (!this.isPageEnabled(page)) {
            this.changePage("DASHBOARD");
            return;
        }
        switch (page) {
            case "DASHBOARD":
                return (
                    <Page.Dashboard
                        data={data}
                        changePage={this.changePage}
                        setTier={this.setTier}
                        resetTier={this.resetTier}
                        generateLink={this.generateLink}
                        beginAssignment={this.beginAssignment}
                        unarchive={this.unarchive}
                    />
                );
            case "PLAN_REPORT":
                return <Page.PlanReport data={data} />;
            case "TRAIT_REPORT":
                return <Page.TraitReport data={data} />;
            case "GLOBAL_COMPETENCE_REPORT":
                return (
                    <Page.GlobalCompetenceReport
                        data={data}
                        printView={this.state.isPrintMode}
                    />
                );
            case "MAJOR_FIT":
                return <Page.MajorFit data={major_fit} />;
            case "SCHOOL_FIT":
                return <Page.SchoolsPage data={school_fit} />;
            case "NOTES":
                return (
                    <Page.Notes
                        userid={data.id}
                        notes={notes}
                        showNotes={this.showNotes}
                    />
                );
            case "LANGUAGE":
                return <Page.Language data={data} />;
            case "PERSONAL":
                return (
                    <Page.StudentInfo
                        page="PERSONAL"
                        data={data}
                        onEdit={
                            data.isBind ? null : this.saveStudent.bind(this)
                        }
                    />
                );
            case "EXAMS":
                return <Page.ExamProgress studentId={data.id} data={exams} />;
            case "PRINT":
                return (
                    <PrintAllView
                        data={data}
                        school_fit={school_fit}
                        major_fit={major_fit}
                        print={this.printAndRevert}
                        showRecommendations={this.isPageEnabled("MAJOR_FIT")}
                    />
                );
            default:
                return (
                    <Page.StudentInfo
                        page={page}
                        data={data.raw_data}
                        profile={data.profile}
                    />
                );
        }
    }
}

export default Profile;
