import React from "react";
import styled from "styled-components";
import LeftNavBar from "../LeftNavBar";
import Dashboard from "./Dashboard";

const DashboardContainer = (props) => {
    return (
        <StyledContainer>
            <LeftNavBar currentPage="dashboard" />
            <Dashboard {...props} />
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    .View.dashboard {
        width: calc(100% - 50px);
        height: 100%;
        left: 50px;
        position: relative;
        overflow-y: auto;
    }
`;

export default DashboardContainer;
