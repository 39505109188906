import React, { useState } from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { Tooltip } from "antd";
import Upload from "routes/excel/Upload";
import More from "routes/excel/More";

const OperationButtons = ({
    studentId,
    studentName,
    applyStatus,
    applyAdvisor,
    fetchData,
    isShowUpload,
    children,
    contractId,
    contractName,
    contractStatus,
    contractNo,
}) => {
    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const tooltipString = contractNo
        ? `${studentName} - ${contractName} - ${contractNo}`
        : `${studentName} - ${contractName} `;

    if (contractStatus && contractStatus === "7") {
        return <StyledContainerCancel>合同已取消</StyledContainerCancel>;
    }
    return (
        <StyledContainer
            onClick={() => {
                setIsShowTooltip(false);
            }}
            onMouseEnter={() => {
                setIsShowTooltip(true);
            }}
            onMouseLeave={() => {
                setIsShowTooltip(false);
            }}
        >
            <Tooltip
                placement="left"
                title={tooltipString}
                visible={isShowTooltip}
            >
                {children ? (
                    children
                ) : (
                    <span>
                        {isShowUpload ? (
                            <Upload
                                contractId={contractId}
                                fetchData={fetchData}
                            />
                        ) : null}{" "}
                        <More
                            studentId={studentId}
                            contractId={contractId}
                            studentName={studentName}
                            applyStatus={applyStatus}
                            applyAdvisor={applyAdvisor}
                            fetchData={fetchData}
                        />
                    </span>
                )}
            </Tooltip>
        </StyledContainer>
    );
};

OperationButtons.propTypes = {
    studentId: propTypes.string,
    studentName: propTypes.string.isRequired,
    applyStatus: propTypes.string,
    applyAdvisor: propTypes.string,
    fetchData: propTypes.func,
    isShowUpload: propTypes.bool,
    children: propTypes.node,
    contractId: propTypes.string,
    contractName: propTypes.string.isRequired,
};

OperationButtons.defaultProps = {
    isShowUpload: false,
};

const StyledContainer = styled.div``;
const StyledContainerCancel = styled.div`
    color: gray;
    text-align: center;
`;

export default OperationButtons;
