import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Spin, Table, message } from "antd";
import DashboardUtils from "./DashboardUtils";
import FilterContext from "./FilterContext";
import propTypes from "prop-types";

function Tab({ tableConfigs }) {
    const [tableData, setTableData] = useState(_.map(tableConfigs, () => []));
    const [loading, setLoading] = useState(false);
    let { filter } = useContext(FilterContext);
    // console.log("filter", filter);
    useEffect(() => {
        setLoading(true);
        const PromiseArr = _.map(tableConfigs, function (item) {
            return DashboardUtils.fetchJSON(`dashboard/${item.path}?status=[4]`, filter);
        });

        Promise.all(PromiseArr)
            .then((responses) => {
                setLoading(false);
                setTableData(
                    _.map(tableConfigs, function (item, index) {
                        if (item.firstColumnText !== "院校提交进度") {
                            return DashboardUtils.parseData(
                                responses[index],
                                item.totalText
                            );
                        }

                        // very sepcial handle, hard code for "院校提交进度"
                        const responseData = responses[index];
                        let listData = [];
                        let summaryData = [];
                        _.each(responseData, function (item) {
                            if (
                                ["三天内截止未提交", "逾期未提交"].includes(
                                    item.type
                                )
                            ) {
                                summaryData.push(item);
                            } else if (item.type === "未提交") {
                                let noFill = _.find(responseData, {
                                    type: "未填写",
                                });

                                listData.push({
                                    type: "未提交(含未填写)",
                                    count: item.count + noFill.count,
                                });
                            } else if (item.type === "未填写") {
                                // do nothing
                            } else {
                                listData.push(item);
                            }
                        });

                        let renderData = DashboardUtils.parseData(
                            listData,
                            item.totalText
                        );

                        renderData.splice(
                            1,
                            0,
                            {
                                type: "三天内截止未提交",
                                key: "三天内截止未提交",
                                count: summaryData[0].count,
                                isTotal: true,
                                fontColor: "orange",
                            },
                            {
                                type: "逾期未提交",
                                key: "逾期未提交",
                                count: summaryData[1].count,
                                isTotal: true,
                                fontColor: "red",
                            }
                        );

                        return renderData;
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                message.error(String(err));
            });
    }, [filter, tableConfigs]);

    return (
        <Spin size="large" tip="Loading..." spinning={loading}>
            <StyledContainer>
                {_.map(tableConfigs, function (item, index) {
                    return (
                        <Table
                            columns={DashboardUtils.getColumns(
                                item.firstColumnText
                            )}
                            dataSource={tableData[index]}
                            size="small"
                            pagination={false}
                            bordered
                            key={item.key}
                        />
                    );
                })}
            </StyledContainer>
        </Spin>
    );
}

const StyledContainer = styled.div.attrs({
    className: "dashboard-tab",
})`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 25px;
    grid-column-gap: 25px;
    row-gap: 25px;
    grid-row-gap: 25px;
`;

Tab.propTypes = {
    tableConfigs: propTypes.array.isRequired,
};

export default Tab;
