import React, { Component } from "react";
import { Pagination, message } from "antd";
import { Request } from "../../lib";
import Editable from "./Editable";
import moment from "moment";
import DataDict from "./DataDict";
import _ from "lodash";
import MultiRow from "./MultiRow";
import ApplyStatus from "components/ApplyStatus";
import OperationButtons from "components/OperationButtons";
import StudentLink from "./summary/StudentLink";

class ExamInfo extends Component {
    rawData = {};
    state = {
        data: [],
        renderdata: {},
        currentPage: 1,
        total: 0,
        error: false,
        errorCode: "",
        loading: true,
        pageSize: 50,
        datadict: {
            otherSubject: DataDict.getMap("moblab:examinfo:othersubject"),
            SAT2Subject: DataDict.getMap("moblab:examinfo:SAT2Subject"),
            APSubject: DataDict.getMap("moblab:examinfo:APSubject"),
        },
        local: {
            emptyText: " ",
        },
        sorter: {},
    };
    componentWillMount = () => {
        this.init();
    };
    componentWillReceiveProps = (props) => {
        this.props = props;
        this.setState({ currentPage: 1 }, () => {
            this.fetchData();
        });
    };
    init = () => {
        this.fetchData();
    };
    fetchData = () => {
        this.setState({ loading: true });
        Request.GET(
            "excel/examinfo/" +
                encodeURIComponent(JSON.stringify({ ...this.buildFilter() })),
            {
                onSuccess: (data) => {
                    this.renderDataSource(data);
                },
                onFail: (status) => {
                    if (status === 401) return true;
                    message.error("查询错误");
                },
            }
        );
    };
    buildFilter = () => {
        return {
            currentPage: this.state.currentPage,
            pageSize: this.state.pageSize,
            filteredInfo: this.props.filteredInfo,
            sorter: this.state.sorter,
        };
    };
    pageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.fetchData();
        });
    };
    renderDataSource = (datasource) => {
        this.rawData = datasource;
        let ds = datasource.data;
        let datasourcetmp = [];
        let renderdata = {};
        for (let i = 0; i < ds.length; i++) {
            let data = ds[i];
            let TOEFLHeighest = 0;
            let maxReadingScore_TOEFL = 0;
            let listeningScore_TOEFL = 0;
            let speakingScore_TOEFL = 0;
            let writingScore_TOEFL = 0;
            let SAT1Heighest = 0;
            let readingScore_SAT1 = 0;
            let mathScore_SAT1 = 0;
            let _TOEFLScores = _.isEmpty(data["TOEFLScores"])
                ? []
                : data["TOEFLScores"];
            let _SAT1Scores = _.isEmpty(data["SAT1Scores"])
                ? []
                : data["SAT1Scores"];
            let _SAT2Scores = _.isEmpty(data["SAT2Scores"])
                ? []
                : data["SAT2Scores"];
            let _OtherScores = _.isEmpty(data["OtherScores"])
                ? []
                : data["OtherScores"];
            let _APScores = _.isEmpty(data["APScores"]) ? [] : data["APScores"];
            let _maxSubject = _.max([
                _TOEFLScores.length,
                _SAT1Scores.length,
                _SAT2Scores.length,
                _OtherScores.length,
                _APScores.length,
            ]);
            if (_maxSubject >= 5) {
                _.isEmpty(_TOEFLScores[_TOEFLScores.length - 1]) &&
                    _TOEFLScores.push({});
                _.isEmpty(_SAT1Scores[_SAT1Scores.length - 1]) &&
                    _SAT1Scores.push({});
                _.isEmpty(_SAT2Scores[_SAT2Scores.length - 1]) &&
                    _SAT2Scores.push({});
                _.isEmpty(_OtherScores[_OtherScores.length - 1]) &&
                    _OtherScores.push({});
                _.isEmpty(_APScores[_APScores.length - 1]) &&
                    _APScores.push({});
                _maxSubject = _maxSubject + 1;
            } else {
                _maxSubject = 5;
            }
            for (let j = 0; j < _maxSubject; j++) {
                let TOEFLScores =
                    _TOEFLScores && _TOEFLScores[j] ? _TOEFLScores[j] : {};
                let SAT1Scores =
                    _SAT1Scores && _SAT1Scores[j] ? _SAT1Scores[j] : {};
                let SAT2Scores =
                    _SAT2Scores && _SAT2Scores[j] ? _SAT2Scores[j] : {};
                let APScores = _APScores && _APScores[j] ? _APScores[j] : {};
                let OtherScores =
                    _OtherScores && _OtherScores[j] ? _OtherScores[j] : {};
                let TOEFLTotalScore =
                    (TOEFLScores["readingScore"]
                        ? TOEFLScores["readingScore"]
                        : 0) +
                    (TOEFLScores["listeningScore"]
                        ? TOEFLScores["listeningScore"]
                        : 0) +
                    (TOEFLScores["speakingScore"]
                        ? TOEFLScores["speakingScore"]
                        : 0) +
                    (TOEFLScores["writingScore"]
                        ? TOEFLScores["writingScore"]
                        : 0);
                TOEFLHeighest = _.max([TOEFLHeighest, TOEFLTotalScore]);
                maxReadingScore_TOEFL = _.max([
                    maxReadingScore_TOEFL,
                    TOEFLScores["readingScore"] || 0,
                ]);
                listeningScore_TOEFL = _.max([
                    listeningScore_TOEFL,
                    TOEFLScores["listeningScore"] || 0,
                ]);
                speakingScore_TOEFL = _.max([
                    speakingScore_TOEFL,
                    TOEFLScores["speakingScore"] || 0,
                ]);
                writingScore_TOEFL = _.max([
                    writingScore_TOEFL,
                    TOEFLScores["writingScore"] || 0,
                ]);
                let SAT1TotalScore =
                    (SAT1Scores["readingScore"]
                        ? SAT1Scores["readingScore"]
                        : 0) +
                    (SAT1Scores["mathScore"] ? SAT1Scores["mathScore"] : 0);
                SAT1Heighest = _.max([SAT1Heighest, SAT1TotalScore]);
                readingScore_SAT1 = _.max([
                    readingScore_SAT1,
                    SAT1Scores["readingScore"] || 0,
                ]);
                mathScore_SAT1 = _.max([
                    mathScore_SAT1,
                    SAT1Scores["mathScore"] || 0,
                ]);
                let rowSpan = 0;
                if (!renderdata[data["contractId"]]) {
                    rowSpan = _maxSubject;
                }
                renderdata[data["contractId"]] = {};
                datasourcetmp.push({
                    isLastItemOfGroup: j === _maxSubject - 1,
                    ...data,
                    examRow: i,
                    rowSpan: rowSpan,
                    key: i,
                    name: data["studentName"],
                    number: i + 1,
                    studentId: data["studentId"],
                    toefl: {
                        ...TOEFLScores,
                        examRow: i,
                        scoreRow: j,
                        toeflId: TOEFLScores["uuid"],
                        toefltotalScore: TOEFLScores["_id"]
                            ? TOEFLTotalScore
                            : null,
                        examDate: TOEFLScores["examDate"]
                            ? moment(TOEFLScores["examDate"])
                            : null,
                        examId: data["_id"] ? data["_id"] : null,
                        studentId: data["studentId"],
                    },
                    sat1: {
                        ...SAT1Scores,
                        examRow: i,
                        scoreRow: j,
                        sat1totalScore: SAT1Scores["_id"]
                            ? SAT1TotalScore
                            : null,
                        sat1Id: SAT1Scores["uuid"],
                        examDate: SAT1Scores["examDate"]
                            ? moment(SAT1Scores["examDate"])
                            : null,
                        examId: data["_id"] ? data["_id"] : null,
                        studentId: data["studentId"],
                    },
                    sat2: {
                        ...SAT2Scores,
                        examRow: i,
                        scoreRow: j,
                        sat2Id: SAT2Scores["uuid"],
                        examDate: SAT2Scores["examDate"]
                            ? moment(SAT2Scores["examDate"])
                            : null,
                        examId: data["_id"] ? data["_id"] : null,
                        studentId: data["studentId"],
                        subject: this.state.datadict.SAT2Subject[
                            SAT2Scores["subject"]
                        ],
                    },
                    ap: {
                        ...APScores,
                        examRow: i,
                        scoreRow: j,
                        apId: APScores["uuid"],
                        examId: data["_id"] ? data["_id"] : null,
                        studentId: data["studentId"],
                        subject: this.state.datadict.APSubject[
                            APScores["subject"]
                        ],
                    },
                    other: {
                        ...OtherScores,
                        examRow: i,
                        scoreRow: j,
                        otherId: OtherScores["uuid"],
                        subject: this.state.datadict.otherSubject[
                            OtherScores["subject"]
                        ],
                        examDate: OtherScores["examDate"]
                            ? moment(OtherScores["examDate"])
                            : null,
                        examId: data["_id"] ? data["_id"] : null,
                        studentId: data["studentId"],
                    },
                });
            }
            renderdata[data["contractId"]] = {
                TOEFLHeighest: TOEFLHeighest,
                SAT1Heighest: SAT1Heighest,
                TOEFLAbsMax:
                    maxReadingScore_TOEFL +
                    listeningScore_TOEFL +
                    speakingScore_TOEFL +
                    writingScore_TOEFL,
                SAT1AbsMax: readingScore_SAT1 + mathScore_SAT1,
            };
        }
        this.setState({
            data: datasourcetmp,
            currentPaged: datasource.currentPage,
            total: datasource.total,
            renderdata: renderdata,
            loading: false,
        });
    };

    setExamId = (row, id, attr, scores) => {
        this.rawData.data[row]._id = id;
        this.rawData.data[row][attr] = scores;
    };
    saveExamInfo = (record, data) => {
        data.contractId = record["contractId"];
        if (record.examRow !== undefined && record.examRow !== null) {
            this.rawData.data[record.examRow] = {
                ...this.rawData.data[record.examRow],
                ...data.update,
            };
        }
        Request.POST("examinfo", {
            data: data,
            onSuccess: (data) => {
                this.setExamId(record.examRow, data.id);
                this.renderDataSource(this.rawData);
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("更新失败");
                this.fetchData();
            },
        });
    };
    saveScoreInfo = (record, data) => {
        if (
            record.examRow !== undefined &&
            record.examRow !== null &&
            record.scoreRow !== undefined &&
            record.scoreRow !== null
        ) {
            // let examData = this.rawData.data[record.examRow];
            // let scoreData = examData[data.attr];
            // if (!examData[data.attr]) {
            // examData[data.attr] = [];
            // }
            // record.scoreRow = examData[data.attr].length < (record.scoreRow + 1) ? examData[data.attr].length : record.scoreRow;
            // examData[data.attr][record.scoreRow]
            //     = { ...examData[data.attr][record.scoreRow], ...data.update, examRow: record.examRow, scoreRow: record.scoreRow, _id: 'tmp_' + UUID.v1() }
        }
        let _syncStuParam = data.syncStuParam;
        if (!_.isEmpty(_syncStuParam)) {
            _.each(this.rawData.data, (v) => {
                if (v.contractId === _syncStuParam.contractId) {
                    v.syncStu = _syncStuParam.syncStu;
                }
            });
        }
        // this.renderDataSource(this.rawData);
        Request.POST("scoreinfo", {
            data: data,
            onSuccess: (res) => {
                this.setExamId(record.examRow, res.id, data.attr, res.scores);
                this.renderDataSource(this.rawData);
            },
            onFail: (status) => {
                if (status === 401) return true;
                message.error("更新失败");
                this.fetchData();
            },
        });
    };

    render() {
        let hiddenDIV = <div style={{ height: "4px", width: "100%" }}></div>;
        let renderdata = JSON.parse(JSON.stringify(this.state.renderdata));
        const columns = [
            {
                className: "column-apply-status cross-row-td",
                title: "申请状态",
                dataIndex: "applyStatus",
                key: "applyStatus",
                render: (statusCode, row, index) => {
                    let tag = (
                        <ApplyStatus
                            statusCode={statusCode}
                            studentId={row["studentId"]}
                            studentName={row["studentName"]}
                            fetchData={this.fetchData}
                            contractId={row["contractId"]}
                            contractStatus={row["contractStatus"]}
                        />
                    );
                    const obj = {
                        children: tag,
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                align: "center",
                sorter: true,
                width: "125px",
                fixed: "left",
            },
            {
                title: "学生姓名",
                dataIndex: "name",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <StudentLink
                                studentName={value}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                sorter: true,
                width: "95px",
                fixed: "left",
            },
            {
                title: "TOEFL账号",
                dataIndex: "TOEFLAccount",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveExamInfo(record, {
                        update: { TOEFLAccount: record["TOEFLAccount"] },
                        contractId: record["contractId"],
                        id: record["_id"],
                    });
                },
            },
            {
                title: "TOEFL密码",
                dataIndex: "TOEFLPwd",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveExamInfo(record, {
                        update: { TOEFLPwd: record["TOEFLPwd"] },
                        studentId: record["studentId"],
                        id: record["_id"],
                    });
                },
            },
            {
                title: "TOEFL最高分",
                dataIndex: "TOEFLHeighest",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={
                                    renderdata[row["contractId"]][
                                        "TOEFLHeighest"
                                    ]
                                }
                            >
                                {renderdata[row["contractId"]]["TOEFLHeighest"]}
                            </div>
                        ),

                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
            },
            {
                title: "TOEFL拼分",
                dataIndex: "TOEFLAbsMax",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    height: "15px",
                                    lineHeight: "15px",
                                }}
                                title={
                                    renderdata[row["contractId"]][
                                        "TOEFLAb sMax"
                                    ]
                                }
                            >
                                {renderdata[row["contractId"]]["TOEFLAbsMax"]}
                            </div>
                        ),

                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
            },
            {
                title: "TOEFL考试时间",
                dataIndex: ["toefl", "examDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            examDate: record["toefl"]["examDate"].valueOf(),
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                width: "137px",
            },
            {
                title: "TOEFL注册号",
                dataIndex: ["toefl", "registerAccount"],
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (_.isEmpty(_.trim(record["toefl"]["registerAccount"]))) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            registerAccount: record["toefl"]["registerAccount"],
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "TOEFL阅读成绩",
                dataIndex: ["toefl", "readingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["toefl"]["readingScore"])) {
                        return;
                    } else if (
                        record["toefl"]["readingScore"] > 30 ||
                        record["toefl"]["readingScore"] < 0
                    ) {
                        message.error("分数必须在0~30内");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            readingScore: Number(
                                record["toefl"]["readingScore"]
                            ),
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "TOEFL听力成绩",
                dataIndex: ["toefl", "listeningScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["toefl"]["listeningScore"])) {
                        return;
                    } else if (
                        record["toefl"]["listeningScore"] > 30 ||
                        record["toefl"]["listeningScore"] < 0
                    ) {
                        message.error("分数必须在0~30内");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            listeningScore: Number(
                                record["toefl"]["listeningScore"]
                            ),
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "TOEFL口语成绩",
                dataIndex: ["toefl", "speakingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["toefl"]["speakingScore"])) {
                        return;
                    } else if (
                        record["toefl"]["speakingScore"] > 30 ||
                        record["toefl"]["speakingScore"] < 0
                    ) {
                        message.error("分数必须在0~30内");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            speakingScore: Number(
                                record["toefl"]["speakingScore"]
                            ),
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "TOEFL写作成绩",
                dataIndex: ["toefl", "writingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["toefl"]["writingScore"])) {
                        return;
                    } else if (
                        record["toefl"]["writingScore"] > 30 ||
                        record["toefl"]["writingScore"] < 0
                    ) {
                        message.error("分数必须在0~30内");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "TOEFLScores",
                        update: {
                            writingScore: Number(
                                record["toefl"]["writingScore"]
                            ),
                        },
                        subId: record["toeflId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "TOEFL总成绩",
                dataIndex: ["toefl", "toefltotalScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
            },
            {
                title: "SAT账号",
                dataIndex: "SATAccount",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveExamInfo(record, {
                        update: { SATAccount: record["SATAccount"] },
                        studentId: record["studentId"],
                        id: record["_id"],
                    });
                },
            },
            {
                title: "SAT密码",
                dataIndex: "SATPwd",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={value}
                            >
                                {value}
                            </div>
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveExamInfo(record, {
                        update: { SATPwd: record["SATPwd"] },
                        studentId: record["studentId"],
                        id: record["_id"],
                    });
                },
            },
            {
                title: "SAT1最高分",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: renderdata[row["contractId"]]["SAT1Heighest"],
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
            },
            {
                title: "SAT1拼分",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: renderdata[row["contractId"]]["SAT1AbsMax"],
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
            },
            {
                title: "SAT1考试时间",
                dataIndex: ["sat1", "examDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            examDate: Number(record["sat1"]["examDate"]),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                width: "137px",
            },
            {
                title: "SAT1阅读成绩",
                dataIndex: ["sat1", "readingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat1"]["readingScore"])) {
                        return;
                    } else if (
                        record["sat1"]["readingScore"] > 800 ||
                        record["sat1"]["readingScore"] < 0 ||
                        record["sat1"]["readingScore"] % 10 !== 0
                    ) {
                        message.error("分数为0~800且为10的整数倍");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            readingScore: Number(
                                record["sat1"]["readingScore"]
                            ),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "SAT1数学成绩",
                dataIndex: ["sat1", "mathScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat1"]["mathScore"])) {
                        return;
                    } else if (
                        record["sat1"]["mathScore"] > 800 ||
                        record["sat1"]["mathScore"] < 0 ||
                        record["sat1"]["mathScore"] % 10 !== 0
                    ) {
                        message.error("分数为0~800且为10的整数倍");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            mathScore: Number(record["sat1"]["mathScore"]),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "SAT1总成绩",
                dataIndex: ["sat1", "sat1totalScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
            },
            {
                title: "SAT写作*Reading",
                dataIndex: ["sat1", "writingReadingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat1"]["writingReadingScore"])) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            writingReadingScore: Number(
                                record["sat1"]["writingReadingScore"]
                            ),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "SAT写作*Analysis",
                dataIndex: ["sat1", "writingAnalysisScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat1"]["writingAnalysisScore"])) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            writingAnalysisScore: Number(
                                record["sat1"]["writingAnalysisScore"]
                            ),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "SAT写作*Writing",
                dataIndex: ["sat1", "writingScore"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat1"]["writingScore"])) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT1Scores",
                        update: {
                            writingScore: Number(
                                record["sat1"]["writingScore"]
                            ),
                        },
                        subId: record["sat1Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "SAT2*科目名称",
                dataIndex: ["sat2", "subject"],
                render: (value, row, index) => {
                    const obj = {
                        children: value ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "SAT2Scores",
                        update: { subject: record["sat2"]["subject"] },
                        subId: record["sat2Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    arr.push({ key: "", value: "无" });
                    _.map(this.state.datadict.SAT2Subject, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                width: "290px",
            },
            {
                title: "SAT2*考试时间",
                dataIndex: ["sat2", "examDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "SAT2Scores",
                        update: { examDate: record["sat2"]["examDate"] },
                        subId: record["sat2Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                width: "137px",
            },
            {
                title: "SAT2*科目分数",
                dataIndex: ["sat2", "score"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["sat2"]["score"])) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "SAT2Scores",
                        update: { score: Number(record["sat2"]["score"]) },
                        subId: record["sat2Id"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "AP科目名称",
                dataIndex: ["ap", "subject"],
                render: (value, row, index) => {
                    const obj = {
                        children: value ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "APScores",
                        update: { subject: record["ap"]["subject"] },
                        subId: record["apId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    arr.push({ key: "", value: "无" });
                    _.map(this.state.datadict.APSubject, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                width: "290px",
            },
            {
                title: "AP科目分数",
                dataIndex: ["ap", "score"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["ap"]["score"])) {
                        return;
                    } else if (
                        record["ap"]["score"] > 5 ||
                        record["ap"]["score"] < 0
                    ) {
                        message.error("分数为0~5");
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "APScores",
                        update: { score: Number(record["ap"]["score"]) },
                        subId: record["apId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "其他*科目名称",
                dataIndex: ["other", "subject"],
                render: (value, row, index) => {
                    const obj = {
                        children: value ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                dropdown: true,
                menus: (() => {
                    let arr = [];
                    arr.push({ key: "", value: "无" });
                    _.map(this.state.datadict.otherSubject, (v, k) => {
                        arr.push({ key: k, value: v });
                    });
                    return arr;
                })(),
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "OtherScores",
                        update: { subject: record["other"]["subject"] },
                        subId: record["otherId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "其他*考试时间",
                dataIndex: ["other", "examDate"],
                render: (value, row, index) => {
                    const obj = {
                        children: value
                            ? value.format("YYYY-MM-DD")
                            : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                datepicker: true,
                handleSave: (record, value) => {
                    this.saveScoreInfo(record, {
                        attr: "OtherScores",
                        update: { examDate: record["other"]["examDate"] },
                        subId: record["otherId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
                width: "137px",
            },
            {
                title: "其他*科目分数",
                dataIndex: ["other", "score"],
                render: (value, row, index) => {
                    const obj = {
                        children: value || value === 0 ? value : hiddenDIV,
                        props: {},
                    };
                    obj.props.rowSpan = 1;
                    return obj;
                },
                editable: true,
                handleSave: (record, value) => {
                    if (isNaN(record["other"]["score"])) {
                        return;
                    }
                    this.saveScoreInfo(record, {
                        attr: "OtherScores",
                        update: { score: Number(record["other"]["score"]) },
                        subId: record["otherId"],
                        id: record["examId"],
                        // studentId: record["studentId"],
                        contractId: record["contractId"],
                        syncStuParam: {
                            syncStu: false,
                            contractId: record["contractId"],
                        },
                    });
                },
            },
            {
                title: "备注",
                dataIndex: "remark",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: <MultiRow value={value} row={8} />,
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    obj.props.className = "textarea";
                    return obj;
                },
                editable: true,
                textarea: true,
                row: 7,
                handleSave: (record, value) => {
                    this.saveExamInfo(record, {
                        update: { remark: record["remark"] },
                        studentId: record["studentId"],
                        id: record["_id"],
                    });
                },
                width: "150px",
            },
            {
                title: "操作",
                dataIndex: "opr",
                className: "cross-row-td",
                render: (value, row, index) => {
                    const obj = {
                        children: (
                            <OperationButtons
                                studentId={row["studentId"]}
                                studentName={row["studentName"]}
                                applyStatus={row["applyStatus"]}
                                applyAdvisor={row["applyAdvisorName"]}
                                fetchData={this.fetchData}
                                isShowUpload={true}
                                contractId={row["contractId"]}
                                contractName={row["contractName"]}
                                contractStatus={row["contractStatus"]}
                                contractNo={row["contractNo"]}
                            />
                        ),
                        props: {},
                    };
                    obj.props.rowSpan = row.rowSpan;
                    return obj;
                },
                fixed: "right",
                width: "140px",
                align: "center",
            },
        ];

        return (
            <>
                <Editable
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 5100, y: "75vh" }}
                    loading={this.state.loading}
                    local={this.state.local}
                    rowClassName={(record, index) => {
                        let syncFlag = record["syncStu"] ? "normal" : "syncRow";
                        const classNames = [syncFlag];

                        if (record.isLastItemOfGroup) {
                            classNames.push("last-item-of-group");
                        }

                        return classNames.join(" ");
                    }}
                    onChange={(pagination, filters, sorter) => {
                        this.setState(
                            {
                                sorter: _.pick(sorter, ["field", "order"]),
                            },
                            () => {
                                this.fetchData();
                            }
                        );
                    }}
                />
                <Pagination
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    onChange={this.pageChange}
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    style={{ marginTop: "10px" }}
                    showSizeChanger={false}
                />
            </>
        );
    }
}
export default ExamInfo;
