import ColorUtil from "./ColorUtil"

const colorMap = {
    GREEN: "#74C058",
    AQUAMARINE: "#00B4A1",
    TEAL: "#00BFD6",
    TURQOISE: "#00BFD6",
    BLUE: "#028CD0",
    INDIGO: "#5972A8",
    PURPLE: "#A392E3",
    PINK: "#EB5BA3",
    ORANGE: "#FD9D2C",
    GRAY: "#E8EAED"
}
const protocols = [
    "INTERPOLATE",
    "MIX",
    "LOOP"
]
export default class ColorGenerator {
    colors=[
        colorMap.GREEN,
        colorMap.AQUAMARINE,
        // colorMap.TEAL,
        colorMap.TURQOISE,
        colorMap.BLUE,
        colorMap.INDIGO,
        colorMap.PURPLE,
        colorMap.PINK,
        colorMap.ORANGE
    ]
    iterator=0
    protocol="INTERPOLATE"
    constructor(props){
        if (!props){
            return;
        }
        if (props.colors){
            this.initColors(props.colors);
        }
        if (props.protocol){
            this.setProtocol(props.protocol);
        }
    }
    /**
     * Initialize this.colors with a new spec. 
     * 
     * Spec can contain known keys (in colorMap) or hex strings. 
     * 
     * @param {Array} colorSpecs array of keys or hex strings
     */
    initColors(colorSpecs){
        const newColors = [];
        for (var c=0; c<colorSpecs.length; c++){
            const colorSpec = colorSpecs[c];
            if (colorSpec.indexOf("#") > -1){
                newColors.push(colorSpec);
            } else if (colorMap.hasOwnProperty(colorSpec)){
                newColors.push(colorMap[colorSpec]);
            } else {
                console.warn("INVALID COLOR: ", colorSpec);
            }
        }
        this.colors = newColors;
    }
    /**
     * Set new color generation protocol. Default is "INTERPOLATE".
     * 
     * @param {String | int} protocol protocol index or string
     */
    setProtocol(protocol){
        switch (protocol){
            case 0:
                this.protocol = protocols[0];
                break;
            case 1:
                this.protocol = protocols[1];
                break;
            case 2:
                this.protocol = protocols[2];
                break;
            default:
                if (protocols.indexOf(protocol) > -1) {
                    this.protocol = protocol;
                }
        }
    }
    /**
     * Get a color from the current generator, by index. 
     */
    get(i){
        if (i >= this.colors.length){ 
           return this.getNewColor(i);
        }
        return this.colors[i];
    }
    /**
     * Get a color from the colorMap, by key. 
     * 
     * @param {String} key colorMap key
     */
    getByKey(key){
        return colorMap[key];
    }
    /**
     * Generate a new color by interpolating two colors in the generators color list. 
     */
    getNewColor(i){
        if (this.protocol === "LOOP"){
            return this.colors[i % this.colors.length];
        }
        const max = this.colors.length - 0.01;
        const rand1 = Math.floor(Math.random() * max);
        const rand2 = Math.floor(Math.random() * max);
        const color1 = this.colors[rand1];
        const color2 = this.colors[rand2];
        if (this.protocol === "MIX"){
            return ColorUtil.mix(color1, color2, Math.random());
        } else {
            return ColorUtil.interpolate(color1, color2, Math.random());
        }
    }
    /**
     * Return the next color in the generator's color list. 
     */
    next(){
        const color = this.get(this.iterator);
        this.iterator++;
        return color;
    }
}