import React from "react";
import _ from "lodash";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import propTypes from "prop-types";
import styled from "styled-components";
import Section from "report/component/Section";

HighchartsMore(Highcharts);

// const colorsZh = {
// 	自我管理: "#CEE874",
// 	人际关系: "#E55B62",
// 	压力承受: "#6FB0C7",
// 	能力基础: "#F4E34E",
// 	文化适应: "#A094BA",
// };

// const colorsEn = {
// 	"Self Management": "#CEE874",
// 	"Interpersonal Relationships": "#E55B62",
// 	"Stress Management": "#6FB0C7",
// 	"Cognitive Skills": "#F4E34E",
// 	"Culture Adaptivity": "#A094BA",
// };

const colorsZh = {
    自我管理: "rgb(245, 188, 143)",
    人际关系: "rgb(250, 222, 164)",
    压力承受: "rgb(193,249,251)",
    能力基础: "rgb(150, 221, 251)",
    文化适应: "rgb(243, 176, 232)",
};

const colorsEn = {
    "Self Management": "rgb(245, 188, 143)",
    "Interpersonal Relationships": "rgb(250, 222, 164)",
    "Stress Management": "rgb(193,249,251)",
    "Cognitive Skills": "rgb(150, 221, 251)",
    "Culture Adaptivity": "rgb(243, 176, 232)",
};

const catagoryOrder = {
    自我管理: 1,
    人际关系: 2,
    压力承受: 3,
    能力基础: 4,
    文化适应: 5,
    "Self Management": 1,
    "Interpersonal Relationships": 2,
    "Stress Management": 3,
    "Cognitive Skills": 4,
    "Culture Adaptivity": 5,
};

function getSeries(traits, useAdvancedMode) {
    const data = [];
    const backgroundData = [];
    const backgroundData2 = [];
    _.each(traits, function (item) {
        data.push(Math.max(item.score, 5));
        if (!_.find(backgroundData, { key: item.category })) {
            backgroundData.push({
                key: item.category,
                y: 100,
                color:
                    colorsZh[item.category] ||
                    colorsEn[item.category] ||
                    "white",
            });
        }
        backgroundData2.push({
            y: 100,
            color: "white",
        });
    });

    let series = {
        type: "area",
        name: "分数",
        data: data,
        pointPlacement: "on",
        color: "#88B5E7",
        // color: "#8b43e0",
        zIndex: 2,
        states: {
            inactive: {
                opacity: 1,
            },
        },
    };

    if (!useAdvancedMode) {
        return [series];
    }

    let backgroundSeries = {
        type: "column",
        data: backgroundData,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        zIndex: 1,
        showInLegend: false,
        enableMouseTracking: false,
        animation: false,
        opacity: 0.8,
        states: {
            inactive: {
                opacity: 0.8,
            },
        },
        className: "column-item",
        xAxis: 1,
    };

    let backgroundSeries2 = {
        type: "column",
        data: backgroundData2,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        zIndex: 0,
        showInLegend: false,
        enableMouseTracking: false,
        animation: false,
        opacity: 0,
        states: {
            inactive: {
                opacity: 0,
            },
        },
        xAxis: 0,
        visible: false,
        label: { enable: false },
    };

    return [series, backgroundSeries, backgroundSeries2];
}

function getOptions({
    orderedTraits,
    printView,
    useAdvancedMode,
    traitsThreshold,
}) {
    const categories = [];
    _.each(orderedTraits, function (item) {
        categories.push(item.type);
    });

    const options = {
        chart: {
            polar: true,
            height: 500,
            width: printView ? 570 : null,
            events: {
                load: function () {
                    // var chart = this;
                    // chart.addSeries();
                },
            },
        },
        title: {
            text: null,
            // x: -80,
        },
        xAxis: !useAdvancedMode
            ? {
                  categories: categories,
                  tickmarkPlacement: "on",
                  lineWidth: 0,
                  labels: {
                      style: {
                          color: "#47525D",
                          fontSize: "14px",
                          maxWidth: "60px",
                          wordBreak: "break-all",
                      },
                  },
              }
            : [
                  {
                      categories: categories,
                      tickmarkPlacement: "on",
                      lineWidth: 0,
                      labels: {
                          style: {
                              color: "#47525D",
                              fontSize: "10px",
                          },
                      },
                  },
                  {
                      categories: [1, 2, 3, 4, 5],
                      gridLineWidth: 0,
                      gridLineColor: "transparent",
                      // tickmarkPlacement: "on",
                      lineWidth: 0,
                      labels: { enabled: false },
                  },
              ],
        yAxis: {
            gridLineColor: !useAdvancedMode ? "#e6e6e6" : "transparent",
            gridLineInterpolation: "polygon",
            lineWidth: 0,
            maxPadding: 0,
            min: 1,
            max: 100,
            tickPositions: [
                0,
                traitsThreshold?.lowerScoreThreshold,
                traitsThreshold?.upperScoreThreshold,
                100,
            ],
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            enabled: false,
            // shared: true,
            // pointFormat:
            // 	// eslint-disable-next-line no-template-curly-in-string
            // 	'<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>',
        },
        legend: {
            enabled: false,
            // align: "right",
            // verticalAlign: "top",
            // y: 70,
            // layout: "vertical",
        },
        series: getSeries(orderedTraits, useAdvancedMode),
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {},
        },
    };

    return options;
}

function TraitsOverview({
    traitsData,
    traitsThreshold,
    studentId,
    printView,
    isShowTitle = true,
    useAdvancedMode,
}) {
    let orderedTraits = _.map(traitsData, function (item) {
        return { ...item, order: catagoryOrder[item.category] };
    });
    orderedTraits = _.sortBy(orderedTraits, "order");
    const langauge = "zh"; // zh, en
    const colorsMap = langauge === "en" ? colorsEn : colorsZh;

    return (
        <StyledContainer printView={printView}>
            {isShowTitle ? (
                <div
                    className="widget-title"
                    // onClick={() => {
                    //     history.push("/student/" + studentId + "/traits");
                    // }}
                >
                    全球化人才报告
                </div>
            ) : null}
            <div className="chart-container">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getOptions({
                        orderedTraits,
                        printView,
                        useAdvancedMode,
                        traitsThreshold,
                    })}
                />
            </div>
            {useAdvancedMode ? (
                <div className="chart-legend">
                    {_.map(colorsMap, function (color, catagary) {
                        return (
                            <div key={catagary} className="catagary-item">
                                <div className="catagary-name">{catagary}</div>
                                <div
                                    className="catagary-color"
                                    style={{ backgroundColor: color }}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </StyledContainer>
    );
}

const StyledContainer = styled(Section).attrs({
    className: "trait-overview-section",
})`
    /* height: 550px; */
    margin-bottom: 30px;
    .widget-title {
        /* position: absolute;
		top: 20px;
		left: 20px;
		z-index: 1000; */
        cursor: pointer;
        font-size: 24px;
        color: #202324;
    }
    .chart-container {
        width: ${({ printView }) => (printView ? "570px" : "unset")};
        margin: auto;
    }

    .chart-legend {
        /* position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: 20px; */
        display: flex;
        justify-content: ${({ printView }) =>
            printView ? "space-between" : "center"};
        width: 100%;
        align-items: center;
        margin-top: 25px;
    }
    .catagary-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        .catagary-name {
            color: #47525d;
            height: 20px;
            line-height: 20px;
            margin-right: 5px;
        }

        .catagary-color {
            width: 40px;
            height: 8px;
            border-radius: 2px;
            opacity: 0.5;
        }
    }
`;

TraitsOverview.propTypes = {
    traitsData: propTypes.array.isRequired,
    traitsThreshold: propTypes.object.isRequired,
    studentId: propTypes.string,
};

export default TraitsOverview;
