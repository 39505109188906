class EscapeUtil {
    static mongoEscape = (v) => {
        v = v.replace(/\*/g, "")
            .replace(/\\/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
            .replace(/\?/g, "")
            .replace(/\//g, "");
        return v;
    }
}
export default EscapeUtil;