import React, { Component } from "react";


class MultiRow extends Component {
    hiddenDIV = <div style={{ height: this.props.row * 20 + 'px', width: "100%" }}></div>;
    render() {
        return (this.props.value ?
            <div
                style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: this.props.row,
                    overflow: 'hidden',
                    height: this.props.row * 20 + 'px',
                }}
                title={this.props.value}
            >
                {this.props.value}
            </div> : this.hiddenDIV
        )
    }
}
export default MultiRow;
