import React from "react";
import _ from "lodash";
import i18n from "lib/i18n/i18n";
import { level1, level2 } from "lib/i18n/data/globalCompetenceText";
import * as Widget from "../../components/widgets";
import { Grid } from "semantic-ui-react";

function getScores(competence, competenceTraits, level1Index) {
    let level2Scores = [];
    let compositeScore = 0;
    if (level1Index === 0) {
        level2Scores = [
            _.get(competenceTraits, "gv_openness", 0),
            _.get(competenceTraits, "gv_intercaltural_communication", 0),
            _.get(competenceTraits, "gv_multiculturalism", 0),
        ];
        compositeScore = _.get(competence, "global_vision", 0);
    }

    if (level1Index === 1) {
        level2Scores = [
            _.get(competenceTraits, "taa_academic_abilities", 0),
            _.get(competenceTraits, "taa_critical_thinking", 0),
            _.get(competenceTraits, "taa_curiosity", 0),
            _.get(competenceTraits, "taa_creativity", 0),
        ];
        compositeScore = _.get(competence, "thinking_and_academic_ability", 0);
    }

    if (level1Index === 2) {
        level2Scores = [
            _.get(competenceTraits, "ss_public_speaking", 0),
            _.get(competenceTraits, "ss_problem_solving", 0),
            _.get(competenceTraits, "ss_teamwork", 0),
            _.get(competenceTraits, "ss_leadership", 0),
        ];
        compositeScore = _.get(competence, "social_skills", 0);
    }

    if (level1Index === 3) {
        level2Scores = [
            _.get(
                competenceTraits,
                "values_agreeableness_conscientiousness",
                0
            ),
        ];
        compositeScore = _.get(competence, "values", 0);
    }

    return [compositeScore, level2Scores];
}
function GlobalCompetenceReport({ data, printView }) {
    const { competence, competence_traits } = data;

    return (
        <div className="Page">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Widget.GlobalCompetenceOverview
                            title={i18n.t(
                                "DASHBOARD.GLOBAL_COMPETENCE_OVERVIEW_TITLE"
                            )}
                            competenceTraits={competence_traits}
                        />
                    </Grid.Column>
                </Grid.Row>
                {_.map(level1, function (item, index) {
                    const level2items = _.filter(level2, { type: item.title });
                    const [compositeScore, level2Scores] = getScores(
                        competence,
                        competence_traits,
                        index
                    );

                    return (
                        <Grid.Row key={index}>
                            <Grid.Column>
                                <Widget.GlobalCompetenceSection
                                    title={item.title}
                                    subtitle={item.description}
                                    planSolution={
                                        compositeScore >= 60
                                            ? item.excellent
                                            : item.enhanced
                                    }
                                    level2items={level2items}
                                    compositeScore={compositeScore}
                                    level2Scores={level2Scores}
                                    printView={printView}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
            </Grid>
        </div>
    );
}

export default GlobalCompetenceReport;
