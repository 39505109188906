import React, { Component } from "react";
import { Logo, Input, PasswordReqs, TermsOfService } from "../components";
import { i18n, Request, Router } from "../lib";
import { URLParser, PasswordValidator } from "../lib/utils";
import { Button, Form, Message } from "semantic-ui-react";
import * as Modal from "../components/modals";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "../scss/App.scss";
import "../scss/Portal.scss";

const { token, change } = URLParser.run().search;
const appStore_link = "https://apps.apple.com/cn/app/visionguide/id1612228405";
const APK_link = "https://download.moblab-us.cn/apk/MobLabVision-release.apk";
const isAndroid = /android/i.test(navigator.userAgent);

class StudentPortal extends Component {
    constructor() {
        super();
        this.state = {
            userid: -1,
            username: "",
            phoneNumber: "",
            password_1: "",
            password_2: "",
            token: "",
            isChange: false,
            activated: false,
            expired: false,
            disabled: false,
            invalid: false,
            errorCode: "",
            validation: {},
            showAPKModal: false,
        };
        this.setPassword_1 = this.setPassword_1.bind(this);
        this.setPassword_2 = this.setPassword_2.bind(this);
        this.setPassword = this.setPassword.bind(this);
    }
    componentWillMount() {
        document.title = change ? "Password Reset" : "Student Signup";
        Router.link(this);
        i18n.init();

        if (this.state.loaded) {
            this.setState({ loaded: false, isChange: change });
        }

        Request.GET("user", {
            onSuccess: this.init.bind(this),
            onFail: () => {
                this.setState({ loaded: true });
            },
        });
    }
    activateToken() {
        this.setState({ disabled: true });

        Request.POST("session/tokenSignin", {
            data: {
                token: token,
            },
            onSuccess: this.init.bind(this),
            onFail: () => {
                this.setState({
                    loaded: true,
                    disabled: false,
                    expired: true,
                });
            },
        });
    }
    init(userData) {
        Request.GET("student", {
            onSuccess: (studentData) => {
                console.log("USER: ", userData, studentData);
                this.setState({
                    loaded: true,
                    activated: true,
                    disabled: false,
                    phoneNumber: studentData.phoneNumber,
                    username: userData.user.name,
                    userid: userData.user._id,
                });
            },
            onFail: () => {
                this.setState({ loaded: true });
            },
        });
    }
    setPassword_1(e) {
        this.setState({ invalid: false, password_1: e.target.value });
    }
    setPassword_2(e) {
        this.setState({ invalid: false, password_2: e.target.value });
    }
    setPassword(e) {
        e.preventDefault();
        if (!this.state.password_1 || !this.state.password_2) {
            return;
        } else if (this.state.password_1 !== this.state.password_2) {
            this.setState({ invalid: true, errorCode: "MISMATCH" });
            return;
        } else if (this.state.disabled) {
            return;
        }
        const result = PasswordValidator.validate(this.state.password_1);
        if (!result.valid) {
            this.setState({
                invalid: true,
                errorCode: "INVALID",
                validation: result,
            });
            return;
        }
        this.setState({ disabled: true });
        Request.POST("user/password", {
            data: {
                id: this.state.userid,
                password: this.state.password_1,
            },
            onSuccess: () => {
                Router.setUrl("/portal/download");
                this.setState({ disabled: false, download: true });
            },
            onFail: () => {
                console.error("COULD NOT UPDATE PASSWORD");
            },
        });
    }
    render() {
        const path = window.location.pathname;
        const expired = this.state.expired;
        const inactive = !this.state.activated && !expired;
        const appClass = "App Portal" + (inactive ? " inactive" : "");
        const grayBoxClass = "slantBox" + (expired ? " expired" : "");
        return (
            <div className={appClass}>
                <div className="splash">
                    <Logo simple={true} />
                </div>
                <div className="scroller">
                    <div className="container">
                        <div className={grayBoxClass}>
                            <div className="slice gray"></div>
                        </div>
                        <div className="slantBox">
                            <div className="slice blue"></div>
                        </div>
                        {path === "/portal" &&
                            this.state.activated &&
                            this.renderPasswordForm()}
                        {path === "/portal/download" &&
                            this.state.activated &&
                            this.renderDownload()}
                        {inactive && this.renderActivate()}
                        {this.state.expired && this.renderExpired()}
                    </div>
                </div>
            </div>
        );
    }
    renderPasswordForm() {
        const {
            password_1,
            password_2,
            phoneNumber,
            validation,
            isChange,
            invalid,
            disabled,
            exiting,
            errorCode,
        } = this.state;
        const canSubmit = password_1 && password_2;
        const pwdAction = isChange ? "CHANGE" : "CREATE";
        console.log("ACTION: ", pwdAction);
        return (
            <>
                <Form className={exiting ? " exiting" : ""} error={invalid}>
                    <h2>
                        {i18n.t(
                            disabled ? "PORTAL.CREATING" : "PORTAL.BEFORE_START"
                        )}
                    </h2>
                    <Input
                        type="password"
                        label={i18n.t("PASSWORD." + pwdAction)}
                        error={invalid}
                        disabled={this.state.disabled}
                        onChange={this.setPassword_1}
                        onFocus={() => {
                            this.bottom.scrollIntoView({
                                block: "end",
                                behavior: "smooth",
                            });
                        }}
                    />
                    <Input
                        type="password"
                        label={i18n.t("PASSWORD.CONFIRM")}
                        error={invalid}
                        disabled={disabled}
                        onChange={this.setPassword_2}
                    />
                    <Message
                        error
                        // header={i18n.t("PORTAL.ERROR_HEADER")}
                        content={i18n.t("PASSWORD." + errorCode)}
                    />
                    {invalid && errorCode === "INVALID" && (
                        <PasswordReqs validation={validation} />
                    )}
                    <div className="action">
                        <Button
                            className={canSubmit ? "" : "hide"}
                            inverted
                            color="blue"
                            style={{
                                width: "10rem",
                                display: "block",
                                margin: "0 auto",
                            }}
                            loading={disabled}
                            disabled={invalid}
                            onClick={this.setPassword.bind(this)}
                        >
                            {i18n.t("APP." + (exiting ? "LOADING" : "OK"))}
                        </Button>
                    </div>
                </Form>
                <div className={"topOverlay"}>
                    <h1>{i18n.t("PORTAL.TITLE")}</h1>
                </div>
                <div className="topOverlay">
                    <p>{i18n.t("PORTAL.YOUR_NUMBER")}</p>
                    <span>{phoneNumber}</span>
                </div>
                <div
                    className="anchor"
                    ref={(el) => {
                        this.bottom = el;
                    }}
                />
            </>
        );
    }
    renderDownload() {
        return (
            <>
                <div className="download">
                    <h2>{i18n.t("PORTAL.DOWNLOAD_PROMPT")}</h2>
                    {!isAndroid && (
                        <form action={appStore_link} target="_blank">
                            <Button
                                type="submit"
                                color="black"
                                style={{ minWidth: "10rem" }}
                                disabled={this.state.disabled}
                                loading={this.state.disabled}
                                // onClick={this.downloadFromAppStore.bind(this)}
                            >
                                {i18n.t("PORTAL.APP_STORE")}
                            </Button>
                        </form>
                    )}
                    {isAndroid && (
                        <form action={APK_link} target="_blank">
                            <Button
                                type="submit"
                                inverted
                                color="black"
                                style={{ minWidth: "10rem" }}
                                disabled={this.state.disabled}
                                loading={this.state.disabled}
                                onClick={() =>
                                    this.setState({ showAPKModal: true })
                                }
                            >
                                {i18n.t("PORTAL.APK")}
                            </Button>
                        </form>
                    )}
                </div>
                <div className={"topOverlay"}>
                    <h1>{i18n.t("PORTAL.DOWNLOAD_TITLE")}</h1>
                </div>
                <div className="topOverlay">
                    <p>{i18n.t("PORTAL.YOUR_NUMBER")}</p>
                    <span>{this.state.phoneNumber}</span>
                </div>
                <Modal.APK
                    open={this.state.showAPKModal}
                    close={() => this.setState({ showAPKModal: false })}
                />
            </>
        );
    }
    renderActivate() {
        const action = this.state.isChange ? "CHANGE" : "CREATE";
        return (
            <>
                <div className={"topOverlay"}>
                    <h1>{i18n.t("PORTAL.ACTIVATE_PROMPT_" + action)}</h1>
                </div>
                <div className="activate">
                    {/* <h2>{i18n.t("PORTAL.ACTIVATE_PROMPT_" + action)}</h2> */}
                    <Button
                        color="black"
                        style={{ minWidth: "10rem" }}
                        type="submit"
                        disabled={this.state.disabled}
                        loading={this.state.disabled}
                        onClick={this.activateToken.bind(this)}
                    >
                        {i18n.t("PORTAL.ACTIVATE_" + action)}
                    </Button>
                </div>
                <TermsOfService />
            </>
        );
    }
    renderExpired() {
        return (
            <>
                <div className={"topOverlay"}>
                    <h1>{i18n.t("PORTAL.EXPIRED_TITLE")}</h1>
                </div>
                <div className="topOverlay">
                    <p>
                        <b>{i18n.t("PORTAL.EXPIRED")}</b>
                    </p>
                </div>
                <div className="spacer" />
            </>
        );
    }
}

export default StudentPortal;
