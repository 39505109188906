import { i18n } from "../";

export { default as AdminUtil } from "./AdminUtil";
export { default as DataConverter } from "./DataConverter";
export { default as ColorGenerator } from "./ColorGenerator";
export { default as ColorUtil } from "./ColorUtil";
export { default as Deferred } from "./Deferred";
export { default as ExamUtil } from "./ExamUtil";
export { default as FilterUtil } from "./FilterUtil";
export { default as NoteUtil } from "./NoteUtil";
export { default as SchoolUtil } from "./SchoolUtil";
export { default as StudentUtil } from "./StudentUtil";
export { default as TimeUtil } from "./TimeUtil";
export { default as PasswordValidator } from "./PasswordValidator";
export { default as URLParser } from "./URLParser";

export const clamp = (val, min, max) => {
    return Math.max(Math.min(Math.max(val, min), max), min);
};
export const rem = (val) => {
    return (
        val * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
};
export const deriveWidth = (container, maxHeight, wRatio) => {
    const widthPx = Number.parseInt(
        window.getComputedStyle(container).getPropertyValue("width")
    );
    if (maxHeight) {
        maxHeight = rem(maxHeight) || window.innerHeight * 0.4;
        wRatio = wRatio || 1;
        return Math.min(widthPx, maxHeight * wRatio);
    } else {
        return widthPx;
    }
};

export const fillMissingDigits = (num, digits) => {
    const digitDiff = digits - num.toString().length;
    var zeros = "";
    for (var i = 0; i < digitDiff; i++) {
        zeros += "0";
    }
    return zeros + num;
};

export const formatDate = (rawDate, format) => {
    format = format || i18n.t("APP.DATE_FORMAT");

    if (format === "input") {
        if (rawDate === "") {
            return new Date();
        }
        return new Date(rawDate);
        // return new Date(rawDate).toISOString().replace(/T.*?Z/i, '')
    }
    if (!rawDate) {
        return i18n.t("APP.DATE_FORMAT");
    }

    const date = new Date(rawDate);
    const year = date.getFullYear().toString();
    const dateMatrix = {
        DD: fillMissingDigits(date.getDate(), 2),
        MM: fillMissingDigits(date.getMonth() + 1, 2),
        YYYY: year,
    };
    return format.replace(/MM|DD|YYYY/gi, (matched) => {
        return dateMatrix[matched];
    });
};

export const formatDuration = (ms) => {
    var daysCutoff = 1000 * 60 * 60 * 24;
    var hoursCutoff = 1000 * 60 * 60;
    var minCutoff = 1000 * 60;
    var units, dur;
    if (ms >= daysCutoff) {
        units = "DAYS";
        dur = Math.floor(ms / daysCutoff);
    } else if (ms >= hoursCutoff) {
        units = "HOURS";
        dur = Math.floor(ms / hoursCutoff);
    } else if (ms >= minCutoff) {
        units = "DAYS";
        dur = Math.floor(ms / minCutoff);
    } else {
        return "--";
    }
    return i18n.t("UNITS." + units, { count: dur, plural: true });
};

export const formatTitleCase = (str) => {
    const split = str.split(" ");
    var output = "";
    for (var s = 0; s < split.length; s++) {
        if (s > 0) {
            output += " ";
        }
        output +=
            split[s].charAt(0).toUpperCase() + split[s].slice(1).toLowerCase();
    }
    return output;
};

export const filterByQuery = (data, filter) => {
    return data.filter((item) => {
        const query = filter.query;
        for (var key in query) {
            if (key === "status" && query[key] === 0) {
                return item[key] <= 0;
            }

            if (item[key] !== query[key]) {
                return false;
            }
        }
        return true;
    });
};

export const isObjectEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const objToArray = (obj) => {
    const arr = [];
    for (var key in obj) {
        arr.push(obj[key]);
    }
    return arr;
};

export const paginate = (data, page, itemsPerPage) => {
    const total = data.length;
    const start = itemsPerPage * (page - 1);
    const end = Math.min(itemsPerPage * page, total);
    const results = data.length > 0 ? data.slice(start, end) : [];
    const lastPage = Math.ceil(total / itemsPerPage);
    return {
        start,
        end,
        results,
        canGoNext: page < lastPage,
    };
};
