import React, { Component } from "react";
import i18n from "../lib/i18n/i18n";
import datastore from "../data/store";
import { StudentUtil, AdminUtil } from "../lib/utils";
import { InteractiveTable } from "../components";
import { NewStudent } from "../components/modals";
import { Request } from "../lib";

const columns = [
    { key: "name" },
    { key: "kind" },
    { key: "status" },
    { key: "assigned_tier" },
    { key: "report" },
];
const columns_no_tier = [
    { key: "name" },
    { key: "kind" },
    { key: "status" },
    { key: "report" },
];

class Students extends Component {
    state = {
        modal_newStudent: false,
        searchSource: null,
        data: datastore.get("STUDENTS"),
    };
    constructor() {
        super();
        this.addNewStudent = this.addNewStudent.bind(this);
        this.showProfile = this.showProfile.bind(this);
        this.showAddStudent = this.showAddStudent.bind(this);
        this.hideAddStudent = this.hideAddStudent.bind(this);
    }
    componentDidMount() {
        document.title = i18n.t("APP.ADVISOR_CONSOLE");
        // const students = datastore.get("STUDENTS");
        const students = this.state.data;
        var source = [];
        // console.log("STUDENTS: ", this.props.students);
        for (var s in students) {
            const { id, name, assigned_tier, status, kind } = students[s];
            const assignedTier = StudentUtil.getTierName(assigned_tier, kind);
            const statusText = i18n.t("STUDENTS.STATUS_" + status);
            source.push({
                id: id,
                title: name,
                key: name + "_" + assignedTier + "_" + statusText + "_" + id,
                description: statusText,
                price: i18n.t("STUDENTS.TIER") + ": " + assignedTier,
            });
        }
        AdminUtil.getAdvisorAuthority(({ advisorAuthority }) => {
            this.setState({ advisorAuthority: advisorAuthority });
        });
        this.setState({ searchSource: source });
    }
    addNewStudent(name, areaCode, phoneNumber, kind) {
        StudentUtil.addStudent(name, areaCode, phoneNumber, kind, (user) => {
            this.showProfile(user._id);
        });
    }
    showProfile(id) {
        console.log("GO TO: ", id);
        this.props.route.goTo("/profile/" + id + "/dashboard");
    }
    showAddStudent() {
        // console.log("NEW STUDENT");
        this.setState({ modal_newStudent: true });
    }
    hideAddStudent() {
        this.setState({ modal_newStudent: false });
    }
    render() {
        const { searchSource } = this.state;
        const model = datastore.get("RENDER_CONDITIONS").requireAssignment
            ? columns
            : columns_no_tier;
        return (
            <div className="View Students">
                <InteractiveTable
                    tableKey="STUDENTS"
                    model={model}
                    data={this.state.data}
                    searchSource={searchSource}
                    filters={datastore.get("FILTERS").filters}
                    cutoff={datastore.get("FILTERS").cutoff}
                    title={i18n.t("STUDENTS.YOUR_STUDENTS")}
                    icon="y"
                    addMsg={
                        this.state.advisorAuthority &&
                        this.state.advisorAuthority.canAddStudent
                            ? i18n.t("STUDENTS.NEW_STUDENT")
                            : null
                    }
                    emptyMsg={i18n.t("STUDENTS.NO_STUDENTS")}
                    addNew={this.showAddStudent}
                    onSelect={this.showProfile}
                    onUpdate={(id, key, value) => {
                        Request.POST("advisor/updateStudentAttribute", {
                            data: {
                                id: id,
                                [key]: value,
                            },
                            onSuccess: () => {
                                datastore.updateStudent(id, {
                                    [key]: value,
                                });
                                this.setState({
                                    data: datastore.get("STUDENTS"),
                                });
                            },
                        });
                    }}
                />
                {this.state.modal_newStudent && (
                    <NewStudent
                        close={this.hideAddStudent}
                        create={this.addNewStudent}
                    />
                )}
            </div>
        );
    }
}

export default Students;
