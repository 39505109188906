import { Component } from "react";
import Router from "lib/Router";

class Switch extends Component {
    render() {
        // console.log("SWITCH: ", this.props.path);
        const path = this.props.path;
        Router.savePathtoLocal(path);

        const pathArgs = path.split("/");
        var defaultRoute = null;
        routeLoop: for (var c in this.props.children) {
            const child = this.props.children[c];

            if (child && child.props) {
                const pathSpecs = child.props.path.split("/");
                const params = {};
                if (pathArgs.length === pathSpecs.length) {
                    for (var s in pathSpecs) {
                        var spec = pathSpecs[s];
                        if (spec[0] === ":") {
                            const key = spec.slice(1);
                            params[key] = pathArgs[s];
                        }
                        if (spec[0] !== ":" && spec !== pathArgs[s]) {
                            continue routeLoop;
                        }
                    }
                    Router.setParams(params);
                    return child;
                }
                if (child.props.path === "/") {
                    defaultRoute = child;
                }
            }
        }
        return defaultRoute;
    }
}

export default Switch;
