import React, { Component } from 'react'
import { i18n } from "../../lib"
import { RichText } from "../../components"
import { Message, Modal } from 'semantic-ui-react'
import '../../scss/Modal.scss';

/**
 * @class StudentLink
 * 
 * A modal that displays a copyable student password reset link.
 * 
 * @prop {String}   link      student password reset link
 * @prop {Boolean}  activated student account activated status
 * @prop {Function} close     close modal handler
 */
class StudentLink extends Component {
    state={
        copied: false
    }
    copy(){
        // this.input.select();
        // document.execCommand('copy');

        var textarea = document.createElement('textarea');
        textarea.textContent = this.props.link;
        document.body.appendChild(textarea);

        // var selection = document.getSelection();
        // var range = document.createRange();
        // //  range.selectNodeContents(textarea);
        // range.selectNode(textarea);
        // selection.removeAllRanges();
        // selection.addRange(range);
        
        textarea.select();
        this.setState({copied: document.execCommand('copy')});
        textarea.focus();

        // selection.removeAllRanges();
        document.body.removeChild(textarea);
        // console.log("COPY: ", this.input.value);
    }
    close(){
        this.setState({copied: false});
        this.props.close()
    }
    render(){
        const exp = 30;
        const action = this.props.activated ? "CHANGE" : "CREATE";
        return (
            <Modal 
                className='StudentLink fullscreen'
                basic
                dimmer='inverted'
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.link !== ""}
            >
                <div className="icon close" onClick={this.close.bind(this)}>e</div>
                <Modal.Header>{i18n.t('DASHBOARD.LINK_TITLE_' + action)}</Modal.Header>
                <Modal.Content>
                    <RichText 
                        styles={{
                            c: {color: "#EE2A49"}
                        }}
                        content={i18n.t('DASHBOARD.LINK_PROMPT', {exp: exp})}
                    />
                    <div className="copyable" onClick={this.copy.bind(this)}>
                        <i className="icon">{"\u00C3"}</i>
                        <span>{i18n.t('DASHBOARD.GET_LINK')}</span>
                    </div>
                    <Message hidden={!this.state.copied} positive>
                        <i className="icon">E</i>
                        <span>{i18n.t('DASHBOARD.LINK_COPIED')}</span>
                    </Message>
                </Modal.Content>
                {/* <textarea
                    ref={(input) => this.input = input}
                    value={this.props.link}
                    onChange={() => {}}
                    style={{display: 'none'}}
                /> */}
            </Modal>
        )
    }
}

export default StudentLink