import React from "react";
import { i18n } from "../lib";

export default () => {
    return (
        <div className="TermsOfService">
            <div className="copyright">
                ©™2011-{new Date().getFullYear()} All Rights Reserved MobLab.
            </div>
            <div role="list" className="ui horizontal list">
                <div className="item">{i18n.t("APP.I_AGREE")}</div>
                <div className="item">
                    <div role="list" className="ui divided horizontal list">
                        <a
                            role="listitem"
                            className="item"
                            href="https://moblab.com/china-eula"
                        >
                            {i18n.t("APP.EULA")}
                        </a>
                        <a
                            role="listitem"
                            className="item"
                            href="https://moblab.com/china-privacy"
                        >
                            {i18n.t("APP.PRIVACY")}
                        </a>
                        <a
                            role="listitem"
                            className="item"
                            href="https://moblab.com/china-terms"
                        >
                            {i18n.t("APP.TOS")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
