import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import i18n from "../lib/i18n/i18n";
import { StudentUtil } from "lib/utils";
import "../scss/TierCard.scss";

class TierCard extends Component {
    select() {
        if (!this.props.select) {
            return;
        }
        this.props.select(this.props.index);
    }
    render() {
        var classString = "TierCard";
        if (this.props.selected || this.props.assigned) {
            classString += " selected";
        } else if (this.props.suggested) {
            classString += " suggested";
        }
        if (this.props.disabled) {
            classString += " disabled";
        }
        return (
            <div className={classString}>
                {this.props.suggested && (
                    <div className="floating">
                        {i18n.t("DASHBOARD.SUGGESTED")}
                    </div>
                )}
                <Segment onClick={this.select.bind(this)}>
                    <h1>{StudentUtil.getTierName(this.props.index)}</h1>
                    <h2>{i18n.t("SCORES.OVERALL")}</h2>
                    <p>{this.props.total}</p>
                    <h2>{i18n.t("DASHBOARD.INDIVIDUAL")}</h2>
                    <p>{this.props.individual}</p>
                </Segment>
            </div>
        );
    }
}

export default TierCard;
