import React, { useEffect, useRef } from "react";
// import _ from "lodash";
import propTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import utils from "common/utils";
import headerImg from "img/report/cover/report-cover-header-ve@2x.jpg";
import visionOverseas from "img/report/logos/vision-overseas.png";
import { ReactComponent as BottomSvg } from "img/report/cover/cover-illust-bottom.svg";

function Cover({ reportData }) {
    const locationRef = useRef();
    const detectPageHight = false;
    useEffect(
        function () {
            if (detectPageHight) {
                const onePageHeight = utils.getElementTop(locationRef.current);
                utils.log(onePageHeight, "onePageHeight");
            }
        },
        [detectPageHight]
    );

    return (
        <StyledContainer>
            <div className="cover-header">
                <div className="float-div"></div>
            </div>
            <div className="cover-body">
                <img src={visionOverseas} alt="" className="vision-logo" />

                <div className="title">新东方前途出国大学申请测评推荐系统</div>
                <div className="title2">-Vision Guide行为能力测评报告-</div>
                <div className="sub-title">{reportData.student.name}</div>
                <div className="report-date">
                    {moment().format("MM.DD.YYYY")}
                </div>
            </div>
            <BottomSvg className="cover-footer" />
            {detectPageHight && (
                <div className="location-div" ref={locationRef}>
                    123
                </div>
            )}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "a4-size",
})`
    .cover-header {
        width: 100%;
        height: 280px;
        background-image: url(${headerImg});
        background-size: cover;
        position: relative;
        z-index: 1;
        .float-div {
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 24px 24px 0px 0px;
            background-color: #fff;
            /* width: 500px; */
            width: 400px;
            height: 25px;
            z-index: 10;
        }
    }
    .cover-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        .ml-logo {
            height: 40px;
            width: 132px;
            position: absolute;
            z-index: 20;
            left: 475px;
            top: -10px;
        }
        .vision-logo {
            width: 200px;
            height: 30px;
            position: absolute;
            z-index: 20;
            /* left: 200px; */
            top: -5px;
        }

        .title {
            margin-top: 100px;
            margin-bottom: 16px;
            color: #47525d;
            font-size: 38px;
            font-weight: bold;
        }

        .title2 {
            margin-bottom: 24px;
            color: #47525d;
            font-size: 30px;
            font-weight: bold;
        }
        .sub-title {
            color: #ff6383;
            font-size: 18px;
            font-weight: bold;
        }
        .report-date {
            color: #838c96;
            font-size: 14px;
        }
    }
    .cover-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px;
        height: 200px;
    }
    .location-div {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 1px;
    }
`;

Cover.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default Cover;
