import React, { Component } from "react";
import { Button, Dropdown, Menu, Col, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./Excel.scss";
import DataDict from "./DataDict";
import SubmissionStatus from "./SubmissionStatus";
import BaseInfo from "./BaseInfo";
import Contact from "./Contact";
import HighSchool from "./HighSchool";
import CoreApply from "./CoreApply";
import ExamInfo from "./ExamInfo";
import Trackadmission from "./Trackadmission";
import AdmissionInfo from "./AdmissionInfo";
import ApplyMaterial from "./ApplyMaterial";
import FilterInfo from "./FilterInfo";
import _ from "lodash";
import Router from "lib/Router";
import { enableES5 } from "immer";

enableES5();

class Excel extends Component {
    componentWillMount = () => {
        DataDict.init(() => {
            this.applyStatusMap = DataDict.getMap(
                "moblab:contractinfo:applyStatus"
            );
            this.setState({ hasInit: true });
        });
    };

    handleMenuClick = (value) => {
        Router.setUrl(Router.CachedPaths[value.key]);
        this.setState({ menuInfo: value.key });
    };

    state = {
        hasInit: false,
        filteredInfo: {
            studentInfo: {
                name: {
                    val: "",
                    expression: "like",
                },
                applyAdvisorName: {
                    val: "",
                    expression: "like",
                },
                applyStatus: {
                    val: "",
                    isNc:true,
                    expression: "eq",
                },
                busiType: {
                    val: "",
                    expression: "eq",
                },
            },
            academyInfo: {
                schoolId: {
                    val: "",
                    expression: "eq",
                },
                overdate: {
                    val: "",
                    expression: "lte",
                },
            },
        },
        sortedInfo: null,
        menuInfo: {
            "/trackingsheet": "基本信息",
            "/trackingsheet/baseinfo": "基本信息",
            "/trackingsheet/contact": "联系信息",
            "/trackingsheet/examinfo": "考试分数",
            "/trackingsheet/highschool": "高中信息",
            "/trackingsheet/coreapply": "核心申请",
            "/trackingsheet/applymaterial": "申请材料",
            "/trackingsheet/submissionstatus": "院校提交",
            "/trackingsheet/trackadmission": "录取跟踪",
            "/trackingsheet/admissioninfo": "入读确认",
        }[window.location.pathname],
        iptclass: {
            student: "normal",
            advisor: "normal",
            applystatus: "normal",
            types: "normal",
            academyname: "normal",
            academydate: "normal",
        },
    };

    menu = (
        <Menu onClick={this.handleMenuClick}>
            <Menu.Item key="基本信息">基本信息</Menu.Item>
            <Menu.Item key="联系信息">联系信息</Menu.Item>
            <Menu.Item key="考试分数">考试分数</Menu.Item>
            <Menu.Item key="高中信息">高中信息</Menu.Item>
            <Menu.Item key="核心申请">核心申请</Menu.Item>
            <Menu.Item key="申请材料">申请材料</Menu.Item>
            <Menu.Item key="院校提交">院校提交</Menu.Item>
            <Menu.Item key="录取跟踪">录取跟踪</Menu.Item>
            <Menu.Item key="入读确认">入读确认</Menu.Item>
            {/* <Menu.Item key="写手">写手</Menu.Item> */}
        </Menu>
    );

    shouldComponentUpdate(nextProps, nextState) {
        const isPropEqual = _.isEqual(this.props, nextProps);
        const isStateEqual = _.isEqual(this.state, nextState);
        if (isPropEqual && isStateEqual) {
            return false;
        } else {
            return true;
        }
    }
    render() {
        window.moblab.log(this.props, "this.props.path");
        if (this.state.hasInit) {
            return (
                <div className="excel-content">
                    <Row>
                        <div style={{ height: "10px" }}></div>
                    </Row>
                    <Row>
                        <Col span={1}>
                            <div style={{ height: "10px" }}></div>
                        </Col>
                        <Col span={22}>
                            <div className="filter-container">
                                <div>
                                    <Dropdown
                                        overlay={this.menu}
                                        style={{
                                            float: "left",
                                            width: "180px",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                float: "left",
                                                width: "180px",
                                                borderRadius: "5px",
                                                backgroundColor: "#fafafa",
                                            }}
                                            className="menu"
                                        >
                                            {this.state.menuInfo}
                                            <DownOutlined
                                                style={{ marginLeft: "85px" }}
                                            />
                                        </Button>
                                    </Dropdown>
                                </div>
                                <div className="filter-right-contaner">
                                    <FilterInfo
                                        onSearch={(filteredInfo) => {
                                            this.setState({
                                                filteredInfo: filteredInfo,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={1}>
                            <div style={{ height: "10px" }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1}>
                            <div style={{ height: "10px" }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1}>
                            <div style={{ height: "10px" }}></div>
                        </Col>
                        <Col span={22}>
                            {(() => {
                                if (this.state.menuInfo === "院校提交") {
                                    return (
                                        <SubmissionStatus
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "基本信息") {
                                    return (
                                        <BaseInfo
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "联系信息") {
                                    return (
                                        <Contact
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "高中信息") {
                                    return (
                                        <HighSchool
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "核心申请") {
                                    return (
                                        <CoreApply
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "考试分数") {
                                    return (
                                        <ExamInfo
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "录取跟踪") {
                                    return (
                                        <Trackadmission
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "入读确认") {
                                    return (
                                        <AdmissionInfo
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                } else if (this.state.menuInfo === "申请材料") {
                                    return (
                                        <ApplyMaterial
                                            filteredInfo={
                                                this.state.filteredInfo
                                            }
                                        />
                                    );
                                }
                            })()}
                        </Col>
                        <Col span={1}>
                            <div style={{ height: "10px" }}></div>
                        </Col>
                    </Row>
                    <Row></Row>
                </div>
            );
        } else {
            return <></>;
        }
    }
}
export default Excel;
