import React, { Component } from "react";
import Card from "../Card";
import { rem, deriveWidth } from "../../lib/utils";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
} from "recharts";

class TraitsOverview extends Component {
    state = {
        width: 0,
    };

    componentDidMount() {
        this.setState({
            width: window.matchMedia("print").matches
                ? this.props.maxHeight
                    ? 400
                    : 600
                : deriveWidth(this.container, this.props.maxHeight, 2),
        });
    }
    render() {
        var width = Math.min(this.state.width, 600);

        return (
            <Card {...this.props} type="traits_overview">
                <div
                    ref={(div) => {
                        this.container = div;
                    }}
                    className="chartContainer"
                >
                    <RadarChart
                        width={width}
                        height={width / 2}
                        cx={width / 2}
                        cy={width / 4}
                        outerRadius={width / 4 - rem(2)}
                        data={this.props.traits}
                    >
                        <PolarGrid />
                        <PolarAngleAxis dataKey="label" />
                        <PolarRadiusAxis
                            axisLine={false}
                            domain={[0, 100]}
                            tickCount={3}
                            tickFormatter={() => ""}
                        />
                        <Radar
                            name="Traits"
                            dataKey="value"
                            // stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                            animationDuration={200}
                        />
                    </RadarChart>
                </div>
            </Card>
        );
    }
}
export default TraitsOverview;
