import { Request } from "../"
import datastore from "../../data/store"

const SAVE_DELAY = 2000;
const timeouts = {};

/**
 * Note Util
 * 
 * Handle all note related actions, including server requests. 
 */
export default {
    /**
     * Profile Component ref, to update global notes state
     */
    profile: null,
    /**
     * Current student's user id, used to ensure that actions are linked to 
     * the correct note, in the case that the user switches users while an 
     * action is being handled. 
     */
    userid: null,
    /**
     * Store the currently selected note id to track new note creation
     */
    noteid: null,
    /**
     * Store a newly created note's id until the view is updated
     */
    new_id: null,
    /**
     * Link the util to the current user and profile object
     * 
     * @param {String} userid   currently viewed student's userid
     * @param {Object} profile  currently viewed profile component
     */
    link(userid, profile){
        this.userid = userid;
        this.profile = profile;
    },
    /**
     * Remove user and profile refs
     */
    unlink(){
        this.userid = null;
        this.profile = null;
    },
    /**
     * Fetch all of a user's notes.
     * 
     * Use locally cached versions if they are stored in the datastore, otherwise 
     * dispatch a request for the user's notes and update the store. 
     * 
     * @param {String} userid       currently viewed student's userid
     * @param {Function} callback   callback to execute when notes are fetched
     */
    getStudentNotes(userid, callback){
        const allNotes = datastore.get('NOTES');
        const self = this;
        // console.log("GET STUDENT NOTES: ", userid, allNotes);
        if (allNotes.hasOwnProperty(userid)){
            callback(allNotes[userid]);
        } else {
            console.warn("Notes requested but not loaded, check render order.");
            Request.GET('advisor/student/' + userid + '/notes', {
                onSuccess({ notes }){
                    const studentNotes = notes;
                    for (var n=0; n<studentNotes.length; n++){
                        studentNotes[n].updatedAt = (new Date(studentNotes[n].updatedAt)).getTime();
                    }
                    datastore.get('NOTES')[userid] = studentNotes;
                    if (userid === self.userid){
                        callback(studentNotes);
                    }
                }
            })
        }
    },
    /**
     * Fetch a cached note. 
     * 
     * @param {String} userid   currently viewed student's userid
     * @param {String} noteid   id of note to fetch
     */
    getNote(userid, noteid){
        return datastore.getNote(userid, noteid);
    },
    /**
     * Create a new note. 
     * 
     * Update the note list with the new note (id=-1), while requesting a new note
     * creation from the server. When the server returns the new note (with an id),
     * overwrite the local copy's id by saving the note with its new id. 
     */
    newNote(){
        const { userid } = this;
        const self = this;
        const newNote = datastore.newNote(userid);
        this.noteid = -1;
        this.updateNotes();

        const postData = Object.assign({}, newNote);
        delete postData.id;

        Request.POST('advisor/student/' + userid + '/note', {
            data: postData,
            onSuccess({ note }){
                self.new_id = note.id;
                self.saveNote(userid, -1, {id: self.new_id});
            }
        })
        return newNote;
    },
    /**
     * Trigger all components to update their models with the latest version
     * of notes, from the datastore.
     */
    updateNotes(){
        if (this.profile){
            this.profile.setState({
                notes: datastore.get("NOTES")[this.userid]
            });
        }
    },
    /**
     * Updated a note with a given id with certain parameters. 
     * 
     * Look up a note by a given id and update its parameters one by one. 
     * noteid can differ from params.id in the case that a new note is created
     * with id=-1, then updated when the server returns with a real id. 
     * 
     * Updating a note will also trigger a save request to the server, though 
     * saves are only allowed every `SAVE_DELAY` ms. This means that if the note
     * was already saved to the server <`SAVE_DELAY` ms ago, the note will be 
     * scheduled to save after `SAVE_DELAY` ms. When the delay is over, the note will
     * save its current local state, not the state when the save was scheduled. In
     * Practice, this means that notes will effectively save every `SAVE_DELAY` ms, if
     * needed. 
     * 
     * @param {String} userid   currently viewed student's userid
     * @param {String} noteid   id of note to save
     * @param {Object} params   note fields to update
     * @param {boolean} [force] forces the note to save to remote, if there are 
     *                              changes. This is called when a note is closed.
     */
    saveNote(userid, noteid, params, force){
        if (!userid){
            return;
        }
        const oldData = datastore.getNote(userid, noteid);
        const now = (new Date()).getTime();
        const self = this;
       
        if (params.body){
            params.body = params.body.toString('html');
        }
        
        if (noteid === -1){
            syncLocal(params);
            return params;
        } 

        if (params.body === oldData.body && params.title === oldData.title){
            return params;
        }
        params.editTime = now;
        params.updatedAt = now;
        
        if (force){
            // console.log("FORCE>>>");
            syncLocal(params);
            syncRemote();
            return params;
        }

        
        const nextUpdateTime = oldData.updatedAt + SAVE_DELAY;
        
        if (nextUpdateTime < now){
            syncLocal(params);
            syncRemote();
        } else if (!timeouts.hasOwnProperty(noteid)){
            syncLocal(params);
            timeouts[noteid] = setTimeout(() => {
                console.log("SAVE AFTER DELAY: ", noteid);
                delete timeouts[noteid];
                const mostRecentVersion = datastore.getNote(userid, noteid);
                if (mostRecentVersion && noteid !== -1){ // Note might be deleted
                    syncRemote();
                }
            }, SAVE_DELAY);
        } else {
            syncLocal(params);
        }

        return params;
        
        function syncLocal(noteData){
            datastore.saveNote(userid, noteid, noteData);
            self.noteid = noteData.id;
            self.updateNotes();
        }
        function syncRemote(){
            Request.POST('advisor/student/' + userid + '/note', {
                data: datastore.getNote(userid, noteid)
            });
        }
    },
    /**
     * Delete a note.
     * 
     * Notes will be marked as `deleted` locally, so that they can be recovered 
     * easily, but the delete action will trigger a delete on the server side. This
     * ensures that if the note is deleted locally and then the page refreshed, the 
     * note will indeed be deleted. 
     * 
     * @param {String} noteid   id of note to delete
     */
    deleteNote(noteid){
        datastore.saveNote(this.userid, noteid, {
            deleted: true
        });
        this.noteid = null;

        Request.DELETE('advisor/student/' + this.userid + '/note/' + noteid, {});
        this.updateNotes();
    },
    /**
     * Restore a note. 
     * 
     * Since the note is stored locally while restorable (this is a state invariant),
     * restoring a note will remove the `deleted` flag from the note locally, then
     * request the note be saved to the server, using the locally stored data.
     * 
     * @param {String} noteid   id of note to restore
     */
    restoreNote(noteid){
        datastore.saveNote(this.userid, noteid, {
            deleted: false
        });
        Request.POST('advisor/student/' + this.userid + '/note/' + noteid + '/restore', {
            data: {}
        });
        this.updateNotes();
    },
    /**
     * Determine if a note can be saved. 
     * 
     * A note can only be saved if it has a title and/or body. Note that the body
     * is an html string, and can thus be of length>0 even if it doesn't have content. 
     * As such, all html tags should first be removed from the body string before
     * checking its length. 
     */
    validateNote(params){
        const title = params.title || "";
        const body = params.body.toString('html');
        const rawString = body.split("/n")[0].replace(/<\/*[a-z]+>/g, "");
        return (rawString.length > 0 || title.length > 0) && params.id !== -1;
    },
    /**
     * Save a note when it is closed. 
     * 
     * A note should only be saved if it is not empty. 
     * Otherwise, the note should be destroyed. 
     */
    saveOnClose(params){
        if (this.validateNote(params)){
            this.saveNote(this.userid, params.id, params, true);
        } else {
            Request.DELETE('advisor/student/' + this.userid + '/note/' + params.id, {});
            datastore.destroyNote(this.userid, params.id);
            this.updateNotes();
        }
    }
}