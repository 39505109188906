import React, { Component } from "react";
import styled from "styled-components";
import Card from "../../components/Card";
import * as Widget from "../../components/widgets";
import { i18n } from "../../lib";
import cn from "classnames";
import {
    FilterUtil,
    SchoolUtil,
    formatTitleCase,
    paginate,
    AdminUtil,
} from "../../lib/utils";
import { RippleButton, FilterField } from "../../components";
import { Form, Table, Loader, Popup } from "semantic-ui-react";

const gridItems = 10;
const tableItems = 20;
const columns = ["SCHOOL", "RANK", "FIT"];
const VIEW = {
    GRID: 0,
    TABLE: 1,
};

class SchoolsPage extends Component {
    state = {
        page: 1,
        view: VIEW.GRID,
        filterModel: null,
        activeFilters: {},
        showActionDivider: false,
    };
    constructor() {
        super();
        this.changePage = this.changePage.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
    }
    componentDidMount() {
        this.filterUtil = new FilterUtil();
        this.setState({ filterModel: SchoolUtil.getFilters() });
    }
    toggleView() {
        this.setState(({ view, page }) => {
            const scaleFactor = tableItems / gridItems;
            const nextPage =
                view === VIEW.GRID
                    ? Math.floor((page - 1) / scaleFactor)
                    : Math.floor((page - 1) * scaleFactor);
            return { view: 1 - view, page: nextPage + 1 };
        });
    }
    updateFilters(filters) {
        this.setState({
            page: 1,
            activeFilters: filters,
        });
    }
    changePage(e, { activePage }) {
        this.setState({ page: activePage });
    }
    back() {
        this.setState({ page: this.state.page - 1 });
    }
    next() {
        this.setState({ page: this.state.page + 1 });
    }
    onScroll({ target }) {
        const { showActionDivider } = this.state;
        const newVal = target.scrollTop > 10;
        if (newVal !== showActionDivider) {
            this.setState({ showActionDivider: newVal });
        }
    }
    renderRow(id, fit) {
        const { INSTNM, MOBLAB_RANK, CITY, STABBR } = SchoolUtil.getSchoolData(
            id
        );
        return (
            <Table.Row key={Math.random() * 10000} disabled>
                <td>
                    <div className="name">{formatTitleCase(INSTNM)}</div>
                    <div className="loc">
                        {formatTitleCase(CITY) + ", " + STABBR}
                    </div>
                </td>
                <td>{"#" + MOBLAB_RANK}</td>
                <td>{Math.round(fit * 100) + "%"}</td>
            </Table.Row>
        );
    }

    render() {
        const {
            page,
            view,
            activeFilters,
            filterModel,
            showActionDivider,
        } = this.state;
        const { data } = this.props;
        // console.log("school_fit", data);

        if (data === null || !this.filterUtil) {
            return (
                <div
                    className={cn([
                        "Page",
                        "SchoolFit",
                        {
                            "is-non-vision-account": !AdminUtil.isVisionAccount(),
                        },
                    ])}
                >
                    <Loader active={true} />
                </div>
            );
        }

        this.filterUtil.init(SchoolUtil.getDataset(), filterModel);

        const filteredData = SchoolUtil.sortSchool(
            this.filterUtil.filter(data, activeFilters)
        );

        // console.log("filteredData", filteredData);

        const itemsPerPage = view === VIEW.GRID ? gridItems : tableItems;
        const { start, end, results, canGoNext } = paginate(
            filteredData,
            page,
            itemsPerPage
        );

        const HAS_RESULTS = filteredData.length > 0;
        const actionsState = showActionDivider ? " raised" : "";

        // console.log("ACTIVE FILTERS: ", activeFilters);
        // console.log("MODEL: ", filterModel);

        return (
            <StyledContainer
                className={cn({
                    "is-non-vision-account": !AdminUtil.isVisionAccount(),
                })}
            >
                <Card type="SchoolList" title={i18n.t("SCHOOLS.TITLE")}>
                    <div className="viewBtn" onClick={this.toggleView}>
                        {i18n.t("SCHOOLS.CHANGE_VIEW_" + view)}
                    </div>
                    {/* <Popup
                        trigger={<div className="icon help">K</div>}
                        content={i18n.t("SCHOOLS.SUBTITLE")}
                        on="click"
                        basic
                        position="bottom right"
                    /> */}
                    <div className="schools-subtile">
                        {i18n.t("SCHOOLS.SUBTITLE")}
                    </div>
                    <Form className="filters">
                        {filterModel.map(({ name, filters }) => (
                            <div className="section" key={name}>
                                <h2>{name}</h2>
                                {filters.map((filter) => (
                                    <FilterField
                                        key={filter.key}
                                        params={filter}
                                        activeFilters={activeFilters}
                                        update={this.updateFilters}
                                    />
                                ))}
                            </div>
                        ))}
                        <Loader active={filterModel.length === 0} />
                    </Form>
                    <div className="content">
                        <div className="scrollArea" onScroll={this.onScroll}>
                            {view === VIEW.GRID && (
                                <>
                                    {results.map(({ id, fit }, r) => (
                                        <Widget.SchoolInfo
                                            key={r}
                                            data={SchoolUtil.getSchoolData(id)}
                                            fit={fit}
                                        />
                                    ))}
                                </>
                            )}
                            {view === VIEW.TABLE && (
                                <Table className="schoolsTable">
                                    <Table.Header>
                                        <Table.Row>
                                            {columns.map((key) => (
                                                <th
                                                    key={key}
                                                    className="disabled"
                                                >
                                                    {i18n.t("SCHOOLS." + key)}
                                                </th>
                                            ))}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {results.map(({ id, fit }) =>
                                            this.renderRow(id, fit)
                                        )}
                                    </Table.Body>
                                </Table>
                            )}
                        </div>
                        <div className={"actions" + actionsState}>
                            <div className="range">
                                {HAS_RESULTS
                                    ? i18n.t("APP.RANGE", {
                                          range: start + 1 + "-" + end,
                                          total: filteredData.length,
                                      })
                                    : i18n.t("APP.NO_RESULTS")}
                            </div>
                            <RippleButton
                                className="back"
                                disabled={HAS_RESULTS && page === 1}
                                icon="!"
                                flat
                                onClick={this.back}
                            />
                            <RippleButton
                                className="next"
                                disabled={HAS_RESULTS && !canGoNext}
                                icon='"'
                                flat
                                onClick={this.next}
                            />
                        </div>
                    </div>
                </Card>
            </StyledContainer>
        );
    }
}

const StyledContainer = styled.div.attrs({
    className: "Page SchoolFit",
})`
    .schools-subtile {
        text-align: left;
        padding-right: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #a1a8b3;
    }
`;

export default SchoolsPage;
