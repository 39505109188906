import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Form, Row, Col, Input } from "antd";
const { TextArea } = Input;

const config = [
    {
        label: "简历信息表",
        fieldName: "resumeInfoValue",
    },
    {
        label: "推荐人信息表",
        fieldName: "referrerInfoValue",
    },
    {
        label: "信用卡信息",
        fieldName: "creditCardInfoValue",
    },
    {
        label: "银行存款证明",
        fieldName: "depositInfoValue",
    },
    {
        label: "护照首页",
        fieldName: "passportInfoValue",
    },
    {
        label: "考试账户信息",
        fieldName: "examInfoValue",
    },
    {
        label: "作品/补充材料",
        fieldName: "worksInfoValue",
    },
    {
        label: "课程描述（转学）",
        fieldName: "courseDesInfoValue",
    },
    {
        label: "成绩认证",
        fieldName: "scoreInfoValue",
    },
    {
        label: "初中成绩单",
        fieldName: "juniorScoreValue",
    },
    {
        label: "高中成绩单",
        fieldName: "seniorScoreValue",
    },
    {
        label: "高中在读证明",
        fieldName: "seniorProveValue",
    },
    {
        label: "高中毕业证",
        fieldName: "seniorDiplomaValue",
    },
    {
        label: "大学成绩单（转学）",
        fieldName: "collegeScoreValue",
    },
    {
        label: "过往美国签证页（如有）",
        fieldName: "visaPageValue",
    },
    {
        label: "过往I-20（如有）",
        fieldName: "pastI20Value",
    },
    {
        label: "备注",
        fieldName: "remark",
        span: 24,
        DisplayComponent: TextArea,
    },
];

const statusColorMap = {
    已收集: "status-green",
    未收集: "status-red",
    无需: "status-gray",
};

function getParsedStudentInfo(studentData) {
    return studentData.applyMaterialsInfos || [];
}

function SummaryApplyMaterial({ studentData }) {
    const [applyMaterialsInfos, setApplyMaterialsInfos] = useState({});

    useEffect(() => {
        setApplyMaterialsInfos(getParsedStudentInfo(studentData));
    }, [studentData, setApplyMaterialsInfos]);
    const getFields = useCallback((config, configData) => {
        const children = [];

        _.each(config, function (
            { label, fieldName, span = 8, DisplayComponent },
            index
        ) {
            if (label === "") {
                children.push(<Col span={span} key={index}></Col>);
                return;
            }

            let classNameForColor = "no-status";
            if (configData[fieldName]) {
                classNameForColor =
                    statusColorMap[configData[fieldName]] || "no-status";
            }

            children.push(
                <Col span={span} key={index}>
                    <Form.Item label={label} colon={false} labelAlign="left">
                        {DisplayComponent ? (
                            <DisplayComponent
                                defaultValue={configData[fieldName]}
                                value={configData[fieldName]}
                                readOnly
                            />
                        ) : (
                            <Input
                                readOnly
                                value={configData[fieldName]}
                                className={classNameForColor}
                            />
                        )}
                    </Form.Item>
                </Col>
            );
        });

        return children;
    }, []);

    return _.map(applyMaterialsInfos, function (item, index) {
        return (
            <StyledContainer key={index}>
                <header className="section-header">
                    申请材料{" "}
                    {applyMaterialsInfos.length > 1
                        ? `(合同号:${item.contractInfo.contractId})`
                        : ""}
                </header>
                <Form
                    className="section-form"
                    initialValues={{
                        size: "small",
                    }}
                >
                    <Row gutter={24} className="page-break-auto">
                        {getFields(config, item)}
                    </Row>
                </Form>
            </StyledContainer>
        );
    });
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})`
    /* .ant-form-item-label > label {
        width: 160px !important;
    } */
    .status-green {
        color: green;
    }
    .status-red {
        color: red;
    }
    .status-gray {
        color: gray;
    }
`;

SummaryApplyMaterial.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryApplyMaterial;
