import React from "react";
import styled from "styled-components";
import LeftNavBar from "./LeftNavBar";
import Admin from "./Admin";
import { AdminUtil } from "lib/utils";
import cn from "classnames";

const ENABLED_PHASE2 =
    String(process.env.REACT_APP_ENABLED_PHASE2).toLocaleLowerCase() === "true";
const AdminContainer = ({ ...props }) => {
    const hasLeftNav = AdminUtil.isVisionAccount() && ENABLED_PHASE2;
    return (
        <StyledContainer
            className={cn({
                "has-left-nav": hasLeftNav,
            })}
        >
            {hasLeftNav ? <LeftNavBar currentPage="assessment" /> : null}
            <Admin {...props} />
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    .View.Admin {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: auto;
    }
    &.has-left-nav {
        .View.Admin {
            left: 50px;
            width: calc (100% - 50px);
            .main {
                width: calc(100% - 15rem - 50px);
            }
        }
    }
`;

export default AdminContainer;
