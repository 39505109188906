import { Deferred } from ".";
import { Router, Request } from "..";
import { formatDate } from "../utils";
import { AdminLevel, Tier, SortDirection } from "../../data/enum";
import { AdminUsers } from "../../data/model";
import datastore from "../../data/store";
import _ from "lodash";

var lastRoute = null;
const VIEW = {
    ADMIN: 0,
    ADVISOR: 1,
};
const startYear = 2019;

export default {
    init(callback) {
        const details = {};
        const deferred = new Deferred();
        const showCampuses = this.showCampuses();

        deferred.GET("admin/Advisor", {
            cacheBust: true,
            onSuccess: ({ items }) => {
                details.advisors = items;
            },
        });
        deferred.GET("admin/Student", {
            cacheBust: true,
            onSuccess: ({ items }) => {
                details.students = items;
            },
        });
        deferred.GET("admin/Campus", {
            cacheBust: true,
            onSuccess: ({ items }) => {
                details.campuses = items;
            },
        });
        deferred.GET("advisorauthority", {
            cacheBust: true,
            onSuccess: (data) => {
                details.advisorAuthority = data;
            },
        });
        deferred.then(() => {
            const { advisors, students, campuses, advisorAuthority } = details;
            console.log("GET ADVISORS AND STUDENTS: ", details);
            const roster = {
                campuses: {},
                advisors: {},
                students: {},
                prospects: {},
                advisorAuthority: {},
            };
            for (var c = 0; c < campuses.length; c++) {
                const campus = campuses[c];
                roster.campuses[campus._id] = campus;
            }
            datastore.set("CAMPUSES", roster.campuses);
            for (var a = 0; a < advisors.length; a++) {
                const advisor = this.processAdvisor(advisors[a]);
                // if (!roster.campuses.hasOwnProperty(advisor.campus)){
                //     danglingAccounts.advisors.push(advisor);
                //     continue;
                // }
                roster.advisors[advisor._id] = advisor;
            }
            for (var s = 0; s < students.length; s++) {
                const student = this.processStudent(
                    students[s],
                    roster.advisors
                );
                // if (!student.hasOwnProperty('advisor') || !roster.advisors.hasOwnProperty(student.advisor)){
                //     roster.prospects[student._id] = student;
                //     continue;
                // }
                // if (!roster.campuses.hasOwnProperty(student.campus)){
                //     danglingAccounts.students.push(student);
                //     continue;
                // }

                roster.students[student._id] = student;
            }

            // if (danglingAccounts.advisors.length > 0 || danglingAccounts.students.length > 0){
            //     console.warn("SOME ACCOUNTS DO NOT HAVE VALID CAMPUSES: ", danglingAccounts);
            // }
            datastore.set("ADMIN_ADVISORS_SORT", {
                key: "creation_date",
                direction: SortDirection.DOWN,
            });
            datastore.set("ADMIN_STUDENTS_SORT", {
                key: "creation_date",
                direction: SortDirection.DOWN,
            });
            roster.advisorAuthority = advisorAuthority;
            datastore.set("ADMIN_ADVISORS", roster.advisors);
            datastore.set("ADMIN_STUDENTS", roster.students);
            datastore.set("ADVISOR_AUTHORITY", roster.advisorAuthority);
            callback(roster);
        });
    },
    refreshAdvisors(callback) {
        Request.GET("admin/Advisor", {
            onSuccess: ({ items }) => {
                const advisors = {};
                for (var a = 0; a < items.length; a++) {
                    const advisor = this.processAdvisor(items[a]);
                    advisors[advisor._id] = advisor;
                }
                datastore.set("ADMIN_ADVISORS", advisors);
                callback(advisors);
            },
        });
    },
    processAdvisor(advisor) {
        advisor.creation_date = formatDate(advisor.createdAt);
        advisor.id = advisor._id;
        advisor.students = 0;
        return advisor;
    },
    processStudent(student, advisors) {
        student.id = student._id;
        student.creation_date = formatDate(student.createdAt);
        if (student.advisor) {
            const theirAdvisor = advisors[student.advisor];

            if (theirAdvisor) {
                theirAdvisor.students++;
                this.setStudentCampus(student, theirAdvisor.campus);
            } else {
                console.warn(
                    "STUDENT MISSING ADVISOR: ",
                    student,
                    student.advisor
                );
            }
        } else if (student.campus) {
            student.campusName = this.getCampusName(student.campus);
        }
        return student;
    },
    getAdvisorsAndStudents(callback) {
        if (
            !datastore.get("ADMIN_ADVISORS") ||
            !datastore.get("ADMIN_STUDENTS")
        ) {
            this.init(callback);
        } else {
            callback({
                campuses: datastore.get("CAMPUSES"),
                advisors: datastore.get("ADMIN_ADVISORS"),
                students: datastore.get("ADMIN_STUDENTS"),
                advisorAuthority: datastore.get("ADVISOR_AUTHORITY"),
            });
        }
    },
    getAdvisorAuthority(callback) {
        if (!datastore.get("ADVISOR_AUTHORITY")) {
            const deferred = new Deferred();
            const details = {};
            deferred.GET("advisorauthority", {
                cacheBust: true,
                onSuccess: (data) => {
                    details.advisorAuthority = data;
                },
            });
            deferred.then(() => {
                datastore.set("ADVISOR_AUTHORITY", details.advisorAuthority);
                callback({
                    advisorAuthority: datastore.get("ADVISOR_AUTHORITY"),
                });
            });
        } else {
            callback({
                advisorAuthority: datastore.get("ADVISOR_AUTHORITY"),
            });
        }
    },
    getCampuses() {
        return datastore.get("CAMPUSES");
    },
    getCampusName(id) {
        if (!this.showCampuses()) {
            return "";
        }
        if (!id) {
            return "--";
        }
        const campus = this.getCampuses()[id] || {};
        return campus.name || "";
    },
    setStudentCampus(student, id) {
        if (this.showCampuses()) {
            student.campus = id;
            student.campusName = this.getCampusName(id);
        }
    },
    getCurrentCampusId() {
        return datastore.get("USER").campus;
    },
    getCurrentView() {
        const root = window.location.pathname.split("/")[1];
        return root === "admin" ? VIEW.ADMIN : VIEW.ADVISOR;
    },
    getAdvisors() {
        return datastore.get("ADMIN_ADVISORS");
    },
    getSearchableAdvisors() {
        const advisors = this.getAdvisors();
        const source = [];
        for (var id in advisors) {
            const { name, email, campus } = advisors[id];
            const campusName = this.getCampusName(campus);
            source.push({
                id: id,
                title: name,
                key: name + "_" + email + "_" + campusName,
                description: email,
                price: campusName,
            });
        }
        return source;
    },
    getAdvisor(id) {
        return this.getAdvisors()[id] || {};
    },
    getAdvisorNames() {
        const advisors = this.getAdvisors();
        return Object.keys(advisors).map((id) => ({
            label: advisors[id].name,
            value: id,
        }));
    },
    addAdvisor(advisor) {
        this.getAdvisors()[advisor._id] = this.processAdvisor(advisor);

        // Request.POST('admin/Advisor/new', {
        //     data: {
        //         name: name,
        //         email: email,
        //         password: email,
        //         countryCode: '+86',
        //         phoneNumber: Math.round(Math.random() * 9999999999),
        //         campus: this.getCurrentCampusId()
        //     },
        //     onSuccess: ({ item }) => {
        //        this.getAdvisors()[item._id] = this.processAdvisor(item);
        //        cb();
        //     },
        //     onFail: () => {
        //         cb();
        //         return true;
        //     }
        // })
    },
    getStudents() {
        return datastore.get("ADMIN_STUDENTS");
    },
    getSearchableStudents(students) {
        const source = [];
        for (var i = 0; i < students.length; i++) {
            const { id, name, advisor, campus, email } = students[i];
            const campusName = this.getCampusName(campus);
            const advisorName = this.getAdvisor(advisor).name || "--";
            source.push({
                id: id,
                title: name,
                key: name + "_" + advisorName + "_" + campusName + "_" + email,
                description: advisorName,
                price: campusName,
            });
        }
        return source;
    },
    getActiveStudents() {
        return _.filter(this.getStudents(), (student) => {
            return student.hasOwnProperty("advisor");
        });
    },
    getStudent(id) {
        return this.getStudents()[id];
    },
    addStudent(user) {
        user.advisor = datastore.get("USER").id;
        user.createdAt = Date.now();
        this.getStudents()[user._id] = this.processStudent(
            user,
            this.getAdvisors()
        );
    },
    getTableModel() {
        const model = {
            advisors: AdminUsers.advisors.slice(0, 4),
            students: AdminUsers.students.slice(0, 3),
        };
        const advisorSelect = model.students[1];
        const advisors = this.getAdvisors();
        advisorSelect.options = Object.keys(advisors).map((id) => ({
            label: advisors[id].name,
            value: id,
        }));

        if (this.showCampuses()) {
            const advisorCampus = AdminUsers.advisors[4];
            const studentCampus = AdminUsers.students[4];
            const campuses = this.getCampuses();
            advisorCampus.options = Object.keys(campuses).map((id) => ({
                label: campuses[id].name,
                value: id,
            }));
            model.advisors.push(advisorCampus);
            model.students.push(studentCampus);
        }
        return model;
    },
    getNormalizedYear(year) {
        return getNormalizedYear(year);
    },
    getRecordedQuarters() {
        const now = new Date();
        const currYear = now.getFullYear();
        const currQuarter = Math.ceil(now.getMonth() / 3);
        const quarters = [];

        for (let y = startYear; y <= currYear; y++) {
            let q_end = y === currYear ? currQuarter : 4;
            for (let q = 1; q <= q_end; q++) {
                quarters.push({
                    year: y,
                    quarter: q,
                });
            }
        }
        return quarters;
    },
    initStatistics() {
        const ids = Object.assign({}, this.getCampuses());
        const now = new Date();
        const currYear = getNormalizedYear(now.getFullYear());
        const currMonth = now.getMonth();
        const statistics = {};
        ids.all = {};

        for (let id in ids) {
            statistics[id] = {
                student_accounts: [],
                completed_assessments: [],
                plan_assignments: [],
                advisor_accounts: [],
            };
            const campusStats = statistics[id];
            const student_accounts = campusStats.student_accounts;
            const completed_assessments = campusStats.completed_assessments;
            const plan_assignments = campusStats.plan_assignments;
            const advisor_accounts = campusStats.advisor_accounts;

            for (let y = 0; y <= currYear; y++) {
                const m_end = y === currYear ? currMonth : 12;

                student_accounts[y] = [];
                completed_assessments[y] = [];
                plan_assignments[y] = [];
                advisor_accounts[y] = [];
                for (let m = 0; m <= m_end; m++) {
                    // const label = i18n.t('STATS.MONTH_' + (m+1), { year: startYear + y});
                    const label =
                        m + 1 + "/" + (y + startYear).toString().slice(2);
                    student_accounts[y][m] = {
                        label,
                        val: 0,
                    };
                    completed_assessments[y][m] = {
                        label,
                        val: 0,
                    };
                    plan_assignments[y][m] = {
                        label,
                        total: 0,
                    };
                    for (var key in Tier) {
                        const tier = Tier[key];
                        if (tier > -1) {
                            plan_assignments[y][m]["tier_" + tier] = 0;
                        }
                    }
                    advisor_accounts[y][m] = {
                        label,
                        val: 0,
                    };
                }
            }
        }
        return statistics;
    },
    getStatistics() {
        let statistics = datastore.get("STATISTICS");
        if (statistics) {
            return statistics;
        }

        const advisors = this.getAdvisors();
        const students = this.getActiveStudents();

        statistics = this.initStatistics();
        // console.log("INIT STATS: ", statistics);

        for (let advisorId in advisors) {
            const advisor = advisors[advisorId];
            const campusId = advisor.campus;
            if (!campusId) {
                console.error("ADVISOR IS MISSING CAMPUS: ", advisor);
                continue;
            }
            if (!statistics.hasOwnProperty(campusId)) {
                console.warn(
                    "ADVISOR CAMPUS MISSING FROM STATISTICS: ",
                    advisor,
                    this.getCampusName(campusId)
                );
                continue;
            }
            const accountsByMonth = statistics[campusId].advisor_accounts;
            const all_accounts = statistics.all.advisor_accounts;
            getCurrentStat(accountsByMonth, advisor.createdAt).val++;
            getCurrentStat(all_accounts, advisor.createdAt).val++;
        }

        for (let studentId in students) {
            const student = students[studentId];
            const campusId = student.campus;
            if (!statistics.hasOwnProperty(campusId)) {
                console.error(
                    "STUDENT ASSIGNED TO DANGLING CAMPUS: ",
                    student,
                    this.getCampusName(campusId)
                );
                continue;
            }

            const accountsByMonth = statistics[campusId].student_accounts;
            const all_accounts = statistics.all.student_accounts;

            getCurrentStat(accountsByMonth, student.createdAt).val++;
            getCurrentStat(all_accounts, student.createdAt).val++;

            if (student.completionDate) {
                const assessmentsByMonth =
                    statistics[campusId].completed_assessments;
                const allAssessments = statistics.all.completed_assessments;
                getCurrentStat(assessmentsByMonth, student.completionDate)
                    .val++;
                getCurrentStat(allAssessments, student.completionDate).val++;
            }
            if (student.planAssignmentDate) {
                const tier = student.assigned_tier;

                const plan_assignments = statistics[campusId].plan_assignments;
                const allPlans = statistics.all.plan_assignments;
                const stat = getCurrentStat(
                    plan_assignments,
                    student.planAssignmentDate
                );
                const allStat = getCurrentStat(
                    allPlans,
                    student.planAssignmentDate
                );
                stat["tier_" + tier] = (stat["tier_" + tier] || 0) + 1;
                allStat["tier_" + tier] = (allStat["tier_" + tier] || 0) + 1;
                stat.total++;
                allStat.total++;
            }
        }
        datastore.set("STATISTICS", statistics);
        return statistics;
    },
    toggleView() {
        const root = window.location.pathname.split("/")[1];
        const newRoot = root === "admin" ? "/" : "/admin/dashboard";
        const destination = lastRoute || newRoot;
        lastRoute = window.location.pathname;
        Router.goTo(destination);
    },

    resetLastRoute() {
        lastRoute = null;
    },

    bounce() {
        Router.goTo("/");
    },
    isAdmin() {
        const level = datastore.get("USER").adminLevel;
        return level === AdminLevel.VISION || level === AdminLevel.CAMPUS;
    },

    isVisionAccount() {
        const organization = datastore.get("USER").organization;
        // return true;
        return (
            !organization ||
            !organization.length ||
            organization.includes("Vision")
        );
    },

    showCampuses() {
        return datastore.get("USER").adminLevel === AdminLevel.VISION;
    },
    showDashboard() {
        return datastore.get("USER").adminLevel === AdminLevel.VISION;
    },
    save(itemType, id) {
        console.log("SAVE: ", itemType, id);
        const params = {};
        const cols = AdminUsers[itemType.toLowerCase() + "s"];
        const item = this["get" + itemType](id);

        for (var c = 0; c < cols.length; c++) {
            const { key, save } = cols[c];
            if (save && (key !== "campus" || this.showCampuses())) {
                params[key] = item[key];
            }
        }
        Request.POST("admin/" + itemType + "/" + id, {
            data: params,
            onSuccess: (res) => {
                console.log("..." + itemType + " saved: ", res);
            },
        });
    },
    updateAdvisor(id, key, value) {
        const advisor = this.getAdvisors()[id];
        if (advisor[key] === value) {
            return;
        }
        advisor[key] = value;

        if (key === "campus") {
            const students = this.getStudents();
            for (var studentId in students) {
                const student = students[studentId];
                if (student.advisor === advisor.id) {
                    this.setStudentCampus(student, advisor.campus);
                    this.save("Student", student.id);
                }
            }
            this.save("Advisor", id);
        }
    },
    updateStudent(id, key, value) {
        const student = this.getStudents()[id];
        const prevVal = student[key];
        if (prevVal === value) {
            return;
        }
        student[key] = value;

        if (key === "advisor") {
            if (prevVal) {
                const prev_advisor = this.getAdvisor(prevVal);
                prev_advisor.students--;
            }

            const new_advisor = this.getAdvisor(value);
            new_advisor.students++;
            this.setStudentCampus(student, new_advisor.campus);
        }
    },
};

function getCurrentStat(statByMonth, date) {
    const creationDate = new Date(date);
    const yearIndex = getNormalizedYear(creationDate.getFullYear());
    const creationMonth = creationDate.getMonth();
    return statByMonth[yearIndex][creationMonth];
}
function getNormalizedYear(year) {
    return year - startYear;
}
