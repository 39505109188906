import React from "react";
import _ from "lodash";
import propTypes from "prop-types";
import SubSection from "../SubSection";
import TraitDynamicPagination from "./TraitDynamicPagination";
import overseasUtils from "../components/overseasUtils";

function PartTrait({ reportData, partOrder, part }) {
    return (
        <React.Fragment>
            <SubSection
                title={part.title}
                partKey={part.key}
                sectionNo={partOrder}
            />

            {(function () {
                const parsedTraits = overseasUtils.parseTrait(
                    reportData.traits
                );

                const pages = overseasUtils.getPagesByTraitData(parsedTraits);
                return _.map(pages, function (pageSections, pageIndex) {
                    return (
                        <TraitDynamicPagination
                            title={part.title}
                            pageSections={pageSections}
                            key={pageIndex}
                            traitsThreshold={{
                                lowerScoreThreshold:
                                    reportData.traitThreshold?.[0],
                                upperScoreThreshold:
                                    reportData.traitThreshold?.[1],
                            }}
                        />
                    );
                });
            })()}
        </React.Fragment>
    );
}

PartTrait.propTypes = {
    reportData: propTypes.object.isRequired,
};

export default PartTrait;
