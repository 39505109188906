import React, { Component } from "react";
import { Logo, RippleButton } from "../components";
import { i18n } from "../lib";
import { AdminUtil } from "../lib/utils";
import { Dropdown } from "semantic-ui-react";

class AppBar extends Component {
    render() {
        const { logout, showPwdChange, route } = this.props;
        const view = AdminUtil.getCurrentView();
        const isPhase2 =
            window.location.pathname.includes("trackingsheet") ||
            route === "/dashboard";
        return (
            <div className={"Component AppBar" + (view ? "" : " admin")}>
                <div className="nav">
                    <Logo />
                    <div className="appbar-link-container">
                        {!isPhase2 && AdminUtil.isAdmin() && (
                            <div
                                className="adminToggle"
                                onClick={AdminUtil.toggleView}
                            >
                                {i18n.t("ADMIN.VIEW_" + view)}
                            </div>
                        )}
                        <Dropdown
                            trigger={<RippleButton icon="8" flat white />}
                            direction="left"
                        >
                            <Dropdown.Menu>
                                {AdminUtil.isAdmin() && (
                                    <Dropdown.Item
                                        onClick={AdminUtil.toggleView}
                                    >
                                        {i18n.t("ADMIN.SWITCH_VIEW_" + view)}
                                    </Dropdown.Item>
                                )}
                                {/* <Dropdown.Item onClick={showPwdChange}>
                                      {i18n.t('PASSWORD.CHANGE')}
                                    </Dropdown.Item> */}
                                <Dropdown.Item onClick={logout}>
                                    {i18n.t("APP.LOGOUT")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <a
                            className="appbar-support-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://moblab-vision.zendesk.com/hc/zh-cn"
                        >
                            {i18n.t("APP.SUPPORT")}
                        </a> */}
                        {/* <div className="account icon">8</div> */}
                        {/* <div className="help icon">K</div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default AppBar;
