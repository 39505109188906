import React, { Component } from 'react'
import Card from '../Card'
import { i18n } from "../../lib"
// import { rem, deriveWidth } from "../../lib/utils"
// import {
//     LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
//   } from 'recharts';

class LanguageOverview extends Component {
    state={
        width: 0,
        data: []
    }

    componentDidMount() {
        const data = [];
        for (var q in this.props.data){
            const question = this.props.data[q];
            data.push({
                english: question.text.split(": ")[1],
                difficulty: question.pool + 1
            });
        }
        this.setState({
            data: data,
            // width: deriveWidth(this.container)
        })
    }

    render() {
        // const { data, width } = this.state
        const { score, median, accuracy } = this.props;
        return (
            <Card {...this.props} type="language_overview">
                {/* <div ref={(div) => {this.container = div}}>
                    <LineChart
                        width={width}
                        height={rem(18)}
                        data={data}
                        margin={{top: rem(2), right: rem(4)}}
                    >
                        <CartesianGrid 
                            strokeDasharray="3 3"
                            vertical={false}
                        />
                        <XAxis tick={false}/>
                        <YAxis domain={[1, 10]}/>
                        <Tooltip 
                            labelFormatter={ q => {
                                return i18n.t('LANGUAGE.QUESTION') + " : " + q;
                            }} 
                            formatter={ q => {
                                return [
                                    q,
                                    i18n.t('LANGUAGE.DIFFICULTY')
                                ];
                            }}
                        />
                        <Line type="monotone" 
                            dataKey="difficulty"
                            stroke="#4386CB" 
                            activeDot={{ r: 8 }} 
                        />
                        <Legend formatter={() => i18n.t('LANGUAGE.LEGEND')}/>
                    </LineChart>
                </div> */}
                <div className="stats">
                    <div className="stat">
                        <h3>{i18n.t('LANGUAGE.VOCAB_SCORE')}</h3>
                        <p>{score + "/" + 100}</p>
                    </div>
                    <div className="stat">
                        <h3>{i18n.t('LANGUAGE.MEDIAN')}</h3>
                        <p>{median + 1}</p>
                    </div>
                    <div className="stat">
                        <h3>{i18n.t('LANGUAGE.ACCURACY')}</h3>
                        <p>{accuracy + "%"}</p>
                    </div>
                </div>
            </Card>
        )
    }
}

export default LanguageOverview;
