import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import LeftNavBar from "../LeftNavBar";
import Excel from "./Excel";
import SummaryContext from "./summary/SummaryContext";
import StudentInfo from "./summary/StudentInfo";

function useSummary() {
    let [contractId, setContractId] = useState(null);
    let showSummary = (id) => setContractId(id);
    let hideSummary = () => setContractId(null);
    return { contractId, showSummary, hideSummary };
}

const ExcelContainer = ({ ...props }) => {
    let { contractId, showSummary, hideSummary } = useSummary();
    return (
        <StyledExcelContainer hasContractId={!!contractId}>
            <GlobalStyle />
            <LeftNavBar currentPage="trackingsheet" />
            <SummaryContext.Provider
                value={{ contractId, showSummary, hideSummary }}
            >
                <Excel {...props} />
                {contractId ? <StudentInfo contractId={contractId} /> : null}
            </SummaryContext.Provider>
        </StyledExcelContainer>
    );
};

const GlobalStyle = createGlobalStyle`
    .ant-modal-root {
        .ant-modal-title {
            font-size: 20px;
        }
        .ant-modal-footer {
            .Ripple.flat {
                font-size: 14px;
                line-height: 20px;
            }
            .ui.button {
                min-height: unset;
                font-size: 14px;
                line-height: 20px;
                padding: 5px 15px;

            }
        }
    }
`;

const StyledExcelContainer = styled.div`
    width: 100%;
    .excel-content {
        width: calc(100% - 50px);
        /* height: 100%; */
        height: calc(100vh - 80px);
        left: 50px;
        display: ${({ hasContractId }) => (hasContractId ? "none" : "block")};
        position: relative;
        overflow-y: auto;
        .filter-container {
            display: flex;
            justify-content: space-between;
        }

        .filter-right-contaner {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .filter-right-contaner > * {
            margin-left: 15px;
        }
    }

    .ant-table-tbody > tr.ant-table-row.normal:hover > td {
        background: #fff;
    }
    .ant-table-tbody > tr.ant-table-row.grayrow:hover > td {
        background: #e6e8e9;
    }

    .ant-table-tbody > tr.ant-table-row .editable-cell-value-wrap {
        padding-left: 5px;
        border: 1px solid #fff;
    }

    .ant-table-tbody > tr.ant-table-row.grayrow .editable-cell-value-wrap {
        padding-left: 5px;
        border: 1px solid #e6e8e9 !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9 !important;
        border-radius: 4px;
    }
    .ant-table-cell.ant-table-cell-fix-right > span {
        display: flex;
        justify-content: space-around;
    }
    .ant-table-cell.tag-cell {
        background: #fafafa !important;
        text-align: center;
    }

    .editable-cell-value-wrap.ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
    }
`;

export default ExcelContainer;
