import React, { useContext, useState, useEffect, useRef } from "react";
import {
    Table,
    Input,
    Popconfirm,
    Form,
    DatePicker,
    Select,
    AutoComplete,
} from "antd";
import EscapeUtil from "../../lib/utils/EscapeUtil";
import _ from "lodash";
import styled from "styled-components";

const { Option } = Select;
const AutoOption = AutoComplete.Option;

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const { TextArea } = Input;

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    menus,
    dropdown,
    datepicker,
    autoComplete,
    autoCompleteAddition,
    handleSearch,
    dynamicDropdown,
    getDropDownData,
    ellipsis,
    textarea,
    row,
    editRow,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [result, setResult] = useState([]);
    // const [options, setOptions] = useState([]);
    const autoChild = result.map((v) => (
        <AutoOption key={v.key} value={v.value} data={v}>
            {v.desc}
        </AutoOption>
    ));
    const selectSave = async (e, option) => {
        try {
            let values = await form.validateFields();
            toggleEdit();
            handleSave(record, values, option.data.data);
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        let value = undefined;
        if (Array.isArray(dataIndex)) {
            for (let i = 0; i < dataIndex.length; i++) {
                value = value ? value[dataIndex[i]] : record[dataIndex[i]];
            }
            form.setFieldsValue({
                [dataIndex[dataIndex.length - 1]]: value,
            });
        } else {
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        }
    };

    const refreshReturnValue = (newValue) => {
        record[dataIndex[dataIndex.length - 1]] = newValue;
        form.setFieldsValue({
            [dataIndex[dataIndex.length - 1]]: newValue,
        });
    };

    const save = async (e) => {
        try {
            let values = await form.validateFields();
            if (Array.isArray(dataIndex)) {
                let val = values[dataIndex[dataIndex.length - 1]];
                let flag = {};
                for (let j = 0; j < dataIndex.length; j++) {
                    if (j === dataIndex.length - 1) {
                        flag[dataIndex[j]] = val;
                    } else {
                        flag = record[dataIndex[j]];
                    }
                }
                values = flag;
            }
            toggleEdit();
            handleSave({ ...record, ...values }, (e) => {
                refreshReturnValue(e);
            });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };
    const search = _.debounce(async (value, autoCompleteAddition) => {
        if (autoCompleteAddition.escape) {
            value = EscapeUtil.mongoEscape(value);
        }
        handleSearch(value, setResult, autoCompleteAddition);
    }, 400);

    let childNode = children;

    if (editable) {
        if (editRow) {
            if (editing) {
                if (dropdown) {
                    let options = [];
                    menus.forEach((item, index) => {
                        options.push(
                            <Option value={item.key} key={item.key}>
                                {item.value}
                            </Option>
                        );
                    });

                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <Select
                                ref={inputRef}
                                onChange={save}
                                className="editableSelect"
                                onBlur={toggleEdit}
                                defaultOpen={true}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                    );
                } else if (dynamicDropdown) {
                    const options = [];
                    getDropDownData(record).forEach((item, index) => {
                        options.push(
                            <Option value={item.key} key={item.key}>
                                {item.value}
                            </Option>
                        );
                    });

                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <Select
                                ref={inputRef}
                                onChange={save}
                                className="editableSelect"
                                onBlur={toggleEdit}
                                defaultOpen={true}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                    );
                } else if (datepicker) {
                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <DatePicker
                                ref={inputRef}
                                onChange={save}
                                className="editableDate"
                                onBlur={toggleEdit}
                                open={true}
                            />
                        </Form.Item>
                    );
                } else if (autoComplete) {
                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <AutoComplete
                                ref={inputRef}
                                onSearch={(value) => {
                                    search(value, autoCompleteAddition);
                                }}
                                onFocus={(value) => {
                                    search(
                                        value.target.value,
                                        autoCompleteAddition
                                    );
                                }}
                                onSelect={selectSave}
                                onBlur={toggleEdit}
                                placeholder="点击选择"
                                className="editableAutoComplete"
                                defaultOpen={true}
                            >
                                {autoChild}
                            </AutoComplete>
                        </Form.Item>
                    );
                } else if (textarea) {
                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <TextArea
                                ref={inputRef}
                                // onPressEnter={save}
                                onBlur={save}
                                className="textarea"
                                rows={row ? row : 4}
                                style={{ resize: "none" }}
                                // className="editableInput"
                            />
                        </Form.Item>
                    );
                } else {
                    childNode = (
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={
                                Array.isArray(dataIndex)
                                    ? dataIndex[dataIndex.length - 1]
                                    : dataIndex
                            }
                        >
                            <Input
                                ref={inputRef}
                                onPressEnter={save}
                                onBlur={save}
                                className="editableInput"
                            />
                        </Form.Item>
                    );
                }
            } else {
                if (ellipsis) {
                    return (
                        <td
                            onClick={toggleEdit}
                            title={children[1]}
                            {...restProps}
                        >
                            <div className="editable-cell-value-wrap ellipsis">
                                {childNode}
                            </div>
                        </td>
                    );
                } else {
                    childNode = (
                        <div
                            className="editable-cell-value-wrap"
                            style={{
                                paddingRight: 24,
                            }}
                            onClick={toggleEdit}
                        >
                            {children}
                        </div>
                    );
                }
            }
        } else {
            if (ellipsis) {
                return (
                    <td title={children[1]} {...restProps}>
                        <div className="uneditable-cell-value-wrap ellipsis">
                            {childNode}
                        </div>
                    </td>
                );
            } else {
                childNode = (
                    <div
                        className="uneditable-cell-value-wrap"
                        style={{
                            paddingRight: 24,
                        }}
                    >
                        {children}
                    </div>
                );
            }
        }
    }

    if (ellipsis) {
        return (
            <td title={children[1]} {...restProps}>
                {childNode}
            </td>
        );
    } else {
        return <td {...restProps}>{childNode}</td>;
    }
};

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: "name",
                dataIndex: "name",
                width: "30%",
                editable: true,
            },
            {
                title: "age",
                dataIndex: "age",
            },
            {
                title: "address",
                dataIndex: "address",
            },
            {
                title: "operation",
                dataIndex: "operation",
                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => this.handleDelete(record.key)}
                        >
                            <a>Delete</a>
                        </Popconfirm>
                    ) : null,
            },
        ];
    }

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            key: count,
            name: `Edward King ${count}`,
            age: 32,
            address: `London, Park Lane no. ${count}`,
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    render() {
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.props.columns.map((col) => {
            if (col.onCell !== undefined) {
                return {
                    ...col,
                    onCell: (record) => {
                        let editable = col.editable;
                        if (_.isFunction(editable)) {
                            editable = editable(record, col.dataIndex);
                        }

                        let cells = col.onCell(record, col.dataIndex);
                        let object = {
                            record,
                            editable: editable,
                            dataIndex: col.dataIndex,
                            title: col.title,
                            handleSave: col.handleSave,
                            menus: col.menus
                                ? col.menus
                                : record["isDropdown"] &&
                                  record["isDropdown"][col.dataIndex]
                                ? record["isDropdown"][col.dataIndex]["menus"]
                                : [],
                            dropdown:
                                (record["isDropdown"] &&
                                record["isDropdown"][col.dataIndex]
                                    ? record["isDropdown"][col.dataIndex][
                                          "enable"
                                      ]
                                    : false) || col.dropdown,
                            datepicker:
                                (record["isDate"]
                                    ? record["isDate"][col.dataIndex]
                                    : false) || col.datepicker,
                            autoComplete:
                                (record["autoComplete"]
                                    ? record["autoComplete"][col.dataIndex]
                                    : false) || col.autoComplete,
                            autoCompleteAddition: record["autoComplete"]
                                ? record["autoComplete"]
                                : {},
                            handleSearch: col.handleSearch,
                            dynamicDropdown: col.dynamicDropdown,
                            getDropDownData: col.getDropDownData,
                            ellipsis: col.ellipsis,
                            textarea: col.textarea,
                            row: col.row,
                            ...cells,
                        };
                        return object;
                    },
                };
            }
            if (!col.editable) {
                return {
                    ...col,
                    onCell: (record) => ({
                        record,
                        ellipsis: col.ellipsis,
                    }),
                };
            }
            return {
                ...col,
                onCell: (record) => {
                    let editable = col.editable;
                    if (_.isFunction(editable)) {
                        editable = editable(record, col.dataIndex);
                    }

                    return {
                        record,
                        editable: editable,
                        dataIndex: col.dataIndex,
                        editRow: record["contractStatus"] !== "7",
                        title: col.title,
                        handleSave: col.handleSave,
                        menus: col.menus
                            ? col.menus
                            : record["isDropdown"] &&
                              record["isDropdown"][col.dataIndex]
                            ? record["isDropdown"][col.dataIndex]["menus"]
                            : [],
                        dropdown:
                            (record["isDropdown"] &&
                            record["isDropdown"][col.dataIndex]
                                ? record["isDropdown"][col.dataIndex]["enable"]
                                : false) || col.dropdown,
                        datepicker:
                            (record["isDate"]
                                ? record["isDate"][col.dataIndex]
                                : false) || col.datepicker,
                        autoComplete:
                            (record["autoComplete"]
                                ? record["autoComplete"][col.dataIndex]
                                : false) || col.autoComplete,
                        autoCompleteAddition: record["autoComplete"]
                            ? record["autoComplete"]
                            : {},
                        textarea: col.textarea,
                        row: col.row,
                        handleSearch: col.handleSearch,
                        dynamicDropdown: col.dynamicDropdown,
                        getDropDownData: col.getDropDownData,
                        ellipsis: col.ellipsis,
                    };
                },
            };
        });
        return (
            <StyledContainer>
                <div>
                    <Table
                        components={components}
                        bordered
                        dataSource={this.props.dataSource}
                        columns={columns}
                        pagination={
                            this.props.pagination
                                ? this.props.pagination
                                : false
                        }
                        scroll={this.props.scroll}
                        loading={this.props.loading}
                        size={"small"}
                        rowClassName={
                            this.props.rowClassName
                                ? this.props.rowClassName
                                : (record, index) => {
                                      const grayFlag =
                                          record["key"] === -1
                                              ? "grayrow"
                                              : "normal";

                                      const classNames = [grayFlag];

                                      if (record.isLastItemOfGroup) {
                                          classNames.push("last-item-of-group");
                                      }

                                      return classNames.join(" ");
                                  }
                        }
                        locale={{ ...this.props.local }}
                        className="editable"
                        onChange={
                            this.props.onChange ? this.props.onChange : null
                        }
                        showSorterTooltip={false}
                        showSizeChanger={false}
                    />
                </div>
            </StyledContainer>
        );
    }
}
const StyledContainer = styled.div.attrs({
    className: "editable-container",
})`
    .textarea {
        .ant-row {
            height: 100% !important;
        }
    }

    tr.last-item-of-group {
        td {
            border-bottom: 2px solid #1b2224;
        }
    }
    td.cross-row-td {
        border-bottom: 2px solid #1b2224;
    }
`;
export default EditableTable;
