import React, { Component } from 'react'
import i18n from "../../lib/i18n/i18n"
import * as Widget from "../../components/widgets"
import { Grid } from 'semantic-ui-react'

// let loaded = 0;
class TraitReport extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { traits } = this.props.data;

        if (this.props.printView) {
            return (
                <div className="Page TraitReport">
                    <Widget.TraitDescriptions
                        title={i18n.t('TRAIT_REPORT.TRAIT_BREAKDOWN_TITLE')}
                        subtitle={i18n.t('TRAIT_REPORT.TRAIT_BREAKDOWN_SUBTITLE')}
                        help={i18n.t('HELP.TRAIT_BREAKDOWN')}
                        traits={traits}
                        simple={true}
                    />
                </div>
            )
        }
        return (
            <div className="Page TraitReport">
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Widget.TraitsOverview
                                title={i18n.t('DASHBOARD.TRAITS_OVERVIEW_TITLE')}
                                traits={traits}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Widget.TraitDescriptions
                                title={i18n.t('TRAIT_REPORT.TRAIT_BREAKDOWN_TITLE')}
                                subtitle={i18n.t('TRAIT_REPORT.TRAIT_BREAKDOWN_SUBTITLE')}
                                help={i18n.t('HELP.TRAIT_BREAKDOWN')}
                                traits={traits}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </div>
        )
    }
}

export default TraitReport;
