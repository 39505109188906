import React, { useContext } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import SummaryContext from "./SummaryContext";
import { Tooltip } from "antd";

function StudentLink({ contractId, studentName, contractName, contractNo }) {
    let { showSummary } = useContext(SummaryContext);
    const tooltipString = contractNo
        ? `${studentName} - ${contractName} - ${contractNo}`
        : `${studentName} - ${contractName} `;

    return (
        <StyledContainer>
            <Tooltip placement="right" title={tooltipString}>
                <span
                    className="link-btn"
                    onClick={() => {
                        showSummary(contractId);
                    }}
                >
                    {studentName}
                </span>
            </Tooltip>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "student-link",
})`
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 15px; */
    line-height: 15px;
    white-space: normal;
    word-break: break-all;
    .link-btn {
        color: #1890ff;
        cursor: pointer;
    }
`;

StudentLink.propTypes = {
    contractId: propTypes.string.isRequired,
    studentName: propTypes.string.isRequired,
    contractName: propTypes.string.isRequired,
    contractNo: propTypes.string,
};

export default StudentLink;
