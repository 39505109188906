import { Strategy, Type } from '../../data/enum/Filter'
import { isObjectEmpty } from '.'

class FilterUtil {
    dataset={}
    initialized=false
    /**
     * Save dataset for current filter and validate filters to be used
     */
    init(dataset, filters){
        if (!dataset || this.initialized){
            return;
        }
        
        this.dataset = dataset;
        this.initialized = true;

        for (var f=0; f<filters.length; f++){
            validateFilter(filters[f]);
        }
    }
    /**
     * Apply an active filter to an entry in the current dataset, to determine if
     * the specified data entry should be included in the filtered output.
     * 
     * @param {Object} activeFilter active filter, including static filter parameters
     *                                  and current filter value 
     * @param {String} id           data entry to apply filter to
     */
    applyFilter({ filter, value }, id){
        const { keys, strategy, min, max, reverse } = filter;
        const data = this.dataset[id];
        
        switch (strategy){
            case Strategy.SUBSTRING:
                const superString = data[keys[0]].toLowerCase();
                const searchString = value.toLowerCase();
                return superString.indexOf(searchString) > -1; 
            case Strategy.EXISTS:
                return !!value === !!data[keys[0]];
            case Strategy.MULTIPLE:
                const exactMatch = data[keys[0]];
                return value.indexOf(exactMatch) > -1;
            case Strategy.RANGE:
                const intVal = parseFloat(data[keys[0]]);
                const rangeVal = (reverse ? (max - intVal) : (intVal - min));
                const val = rangeVal / (max-min) * 100;
                return val >= value[0] && val <= value[1];
            case Strategy.BITWISE_OR:
                for (var i=0; i<keys.length; i++){
                    if (eq(value.charAt(i), data[keys[i]] || 0)){
                        return true;
                    }
                }
                return false;
            case Strategy.BITWISE_AND:
                for (var k=0; k<keys.length; k++){
                    if (!eq(value.charAt(k), data[keys[k]] || 0)){
                        return false;
                    }
                }
                return true;
            default:
                return eq(value, data[keys[0]]);
        }
    }
    /**
     * 
     * @param {Array} data      Data to be filtered. Each entry must contain an id
     *                              that maps to its full entry in the saved dataset.
     * @param {Object} filters  Filters to apply. 
     */
    filter(data, filters){
        
        if (isObjectEmpty(filters)){
            return data;
        }

        const filteredData = [];
        for (var d=0; d<data.length; d++){
            const { id } = data[d];

            var isValid = true;
            for (var key in filters){
                isValid = this.applyFilter(filters[key], id);

                if (!isValid){
                    break;
                }
            }
            if (isValid){
                filteredData.push(data[d]);
            }
        }
        return filteredData;
    }
}


/**
 * Check that the filter has all the necessary required fields. 
 */
function validateFilter(filter){
    const { type } = filter;
    if (type === Type.RANGE){
        if (!has('min') || !has('max') || !has('units')){
            console.error('INVALID FILTER [RANGE]: filter must have props [min {Int}, max {Int}, units {[String, String]}]');
            return false;
        }
    } else if (type === Type.SELECT){
        if (!has('options') || filter.options.length < 1){
            console.error('INVALID FILTER [SELECT]: filter must have props [options {Array}]');
            return false;
        }
    }

    return true;

    function has(prop){
        return filter.hasOwnProperty(prop);
    }
}
/**
 * Compare two values with loose equality (i.e. 1 should equal '1')
 * 
 * @param {String | Number} a 
 * @param {String | Number} b 
 */
function eq(a, b){
    return a.toString() === b.toString();
}

export default FilterUtil;