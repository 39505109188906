import _ from "lodash";
import React, { useCallback } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Form, Row, Col, Input } from "antd";
import cn from "classnames";
const { TextArea } = Input;

const fieldConfig = [
    {
        label: "院校排名",
        fieldName: "schoolRank",
    },
    {
        label: "截止日期",
        fieldName: "overdate",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "申请类型",
        fieldName: "applyType",
    },
    {
        label: "面试情况",
        fieldName: "interviewStatusValue",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "查询网址",
        fieldName: "queryWebsite",
    },
    {
        label: "查询账号",
        fieldName: "queryAccount",
    },
    {
        label: "查询密码",
        fieldName: "queryPwd",
    },
    {
        label: "查询ID",
        fieldName: "queryId",
    },
    {
        label: "最后查询日期",
        fieldName: "lastQueryDate",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "录取结果",
        fieldName: "fillResultValue",
    },
    {
        label: "接受Offer截止日期",
        fieldName: "offerEndDate",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "奖学金金额",
        fieldName: "scholarship",
    },
    {
        label: "结果日期",
        fieldName: "endDate",
    },
    {
        label: "",
        span: 8,
    },
    {
        label: "备注",
        fieldName: "remark",
        isRemark: true,
        span: 24,
    },
];

function TrackadMissionPrint({ dataSource }) {
    const getFields = useCallback((config, schoolData) => {
        const children = [];

        _.each(config, function (
            { label, fieldName, span = 8, isRemark },
            index
        ) {
            if (label === "") {
                children.push(<Col span={span} key={index}></Col>);
                return;
            }

            children.push(
                <Col span={span} key={index}>
                    <Form.Item label={label} colon={false} labelAlign="left">
                        {isRemark ? (
                            <TextArea readOnly value={schoolData[fieldName]} />
                        ) : (
                            <Input readOnly value={schoolData[fieldName]} />
                        )}
                    </Form.Item>
                </Col>
            );
        });

        return children;
    }, []);

    return (
        <StyledContainer>
            {_.map(dataSource, function (schoolData, index) {
                return (
                    <React.Fragment key={index}>
                        <div
                            className={cn([
                                "sub-title",
                                { "break-before": index > 0 },
                            ])}
                        >
                            {schoolData.schoolNameEN}
                        </div>
                        <Row gutter={24} className="border-row page-break-auto">
                            {getFields(fieldConfig, schoolData)}
                        </Row>
                    </React.Fragment>
                );
            })}
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "only-for-print",
})``;

TrackadMissionPrint.propTypes = {
    dataSource: propTypes.array.isRequired,
};

export default TrackadMissionPrint;
