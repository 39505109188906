import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { TimeUtil } from "lib/utils";
import { Form, Row, Col, Table } from "antd";
import TrackadMissionPrint from "./TrackadMissionPrint";

function getParsedStudentInfo(studentData) {
    return studentData.academyInfos || [];
}

function SummaryTrackadMission({ studentData }) {
    const [academyInfoData, setAcademyInfoData] = useState({});

    useEffect(() => {
        setAcademyInfoData(getParsedStudentInfo(studentData));
    }, [studentData, setAcademyInfoData]);

    const [tableDataSource, tableColumns] = useMemo(
        function () {
            let columns = [
                {
                    title: "院校排名",
                    dataIndex: "schoolRank",
                    key: "schoolRank",
                    width: "80px",
                },
                {
                    title: "院校名称",
                    dataIndex: "schoolNameEN",
                    key: "schoolNameEN",
                    width: "300px",
                    ellipsis: true,
                },
                {
                    title: "面试情况",
                    dataIndex: "interviewStatusValue",
                    key: "interviewStatusValue",
                    width: "120px",
                },
                {
                    title: "申请类型",
                    dataIndex: "applyType",
                    key: "applyType",
                    width: "110px",
                },
                {
                    title: "截止日期",
                    dataIndex: "overdate",
                    key: "overdate",
                    width: "140px",
                },
                {
                    title: "查询网址",
                    dataIndex: "queryWebsite",
                    key: "queryWebsite",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "查询账号",
                    dataIndex: "queryAccount",
                    key: "queryAccount",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "查询密码",
                    dataIndex: "queryPwd",
                    key: "queryPwd",
                    width: "200px",
                    ellipsis: true,
                },
                {
                    title: "查询ID",
                    dataIndex: "queryId",
                    key: "queryId",
                    width: "150px",
                    ellipsis: true,
                },
                {
                    title: "最后查询日期",
                    dataIndex: "lastQueryDate",
                    key: "lastQueryDate",
                    width: "140px",
                },
                {
                    title: "录取结果",
                    dataIndex: "fillResultValue",
                    key: "fillResultValue",
                    width: "140px",
                    ellipsis: true,
                },
                {
                    title: "结果日期",
                    dataIndex: "endDate",
                    key: "endDate",
                    width: "140px",
                },
                {
                    title: "接受Offer截止日期",
                    dataIndex: "offerEndDate",
                    key: "offerEndDate",
                    width: "140px",
                    ellipsis: true,
                },
                {
                    title: "奖学金金额",
                    dataIndex: "scholarship",
                    key: "scholarship",
                    width: "125px",
                    ellipsis: true,
                },
                {
                    title: "备注",
                    dataIndex: "remark",
                    key: "remark",
                    width: "400px",
                    ellipsis: true,
                },
            ];

            let datasource = [];
            _.each(academyInfoData, function (item, index) {
                datasource.push({
                    key: index,
                    schoolRank: item.schoolRank,
                    schoolNameEN: item.schoolNameEN,
                    interviewStatusValue:
                        item.trackadmission.interviewStatusValue,
                    applyType: item.applyType,
                    overdate: TimeUtil.formatDate(item.overdate),
                    queryWebsite: item.trackadmission.queryWebsite,
                    queryAccount: item.trackadmission.queryAccount,
                    queryPwd: item.trackadmission.queryPwd,
                    queryId: item.trackadmission.queryId,
                    lastQueryDate: TimeUtil.formatDate(
                        item.trackadmission.lastQueryDate
                    ),
                    fillResultValue: item.trackadmission.fillResultValue,
                    endDate: TimeUtil.formatDate(item.trackadmission.endDate),
                    offerEndDate: TimeUtil.formatDate(
                        item.trackadmission.offerEndDate
                    ),
                    scholarship: item.trackadmission.scholarship,
                    remark: item.trackadmission.remark,
                });
            });

            return [datasource, columns];
        },
        [academyInfoData]
    );

    return (
        <StyledContainer>
            <header className="section-header">录取跟踪</header>
            <Form
                className="section-form"
                initialValues={{
                    size: "small",
                }}
            >
                <Row gutter={24} className="not-for-print">
                    <Col span={24}>
                        <Table
                            dataSource={tableDataSource}
                            columns={tableColumns}
                            size="small"
                            pagination={false}
                            scroll={{ x: 3000 }}
                            bordered
                        />
                    </Col>
                </Row>
                <TrackadMissionPrint dataSource={tableDataSource} />
            </Form>
        </StyledContainer>
    );
}

const StyledContainer = styled.div.attrs({
    className: "info-section",
})`
    .section-form {
        padding-bottom: 24px !important;
    }
`;

SummaryTrackadMission.propTypes = {
    studentData: propTypes.object.isRequired,
};

export default SummaryTrackadMission;
